import { secureCall, json, JSON_HEADER } from './api';

export const addFoodEntry = (entry, mealId) =>
  secureCall(`/meals/${mealId}/entries`, {
    method: 'post',
    headers: JSON_HEADER,
    body: JSON.stringify({
      FoodID: entry.food_id,
      FoodDescription: entry.food_description,
      quantity: entry.quantity,
      unit: entry.unit,
      unitName: entry.unit_name,
      unitDimension: entry.unit_dimension,
      unitMeasureId: entry.unit_measure_id,
      nutrients: entry.nutrients,
    }),
  }).then(json);

export const deleteFoodEntry = entryId =>
  secureCall(`/entries/${entryId}`, {
    method: 'delete',
  });

export const updateFoodEntry = entry =>
  secureCall(`/entries/${entry.id}`, {
    method: 'put',
    headers: JSON_HEADER,
    body: JSON.stringify({
      FoodID: entry.food_id,
      FoodDescription: entry.food_description,
      quantity: entry.quantity,
      unit: entry.unit,
      unitName: entry.unit_name,
      unitDimension: entry.unit_dimension,
      unitMeasureId: entry.unit_measure_id,
      nutrients: entry.nutrients,
    }),
  }).then(json);

export default {
  addFoodEntry,
  deleteFoodEntry,
  updateFoodEntry,
};
