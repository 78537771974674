import { connect } from 'react-redux';
import Row from './Row';
import { getSmallLocale } from 'redux/modules/account/account.selectors';
import {
  openModal,
  MODAL_TYPES,
  getModalProps,
  closeModal,
  updateModalProps,
} from 'redux/modules/modals/modals';
import get from 'lodash/get';

const mapStateToProps = state => ({
  locale: getSmallLocale(state),
  mealId: get(getModalProps(state), 'mealId', null),
  entryId: get(getModalProps(state), 'entryId', null),
});

const mapDispatchToProps = dispatch => ({
  onClick: (foodId, foodDescription, mealId, entryId) => {
    if (entryId) {
      dispatch(closeModal(MODAL_TYPES.ADD_FOOD));
      dispatch(updateModalProps({ foodId, foodDescription }));
    } else {
      dispatch(
        openModal(
          MODAL_TYPES.NEW_ENTRY,
          { foodId, foodDescription, mealId },
          true
        )
      );
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Row);
