import React from 'react';
import Modal from 'components/Modal';
import { FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
  header: { id: 'welcome.header' },
  content: { id: 'welcome.content' },
  footer: { id: 'welcome.footer' },
});

class WelcomeModal extends React.PureComponent {
  render() {
    const { isOpen, onExit } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onExit}>
        <h2 className="mdc-typography--display1">
          <FormattedMessage {...messages.header} />
        </h2>
        <p className="mdc-typography--body1">
          <FormattedMessage {...messages.content} />
        </p>
        <p className="mdc-typography--body2">
          <FormattedMessage {...messages.footer} />
        </p>
      </Modal>
    );
  }
}

export default WelcomeModal;
