import React from 'react';
import Modal from 'components/Modal';

import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { TextField } from 'components/forms/Textfield';
import Spacer from 'components/Spacer';
import { Button } from 'components/material/Button';
import H2 from 'components/H2';

const messages = defineMessages({
  title: {
    id: 'editName.title',
    defaultMessage: 'Change your name',
  },
  firstNamePlaceholder: {
    id: 'editName.firstName',
    defaultMessage: 'First name',
  },
  lastNamePlaceholder: {
    id: 'editName.lastName',
    defaultMessage: 'Last name',
  },
  button: {
    id: 'editName.button',
    defaultMessage: 'Change name',
  },
});

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

class EditName extends React.Component {
  constructor(props) {
    super(props);
    this.state = this._initState();
  }

  _initState = () => ({
    firstName: '',
    lastName: '',
  });

  _onChangeFirstName = firstName => {
    this.setState({ firstName });
  };

  _onChangeLastName = lastName => {
    this.setState({ lastName });
  };

  _forwardValueTo = action => e => {
    action(e.target.value);
  };

  _onSubmit = () => {
    if (this._readyToSend()) {
      this.props.onUpdateName(this.state.firstName, this.state.lastName);
      this._onClose();
    }
  };

  _onClose = () => {
    this.props.onExit();
    this.setState(this._initState());
  };

  _readyToSend = () =>
    this.state.firstName.trim() !== '' && this.state.lastName.trim() !== '';

  _onKeyPress = e => {
    if (e.key === 'Enter') {
      this._onSubmit();
    }
  };
  render() {
    const { intl, isOpen } = this.props;
    const { formatMessage } = intl;

    return (
      <Modal isOpen={isOpen} onClose={this._onClose}>
        <div onKeyPress={this._onKeyPress}>
          <H2 style={{ marginBottom: 25 }}>
            <FormattedMessage {...messages.title} />
          </H2>
          <Spacer spacing="xl" />
          <TextField
            outlined
            value={this.state.firstName}
            onChange={this._forwardValueTo(this._onChangeFirstName)}
            label={formatMessage(messages.firstNamePlaceholder)}
            autoFocus={true}
            style={{ width: '100%' }}
          />
          <Spacer spacing="lg" />
          <TextField
            outlined
            value={this.state.lastName}
            onChange={this._forwardValueTo(this._onChangeLastName)}
            label={formatMessage(messages.lastNamePlaceholder)}
            style={{ width: '100%' }}
          />
          <Spacer spacing="lg" />
          <ButtonWrapper>
            <Button
              unelevated
              disabled={!this._readyToSend()}
              onClick={this._onSubmit}
            >
              <FormattedMessage {...messages.button} />
            </Button>
          </ButtonWrapper>
        </div>
      </Modal>
    );
  }
}

export default injectIntl(EditName);
