import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0 15px;
`;

class QuestionHeader extends React.Component {
  render() {
    const { text, style } = this.props;
    return (
      <Wrapper style={style}>
        <span>{text}</span>
      </Wrapper>
    );
  }
}

QuestionHeader.propTypes = {
  text: PropTypes.string,
};

export default QuestionHeader;
