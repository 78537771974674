import React from 'react';
import Modal from 'components/Modal';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  defineMessages,
  injectIntl,
} from 'react-intl';
import H2 from 'components/H2';
import Radio from 'components/forms/Radio';
import Checkbox from 'components/forms/Checkbox';
import Spacer from 'components/Spacer';
import Typography from 'components/material/Typography';
import { TextField } from 'components/forms/Textfield';
import { Button } from 'components/material/Button';
import styled from 'styled-components';
import { CLIENT_GENDERS } from '../../../../../../../constants';
import { CircularProgress } from 'components/loaders/CircularProgress';
import { MonthPicker } from './MonthPicker';

const messages = defineMessages({
  title: { id: 'dri.profile.title' },
  explanation: { id: 'dri.profile.explanation' },
  sex: { id: 'dri.profile.sex' },
  birthdate: { id: 'dri.profile.birthdate' },
  done: { id: 'dri.profile.done' },
  day: { id: 'dri.profile.day' },
  year: { id: 'dri.profile.year' },
  other: { id: 'dri.profile.other' },
  woman: { id: 'dri.profile.woman' },
  man: { id: 'dri.profile.man' },
  lactation: { id: 'dri.profile.lactation' },
  pregnancy: { id: 'dri.profile.pregnancy' },
});

const Loader = styled(CircularProgress)`
  --mdc-theme-primary: white;
`;
const GenderWrapper = styled.div`
  --mdc-theme-secondary: ${x => x.theme.palette.primary1};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

class ProfileModal extends React.PureComponent {
  state = {
    gender: undefined,

    day: undefined,
    month: undefined,
    year: undefined,

    lactation: false,
    pregrancy: false,

    loading: false,
  };

  // We do not need the componentDidMount since modal are rendered even when not open.
  componentDidUpdate(prevProps) {
    if (prevProps.isOpen || !this.props.isOpen) return;

    const { gender, birthdate, lactation, pregnancy } = this.props;
    this.setState({ gender, lactation, pregnancy });

    if (!birthdate) return;

    const [year, month, day] = birthdate.split('-');
    this.setState({ year, month, day });
  }

  _updateGender = evt => {
    const gender = evt.target.value;
    this.setState({ gender });
  };

  renderGenderSelection = () => (
    <GenderWrapper>
      <Typography use="overline" rank="tertiary" tag="div">
        <FormattedMessage {...messages.sex} />
      </Typography>
      <Radio
        value={CLIENT_GENDERS.FEMALE}
        checked={this.state.gender === CLIENT_GENDERS.FEMALE}
        onChange={this._updateGender}
      >
        <FormattedMessage {...messages.woman} />
      </Radio>
      <Radio
        value={CLIENT_GENDERS.MALE}
        checked={this.state.gender === CLIENT_GENDERS.MALE}
        onChange={this._updateGender}
      >
        <FormattedMessage {...messages.man} />
      </Radio>
    </GenderWrapper>
  );

  _updateDay = evt => {
    const d = evt.target.value;
    if (!d) {
      return this.setState({ day: '' });
    }
    const day = d > 31 ? 31 : d < 1 ? 1 : d;
    this.setState({ day });
  };

  _updateYear = evt => {
    const y = evt.target.value;
    if (!y) {
      return this.setState({ year: '' });
    }
    this.setState({ year: y });
  };

  renderBirthdateSelection = () => {
    const { intl, isOpen } = this.props;

    if (!isOpen) return null;

    return (
      <GenderWrapper>
        <Typography use="overline" rank="tertiary">
          <FormattedMessage {...messages.birthdate} />
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            outlined
            type="number"
            name="day"
            label={intl.formatMessage(messages.day)}
            value={this.state.day}
            onChange={this._updateDay}
            style={{ width: 120 }}
          />
          <Spacer spacing="md" />
          <MonthPicker
            value={this.state.month}
            onChange={month => this.setState({ month })}
          />
          <Spacer spacing="md" />
          <TextField
            outlined
            type="number"
            name="year"
            label={intl.formatMessage(messages.year)}
            value={this.state.year}
            onChange={this._updateYear}
            style={{ width: 150 }}
          />
        </div>
      </GenderWrapper>
    );
  };

  _makeToggleCheckbox = box => evt => {
    const isChecked = evt.target.checked;
    this.setState({ [box]: isChecked });
  };

  renderOther = () => {
    if (this.state.gender !== CLIENT_GENDERS.FEMALE) return null;
    const { intl } = this.props;
    const { lactation, pregnancy } = this.state;
    return (
      <GenderWrapper>
        <Typography use="overline" rank="tertiary">
          <FormattedMessage {...messages.other} />
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Checkbox
            checked={!!lactation}
            onChange={this._makeToggleCheckbox('lactation')}
            label={intl.formatMessage(messages.lactation)}
          />
          <Spacer spacing="xl" />
          <Checkbox
            checked={!!pregnancy}
            onChange={this._makeToggleCheckbox('pregnancy')}
            label={intl.formatMessage(messages.pregnancy)}
          />
        </div>
      </GenderWrapper>
    );
  };

  _submit = () => {
    const { gender, day, month, year, lactation, pregnancy } = this.state;

    const birthdate = `${year}-${month}-${day}`;

    const { clientId, updateProfile, onExit, attributes } = this.props;
    this.setState({ loading: true });
    const updateObj = {
      gender,
      birth_date: birthdate,
      attributes: {
        ...attributes,
        lactation,
        pregnancy,
      },
    };
    return updateProfile(clientId, updateObj).finally(() => {
      this.setState({ loading: false });
      onExit();
    });
  };

  _isReady = () => {
    const { day, month, year, gender } = this.state;
    return day && month && year && gender;
  };

  render() {
    const { isOpen, onExit, firstName } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onExit}>
        <H2>
          <FormattedMessage {...messages.title} values={{ firstName }} />
        </H2>
        <Typography use="body2" rank="secondary" tag="div">
          <FormattedHTMLMessage {...messages.explanation} />
        </Typography>
        <Spacer spacing="lg" />
        {this.renderGenderSelection()}
        <Spacer spacing="md" />
        {this.renderBirthdateSelection()}
        <Spacer spacing="md" />
        {this.renderOther()}
        <Spacer spacing="lg" />
        <ButtonContainer>
          <Button
            unelevated
            onClick={this._submit}
            disabled={this.state.loading || !this._isReady()}
          >
            {this.state.loading ? (
              <Loader size="xsmall" />
            ) : (
              <FormattedMessage {...messages.done} />
            )}
          </Button>
        </ButtonContainer>
      </Modal>
    );
  }
}

export default injectIntl(ProfileModal);
