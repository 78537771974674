import React, { Component } from 'react';
import Dropdown from '../Dropdown';
import styled from 'styled-components';
import get from 'lodash/get';

const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 350px;
  overflow: auto;
`;
const StyledLi = styled.li`
  color: ${props =>
    props.selected
      ? props.theme.palette.primarySaturated
      : props.theme.palette.text.primary};
  font-weight: ${props => (props.selected ? 600 : 400)};

  &:hover {
    background-color: ${props => props.theme.palette.canvas};
    cursor: pointer;
  }
`;

const SelectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1.5px solid ${props => props.theme.palette.accent2};
  border-radius: 3px;
  padding: 10px;
  color: ${props => props.theme.palette.accent2};
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.palette.primary1};
    border-color: ${props => props.theme.palette.primary1};
  }
`;
const Option = styled.div`
  padding: 5px 10px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CaretDown = styled.i``;

const SelectValue = styled.span`
  color: ${props =>
    props.topValue
      ? props.theme.palette.text.secondary
      : props.theme.palette.text.primary};
`;

class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };

    this.toggleDropDown = this.toggleDropDown.bind(this);
  }

  onChange = option => {
    this.props.onChange(option);
  };

  toggleDropDown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  render() {
    const {
      value,
      placeholder,
      options,
      buttonStyle,
      buttonTextStyle,
      carretIcon = 'arrow_drop_down',
      maxWidth,
    } = this.props;
    const valueText = get(value, 'label', placeholder) || '';
    return (
      <div style={{ position: 'relative' }}>
        <SelectButton
          onClick={this.toggleDropDown}
          style={{ ...buttonStyle, maxWidth }}
        >
          <div
            style={{
              maxWidth: maxWidth - 30,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <SelectValue
              style={buttonTextStyle}
              className="mdc-typography--body2"
            >
              {valueText}
            </SelectValue>
          </div>
          <CaretDown className="material-icons">{carretIcon}</CaretDown>
        </SelectButton>
        <Dropdown
          left="0"
          right="-20px"
          top="-40px"
          isOpen={this.state.dropdownOpen}
          onOutsideClick={this.toggleDropDown}
        >
          <StyledUl>
            {options.map(option => (
              <StyledLi
                key={option.id}
                className="mdc-typography--body2"
                selected={value && option.id === value.id}
                onClick={() => {
                  this.onChange(option);
                  this.toggleDropDown();
                }}
              >
                <Option>{option.label}</Option>
              </StyledLi>
            ))}
          </StyledUl>
        </Dropdown>
      </div>
    );
  }
}

export const Select = CustomSelect;
export default CustomSelect;
