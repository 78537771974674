import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LeftNavigation from './components/LeftNavigation';
import {
  useParams,
  useRouteMatch,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Diaries from './Diaries';
import { useSelector } from 'react-redux';
import { getCurrentClientId } from 'redux/modules/clients/clients.selectors';
import Profile from './Profile';
import Reports from './Reports';
const Wrapper = styled.div`
  flex: 1;
  overflow-y: auto !important;
`;

const Client = ({ selectClient }) => {
  const match = useRouteMatch();
  const [open, setOpen] = useState(true);
  const { clientId } = useParams();
  const currentClientId = useSelector(getCurrentClientId);

  useEffect(() => {
    selectClient(clientId);
  }, [clientId]);

  let oldVisualUrl = useRouteMatch(`${match.path}/visual/:diaryId`);
  let oldDetailedUrl = useRouteMatch(`${match.path}/detailed/:diaryId`);
  if (oldVisualUrl)
    return (
      <Redirect
        to={`${match.url}/diaries/${oldVisualUrl.params.diaryId}/visual`}
      />
    );
  if (oldDetailedUrl)
    return (
      <Redirect
        to={`${match.url}/diaries/${oldDetailedUrl.params.diaryId}/detailed`}
      />
    );

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex' }}>
      <LeftNavigation open={open} setOpen={setOpen} />
      {/** TODO: Add a proper loader */}
      {!currentClientId ? null : (
        <Wrapper id="keenoa_page_content">
          <Switch>
            <Route path={`${match.path}/diaries`}>
              <Diaries />
            </Route>
            <Route path={`${match.path}/profile`}>
              <Profile />
            </Route>
            <Route path={`${match.path}/reports`}>
              <Reports />
            </Route>
            <Route>
              <Redirect to={`${match.url}/diaries`} />
            </Route>
          </Switch>
        </Wrapper>
      )}
    </div>
  );
};

export default Client;
