import { getIsCurrentClientFictive } from '../modules/clients/clients.selectors';
import { openModal, MODAL_TYPES } from '../modules/modals/modals';

function revokedMessageMiddleware({ dispatch, getState }) {
  return next => action => {
    const { error } = action;

    if (!error) {
      return next(action);
    }

    if (error.type !== 'revoked_token') {
      return next(action);
    }

    const isTest = getIsCurrentClientFictive(getState());
    setTimeout(() => {
      if (isTest) {
        dispatch(openModal(MODAL_TYPES.TEST_CLIENT_ERROR));
      } else {
        dispatch(openModal(MODAL_TYPES.REVOKED_CLIENT_ERROR));
      }
    });

    return next(action);
  };
}

export default revokedMessageMiddleware;
