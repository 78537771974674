import React, { Suspense } from 'react';
import HorizontalLoader from '../loaders/HorizontalLoader';
const EmojiPicker = React.lazy(() => import('./InternalPicker'));

export default function Picker(props) {
  return (
    <Suspense
      fallback={
        <div
          style={{
            height: 425,
            width: 320,
            background: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <HorizontalLoader />
        </div>
      }
    >
      <EmojiPicker {...props} />
    </Suspense>
  );
}
