import { connect } from 'react-redux';
import { getMealsThumbnails } from 'redux/modules/meals/meals.selectors';
import AddEntryButton from './AddEntryButton';
import { openSearchFoodModal } from 'redux/modules/entries/entries.operations';

const mapStateToProps = (state, { meals }) => ({
  pictures: getMealsThumbnails(state, meals),
});

const mapDispatchToProps = dispatch => ({
  addFood: mealId => dispatch(openSearchFoodModal(mealId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEntryButton);
