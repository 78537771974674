import React from 'react';
import isFinite from 'lodash/isFinite';

const Decimal = ({ children, decimals = 2, percentage }) =>
  <span>
    {children
      ? isFinite(children) ? +children.toFixed(decimals) : children
      : '-'}
    {percentage ? '%' : ''}
  </span>;

export default Decimal;
