import React, { useEffect, useState } from 'react';
import FETCH_STATUS from '../../../../constants/fetchStatus';
import { useHotkeys } from 'react-hotkeys-hook';
import useDebouncedCallback from 'use-debounce/lib/callback';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery } from 'utils/hooks';
import { useSelector, batch } from 'react-redux';
import { getTemplateFetchStatus } from 'redux/modules/reportTemplates';

function ReportLoader(props) {
  const {
    clientId,
    selectClient,

    createStatus,

    reportId,
    stageReport,

    fetchReport,
    fetchStatus,
    loadReport,

    save,
    isDirty,

    fetchTemplate,
    templateStructure,
    loadStructure,

    loadReportId,
    loadReportFetchStatus,
    loadReportStructure,

    selectDiary,
  } = props;

  const params = useParams();
  const query = useQuery();
  const history = useHistory();
  const match = useRouteMatch();
  const templateToLoad = query.get('load_template_id');
  let diariesToLoad = query.get('load_diaries');
  diariesToLoad = diariesToLoad ? diariesToLoad.split(',') : [];
  const [loaded, setLoaded] = useState(false);
  const templateFetchStatus = useSelector(s =>
    getTemplateFetchStatus(s, templateToLoad)
  );

  function saveVersion() {
    if (isDirty) save();
    return Promise.resolve();
  }
  function noDefaultSave(e) {
    e.preventDefault();
    e.stopPropagation();
    saveVersion();
  }

  useHotkeys('ctrl+s', noDefaultSave);
  useHotkeys('cmd+s', noDefaultSave);

  // We do not want to save instantly, to avoid creating to many versions
  const [debouncedSave] = useDebouncedCallback(save, 4 * 1000, [], {
    maxWait: 60 * 1000,
  });

  // Load and select the report client
  useEffect(() => {
    selectClient(params.clientId);
  }, [params.clientId]);

  // Stage the report based on the route
  useEffect(() => {
    if (params.reportId && params.reportId !== reportId) {
      stageReport(params.reportId);
    }
  }, [params.reportId, reportId]);

  // Fetch the report structure / content
  useEffect(() => {
    if (fetchStatus === FETCH_STATUS.NONE && params.reportId) {
      fetchReport(params.reportId);
    }
  }, [fetchStatus, params.reportId]);

  // Load the report's structure after it was fetched.
  useEffect(() => {
    // We wait for the client to be loaded, otherwise
    // fetching the diaries fails. (it is loaded from Export.js)
    if (
      fetchStatus === FETCH_STATUS.LOADED &&
      createStatus !== FETCH_STATUS.LOADING &&
      clientId
    ) {
      loadReport(params.reportId);
      setLoaded(true);
    }
  }, [fetchStatus, clientId]);

  // Load a template structure in if its a new report and
  // it was created with a template
  useEffect(() => {
    if (!templateToLoad) return;
    if (!loaded) return;

    if (templateFetchStatus === FETCH_STATUS.NONE) {
      fetchTemplate(templateToLoad);
      return;
    }
    if (templateFetchStatus === FETCH_STATUS.LOADED) {
      const loadTemplate = async () => {
        loadStructure(templateStructure);
        await save();
        query.delete('load_template_id');
        history.replace(`${match.url}?${query.toString()}`);
      };
      loadTemplate();
      return;
    }
  }, [templateToLoad, fetchStatus, loaded, templateFetchStatus]);

  // Load a template structure in if its a new report and
  // it was created by copying a past report
  useEffect(() => {
    if (!loadReportId) return;
    if (!loaded) return;

    if (loadReportFetchStatus === FETCH_STATUS.NONE) {
      fetchReport(loadReportId);
      return;
    }
    if (loadReportFetchStatus === FETCH_STATUS.LOADED && loadReportStructure) {
      const loadTheReport = async () => {
        loadStructure(loadReportStructure);
        await save();
        query.delete('load_report_id');
        history.replace(`${match.url}?${query.toString()}`);
      };
      loadTheReport();
      return;
    }
  }, [loadReportId, loadReportFetchStatus, loaded, loadReportStructure]);

  // Select diaries if its a new report and
  // some diaries were already selected
  useEffect(() => {
    if (!diariesToLoad.length) return;
    if (!loaded) return;

    batch(() => {
      diariesToLoad.forEach(selectDiary);
    });
    query.delete('load_diaries');
    history.replace(`${match.url}?${query.toString()}`);

    return;
  }, [diariesToLoad, loaded]);

  // Save the report when it becomes dirty
  useEffect(() => {
    if (isDirty) debouncedSave();
  }, [isDirty]);

  return null;
}

class Loader extends React.Component {
  save = () => {
    if (this.props.isDirty) {
      this.props.save();
    }
  };

  onUnload = event => {
    if (this.props.isDirty) {
      this.save();
      event.returnValue = `Are you sure you want to leave?`;
    }
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    this.save();
    window.removeEventListener('beforeunload', this.onUnload);
  }

  render() {
    return <ReportLoader {...this.props} />;
  }
}

export default Loader;
