import React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Switch } from 'components/forms/Switch';
import SettingRow from 'components/SettingRow';
import Typography from 'components/material/Typography';
import Spacer from 'components/Spacer';
import { codeToConstant, idToConstant } from '../../../../constants/nutriments';
import NutrientsModal from '../../modals/NutrientsModal/index.profile';
import IntuitiveModal from './IntuitiveModal';
import { PageWrapper } from 'components/PageWrapper';

const messages = defineMessages({
  questionActive: {
    id: 'client.profile.questionActive',
  },
  questionActiveHelp: {
    id: 'client.profile.questionActiveHelp',
  },
  foodDiaryTitle: {
    id: 'client.profile.diaryTitle',
  },
  subTitle: {
    id: 'client.profile.mobileSubtitle',
  },
  activeNutrients: {
    id: 'client.profile.activeNutrients',
  },
  activeNutrientsHelp: {
    id: 'client.profile.activeNutrientsHelp',
  },
});

class Profile extends React.PureComponent {
  state = {
    intuitiveOpen: false,
  };

  _setQuestionActive = evt => {
    const { questionActive } = this.props;
    this.props.setQuestionActive(!questionActive);
  };

  renderQuestionToggle = () => {
    const { questionActive } = this.props;
    return (
      <Switch checked={questionActive} onChange={this._setQuestionActive} />
    );
  };

  _updateCodes = nutrientIds => {
    const { updateActiveNutrients } = this.props;
    const newCodes = nutrientIds
      .filter(x => x)
      .map(id => idToConstant[id].code);
    updateActiveNutrients(newCodes);
  };

  renderBasic = () => {
    const {
      intl,
      activeNutrients,
      openNutrientsModal,
      questionActive,
      setQuestionActive,
      clientId,
    } = this.props;
    const nutrientIds = activeNutrients
      .filter(x => x)
      .map(c => codeToConstant[c].id);

    return (
      <PageWrapper dense>
        <>
          <Spacer spacing="xxl" />
          <Typography use="headline5" rank="primary" tag="div" center>
            <FormattedMessage {...messages.foodDiaryTitle} />
          </Typography>
          <Spacer spacing="sm" />
          <Typography use="body2" rank="secondary" tag="div" center>
            <FormattedMessage {...messages.subTitle} />
          </Typography>
          <Spacer spacing="xl" />
          <SettingRow
            name={messages.questionActive}
            icon={'spa'}
            renderValue={this.renderQuestionToggle}
            value={!!questionActive}
            onEdit={() => {
              this.setState({ intuitiveOpen: true });
            }}
          />

          <SettingRow
            name={messages.activeNutrients}
            icon={'monitor-heart-rate'}
            value={activeNutrients.map(code =>
              intl.formatMessage(codeToConstant[code].label)
            )}
            onEdit={openNutrientsModal}
          />
        </>
        {!clientId ? null : (
          <NutrientsModal
            currentNutrients={nutrientIds}
            updateNutrients={this._updateCodes}
            max={4}
          />
        )}
        <IntuitiveModal
          visible={this.state.intuitiveOpen}
          onDismiss={() => {
            this.setState({
              intuitiveOpen: false,
            });
          }}
          questionActive={questionActive}
          setQuestionActive={setQuestionActive}
        />
      </PageWrapper>
    );
  };

  render() {
    return <div>{this.renderBasic()}</div>;
  }
}

export default injectIntl(Profile);
