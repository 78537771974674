export const pre = 'keenoa/clients/';

export default {
  FETCH_CLIENTS_REQUEST: `${pre}FETCH_CLIENTS_REQUEST`,
  FETCH_CLIENTS_SUCCESS: `${pre}FETCH_CLIENTS_SUCCESS`,
  FETCH_CLIENTS_FAILURE: `${pre}FETCH_CLIENTS_FAILURE`,

  UPDATE_SEARCH_STRING: `${pre}UPDATE_SEARCH_STRING`,
  SET_CURRENT_CLIENT: `${pre}SET_CURRENT_CLIENT`,
  REMOVE_CURRENT_CLIENT: `${pre}REMOVE_CURRENT_CLIENT`,

  ADD_CLIENT_REQUEST: `${pre}ADD_CLIENT_REQUEST`,
  ADD_CLIENT_SUCCESS: `${pre}ADD_CLIENT_SUCCESS`,
  ADD_CLIENT_FAILURE: `${pre}ADD_CLIENT_FAILURE`,

  RENEW_CLIENT_REQUEST: `${pre}RENEW_CLIENT_REQUEST`,
  RENEW_CLIENT_SUCCESS: `${pre}RENEW_CLIENT_SUCCESS`,
  RENEW_CLIENT_FAILURE: `${pre}RENEW_CLIENT_FAILURE`,

  FETCH_CLIENT_TOKEN_REQUEST: `${pre}FETCH_CLIENT_TOKEN_REQUEST`,
  FETCH_CLIENT_TOKEN_SUCCESS: `${pre}FETCH_CLIENT_TOKEN_SUCCESS`,
  FETCH_CLIENT_TOKEN_FAILURE: `${pre}FETCH_CLIENT_TOKEN_FAILURE`,

  ADD_CLIENT_NUTRIENT: `${pre}ADD_CLIENT_NUTRIENT`,
  REMOVE_CLIENT_NUTRIENT: `${pre}REMOVE_CLIENT_NUTRIENT`,
  UPDATE_CLIENT_NUTRIENTS_SUCCESS: `${pre}UPDATE_CLIENT_NUTRIENTS_SUCCESS`,
  UPDATE_CLIENT_NUTRIENTS_FAILURE: `${pre}UPDATE_CLIENT_NUTRIENTS_FAILURE`,

  SELECT_CLIENTS: `${pre}SELECT_ALL_CLIENTS`,
  UNSELECT_ALL_CLIENTS: `${pre}UNSELECT_ALL_CLIENTS`,
  SELECT_CLIENT: `${pre}SELECT_CLIENT`,
  UNSELECT_CLIENT: `${pre}UNSELECT_CLIENT`,

  UPDATE_CLIENT_STATUS_REQUEST: `${pre}UPDATE_CLIENT_STATUS_REQUEST`,
  UPDATE_CLIENT_STATUS_SUCCESS: `${pre}UPDATE_CLIENT_STATUS_SUCCESS`,
  UPDATE_CLIENT_STATUS_FAILURE: `${pre}UPDATE_CLIENT_STATUS_FAILURE`,

  REMOVE_INVITE_REQUEST: `${pre}REMOVE_INVITE_REQUEST`,
  REMOVE_INVITE_SUCCESS: `${pre}REMOVE_INVITE_SUCCESS`,
  REMOVE_INVITE_FAILURE: `${pre}REMOVE_INVITE_FAILURE`,

  UPDATE_PROFILE_REQUEST: `${pre}UPDATE_PROFILE_REQUEST`,
  UPDATE_PROFILE_SUCCESS: `${pre}UPDATE_PROFILE_SUCCESS`,
  UPDATE_PROFILE_FAILURE: `${pre}UPDATE_PROFILE_FAILURE`,

  UPDATE_SETTINGS_REQUEST: `${pre}UPDATE_SETTINGS_REQUEST`,
  UPDATE_SETTINGS_SUCCESS: `${pre}UPDATE_SETTINGS_SUCCESS`,
  UPDATE_SETTINGS_FAILURE: `${pre}UPDATE_SETTINGS_FAILURE`,
};
