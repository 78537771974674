import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Typography from 'components/material/Typography';
import { get } from 'utils/storage';

const messages = defineMessages({
  smallTerms: {
    id: 'signup.smallTerms',
    defaultMessage: 'By signing in, you agree to the ',
  },
  terms: {
    id: 'signup.terms',
    defaultMessage: 'Terms of Service',
  },
  smallTermsMiddle: {
    id: 'signup.smallTermsMiddle',
  },
  subscriptionTerms: {
    id: 'signup.subscriptionTerms',
  },
});

export function Terms() {
  const locale = get('locale');
  const baseUrl = `https://keenoa.com/${
    locale && locale.startsWith('fr') ? 'fr/' : ''
  }policy/`;
  return (
    <div>
      <Typography use="caption" rank="secondary">
        <FormattedMessage {...messages.smallTerms} />
        <a target="_blank" href={`${baseUrl}terms`} rel="noopener noreferrer">
          <FormattedMessage {...messages.terms} />
        </a>
        <FormattedMessage {...messages.smallTermsMiddle} />
        <a
          target="_blank"
          href={`${baseUrl}subscription`}
          rel="noopener noreferrer"
        >
          <FormattedMessage {...messages.subscriptionTerms} />
        </a>
        .
      </Typography>
    </div>
  );
}
