import UserFoodCheckbox from './UserFoodCheckbox';
import { connect } from 'react-redux';
import {
  getSelectedUserFoodsIds,
  selectUserFoods,
  unselectAllUserFoods,
} from 'redux/modules/UI/userFoodsView';
import { getUserFoodIds } from 'redux/modules/userFoods';

const mapStateToProps = state => ({
  indeterminate: !!getSelectedUserFoodsIds(state).length,
  selected: !!getSelectedUserFoodsIds(state).length,
  visibleIds: getUserFoodIds(state),
});

const mapDispatchToProps = dispatch => ({
  select: ids => dispatch(selectUserFoods(ids)),
  unselect: () => dispatch(unselectAllUserFoods()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  ...dispatchProps,
  select: () => dispatchProps.select(stateProps.visibleIds),
});

export const UserFoodsCheckbox = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(UserFoodCheckbox);
