import React, { useState } from 'react';
import Dropdown from './Dropdown';
import EmojiPicker from './EmojiPicker';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconWrapper = styled.div`
  padding: 5px;

  transition: color 100ms ease-in-out;
  color: rgba(0, 0, 0, 0.17);

  &:hover {
    color: rgba(0, 0, 0, 0.33);
    cursor: pointer;
  }
`;

export default function Emoji({ onEmoji, center }) {
  const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        height: 22,
      }}
    >
      <IconWrapper>
        <FontAwesomeIcon
          icon={['fal', 'smile']}
          onClick={() => setOpen(!open)}
          style={{ fontSize: 26 }}
        />
      </IconWrapper>

      <Dropdown
        isOpen={open}
        onOutsideClick={() => setOpen(!open)}
        top={center ? '-212px' : '-425px'}
        left={center ? '-162px' : '-325px'}
      >
        <EmojiPicker
          onSelect={e => {
            onEmoji(e.native);
            setOpen(false);
          }}
        />
      </Dropdown>
    </div>
  );
}
