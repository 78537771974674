import styled, { useTheme } from 'styled-components';
import React, { useState } from 'react';
import { Button } from 'components/material/Button';
import Joyride from 'react-joyride';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';
import { useDispatch } from 'react-redux';

const messages = defineMessages({
  inviteBtn: {
    id: 'clientList.addBtn',
    defaultMessage: 'New Client',
  },
  inviteInstruction: {
    id: 'clientList.inviteInstruction',
  },
});

const NewClientBtn = styled(Button)`
  white-space: nowrap;
`;

function useUrl() {
  return new URL(window.location.href);
}

function NewClientButton() {
  const url = useUrl();
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [run, setRun] = useState(!!url.searchParams.get('onboard-invite'));
  const steps = [
    {
      disableBeacon: true,
      target: '.invite-button',
      title: intl.formatMessage(messages.inviteBtn),
      content: intl.formatMessage(messages.inviteInstruction),
      placement: 'bottom',
      spotlightClicks: true,
      styles: {
        tooltipFooter: {
          display: 'none',
        },
        buttonClose: {
          display: 'none',
        },
      },
    },
  ];

  const _addClient = () => {
    dispatch(openModal(MODAL_TYPES.NEW_CLIENT));
  };

  return (
    <>
      <Joyride
        steps={steps}
        run={run}
        styles={{
          options: {
            primaryColor: theme.palette.primarySaturated,
          },
        }}
      />
      <NewClientBtn
        className="invite-button"
        unelevated
        onClick={() => {
          setRun(false);
          _addClient();
        }}
      >
        <FormattedMessage {...messages.inviteBtn} />
      </NewClientBtn>
    </>
  );
}

export default NewClientButton;
