import CreateReportModal from './CreateReportModal';
import { connect } from 'react-redux';
import { fetchReportTemplates } from 'redux/modules/reportTemplates/reportTemplates.actions';
import {
  getFetchTemplatesStatus,
  getCurrentTemplates,
} from 'redux/modules/reportTemplates';
import {
  closeModal,
  getModalOpen,
  MODAL_TYPES,
} from 'redux/modules/modals/modals';
import { createReport } from 'redux/modules/reports';
import { getCurrentlySelectedDiaryIds } from 'redux/modules/foodDiaries/foodDiaries.selectors';

const mapStateToProps = state => ({
  status: getFetchTemplatesStatus(state),
  templates: getCurrentTemplates(state),
  isOpen: getModalOpen(state, MODAL_TYPES.PICK_EXPORT_TEMPLATE),
  diaryIds: getCurrentlySelectedDiaryIds(state),
});
const mapDispatchToProps = dispatch => ({
  fetchTemplates: () => dispatch(fetchReportTemplates()),
  onExit: () => dispatch(closeModal(MODAL_TYPES.PICK_EXPORT_TEMPLATE)),

  createReport: templateId => dispatch(createReport(templateId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateReportModal);
