import React from 'react';
import { Button as B } from '@rmwc/button';
import '@rmwc/button/styles';
import styled from 'styled-components';
import './Button.css';

const MyButton = styled(B)`
  ${x =>
    x.rank === 'secondary'
      ? `--mdc-theme-primary: ${x.theme.palette.text.secondary}`
      : x.rank === 'accent'
      ? `--mdc-theme-primary: ${x.theme.palette.accent1}`
      : x.rank === 'error'
      ? `--mdc-theme-primary: ${x.theme.palette.error}`
      : x.rank === 'primary'
      ? `--mdc-theme-primary: ${x.theme.palette.text.primary}`
      : x.rank === 'midPrimary'
      ? `--mdc-theme-primary: ${x.theme.palette.text.midPrimary}`
      : x.rank === 'saturated'
      ? `--mdc-theme-primary: ${x.theme.palette.primarySaturated}`
      : x.rank === 'success'
      ? `--mdc-theme-primary: rgba(46, 204, 113, 0.33);`
      : x.rank === 'warning'
      ? `--mdc-theme-primary: ${x.theme.palette.primary4}`
      : ''};
`;
export function Button(props) {
  return <MyButton {...props}>{props.children}</MyButton>;
}
