const pre = 'keenoa/comments/';

export default {
  ADD_COMMENT_REQUEST: `${pre}ADD_COMMENT_REQUEST`,
  ADD_COMMENT_SUCCESS: `${pre}ADD_COMMENT_SUCCESS`,
  ADD_COMMENT_FAILURE: `${pre}ADD_COMMENT_FAILURE`,

  FETCH_COMMENTS_REQUEST: `${pre}FETCH_COMMENTS_REQUEST`,
  FETCH_COMMENTS_SUCCESS: `${pre}FETCH_COMMENTS_SUCCESS`,
  FETCH_COMMENTS_FAILURE: `${pre}FETCH_COMMENTS_FAILURE`,
};
