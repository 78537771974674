import { connect } from 'react-redux';
import ToolsBox from 'components/ToolsBox';
import { clientsOperations } from 'redux/modules/clients';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onElementClick: nutrient =>
    dispatch(
      clientsOperations.addNutrientToCurrentClient(
        nutrient,
        ownProps.elementsInUse
      )
    ),
});

const ToolsBoxContainer = connect(null, mapDispatchToProps)(ToolsBox);

export default ToolsBoxContainer;
