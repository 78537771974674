import React from 'react';
import Modal from 'components/Modal';
import H2 from 'components/H2';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Elements } from 'react-stripe-elements';
import Spacer from 'components/Spacer';
import CheckoutForm from './CheckoutForm';

const messages = defineMessages({
  header: { id: 'addSource.header' },
});

class AddPayment extends React.Component {
  _onClose = () => {
    this.props.onExit();
  };

  render() {
    const { isOpen, locale } = this.props;

    return (
      <Modal isOpen={isOpen} onClose={this._onClose}>
        <H2 className="mdc-typography--display1" style={{ marginBottom: 35 }}>
          <FormattedMessage {...messages.header} />
        </H2>
        <Spacer spacing="xl" />
        <Elements
          locale={locale}
          fonts={[
            {
              cssSrc:
                'https://fonts.googleapis.com/css?family=Roboto:400,500,600,700',
            },
          ]}
        >
          <CheckoutForm close={this._onClose} />
        </Elements>
      </Modal>
    );
  }
}

export default AddPayment;
