import actions from './foodDiaries.actions';
import {
  makeGetMonthFetchStatus,
  getFetchStatus,
} from './foodDiaries.selectors';
import padStart from 'lodash/padStart';
import FETCH_STATUS from '../../../constants/fetchStatus';
import foodDiariesService from '../../../api/foodDiaries';
import * as schemas from '../../schemas';
import { normalize } from 'normalizr';

export const fetchFoodDiariesNMonthsAgo = (clientId, nbMonths) => dispatch => {
  // We fetch from 7 days in the future to
  // support the use case where dietitians
  // want to pre-fill diaries.
  const in7Days = new Date();
  in7Days.setDate(in7Days.getDate() + 7);
  const nMonthsAgo = new Date();
  nMonthsAgo.setMonth(in7Days.getMonth() - nbMonths);
  return dispatch(
    fetchFoodDiaries(nMonthsAgo.toISOString(), in7Days.toISOString(), clientId)
  );
};

export const fetchFoodDiaries = (startDate, endDate, clientId) => (
  dispatch,
  getState
) => {
  const monthStatus = makeGetMonthFetchStatus(clientId)(getState());
  const trueStartDate = _findTrueDate(startDate, monthStatus, true)(_nextMonth);
  const trueEndDate = _findTrueDate(
    endDate,
    monthStatus,
    false
  )(_previousMonth);

  dispatch(actions.fetchDiaries(trueStartDate, trueEndDate, clientId));
  return foodDiariesService
    .fetchAll(trueStartDate, trueEndDate, clientId)
    .then(res => {
      dispatch(
        actions.fetchDiariesSuccess(
          trueStartDate,
          trueEndDate,
          normalize(res, schemas.arrayOfFoodDiary),
          clientId
        )
      );
    })
    .catch(err => {
      dispatch(
        actions.fetchDiariesFailure(trueStartDate, trueEndDate, clientId, err)
      );
    });
};

export const fetchFoodDiary = (id, clientId) => (dispatch, getState) => {
  const fetchStatus = getFetchStatus(getState());
  if (
    fetchStatus[id] === FETCH_STATUS.LOADING ||
    fetchStatus[id] === FETCH_STATUS.LOADED ||
    id === null
  ) {
    return Promise.resolve();
  }

  dispatch(actions.fetchDiary(id));
  return foodDiariesService
    .fetchOne(id, clientId)
    .then(res =>
      dispatch(actions.fetchDiarySuccess(id, normalize(res, schemas.foodDiary)))
    )
    .catch(err => {
      dispatch(actions.fetchDiaryFailure(id, err));
    });
};

export const unselectFoodDiary = () => actions.selectFoodDiary(null);

export default {
  selectFoodDiary: actions.selectFoodDiary,
  unselectFoodDiary,
  fetchFoodDiaries,
  fetchFoodDiary,
  newClientFetchDiaries: actions.newClientFetchDiaries,
};

function _findTrueDate(dateString, monthStatus, isStart) {
  const _removeDay = value =>
    value
      .split('-')
      .splice(0, 2)
      .join('-');

  return nextDateFn => {
    let trueDate = dateString;
    let found = false;
    let updates = 0;
    while (!found) {
      const noDay = _removeDay(trueDate);
      const trueDateStatus = monthStatus[noDay];
      if (trueDateStatus && trueDateStatus === FETCH_STATUS.LOADING) {
        trueDate = nextDateFn(trueDate);
        updates = updates + 1;
      } else {
        found = true;
      }
    }

    const dateParts = trueDate.split('-');
    const y = parseInt(dateParts[0], 10);
    const m = parseInt(dateParts[1], 10);
    const d = isStart ? 1 : _lastDayInMonth(m, y);
    return _buildDateString(y, m, d);
  };
}

function _nextMonth(dateString) {
  return _closeMonth(dateString, true);
}

function _previousMonth(dateString) {
  return _closeMonth(dateString, false);
}

function _closeMonth(dateString, isNext = true) {
  const dateParts = dateString.split('-');
  let y = parseInt(dateParts[0], 10);
  let m = parseInt(dateParts[1], 10);
  let d = parseInt(dateParts[2], 10);

  let newMonth;
  let newYear;
  if (isNext) {
    if (m + 1 > 12) {
      newMonth = 1;
      newYear = y + 1;
    } else {
      newMonth = m + 1;
      newYear = y;
    }
  } else {
    if (m - 1 < 1) {
      newMonth = 12;
      newYear = y - 1;
    } else {
      newMonth = m - 1;
      newYear = y;
    }
  }
  return _buildDateString(newYear, newMonth, d);
}

function _buildDateString(y, m, d) {
  return `${y}-${padStart(m, 2, 0)}-${padStart(d, 2, 0)}`;
}

/** Cool answer from: https://stackoverflow.com/a/27947860/5059407 */
function _lastDayInMonth(m, y) {
  return m === 2
    ? y & 3 || (!(y % 25) && y & 15)
      ? 28
      : 29
    : 30 + ((m + (m >> 3)) & 1);
}
