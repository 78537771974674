import React from 'react';
import { FastCheckbox } from 'components/forms/Checkbox';

function ReportCheckBox({ id, selected, select, unselect, indeterminate }) {
  const handleInputChange = e => {
    e.stopPropagation();
    if (!selected) {
      select(id);
    } else {
      unselect(id);
    }
  };
  const stopPropagation = e => {
    e.stopPropagation();
  };

  return (
    <FastCheckbox
      indeterminate={indeterminate}
      checked={selected}
      onChange={handleInputChange}
      onClick={stopPropagation}
    />
  );
}

export default ReportCheckBox;
