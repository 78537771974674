import { connect } from 'react-redux';
import {
  getModalOpen,
  MODAL_TYPES,
  closeModal,
} from 'redux/modules/modals/modals';

import ProfileModal from './ProfileModal';
import {
  getCurrentClientFirstName,
  getCurrentClientGender,
  getCurrentClientId,
  getCurrentClientBirthdate,
  getCurrentClientPregnancy,
  getCurrentClientLactation,
  getCurrentClientAttributes,
} from 'redux/modules/clients/clients.selectors';
import { updateClientProfile } from 'redux/modules/clients/clients.actions';
import { getIsLocaleFr } from 'redux/modules/account/account.selectors';

const mapStateToProps = state => ({
  isOpen: getModalOpen(state, MODAL_TYPES.PROFILE),
  firstName: getCurrentClientFirstName(state),
  isFr: getIsLocaleFr(state),

  gender: getCurrentClientGender(state),
  birthdate: getCurrentClientBirthdate(state),
  pregnancy: getCurrentClientPregnancy(state),
  lactation: getCurrentClientLactation(state),
  attributes: getCurrentClientAttributes(state),

  clientId: getCurrentClientId(state),
});

const mapDispatchToProps = dispatch => ({
  onExit: () => dispatch(closeModal(MODAL_TYPES.PROFILE)),
  updateProfile: (clientId, props) =>
    dispatch(updateClientProfile(clientId, props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileModal);
