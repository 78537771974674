import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import types, { pre } from './builderConfigs.types';
import {
  createBuilderConfigApi,
  fetchBuilderConfigApi,
} from '../../../../api/foods';
import schemas from '../../../schemas';
import { makeFetchStatus, makeById } from '../../../../utils/standardReducers';

export default combineReducers({
  byId: makeById('builderConfigs'),

  createConfigStatus: makeFetchStatus(`${pre}CREATE_BUILDER_CONFIG`),
});

/**
 * Actions
 * */
export const createBuilderConfig = (builderId, options, adjustments) => ({
  types: [
    types.CREATE_BUILDER_CONFIG_REQUEST,
    types.CREATE_BUILDER_CONFIG_SUCCESS,
    types.CREATE_BUILDER_CONFIG_FAILURE,
  ],
  callAPI: () =>
    createBuilderConfigApi(builderId, options, adjustments).then(res => {
      return normalize(res, schemas.builderConfig);
    }),
  payload: { builderId, options },
});

export const fetchBuilderConfig = configId => ({
  types: [
    types.FETCH_BUILDER_CONFIG_REQUEST,
    types.FETCH_BUILDER_CONFIG_SUCCESS,
    types.FETCH_BUILDER_CONFIG_FAILURE,
  ],
  callAPI: () =>
    fetchBuilderConfigApi(configId).then(res =>
      normalize(res, schemas.builderConfig)
    ),
  payload: { configId },
});
