import React from 'react';
import styled, { withTheme } from 'styled-components';
import { defineMessages } from 'react-intl';
import differenceBy from 'lodash/differenceBy';
import InfoText from './InfoText';
import NutrientTags from './NutrientTags';
import Typography from './material/Typography';

const messages = defineMessages({
  title: { id: 'toolbox.title' },
  instruction: { id: 'toolbox.instruction' },
});

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: stretch;
  border-radius: 5px;
  margin-bottom: 10px;
  min-height: 21px;
  padding: 10px;
  border: 1px solid ${props => props.theme.palette.lightGray2};
`;

class ToolsBox extends React.PureComponent {
  _onElementClick = element => this.props.onElementClick(element);

  render() {
    const inUse = this.props.elementsInUse || [];
    const elementsToShow = differenceBy(this.props.elements, inUse, 'id');
    return (
      <Box>
        <Typography
          use="subtitle2"
          rank="midPrimary"
          style={{ padding: '5px 10px' }}
        >
          <InfoText
            textMessage={messages.title}
            tooltipMessage={messages.instruction}
          />
        </Typography>
        <NutrientTags
          onElementClick={this._onElementClick}
          elements={elementsToShow}
          chipColorMapper={this.props.chipColorMapper}
        />
      </Box>
    );
  }
}

export default withTheme(ToolsBox);
