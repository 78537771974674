import types from './comments.types';

import { normalize } from 'normalizr';
import schemas, { arrayOfComment } from '../../schemas';
import { addComment, getComments } from '../../../api/foodDiaries';

export const commentMeal = (mealId, comment) => ({
  types: [
    types.ADD_COMMENT_REQUEST,
    types.ADD_COMMENT_SUCCESS,
    types.ADD_COMMENT_FAILURE,
  ],
  callAPI: () =>
    addComment(mealId, comment).then(res => {
      return normalize(res, schemas.comment);
    }),
  payload: {
    mealId,
    comment,
  },
});

export const fetchComments = mealId => ({
  types: [
    types.FETCH_COMMENTS_REQUEST,
    types.FETCH_COMMENTS_SUCCESS,
    types.FETCH_COMMENTS_FAILURE,
  ],
  callAPI: () =>
    getComments(mealId).then(res => normalize(res, arrayOfComment)),
  payload: {
    mealId,
  },
});
