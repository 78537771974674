import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const ShowAll = styled.div`
  color: ${props => props.theme.palette.primary1};
  text-align: center;
  padding: 10px;

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.palette.primarySaturated};
  }
`;

const CountBadge = styled.div`
  border-radius: 3px;
  background-color: ${props => props.theme.palette.midGray};
  color: white;
  padding: 2px 4px;
  font-size: 12px;
  font-weight: 600;
  display: inline;
`;

class ViewMore extends React.PureComponent {
  render() {
    const { open, showMessage, closeMessage, remaining, onClick } = this.props;

    return (
      <ShowAll onClick={onClick} className="mdc-typography--subtitle2">
        {open ? (
          <FormattedMessage {...closeMessage} />
        ) : (
          <span>
            <FormattedMessage {...showMessage} />{' '}
            <CountBadge>{remaining}</CountBadge>
          </span>
        )}
      </ShowAll>
    );
  }
}

export default ViewMore;
