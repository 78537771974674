import React from 'react';
import Modal from 'components/Modal';
import MealGroup from '../../MealGroup';

class GroupModal extends React.PureComponent {
  render() {
    const { isOpen, onExit, diaryId, pos } = this.props;
    if (!diaryId || (!pos && pos !== 0)) return null;
    return (
      <Modal isOpen={isOpen} onClose={onExit} style={{ maxWidth: '700px' }}>
        <MealGroup diaryId={diaryId} pos={pos} inModal />
      </Modal>
    );
  }
}

export default GroupModal;
