import types from './foodDiaries.types';
import FETCH_STATUS from '../../../constants/fetchStatus';
import { combineReducers } from 'redux';
import padStart from 'lodash/padStart';
import { makeFetchStatus } from 'utils/standardReducers';
import merge from 'lodash/merge';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_FOOD_DIARIES_SUCCESS:
    case types.FETCH_FOOD_DIARY_SUCCESS:
    case types.SET_DIARY_VERIFY_SUCCESS:
      return merge({}, state, action.response.entities.foodDiaries);
    case types.SET_CURRENT_FOOD_DIARY:
      return {
        ...state,
        [action.foodDiaryId]: {
          ...state[action.foodDiaryId],
          last_viewed: new Date(),
        },
      };
    default:
      return state;
  }
};

export const fetchStatus = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_FOOD_DIARY_REQUEST:
      return {
        ...state,
        [action.id]: FETCH_STATUS.LOADING,
      };
    case types.FETCH_FOOD_DIARY_SUCCESS:
      return {
        ...state,
        [action.id]: FETCH_STATUS.LOADED,
      };
    case types.FETCH_FOOD_DIARY_FAILURE:
      return {
        ...state,
        [action.id]: FETCH_STATUS.FAILED,
      };
    default:
      return state;
  }
};

/** We always fetch complete months. The keys of this map are month with
 * year yyyy-mm */
export const monthFetchStatus = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_FOOD_DIARIES_REQUEST:
      return _updateStatus(state, action, FETCH_STATUS.LOADING);
    case types.FETCH_FOOD_DIARIES_SUCCESS:
      return _updateStatus(state, action, FETCH_STATUS.LOADED);
    case types.FETCH_FOOD_DIARIES_FAILURE:
      return _updateStatus(state, action, FETCH_STATUS.FAILED);
    case types.NEW_CLIENT_FETCH_FOOD_DIARIES:
      return {};
    default:
      return state;
  }
};

export const currentFoodDiaryId = (state = null, action) => {
  switch (action.type) {
    case types.SET_CURRENT_FOOD_DIARY:
      return action.foodDiaryId;
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  createStatus: makeFetchStatus('CREATE_FOOD_DIARY'),
  fetchStatus,
  monthFetchStatus,
  currentFoodDiaryId,
});

function _updateStatus(state, action, status) {
  return {
    ...state,
    [action.clientId]: {
      ...state[action.clientId],
      ..._updateObject(
        _getMonthsStrings(action.startDate, action.endDate),
        status
      ),
    },
  };
}

function _updateObject(monthStrings, status) {
  return monthStrings.reduce((o, k) => ({ ...o, [k]: status }), {});
}

function _getMonthsStrings(startDate, endDate) {
  const start = startDate.split('-');
  let ys = parseInt(start[0], 10);
  let ms = parseInt(start[1], 10);

  const end = endDate.split('-');
  const ye = parseInt(end[0], 10);
  const me = parseInt(end[1], 10);

  if (ys > ye || (ys === ye && ms > me)) return [];

  const monthStrings = [];
  while (ys < ye) {
    monthStrings.push(`${ys}-${padStart(ms, 2, 0)}`);
    ms += 1;
    if (ms > 12) {
      ms = 1;
      ys += 1;
    }
  }
  while (ms <= me) {
    monthStrings.push(`${ys}-${padStart(ms, 2, 0)}`);
    ms += 1;
  }
  return monthStrings;
}
