import React, { useState, useEffect } from 'react';
import Typography from 'components/material/Typography';
import { TextField } from 'components/forms/Textfield';
import { FormattedMessage } from 'react-intl';
import Spacer, { SPACINGS, SIZES } from 'components/Spacer';
import { MAIN_IDS, CHILDREN_IDS } from './constants';

function idToStyle(id) {
  if (MAIN_IDS.has(id))
    return {
      use: 'subtitle2',
      rank: 'primary',
      style: { fontWeight: 600 },
    };

  return {
    use: 'subtitle2',
    rank: 'primary',
    style: {
      // flex: 1,
      fontWeight: 400,
      marginLeft: CHILDREN_IDS.has(id) ? SIZES[SPACINGS.large] : 0,
    },
  };
}
//https://stackoverflow.com/a/11832950/5059407
function roundTo2Deceimals(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

function calculatePercent(dv, value) {
  return value ? roundTo2Deceimals((value * 100) / dv) : '';
}
function calculateValue(dv, percent) {
  return roundTo2Deceimals((percent * dv) / 100);
}

function Dv({ dv, value, setValue }) {
  const [val, setVal] = useState(calculatePercent(dv, value));
  useEffect(() => {
    setVal(calculatePercent(dv, value));
  }, [value]);

  if (!dv) return null;

  return (
    <>
      <div style={{ width: 58, position: 'relative', marginLeft: 'auto' }}>
        <TextField
          type="number"
          step="0.1"
          onBlur={() => {
            if (!val) return;

            setValue(calculateValue(dv, val));
          }}
          value={val}
          onChange={e => {
            setVal(e.target.value);
          }}
          name="dv"
          className="kmdc_compact"
        />
      </div>
      <Spacer spacing={SPACINGS.small} />
      <div className="flex-row">
        <Typography use="body2" rank="primary">
          {'%'}
        </Typography>
        <Spacer spacing={SPACINGS.medium} />
      </div>
    </>
  );
}

function Unit({ unit, value, setValue }) {
  return (
    <>
      <div
        style={{
          width: 68,
          position: 'relative',
          marginLeft: SIZES[SPACINGS.small],
        }}
      >
        <TextField
          value={value}
          type="number"
          step="0.01"
          onChange={e => setValue(e.target.value)}
          name="size"
          className="kmdc_compact"
        />
      </div>
      <Spacer spacing={SPACINGS.small} />
      <div className="flex-row">
        <Typography use="body2" rank="primary">
          {unit}
        </Typography>
        <Spacer spacing={SPACINGS.medium} />
      </div>
    </>
  );
}

export function Nutrient({ id, label, unit, small, dv, value, setValue }) {
  return (
    <React.Fragment key={id}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Typography
          {...(small
            ? {
                use: 'subtitle2',
                rank: 'primary',
                style: { flex: 1 },
              }
            : idToStyle(id))}
          tag="div"
        >
          <FormattedMessage {...label} />
        </Typography>

        <Unit unit={unit} value={value} setValue={setValue} />
        <Dv dv={dv} value={value} setValue={setValue} />
      </div>
      <Spacer spacing={SPACINGS.medium} />
    </React.Fragment>
  );
}
