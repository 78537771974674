import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Button } from 'components/material/Button';
import Typography from 'components/material/Typography';
import { exportDiariesApi } from '../../../../api/foodDiaries';
import RangePicker from 'components/RangePicker/RangePicker';
import Checkbox from 'components/forms/Checkbox';
import H2 from 'components/H2';
import styled from 'styled-components';
import CircularProgress from 'components/loaders/CircularProgress';
import Spacer from 'components/Spacer';

const messages = defineMessages({
  selected: { id: 'csv.export.selected' },
  configure: { id: 'csv.export.configure' },
  confirmTitle: { id: 'csv.export.confirm.title' },
  confirmDetail: { id: 'csv.export.confirm.detail' },
  confirmDone: { id: 'csv.export.confirm.done' },
  title: { id: 'csv.export.title' },
  subTitle: { id: 'csv.export.info' },
  range: { id: 'csv.export.range' },
  verified: { id: 'csv.export.verifiedOnly' },
  withinRange: { id: 'csv.export.withinRange' },
  all: { id: 'csv.export.all' },
  export: { id: 'csv.export.export' },
  submitting: { id: 'csv.export.submitting' },
  options: { id: 'csv.export.options' },
  withIngredients: { id: 'csv.export.withIngredients' },
  grouping: { id: 'csv.export.grouping' },
  withDishes: { id: 'csv.export.withDishes' },
  aggregate: { id: 'csv.export.aggregate' },
  single: { id: 'csv.export.single' },
  details: { id: 'csv.export.details' },
  allDiaries: { id: 'csv.export.allDiaries' },
});

const CheckboxWrapper = styled.div`
  --mdc-theme-secondary: ${x => x.theme.palette.success};
`;

const PrimaryCheckboxWrapper = styled.div`
  --mdc-theme-secondary: ${x => x.theme.palette.primary1};
`;

function ExportModal({ isOpen, onExit, clientIds }) {
  const [forced, setForced] = useState(false);
  const [from, setFrom] = useState(undefined);
  const [to, setTo] = useState(undefined);
  const [onlyVerified, setOnlyVerified] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [exportAll, setExportAll] = useState(true);
  const [withIngredients, setWithIngredients] = useState(false);
  const [aggregate, setAggregate] = useState(true);

  useEffect(() => {
    if (isOpen && !forced) {
      setForced(true);
    }
  }, [isOpen]);

  const _requestExports = async () => {
    setSending(true);
    try {
      await exportDiariesApi(clientIds, {
        exportAll,
        from,
        to,
        onlyVerified,
        aggregate,
        withIngredients,
      });
    } catch (err) {
      console.log(err);
    }

    setSending(false);
    setSent(true);
  };

  const _onClose = () => {
    if (sent) {
      setSent(false);
      setForced(false);
    }
    onExit();
  };

  const _setRange = ({ from, to }) => {
    setTo(to);
    setFrom(from);
  };

  const renderSent = () => (
    <>
      <H2>
        <FormattedMessage {...messages.confirmTitle} />
      </H2>
      <Typography
        use="body2"
        rank="secondary"
        tag="div"
        style={{ maxWidth: 400 }}
      >
        <FormattedMessage {...messages.confirmDetail} />
      </Typography>
      <Button btnType="primary" onClick={_onClose} style={{ float: 'right' }}>
        <FormattedMessage {...messages.confirmDone} />
      </Button>
    </>
  );

  const buttonDisabled = () => {
    return sending || (!exportAll && (!from || !to));
  };

  const renderForm = () => (
    <div style={{ maxHeight: '95vh' }}>
      <H2>
        <FormattedMessage {...messages.title} />
      </H2>
      <Typography use="body2" rank="secondary" tag="div" style={{ width: 500 }}>
        <FormattedMessage
          {...messages.subTitle}
          values={{ count: clientIds.length }}
        />
      </Typography>
      <Spacer spacing="lg" />
      <Typography use="overline" rank="tertiary">
        <FormattedMessage {...messages.range} />
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <PrimaryCheckboxWrapper>
          <Checkbox checked={exportAll} onChange={() => setExportAll(true)}>
            <FormattedMessage {...messages.all} />
          </Checkbox>
        </PrimaryCheckboxWrapper>
        <PrimaryCheckboxWrapper>
          <Checkbox checked={!exportAll} onChange={() => setExportAll(false)}>
            <FormattedMessage {...messages.withinRange} />
          </Checkbox>
        </PrimaryCheckboxWrapper>
      </div>

      {exportAll ? null : <RangePicker setRange={_setRange} />}
      <Spacer spacing="sm" />
      <Typography use="overline" rank="tertiary">
        <FormattedMessage {...messages.options} />
      </Typography>
      <div style={{ display: 'flex', direction: 'row' }}>
        <PrimaryCheckboxWrapper>
          <Checkbox
            checked={!onlyVerified}
            onChange={() => setOnlyVerified(false)}
          >
            <FormattedMessage {...messages.allDiaries} />
          </Checkbox>
        </PrimaryCheckboxWrapper>
        <Spacer spacing="md" />
        <CheckboxWrapper>
          <Checkbox
            checked={onlyVerified}
            onChange={() => setOnlyVerified(true)}
          >
            <FormattedMessage {...messages.verified} />
          </Checkbox>
        </CheckboxWrapper>
      </div>
      <Typography use="overline" rank="tertiary">
        <FormattedMessage {...messages.details} />
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <PrimaryCheckboxWrapper>
          <Checkbox
            checked={withIngredients}
            onChange={() => setWithIngredients(true)}
          >
            <FormattedMessage {...messages.withIngredients} />
          </Checkbox>
        </PrimaryCheckboxWrapper>
        <PrimaryCheckboxWrapper>
          <Checkbox
            checked={!withIngredients}
            onChange={() => setWithIngredients(false)}
          >
            <FormattedMessage {...messages.withDishes} />
          </Checkbox>
        </PrimaryCheckboxWrapper>
      </div>
      <Typography use="overline" rank="tertiary">
        <FormattedMessage {...messages.grouping} />
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <PrimaryCheckboxWrapper>
          <Checkbox checked={aggregate} onChange={() => setAggregate(true)}>
            <FormattedMessage {...messages.aggregate} />
          </Checkbox>
        </PrimaryCheckboxWrapper>
        <PrimaryCheckboxWrapper>
          <Checkbox checked={!aggregate} onChange={() => setAggregate(false)}>
            <FormattedMessage {...messages.single} />
          </Checkbox>
        </PrimaryCheckboxWrapper>
      </div>
      <Spacer spacing="lg" />
      <Button
        unelevated
        onClick={_requestExports}
        style={{
          float: 'right',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        icon={sending ? <CircularProgress size="xsmall" /> : null}
        disabled={buttonDisabled()}
      >
        {
          <FormattedMessage
            {...(sending ? messages.submitting : messages.export)}
          />
        }
      </Button>
    </div>
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={_onClose}
        style={{ maxWidth: '95vw', width: '' }}
      >
        {sent ? renderSent() : renderForm()}
      </Modal>
    </>
  );
}

export default ExportModal;
