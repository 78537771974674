import { createSelector } from 'reselect';
import get from 'lodash/get';
import FETCH_STATUS from '../../../constants/fetchStatus';

const _getRoot = state => get(state, 'foods');
/** (state) */
export const getFoodById = createSelector(
  _getRoot,
  x => get(x, 'byId')
);
/** (state) */
const _getFetchStatus = createSelector(
  _getRoot,
  x => get(x, 'fetchStatus')
);
/** (state, foodId) */
export const getFood = createSelector(
  [getFoodById, (_, foodId) => foodId],
  (byId, foodId) => get(byId, foodId)
);
/** (state, foodId) */
export const getFoodFetchStatus = createSelector(
  [_getFetchStatus, (_, foodId) => foodId],
  (fetchStatus, foodId) => get(fetchStatus, foodId, FETCH_STATUS.NONE)
);
/** (state, foodId) */
export const getFoodMeasures = createSelector(
  getFood,
  food => {
    if (!food) return [];

    return food.measures.map(measure => ({
      unit_measure_id: measure.measureid,
      unit: measure.unit,
      unit_name: measure.name,
      unit_dimension: measure.dimension,
      initialValue: measure.initialvalue,
      conversionFactorValue: measure.conversionFactorValue,
    }));
  }
);
export const getFoodNutrients = createSelector(
  getFood,
  food => get(food, 'nutrients', [])
);

export const makeGetStatusFromId = id => state =>
  id ? _getFetchStatus(state)[id.toString()] : null;

export const makeGetFoodFromId = id => state =>
  id ? getFoodById(state)[id.toString()] : null;

export const makeGetCodeFromId = id => state => {
  const food = makeGetFoodFromId(id)(state);
  return food ? food.code : null;
};

export const makeGetMeasuresFromId = id => state => {
  const food = makeGetFoodFromId(id)(state);
  if (!food) {
    return null;
  }
  return food.measures.map(measure => ({
    unit_measure_id: measure.measureid,
    unit: measure.unit,
    unit_name: measure.name,
    unit_dimension: measure.dimension,
    initialValue: measure.initialvalue,
    conversionFactorValue: measure.conversionFactorValue,
  }));
};

export const makeGetNutrimentsFromId = id => state => {
  const food = makeGetFoodFromId(id)(state);
  // NOT A TYPO: nutrients is return from elastic search
  return food ? food.nutrients : null;
};

export const makeGetEntryFromFoodId = (id, description, entryId) =>
  createSelector(
    [makeGetFoodFromId(id), makeGetMeasuresFromId(id)],
    (food, measures) => ({
      id: entryId,
      quantity: get(measures, '0.initialValue', 0),
      unit: get(measures, '0.unit', null),
      unit_name: get(measures, '0.unit_name', null),
      unit_dimension: get(measures, '0.unit_dimension', null),
      unit_measure_id: get(measures, '0.unit_measure_id', null),
      food_id: id,
      food_description: description,
      nutrients: get(food, 'nutrients', null),
    })
  );

export default {
  makeGetStatusFromId,
  makeGetFoodFromId,
  makeGetCodeFromId,
  makeGetMeasuresFromId,
  makeGetNutrimentsFromId,
};
