import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem } from '../Menu';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const MyMenuItem = styled(MenuItem)`
  color: ${x => x.theme.palette.text.midPrimary};
`;

const IconWrapper = styled.span`
  width: 48px;
  margin-left: -16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function RowIcon({ icon }) {
  return (
    <IconWrapper>
      <FontAwesomeIcon icon={icon} />
    </IconWrapper>
  );
}

export default function ActionRow({
  onClick,
  icon,
  message,
  values = {},
  children,
  width = '125px',
}) {
  return (
    <MyMenuItem onClick={onClick}>
      <RowIcon icon={icon} />
      <span style={{ width }}>
        <FormattedMessage {...message} values={values} />
      </span>
      {children}
    </MyMenuItem>
  );
}
