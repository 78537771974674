import actions from './auth.actions';
import { put, remove, get as getFromStorage } from '../../../utils/storage';
import * as authApi from '../../../api/auth';
import * as accountApi from '../../../api/account';
import get from 'lodash/get';
import { safeLocale } from '../../../utils/misc';

export const loadProfile = () => dispatch => {
  const token = getFromStorage('token');
  if (!token) {
    dispatch(actions.profileLoadUnauthorized());
    return Promise.resolve();
  }
  return accountApi
    .fetchAccount()
    .then(res => dispatch(actions.profileLoadSuccess(res)))
    .then(res => {
      const locale = get(res, 'info.locale');
      if (locale) put('locale', safeLocale(locale));
      return res;
    })
    .catch(err => {
      if (err.type === 'invalid_token') {
        return dispatch(actions.profileLoadUnauthorized());
      } else {
        throw err;
      }
    });
};

const login = credentials => dispatch => {
  dispatch(actions.loginStart());
  return authApi
    .login(credentials)
    .then(res => {
      put('token', res.token);
      put('id', res.id);
      const locale = get(res, 'locale');
      if (locale) put('locale', safeLocale(locale));
      return dispatch(actions.loginSuccess(res));
    })
    .catch(err => {
      dispatch(actions.loginFailure(err));
      throw err;
    });
};
const _makeSignup = apiCallFunction => credentials => dispatch => {
  dispatch(actions.signupStart());
  return apiCallFunction(credentials)
    .then(info => {
      put('token', info.token);
      put('id', info.id);
      dispatch(actions.signupSuccess(info));
    })
    .catch(err => {
      dispatch(actions.signupFailure(err));
      throw err;
    });
};
const signupAndEnroll = _makeSignup(authApi.signupAndEnroll);
const signup = _makeSignup(authApi.signup);

const requestPasswordReset = email => dispatch => {
  return authApi
    .requestPasswordReset(email)
    .then(() => dispatch(actions.passwordResetSent()))
    .catch(err => dispatch(actions.passwordResetFailure(err)));
};

const sendPasswordReset = (token, password) => dispatch => {
  return authApi
    .sendPasswordReset(token, password)
    .then(() => dispatch(actions.passwordResetSuccess()))
    .catch(err => dispatch(actions.executePasswordResetFailure(err)));
};

const logout = () => dispatch => {
  remove('token');
  remove('id');
  dispatch(actions.logout());
};

export default {
  loadProfile,
  login,
  signup,
  signupAndEnroll,
  requestPasswordReset,
  sendPasswordReset,
  logout,
};
