import React from 'react';
import styled from 'styled-components';
import { NavLink as Link, withRouter } from 'react-router-dom';
import { CollapsibleList } from './material/List';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SecondTitle from './SecondTitle';
import { DrawerContent, Drawer } from './material/Drawer';
import { SimpleListItem } from '@rmwc/list';
import { injectIntl } from 'react-intl';

export const LEFT_NAVIGATION_WIDTH = 160;

const _NavRow = ({ message, to, links = [], startOpen, location, intl }) => {
  const hasLinks = !!links.length;
  if (!hasLinks) {
    return (
      <Link to={to}>
        <SimpleListItem
          activated={location.pathname === to}
          text={intl.formatMessage(message)}
        />
      </Link>
    );
  }

  return (
    <CollapsibleList
      defaultOpen={startOpen}
      handle={
        <SimpleListItem
          metaIcon="chevron_right"
          text={intl.formatMessage(message)}
        />
      }
    >
      {links.map(({ url, message }) => (
        <Link to={url} key={url}>
          <SimpleListItem
            activated={location.pathname === url}
            text={intl.formatMessage(message)}
          />
        </Link>
      ))}
    </CollapsibleList>
  );
};
const NavRow = withRouter(injectIntl(_NavRow));

const RetrackButton = styled.div`
  position: absolute;
  right: -10px;
  width: 20px;
  top: 0;
  bottom: 0;
  transition: opacity 0.1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 2;
  background-color: #f5f5f5;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  transition: transform 0.1s ease-in-out;
  transform: scaleX(${x => (x.turn === 'true' ? -1 : 1)}) scaleY(2);
  color: ${x => x.theme.palette.text.primary};
`;

const Nav = styled(Drawer)`
  transition: margin-left 0.1s ease-in-out;
  width: ${LEFT_NAVIGATION_WIDTH}px !important;
  margin-left: ${x =>
    `${x.hide === 'true' ? -(LEFT_NAVIGATION_WIDTH - 10) : 0}px`};
  overflow: visible;
  height: 100%;
  position: relative;
`;

export default function LeftNavigation({
  titleMsg,
  backMsg,
  backTo,
  rows,
  open,
  setOpen,
}) {
  const hide = !open;
  return (
    <Nav hide={`${hide}`}>
      <RetrackButton onClick={() => setOpen(!open)}>
        <Icon icon={['far', 'chevron-left']} turn={`${hide}`} />
      </RetrackButton>
      <SecondTitle title={titleMsg} backTitle={backMsg} backTo={backTo} />
      <DrawerContent>
        {rows.map(({ message, to, icon, links, startOpen = false }) => (
          <React.Fragment
            key={`${message.id}_${(links || ['empty'])
              .map(l => l.url)
              .join('_')}`}
          >
            <NavRow
              message={message}
              icon={icon}
              to={to}
              links={links}
              startOpen={startOpen}
            />
          </React.Fragment>
        ))}
      </DrawerContent>
    </Nav>
  );
}
