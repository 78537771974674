import types from './entries.types';
import { foodDiariesTypes } from '../foodDiaries';
import { combineReducers } from 'redux';
import _ from 'lodash';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case foodDiariesTypes.FETCH_FOOD_DIARY_SUCCESS:
      return {
        ...state,
        ...action.response.entities.entries,
      };
    case types.ADD_ENTRY_SUCCESS:
      return {
        ...state,
        ...action.response.entities.entries,
      };
    case types.DELETE_ENTRY_SUCCESS:
      return _.omit(state, [action.entryId]);
    case types.UPDATE_ENTRY_SUCCESS:
      return {
        ...state,
        ...action.response.entities.entries,
      };
    default:
      return state;
  }
};

export default combineReducers({
  byId,
});
