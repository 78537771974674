import React from 'react';
import styled from 'styled-components';
import WhiteFrame from 'components/WhiteFrame';
import { Switch, Route } from 'react-router-dom';
import values from 'lodash/values';
import Login from './Login';
import Signup from './Signup';
import BeginPasswordReset from './BeginPasswordReset';
import CompletePasswordReset from './CompletePasswordReset';

const MainLayout = styled.div`
  position: absolute;
  padding: 10px;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
`;
const StyledWhiteframe = styled(WhiteFrame)`
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
  padding: 20px 0;
`;
const Content = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;

export const PATHS = {
  LOGIN: '/login',
  SIGNUP: '/signup',
  BEGIN_PASSWORD_RESET: '/begin-password-reset',
  COMPLETE_PASSWORD_RESET: '/complete-password-reset',
};

export default function() {
  return (
    <Switch>
      <Route path={values(PATHS)}>
        <Auth />
      </Route>
    </Switch>
  );
}

function Auth() {
  const renderRoutes = () => (
    <Switch>
      <Route path={PATHS.LOGIN}>
        <Login />
      </Route>
      <Route path={PATHS.SIGNUP}>
        <Signup />
      </Route>
      <Route path={PATHS.BEGIN_PASSWORD_RESET}>
        <BeginPasswordReset />
      </Route>
      <Route path={PATHS.COMPLETE_PASSWORD_RESET}>
        <CompletePasswordReset />
      </Route>
    </Switch>
  );
  return (
    <div>
      <MainLayout>
        <StyledWhiteframe>
          <Content>{renderRoutes()}</Content>
        </StyledWhiteframe>
      </MainLayout>
    </div>
  );
}
