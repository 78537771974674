import types from './meals.types';

export const setCurrentMeal = mealId => ({
  type: types.SET_CURRENT_MEAL,
  mealId,
});

export default {
  setCurrentMeal,
};
