import authTypes from '../modules/auth/auth.types';
import { MODAL_TYPES, SHOW_MODAL } from '../modules/modals/modals';
import reportsTypes from '../modules/reports/reports.types';
import reportViewTypes from '../modules/UI/reportView/reportView.types';
import reportTemplatesTypes from '../modules/reportTemplates/reportTemplates.types';
import reportSettingsTypes from '../modules/reportSettings/reportSettings.types';
import get from 'lodash/get';
import clientViewTypes from '../modules/UI/clientView/clientView.types';
import { foodDiariesTypes } from '../modules/foodDiaries';

/* This middleware makes it easy to log actions for analytics, without
   having to "polute" the business code */
const analyticsMiddleware = store => next => action => {
  const track = (event, properties) => {
    if (window.analytics) {
      window.analytics.track('DIETITIAN_' + event, properties);
    } else {
      console.log(`Segment track: ${event}`, properties);
    }
  };
  const identify = (id, properties) => {
    const identifyId = `dietitian_${id}`;
    if (window.analytics) {
      window.analytics.identify(identifyId, properties);
    } else {
      console.log(`Segment identify: ${identifyId}`, properties);
    }
  };

  switch (action.type) {
    case authTypes.PROFILE_LOAD_SUCCESS:
      const { id, full_name, email, locale } = action.info;
      identify(id, {
        name: full_name,
        email,
        locale,
      });
      break;
    case SHOW_MODAL:
      if (action.modalType === MODAL_TYPES.PICK_EXPORT_TEMPLATE) {
        track('OPEN_EXPORT_MODAL');
      }
      break;
    case reportsTypes.CREATE_REPORT_SUCCESS:
      track('CREATE_REPORT');
      break;
    case reportsTypes.DELETE_REPORT_SUCCESS:
      track('DELETE_REPORT');
      break;
    case reportViewTypes.RESUME_REPORT:
      track('RESUME_REPORT');
      break;
    case reportViewTypes.ADD_WIDGET:
      const widgetType = get(action, 'payload.type');
      track('ADD_WIDGET', { type: widgetType });
      break;

    case reportTemplatesTypes.CREATE_REPORT_TEMPLATE_SUCCESS:
      track('CREATE_TEMPLATE');
      break;
    case reportTemplatesTypes.UPDATE_REPORT_TEMPLATE_SUCCESS:
      track('UPDATE_TEMPLATE');
      break;
    case reportTemplatesTypes.DELETE_REPORT_TEMPLATES_SUCCESS:
      track('DELETE_TEMPLATE');
      break;

    case reportsTypes.DOWNLOAD_REPORT:
      track('DOWNLOAD_REPORT');
      break;
    case reportsTypes.VIEW_REPORT:
      track('VIEW_REPORT');
      break;
    case reportsTypes.SEND_REPORT_SUCCESS:
      track('SEND_REPORT');
      break;

    case reportSettingsTypes.UPDATE_REPORT_LOGO_SUCCESS:
      track('SET_LOGO');
      break;
    case reportSettingsTypes.UPDATE_REPORT_SETTING_SUCCESS:
      if (action.theme) {
        track('SET_REPORT_THEME');
      }
      break;
    case clientViewTypes.SET_COMPARE_MODE:
      track('TOGGLE_COMPARE_MODE', { value: action.isInCompareMode });
      break;

    case foodDiariesTypes.SET_DIARY_VERIFY_SUCCESS:
      track('TOGGLE_DIARY_VERIFY', { value: action.isVerified });
      break;
    default:
      break;
  }
  return next(action);
};

export default analyticsMiddleware;
