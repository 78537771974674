import React from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import './scrollbar.css';
import ListEntry from '../../../../components/ListEntry';
import Color from 'color';
import take from 'lodash/take';
import ViewMore from 'components/ViewMore';

const messages = defineMessages({
  noEntry: { id: 'entries.noEntry' },
  viewMore: { id: 'entries.viewMore' },
  viewLess: { id: 'entries.viewLess' },
});

const Wrapper = styled.tbody`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  border-right: 1px solid ${props => props.theme.palette.canvas};
`;
const bg = Color('black')
  .fade(0.97)
  .toString();
const EntryWrapper = styled.tr`
  width: 100%;
  background-color: ${props => (props.even ? 'white' : bg)};
`;
const EmptyList = styled.tr`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: ${props => props.theme.palette.text.tertiary};
`;

const SHOW_COUNT = 2;

class Entry extends React.PureComponent {
  _onEdit = () => {
    const { editEntry, entry, mealId } = this.props;
    editEntry(entry.id, mealId);
  };

  _onDelete = () => {
    const { deleteEntry, entry, mealId } = this.props;
    deleteEntry(entry.id, mealId);
  };

  render() {
    const { entry } = this.props;
    return (
      <ListEntry
        entry={entry}
        onEdit={this._onEdit}
        onDelete={this._onDelete}
      />
    );
  }
}

class Entries extends React.PureComponent {
  state = { showAll: false };

  _toggleShowAll = () =>
    this.setState({
      showAll: !this.state.showAll,
    });

  renderEmptyState = () => {
    const { isLoading } = this.props;
    return (
      <EmptyList className="mdc-typography--subtitle1">
        <td>
          {!isLoading ? <FormattedMessage {...messages.noEntry} /> : null}
        </td>
      </EmptyList>
    );
  };

  renderEntries = () => {
    const { entries, deleteEntry, editEntry, mealId } = this.props;
    const { showAll } = this.state;

    const visibleEntries = showAll ? entries : take(entries, SHOW_COUNT);

    return visibleEntries.map((entry, i) => (
      <EntryWrapper key={entry.id} even={i % 2}>
        <Entry
          entry={entry}
          deleteEntry={deleteEntry}
          editEntry={editEntry}
          mealId={mealId}
        />
      </EntryWrapper>
    ));
  };

  renderLoader = () => {
    const { entries } = this.props;
    if (entries.length <= SHOW_COUNT) return null;

    return (
      <tr style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <td>
          <ViewMore
            open={this.state.showAll}
            showMessage={messages.viewMore}
            closeMessage={messages.viewLess}
            remaining={entries.length - SHOW_COUNT}
            onClick={this._toggleShowAll}
          />
        </td>
      </tr>
    );
  };

  render() {
    return (
      <table style={{ width: '100%' }}>
        <Wrapper>
          {!isEmpty(this.props.entries)
            ? this.renderEntries()
            : this.renderEmptyState()}
          {this.renderLoader()}
        </Wrapper>
      </table>
    );
  }
}

export default Entries;
