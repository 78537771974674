import React from 'react';
import DayScale from '../DayScale';
import DayCharts from '../DayCharts';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
`;

class ChartContent extends React.PureComponent {
  render() {
    const { diaries, startHour, endHour, setStartHour } = this.props;
    return (
      <Frame>
        <DayScale
          startHour={startHour}
          endHour={endHour}
          setStartHour={setStartHour}
        />
        {diaries.map(diary => {
          if (!diary) return null; // Prevent crash on transient state.
          const { meal_groups, diary_date, id } = diary;
          return (
            <DayCharts
              key={id}
              id={id}
              groups={meal_groups}
              date={diary_date}
              startHour={startHour}
              endHour={endHour}
            />
          );
        })}
      </Frame>
    );
  }
}

export default ChartContent;
