import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const NavButton = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  color: ${x => x.theme.palette.text.secondary};
`;

export default NavButton;
