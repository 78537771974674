import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import DeleteButton from 'components/DeleteButton';
import NutrientTd from '../NutrientTd';
import { NUTRIENT_COLORS } from 'constants/nutriments';
import Color from 'color';
import AddEntryButton from '../../../../components/AddEntryButton';
import { Tooltip } from 'react-tippy';
import { injectIntl } from 'react-intl';
import TimeFormat from 'components/TimeFormat';
import Typography from 'components/material/Typography';

const SUBHEADER_HEIGHT = 42;
const HEADER_PADDING = 18;

const MealTitleTd = styled.td`
  font-size: 14px;
  color: ${props => props.theme.palette.text.primary};
  text-align: left;
  align-items: center;
  width: 290px;
`;

const ValueTd = styled(NutrientTd)`
  white-space: nowrap;
  padding: 10px;
`;

const NutrimentHeaderTd = styled(ValueTd)`
  border-radius: ${props => (!props.idx ? '4px 0 0 4px' : '0')};
  padding: 0;
  color: white;
  &:hover {
    cursor: pointer;
  }
`;

const TimeContent = styled.div`
  align-items: center;
  display: flex;
  margin-left: 20px;
`;

const Time = styled.div`
  margin-left: -7.5px;
  min-height: 45px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.palette.secondary};

  transition: opacity 0.2s ease-in-out;
`;

const deleteButtonStyle = hovered => ({
  display: 'inline-block',
  color: 'white',
  opacity: hovered ? 1 : 0,
});

const HeadersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0;
`;

const HeaderText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 2px;
  position: relative;
  transition: all 0.125s ease-in-out;
  font-weight: 500;

  &:hover {
    transform: scale(1.04);
  }
`;

const SubNutrientHeader = styled.div`
  flex: 1;
  position: relative;
  min-width: 86px;
  padding: ${HEADER_PADDING}px;
`;

const SubheaderWrapper = styled.div`
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: -${SUBHEADER_HEIGHT}px;
  height: ${SUBHEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-color: ${props =>
    Color(props.theme.palette[props.color])
      .saturate(0.1)
      .lighten(props.color === 'black' ? 1 : 0.1)
      .hsl()
      .string()};
`;

class MealBodyHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hovered: null,
    };

    this.header = undefined;

    this.sticky = false;
    this.headerTop = null;
    this.lastKnownScrollPosition = 0;
    this.ticking = false;
  }

  updateHeaderOffset = y => {
    if (this.sticky) {
      this.header.style.transform = `translateY(${y - this.headerTop}px)`;
    }

    if (y > this.headerTop && !this.sticky) {
      this.sticky = true;
    } else if (y < this.headerTop && this.sticky) {
      this.sticky = false;
      this.header.style = '';
    }
  };

  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.nutrimentsToShow.length ===
        this.props.nutrimentsToShow.length ||
      !this.props.showNutriments
    )
      return;

    this.scrollBeforeUpdate = this.header.getBoundingClientRect().top;
  }

  componentDidUpdate(nextProps, nextState) {
    if (
      nextProps.nutrimentsToShow.length ===
        this.props.nutrimentsToShow.length ||
      !this.props.showNutriments
    )
      return;

    setTimeout(() => {
      const afterUpdate = this.header.getBoundingClientRect().top;
      const difference = afterUpdate - this.scrollBeforeUpdate;
      if (difference) {
        this.headerTop += difference;
        this.updateHeaderOffset(this.lastKnownScrollPosition);
      }
    }, 50);
  }

  _calculateHeaderTop = currentScroll => {
    const boundindClient = this.header.getBoundingClientRect();
    this.headerTop = boundindClient.top + currentScroll;
  };

  _listener = e => {
    this.lastKnownScrollPosition = e.target.scrollTop;
    // Optimization based on: https://developer.mozilla.org/en-US/docs/Web/Events/scroll
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.updateHeaderOffset(this.lastKnownScrollPosition);
        this.ticking = false;
      });

      this.ticking = true;
    }
  };

  componentDidMount() {
    if (!this.props.showNutriments) return;

    var scrolledDiv = document.getElementById('keenoa_page_content');
    this._calculateHeaderTop(scrolledDiv.scrollTop);
    scrolledDiv.addEventListener('scroll', this._listener, true);
  }
  componentWillUnmount() {
    var scrolledDiv = document.getElementById('keenoa_page_content');
    scrolledDiv.removeEventListener('scroll', this._listener);
  }

  setHover(val) {
    this.setState({
      hovered: val,
    });
  }

  _onRemove(n) {
    if (this.props.nutrimentsToShow.length > 1 || n.parent) {
      this.props.onRemoveColumn(n); // We always want at least one
    }
  }

  _getNutrimentHeader = (n, isTop) => {
    const name = this.props.intl.formatMessage(n.label);
    const unit = n.unit ? `, ${n.unit}` : '';
    const text = name + unit;
    return (
      <SubNutrientHeader
        style={
          isTop
            ? {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }
            : {}
        }
        key={n.id}
        onMouseEnter={() => this.setHover(n.id)}
        onMouseLeave={() => this.setHover(null)}
      >
        <HeaderText>
          <Tooltip
            position="bottom"
            title={text}
            trigger="mouseenter"
            animateFill={false}
            interactive={true}
            duration={105}
            delay={500}
            arrow={false}
            theme="light"
            animtation="fade"
            interactiveBorder={0}
          >
            {text}
          </Tooltip>
        </HeaderText>
        <DeleteButton
          onClick={() => this._onRemove(n)}
          styles={deleteButtonStyle(this.state.hovered === n.id)}
        />
      </SubNutrientHeader>
    );
  };

  _renderTimes = mealName => {
    if (!mealName) return null;
    const parts = mealName.split(' - ');
    return (
      <>
        <TimeFormat time={parts[0]} />
        {parts.length > 1 ? ' - ' : null}
        {parts.length > 1 ? <TimeFormat time={parts[1]} /> : null}
      </>
    );
  };

  _renderHeader = () => {
    const {
      mealName,
      showNutriments,
      nutrimentsToShow,
      onClick,
      mealIds,
      innerRef,
    } = this.props;

    return (
      <tr
        ref={x => {
          const node = ReactDOM.findDOMNode(x);
          if (node) {
            this.header = node;
          }
          if (innerRef) {
            innerRef(x);
          }
        }}
      >
        <MealTitleTd>
          {!mealName ? null : (
            <Typography use="subtitle1" rank="secondary" tag="div">
              <Time
                ref={x => {
                  const node = ReactDOM.findDOMNode(x);
                  if (node) {
                    this.time = node;
                  }
                }}
              >
                <TimeContent>{this._renderTimes(mealName)}</TimeContent>
                <AddEntryButton
                  meals={mealIds}
                  style={{ marginBottom: 0, alignSelf: 'center' }}
                />
              </Time>
            </Typography>
          )}
        </MealTitleTd>
        {nutrimentsToShow.map((n, i) =>
          !showNutriments ? (
            <ValueTd key={n.id} idx={i} nutrient={n} />
          ) : (
            <NutrimentHeaderTd
              className="mdc-typography--body1"
              key={n.id}
              idx={i}
              onClick={() => onClick(n)}
              nutrient={n}
              isHeader={true}
              space={SUBHEADER_HEIGHT}
            >
              <HeadersWrapper>
                {this._getNutrimentHeader(n, true)}

                {n.children && n.isActive ? (
                  <SubheaderWrapper
                    color={NUTRIENT_COLORS[n.id] || 'neutral'}
                    className="mdc-typography--body1"
                  >
                    {n.children.map(this._getNutrimentHeader)}
                    <SubNutrientHeader>Total</SubNutrientHeader>
                  </SubheaderWrapper>
                ) : null}
              </HeadersWrapper>
            </NutrimentHeaderTd>
          )
        )}
      </tr>
    );
  };

  render() {
    return this._renderHeader();
  }
}

export default injectIntl(MealBodyHeader);
