import React from 'react';
import styled from 'styled-components';
import { LinearProgress } from 'components/loaders/LinearProgress';

const Wrapper = styled.div`
  --mdc-theme-primary: ${x => x.theme.palette.primarySaturated};

  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const ExportLoading = () => (
  <Wrapper>
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        paddingTop: 50,
        backgroundColor: 'white',
      }}
    >
      <LinearProgress />
    </div>
    <div style={{ backgroundColor: 'white', width: 310, height: '100%' }} />
    <div style={{ backgroundColor: '#eff0f1', flex: 1 }} />
  </Wrapper>
);
