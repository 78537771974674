import React from 'react';
import ReactDOM from 'react-dom';
import Avatar from 'components/Avatar';
import EmojiButton from 'components/EmojiButton';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Tooltip } from 'react-tippy';

const messages = defineMessages({
  send: { id: 'messages.send' },
  sendHelp: { id: 'messages.sendHelp' },
  sendPlaceholder: { id: 'messages.sendPlaceholder' },
  paragraphHelp: { id: 'messages.paragraphHelp' },
});

const MAX_CHAR_COUNT = 75;

const TextArea = styled(TextareaAutosize)`
  resize: none;
  flex: 1;
  border: none;
  color: ${x => x.theme.palette.primary};
  font-weight: 400;
`;

const TextAreaStyle = styled.div`
  background-color: white;
  border: 1px solid ${props => props.theme.palette.lightGray};
  border-radius: 20px;
  flex: 1;
  margin-left: 5px;
  display: flex;
  padding: 5px 10px;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 8px 0 0;
`;

const Send = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-left: auto;
  font-weight: 600;
  padding: 5px;
  color: ${props =>
    props.active
      ? props.theme.palette.primary1
      : props.theme.palette.lightGray};
  transition: color 0.15s ease-in-out;

  ${props =>
    props.active
      ? `&:hover {
    filer: contrast(1.3);
    cursor: pointer;
  }`
      : ''};
`;

class CommentBox extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      multiLines: false,
      twoLine: false,
    };
  }

  _setTwoLine = ({ text, multiLines }) => {
    const t = text || this.state.text;
    const m = multiLines !== undefined ? multiLines : this.state.multiLines;
    this.setState({
      twoLine: m || t.length > MAX_CHAR_COUNT,
    });
  };

  _setText = text => {
    this.setState({
      text,
    });
    this._setTwoLine({ text });
  };

  _onEmoji = e => {
    const { text } = this.state;
    this._setText(text + e);
  };

  _onType = e => {
    const value = e.target.value;
    this._setText(value);
  };

  _onKeyPress = e => {
    if (e.key !== 'Enter' || e.nativeEvent.shiftKey) return;

    e.preventDefault();
    this._sendMessage();
  };

  _sendMessage = () => {
    const { text } = this.state;
    const { meals } = this.props;
    if (!text) return;

    this._setText('');
    this.props.sendComment(meals[0], text);
  };
  _onResize = (height, { rowCount }) => {
    const node = ReactDOM.findDOMNode(this.textArea);
    if (!node) return;

    const multiLines = rowCount > 1;
    this.setState({
      multiLines,
    });
    this._setTwoLine({ multiLines });
  };

  render() {
    const { intl } = this.props;
    const active = this.state.text.trim().length;
    return (
      <Wrapper onKeyPress={this._onKeyPress}>
        <Avatar size={35} />
        <TextAreaStyle column={this.state.twoLine}>
          <TextArea
            spellCheck={true}
            placeholder={intl.formatMessage(messages.sendPlaceholder)}
            className={'mdc-typography--body2'}
            value={this.state.text}
            onChange={this._onType}
            ref={x => (this.textArea = x)}
            onHeightChange={this._onResize}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <EmojiButton onEmoji={this._onEmoji} />
            <Tooltip
              position="bottom"
              disabled={!active}
              html={
                <div style={{ textAlign: 'left', fontSize: 14 }}>
                  <div>{intl.formatMessage(messages.sendHelp)}</div>
                  <div>{intl.formatMessage(messages.paragraphHelp)}</div>
                </div>
              }
              trigger="mouseenter"
            >
              <Send active={active} onClick={this._sendMessage}>
                <FormattedMessage {...messages.send} />
              </Send>
            </Tooltip>
          </div>
        </TextAreaStyle>
      </Wrapper>
    );
  }
}

export default injectIntl(CommentBox);
