import { combineReducers } from 'redux';
import { makeById } from 'utils/standardReducers';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import types from './userFoods.types';
import { arrayOfUserFoods, userFood } from 'redux/schemas';
import { normalize } from 'normalizr';
import {
  createUserFoodApi,
  deleteUserFoodApi,
  fetchUserFoodApi,
  fetchUserFoodsApi,
  updateUserFoodApi,
} from 'api/foods';

export const userFoodIds = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_USER_FOODS_SUCCESS:
      return action.response.result;
    case types.CREATE_USER_FOOD_SUCCESS:
      return [action.response.result, ...state];
    case types.DELETE_USER_FOOD_SUCCESS:
      return state.filter(x => x !== action.userFoodId);
    default:
      return state;
  }
};

export default combineReducers({
  byId: makeById('userFoods'),
  userFoodIds,
});

/// SELECTORS
const _getRoot = state => state.userFoods;
export const getById = createSelector(_getRoot, x => x.byId);
export const getUserFoodIds = createSelector(_getRoot, x => x.userFoodIds);

/** (state) */
export const getUserFoods = createSelector(
  [getUserFoodIds, getById],
  (ids, byId) => ids.map(id => get(byId, id))
);

/** (state, userFoodId) */
export const getUserFood = createSelector(
  [getById, (_, id) => id],
  (byId, id) => get(byId, id, null)
);

/** (state, userFoodId) */
export const getUserFoodName = createSelector(getUserFood, food =>
  get(food, 'name')
);

/** (state, userFoodId) */
export const getUserFoodServing = createSelector(getUserFood, food =>
  get(food, 'serving')
);

/** (state, userFoodId) */
export const getUserFoodServingName = createSelector(getUserFoodServing, food =>
  get(food, 'name')
);

/** (state, userFoodId) */
export const getUserFoodServingAmount = createSelector(
  getUserFoodServing,
  food => get(food, 'amount')
);

/** (state, userFoodId) */
export const getUserFoodServingQuantity = createSelector(
  getUserFoodServing,
  food => get(food, 'quantity')
);

/** (state, userFoodId) */
export const getUserFoodServingQuantityUnit = createSelector(
  getUserFoodServing,
  food => get(food, 'unit')
);

/** (state, userFoodId) */
export const getUserFoodNutrients = createSelector(getUserFood, food =>
  get(food, 'nutrients')
);

/// ACTION
export const fetchUserFoods = () => ({
  types: [
    types.FETCH_USER_FOODS_REQUEST,
    types.FETCH_USER_FOODS_SUCCESS,
    types.FETCH_USER_FOODS_FAILURE,
  ],
  callAPI: () =>
    fetchUserFoodsApi().then(res => normalize(res, arrayOfUserFoods)),
});
export const createUserFood = ({ name, serving, nutrients, permissions }) => ({
  types: [
    types.CREATE_USER_FOOD_REQUEST,
    types.CREATE_USER_FOOD_SUCCESS,
    types.CREATE_USER_FOOD_FAILURE,
  ],
  callAPI: () =>
    createUserFoodApi({ name, serving, nutrients, permissions }).then(res =>
      normalize(res, userFood)
    ),
});
export const fetchUserFood = userFoodId => ({
  types: [
    types.FETCH_USER_FOOD_REQUEST,
    types.FETCH_USER_FOOD_SUCCESS,
    types.FETCH_USER_FOOD_FAILURE,
  ],
  callAPI: () =>
    fetchUserFoodApi(userFoodId).then(res => normalize(res, userFood)),
  payload: {
    userFoodId,
  },
});
export const deleteUserFood = userFoodId => ({
  types: [
    types.DELETE_USER_FOOD_REQUEST,
    types.DELETE_USER_FOOD_SUCCESS,
    types.DELETE_USER_FOOD_FAILURE,
  ],
  callAPI: () => deleteUserFoodApi(userFoodId),
  payload: {
    userFoodId,
  },
});
export const updateUserFood = (
  foodId,
  { name, serving, nutrients, permissions }
) => ({
  types: [
    types.UPDATE_USER_FOOD_REQUEST,
    types.UPDATE_USER_FOOD_SUCCESS,
    types.UPDATE_USER_FOOD_FAILURE,
  ],
  callAPI: () =>
    updateUserFoodApi(foodId, {
      name,
      serving,
      nutrients,
      permissions,
    }).then(res => normalize(res, userFood)),
  payload: {
    foodId,
    payload: {
      name,
      serving,
      nutrients,
      permissions,
    },
  },
});
