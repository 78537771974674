import React from 'react';
import NutrientsSumRow from '../NutrientsSumRow';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  min: {
    id: 'foodLog.compareMin',
  },
});

class CompareMin extends React.PureComponent {
  render() {
    const { sum, nutrients } = this.props;
    return (
      <tbody>
        <NutrientsSumRow
          top
          nutrimentsToShow={nutrients}
          sum={sum}
          message={messages.min}
        />
      </tbody>
    );
  }
}

export default CompareMin;
