import React, { useState } from 'react';
import styled from 'styled-components';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import { General, Billing } from '.';
import LeftNavigation from 'components/LeftNavigation';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  title: { id: 'account.title', defaultMessage: 'Account' },
  back: { id: 'account.back', defaultMessage: 'Back home' },
  general: { id: 'account.general', defaultMessage: 'General' },
  billing: { id: 'account.billing', defaultMessage: 'Billing' },
});

const Wrapper = styled.div`
  flex: 1;
  overflow-y: auto !important;
`;

const Account = () => {
  const match = useRouteMatch();
  const [open, setOpen] = useState(true);
  const intl = useIntl();

  const rows = [
    {
      message: messages.general,
      icon: 'user-circle',
      to: `${match.url}/general`,
    },
    {
      message: messages.billing,
      icon: 'credit-card',
      to: `${match.url}/billing`,
    },
  ];

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex' }}>
      <LeftNavigation
        titleMsg={intl.formatMessage(messages.title)}
        backMsg={messages.back}
        backTo={'/clients'}
        rows={rows}
        open={open}
        setOpen={setOpen}
      />
      <Wrapper open={open}>
        <Switch>
          <Route path={`${match.path}/general`}>
            <General />
          </Route>
          <Route path={`${match.path}/billing`}>
            <Billing />
          </Route>
          <Route path={`${match.path}`}>
            <Redirect to={`${match.path}/general`} />
          </Route>
        </Switch>
      </Wrapper>
    </div>
  );
};

export default Account;
