import React, { useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Twemoji } from 'react-emoji-render';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import IconButton from './forms/IconButton';
import Typography from './material/Typography';
import AutosizeInput from 'react-input-autosize';
import { useState } from 'react';
import Spacer from 'components/Spacer';

const TITLE_WIDTH = 132;

const messages = defineMessages({
  back: { id: 'secondTitle.back' },
});

const StyledTab = styled.div`
  padding: 0 15px;
  display: flex;
  justify-content: center;
  color: ${x => x.theme.palette.text.primary};
`;

const LoadingBox = styled.div`
  width: ${TITLE_WIDTH}px;
  height: 30px;
  border-radius: 5px;
  padding-bottom: 2px;
  background-color: #f7f7f7;
`;

const Back = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 0;
  cursor: pointer;
  color: ${x => x.theme.palette.primary1};
`;

const BackIcon = styled(FontAwesomeIcon)`
  font-size: 10px;

  margin-right: 5px;
`;

function GrowingInput({ value, onChange, placeholder }) {
  const [focused, setFocused] = useState(false);
  const [val, setVal] = useState(value);
  const theme = useTheme();

  useEffect(() => {
    if (val !== value) {
      setVal(value);
    }
  }, [value]);

  const onBlur = () => {
    setFocused(false);
    if (value !== val) onChange(val);
  };
  const onFocus = () => {
    setFocused(true);
  };

  return (
    <AutosizeInput
      value={val}
      placeholder={placeholder}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={e => setVal(e.target.value)}
      autoFocus={false}
      inputStyle={{
        fontFamily: 'roboto',
        fontSize: 20,
        fontWeight: 500,
        color: theme.palette.text.midPrimary,
        border: focused ? `2px solid ${theme.palette.primary1}` : 'none',
        borderRadius: 6,
      }}
    />
  );
}

function SecondTitle({
  title,
  titlePlaceholder,
  onClick,
  backTitle,
  backTo,
  icon,
  onEdit,
}) {
  const stacked = backTo && backTitle;
  const intl = useIntl();
  return (
    <StyledTab
      style={{
        flexDirection: stacked || !backTo ? 'column' : 'row',
        alignItems: stacked || !backTo ? 'flex-start' : 'center',
      }}
    >
      {backTo ? (
        backTitle ? (
          <Back to={backTo}>
            <BackIcon icon={['far', 'chevron-left']} />
            {intl.formatMessage(backTitle || messages.back)}
          </Back>
        ) : (
          <Link to={backTo}>
            <IconButton icon={'close'} style={{ color: 'rgba(0,0,0,0.61)' }} />
          </Link>
        )
      ) : (
        <Spacer spacing="lg" />
      )}
      {title || onEdit ? (
        <Typography
          onClick={onClick}
          use="headline6"
          rank="midPrimary"
          style={{
            width: TITLE_WIDTH,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {onEdit ? (
            <GrowingInput
              value={title}
              onChange={onEdit}
              placeholder={titlePlaceholder}
            />
          ) : (
            <Twemoji style={{ whiteSpace: 'nowrap' }} text={title} />
          )}
          {!icon ? null : (
            <FontAwesomeIcon
              icon={icon}
              style={{ marginLeft: 5, opacity: 0.83 }}
            />
          )}
        </Typography>
      ) : (
        <LoadingBox />
      )}
    </StyledTab>
  );
}
export default SecondTitle;
