export const CLIENT_STATUS = {
  PENDING: 'pending',
  ACTIVE: 'accepted',
  INACTIVE: 'inactive',
  REVOKED: 'revoked',
  FICTIVE: 'fictive',
  ARCHIVED: 'archived',
  DECLINED: 'declined',
};

export const CLIENT_ACTIONS = {
  ARCHIVE: 'archive',
  REMOVE: 'remove',
  RE_INVITE: 're-invite',
  UNARCHIVE: 'unarchive',
  EXPORT: 'export',
};

export const STATUS_FILTERS = {
  ALL: 'all',
  ALL_BUT_ARCHIVED: 'all_but_archived',
  ACTIVE: 'active',
  PENDING: 'pending',
  ARCHIVED: 'archived',
};

export const COURSES_FILTERS = {
  ALL: 'all',
};

export const STATUS_FILTER_NAME = 'status_filter';
export const COURSES_FILTER_NAME = 'courses_filter';
export const STUDIES_FILTER_NAME = 'studies_filter';

export const STUDIES_FILTERS = {
  ALL: 'all',
};
