import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ClientList from './ClientList';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';
import { clientsSelectors, clientsOperations } from 'redux/modules/clients';
import {
  getSortColumn,
  getSortOrder,
  setSortColumn,
  invertSortOrder,
} from 'redux/modules/UI/clientsView';
import { setOnboardRun } from 'redux/modules/account/account.operations';
import { ONBOARD_IDS } from '../../../../constants';
import { getCanRunFictiveClient } from 'redux/modules/account/onboard.selectors';
import { getClientsColumns } from './Columns';
import {
  getDietitianIsInstructor,
  getDietitianIsResearcher,
} from 'redux/modules/account/account.selectors';
import { fetchStudies, getStudiesFetchStatus } from 'redux/modules/studies';
import FETCH_STATUS from 'constants/fetchStatus';

function ClientListContainer(props) {
  const {
    fetchClients,
    isResearcher,
    studiesFetchStatus,
    fetchStudies,
  } = props;
  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    if (!isResearcher) return;

    if (studiesFetchStatus === FETCH_STATUS.NONE) {
      fetchStudies();
    }
  }, [isResearcher, studiesFetchStatus]);

  return <ClientList {...props} />;
}

const mapStateToProps = state => ({
  clients: clientsSelectors.getVisibleClients(state),
  isFetching: clientsSelectors.getIsFetching(state),
  sortColumn: getSortColumn(state),
  sortOrder: getSortOrder(state),
  canRunOnboard: getCanRunFictiveClient(state),
  columns: getClientsColumns({
    isResearcher: getDietitianIsResearcher(state),
    isInstructor: getDietitianIsInstructor(state),
  }),
  isResearcher: getDietitianIsResearcher(state),
  studiesFetchStatus: getStudiesFetchStatus(state),
});

const mapDispatchToProps = dispatch => ({
  onAddClientClick: () => dispatch(openModal(MODAL_TYPES.NEW_CLIENT)),

  fetchClients: () => dispatch(clientsOperations.fetchClients()),
  fetchStudies: () => dispatch(fetchStudies()),

  selectSortColumn: col => dispatch(setSortColumn(col)),
  invertSortOrder: () => dispatch(invertSortOrder()),
  setFictiveClientRun: () =>
    dispatch(setOnboardRun(ONBOARD_IDS.FICTIVE_CLIENT)),
});

ClientListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientListContainer);

export default ClientListContainer;
