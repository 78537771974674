import { connect } from 'react-redux';
import { createUserFood } from 'redux/modules/userFoods';
import { UserFoodEditor } from '../UserFoodEditor';

const mapDispatchToProps = dispatch => ({
  onSave: (name, serving, nutrients, permissions) =>
    dispatch(createUserFood({ name, serving, nutrients, permissions })),
});

export default connect(null, mapDispatchToProps)(UserFoodEditor);
