import React from 'react';
import NutrientsSumRow from '../NutrientsSumRow';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  coefVar: { id: 'foodLog.coefVar' },
  coefVarHelp: { id: 'foodLog.coefVarHelp' },
});

class CompareCoefVar extends React.PureComponent {
  render() {
    const { sum, nutrients } = this.props;
    return (
      <tbody>
        <NutrientsSumRow
          top
          nutrimentsToShow={nutrients}
          sum={sum}
          message={messages.coefVar}
          helpMessage={messages.coefVarHelp}
          percentage={true}
        />
      </tbody>
    );
  }
}

export default CompareCoefVar;
