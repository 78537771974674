import React from 'react';
import styled from 'styled-components';
import selectedFoodsUtils from 'utils/selectedFoods';
import { FRACTION_OPTIONS } from 'constants/fractions';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HORIZONTAL_PADDING = 16;

const EntryWrapper = styled.td`
  display: flex;
  align-items: center;
  justify-content: 'flex-start';
  min-width: 300px;
`;

const TextSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: -20px;

  padding: 10px 0 10px ${HORIZONTAL_PADDING}px;
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.palette.primary1};
  }
`;

const FoodDescription = styled.div`
  text-align: left;
`;

const FoodQuantity = styled.div`
  text-align: left;
  color: ${props => props.theme.palette.text.secondary};

  ${TextSection}:hover & {
    color: ${props => props.theme.palette.primary1};
  }
`;

const DeleteSection = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
  width: 60px;
  transition: color 0.1s ease-in-out;
  color: ${props => props.theme.palette.text.secondary};
  opacity: 0;

  &:hover {
    opacity: 1;
    transform: scale(1.3);
    cursor: pointer;
    color: ${props => props.theme.palette.error};
  }

  ${EntryWrapper}:hover & {
    opacity: 1;
  }
`;

const EditIcon = styled(FontAwesomeIcon)`
  color: transparent;
  ${TextSection}:hover & {
    color: ${props => props.theme.palette.primary1};
  }
`;

class ListEntry extends React.PureComponent {
  _onDelete = e => {
    e.stopPropagation();
    this.props.onDelete();
  };

  _onEdit = e => {
    e.stopPropagation();
    this.props.onEdit();
  };

  _getFormattedQuantity = () => {
    const { entry } = this.props;
    const totalQtyToDisplay = selectedFoodsUtils.formatQuantityInfo(
      parseInt(entry.quantity, 10),
      selectedFoodsUtils.getFractionOptionFromQuatity(
        entry.quantity,
        FRACTION_OPTIONS
      ).label
    );
    const unitToDisplay = selectedFoodsUtils.formatUnitInfoWithDimension(
      entry.unit,
      entry.unit_name,
      entry.unit_dimension
    );

    let formatted = `${totalQtyToDisplay} ${unitToDisplay}`;
    const calories = get(entry, 'calories');
    if (calories) {
      formatted = `${formatted}    |    ${Math.round(calories)} cal`;
    }
    return formatted;
  };

  render() {
    return (
      <EntryWrapper>
        <TextSection onClick={this._onEdit}>
          <FoodDescription className="mdc-typography--subtitle2">
            {capitalize(get(this.props, 'entry.food_description'))}
          </FoodDescription>
          <FoodQuantity className="mdc-typography--body2">
            {this._getFormattedQuantity()} <EditIcon icon={['far', 'edit']} />
          </FoodQuantity>
        </TextSection>
        <DeleteSection
          onClick={this._onDelete}
          className="mdc-typography--body1"
        >
          <FontAwesomeIcon icon={['far', 'trash']} />
        </DeleteSection>
      </EntryWrapper>
    );
  }
}

export default ListEntry;
