export const pre = 'keenoa/billing/';

export default {
  FETCH_INVOICES_REQUEST: `${pre}FETCH_INVOICES_REQUEST`,
  FETCH_INVOICES_SUCCESS: `${pre}FETCH_INVOICES_SUCCESS`,
  FETCH_INVOICES_FAILURE: `${pre}FETCH_INVOICES_FAILURE`,

  FETCH_SUBSCRIPTIONS_REQUEST: `${pre}FETCH_SUBSCRIPTIONS_REQUEST`,
  FETCH_SUBSCRIPTIONS_SUCCESS: `${pre}FETCH_SUBSCRIPTIONS_SUCCESS`,
  FETCH_SUBSCRIPTIONS_FAILURE: `${pre}FETCH_SUBSCRIPTIONS_FAILURE`,

  FETCH_SOURCES_REQUEST: `${pre}FETCH_SOURCES_REQUEST`,
  FETCH_SOURCES_SUCCESS: `${pre}FETCH_SOURCES_SUCCESS`,
  FETCH_SOURCES_FAILURE: `${pre}FETCH_SOURCES_FAILURE`,

  ADD_SOURCE_REQUEST: `${pre}ADD_SOURCE_REQUEST`,
  ADD_SOURCE_SUCCESS: `${pre}ADD_SOURCE_SUCCESS`,
  ADD_SOURCE_FAILURE: `${pre}ADD_SOURCE_FAILURE`,
};
