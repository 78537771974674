import { createSelector } from 'reselect';
import { getOnboardRun } from './account.selectors';
import {
  getCurrentClientId,
  getCurrentClientDiaryIds,
  getAllConnectedClients,
} from '../clients/clients.selectors';
import { ONBOARD_IDS, FICTIVE_CLIENT_IDS } from '../../../constants';

// Can run if the current client is not fictive ans has more
// than 2 diaries
export const getCanRunCompare = createSelector(
  [getCurrentClientId, getCurrentClientDiaryIds],
  (clientId, diaryIds) => {
    return !FICTIVE_CLIENT_IDS.has(clientId) && diaryIds.length > 2;
  }
);

// Can run if the compare mode onboarding has been run
export const getCanRunVerify = createSelector(
  [state => getOnboardRun(state, ONBOARD_IDS.COMPARE_MODE)],
  compareOnboardRun => compareOnboardRun
);

// If the dietitian has more connected clients than that, it likely
// means that she started using keenoa before we addded this onboarding
// step and does not need it.
export const getCanRunFictiveClient = createSelector(
  [getAllConnectedClients],
  clients => clients.length < 3
);

// We use the same triggeras the fictive client to prevent showing this
// to dietitians who already have clients.
export const getCanRunDetailed = createSelector(
  getCanRunFictiveClient,
  canRun => canRun
);
