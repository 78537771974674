import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import { get } from 'utils/storage';

const sendPageView = location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
};

export const identifyGA = () => {
  const id = get('id');
  if (!id) return;

  ReactGA.set({ userId: id });
};

/** Inspired by: https://github.com/react-ga/react-ga/issues/122#issuecomment-549575766 */
const GAListener = ({ children, trackingId }) => {
  const history = useHistory();
  useEffect(() => {
    if (trackingId) {
      const userId = get('id');
      ReactGA.initialize(trackingId, {
        testMode: process.env.NODE_ENV === 'development',
        gaOptions: userId ? { userId } : {},
      });
      sendPageView(history.location, 'REPLACE');
      return history.listen(sendPageView);
    }
  }, [history, trackingId]);

  return children;
};

export default GAListener;
