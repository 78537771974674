import { secureCall, json, JSON_HEADER } from './api';

export const fetchAll = (startDate, endDate, clientId) =>
  secureCall('/food-diaries', {
    method: 'GET',
    queryParams: {
      'start-date': startDate,
      'end-date': endDate,
      'client-id': clientId,
    },
  }).then(json);

export const fetchOne = (id, clientId) =>
  secureCall(`/food-diaries/${id}`, {
    method: 'GET',
    queryParams: {
      'client-id': clientId,
    },
  }).then(json);

export const addComment = (mealId, comment) => {
  return secureCall(`/meals/${mealId}/comments`, {
    method: 'POST',
    headers: JSON_HEADER,
    body: JSON.stringify({
      comment,
    }),
  }).then(json);
};

export const getComments = mealId => {
  return secureCall(`/meals/${mealId}/comments`, {
    method: 'GET',
    headers: JSON_HEADER,
  }).then(json);
};

export const updateDiaryVerifyApi = (diaryId, isVerified) => {
  return secureCall(`food-diaries/${diaryId}`, {
    method: 'PATCH',
    headers: JSON_HEADER,
    body: JSON.stringify({
      is_verified: isVerified,
    }),
  }).then(json);
};

export const exportDiariesApi = (
  clientIds,
  { exportAll, from, to, onlyVerified, aggregate, withIngredients }
) =>
  secureCall(`food-diaries/export`, {
    method: 'POST',
    headers: JSON_HEADER,
    body: JSON.stringify({
      'client-ids': clientIds,
      'start-date': from,
      'end-date': to,
      'verified-only': onlyVerified,
      'request-time': new Date().toISOString(),
      'all-dates': exportAll,
      'should-aggregate': aggregate,
      'with-ingredients': withIngredients,
    }),
  });

export default {
  fetchAll,
  fetchOne,
};
