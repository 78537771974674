import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import types from './userFoodsView.types';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import includes from 'lodash/includes';
import { SORT_ORDERS } from 'constants/index';

export const sortColumn = (state = null, action) => {
  if (action.type === types.SELECT_SORT_COLUMN) {
    return action.column;
  }
  return state;
};

export const sortOrder = (state = SORT_ORDERS.ASCENDING, action) => {
  if (action.type === types.INVERT_SORT_ORDER) {
    return state === SORT_ORDERS.DESCENDING
      ? SORT_ORDERS.ASCENDING
      : SORT_ORDERS.DESCENDING;
  }
  return state;
};

export const selectedIds = (state = [], action) => {
  switch (action.type) {
    case types.UNSELECT_ALL_USER_FOODS:
      return [];
    case types.SELECT_USER_FOOD:
      return uniq([...state, action.id]);
    case types.UNSELECT_USER_FOOD:
      return state.filter(x => x !== action.id);
    case types.SELECT_USER_FOODS:
      return action.ids;
    default:
      return state;
  }
};

export default combineReducers({
  sortColumn,
  sortOrder,
  selectedIds,
});

// ACTIONS
export const setUserFoodsSortColumn = column => ({
  type: types.SELECT_SORT_COLUMN,
  column,
});
export const invertUserFoodsSortOrder = () => ({
  type: types.INVERT_SORT_ORDER,
});
export const selectUserFood = id => ({
  type: types.SELECT_USER_FOOD,
  id,
});
export const selectUserFoods = ids => ({
  type: types.SELECT_USER_FOODS,
  ids,
});
export const unselectUserFood = id => ({
  type: types.UNSELECT_USER_FOOD,
  id,
});
export const unselectAllUserFoods = () => ({
  type: types.UNSELECT_ALL_USER_FOODS,
});

/// SELECTORS
const _getView = state => get(state, 'UI.userFoodsView');
export const getUserFoodsSortColumn = createSelector(
  _getView,
  x => x.sortColumn
);
export const getUserFoodsSortOrder = createSelector(_getView, x => x.sortOrder);
export const getSelectedUserFoodsIds = createSelector(
  _getView,
  x => x.selectedIds
);
/** (state, id) */
export const getIsUserFoodSelected = createSelector(
  [getSelectedUserFoodsIds, (_state, id) => id],
  (ids, id) => includes(ids, id)
);
