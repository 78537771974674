import React from 'react';
import Highlight from './Highlight';
import styled from 'styled-components';
import get from 'lodash/get';

const TitleText = styled.span`
  font-size: 14px;
  color: ${x => x.theme.palette.text.primary};
`;
const OptionText = styled.span`
  color: ${x => x.theme.palette.text.tertiary};
`;

export default function Title({ hit, locale }) {
  const matchedOptionsIndexes = () => {
    const options = get(hit, `_highlightResult.options_${locale}`, []);
    const matchedIndexes = options
      .map((x, i) => (!!x.matchedWords.length ? i : null))
      .filter(x => x !== null);
    return matchedIndexes;
  };

  const renderOptions = () => {
    const options = get(hit, `_highlightResult.options_${locale}`, []);
    const matchedIndexes = matchedOptionsIndexes();
    if (!matchedIndexes.length) return null;

    const group = get(hit, `options_group.name_${locale}`).toLowerCase();
    const remaining = options.length - matchedIndexes.length;
    return (
      <OptionText>
        {' - '}
        {matchedIndexes.map((idx, i) => {
          return [
            <Highlight
              key={idx}
              arrayIndex={idx}
              attribute={`p`}
              hit={{
                p: get(hit, `options_${locale}.${idx}`),
                h: { p: options[idx] },
              }}
              highlightProperty="h"
              lowerFirst={i > 0}
            />,
            i === matchedIndexes.length - 1 ? '' : ', ',
          ];
        })}
        {` & ${remaining} other ${group}${remaining > 1 ? 's' : ''}`}
      </OptionText>
    );
  };

  return (
    <TitleText>
      <Highlight
        attribute={`description_${locale}`}
        hit={hit}
        highlightProperty="_highlightResult"
      />
      {renderOptions()}
    </TitleText>
  );
}
