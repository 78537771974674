import ConfirmationDialog from './ConfirmationDialog';
import { connect } from 'react-redux';

import {
  getModalProps,
  MODAL_TYPES,
  getModalOpen,
  closeModal,
} from 'redux/modules/modals/modals';

const mapStateToProps = state => {
  return {
    ...getModalProps(state),
    isOpen: getModalOpen(state, MODAL_TYPES.CONFIRMATION_DIALOG),
  };
};

const mapDispatchToProps = dispatch => ({
  onExit: () => dispatch(closeModal(MODAL_TYPES.CONFIRMATION_DIALOG)),
});

const mergeProps = (stateProps, { onExit }, ownProps) => ({
  ...stateProps,
  ...ownProps,
  onConfirm: () => {
    onExit();
    stateProps.onConfirm();
  },
  onCancel: () => {
    onExit();
    stateProps.onCancel && stateProps.onCancel();
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ConfirmationDialog);
