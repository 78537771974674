import { connect } from 'react-redux';
import NutrientsModal from './NutrientsModal';
import {
  getModalOpen,
  MODAL_TYPES,
  closeModal,
} from 'redux/modules/modals/modals';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: { id: 'client.profile.nutrients.title' },
});

const mapStateToProps = state => ({
  isOpen: getModalOpen(state, MODAL_TYPES.EDIT_CLIENT_NUTRIENTS),
  title: messages.title,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal(MODAL_TYPES.EDIT_CLIENT_NUTRIENTS)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NutrientsModal);
