import { combineReducers } from 'redux';
import { makeById } from '../../../../utils/standardReducers';
import types from './builders.types';
import { fetchBuilderApi } from '../../../../api/foods';
import schemas from '../../../schemas';
import { getBuilder } from './builders.selectors';
import { normalize } from 'normalizr';

export default combineReducers({
  byId: makeById('builders'),
});

// Actions
export const fetchBuilder = (builderId, dev) => ({
  types: [
    types.FETCH_BUILDER_REQUEST,
    types.FETCH_BUILDER_SUCCESS,
    types.FETCH_BUILDER_FAILURE,
  ],
  callAPI: () =>
    fetchBuilderApi(builderId).then(res => {
      return normalize(res, schemas.builder);
    }),
  payload: { builderId },
  shouldCallAPI: state => getBuilder(state, builderId) === null,
});
