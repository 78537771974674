import React from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Title, Background } from './components';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/material/Button';
import { setOnboardRun } from 'redux/modules/account/account.operations';
import { ONBOARD_IDS } from '../../constants';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { makeCustomIcon } from '../../utils/misc';

const messages = defineMessages({
  title: { id: 'onboard.confirm.title' },
  to: { id: 'onboard.confirm.to' },
  explore: { id: 'onboard.confirm.explore' },
});

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 400px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20vh;
`;

const To = styled.div`
  color: ${x => x.theme.palette.text.tertiary};
  margin-right: 20px;
`;

function Confirm() {
  const history = useHistory();
  const location = useLocation();
  const emails = get(location, 'state.emails', []);
  const dispatch = useDispatch();

  const exploreKeenoa = () => {
    dispatch(setOnboardRun(ONBOARD_IDS.CONFIRM));
    history.push('/clients');
  };

  return (
    <Background>
      <Wrapper>
        <Title style={{ marginTop: 0 }}>
          <FontAwesomeIcon
            icon={['fad', 'check-circle']}
            style={{ marginRight: 15 }}
            color={'#4B9E73'}
          />
          <FormattedMessage {...messages.title} />
        </Title>
        <div style={{ display: 'flex', flexDirection: 'row', padding: 15 }}>
          <To>
            <FormattedMessage {...messages.to} />
          </To>
          <div>
            {emails.map(email => (
              <div style={{ marginBottom: 8 }}>{email}</div>
            ))}
          </div>
        </div>
        <Button
          unelevated
          onClick={exploreKeenoa}
          rank="saturated"
          icon={makeCustomIcon(['fad', 'street-view'])}
        >
          <FormattedMessage {...messages.explore} />
        </Button>
      </Wrapper>
    </Background>
  );
}

export default injectIntl(Confirm);
