import { connect } from 'react-redux';
import Analytics from './Analytics';
import {
  getMealsSummary,
  getCurrentDiaryMaxEnergy,
  getDiaryMaxEnergy,
} from 'redux/modules/meals/meals.selectors';

const mapStateToProps = (state, { ids, diaryId }) => ({
  mealSummary: getMealsSummary(state, ids),
  maxEnergy: !diaryId
    ? getCurrentDiaryMaxEnergy(state)
    : getDiaryMaxEnergy(state, diaryId),
});

const AnalyticsContainer = connect(mapStateToProps, null)(Analytics);

export default AnalyticsContainer;
