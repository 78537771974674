import React, { useState, useEffect } from 'react';
import CompactView from './CompactView';
import { defineMessages, FormattedMessage } from 'react-intl';
import H2 from 'components/H2';
import { withTheme } from 'styled-components';
import CreatorView from './CreatorView';
import { SOURCE_PREFIX } from '../../../../../constants';
import Spacer from 'components/Spacer';

const messages = defineMessages({
  foodNamePlaceholder: { id: 'editEntry.foodNamePlaceholder' },
  title: { id: 'editEntry.title' },
  titleNew: { id: 'editEntry.titleNew' },
});

const ENTRY_VIEWS = {
  COMPACT: 'compact',
  CREATOR: 'creator',
  INGREDIENTS: 'ingredients',
};

function EditEntry({
  entryId,
  mealId,
  foodId,

  isEdit,
  theme,
  namespace,
  onSubmit,
}) {
  const [currentView, setCurrentView] = useState(null);

  useEffect(() => {
    if (!foodId) {
      setCurrentView(null);
      return;
    }
    const [pre] = foodId.split('_');
    switch (pre) {
      case SOURCE_PREFIX.KEENOA_FOOD:
      case SOURCE_PREFIX.KEENOA_BRANDED_FOOD:
      case SOURCE_PREFIX.KEENOA_DISH:
        setCurrentView(ENTRY_VIEWS.COMPACT);
        break;
      case SOURCE_PREFIX.KEENOA_BUILDER:
      case SOURCE_PREFIX.KEENOA_PUBLIC_BUILDER:
        setCurrentView(ENTRY_VIEWS.CREATOR);
        break;
      default:
        setCurrentView(ENTRY_VIEWS.COMPACT);
    }
  }, [foodId]);

  const _onSubmit = async newEntry => {
    await onSubmit(newEntry, { entryId, mealId });
  };

  const renderView = () => {
    if (!currentView) return null;

    if (currentView === ENTRY_VIEWS.CREATOR)
      return (
        <CreatorView
          foodId={foodId}
          entryId={entryId}
          namespace={namespace}
          onSubmit={_onSubmit}
        />
      );

    return (
      <CompactView foodId={foodId} entryId={entryId} onSubmit={_onSubmit} />
    );
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <H2>
          <FormattedMessage
            {...(isEdit ? messages.title : messages.titleNew)}
          />
        </H2>
      </div>
      <Spacer spacing="lg" />
      {renderView()}
    </div>
  );
}

export default withTheme(EditEntry);
