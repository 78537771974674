import { defineMessages } from 'react-intl';

const messages = defineMessages({
  localeEn: {
    id: 'settings.localeEn',
    defaultMessage: 'English',
  },
  localeFr: {
    id: 'settings.localeFr',
    defaultMessage: 'Francais',
  },
  summaryANR: { id: 'sections.summaryANR' },
  summaryAMT: { id: 'sections.summaryAMT' },
  summaryAverage: { id: 'sections.summaryAverage' },
  summaryMin: { id: 'sections.summaryMin' },
  summaryMax: { id: 'sections.summaryMax' },

  infoFirstName: { id: 'sections.infoFirstName' },
  infoLasttName: { id: 'sections.infoLastName' },
  infoBirthdate: { id: 'sections.infoBirthdate' },
  infoGender: { id: 'sections.infoGender' },

  dailyProt: { id: 'sections.dailProt' },
  dailyCarb: { id: 'sections.dailyCarb' },
  dailyFat: { id: 'sections.dailyFat' },
  dailyAlcohol: { id: 'sections.dailyAlcohol' },
  dailyCal: { id: 'sections.dailyCal' },
});

export const ONBOARD_IDS = {
  VERIFY: 'verify',
  COMPARE_MODE: 'compare_mode',
  EXPORT_DESIGN: 'export_design',
  EXPORT_CREATE_TEMPLATE: 'export_create_template',
  VIDEOS: 'videos',
  FICTIVE_CLIENT: 'fictive_client',
  DETAILED_DIARY: 'detailed_diary',

  WELCOME: 'welcome',
  INVITE: 'invite',
  CONFIRM: 'confirm',
};

export const CLIENT_GENDERS = {
  MALE: 'male',
  FEMALE: 'female',
};

export const SORT_ORDERS = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
};

export const SORT_COLUMNS = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  STATUS: 'status',
  PARTICIPANT_ID: 'paricipant_id',
  ACCEPT_DATE: 'accept_date',
  LEARNER_NAME: 'learner_name',
  COURSES: 'courses',
};

export const REPORTS_SORT_COLUMNS = {
  TITLE: 'title',
  CREATION_DATE: 'creation_date',
  STATUS: 'status',
};

export const WIDGET_TYPES = {
  CLIENT_INFO: 'client_info',

  DAILY_MACRO: 'daily_macro',
  DAILY_AVERAGE: 'daily_average',
  NUTRIENTS_SUMMARY: 'nutrients_summary',
  VISUAL_OVERVIEW: 'visual_overview',

  RICH_TEXT: 'rich_text',
};

export const WIDGET_SECTIONS = {
  SUMMARY_ANR: {
    id: 'SUMMARY_ANR',
    label: messages.summaryANR,
  },
  SUMMARY_AMT: {
    id: 'SUMMARY_AMT',
    label: messages.summaryAMT,
  },
  SUMMARY_AVERAGE: {
    id: 'SUMMARY_AVERAGE',
    label: messages.summaryAverage,
  },
  SUMMARY_MIN: {
    id: 'SUMMARY_MIN',
    label: messages.summaryMin,
  },
  SUMMARY_MAX: {
    id: 'SUMMARY_MAX',
    label: messages.summaryMax,
  },

  INFO_FIRST_NAME: {
    id: 'INFO_FIRST_NAME',
    label: messages.infoFirstName,
  },
  INFO_LAST_NAME: {
    id: 'INFO_LAST_NAME',
    label: messages.infoLasttName,
  },
  INFO_BIRTHDATE: {
    id: 'INFO_BIRTHDATE',
    label: messages.infoBirthdate,
  },
  INFO_GENDER: {
    id: 'INFO_GENDER',
    label: messages.infoGender,
  },

  DAILY_PROT: {
    id: 'DAILY_PROT',
    label: messages.dailyProt,
  },
  DAILY_FAT: {
    id: 'DAILY_FAT',
    label: messages.dailyFat,
  },
  DAILY_CARB: {
    id: 'DAILY_CARB',
    label: messages.dailyCarb,
  },
  DAILY_ALCOHOL: {
    id: 'DAILY_ALCOHOL',
    label: messages.dailyAlcohol,
  },
  DAILY_CAL: {
    id: 'DAILY_CAL',
    label: messages.dailyCal,
  },
};

export const DROPPABLE_IDS = {
  CONTENT_TAB: 'content-tab',
  PAGE: 'page',
};

export const PDF_LAYOUT = {
  HORIZONTAL_PADDING: 25,
};

export const AVAILABLE_LOCALES = [
  {
    id: 'fr',
    value: 'fr',
    label: messages.localeFr,
  },
  {
    id: 'en',
    value: 'en',
    label: messages.localeEn,
  },
];

export const TIME_FORMATS = {
  TWELVE_HOUR: 'twelveHour',
  TWENTY_FOUR_HOUR: 'twentyFourHour',
};
export const AVAILABLE_TIME_FORMATS = [
  {
    id: TIME_FORMATS.TWELVE_HOUR,
    label: '12h',
  },
  {
    id: TIME_FORMATS.TWENTY_FOUR_HOUR,
    label: '24h',
  },
];

export const EDITABLE_WIDGET_TYPES = {
  [WIDGET_TYPES.CLIENT_INFO]: true,
  [WIDGET_TYPES.DAILY_AVERAGE]: true,
  [WIDGET_TYPES.DAILY_MACRO]: true,
  [WIDGET_TYPES.NUTRIENTS_SUMMARY]: true,
  [WIDGET_TYPES.VISUAL_OVERVIEW]: true,
};

export const TEMPLATE_VISIBILITY = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const FICTIVE_CLIENT_IDS = new Set([
  'k0Yv1Qr4kxmgoZy',
  'zVjEkxLbwEQgJro',
]);
export const HEIGHT_BEFORE_CLIENT_LIST = 158;

export const SPECIAL_OPTION_TYPES = {
  SCAN: 'scan',
  SEARCH: 'search',
  REDIRECT: 'redirect',
  DIRECT_SCAN: 'direct_scan',
};

export const BUILDER_TYPES = {
  CREATOR: 'creator',
  INGREDIENT: 'ingredient',
};

export const SOURCE_PREFIX = {
  CNF: '',
  NUTRITIONIX: 'nix',

  KEENOA_FOOD: 'kef', // Custom food added to our database
  KEENOA_BUILDER: 'keb', // Realization of a builder (builder_config)
  KEENOA_DISH: 'ked', // The average form of a dish.
  KEENOA_PUBLIC_BUILDER: 'kpb', // For public builder id. (might not be a valid food)
  KEENOA_BRANDED_FOOD: 'kbf', // Branded food added to our database

  KEENOA_RECIPE: 'ker', // A recipe created by a dietitian.
};

export const LEFT_NAV_WIDTH = 54;

export const REPORT_ACTIONS = {
  DOWNLOAD: 'download',
  REMOVE: 'remove',
  SEND: 'send',
  EDIT: 'edit',
  COPY: 'copy',
};

export const REPORT_STATUS = {
  CURRENT: 'current',
  FINALIZED: 'finalized',
};

export const REPORT_FILTERS = {
  ALL: 'all',
  CURRENT: 'current',
  FINALIZED: 'finalized',
};

export const USER_FOODS_SORT_COLUMNS = {
  NAME: 'name',
  IS_SHARED: 'is_shared_with_clients',
  SERVING: 'serving',
};

export const MIN_WIDTH = '80vw';

export const GROUP_TAGS = {
  NUTRISIMPLE: 'NS',
  EQUIPE_NUTRITION: 'EN',
};

export const TAGS = {
  RESEARCHER: 'RESEARCHER',
  INSTRUCTOR: 'INSTRUCTOR',
};

export const USER_FOOD_ACTIONS = {
  DELETE: 'delete',
};

export const FOOD_IMPORT_SOURCES = {
  USDA: 'usda',
  HONG_KONG_FOOD_DATABASE: 'hkfd',
};
