import { connect } from 'react-redux';
import Billing from './Billing';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';
import {
  getSources,
  getSourcesStatus,
} from 'redux/modules/billing/billing.selectors';
import { fetchSources } from 'redux/modules/billing/billing.actions';

const mapStateToProps = state => ({
  sources: getSources(state),
  sourcesStatus: getSourcesStatus(state),
});

const mapDispatchToProps = dispatch => ({
  fetchSources: () => dispatch(fetchSources()),
  addPayment: () => dispatch(openModal(MODAL_TYPES.ADD_PAYMENT)),
  openPaymentDue: () => dispatch(openModal(MODAL_TYPES.PAYMENT_DUE)),
});

const BillingContainer = connect(mapStateToProps, mapDispatchToProps)(Billing);

export default BillingContainer;
