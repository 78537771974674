import ClientCheckbox from '../ClientCheckbox/ClientCheckbox';
import { connect } from 'react-redux';
import {
  getVisibleClientIds,
  getSelectedIds,
} from 'redux/modules/clients/clients.selectors';
import {
  selectClients,
  unselectAllClients,
} from 'redux/modules/clients/clients.actions';

const mapStateToProps = state => ({
  indeterminate: !!getSelectedIds(state).length,
  selected: !!getSelectedIds(state).length,
  visibleIds: getVisibleClientIds(state),
});

const mapDispatchToProps = dispatch => ({
  select: ids => dispatch(selectClients(ids)),
  unselect: () => dispatch(unselectAllClients()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  ...dispatchProps,
  select: () => dispatchProps.select(stateProps.visibleIds),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ClientCheckbox);
