import { connect } from 'react-redux';
import DRIs from './DRIs';
import {
  getCurrentClientUl,
  getCurrentClientRdaOrAi,
} from 'redux/modules/meals/meals.selectors';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';
import { getIsCurrentClientProfileComplete } from 'redux/modules/clients/clients.selectors';

const mapStateToProps = state => ({
  uls: getCurrentClientUl(state),
  rdasOrAis: getCurrentClientRdaOrAi(state),

  empty: !getIsCurrentClientProfileComplete(state),
});

const mapDispatchToProps = dispatch => ({
  onEditProfile: () => dispatch(openModal(MODAL_TYPES.PROFILE)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DRIs);
