import ExportModal from './ExportModal';
import { connect } from 'react-redux';
import {
  closeModal,
  getModalOpen,
  MODAL_TYPES,
  openModal,
} from 'redux/modules/modals/modals';
import { getSelectedIds } from 'redux/modules/clients/clients.selectors';
import { getNutrients } from 'redux/modules/account/account.selectors';

const mapStateToProps = state => ({
  isOpen: getModalOpen(state, MODAL_TYPES.EXPORT_CLIENTS),
  clientIds: getSelectedIds(state),

  defaultNutrients: getNutrients(state),
});

const mapDispatchToProps = dispatch => ({
  onExit: () => dispatch(closeModal(MODAL_TYPES.EXPORT_CLIENTS)),
  openNutrientsModal: () =>
    dispatch(openModal(MODAL_TYPES.EDIT_EXPORT_NUTRIENTS, {}, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportModal);
