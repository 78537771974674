import StudiesFiltering from './StudiesFiltering';

import { connect } from 'react-redux';
import { getIsFetchingStudies, getStudies } from 'redux/modules/studies';
import {
  getStudiesFilter,
  setStudiesFilter,
} from 'redux/modules/UI/clientsView';

const mapStateToProps = state => ({
  filter: getStudiesFilter(state),
  studies: getStudies(state),
  isFetching: getIsFetchingStudies(state),
});

const mapDispatchToProps = dispatch => ({
  selectFilter: filter => dispatch(setStudiesFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudiesFiltering);
