import types from './billing.types';
import { normalize } from 'normalizr';
import { source, arrayOfSources } from '../../schemas';
import billingApi from '../../../api/billing';

export const fetchInvoices = () => ({
  type: types.FETCH_INVOICES_REQUEST,
});
export const fetchInvoicesSuccess = response => ({
  type: types.FETCH_INVOICES_SUCCESS,
  response,
});
export const fetchInvoicesFailure = error => ({
  type: types.FETCH_INVOICES_FAILURE,
  error,
});

export const fetchSubscriptions = () => ({
  type: types.FETCH_SUBSCRIPTIONS_REQUEST,
});
export const fetchSubscriptionsSuccess = response => ({
  type: types.FETCH_SUBSCRIPTIONS_SUCCESS,
  response,
});
export const fetchSubscriptionsFailure = error => ({
  type: types.FETCH_SUBSCRIPTIONS_FAILURE,
  error,
});

export const fetchSources = () => ({
  types: [
    types.FETCH_SOURCES_REQUEST,
    types.FETCH_SOURCES_SUCCESS,
    types.FETCH_SOURCES_FAILURE,
  ],
  callAPI: () =>
    billingApi.fetchSources().then(res => {
      return normalize(res, arrayOfSources);
    }),
});

export const addSource = token => ({
  types: [
    types.ADD_SOURCE_REQUEST,
    types.ADD_SOURCE_SUCCESS,
    types.ADD_SOURCE_FAILURE,
  ],
  callAPI: () =>
    billingApi.addSource(token).then(res => {
      return normalize(res, source);
    }),
  payload: { token: token },
});

export default {
  fetchInvoices,
  fetchInvoicesSuccess,
  fetchInvoicesFailure,

  fetchSubscriptions,
  fetchSubscriptionsSuccess,
  fetchSubscriptionsFailure,
};
