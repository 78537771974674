import MindfulHelpModal from './MindfulHelpModal';
import { connect } from 'react-redux';
import {
  MODAL_TYPES,
  closeModal,
  getModalOpen,
} from 'redux/modules/modals/modals';
import { getCurrentClientFirstName } from 'redux/modules/clients/clients.selectors';

const mapStateToProps = state => ({
  isOpen: getModalOpen(state, MODAL_TYPES.MINDFUL_HELP),
  firstName: getCurrentClientFirstName(state),
});

const mapDispatchToProps = dispatch => ({
  onExit: () => dispatch(closeModal(MODAL_TYPES.MINDFUL_HELP)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MindfulHelpModal);
