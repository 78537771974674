import { connect } from 'react-redux';
import Avatar from './Avatar';
import { getInitial } from 'redux/modules/account/account.selectors';

const mapStateToProps = state => ({
  initial: getInitial(state),
});

const AvatarContainer = connect(mapStateToProps, null)(Avatar);

export default AvatarContainer;
