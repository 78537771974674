import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import Center from 'components/Center';
import styled from 'styled-components';

import 'react-day-picker/lib/style.css';
import './DatePicker.css';

const MONTHS = {
  fr: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
};

const WEEKDAYS_LONG = {
  fr: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  en: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ],
};

const WEEKDAYS_SHORT = {
  fr: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
  en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
};

const TODAY = new Date(Date.now());

const DayCell = styled(Center)`
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: ${props => {
    const compared = props.compared && props.compareMode;
    const selected = props.selected && !props.compareMode;
    if (props.verified && (selected || compared))
      return props.theme.palette.success;
    if (selected || compared) return props.theme.palette.accent1;
    if (props.available && !props.viewed) return props.theme.palette.primary1;
    return 'transparent';
  }};
  border: ${props => {
    const compared = props.compared && props.compareMode;
    const selected = props.selected && !props.compareMode;
    if (selected || compared) return '';
    if (props.verified) return `solid 2px ${props.theme.palette.success}`;
    if (props.viewed) return `solid 2px ${props.theme.palette.primary1}`;
  }};
  color: ${props => {
    const compared = props.compared && props.compareMode;
    const selected = props.selected && !props.compareMode;
    if (
      selected ||
      compared ||
      (props.today && props.available && !props.viewed)
    )
      return props.theme.palette.white;
    if (props.today) return props.theme.palette.primarySaturated;
    if (props.available && !props.viewed) return props.theme.palette.white;
    return props.theme.palette.text.primary;
  }};
  transition: border-radius 0.2s ease-in-out;
  padding: 3px;

  &:hover {
    border-radius: ${props => (!props.selected ? '5%' : '50%')};
    cursor: ${props =>
      props.available && (!props.selected || props.compareMode)
        ? 'pointer'
        : 'default'};
  }
`;

export default class DatePicker extends React.PureComponent {
  handleDayClick = (day, { disabled }) => {
    if (disabled) return;
    const selectedLogId = this.props.availableDays.find(x =>
      DateUtils.isSameDay(day, x.date)
    ).id;
    this.props.onSelectDate(selectedLogId);
  };

  isDayAvailable = day => {
    return this.props.availableDays.find(x => DateUtils.isSameDay(day, x.date));
  };
  isDayUnavailable = day => !this.isDayAvailable(day);

  isDayViewed = day => {
    const dayString = day.toLocaleDateString();
    return this.props.viewedDiaries[dayString];
  };

  isDayVerified = day => {
    const dayString = day.toLocaleDateString();
    return this.props.verifiedDiaries[dayString];
  };

  isDayCompared = day => {
    const dayString = day.toLocaleDateString();
    return this.props.comparedDiaries[dayString];
  };

  renderDay = day => {
    const { selectedDate } = this.props;
    return (
      <DayCell
        className="mdc-typography--caption"
        today={DateUtils.isSameDay(TODAY, day)}
        selected={DateUtils.isSameDay(selectedDate, day)}
        available={this.isDayAvailable(day)}
        viewed={this.isDayViewed(day)}
        verified={this.isDayVerified(day)}
        compared={this.isDayCompared(day)}
        compareMode={this.props.compareMode}
      >
        {day.getDate()}
      </DayCell>
    );
  };

  render() {
    const { locale, selectedDate, fixDate, clientId } = this.props;
    let l = locale && locale.startsWith('fr') ? 'fr' : 'en';

    let date = selectedDate || new Date();
    if (!isValidDate(date)) {
      if (clientId) {
        fixDate(clientId);
        return null;
      } else {
        return null;
      }
    }

    return (
      <DayPicker
        className="mdc-typography--subheading2"
        locale={l}
        months={MONTHS[l]}
        month={date}
        weekdaysLong={WEEKDAYS_LONG[l]}
        weekdaysShort={WEEKDAYS_SHORT[l]}
        firstDayOfWeek={1}
        renderDay={this.renderDay}
        onDayClick={this.handleDayClick}
        disabledDays={this.isDayUnavailable}
      />
    );
  }
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
