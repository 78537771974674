import BeginPasswordReset from './BeginPasswordReset';
import { connect } from 'react-redux';
import { authOperations } from 'redux/modules/auth';

const mapStateToProps = ({ auth }) => {
  const { resetEmailSent, resetError } = auth;
  return { sent: resetEmailSent, error: resetError };
};
const mapDispatchToProps = dispatch => ({
  onSubmit: email => dispatch(authOperations.requestPasswordReset(email)),
});

const BeginPasswordResetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BeginPasswordReset);

export default BeginPasswordResetContainer;
