import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import Typography from 'components/material/Typography';
import Spacer from 'components/Spacer';
import {
  QUESTIONS_BEFORE,
  QUESTIONS_AFTER,
  ANSWER_TO_I18N,
} from 'constants/mindful';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import Entries from '../Entries';
import AddEntryButton from '../../../../components/AddEntryButton';
import Chip from 'components/Chip';

const messages = defineMessages({
  mindfullness: { id: 'meal.mindfullness' },
  description: { id: 'meal.description' },
  entries: { id: 'meal.entries' },
  after: { id: 'meal.mindfullnes.after' },
  before: { id: 'meal.mindfullness.before' },
});

const HelpIcon = styled.span`
  font-size: 16px;
  padding: 10px;
  margin-left: -8px;
  margin-top: -10px;
  color: ${props => props.theme.palette.text.secondary};

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.palette.primary1};
  }
`;

class Meal extends React.PureComponent {
  renderTitle = (text, icon, onHelp) => (
    <div
      key={text}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          fontSize: 16,
          color: 'rgba(0,0,0,0.33)',
          marginRight: 3,
        }}
        className="material-icons"
      >
        {icon}
      </div>
      <Typography use="overline" rank="tertiary">
        <FormattedMessage {...text} />
        {!onHelp ? null : (
          <HelpIcon onClick={onHelp} className="material-icons">
            help_outline
          </HelpIcon>
        )}
      </Typography>
    </div>
  );

  renderTag = message => (
    <Chip key={get(message, 'id')}>
      <FormattedMessage {...message} />
    </Chip>
  );

  renderAnswers = (isBefore, answers) => {
    return (
      <div
        key={`${this.props.meal.id}_${isBefore}`}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          use="subtitle2"
          rank="secondary"
          tag="div"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {isBefore ? (
            <FormattedMessage {...messages.before} />
          ) : (
            <FormattedMessage {...messages.after} />
          )}
        </Typography>
        {answers.map(this.renderTag)}
      </div>
    );
  };

  renderMindful = () => {
    const answers = get(this, 'props.meal.answers');
    const before = QUESTIONS_BEFORE.map(id => answers[id]).filter(x => x);
    const after = QUESTIONS_AFTER.map(id => answers[id]).filter(x => x);

    const beforeAnswers = flatMap(before, x =>
      map(x, (_, answer) => ANSWER_TO_I18N[answer])
    );
    const afterAnswers = flatMap(after, x =>
      map(x, (_, answer) => ANSWER_TO_I18N[answer])
    );

    if (!beforeAnswers.length && !afterAnswers.length) return null;

    return [
      this.renderTitle(
        messages.mindfullness,
        'tag_faces',
        this.props.openMindfulHelp
      ),
      this.renderAnswers(true, beforeAnswers),
      this.renderAnswers(false, afterAnswers),
      <Spacer spacing="lg" key={'mindful_spacer'} />,
    ];
  };
  renderDescription = () => {
    const description = get(this, 'props.meal.description');
    if (!description) return null;

    return [
      this.renderTitle(messages.description, 'description'),
      <Typography key={description} use="body1" rank="primary">
        {description}
      </Typography>,
      <Spacer spacing="lg" key={'description_spacer'} />,
    ];
  };
  renderEntries = () => [
    this.renderTitle(messages.entries, 'restaurant'),
    <AddEntryButton
      key={`${this.props.meal.id}_add_entry`}
      meals={[this.props.meal.id]}
      style={{ marginTop: -36 }}
    />,
    <Entries
      key={`${this.props.meal.id}_entries`}
      mealId={this.props.meal.id}
    />,
  ];

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: 0,
          marginLeft: 24,
        }}
      >
        {this.renderMindful()}
        {this.renderDescription()}
        {this.renderEntries()}
      </div>
    );
  }
}

export default Meal;
