export const pre = 'keenoa/userFoods/';

export default {
  CREATE_USER_FOOD_REQUEST: `${pre}CREATE_USER_FOOD_REQUEST`,
  CREATE_USER_FOOD_SUCCESS: `${pre}CREATE_USER_FOOD_SUCCESS`,
  CREATE_USER_FOOD_FAILURE: `${pre}CREATE_USER_FOOD_FAILURE`,

  FETCH_USER_FOOD_REQUEST: `${pre}FETCH_USER_FOOD_REQUEST`,
  FETCH_USER_FOOD_SUCCESS: `${pre}FETCH_USER_FOOD_SUCCESS`,
  FETCH_USER_FOOD_FAILURE: `${pre}FETCH_USER_FOOD_FAILURE`,

  FETCH_USER_FOODS_REQUEST: `${pre}FETCH_USER_FOODS_REQUEST`,
  FETCH_USER_FOODS_SUCCESS: `${pre}FETCH_USER_FOODS_SUCCESS`,
  FETCH_USER_FOODS_FAILURE: `${pre}FETCH_USER_FOODS_FAILURE`,

  DELETE_USER_FOOD_REQUEST: `${pre}DELETE_USER_FOOD_REQUEST`,
  DELETE_USER_FOOD_SUCCESS: `${pre}DELETE_USER_FOOD_SUCCESS`,
  DELETE_USER_FOOD_FAILURE: `${pre}DELETE_USER_FOOD_FAILURE`,

  UPDATE_USER_FOOD_REQUEST: `${pre}UPDATE_USER_FOOD_REQUEST`,
  UPDATE_USER_FOOD_SUCCESS: `${pre}UPDATE_USER_FOOD_SUCCESS`,
  UPDATE_USER_FOOD_FAILURE: `${pre}UPDATE_USER_FOOD_FAILURE`,
};
