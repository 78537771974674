import get from 'lodash/get';
import head from 'lodash/head';
import tail from 'lodash/tail';
import { createSelector } from 'reselect';
import { getBuilder } from '../../builders/builders/builders.selectors';
import { getPublicBuilderOptions } from '../../builders/publicBuilders/publicBuilders.selectors';

const _getRoot = state => get(state, ['UI', 'builderView']);
const _getByNamespace = createSelector(_getRoot, x => get(x, 'byNamespace'));
/** (state, namespace) */
const _getNamespace = createSelector(
  [_getByNamespace, (_, namespace) => namespace],
  (byNamespace, namespace) => get(byNamespace, namespace)
);
/** (state, namespace) */
const _getGroupOptions = createSelector(_getNamespace, x =>
  get(x, 'groupOptions')
);
/** (state, namespace, groupId) */
export const getGroupOptions = createSelector(
  [_getGroupOptions, (_, __, groupId) => `${groupId}`],
  (groups, groupId) => get(groups, groupId, [])
);
/** (state, namespace) */
export const _getSelectedOptions = createSelector(_getNamespace, x =>
  get(x, 'selectedOptions', new Set())
);
/** (state, namespace) */
export const getSelectedOptions = createSelector(_getSelectedOptions, options =>
  Array.from(options)
);
/** (state, namespace, optionId) */
export const getOptionSelected = createSelector(
  [_getSelectedOptions, (_, __, optionId) => `${optionId}`],
  (options, id) => options.has(id)
);

export const _formatGroup = group => {
  const options = get(group, 'options').map(option => ({
    id: get(option, 'id'),
    name: get(option, 'name'),
    foodId: get(option, 'foodId'),
    specialType: get(option, 'specialType'),
  }));
  return {
    ...group,
    options,
  };
};
const PRUNABLE_GROUP_NAMES = new Set(['type', 'flavour', 'saveur']);
const _getBuilderGroupIds = (builder, selectedOptions, defaultOptions) => {
  if (!builder) return [];

  const selectedOptionsSet = new Set(selectedOptions);
  const defaultOptionsSet = new Set(defaultOptions);

  let availableGroups = [];
  const addGroup = node => {
    const groups = get(node, 'optionsGroups', []);
    if (!groups.length) return;

    groups.forEach(group => {
      availableGroups.push(_formatGroup(group));
      get(group, 'options', [])
        .filter(({ id }) => selectedOptionsSet.has(id))
        .forEach(addGroup);
    });
  };
  addGroup(builder);

  // Builders are hierarchical, if a public builder specifies
  // the type, we can omit asking the type question.
  while (true) {
    const group = head(availableGroups);
    if (
      group &&
      PRUNABLE_GROUP_NAMES.has(get(group, 'name', '').toLowerCase()) &&
      !!get(group, 'options', []).find(({ id }) => defaultOptionsSet.has(id))
    ) {
      availableGroups.shift();
      continue;
    }
    break;
  }
  return availableGroups;
};

/** (state, namespace, builderId, publicBuilderId) */
export const getNamespaceGroups = createSelector(
  [
    (state, _, builderId) => getBuilder(state, builderId),
    getSelectedOptions,
    (state, _, __, publicBuilderId) => {
      return publicBuilderId
        ? getPublicBuilderOptions(state, publicBuilderId)
        : [];
    },
  ],
  _getBuilderGroupIds
);

/** (state, namespace, builderId) */
export const getNamespaceMeasures = createSelector(
  [(state, _, builderId) => getBuilder(state, builderId), _getSelectedOptions],
  (builder, selectedOptionsSet) => {
    if (!builder) return null;

    let finalMeasures = null;

    const formatMeasure = measure => {
      const serving = get(measure, 'serving');
      const initialvalue = get(serving, 'quantity');
      let name = get(serving, 'name', null);
      let unit = get(serving, ['unit', 'name'], null);
      const isWhole = get(serving, ['unit', 'isWhole'], false);
      if (isWhole) {
        name = unit;
        unit = '';
      }
      const dimension = get(serving, 'dimension');
      return {
        // There is no conversion factor as it will be
        // retrieved by the builder config.
        initialValue: initialvalue,
        unit: unit === '' ? null : unit,
        unit_name: name === '' ? null : name,
        unit_dimension: dimension === '' ? null : dimension,
        unit_measure_id: measure.id,
      };
    };

    const findMeasure = node => {
      if (!node || finalMeasures) return;

      const measures = get(node, 'measures', []);
      if (measures.length) {
        finalMeasures = measures.map(formatMeasure);
        return;
      }

      const groups = get(node, 'optionsGroups', []);
      groups.forEach(group => {
        get(group, 'options', [])
          .filter(({ id }) => selectedOptionsSet.has(id))
          .forEach(findMeasure);
      });
    };

    findMeasure(builder);
    return finalMeasures;
  }
);

function _makeGetCleanSelectedOptions(propToGet = 'id') {
  return (selectedOptionsSet, builder) => {
    if (!builder) return [];

    let cleanOptions = [];
    const findOptions = node => {
      cleanOptions.push(get(node, propToGet));
      const groups = get(node, 'optionsGroups', []);
      groups.forEach(({ options }) => {
        options
          .filter(({ id }) => selectedOptionsSet.has(id))
          .forEach(findOptions);
      });
    };
    findOptions(builder);

    // The first clean option always is the builder id.
    return tail(cleanOptions);
  };
}

// Returns an array that includes only the selected options
// that have an impact on the builder.
/** (state, namespace, builderId) */
export const getCleanSelectedOptions = createSelector(
  [_getSelectedOptions, (state, _, builderId) => getBuilder(state, builderId)],
  _makeGetCleanSelectedOptions('id')
);

/** (state, namespace, builderId) */
export const getCleanSelectedOptionsNames = createSelector(
  [_getSelectedOptions, (state, _, builderId) => getBuilder(state, builderId)],
  _makeGetCleanSelectedOptions('name')
);

/** To be ready, all required groups must have a selected option. */
/** (state, namespace, builderId) */
export const getNamespaceBuilderReady = createSelector(
  [(state, _, builderId) => getBuilder(state, builderId), _getSelectedOptions],
  (builder, selectedOptionsSet) => {
    if (!builder) return false;

    const hasMissingRequired = node => {
      const groups = get(node, 'optionsGroups', []);
      if (!groups.length) return false;

      for (let i = 0; i < groups.length; ++i) {
        const group = groups[i];
        const sOptions = get(group, 'options', []).filter(({ id }) =>
          selectedOptionsSet.has(id)
        );
        if (get(group, 'isRequired') && !sOptions.length) {
          return true;
        }
        for (let j = 0; j < sOptions.length; ++j) {
          const hasMissing = hasMissingRequired(sOptions[j]);
          if (hasMissing) return true;
        }
      }
      return false;
    };
    return !hasMissingRequired(builder);
  }
);
