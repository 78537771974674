import { secureCall, JSON_HEADER, json } from './api';

export const fetchPublicBuilderApi = builderId =>
  secureCall(`/public_builders/${builderId}`, {
    method: 'GET',
    queryParams: { isDev: false },
  }).then(json);

export const createBuilderConfigApi = (builderId, options, adjustments) =>
  secureCall(`/builder_configs`, {
    method: 'POST',
    headers: JSON_HEADER,
    body: JSON.stringify({ builderId, options, adjustments }),
  }).then(json);

export const fetchBuilderConfigApi = configId =>
  secureCall(`/builder_configs/${configId}`, {
    method: 'GET',
    headers: JSON_HEADER,
  }).then(json);

export const fetchBuilderApi = builderId =>
  secureCall(`/builders/${builderId}`, {
    method: 'GET',
    headers: JSON_HEADER,
  }).then(json);

export const refreshSearchKeyApi = () =>
  secureCall(`/foods/search-key`).then(json);

export const fetchUserFoodsApi = () => secureCall('/user_foods').then(json);
export const fetchUserFoodApi = foodId =>
  secureCall(`/user_foods/${foodId}`).then(json);
export const deleteUserFoodApi = foodId =>
  secureCall(`/user_foods/${foodId}`, {
    method: 'DELETE',
  });

export const createUserFoodApi = ({ name, serving, nutrients, permissions }) =>
  secureCall('/user_foods', {
    method: 'POST',
    headers: JSON_HEADER,
    body: JSON.stringify({
      name,
      serving,
      nutrients,
      permissions,
    }),
  }).then(json);

export const updateUserFoodApi = (
  foodId,
  { name, serving, nutrients, permissions }
) =>
  secureCall(`/user_foods/${foodId}`, {
    method: 'PATCH',
    headers: JSON_HEADER,
    body: JSON.stringify({
      name,
      serving,
      nutrients,
      permissions,
    }),
  }).then(json);
