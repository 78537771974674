import { connect } from 'react-redux';
import Toolbar from './Toolbar';
import { getCurrentFoodDiaryFormattedDate } from 'redux/modules/foodDiaries/foodDiaries.selectors';
import {
  getSumMealGroups,
  getCurrentDiaryEnergy,
} from 'redux/modules/meals/meals.selectors';
import {
  getIsInCompareMode,
  getCompareDiaryIds,
} from 'redux/modules/UI/clientView';
import { updateOnboard } from 'redux/modules/account/account.actions';
import { getCanRunVerify } from 'redux/modules/account/onboard.selectors';

const mapStateToProps = state => ({
  date: getCurrentFoodDiaryFormattedDate(state),
  mealCount: getSumMealGroups(state),
  energy: getCurrentDiaryEnergy(state),

  compareMode: getIsInCompareMode(state),
  compareCount: getCompareDiaryIds(state).length,

  canRunVerify: getCanRunVerify(state),
});

const mapDispatchToProps = dispatch => ({
  updateOnboard: onboard => dispatch(updateOnboard(onboard)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
