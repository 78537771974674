import storage from '../../../utils/storage';
import types from './account.types';
import authTypes from '../auth/auth.types';
import get from 'lodash/get';
import window from '../../../utils/window';

function getDefaultLocale() {
  const getLocale = () => {
    if (navigator.languages !== undefined) return navigator.languages[0];
    else return navigator.language;
  };

  const l = getLocale().toLowerCase();
  if (l.startsWith('fr')) return 'fr';
  else return 'en';
}

const initialLocale = () => {
  const pathname = get(window.location(), 'search', '').split('=');
  const prop = pathname[0].split('?')[1];
  const value = pathname[1];
  if ((prop === 'locale' && value === 'fr') || value === 'en') {
    storage.put('locale', value);
    return value;
  }

  const pastLocale = storage.get('locale');
  if (pastLocale) return pastLocale;

  let defaultLocale;
  try {
    defaultLocale = getDefaultLocale();
  } catch (err) {
    defaultLocale = 'en';
  }

  storage.put('locale', defaultLocale);
  return defaultLocale;
};

const initialState = () => ({
  locale: initialLocale(),
  theme: 'light', // it will be easy to change if we want to make it configurable
  full_name: '',
  settings_open: false,
  nutrients: [],
  onboard: {},
  signature: null,
  email: '',
  tags: [],
});

export default function reducer(state = initialState(), action = {}) {
  switch (action.type) {
    case types.SWITCH_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    case authTypes.LOGIN_SUCCESS:
    case authTypes.PROFILE_LOAD_SUCCESS:
    case types.UPDATE_NAME_SUCCESS:
    case types.UPDATE_TOOLBOX_SUCCESS:
    case types.UPDATE_TIME_FORMAT_SUCCESS:
      const {
        full_name,
        locale,
        nutrients,
        onboard,
        prefer_twelve_hour,
        signature,
        email,
        logTimestamp,
        tags,
      } = action.info || action.response;
      return {
        ...state,
        locale,
        full_name,
        onboard,
        prefer_twelve_hour,
        signature,
        logTimestamp,
        email,
        nutrients: nutrients && nutrients.length > 0 ? nutrients : [],
        tags,
      };
    case types.UPDATE_ONBOARD_SUCCESS:
      return {
        ...state,
        onboard: {
          ...get(state, 'onboard', {}),
          ...get(action, ['response', 'onboard'], {}),
        },
      };
    case types.OPEN_SETTINGS:
      return {
        ...state,
        settings_open: true,
      };
    case types.CLOSE_SETTINGS:
      return {
        ...state,
        settings_open: false,
      };
    default:
      return state;
  }
}
