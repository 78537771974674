import React, { useState } from 'react';
import styled from 'styled-components';
import { ONE_HOUR_HEIGHT } from '../DayScale';
import MealMosaic from '../MealMosaic';
import TimeItem from '../TimeItem';
import { THUMBNAIL_SIZE } from '../MealMosaic/MealMosaic';
import DayGrid from '../DayGrid';
import Measure from 'react-measure';

const Wrapper = styled.div`
  position: relative;
  width: ${(THUMBNAIL_SIZE + 6) * 2}px;
  height: ${x => x.hoursCount * ONE_HOUR_HEIGHT}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

function DayCharts(props) {
  const [width, setWidth] = useState(160);
  const { groups, startHour, endHour } = props;
  const hoursCount = endHour - startHour;

  const _makeOpenModal = pos => () => {
    const { openModal, id } = props;
    openModal({ pos, diaryId: id });
  };

  if (!groups) return null;
  return (
    <Wrapper hoursCount={hoursCount}>
      {groups.map((mealIds, i) => (
        <Measure
          key={mealIds.join('.')}
          bounds
          onResize={contentRect => setWidth(contentRect.bounds.width)}
        >
          {({ measureRef }) => (
            <div ref={measureRef}>
              <TimeItem
                ids={mealIds}
                key={mealIds.join('_')}
                startHour={startHour}
                endHour={endHour}
              >
                <MealMosaic
                  ids={mealIds}
                  width={width}
                  onClick={_makeOpenModal(i)}
                />
              </TimeItem>
            </div>
          )}
        </Measure>
      ))}
      <DayGrid hoursCount={hoursCount} />
    </Wrapper>
  );
}

export default DayCharts;
