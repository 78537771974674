import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import { SORT_ORDERS } from 'constants/index';

// Highly inspired by this: https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

// https://stackoverflow.com/a/51082563/5059407
export const useTraceUpdate = function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
};

// https://usehooks.com/useWindowSize/
export function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

// https://reacttraining.com/react-router/web/example/query-parameters
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 *
 * @param {string} searchText
 * @param {arraty<object>} items
 * @param {array<string>} props
 */
export function useVisibleElements(searchText = '', items = [], props = []) {
  if (!searchText) return items;

  // Search technique based on http://jsperf.com/substring-test
  const regex = new RegExp(searchText, 'i');
  return items.filter(t => props.some(p => t[p] && regex.test(t[p])));
}

/**
 *
 * @param {arraty<object>} items
 * @param {string} sortColumn
 * @param {string} sortOrder
 */
export function sortElements(items = [], sortColumn, sortOrder) {
  let sortedItems = items;
  if (sortColumn) {
    sortedItems = sortBy(sortedItems, sortColumn);
    if (sortOrder !== SORT_ORDERS.ASCENDING) {
      sortedItems = sortedItems.reverse();
    }
  }
  return sortedItems;
}
