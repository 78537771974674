export const periodToDate = periodTimestamp => new Date(periodTimestamp * 1000);

export const dateStringToDate = dateString => {
  const startTime = new Date(dateString);
  return new Date(startTime.getTime() + startTime.getTimezoneOffset() * 60000);
};

export const dateStringToAge = dateString => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age + m / 12;
};

export const timeStringToSeconds = timeString => {
  const [hour, minute, seconds] = timeString
    .split(':')
    .map(x => parseInt(x, 10));
  return seconds + minute * 60 + hour * 3600;
};

export const todayDateString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = _formatNum(today.getMonth() + 1);
  const day = _formatNum(today.getDate());
  return `${year}-${month}-${day}`;
};

function _formatNum(num) {
  return num < 10 ? `0${num}` : num;
}

export const getDaysBetweenTodayAndADate = date =>
  Math.round((dateStringToDate(new Date()) - date) / (1000 * 60 * 60 * 24));

export default {
  dateStringToDate,
  dateStringToAge,
  todayDateString,
  getDaysBetweenTodayAndADate,
  periodToDate,
};
