import { store } from 'redux/store';
import React from 'react';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const confirmAction = (
  { title, explanation, confirm, cancel },
  onConfirm,
  onCancel
) => {
  store.dispatch(
    openModal(
      MODAL_TYPES.CONFIRMATION_DIALOG,
      {
        title,
        explanation,
        confirm,
        cancel,
        onConfirm,
        onCancel,
      },
      true
    )
  );
};

export const openLoomVideo = id => {
  store.dispatch(openModal(MODAL_TYPES.LOOM_VIDEO, { id }, true));
};

export const openYoutubeVideo = (id, onNext) => {
  store.dispatch(openModal(MODAL_TYPES.YOUTUBE_VIDEO, { id, onNext }, true));
};
export function vhToPx(value) {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    y = w.innerHeight || e.clientHeight || g.clientHeight;

  var result = (y * value) / 100;
  return result;
}
export function vwToPx(value) {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    y = w.innerWidth || e.clientWidth || g.clientWidth;

  var result = (y * value) / 100;
  return result;
}

export const safeLocale = l => {
  try {
    let locale = l.slice(0, 2).toLowerCase();
    return locale === 'fr' ? 'fr' : 'en';
  } catch (err) {
    return 'en';
  }
};

const makeRender = icon => ({ className, ...rest }) => (
  <FontAwesomeIcon className={className} icon={icon} {...rest} />
);
export const makeCustomIcon = icon => ({
  strategy: 'custom',
  render: makeRender(icon),
});
