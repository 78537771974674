import React, { useState, useEffect } from 'react';
import { Select } from 'components/material/Select';
import { formatUnitInfoWithDimension } from '../../../../../utils/selectedFoods';
import first from 'lodash/first';

const ID = 'unit_measure_id';

function findUnit(unit, units) {
  if (!unit) return first(units);

  const next = units.find(u => `${u.value}` === `${unit[ID]}`);
  return next || first(units);
}

export function UnitPicker({ value, onChange, children }) {
  const options = children.map(u => ({
    value: `${u[ID]}`,
    label: formatUnitInfoWithDimension(u.unit, u.unit_name, u.unit_dimension),
  }));
  const [val, setVal] = useState(findUnit(value, options));
  useEffect(() => {
    const u = findUnit(value, options);
    if (val.value !== u.value) {
      setVal(u);
    }
  }, [value, children]);

  return (
    <Select
      outlined
      value={`${val.value}`}
      options={options}
      onChange={e => {
        setVal(options[e.detail.index]);
        onChange(children[e.detail.index]);
      }}
      rootProps={{ style: { width: '100%' } }}
    />
  );
}
