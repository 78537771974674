export const pre = 'keenoa/reportSettings/';

export default {
  FETCH_REPORT_SETTING_REQUEST: `${pre}FETCH_REPORT_SETTING_REQUEST`,
  FETCH_REPORT_SETTING_SUCCESS: `${pre}FETCH_REPORT_SETTING_SUCCESS`,
  FETCH_REPORT_SETTING_FAILURE: `${pre}FETCH_REPORT_SETTING_FAILURE`,

  UPDATE_REPORT_SETTING_REQUEST: `${pre}UPDATE_REPORT_SETTING_REQUEST`,
  UPDATE_REPORT_SETTING_SUCCESS: `${pre}UPDATE_REPORT_SETTING_SUCCESS`,
  UPDATE_REPORT_SETTING_FAILURE: `${pre}UPDATE_REPORT_SETTING_FAILURE`,

  UPDATE_REPORT_LOGO_REQUEST: `${pre}UPDATE_REPORT_LOGO_REQUEST`,
  UPDATE_REPORT_LOGO_SUCCESS: `${pre}UPDATE_REPORT_LOGO_SUCCESS`,
  UPDATE_REPORT_LOGO_FAILURE: `${pre}UPDATE_REPORT_LOGO_FAILURE`,
};
