import React from 'react';
import Modal from 'components/Modal';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Button } from 'components/material/Button';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import H2 from 'components/H2';
import Typography from 'components/material/Typography';
import Spacer from 'components/Spacer';

const messages = defineMessages({
  header: { id: 'revokedClient.header' },
  explanatinon: { id: 'revokedClient.explanation' },
  opening: { id: 'revokedClient.opening' },
  continue: { id: 'revokedClient.continue' },
});

const WarningIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.palette.warning};
`;

class RevokedModal extends React.PureComponent {
  render() {
    const { isOpen, onExit } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onExit}>
        <H2>
          <WarningIcon icon={['fad', 'exclamation-triangle']} />{' '}
          <FormattedMessage {...messages.header} />
        </H2>
        <Spacer spacing="lg" />
        <Typography use="body1" rank="primary">
          <FormattedMessage {...messages.explanatinon} />
        </Typography>
        <Spacer spacing="md" />
        <Typography use="body1" rank="primary">
          <FormattedMessage {...messages.opening} />
        </Typography>
        <Spacer spacing="lg" />
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button unelevated onClick={onExit}>
            <FormattedMessage {...messages.continue} />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default RevokedModal;
