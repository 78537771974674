import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Courses from './Courses';

export default function() {
  return (
    <Switch>
      <Route path="/courses">
        <Courses />
      </Route>
    </Switch>
  );
}
