import { createSelector } from 'reselect';
import get from 'lodash/get';

const _getRoot = state => state.entries;

/** (state) */
export const getById = createSelector(
  _getRoot,
  x => x.byId
);
/** (state, entryId) */
export const getEntry = createSelector(
  [getById, (_, entryId) => entryId],
  (byId, id) => get(byId, id)
);
/** (state, entryId) */
export const getEntryFoodId = createSelector(
  [getEntry],
  entry => get(entry, 'food_id')
);
/** (state, entryId) */
export const getEntryDescription = createSelector(
  [getEntry],
  entry => get(entry, 'food_description')
);
/** (state, entryId) */
export const getEntryQuantity = createSelector(
  [getEntry],
  entry => get(entry, 'quantity', 0)
);
/** (state, entryId) */
export const getEntryMeasureId = createSelector(
  [getEntry],
  entry => get(entry, 'unit_measure_id', null)
);

export default {
  getEntry,
};
