import actions, { addSource } from './billing.actions';
import {
  getIsFetchingInvoices,
  getIsFetchingSubscriptions,
} from './billing.selectors';
import billingApi from '../../../api/billing';
import { normalize } from 'normalizr';
import * as schema from '../../schemas';

export const fetchInvoices = () => (dispatch, getState) => {
  if (getIsFetchingInvoices(getState())) {
    return Promise.resolve();
  }
  dispatch(actions.fetchInvoices());
  return billingApi
    .fetchInvoices()
    .then(res => normalize(res, schema.arrayOfInvoice))
    .then(response => dispatch(actions.fetchInvoicesSuccess(response)))
    .catch(err => dispatch(actions.fetchInvoicesFailure(err)));
};

export const fetchSubscriptions = () => (dispatch, getState) => {
  if (getIsFetchingSubscriptions(getState())) {
    return Promise.resolve();
  }
  dispatch(actions.fetchSubscriptions());
  return billingApi
    .fetchSubscriptions()
    .then(res => normalize(res, schema.arrayOfSubscription))
    .then(response => dispatch(actions.fetchSubscriptionsSuccess(response)))
    .catch(err => dispatch(actions.fetchSubscriptionsFailure(err)));
};

export default {
  fetchInvoices,
  fetchSubscriptions,

  addSource,
};
