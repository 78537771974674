import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import SearchBox from './SearchBox';
import Hits from './Hits';

function SearchFood({ searchKey }) {
  const searchClient = algoliasearch('CLLXME7U1H', searchKey);

  return (
    <InstantSearch searchClient={searchClient} indexName="version_2">
      <SearchBox />
      <Hits />
    </InstantSearch>
  );
}

export default SearchFood;
