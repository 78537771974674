import ReportCheckbox from './ReportCheckbox/ReportCheckbox';
import { connect } from 'react-redux';
import {
  getSelectedReportsCount,
  selectReports,
  unselectAllReports,
} from 'redux/modules/UI/reportsView';

const mapStateToProps = (state, { ids }) => {
  const count = getSelectedReportsCount(state);
  const selected = !!count;
  const indeterminate = selected && count < ids.length;
  return { selected, indeterminate };
};

const mapDispatchToProps = (dispatch, { ids }) => ({
  select: () => dispatch(selectReports(ids)),
  unselect: () => dispatch(unselectAllReports()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportCheckbox);
