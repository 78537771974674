import { connect } from 'react-redux';
import {
  getMealPhoto,
  getMealTime,
  getMealEmoji,
} from 'redux/modules/meals/meals.selectors';
import MealImage from './MealImage';

const mapStateToProps = (state, ownProps) => ({
  photo: getMealPhoto(state, ownProps.id),
  time: getMealTime(state, ownProps.id),
  emoji: getMealEmoji(state, ownProps.id),
});

export default connect(mapStateToProps, null)(MealImage);
