import types, { pre } from './clients.types';
import { combineReducers } from 'redux';
import mapValues from 'lodash/mapValues';
import { foodDiariesTypes } from '../foodDiaries';
import nutrimentsConstants, {
  defaultClientNutrimentsKeys,
} from '../../../constants/nutriments';
import uniq from 'lodash/uniq';
import { CLIENT_STATUS } from '../../../constants/clientStatus';
import { makeFetchStatus, makeError } from '../../../utils/standardReducers';
import { MODAL_TYPES, HIDE_MODAL } from '../modals/modals';
import { FETCH_STATUS } from '../../../constants/fetchStatus';
import studiesTypes from '../studies/studies.types';

const defaultNutrients = defaultClientNutrimentsKeys.map(
  key => nutrimentsConstants[key]
);

export const byId = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_CLIENTS_SUCCESS:
    case studiesTypes.GENERATE_PARTICIPANTS_SUCCESS:
    case types.UPDATE_CLIENT_STATUS_SUCCESS:
    case types.UPDATE_SETTINGS_SUCCESS:
    case types.UPDATE_CLIENT_NUTRIENTS_SUCCESS:
      return {
        ...state,
        ...mapValues(action.response.entities.clients, client => ({
          ...state[client.id],
          ...client,
          id: client.id || client.invite_email || client.code,
          nutrients:
            client.nutrients && client.nutrients.length > 0
              ? client.nutrients
              : defaultNutrients,
        })),
      };
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        [action.clientId]: {
          ...state[action.clientId],
          ...action.response,
        },
      };
    case types.ADD_CLIENT_SUCCESS:
      return {
        ...state,
        [action.email]: {
          id: action.email,
          invite_email: action.email,
          status: CLIENT_STATUS.PENDING,
        },
      };
    case types.RENEW_CLIENT_SUCCESS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          status: CLIENT_STATUS.PENDING,
        },
      };
    case types.REMOVE_INVITE_SUCCESS:
      const copy = { ...state };
      delete copy[action.id];
      return copy;
    case foodDiariesTypes.FETCH_FOOD_DIARIES_SUCCESS:
      return _handleIncomingFoodDiaries(state, action);
    default:
      return state;
  }
};

const tokens = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_CLIENT_TOKEN_SUCCESS:
      return {
        ...state,
        [action.clientId]: action.token,
      };
    default:
      return state;
  }
};

function _handleIncomingFoodDiaries(state, action) {
  const { clientId, response } = action;
  return {
    ...state,
    [clientId]: {
      ...state[clientId],
      foodDiariesIds: response.result,
    },
  };
}

export const allIds = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_CLIENTS_SUCCESS:
      return action.response.result;
    case types.ADD_CLIENT_SUCCESS:
      return [...state, action.email];
    case types.REMOVE_INVITE_SUCCESS:
      return [...state.filter(x => x !== action.id)];
    case studiesTypes.GENERATE_PARTICIPANTS_SUCCESS:
      return [...state, ...action.response.result];
    default:
      return state;
  }
};

const searchString = (state = '', action) => {
  switch (action.type) {
    case types.UPDATE_SEARCH_STRING:
      return action.value;
    default:
      return state;
  }
};

const currentId = (state = null, action) => {
  switch (action.type) {
    case types.SET_CURRENT_CLIENT:
      return action.clientId;
    case types.REMOVE_CURRENT_CLIENT:
      return null;
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_CLIENTS_REQUEST:
      return true;
    case types.FETCH_CLIENTS_SUCCESS:
    case types.FETCH_CLIENTS_FAILURE:
      return false;
    default:
      return state;
  }
};

export const selectedIds = (state = [], action) => {
  switch (action.type) {
    case types.UNSELECT_ALL_CLIENTS:
      return [];
    case types.SELECT_CLIENTS:
      return action.ids;
    case types.SELECT_CLIENT:
      return uniq([...state, action.id]);
    case types.UNSELECT_CLIENT:
      return state.filter(x => x !== action.id);
    default:
      return state;
  }
};

const _inviteError = makeError(`${pre}ADD_CLIENT`);
const inviteError = (state = null, action) => {
  if (
    action.type === HIDE_MODAL &&
    action.modalType === MODAL_TYPES.NEW_CLIENT
  ) {
    return null;
  }
  return _inviteError(state, action);
};

const _inviteStatus = makeFetchStatus(`${pre}ADD_CLIENT`);
const inviteStatus = (state = FETCH_STATUS.NONE, action) => {
  if (
    action.type === HIDE_MODAL &&
    action.modalType === MODAL_TYPES.NEW_CLIENT
  ) {
    return FETCH_STATUS.NONE;
  }
  return _inviteStatus(state, action);
};

const _renewStatus = makeFetchStatus(`${pre}RENEW_CLIENT`);
const renewStatus = (state = FETCH_STATUS.NONE, action) => {
  if (
    action.type === HIDE_MODAL &&
    action.modalType === MODAL_TYPES.NEW_CLIENT
  ) {
    return FETCH_STATUS.NONE;
  }
  return _renewStatus(state, action);
};

export default combineReducers({
  byId,
  allIds,
  currentId,
  searchString,
  isFetching,
  tokens,
  selectedIds,

  inviteStatus,
  inviteError,

  renewStatus,
});
