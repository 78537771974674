import { createSelector } from 'reselect';
import get from 'lodash/get';

/** (state) */
const _getRoot = state => get(state, ['builders', 'builderConfigs']);

/** (state) */
export const getById = createSelector(
  _getRoot,
  x => get(x, 'byId')
);

/** (state, builderConfigId) */
export const getBuilderConfig = createSelector(
  [getById, (_, builderConfigId) => builderConfigId],
  (byId, id) => get(byId, id)
);

/** (state, builderConfigId) */
export const getBuilderConfigOptions = createSelector(
  getBuilderConfig,
  config => get(config, 'options', [])
);
/** (state, builderConfigId) */
export const getBuilderConfigBuilderId = createSelector(
  getBuilderConfig,
  config => get(config, 'builder', null)
);

/** (state) */
export const getCreateConfigStatus = createSelector(
  _getRoot,
  x => get(x, 'createConfigStatus')
);
