import React from 'react';

export const SPACINGS = {
  none: 'none',
  small: 'sm',
  medium: 'md',
  large: 'lg',
  xLarge: 'xl',
  xxLarge: 'xxl',
};

export const SIZES = {
  [SPACINGS.none]: 0,
  [SPACINGS.small]: 4,
  [SPACINGS.medium]: 8,
  [SPACINGS.large]: 16,
  [SPACINGS.xLarge]: 32,
  [SPACINGS.xxLarge]: 64,
};

export default function({ spacing = SPACINGS.none }) {
  return <div style={{ height: SIZES[spacing], width: SIZES[spacing] }} />;
}
