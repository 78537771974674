import { connect } from 'react-redux';
import VisualFoodLog from './VisualLog';
import { getIsCurrentClientNoFoodDiary } from 'redux/modules/foodDiaries/foodDiaries.selectors';
import { getCurrentMealGroupCounts } from 'redux/modules/meals/meals.selectors';
import { getIsInCompareMode } from 'redux/modules/UI/clientView';

const mapStateToProps = state => ({
  hasNoFoodDiary: getIsCurrentClientNoFoodDiary(state),
  mealGroupCounts: getCurrentMealGroupCounts(state),
  compareMode: getIsInCompareMode(state),
});

const VisualFoodLogContainer = connect(mapStateToProps)(VisualFoodLog);

export default VisualFoodLogContainer;
