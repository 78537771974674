import CompletePasswordReset from './CompletePasswordReset';
import { connect } from 'react-redux';
import { authOperations } from 'redux/modules/auth';

const mapStateToProps = ({ auth }) => {
  const { passwordReset, executeResetError } = auth;
  return { sent: passwordReset, error: executeResetError };
};
const mapDispatchToProps = dispatch => ({
  onSubmit: (token, password) =>
    dispatch(authOperations.sendPasswordReset(token, password)),
});

const CompletePasswordResetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletePasswordReset);

export default CompletePasswordResetContainer;
