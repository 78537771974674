import types from './reportView.types';
import uuidv4 from 'uuid/v4';
import times from 'lodash/times';
import { WIDGET_TYPES } from '../../../../constants';
import { WIDGET_TYPE_TO_CONFIG } from '../../../../pages/Dashboard/Export/ConfigBox/ContentTab/ContentTab';

export const resetExport = () => ({
  type: types.RESET_EXPORT,
});

export const resumeReport = () => ({
  type: types.RESUME_REPORT,
});

export const openExport = diaryIds => ({
  type: types.OPEN_REPORT_EXPORT,
  diaryIds,
});

export const toggleReportDiary = (diaryId, isTemplateMode) => ({
  type: types.TOGGLE_REPORT_DIARY,
  diaryId,
  isTemplateMode,
});

export const updateLogo = () => ({
  type: types.UPDATE_LOGO,
});

export const getChunks = (widget, getState) => {
  const { type } = widget;
  if (type === WIDGET_TYPES.VISUAL_OVERVIEW) {
    return 3;
  }
  return 1;
};
export const addWidget = (widget, pageIndex, index) => (dispatch, getState) => {
  const id = uuidv4();
  const chunks = getChunks(widget, getState);
  const res = times(chunks, i =>
    dispatch({
      type: types.ADD_WIDGET,
      id: `${id}_${i}`,
      index: index + i,
      pageIndex,
      payload: {
        ...widget,
        id: `${id}_${i}`,
      },
    })
  );
  if (WIDGET_TYPE_TO_CONFIG[widget.type]) {
    dispatch(configWidget(`${id}_0`));
  }
  return res;
};

export const removeWidget = widgetId => ({
  type: types.REMOVE_WIDGET,
  widgetId,
});

export const configWidget = widgetId => ({
  type: types.CONFIG_WIDGET,
  widgetId,
});
export const closeWidgetConfig = () => ({
  type: types.CLOSE_WIDGET_CONFIG,
});

export const reorderWidgets = (startPage, endPage, startIndex, endIndex) => ({
  type: types.REORDER_WIDGET,
  startPage,
  endPage,
  startIndex,
  endIndex,
});

export const updateWidget = (widgetId, payload) => ({
  type: types.UPDATE_WIDGET,
  widgetId,
  payload,
});

export const updateWidgetTemp = (widgetId, payload) => ({
  type: types.UPDATE_WIDGET_TEMP,
  widgetId,
  payload,
});

export const setWidgetTitle = (widgetId, title) => ({
  type: types.SET_WIDGET_TITLE,
  widgetId,
  title,
});

export const setWidgetSection = (widgetId, section, value) => ({
  type: types.SET_WIDGET_SECTION,
  widgetId,
  section,
  value,
});

export const dragWidgetStart = widgetId => ({
  type: types.DRAG_WIDGET_START,
  widgetId,
});
export const dragWidgetEnd = widgetId => ({
  type: types.DRAG_WIDGET_END,
  widgetId,
});

export const startPdfPreProcessing = itemCount => ({
  type: types.START_PDF_PRE_PROCESSING,
  itemCount,
});
export const updatePdfPreProcessing = () => ({
  type: types.UPDATE_PDF_PRE_PROCESSING,
});
export const donePdfPreProcessing = () => ({
  type: types.DONE_PDF_PRE_PROCESSING,
});

export const updateWidgetImage = (widgetId, image) => ({
  type: types.UPDATE_WIDGET_IMAGE,
  widgetId,
  image,
});

export const updateWidgetText = (widgetId, markdown) => ({
  type: types.UPDATE_WIDGET_TEXT,
  widgetId,
  markdown,
});

export const removePage = pageIndex => ({
  type: types.REMOVE_PAGE,
  pageIndex,
});

export const loadStructure = structure => ({
  type: types.LOAD_STRUCTURE,
  payload: structure,
});

export const loadDiaryIds = diaryIds => ({
  type: types.LOAD_DIARY_IDS,
  payload: {
    diaryIds,
  },
});

export const stageTemplate = templateId => ({
  type: types.STAGE_TEMPLATE,
  templateId,
});

export const stageReport = reportId => ({
  type: types.STAGE_REPORT,
  reportId,
});
