import { connect } from 'react-redux';
import Verify from './Verify';
import {
  getCurrentFoodDiaryId,
  getCurrentFoodDiaryVerified,
} from 'redux/modules/foodDiaries/foodDiaries.selectors';
import { updateDiaryVerify } from 'redux/modules/foodDiaries/foodDiaries.actions';

const mapStateToProps = state => ({
  diaryId: getCurrentFoodDiaryId(state),
  isVerified: getCurrentFoodDiaryVerified(state),
});

const mapDispatchToProps = dispatch => ({
  updateVerify: (diaryId, isVerified) =>
    dispatch(updateDiaryVerify(diaryId, isVerified)),
});

const mergeProps = (stateProps, { updateVerify }, ownProps) => ({
  ...stateProps,
  ...ownProps,
  updateVerify: isVerified => updateVerify(stateProps.diaryId, isVerified),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Verify);
