import TemplateActions from './TemplateActions';
import { connect } from 'react-redux';
import { deleteReportTemplate } from 'redux/modules/reportTemplates/reportTemplates.actions';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';

const mapDispatchToProps = (dispatch, { templateId }) => ({
  onEdit: () => {
    dispatch(
      openModal(MODAL_TYPES.CREATE_REPORT_TEMPLATE, { templateId }, true)
    );
  },
  onDelete: () => dispatch(deleteReportTemplate(templateId)),
  onDuplicate: () => {
    dispatch(
      openModal(
        MODAL_TYPES.CREATE_REPORT_TEMPLATE,
        { templateId, isDuplicate: true },
        true
      )
    );
  },
});

export default connect(null, mapDispatchToProps)(TemplateActions);
