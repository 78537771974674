import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Welcome from './Welcome';
import Invite from './Invite';
import Confirm from './Confirm';
import decode from 'jwt-decode';
import { put } from '../../utils/storage';
import { useDispatch } from 'react-redux';
import { loadProfile } from 'redux/modules/auth/auth.operations';
import { useQuery } from '../../utils/hooks';
import { notify } from 'react-notify-toast';
import { defineMessages, useIntl } from 'react-intl';
import Logo from 'components/Logo';

const messages = defineMessages({
  invalidToken: { id: 'onboard.invalidToken' },
});

export default function Onboarding() {
  const dispatch = useDispatch();
  const query = useQuery();
  const token = query.get('token');
  const intl = useIntl();
  let matchWelcome = useRouteMatch('/welcome');

  useEffect(() => {
    if (!token || !matchWelcome || !matchWelcome.isExact) return;

    try {
      const id = decode(token).dietitianId;
      put('token', token);
      put('id', id);
      dispatch(loadProfile());
    } catch (err) {
      notify.show(intl.formatMessage(messages.invalidToken), 'error');
    }
  }, [token]);

  return (
    <>
      <Logo />
      <Switch>
        <Redirect from="/welcome" to="/onboard-1" />
        <Route path={'/onboard-1'}>
          <Welcome />
        </Route>
        <Route path={'/onboard-2'}>
          <Invite />
        </Route>
        <Route path={'/onboard-3'}>
          <Confirm />
        </Route>
      </Switch>
    </>
  );
}
