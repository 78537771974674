import React, { useState, useRef } from 'react';
import Modal from 'components/Modal';
import H2 from 'components/H2';
import { useDispatch } from 'react-redux';
import { MODAL_TYPES, useModal } from 'redux/modules/modals/modals';
import { TextField } from 'components/forms/Textfield';
import Typography from 'components/material/Typography';
import { Button } from 'components/material/Button';
import Spacer from 'components/Spacer';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { createStudy } from 'redux/modules/studies';
import { CircularProgress } from 'components/loaders/CircularProgress';

const messages = defineMessages({
  header: { id: 'newStudyModal.header' },
  content: { id: 'newStudyModal.content' },
  code: { id: 'newStudyModal.code' },
  name: { id: 'newStudyModal.name' },
  create: { id: 'newStudyModal.create' },
  creating: { id: 'newStudyModal.creating' },

  selectLabel: { id: 'newStudyModal.selectLabel' },
  continue: { id: 'newStudyModal.next' },
});

const VALID_CODE_REGEX = new RegExp('[A-Z]{3}');
function NewStudyModal() {
  const intl = useIntl();
  const [isOpen, onExit] = useModal(MODAL_TYPES.NEW_STUDY);
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const codeRef = useRef();
  const dispatch = useDispatch();

  const codeValid = VALID_CODE_REGEX.test(code);
  const _onCreate = async () => {
    setLoading(true);
    try {
      dispatch(createStudy(code, name));
      setName('');
      setCode('');
      onExit();
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onExit}>
      <H2>
        <FormattedMessage {...messages.header} />
      </H2>
      <Typography use="body2" rank="secondary" tag="div">
        <FormattedMessage {...messages.content} />
      </Typography>
      <Spacer spacing="xl" />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 1.25 }}>
          <TextField
            outlined
            value={code}
            maxLength={3}
            inputRef={codeRef}
            onChange={e => setCode((e.target.value || '').toUpperCase())}
            label={intl.formatMessage(messages.code)}
            autoFocus={true}
            invalid={code && !codeValid}
            helpText={{
              persistent: false,
              validationMsg: true,
              children: 'Must be 3 letters',
            }}
          />
        </div>
        <Spacer spacing="sm" />
        <TextField
          outlined
          value={name}
          style={{ flex: 5 }}
          onChange={e => setName(e.target.value)}
          label={intl.formatMessage(messages.name)}
        />
      </div>
      <Button
        style={{ float: 'right', marginTop: '-24px' }}
        unelevated
        icon={loading ? <CircularProgress /> : null}
        disabled={!code || !codeValid || !name || loading}
        onClick={_onCreate}
        label={intl.formatMessage(
          loading ? messages.creating : messages.create
        )}
      />
    </Modal>
  );
}

export default NewStudyModal;
