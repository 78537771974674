import React from 'react';
import { Button } from './material/Button';
import { makeCustomIcon } from 'utils/misc';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Typography from 'components/material/Typography';
import Spacer from 'components/Spacer';
import LinearProgress from './loaders/LinearProgress';

const Box = styled.div`
  position: relative;
  background-color: #e3eefb;
  padding: 15px;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

/**
 * @param {String} label : the name of items ex. "clients"
 * @param {function} onAction
 * @param {[Action]} actions:
 *  - color
 *  - icon
 *  - message
 *  - id
 */
function TableActions({ actions, onAction, label, selectCount, loading }) {
  const renderActions = () => {
    return actions.length ? (
      actions.map(({ id, color, icon, message }) => {
        return (
          <Button
            key={id}
            onClick={() => onAction(id)}
            rank={color}
            icon={makeCustomIcon(['far', icon])}
          >
            <FormattedMessage {...message} />
          </Button>
        );
      })
    ) : (
      <span style={{ padding: 10 }}>
        <FormattedMessage
          id="tableActions.noActions"
          defaultMessage={`no actions`}
          values={{ label }}
        />
      </span>
    );
  };

  if (!selectCount) return null;

  const renderLoader = () => {
    if (!loading) return null;
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          background: 'rgba(255,255,255,0.5)',
        }}
      >
        <LinearProgress style={{ position: 'absolute', bottom: 0 }} />
      </div>
    );
  };

  return (
    <Box>
      {renderLoader()}
      <Typography use="body2" rank="primary">
        {selectCount === 1 ? (
          <FormattedMessage
            id="tableActions.selected"
            defaultMessage={`1 selected {label}`}
            values={{ label }}
          />
        ) : (
          <FormattedMessage
            id="tableActions.selecteds"
            defaultMessage={`{count} selected {label}s`}
            values={{ label, count: selectCount }}
          />
        )}
      </Typography>
      <Spacer spacing="lg" />
      {renderActions()}
    </Box>
  );
}

export default TableActions;
