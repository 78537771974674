import { createSelector } from 'reselect';
import get from 'lodash/get';

/** (state) */
const _getRoot = state => get(state, ['builders', 'builders']);

/** (state) */
export const getById = createSelector(
  _getRoot,
  x => get(x, 'byId')
);

/** (state, builderId) */
export const getBuilder = createSelector(
  [getById, (_, builderId) => builderId],
  (byId, builderId) => get(byId, builderId, null)
);
/** (state, builderId, optionId) */
export const getBuilderOptionGroupId = createSelector(
  [getBuilder, (_, __, optionId) => optionId],
  (builder, optionId) => {
    if (!builder) return null;

    const getGroupId = (node, group) => {
      if (get(node, 'id') === optionId) return get(group, 'id');

      const groups = get(node, 'optionsGroups', []);
      if (!groups.length) return null;

      for (let i = 0; i < groups.length; ++i) {
        const group = groups[i];

        const options = get(group, 'options', []);
        for (let j = 0; j < options.length; ++j) {
          const id = getGroupId(get(options, j), group);
          if (id) return id; // bubble up
        }
      }
      return null;
    };
    return getGroupId(builder);
  }
);
/** (state, builderId) */
export const getBuilderName = createSelector(
  [getBuilder],
  builder => get(builder, 'name', '')
);
