const pre = 'keenoa/UI/userFoodsView/';

export default {
  SET_STATUS_FILTER: `${pre}SET_STATUS_FILTER`,
  INVERT_SORT_ORDER: `${pre}INVERT_SORT_ORDER`,
  SELECT_SORT_COLUMN: `${pre}SELECT_SORT_COLUMN`,

  SELECT_USER_FOOD: `${pre}SELECT_USER_FOOD`,
  UNSELECT_USER_FOOD: `${pre}UNSELECT_USER_FOOD`,
  SELECT_USER_FOODS: `${pre}SELECT_USER_FOODS`,
  UNSELECT_ALL_USER_FOODS: `${pre}SELECT_ALL_USER_FOODS`,
};
