import types from './foodDiaries.types';
import { updateDiaryVerifyApi } from '../../../api/foodDiaries';
import { normalize } from 'normalizr';
import schemas from '../../schemas';

export const fetchDiaries = (startDate, endDate, clientId) => ({
  type: types.FETCH_FOOD_DIARIES_REQUEST,
  startDate,
  endDate,
  clientId,
});
export const fetchDiariesSuccess = (
  startDate,
  endDate,
  response,
  clientId
) => ({
  type: types.FETCH_FOOD_DIARIES_SUCCESS,
  startDate,
  endDate,
  response,
  clientId,
});
export const fetchDiariesFailure = (startDate, endDate, clientId, error) => ({
  type: types.FETCH_FOOD_DIARIES_FAILURE,
  startDate,
  endDate,
  clientId,
  error,
});

export const selectFoodDiary = (foodDiaryId = null) => ({
  type: types.SET_CURRENT_FOOD_DIARY,
  foodDiaryId,
});

export const fetchDiary = id => ({
  type: types.FETCH_FOOD_DIARY_REQUEST,
  id,
});
export const fetchDiarySuccess = (id, response) => ({
  type: types.FETCH_FOOD_DIARY_SUCCESS,
  id,
  response,
});
export const fetchDiaryFailure = (id, error) => ({
  type: types.FETCH_FOOD_DIARY_FAILURE,
  id,
  error,
});

export const updateDiaryVerify = (diaryId, isVerified) => ({
  types: [
    types.SET_DIARY_VERIFY_REQUEST,
    types.SET_DIARY_VERIFY_SUCCESS,
    types.SET_DIARY_VERIFY_FAILURE,
  ],
  callAPI: () =>
    updateDiaryVerifyApi(diaryId, isVerified).then(res =>
      normalize(res, schemas.foodDiary)
    ),
  payload: {
    diaryId,
    isVerified,
  },
});

export default {
  fetchDiaries,
  fetchDiariesSuccess,
  fetchDiariesFailure,
  selectFoodDiary,
  fetchDiary,
  fetchDiarySuccess,
  fetchDiaryFailure,
};
