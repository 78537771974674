import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Spacer from 'components/Spacer';
import Typography from 'components/material/Typography';
import { useQuery } from 'utils/hooks';
import styled from 'styled-components';

const messages = defineMessages({
  enroll: {
    id: 'courses.enroll',
  },
  learnerIntro: {
    id: 'courses.learnerIntro',
  },
  instructorIntro: {
    id: 'courses.instructorIntro',
  },
});

const CourseExplanation = styled.div`
  color: ${props => props.theme.palette.text.secondary};
`;

export function CourseRegistrationHeader() {
  const query = useQuery();
  const courseName = query.get('name');
  const role = query.get('role');

  return (
    <>
      <Typography use="overline" rank="tertiary">
        <FormattedMessage {...messages.enroll} />
      </Typography>
      <Typography use="headline5" rank="primary">
        {courseName}
      </Typography>
      <Spacer spacing="sm" />
      <CourseExplanation className="mdc-typography--caption">
        <FormattedMessage
          {...(role === 'instructor'
            ? messages.instructorIntro
            : messages.learnerIntro)}
        />
      </CourseExplanation>
    </>
  );
}
