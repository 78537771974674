export default {
  'mainNav.clients': 'Clients',
  'mainNav.library': 'Library',
  'leftNav.support': 'Support',
  'leftNav.support.header': 'Support',
  'leftNav.support.body': 'You can contact our Support Team via email at ',

  'library.title': 'My library',
  'library.back': 'Back to clients',
  'library.templates': 'Templates',
  'library.foods': 'Foods',

  'dashboard.menu2.diaries': 'Diary',
  'dashboard.menu2.reports': 'Reports',
  'foodDiary.compareSwitch.onboard.close': 'Close',

  'diary.detailed.hourlyGraph': 'Hourly macronutrients intake',
  'diary.detailed.dailyGraph': 'Daily macronutrients intake',

  'general.on': 'On',
  'general.off': 'Disabled',
  'general.timeformat.title': 'Choose time format :',
  'general.timeformat.subtitle':
    'You can pick the time format that is easier for you to use.',

  'tableActions.selected': `1 selected {label}`,
  'tableActions.selecteds': `{count} selected {label}s`,

  'youtube.next': 'Next video',

  'onboard.invalidToken': 'Expired or invalid access, login required.',

  'onboard.welcome.title': 'Welcome to Keenoa',
  'onboard.welcome.subTitle': 'Here are 4 tips to get you started',
  'onboard.welcome.continue': 'Continue',

  'onboard.invite.title': 'Invite your clients',
  'onboard.invite.subTitle':
    'Add your clients’ email below to send them an invitation to download the Keenoa mobile application which will be connected to your platform.',
  'onboard.invite.video':
    'Curious to see what the app looks like for your client?',
  'onboard.invite.watch': 'Watch a short video',
  'onboard.invite.email': 'Client email #{i}',
  'onboard.invite.invite': 'Send invitation',
  'onboard.invite.invites': 'Send invitations',
  'onboard.invite.preview': 'Preview invitation',

  'onboard.confirm.title': 'Invitations sent',
  'onboard.confirm.to': 'to:',
  'onboard.confirm.explore': 'Explore the Keenoa dietitian platform',

  'onboard.detailed.title': 'Detailed log 🤓',
  'onboard.detailed.content':
    'Keenoa uses a national comprehensive database of foods to breakdown each food item captured in pictures into 30+ nutrients.',
  'onboard.videos.welcome': 'Getting started with Keenoa!',
  'onboard.videos.clientExperience':
    'The experience you will offer to your clients ',
  'onboard.videos.consultation': 'Spice up your consultation',
  'onboard.videos.retention': 'Keep your clients close to you ',

  'onboard.videos.title': 'Short videos',
  'onboard.videos.content':
    'We have prepared short videos to help you get the most of Keenoa.',
  'onboard.videos.tutorial': 'Tutorials',

  'newClient.preview.invite': 'Invite',
  'newClient.preview.from': 'From: ',
  'newClient.preview.acceptHelp':
    'When your client press on this button, he/she is redirected to the App Store or Play Store to download the Keenoa app.',
  'newClient.preview.title': 'Preview invitation email',
  'newClient.preview.to': 'To: ',
  'newClient.preview.subject': 'Subject: ',
  'newClient.preview.subjectRow':
    '{dietitianName}, your dietitian, invited you to use Keenoa',
  'newClient.preview.hi': 'Hi!',
  'newClient.preview.first':
    '{dietitianName} invited you to use the Keenoa mobile application to understand your eating habits and collaborate to reach your nutritional goals. Use the button below to download the app, accept the invitation and get started:',
  'newClient.preview.button': 'Accept invite',
  'newClient.preview.second':
    "If you have any questions, feel free to contact our customer success team anytime. (We're lightning quick at replying.) \n\nWelcome aboard,\nThe Keenoa team\n\n P.S. Need help getting started? Check out our <a href='https://keenoa.com/client/how-it-works' target='_blank'>step by step guide</a>.",
  'newClient.preview.small':
    'If you’re having trouble with the button above, you can directly download the app from the App Store or Play Store. Simply make sure to use the address: {email} when creating your account.',

  'templates.title': 'My report templates',
  'templates.newButton': 'New template',
  'templates.export.backTitle': 'Back to templates',
  'templates.noTemplates.title':
    'No matching template, do you want to create a new one ?',
  'templates.noTemplates.new': 'Create a template',
  'templates.search': 'Search templates',
  'templates.help':
    'Templates allow you to save and reuse a report structure. When you export a report, you will then be able to start from a template instead of a blank page. You can create different templates, for different types of clientele, for your charts or to share with other professionals.',

  'dashboard.menu.templates': 'Report templates',
  'clients.firstName': 'First Name',
  'clients.lastName': 'Last Name',
  'clients.email': 'Invite email',
  'clients.status': 'Status',
  'clients.learnerName': 'Student Name',
  'clients.courses': 'Courses',

  'widgets.clientInformation.firstName': 'First name:',
  'widgets.clientInformation.lastName': 'Last name:',
  'widgets.clientInformation.birthDate': 'Birthdate:',
  'widgets.clientInformation.gender': 'Sex:',
  'widgets.clientInformation.title': 'Personal Information',

  'createTemplate.header': 'Create report template',
  'createTemplate.subheader':
    'Identify your template with a title, description and icon to find it easily when you need it.',
  'createTemplate.create': 'Create',
  'createTemplate.success': '"{emoji} {title}" template created!',
  'createTemplate.header.update': 'Update template',
  'createTemplate.create.update': 'Update',
  'createTemplate.success.update': '{title}{emoji} template updated!',
  'createTemplate.header.duplicate': 'Duplicate template',
  'createTemplate.create.duplicate': 'Duplicate',
  'createTemplate.succes.duplicate': '"{emoji} {title}" template created!',
  'createTemplate.title': 'Title',
  'createTemplate.description': 'Description',
  'createTemplate.icon': 'Icon',

  'export.createTemplate.onboard.title': 'Create a template ⏳',
  'export.createTemplate.onboard.content':
    'Save time by saving a report template. You will then be able to use it to quickly generate reports for other clients or other days.',
  'export.createTemplate.onboard.close': 'Go',

  'templateActions.delete.header': 'Delete the template?',
  'templateActions.delete.explanation':
    'Deleting a template will permanently remove it. You will not be able to use it to generate reports anymore.',
  'templateActions.delete.confirm': 'Yes, delete the template',
  'templateActions.delete.cancel': 'No, keep the template',
  'templateActions.edit': 'Edit',
  'templateActions.delete': 'Delete',
  'templateActions.duplicate': 'Duplicate',

  'rowActions.delete.header': 'Delete the report?',
  'rowActions.delete.explanation':
    'Deleting a report will permanently remove it.',
  'rowActions.delete.confirm': 'Yes, delete the report',
  'rowActions.delete.cancel': 'No, keep the report',
  'rowActions.delete': 'Delete',
  'rowActions.download': 'Download',
  'rowActions.view': 'View in new tab',
  'rowActions.send': 'Send to {name}',

  'finalizeForm.submit': 'Finalize report',
  'finalizeForm.cancel': 'Continue editing',
  'finalizeForm.warning':
    'Once finalized, the report cannot be updated. However, you will be able to duplicate it.',
  'finalizeForm.title': 'Title',
  'finalizeForm.help':
    'Choose a title, that will make it easy for you to keep track of this report and possibly use it again in the future.',

  'templateLinks.update': 'Update',
  'templateLinks.create': 'New template',

  'report.label': 'report',
  'report.settings.logo': 'Your logo',
  'report.settings.colors': 'Your colors',
  'report.settings.colors.principal': 'Primary',
  'logo.idle': 'Drag & Drop your logo or <b> Browse </b>',
  'logo.hover': 'Drag image here...',
  'logo.loading': 'Uploading...',
  'logo.remove': 'Click to remove',
  'logo.invalid': 'Upload error, only jpeg and png images are accepted.',

  'clientList.inviteInstruction':
    'Press this button to invite a client to download the Keenoa app.',
  'clientList.addParticipantsButton': 'Add participants',

  'selectStudyModal.header': 'Select Study',
  'selectStudyModal.content':
    'Please select the study for which you would like to generate participants.',
  'selectStudyModal.newStudy': '➕ Create a new study',
  'selectStudyModal.selectLabel': 'Study',
  'selectStudyModal.next': 'Continue',

  'newStudyModal.header': 'New Study',
  'newStudyModal.content':
    'Please pick a 3-letter code and a name for your study. You will be able to invite and classify participants by study.',
  'newStudyModal.code': 'Code',
  'newStudyModal.name': 'Name',
  'newStudyModal.create': 'Create',
  'newStudyMOdal.creating': 'Creating...',

  'participantCountModal.header': 'Number of participants',
  'participantCountModal.content':
    'Please enter the number of study IDs you wish to generate for your study, i.e., number of participants you plan on recruiting. You may generate up to 100 IDs at a time, but you may generate additional IDs at anytime.',
  'participantCountModal.content1':
    'IMPORTANT: please ensure that you provide each of your participants with a unique ID (generated through this process) and associate it with their respective contact information in a secured key table of your own.',
  'participantCountModal.content2':
    'After downloading the Keenoa application on their phone, your participant will be asked to enter their participant ID. This will allow connection between your Keenoa researcher account and their mobile food diary.',
  'participantCountModal.count': 'Number of participants',
  'participantCountModal.generate': 'Generate',
  'participantCountModal.generating': 'Generating...',
  'participantCountModal.help': 'Max 100.',

  'widgets.dailyMacro.title': 'Daily macronutrient overview',
  'widgets.dailyAverageDistribution.title': 'Daily average distribution',
  'widgets.dailyAverageDistribution.empty': 'No summary available',

  'widgets.nutrientsSummary.title': 'Nutrients summary',

  'widgets.nutrientsSummary.basic': 'Intake',
  'widgets.nutrientsSummary.dri': 'Reference',

  'widgets.nutrientsSummary.advanced': 'Advanced',
  'widgets.nutrientsSummary.average': 'Average',
  'widgets.nutrientsSummary.min': 'Min.',
  'widgets.nutrientsSummary.max': 'Max.',

  'widgets.dailyMarco.protein': 'Prot.',
  'widgets.dailyMacro.fat': 'Fat',
  'widgets.dailyMacro.carbs': 'Carb.',
  'widgets.dailyMacro.alcohol': 'Alcohol',
  'widget.dailyMacro.calories': 'Energy',

  'widget.remove.confirm.title': 'Remove the section ?',
  'widget.remove.confirm.explanation':
    'Are you sure that you want to remove this section from your report ?',
  'widget.remove.confirm.confirm': 'Remove the section',
  'widget.remove.confirm.cancel': 'Keep the section',

  'widgetsConfigs.nutrientsSummary.nutrients': 'Nutrients',
  'widgetsConfigs.nutrientsSummary.sections': 'Sections',
  'widgetsConfigs.dailyAverage.sections': 'Sections',
  'widgetsConfigs.nutrientsSummary.edit': 'Edit',
  'sections.summaryANR': 'Dietary reference intakes (DRI)',
  'sections.summaryAMT': 'Tolerable Upper Intake Level (UL)',
  'sections.summaryAverage': 'Average',
  'sections.summaryMin': 'Min.',
  'sections.summaryMax': 'Max.',
  'widgetsConfigs.nutrientsSummary.reference': 'References',
  'widgetsConfigs.nutrientsSummary.intakes': 'Intakes',
  'widgets.nutrientsSummary.rdaOrAi': 'RDA/AI* (1)',
  'widgets.nutrientsSummary.rdaHelp':
    '(1) Recommended Dietary Allowance (RDA): average daily level of intake sufficient to meet the nutrient requirements of nearly all (97%-98%) healthy people. When an RDA is not available for a nutrient, the adequate intake(AI) can be used as the goal for usual intake by an individual.',
  'widgets.nutrientsSummary.ul': 'UL (2)',
  'widgets.nutrientsSummary.ulHelp':
    '(2) Tolerable Upper Intake Level (UL): maximum daily intake unlikely to cause adverse health effects.',

  'sections.infoFirstName': 'First name',
  'sections.infoLastName': 'Last name',
  'sections.infoBirthdate': 'Birthdate',
  'sections.infoGender': 'Gender',

  'sections.dailProt': 'Protein',
  'sections.dailyCarb': 'Carbohydrate',
  'sections.dailyFat': 'Fat',
  'sections.dailyCal': 'Energy',
  'sections.dailyAlcohol': 'Alcohol',

  'nutrientsModal.configTitle': 'Select nutrients',
  'nutrientsModal.fats': 'Fat',
  'nutrientsModal.proteins': 'Protein',
  'nutrientsModal.sugars': 'Carbohydrate',
  'nutrientsModal.vitamins': 'Vitamins',
  'nutrientsModal.others': 'Others',
  'nutrientsModal.save': 'Save',
  'nutrientsModal.placeholder': 'Nutrient name...',
  'nutrientsModal.selectAll': 'Select all',
  'nutrientsModal.unselectAll': 'Unselect all',

  'widget.richtext.placeholder': 'Add a note or a section for your client...',
  'widget.richText.tooLong':
    'This note is too long. Add a new section and copy a part of this note in it.',
  'widget.visualOverview.morning': 'Meals | Morning ☕',
  'widget.visualOverview.afternoon': 'Meals | Afternoon ☀️',
  'widget.visualOverview.evening': 'Meals | Evening 🌃',

  'widgetConfig.title': 'Edit properties',
  'widgetConfig.visualOverview.startTime': 'Start time',
  'widgetConfig.visualOverview.endTime': 'End time',
  'widgetConfig.visualOverview.invalidTime':
    'The start time must be before the end time.',
  'widgetConfig.visualOverview.tooLargeSpan':
    'The time span must be shorter or equal to 12 hours.',

  'widgetsConfigs.clientInformation.sections': 'Sections',

  'contentTab.summary.title': 'Summary',
  'contentTab.organization.title': 'Organization',
  'contentTab.summary.dailyMacro': 'Daily macronutrient overview',
  'contentTab.summary.clientInfo': 'Client information',
  'contentTab.summary.dailyAverage': 'Daily average distribution',
  'contentTab.summary.nutrientsSummary': 'Nutrients summary',
  'contentTab.recommendations.title': 'Recommendations',
  'contentTab.summary.tab': 'Note or Comment',
  'contentTab.dragWarning':
    'To add content, drag a content square to the page.',

  'contentTab.summary.visualOverview': 'Visual calendar',

  'report.settings.locale': 'Report language',

  'settings.timeFormat': 'Time format',

  'export.top.saved': 'Saved',
  'export.top.saving': 'Saving...',
  'export.tab.content': 'Content',
  'export.tab.design': 'Design',
  'export.tab.design.onboard.title': 'Your branding 🎨',
  'export.tab.design.onboard.content':
    'Add your own logo and colors to make the exports truly yours.',
  'export.tab.design.onboard.close': 'Awesome',
  'export.tab.days': 'Days',
  'export.tab.days.calendar': 'Selected days',
  'export.tab.days.fictive':
    'A fictive client is pre-loaded to help you structure your template with actual data.',
  'export.page.placeholder':
    '💡<b>Drag</b> squares from the <b>content tab</b> and drop them <b>here</b> to build your report.',

  'footer.builtWith': 'Built with',
  'footer.of': 'of',
  'widget.macrotable.average': 'Average',

  'foodLog.totalRow': 'Total :',
  'foodLog.averageRow': 'Average:',
  'foodLog.stdevRow': 'Standard deviation:',
  'foodlog.stdevHelp':
    'The standard deviation (SD) is a measure of how spread numbers from compared days are. For a nutrient, a high SD means that the amount consumed quite differs across the compared days. For more details see <a href="https://en.wikipedia.org/wiki/Standard_deviation" target="_blank" rel="noopener noreferrer">this link</a>.',
  'foodLog.coefVar': 'Coefficient of variation:',
  'foodLog.coefVarHelp':
    "The coefficient of variation represents the ratio of the standard deviation to the mean. In other words, it allows you to compare variation for the different nutrients together; i.e., the nutrients with the highest coefficient of variation has the most variation between days. For more information, you can <a href='https://en.wikipedia.org/wiki/Coefficient_of_variation' target='_blank'>read this</a>.",
  'foodLog.compareMin': 'Min:',
  'foodLog.compareMax': 'Max:',
  'foodDiary.toolbar.days': 'days selected',
  'foodDiary.toolbar.day': 'day selected',
  'foodDiary.toolbar.compareTitle': 'Compare mode',
  'foodLog.basic': 'Basic',
  'foodLog.advanced': 'Advanced',
  'foodLog.dri': 'DRI',

  'foodModal.creator.submit': 'Submit',
  'foodModal.picker.submitting': 'Submitting...',
  'foodModal.creator.ingredients': 'Ingredients / options: ',
  'foodModal.creator.help': 'Please select all required options',
  'foodModal.creator.error':
    'Sorry, an unexpected error occurred. Please try again or contact our support team for help.',
  'searchFood.creator': 'creator',

  'dri.rda': 'RDA/AI*',
  'dri.rda.help': `<b><a href="https://www.canada.ca/en/health-canada/services/food-nutrition/healthy-eating/dietary-reference-intakes/tables.html" target="_blank"  rel="noopener noreferrer">Recommended Dietary Allowance (RDA)</a></b>: average daily level of intake sufficient to meet the nutrient requirements of nearly all (97%-98%) healthy people.
      <b><a href="https://www.canada.ca/en/health-canada/services/food-nutrition/healthy-eating/dietary-reference-intakes/tables.html" target="_blank"  rel="noopener noreferrer">Adequate Intake (AI)</a></b>: established when evidence is insufficient to develop an RDA and is set at a level assumed to ensure nutritional adequacy.`,
  'dri.ul': 'UL',
  'dri.ul.help':
    '<b><a href="https://www.canada.ca/en/health-canada/services/food-nutrition/healthy-eating/dietary-reference-intakes/tables.html" target="_blank"  rel="noopener noreferrer">Tolerable Upper Intake Level (UL)</a></b>: maximum daily intake unlikely to cause adverse health effects.',
  'dri.profile.title': `Edit {firstName}'s profile`,
  'dri.profile.explanation':
    'We need the sex and age of your client to retrieve the <a href="https://www.canada.ca/en/health-canada/services/food-nutrition/healthy-eating/dietary-reference-intakes/tables.html" target="_blank" rel="noopener noreferrer">dietary reference intakes (DRI)</a>.',
  'dri.profile.sex': 'Sex :',
  'dri.profile.done': 'Update profile',
  'dri.profile.birthdate': 'Birthdate :',
  'dri.profile.day': 'Day',
  'dri.profile.month': 'Month',
  'dri.profile.year': 'Year',
  'dri.profile.lactation': 'Lactation',
  'dri.profile.pregnancy': 'Pregnancy',
  'dri.profile.woman': 'Woman',
  'dri.profile.man': 'Man',
  'dri.profile.other': 'Other',
  'dri.noProfile.0': 'To see dietary reference intakes (DRI),',
  'dri.noProfile.1': 'your client information.',
  'dri.noProfile.edit': 'update',

  'signup.welcome': 'Sign up',
  'signup.board': 'Board',
  'signup.boardName': 'College of dietetians name',
  'signup.permitNumber': 'Licence number*',
  'signup.permitNumberExplanation':
    '*We need this information to validate your professional status.',
  'signup.aboutYou': 'Dietitian',
  'signup.credentials': 'Credentials',
  'signup.smallTermsMiddle': ' and the ',
  'signup.subscriptionTerms': 'Registration and Subscription Conditions',
  'signup.logIn': 'Log in',
  'signup.existingAccount': ' Have a Keenoa account ?',

  'client.active': 'Active',
  'client.inactive': 'Inactive',
  'client.pending': 'Pending',
  'client.revoked': 'Revoked',
  'client.archived': 'Archived',
  'client.fictive': 'Fictive',
  'client.sent': 'Sent',
  'client.declined': 'Declined',

  'client.backTitle': 'Back to clients',
  'client.export': 'Export',
  'client.archive': 'Archive',
  'client.remove': 'Remove',
  'client.reInvite': 'Resend request',
  'client.unarchive': 'Unarchive',
  'client.noActions': 'No actions possible',
  'clients.filter.all': 'All clients',
  'clients.filter.allBut': 'Non archived clients',
  'clients.filter.active': 'Active clients',
  'clients.filter.pending': 'Pending clients',
  'clients.filter.archived': 'Archived clients',
  'client.selected': 'selected client',
  'client.selecteds': 'selected clients',
  'clients.participantId': 'Participant ID',
  'clients.acceptDate': 'Accept date',
  'clients.filter.courses.all': 'All courses',
  'clients.filter.studies.all': 'All studies',

  'client.profile.questionActive': 'Intuitive eating questions',
  'client.profile.questionActiveHelp':
    'You can enable or disable the intuitive eating questions (satiety level, etc).',
  'client.profile.diaryTitle': 'Mobile features',
  'client.profile.mobileSubtitle':
    'You can choose the features that you want to activate within your client app.',
  'client.profile.activeNutrients': 'Visible nutrients (max. 4)',
  'client.profile.activeNutrientsHelp':
    'Only visible nutrients will be accessible/visible to your client within the Keenoa app.',
  'client.profile.nutrients.title': "Select your client's visible nutrients",
  'client.profile.edit': 'Edit',

  'clients.archiveSuccess': 'Archival success.',
  'clients.archiveSuccessP': '{count} clients successfully archived.',
  'clients.removeSuccess': 'Pending request removed.',
  'clients.removeSuccesP': '{count} requests were successfully removed.',
  'clients.unarchiveSuccess': 'Unarchiving successful.',
  'clients.unarchiveSuccessP': '{count} clients were successfully unarchived.',
  'clients.reinviteSuccess': 'Invitation successfully re-sent.',
  'clients.reinviteSuccessP': '{count} invitations were successfully re-sent.',
  'clients.title': 'My clients',

  'clients.performance.noActive':
    'No client captured meals in the last 30 days.',
  'clients.performance.yAxisTitle': 'Total daily meal count',
  'clients.performance.help':
    'This graph shows the number of meals (photo taken) by each of your client at a daily interval for the past 30 days. You can use it to find your top clients as well as to identify your clients who need a nudge to stay motivated. Keep the total number of meals growing to increase your client retention through time.',
  'clients.performance.title': 'My clients daily activity',
  'clients.performance.error': 'There was an error loading the usage.',
  'clients.performance.retry': 'Click to retry',

  'welcome.header': 'Welcome to your Keenoa dashboard 🎉',
  'welcome.content':
    'Anthony is a test/fictive user, feel free to click on it to explore the interface.',
  'welcome.footer': 'We are very happy to have you on board, enjoy!',

  'paymentDue.header': 'Credit card required',
  'paymentDue.content':
    'Your free trial is now over. To continue using Keenoa, add a credit card to your account.',
  'paymentDue.continue': 'Ok',

  'addSource.header': 'Add a credit card',

  'billing.title': 'Billing',
  'billing.expiredError':
    'The credit card you provided is expired. Please try again with a different card.',
  'billing.incorectCVC':
    'The provided security code (CVC) is incorrect. Please review your security code.',
  'billing.incorectZIP':
    'The ZIP/postal code provided is incorrect. Please review your ZIP/postal code.',
  'billing.cardDeclined':
    'The credit card you provided was declined. Please try again with a different card.',
  'billing.processingError':
    'An error occurred while processing your credit card. Please try again with a different card.',
  'billing.savingCard': 'Saving...',
  'billing.unknownError':
    'Sorry, an unknown error occur while processing your request. Please try again. If the error persist, you can reach out to our support team (support@keenoa.co).',
  'billing.sourcesHeader': 'Credit cards',
  'billing.addPaymentBtn': 'Add a card',
  'billing.invoicesHeader': 'Payment history',
  'billing.noSources': 'No credit card available',
  'billing.expires': 'Expires',

  'testClient.header': 'Fictive client',
  'testClient.explanation':
    'Fictive clients allow you to explore the platform. It is not possible to edit their food diary.',
  'testClient.opening':
    'Once your own clients will be on the platform, you will have full control of their food diaries.',
  'testClient.invite': 'Invite a client',

  'revokedClient.header': 'Revoked client',
  'revokedClient.explanation':
    'This client decided to revoke your access to their informations.',
  'revokedClient.opening':
    'You can still view their diaries for the days prior to the revocation of access. However, you can no longer edit it and will not receive new informations concerning this client.',
  'revokedClient.continue': 'Continue',

  'editEntry.foodNamePlaceholder': 'Food name',
  'editEntry.title': 'Edit a food entry',
  'editEntry.titleNew': 'Add a food entry',

  'entries.addEntryHelp': 'The entry is linked to: ',
  'entries.viewMore': 'View all entries',
  'entries.viewLess': 'View less entries',
  'entries.noEntry': 'No entry for this photo',

  'messages.viewMore': 'View more comments',
  'messages.viewLess': 'View less comments',
  'messages.send': 'Send',
  'messages.sendHelp': 'Press Enter to send',
  'messages.paragraphHelp': 'Press Shift+Enter to add a new paragraph',
  'messages.sendPlaceholder': 'Write a comment...',

  'toolbox.title': 'Nutrient toolbox',
  'toolbox.instruction':
    'Click on any nutrient of interest to add it to the table below.',

  'answers.why.0': 'Bored',
  'answers.why.1': 'Stressed',
  'answers.why.2': 'Sad',
  'answers.why.3': 'Social',
  'answers.why.4': 'It was time',
  'answers.why.5': 'Tired',
  'answers.why.6': 'Hungry',
  'answers.why.7': 'Why not?',
  'answers.why.8': 'Thirsty',

  'answers.how.0': 'Forgettable',
  'answers.how.1': 'Good',
  'answers.how.2': 'Awesome',

  'answers.feel.0': 'Happy',
  'answers.feel.1': 'Guilty',
  'answers.feel.2': 'Satisfied',
  'answers.feel.3': 'Disappointed',

  'answers.hungriness.0': 'Starving',
  'answers.hungriness.1': 'Lightly Hungry',
  'answers.hungriness.2': 'Not Hungry',
  'answers.hungriness.3': 'Pretty Hungry',
  'answers.hungriness.4': 'Hungry',

  'answers.fullness.0': 'Stuffed',
  'answers.fullness.1': 'Comfortable',
  'answers.fullness.2': 'Not full yet',
  'answers.fullness.3': 'Very full',
  'answers.fullness.4': 'Full',

  'questions.why': 'Why are you about to eat ?',
  'questions.how': 'How was it ?',
  'questions.feel': 'How do you feel ?',
  'questions.fullness': 'How full are you ? ',
  'questions.hungriness': 'How hungry are you ? ',

  'mindful.help.title': 'Mindfulness Help: What your client sees.',
  'mindful.help.before':
    'When {firstName} takes a picture, we ask him/her these questions :',
  'mindful.help.after':
    'Some questions are configured to be answered after the meal :',

  'meal.mindfullness': 'Mindfulness',
  'meal.description': 'Description',
  'meal.entries': 'Food entries',
  'meal.mindfullnes.after': 'After :',
  'meal.mindfullness.before': 'Before :',

  'meal.forgot.help':
    'When your clients forget to take a picture, they can still add their meal. In that case, the picture is replaced by an emoji of their choice.',

  'foodDiary.toolbar.meal': 'meal',
  'foodDiary.toolbar.meals': 'meals',
  'foodDiary.toolbar.verified': 'Verify day:',
  'foodDiary.toolbar.verifiedTitle': 'Day verified 👌',
  'foodDiary.toolbar.verifiedCheck': 'Day verified: ',
  'foodDiary.toolbar.verifiedHelp':
    'Keep track of the days that were completed by your client and that you reviewed by hitting the verified button. Once verified, the day will appear in the green color in the calendar.',
  'foodDiary.compareSwitch.onboard.title': 'Compare mode',
  'foodDiary.compareSwitch': 'Compare mode',
  'foodDiary.compareSwitchHelp':
    'Activate this mode to aggregate and compare the nutritional values of multiple days. In the detailed journal, you will see the averages, min, max, etc. In the visual diary, you will see all the meals pictures side by side.',
  'compare.visualOverview': 'Visual overview',
  'dayChart.compareHeader': 'Daily average distribution',

  'toolbar.button.export': 'Create/edit a report',
  'toolbar.button.verify': 'Verify',
  'toolbar.button.verified': 'Verified',

  'export.exportButton': 'Finalize report',

  'export.modal.title': 'Export/share report',
  'export.modal.download': 'Download PDF',
  'export.modal.oneClick': 'Send via email',
  'export.modal.email': 'One-click send to {firstName}',
  'export.modal.finalized': 'Report successfully finalized.',
  'export.modal.finalizing': 'Finalizing report...',
  'export.modal.emailHelp':
    'If you click on this button, an email containing a secured link to download the generated report will instantly be sent to your client.',
  'export.modal.generating': 'Generating report...',
  'export.modal.user': 'Return to diary',

  'pickTemplate.header': 'Create a report',
  'pickTemplate.subheader':
    'You can create different types of report for different purposes. Pick one of our customizable templates, use your own templates or build a report from scratch.',
  'pickTemplate.base.title': 'Start from scratch',
  'picktemplate.base.description':
    'Got your own idea ? Start with a blank report.',
  'pickTemplate.search.placeholder': 'Search a template...',

  'reports.search.placeholder': 'Search by report title...',
  'reports.filter.all': 'All reports',
  'reports.filter.finalized': 'Finalized reports',
  'reports.filter.current': 'Current reports',
  'reports.newReport': 'New report',
  'reports.noReports.title': 'Would you like to create a new report ?',
  'reports.noReports.hint':
    'Reports can be sent to your clients or shared with other professionnals. By starting from a template, they can be generated in a minute.',
  'reports.noReports.addBtn': 'Create a report',

  'foods.create.name': '',
  'foods.create.pickFoodName.placeholder': 'Add food name',
  'foods.create.allNutrients.search': 'Search for a nutrient...',
  'foods.create.save': 'Save',
  'foods.create.saving': 'Saving...',

  'foods.create.size': 'Size',
  'foods.create.serving': 'eg. Sandwich',
  'foods.create.amount': 'eg. 60',
  'foods.create.allowClients': 'Allow your clients to use this food item',

  'foods.create.basicLabel': 'Label info',
  'foods.create.allNutrients': 'All nutrients',

  'foods.create.selectNameError': 'Please select a name.',
  'foods.create.selectServingAmountError': 'Please select a serving amount.',
  'foods.create.selectServingNameError': 'Please select a serving name.',
  'foods.create.nutrientsError': 'Please specify at least 1 nutrient.',

  'foods.create.unsavedChanges':
    'You have unsaved changes, are you sure you want to leave?',

  'reports.title': 'Reports',
  'pickTemplate.templates.title': 'Start a new report',
  'pickTemplate.previousReports.title': 'Duplicate a previous report',
  'pickTemplate.reports.title': 'Resume report creation',
  'pickTemplate.reports.emptyTitle': 'No reports',
  'pickTemplate.reports.emptyText':
    'After finalizing a report for {name}, it will appear here.',
  'pickTemplate.template.creation': `Created a new report based on "{emoji} {title}" template.`,
  'pickTemplate.header.downloaded': 'Downloaded',
  'pickTemplate.header.template': 'Template',
  'pickTemplate.header.sent': 'Sent',
  'pickTemplate.header.creationDate': 'Creation date',
  'pickTemplate.notify.continue': 'Continue report creation',
  'pickTemplate.notify.duplicate':
    'Created a new report by duplicating "{title}"',
  'pickTemplate.templateCard.mine': 'Your report',
  'pickTemplate.cantFind': "Can't find the right template?",
  'pickTemplate.buildYours': 'Build yours',

  'reports.actions.sendSuccess': 'Report sent to your client.',
  'report.defaultTitle': 'Untitled report',
  'reports.status.finalized': 'Finalized',
  'reports.status.unfinalized': 'Current',

  'report.actions.delete': 'Delete report',
  'report.actions.download': 'Download',
  'report.actions.send': 'Send',
  'report.actions.edit': 'Edit',
  'report.actions.copyAndEdit': 'Copy & edit',

  'export.backTitle': 'Back to diary',
  'builder.specialOptions.other': 'More...',

  'csv.export.nutrients.title': 'Select nutrients to export',
  'csv.export.nutrientsTitle': 'Select nutrients',
  'csv.export.selected': '{count} selected',
  'csv.export.configure': 'Configure',
  'csv.export.confirm.title': 'Export submitted',
  'csv.export.confirm.detail':
    'We are now preparing your export. You will receive an email with you csv files as soon as it is ready. During peak hours, it might take few hours before you receive it.',
  'csv.export.confirm.done': 'Done',
  'csv.export.title': 'CSV export',
  'csv.export.info':
    'The complete diaries of {count} client(s) will be exported to csv file(s). Note that all nutrients available will be exported.',
  'csv.export.range': 'Export range',
  'csv.export.verifiedOnly': 'Verified only',
  'csv.export.allDiaries': 'All',
  'csv.export.export': 'Export',
  'csv.export.submitting': 'Submitting',
  'csv.export.withinRange': 'From start to end date',
  'csv.export.all': 'All dates',
  'csv.export.options': 'Verified/unverified days',
  'csv.export.details': 'Export nutrients as',
  'csv.export.withIngredients':
    'Individual food items (e.g., bread, ham & tomato)',
  'csv.export.withDishes': 'Dishes (e.g., sandwich)',
  'csv.export.aggregate': 'Single CSV (data from all client(s)/participant(s))',
  'csv.export.single': 'Multiple CSV',
  'csv.export.grouping': 'Single/multiple CSV',

  'rangePicker.from': 'Start date',
  'rangePicker.to': 'End date',

  'oauth.authorize.error':
    '⚠ Some parameters are missing from the authorized query link.',
  'oauth.authorize.errorHelp':
    'Please contact the developers of the app that redirected you here and let them know that they need to specify: client_id, scope & redirect_uri.',
  'oauth.authorize.title': 'Authorize',
  'oauth.authorize.subtitle': 'would like to:',
  'oauth.authorize.data':
    'Data shared to {app_name} will be governed by their ',
  'oauth.authorize.review':
    '. Please review them carefuly before allowing the request.',
  'oauth.authorize.allow': 'Allow',
  'oauth.authorize.deny': 'Deny',
  'oauth.authorize.privacy': 'privacy policy',
  'oauth.authorize.terms': 'terms of service',
  'oauth.authorize.and': 'and ',
  'oauth.authorize.confirm': 'I read and understood {app_name} policies.',
  'oauth.authorize.callError': 'Redirection error.',
  'oauth.authorize.callErrorHelp':
    'An error occured during the receiption of your authorization. Please contact {app_name} developers.',
  'oauth.authorize.retry': 'Retry',

  'oauth.scope.clientRead':
    "Access your clients' profile information (email, name, birthdate & sex).",
  'oauth.scope.clientWrite':
    "Edit your clients' profile information (email, name, birthdate & sex).",
  'oauth.scope.diaryRead': "Access your clients' nutritional information.",
  'oauth.scope.diaryWrite': "Edit your clients' nutritional information.",
  'oauth.scope.commentRead':
    'Access comments sent between you and your clients.',
  'oauth.scope.photoRead': "Access your clients' pictures of meals.",
  'oauth.scope.inviteRead':
    'Be notified and access the state of your invitations.',
  'oauth.scope.inviteWrite': 'Send clients invitations on your behalf.',

  'oauth.box.title': 'Link your Keenoa account',

  'builder.one': 'choose 1',
  'builder.atLeastOne': 'choose at least 1',
  'builder.oneIfApplicable': 'choose 1 if applicable',
  'builder.onlyIfApplicable': 'choose any if applicable',

  'userFoods.title': 'My foods',
  'userFoods.newFood': 'New food',
  'userFoods.search': 'Search a food',
  'userFoods.columns.name': 'Name',
  'userFoods.columns.serving': 'Serving',
  'userFoods.columns.shared': 'Visible to clients',
  'userFood.label': 'food',
  'userFood.remove': 'Delete food',
  'userFood.removeSuccess': 'Food deleted successfully.',
  'userFood.removeSuccessP': '{count} foods were successfully deleted.',
  'userFoods.empty.title': 'Need a particular food in the database ?',
  'userFoods.empty.help':
    'You can quickly add food items to the database, these items will be available only to you and to your clients, if desired.',
  'userFoods.empty.new': 'Add a food item',

  'userFood.deleteHeader': 'Delete the food?',
  'userFood.deleteExplanation':
    'Deleting a food will permanently remove it. You will not be able to add it to a client diary anymore.',
  'userFood.deleteConfirm': 'Yes, delete food',
  'userFood.deleteCancel': 'No, keep the food',

  'foodDiary.noDiaryAvailableTitle': 'No picture',
  'foodDiary.noDiaryAvailableText':
    'Your client has not captured any photos yet. As soon as they do, you will be able to consult their food diary here.',

  'courses.enroll': 'Use Keenoa in your course',
  'courses.instructorIntro':
    'When you join a course as an instructor, you gain access to the data collected in Keenoa by all students of the course.',
  'courses.learnerIntro':
    'When you join a course, the instructor has access to the data that you record in your Keenoa account.',
  'courses.invalidToken':
    'It looks like the link you used to register to the course is invalid. Please, use a different link.',

  'receiveInvite.success': 'Invitation sent successfully.',
  'receiveInvite.error':
    'An error occurred while sending the invitation. It might already be in your list.',
};
