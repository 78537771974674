import WelcomeModal from './WelcomeModal';
import { connect } from 'react-redux';
import {
  closeModal,
  getModalOpen,
  MODAL_TYPES,
} from 'redux/modules/modals/modals';

const mapStateToProps = state => ({
  isOpen: getModalOpen(state, MODAL_TYPES.WELCOME),
});

const mapDispatchToProps = dispatch => ({
  onExit: () => dispatch(closeModal(MODAL_TYPES.WELCOME)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeModal);
