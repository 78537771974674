import { createSelector } from 'reselect';
import { dateStringToDate } from '../../../utils/dateUtils';
import {
  getCurrentClientId,
  getCurrentClientDiaryIds,
} from '../clients/clients.selectors';
import isEmpty from 'lodash/isEmpty';
import fetchStatus from '../../../constants/fetchStatus';
import times from 'lodash/times';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { getFullLocale } from '../account/account.selectors';
import {
  getCompareById,
  getCompareDiaryIds,
  getIsInCompareMode,
} from '../UI/clientView';
import { getExportDiaryIds } from '../UI/reportView';

export const getDiariesById = state => state.foodDiaries.byId;
export const getCurrentFoodDiaryId = state =>
  state.foodDiaries.currentFoodDiaryId;

export const makeGetMonthFetchStatus = cliendId => state =>
  (cliendId || cliendId === 0) &&
  !isEmpty(state.foodDiaries.monthFetchStatus[cliendId])
    ? state.foodDiaries.monthFetchStatus[cliendId]
    : {};

export const getMonthFetchStatusOfCurrentClient = state =>
  makeGetMonthFetchStatus(getCurrentClientId(state))(state);

export const getFetchStatus = state => state.foodDiaries.fetchStatus;

export const getFetchStatusCurrentFoodDiary = createSelector(
  [getFetchStatus, getCurrentFoodDiaryId],
  (status, id) => (id || id === 0 ? status[id] : undefined)
);

export const getCurrentFoodDiary = createSelector(
  [getDiariesById, getCurrentFoodDiaryId],
  (byId, id) => (id || id === 0 ? byId[id] : undefined)
);

export const getCurrentFoodDiaryLoadingMeals = createSelector(
  [getCurrentFoodDiary],
  foodDiary =>
    foodDiary ? times(foodDiary.meal_groups_count.length, index => index) : [1]
);

export const getCurrentFoodDiaryDate = createSelector(
  [getCurrentFoodDiary],
  foodDiary => (foodDiary ? dateStringToDate(foodDiary.diary_date) : null)
);

export const getCurrentFoodDiaryVerified = createSelector(
  [getCurrentFoodDiary],
  x => get(x, 'is_verified', false)
);

export const getCurrentFoodDiaryFormattedDate = createSelector(
  [getCurrentFoodDiaryDate, getFullLocale],
  (date, locale) => {
    if (!date) return '';

    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString(locale, options);
  }
);

const _getFoodDiariesOfCurrentClient = createSelector(
  [getDiariesById, getCurrentClientDiaryIds],
  (logs, foodDiariesids) =>
    foodDiariesids
      ? Object.keys(logs)
          .filter(id => foodDiariesids.indexOf(id) > -1)
          .map(id => {
            return { ...logs[id] };
          })
      : []
);

export const makeGetFoodDiaryFromDayOfCurrentClient = day =>
  createSelector([_getFoodDiariesOfCurrentClient], byId => {
    for (const id in byId) {
      if (
        dateStringToDate(byId[id]['diary_date']).toDateString() ===
        dateStringToDate(day).toDateString()
      ) {
        return byId[id];
      }
    }
    return undefined;
  });

export const getLastedDiaryOfCurrentClient = createSelector(
  [_getFoodDiariesOfCurrentClient],
  byId => {
    const dates = Object.keys(byId).map(id => ({
      date: dateStringToDate(byId[id].diary_date),
      id,
    }));
    if (dates.length === 0) return null;

    let max = dates[0];
    for (let i = 1; i < dates.length; ++i) {
      if (dates[i].date > max.date) max = dates[i];
    }

    return byId[max.id];
  }
);
export const getLatestDiaryId = createSelector(
  getLastedDiaryOfCurrentClient,
  d => get(d, 'id', null)
);
export const getTargetDiaryId = createSelector(
  [getLatestDiaryId, getCurrentFoodDiaryId],
  (latest, current) => current || latest
);

export const getLatestDateOfFoodDiariesOfCurrentClient = createSelector(
  [getLastedDiaryOfCurrentClient],
  diary => (!diary ? null : dateStringToDate(diary.diary_date))
);

export const getFoodDiariesWithDateObjectOfCurrentClient = createSelector(
  [_getFoodDiariesOfCurrentClient],
  foodDiaries =>
    foodDiaries
      ? foodDiaries.map(foodDiary => {
          return { ...foodDiary, date: dateStringToDate(foodDiary.diary_date) };
        })
      : []
);

const getIsMonthLoadedCurrentFoodDiary = createSelector(
  [getCurrentFoodDiary, getMonthFetchStatusOfCurrentClient],
  (foodDiary, monthFetch) => {
    if (Object.keys(monthFetch).length === 0) {
      return false;
    } else if (!foodDiary) {
      //mean no foodDiary entered yet for this client
      return true;
    }
    const diaryDate = get(foodDiary, 'diary_date');
    if (!diaryDate) return false;
    const monthDate = diaryDate.substring(0, 7);
    return monthFetch[monthDate] === fetchStatus.LOADED;
  }
);

export const getIsCurrentClientNoFoodDiary = createSelector(
  [getCurrentClientDiaryIds, getMonthFetchStatusOfCurrentClient],
  (diaries, monthFetch) => {
    const monthFetchKeys = Object.keys(monthFetch);
    if (diaries.length > 0 || monthFetchKeys.length === 0) return false;

    const pending = monthFetchKeys.filter(
      month => monthFetch[month] === fetchStatus.LOADING
    );
    return pending.length === 0;
  }
);

export const getViewedDiaries = createSelector(
  [getFoodDiariesWithDateObjectOfCurrentClient],
  diaries => {
    const viewed = {};
    diaries.forEach(diary => {
      if (diary.last_viewed) {
        const key = diary.date.toLocaleDateString();
        viewed[key] = diary.last_viewed;
      }
    });
    return viewed;
  }
);

export const _getDiariesByDate = (diaries, byId) => {
  const compare = {};
  diaries.forEach(diary => {
    if (byId[diary.id]) {
      const key = diary.date.toLocaleDateString();
      compare[key] = true;
    }
  });
  return compare;
};
export const getCompareDiariesByDate = createSelector(
  [getFoodDiariesWithDateObjectOfCurrentClient, getCompareById],
  _getDiariesByDate
);
export const getExportById = createSelector(getExportDiaryIds, ids => {
  const x = {};
  ids.forEach(id => {
    x[id] = true;
  });
  return x;
});
export const getExportDiariesByDate = createSelector(
  [getFoodDiariesWithDateObjectOfCurrentClient, getExportById],
  _getDiariesByDate
);

const _mapIds = (ids, byId) => ids.map(x => byId[x]);
export const getCompareDiaries = createSelector(
  [getCompareDiaryIds, getDiariesById],
  _mapIds
);
export const getExportDiaries = createSelector(
  [getExportDiaryIds, getDiariesById],
  _mapIds
);

const _sortDiaries = diaries => sortBy(diaries, 'diary_date');
export const getOrderedCompareDiaries = createSelector(
  getCompareDiaries,
  _sortDiaries
);
export const getOrderedExportDiaries = createSelector(
  getExportDiaries,
  _sortDiaries
);
const _getOrderedDiariesDates = diaries =>
  diaries.map(diary => dateStringToDate(get(diary, 'diary_date')));
export const getOrderedComparedDiariesDates = createSelector(
  getOrderedCompareDiaries,
  _getOrderedDiariesDates
);
export const getOrderedExportDiariesDates = createSelector(
  getOrderedExportDiaries,
  _getOrderedDiariesDates
);

export const getVerifiedDiaries = createSelector(
  [getFoodDiariesWithDateObjectOfCurrentClient],
  diaries => {
    const verified = {};
    diaries.forEach(diary => {
      if (diary.is_verified) {
        const key = diary.date.toLocaleDateString();
        verified[key] = diary.is_verified;
      }
    });
    return verified;
  }
);

export const getCurrentlySelectedDiaryIds = createSelector(
  [getIsInCompareMode, getCurrentFoodDiaryId, getCompareDiaryIds],
  (isCompareMode, diaryId, diaryIds) =>
    isCompareMode ? diaryIds : diaryId ? [diaryId] : []
);

export default {
  getCurrentFoodDiaryId,
  getCurrentFoodDiary,
  getCurrentFoodDiaryDate,
  getCurrentFoodDiaryLoadingMeals,
  getFetchStatusCurrentFoodDiary,
  makeGetMonthFetchStatus,
  getMonthFetchStatusOfCurrentClient,
  getFetchStatus,
  getIsCurrentClientNoFoodDiary,
  makeGetFoodDiaryFromDayOfCurrentClient,
  getLatestDateOfFoodDiariesOfCurrentClient,
  getFoodDiariesWithDateObjectOfCurrentClient,
  getIsMonthLoadedCurrentFoodDiary,
  getViewedDiaries,
};
