import React from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider as SThemeProvider } from 'styled-components';
import * as themes from '../themes';
import { StripeProvider } from 'react-stripe-elements';
import {
  RenderingContext,
  RENDERING_CONTEXT,
} from '../pages/Dashboard/Export/RenderingContext';
import Notifications from 'react-notify-toast';
import IntlGlobalProvider from '../utils/intl';
import { useSelector } from 'react-redux';
import {
  getAccountTheme,
  getSmallLocale,
} from 'redux/modules/account/account.selectors';
import * as i18n from '../i18n';
import { get } from '../utils/storage';
import { ThemeProvider as MThemeProvider } from '@rmwc/theme';

import 'react-tippy/dist/tippy.css';

export function ThemeProvider({ children }) {
  const theme = useSelector(getAccountTheme);

  return (
    <SThemeProvider theme={themes[theme]}>
      <MThemeProvider
        options={{
          primary: themes[theme].palette.primary1,
          secondary: themes[theme].palette.primary4,
          background: '#f8f8f8',
        }}
      >
        {children}
      </MThemeProvider>
    </SThemeProvider>
  );
}

export default function Providers({ children }) {
  const theme = useSelector(getAccountTheme);
  const locale = useSelector(getSmallLocale) || get('locale') || 'en';
  const intlData = {
    locale,
    messages: i18n[locale],
  };

  return (
    <IntlProvider key={locale} {...intlData}>
      <IntlGlobalProvider>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
          <ThemeProvider>
            <RenderingContext.Provider value={RENDERING_CONTEXT.DOM}>
              <Notifications
                colors={{
                  error: { backgroundColor: themes[theme].palette.error },
                  success: { backgroundColor: themes[theme].palette.success },
                  warning: { backgroundColor: themes[theme].palette.warning },
                  info: { backgroundColor: themes[theme].palette.primary1 },
                }}
                options={{
                  zIndex: 2000,
                }}
              />
              {children}
            </RenderingContext.Provider>
          </ThemeProvider>
        </StripeProvider>
      </IntlGlobalProvider>
    </IntlProvider>
  );
}
