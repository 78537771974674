import React from 'react';
import { Chip } from 'components/forms/Chip';
import { updateModalProps } from 'redux/modules/modals/modals';
import { useDispatch } from 'react-redux';

/**
 * Redirect special options are skiping the current builder.
 */
function RedirectOption({ foodId, name }) {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(updateModalProps({ foodId, foodDescription: name }));
  };

  return (
    <Chip onClick={onClick} mode="outlined" style={{ borderRadius: 6 }}>
      {name}
    </Chip>
  );
}

export default RedirectOption;
