import ClientActions from './ClientActions';
import { connect } from 'react-redux';
import {
  getSelectedIds,
  getAvailableActions,
  getById,
} from 'redux/modules/clients/clients.selectors';
import {
  updateClientStatus,
  unselectAllClients,
  removePendingInvite,
  renewInvite,
} from 'redux/modules/clients/clients.actions';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';

const mapStateToProps = state => ({
  selectedIds: getSelectedIds(state),
  actions: getAvailableActions(state),
  byId: getById(state),
});

const mapDispatchToProps = dispatch => ({
  renewInvite: (email, id) => dispatch(renewInvite(email, id)),
  removeInvite: (email, id) => dispatch(removePendingInvite(email, id)),
  updateStatus: (clientId, status) =>
    dispatch(updateClientStatus(clientId, status)),
  unselectAll: () => dispatch(unselectAllClients()),
  openExportModal: () => dispatch(openModal(MODAL_TYPES.EXPORT_CLIENTS)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientActions);
