import styled, { keyframes, css } from 'styled-components';
import React from 'react';
import Center from '../Center';

const bounceDelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 
  40% {
      transform: scale(1.0);
    }
`;
const bounceDelayAnimation = css`
  ${bounceDelay} 1s infinite ease-in-out both;
`;

const Spinner = styled.div`
  padding: 10px;
  width: auto;
  text-align: center;
`;

const Bounce = styled.div`
  width: 15px;
  height: 15px;
  margin: 3px;
  background-color: ${props => props.theme.palette.primarySaturated};

  border-radius: 100%;
  display: inline-block;
  animation: ${bounceDelayAnimation};
`;

const Bounce1 = styled(Bounce)`
  animation-delay: -0.25s;
`;

const Bounce2 = styled(Bounce)`
  animation-delay: -0.12s;
`;

export default () => (
  <Center>
    <Spinner>
      <Bounce1 />
      <Bounce2 />
      <Bounce />
    </Spinner>
  </Center>
);
