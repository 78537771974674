import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  updateUserFood,
  fetchUserFood,
  getUserFoodName,
  getUserFoodNutrients,
  getUserFoodServingAmount,
  getUserFoodServingName,
  getUserFoodServingQuantity,
  getUserFoodServingQuantityUnit,
} from 'redux/modules/userFoods';
import { UserFoodEditor } from '../UserFoodEditor';
import { useParams } from 'react-router-dom';

const mapStateToProps = (state, { id }) => ({
  defaultName: getUserFoodName(state, id),
  defaultServingAmount: getUserFoodServingAmount(state, id),
  defaultServingName: getUserFoodServingName(state, id),
  defaultServingQuantity: getUserFoodServingQuantity(state, id),
  defaultServingQuantityUnit: getUserFoodServingQuantityUnit(state, id),
  defaultNutrients: getUserFoodNutrients(state, id),
});

const mapDispatchToProps = (dispatch, { id }) => ({
  onSave: (name, serving, nutrients, permissions) =>
    dispatch(updateUserFood(id, { name, serving, nutrients, permissions })),
});

const Container = connect(mapStateToProps, mapDispatchToProps)(UserFoodEditor);

export default function EditUserFood(props) {
  const { userFoodId } = useParams();

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const loadFood = async foodId => {
    try {
      await dispatch(fetchUserFood(foodId));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!userFoodId) return;

    loadFood(userFoodId);
  }, [userFoodId]);

  return <Container id={userFoodId} {...props} isFetchingDefaults={loading} />;
}
