import React, { useState, useEffect } from 'react';
import NewClientForm from './NewClientForm';
import NewClientConfirm from './NewClientConfirm';
import Modal from 'components/Modal';
import { FETCH_STATUS } from '../../../../constants/fetchStatus';
import PreviewModal from './PreviewModal';

function NewClientModal({
  isOpen,
  onExit,
  onOpenPreview,
  renewInvite,
  inviteError,
  inviteStatus,
  renewStatus,
  defaultLocale,
  onAddClientClick,
}) {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const [locale, setLocale] = useState(defaultLocale);

  useEffect(() => {
    if (
      inviteStatus === FETCH_STATUS.LOADED ||
      renewStatus === FETCH_STATUS.LOADED
    ) {
      setSent(true);
    }
  }, [inviteStatus, renewStatus]);

  const onClose = () => {
    setEmail('');
    setSent(false);
    onExit();
  };

  const onChangeEmail = newEmail => {
    setEmail(newEmail);
  };

  const onInviteClick = () => {
    onAddClientClick(email, locale);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        {!sent ? (
          <NewClientForm
            locale={locale}
            email={email}
            onPreview={onOpenPreview}
            onEmailChange={onChangeEmail}
            onLocaleChange={setLocale}
            renewInvite={renewInvite}
            onInviteClick={onInviteClick}
            error={inviteError}
            status={inviteStatus}
            renewStatus={renewStatus}
          />
        ) : (
          <NewClientConfirm email={email} onConfirmClick={onClose} />
        )}
      </Modal>
      <PreviewModal
        email={email}
        onInvite={onInviteClick}
        locale={locale.slice(0, 2)}
      />
    </>
  );
}

export default NewClientModal;
