/** To keep track of the fetch status state. Inspired by:
 * https://medium.com/statuscode/dissecting-twitters-redux-store-d7280b62c6b1
 */
export const FETCH_STATUS = Object.freeze({
  NONE: 'none',
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
});
export default FETCH_STATUS;
