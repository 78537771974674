import React from 'react';
import Switch from 'components/forms/Switch';
import styled from 'styled-components';
import { defineMessages } from 'react-intl';
import InfoText from 'components/InfoText';
import SimpleTour from 'components/SimpleTour';
import { ONBOARD_IDS } from '../../../../../constants';

const messages = defineMessages({
  compareSwitch: { id: 'foodDiary.compareSwitch' },
  compareSwitchHelp: { id: 'foodDiary.compareSwitchHelp' },

  onboardTitle: { id: 'foodDiary.compareSwitch.onboard.title' },
  onboardClose: { id: 'foodDiary.compareSwitch.onboard.close' },
});

const Wrapper = styled.div`
  --mdc-theme-secondary: ${x => x.theme.palette.primary1};
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
`;

class CompareModeSwitch extends React.PureComponent {
  _toggleCompareMode = evt => {
    const { onboarded, setOnboarded, setMode, canOnboard } = this.props;
    setMode(evt.target.checked);
    if (!!evt.target.checked && !onboarded && canOnboard) {
      // The user did not need our help to understand the toggle
      setOnboarded();
    }

    const { compareIds, diaryId, selectDay } = this.props;
    if (compareIds.length === 0) {
      // If the compareMode is empty we select the current diary
      selectDay(diaryId);
    }
  };
  render() {
    return (
      <>
        <SimpleTour
          onboardId={ONBOARD_IDS.COMPARE_MODE}
          target=".compare-mode-switch"
          titleMsg={messages.onboardTitle}
          contentMsg={messages.compareSwitchHelp}
          closeMsg={messages.onboardClose}
          joyrideOptions={{
            event: 'hover',
            spotlightClicks: true,
            hideFooter: true,
            placement: 'bottom',
          }}
          canRun={this.props.canOnboard}
        />
        <Wrapper>
          <span
            className="mdc-typography--body"
            style={{ color: 'rgba(0,0,0,0.54)' }}
          >
            <InfoText
              textMessage={messages.compareSwitch}
              tooltipMessage={messages.compareSwitchHelp}
            />
          </span>
          <span
            className="compare-mode-switch"
            style={{ display: 'flex', alignItems: 'center', height: 30 }}
          >
            <Switch
              checked={!!this.props.open}
              onChange={this._toggleCompareMode}
            />
          </span>
        </Wrapper>
      </>
    );
  }
}

export default CompareModeSwitch;
