import { connect } from 'react-redux';
import Graph from './Graph';
import {
  getCompareDaysNutrients,
  getCurrentDiaryHourlyMacros,
  getCurrentDiaryLabelToEnergy,
  getCompareLabelToEnergy,
} from 'redux/modules/meals/meals.selectors';
import { getIsInCompareMode } from 'redux/modules/UI/clientView';

const mapStateToProps = state => {
  const compareMode = getIsInCompareMode(state);
  return {
    data: compareMode
      ? getCompareDaysNutrients(state)
      : getCurrentDiaryHourlyMacros(state),
    labelToEnergy: compareMode
      ? getCompareLabelToEnergy(state)
      : getCurrentDiaryLabelToEnergy(state),
    hideLegend: true,
    isPdf: false,
    isHourly: !compareMode,
  };
};

const GraphContainer = connect(mapStateToProps)(Graph);

export default GraphContainer;
