const pre = 'keenoa/account/';

export default {
  SWITCH_LOCALE: `${pre}SWITCH_LOCALE`,
  OPEN_SETTINGS: `${pre}OPEN_SETTINGS`,
  CLOSE_SETTINGS: `${pre}CLOSE_SETTINGS`,
  UPDATE_LOCALE_SUCCESS: `${pre}UPDATE_LOCALE_SUCCESS`,
  UPDATE_LOCALE_FAILURE: `${pre}UPDATE_LOCALE_FAILURE`,
  UPDATE_TOOLBOX_SUCCESS: `${pre}UPDATE_TOOLBOX_SUCCESS`,
  UPDATE_TOOLBOX_FAILURE: `${pre}UPDATE_TOOLBOX_FAILURE`,

  UPDATE_NAME: `${pre}UPDATE_NAME`,
  UPDATE_NAME_SUCCESS: `${pre}UPDATE_NAME_SUCCESS`,
  UPDATE_NAME_FAILURE: `${pre}UPDATE_NAME_FAILURE`,

  UPDATE_TOKEN_REQUEST: `${pre}UPDATE_TOKEN_REQUEST`,
  UPDATE_TOKEN_SUCCESS: `${pre}UPDATE_TOKEN_SUCCESS`,
  UPDATE_TOKEN_FAILURE: `${pre}UPDATE_TOKEN_FAILURE`,

  UPDATE_ONBOARD_REQUEST: `${pre}UPDATE_ONBOARD_REQUEST`,
  UPDATE_ONBOARD_SUCCESS: `${pre}UPDATE_ONBOARD_SUCCESS`,
  UPDATE_ONBOARD_FAILURE: `${pre}UPDATE_ONBOARD_FAILURE`,

  UPDATE_TIME_FORMAT_REQUEST: `${pre}UPDATE_TIME_FORMAT_REQUEST`,
  UPDATE_TIME_FORMAT_SUCCESS: `${pre}UPDATE_TIME_FORMAT_SUCCESS`,
  UPDATE_TIME_FORMAT_FAILURE: `${pre}UPDATE_TIME_FORMAT_FAILURE`,
};
