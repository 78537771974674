import React from 'react';
import Highlight from './Highlight';
import styled from 'styled-components';
import { BUILDER_TYPES } from '../../../../../../../constants';
import { FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
  creator: { id: 'searchFood.creator' },
});

const Badge = styled.div`
  border: 2px solid ${x => x.theme.palette.primary4};
  color: ${x => x.theme.palette.primary4};
  padding: 2px;
  border-radius: 3px;
  margin-right: 3px;
  font-size: 10px;
  font-weight: bold;
`;
const Circle = styled.div`
  background-color: ${x => x.theme.palette.primary4};
  border-radius: 6px;
  height: 6px;
  width: 6px;
  margin-right: 3px;
`;

const Wrapper = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
`;
const DescriptionText = styled.span`
  font-size: 13px;
  color: ${x => x.theme.palette.text.secondary};
`;

export default function Description({ hit, locale }) {
  const renderBadge = () => {
    if (!hit.builder_type) return null;

    if (hit.builder_type === BUILDER_TYPES.CREATOR)
      return (
        <Badge>
          <FormattedMessage {...messages.creator} />
        </Badge>
      );

    return <Circle />;
  };

  if (!hit.builder_name_en) return null;

  return (
    <Wrapper>
      {renderBadge()}
      <DescriptionText>
        <Highlight
          attribute={`builder_name_${locale}`}
          hit={hit}
          highlightProperty="_highlightResult"
        />
      </DescriptionText>
    </Wrapper>
  );
}
