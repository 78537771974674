import React, { useState } from 'react';
import EditName from './EditName';
import EditLocale from './EditLocale';
import EditTimeFormat from './EditTimeFormat';

import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { TIME_FORMATS } from '../../../../constants';
import SettingRow from 'components/SettingRow';
import Typography from 'components/material/Typography';
import Spacer from 'components/Spacer';
import { PageWrapper } from 'components/PageWrapper';

const messages = defineMessages({
  basicsTitle: {
    id: 'general.personnalInfo',
    defaultMessage: 'General',
  },
  basicName: {
    id: 'general.basics.name',
    defaultMessage: 'Name',
  },
  basicLanguage: {
    id: 'general.basics.language',
    defaultMessage: 'Language',
  },
  localeEn: {
    id: 'settings.localeEn',
    defaultMessage: 'English',
  },
  localeFr: {
    id: 'settings.localeFr',
    defaultMessage: 'French',
  },
  timeFormat: { id: 'settings.timeFormat' },
});

function General({
  timeFormat,
  pickTimeFormat,
  fullName,
  locale,
  editName,
  editLocale,
  intl,
}) {
  const [editTimeOpen, setEditTimeOpen] = useState(false);

  const timeValue = () => {
    const options = [
      {
        id: TIME_FORMATS.TWELVE_HOUR,
        label: '12 h',
      },
      {
        id: TIME_FORMATS.TWENTY_FOUR_HOUR,
        label: '24 h',
      },
    ];
    const value = options.find(x => x.id === timeFormat);
    return value.label;
  };

  return (
    <PageWrapper dense>
      <Spacer spacing="xxl" />
      <Typography use="headline5" rank="primary" tag="div" center>
        <FormattedMessage {...messages.basicsTitle} />
      </Typography>
      <Spacer spacing="lg" />
      <SettingRow
        name={messages.basicName}
        value={fullName}
        onEdit={editName}
        icon={'id-card-alt'}
      />
      <SettingRow
        name={messages.basicLanguage}
        value={intl.formatMessage(
          locale.startsWith('fr') ? messages.localeFr : messages.localeEn
        )}
        onEdit={editLocale}
        icon={'language'}
      />
      <SettingRow
        name={messages.timeFormat}
        icon={'watch'}
        value={timeValue()}
        onEdit={() => setEditTimeOpen(true)}
      />

      <EditName />
      <EditLocale />
      <EditTimeFormat
        visible={editTimeOpen}
        setTimeFormatId={pickTimeFormat}
        onDismiss={() => setEditTimeOpen(false)}
      />
    </PageWrapper>
  );
}

export default injectIntl(General);
