import React from 'react';
import { Twemoji } from 'react-emoji-render';
import styled from 'styled-components';
import TemplateActions from './TemplateActions';
import Dotdotdot from 'react-dotdotdot';
import { TEMPLATE_VISIBILITY } from '../../constants';

const Card = styled.div`
  width: 95px;
  height: 95px;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.17);
  position: relative;
  cursor: pointer;
  background-color: white;

  &:hover {
    border: 1px solid
      ${x =>
        x.isPublic
          ? x.theme.palette.primary1
          : x.theme.palette.primarySaturated};
  }
`;

const IconWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const TemplateTitleWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TemplateTitle = styled.div`
  text-align: center;
  line-height: 1.3rem !important;
  color: ${x => x.theme.palette.text.midPrimary};
  font-weight: 500 !important;
`;
const TemplateDescription = styled.div`
  color: ${x => x.theme.palette.text.tertiary};
  font-weight: 500 !important;
  margin-top: 2px;
  padding: 0 2px;
  line-height: 1.2em !important;
`;

const ActionsWrapper = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
`;

const TypeWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 10px;
  font-weight: 600;
  color: ${x =>
    x.visibility === TEMPLATE_VISIBILITY.PUBLIC
      ? x.theme.palette.primary1
      : x.theme.palette.primary2};
`;

function TemplateType({ visibility }) {
  let type = '';
  if (visibility === TEMPLATE_VISIBILITY.PUBLIC) type = 'Keenoa';

  return <TypeWrapper visibility={visibility}>{type}</TypeWrapper>;
}

export default function TemplateCard({
  id,
  emoji,
  title,
  description,
  onClick,
  visibility,

  actionsEnabled,
}) {
  return (
    <div style={{ width: 115, margin: 7 }}>
      <Card
        onClick={() => onClick(id)}
        isPublic={visibility === TEMPLATE_VISIBILITY.PUBLIC}
      >
        <TemplateType visibility={visibility} />
        <ActionsWrapper>
          {actionsEnabled && id && (
            <TemplateActions templateId={id} visibility={visibility} />
          )}
        </ActionsWrapper>
        <IconWrapper>
          <Twemoji text={emoji || ''} style={{ fontSize: 28 }} />
        </IconWrapper>
        <TemplateTitleWrapper>
          <TemplateTitle className="mdc-typography--body1" style={{}}>
            {title}
          </TemplateTitle>
        </TemplateTitleWrapper>
      </Card>
      <TemplateDescription className="mdc-typography--caption">
        <Dotdotdot clamp={2}>{description}</Dotdotdot>
      </TemplateDescription>
    </div>
  );
}
