import React from 'react';
import Modal from 'components/Modal';
import H2 from 'components/H2';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import forEach from 'lodash/forEach';
import {
  idToConstant,
  NUTRIENTS_SECTIONS,
  NUTRIENTS_SECTIONS_FOR_NS,
} from '../../../../constants/nutriments';
import Checkbox from 'components/forms/Checkbox';
import { Button } from 'components/material/Button';
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import SearchBar from 'components/SearchBar';
import { Tooltip } from 'react-tippy';
import get from 'lodash/get';
import { withTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { getDietitianIsWithNutrisimple } from 'redux/modules/account/account.selectors';
import Spacer from 'components/Spacer';

const messages = defineMessages({
  save: { id: 'nutrientsModal.save' },
  placeholder: { id: 'nutrientsModal.placeholder' },
  selectAll: { id: 'nutrientsModal.selectAll' },
  unselectAll: { id: 'nutrientsModal.unselectAll' },
});

const SectionWrapper = styled.div`
  --mdc-theme-secondary: ${x => x.theme.palette[x.color]};

  display: flex;
  flex-direction: column;
`;

const SectionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(75vh - 185px);
  overflow-y: auto;
  width: 17vw;
  max-width: 200px;
  min-width: 100px;
  overflow-x: hidden;
  margin: 0 15px;
  margin-left: -10px;
`;

const SectionTitleStyle = styled.div`
  background-color: ${x => x.theme.palette[x.color]};
  width: 5px;
  height: 20px;
  border-radius: 5px;
  margin-right: 5px;
`;
const CheckboxLabel = styled.div`
  width: 10vw;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const RowWrapper = styled.div`
  padding: 0;
`;

const Row = React.memo(function({ label, selected, onToggle }) {
  return (
    <RowWrapper>
      <Checkbox checked={selected} onChange={onToggle}>
        <CheckboxLabel>
          <Tooltip
            position="bottom"
            title={label}
            trigger="mouseenter"
            animateFill={false}
            duration={105}
            delay={1100}
            arrow={false}
            theme="light"
            animtation="fade"
            interactiveBorder={0}
          >
            {label}
          </Tooltip>
        </CheckboxLabel>
      </Checkbox>
    </RowWrapper>
  );
});

class NutrientsModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      nutrients: {},
      saving: false,
      searchText: '',
    };
    this._saveNutrients = throttle(this._saveNutrients, 1000, {
      trailing: false,
    });
  }
  componentDidMount() {
    this._setStartNutrients();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.currentNutrients === this.props.currentNutrients ||
      !this.props.isOpen ||
      prevProps.isOpen
    )
      return;

    this._setStartNutrients();
  }

  _setStartNutrients = () => {
    const nutrients = {};
    get(this.props, 'currentNutrients', []).forEach(nutrientId => {
      nutrients[nutrientId] = true;
    });
    this.setState({ nutrients });
  };

  _makeToggleNutrient = id => () => {
    this.setState(({ nutrients }) => ({
      nutrients: {
        ...nutrients,
        [id]: !nutrients[id],
      },
    }));
  };

  _saveable = () => {
    const { max } = this.props;
    if (!max) return true;

    return max >= this._nurientsCount();
  };

  _saveNutrients = () => {
    const { updateNutrients, onClose } = this.props;
    const { nutrients } = this.state;
    const newNutrients = [];
    forEach(nutrients, (v, k) => {
      if (!v) return;
      newNutrients.push(k);
    });

    return Promise.resolve(updateNutrients(newNutrients)).then(onClose);
  };
  _nurientsCount = () => {
    return Object.values(this.state.nutrients).filter(x => x).length;
  };

  renderSaveButton = () => {
    const saveable = this._saveable();
    const { theme, max } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'center',
          marginTop: 20,
          position: 'absolute',
          bottom: 20,
          right: 20,
        }}
      >
        <Button unelevated onClick={this._saveNutrients} disabled={!saveable}>
          <FormattedMessage {...messages.save} />
        </Button>
        {!max ? null : (
          <div
            className="mdc-typography--subheading2"
            style={{
              marginRight: 10,
              ...(saveable ? {} : { fontWeight: 'bold' }),
              color: saveable
                ? theme.palette.text.secondary
                : theme.palette.error,
            }}
          >
            {`${this._nurientsCount()} / ${this.props.max}`}
          </div>
        )}
      </div>
    );
  };

  _updateSearch = searchText => {
    this.setState({ searchText });
  };

  renderSearchBar = () => {
    return (
      <div style={{ marginBottom: 15, marginTop: 10, flex: 1 }}>
        <SearchBar
          placeholder={this.props.intl.formatMessage(messages.placeholder)}
          value={this.state.searchText}
          onInputChange={this._updateSearch}
        />
      </div>
    );
  };

  renderSectionTitle = ({ title, color }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          fontWeight: 500,
        }}
        className="mdc-typography--subheading2"
      >
        <SectionTitleStyle color={color} />
        <FormattedMessage {...title} />
        {}
      </div>
    );
  };

  renderSection = ({ title, color, ids }) => {
    const { nutrients, searchText } = this.state;
    const { intl } = this.props;

    // Search technique based on http://jsperf.com/substring-test
    const regex = new RegExp(searchText, 'i');
    return (
      <SectionWrapper key={title.id} color={color}>
        {this.renderSectionTitle({ title, color })}
        <SectionContentWrapper>
          {ids
            .map(id => ({ id, constant: idToConstant[id] }))
            .map(({ id, constant }) => ({
              id,
              selected: !!nutrients[id],
              label: intl.formatMessage(constant.label) || id,
            }))
            .filter(({ label }) => regex.test(label))
            .map(({ id, selected, label }) => (
              <Row
                key={id}
                label={label}
                selected={selected}
                onToggle={this._makeToggleNutrient(id)}
              />
            ))}
        </SectionContentWrapper>
      </SectionWrapper>
    );
  };

  getSections = () => {
    return this.props.isWithNutrisimple
      ? NUTRIENTS_SECTIONS_FOR_NS
      : NUTRIENTS_SECTIONS;
  };

  renderSections = () => {
    const S = this.getSections();

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {S.map(this.renderSection)}
      </div>
    );
  };

  renderSelectAll = () => {
    if (!this.props.allowSelectAll) return null;

    const S = this.getSections();

    const makeChangeAll = toValue => () => {
      const nextNutrients = {};
      S.flatMap(x => x.ids).forEach(id => {
        nextNutrients[id] = toValue;
      });
      this.setState({
        nutrients: nextNutrients,
      });
    };
    const selectAll = makeChangeAll(true);
    const unselectAll = makeChangeAll(false);

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <Button outlined onClick={selectAll}>
          <FormattedMessage {...messages.selectAll} />
        </Button>
        <Spacer spacing="md" />
        <Button outlined onClick={unselectAll}>
          <FormattedMessage {...messages.unselectAll} />
        </Button>
      </div>
    );
  };

  render() {
    const { isOpen, onClose, title } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        style={{ maxWidth: '95vw', width: '', height: '75vh' }}
      >
        <H2>
          <FormattedMessage {...title} />
        </H2>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.renderSearchBar()}
          {this.renderSelectAll()}
        </div>

        {this.renderSections()}
        {this.renderSaveButton()}
      </Modal>
    );
  }
}

export default withTheme(
  injectIntl(function(props) {
    const isWithNutrisimple = useSelector(getDietitianIsWithNutrisimple);

    return <NutrientsModal {...props} isWithNutrisimple={isWithNutrisimple} />;
  })
);
