import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import { injectIntl } from 'react-intl';

const HelpIcon = styled.div`
  padding-left: 4px;
  font-size: inherit;
  color: ${props => props.theme.palette.text.tertiary};

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.palette.primary1};
  }
`;

class Info extends React.PureComponent {
  render() {
    const { style, tooltipMessage, intl } = this.props;
    return (
      <Tooltip
        position="bottom"
        title={intl.formatHTMLMessage(tooltipMessage)}
        trigger="mouseenter"
        animateFill={false}
        interactive={true}
        duration={115}
        arrow={true}
        theme="light"
        animtation="fade"
        interactiveBorder={10}
      >
        <HelpIcon className="material-icons" style={style}>
          info_outline
        </HelpIcon>
      </Tooltip>
    );
  }
}

export default injectIntl(Info);
