import types from './clientView.types';

export const setCompareMode = isInCompareMode => ({
  type: types.SET_COMPARE_MODE,
  isInCompareMode,
});

export const selectCompareDay = diaryId => ({
  type: types.SELECT_DAY_COMPARE,
  diaryId,
});

export const unselectCompareDay = diaryId => ({
  type: types.UNSELECT_DAY_COMPARE,
  diaryId,
});
