import { connect } from 'react-redux';
import DatePicker from './DatePicker';
import { foodDiariesSelectors } from 'redux/modules/foodDiaries';
import {
  getVerifiedDiaries,
  getCompareDiariesByDate,
} from 'redux/modules/foodDiaries/foodDiaries.selectors';
import {
  selectCompareDay,
  unselectCompareDay,
} from 'redux/modules/UI/clientView/clientView.actions';
import {
  getIsInCompareMode,
  getCompareById,
} from 'redux/modules/UI/clientView';
import { fetchFoodDiary } from 'redux/modules/foodDiaries/foodDiaries.operations';
import { withRouter } from 'react-router-dom';
import {
  getCurrentClientId,
  getCurrentClientToken,
} from 'redux/modules/clients/clients.selectors';

const mapStateToProps = state => {
  const { account } = state;
  const { locale } = account;
  return {
    locale,
    selectedDate: foodDiariesSelectors.getCurrentFoodDiaryDate(state),
    availableDays: foodDiariesSelectors.getFoodDiariesWithDateObjectOfCurrentClient(
      state
    ),
    viewedDiaries: foodDiariesSelectors.getViewedDiaries(state),
    comparedDiaries: getCompareDiariesByDate(state),
    verifiedDiaries: getVerifiedDiaries(state),
    _currentFoodDiaryId: foodDiariesSelectors.getCurrentFoodDiaryId(state),

    compareMode: getIsInCompareMode(state),
    compareById: getCompareById(state),
    clientId: getCurrentClientId(state),
    token: getCurrentClientToken(state),
  };
};

const _redirectToCurrentFoodDiaryUrl = (nextDiaryId, { match, history }) => {
  const currentId = match.params.diaryId;
  const nextPath = match.url.replace(currentId, nextDiaryId);
  history.push(nextPath);
};

const mapDispatchToProps = (dispatch, props) => ({
  redirect: diaryId => _redirectToCurrentFoodDiaryUrl(diaryId, props),
  compare: (diaryId, clientId) => {
    dispatch(selectCompareDay(diaryId));
    dispatch(fetchFoodDiary(diaryId, clientId));
  },
  uncompare: diaryId => dispatch(unselectCompareDay(diaryId)),
  fixDate: clientId => {
    const key = `fix - ${clientId}`;
    const attempted = sessionStorage.getItem(key);
    if (attempted) {
      window.location = `${window.location.origin}/clients`;
    } else {
      window.sessionStorage.setItem(key, true);
      window.location = `${window.location.origin}/clients/${clientId}`;
    }
  },
});

const mergeProps = (
  stateProps,
  { redirect, compare, uncompare, fixDate },
  ownProps
) => ({
  ...stateProps,
  ...ownProps,
  fixDate,
  onSelectDate: diaryId => {
    if (!stateProps.compareMode) redirect(diaryId);
    else if (stateProps.compareById[diaryId]) uncompare(diaryId);
    else compare(diaryId, stateProps.clientId);
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(DatePicker)
);
