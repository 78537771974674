import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { defineMessages } from 'react-intl';
import {
  getFractionOptionFromQuantity,
  getTotalQuantity,
  adjustNutrientsValuesByUnit,
  convertMeasure,
  getBestMatchingMeasure,
} from '../../../../../../utils/selectedFoods';
import { FRACTION_OPTIONS } from '../../../../../../constants/fractions';
import Picker from '../Picker';
import {
  getFoodMeasures,
  getFoodNutrients,
} from 'redux/modules/foods/foods.selectors';
import PlaceholderPicker from '../PlaceholderPicker';

const messages = defineMessages({
  updateBtn: {
    id: 'editEntry.updateBtn',
    defaultMessage: 'Update',
  },
});

function QuantityPicker({
  foodId,
  foodDescription,
  onSubmit,
  startQuantity,
  startMeasureId,
}) {
  const measures = useSelector(state => getFoodMeasures(state, foodId));
  const nutrients = useSelector(state => getFoodNutrients(state, foodId));

  const [quantity, setQuantity] = useState(null);
  const [fraction, setFraction] = useState(FRACTION_OPTIONS[0]);
  const [measure, setMeasure] = useState(null);

  useEffect(() => {
    if (!measures || !measures.length) return;

    if (quantity !== null) {
      setMeasure(getBestMatchingMeasure(measures, null, quantity));
    } else if (startQuantity) {
      const newFraction = getFractionOptionFromQuantity(
        startQuantity,
        FRACTION_OPTIONS
      );
      setFraction(newFraction);
      setQuantity(parseInt(startQuantity, 10));
      setMeasure(
        getBestMatchingMeasure(measures, startMeasureId, startQuantity)
      );
    } else {
      const nextMeasure = measures[0];
      setMeasure(nextMeasure);
      setFraction(
        getFractionOptionFromQuantity(
          nextMeasure.initialValue,
          FRACTION_OPTIONS
        )
      );
      setQuantity(parseInt(nextMeasure.initialValue, 10));
    }
  }, [measures]);

  const _onMeasureChange = newMeasure => {
    const totalQuantity = getTotalQuantity(quantity, fraction.value);
    const newQuantity = convertMeasure(
      { ...measure, quantity: totalQuantity },
      newMeasure
    );

    if (!measure.unit) {
      setFraction(FRACTION_OPTIONS[0]);
    } else {
      const newFraction = getFractionOptionFromQuantity(
        newQuantity,
        FRACTION_OPTIONS
      );
      setFraction(newFraction);
    }

    setMeasure(newMeasure);
    setQuantity(parseInt(newQuantity, 10));
  };

  const _onSubmit = () => {
    const totalQty = getTotalQuantity(quantity, fraction.value);
    const newEntry = {
      food_id: foodId,
      food_description: foodDescription,
      quantity: totalQty,
      unit: measure.unit,
      unit_name: measure.unit_name,
      unit_dimension: measure.unit_dimension,
      unit_measure_id: measure.unit_measure_id,
      nutrients: adjustNutrientsValuesByUnit(nutrients, totalQty, measure),
    };
    onSubmit(newEntry);
  };

  if (!measure) return <PlaceholderPicker />;

  return (
    <Picker
      isWholeUnit={!measure.unit}
      quantity={quantity}
      onQuantityChange={q => setQuantity(q)}
      fraction={fraction}
      onFractionChange={f => setFraction(f)}
      measure={measure}
      measures={measures}
      onMeasureChange={_onMeasureChange}
      submitMessage={messages.updateBtn}
      onSubmit={_onSubmit}
    />
  );
}

export default QuantityPicker;
