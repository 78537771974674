import { connect } from 'react-redux';
import { getMealMessages } from 'redux/modules/comments';
import MessageFeed from './MessageFeed';
import { getLocale } from 'redux/modules/account/account.selectors';

const mapStateToProps = (state, { meals }) => ({
  comments: getMealMessages(state, meals[0]),
  locale: getLocale(state),
});

export default connect(mapStateToProps)(MessageFeed);
