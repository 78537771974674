import '@rmwc/data-table/styles';
import './DataTable.css';

import {
  DataTable as DT,
  DataTableRow as DTR,
  DataTableContent as DTCC,
  DataTableCell as DTC,
  DataTableHead as DTH,
  DataTableBody as DTB,
  DataTableHeadCell as DTHC,
} from '@rmwc/data-table';
import styled from 'styled-components';

export const DataTable = DT;
export const DataTableRow = DTR;
export const DataTableCell = DTC;
export const DataTableHead = DTH;
export const DataTableBody = DTB;
export const DataTableHeadCell = DTHC;
export const DataTableContent = DTCC;

export const DATA_TABLE_ROW_HEIGHT = 48;

export const HoverableDataTableBody = styled(DataTableBody)`
  &:hover {
    cursor: pointer;
  }
`;
