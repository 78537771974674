import React from 'react';
import styled from 'styled-components';
import take from 'lodash/take';
import { defineMessages } from 'react-intl';
import { Tooltip } from 'react-tippy';
import ViewMore from 'components/ViewMore';
import { Twemoji } from 'react-emoji-render';

const messages = defineMessages({
  viewMore: { id: 'messages.viewMore' },
  viewLess: { id: 'messages.viewLess' },
});

const SHOW_COUNT = 2;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => (props.count < 1 ? '0' : '10px')};
  border-top: ${props =>
    props.count < 1 ? '' : `1px solid ${props.theme.palette.lightGray}`};
  margin-right: 9px;
  padding-top: 10px;
`;
const Bubble = styled.div`
  border-radius: 15px;

  margin: 5px 0;
  width: max-content;
  max-width: 370px;
  padding: 6px 8px;
  white-space: pre-line;
`;

const OwnBubble = styled(Bubble)`
  background-color: ${props => props.theme.palette.primary1};
  color: white;
`;

const ClientBubble = styled(Bubble)`
  background-color: ${props => props.theme.palette.lightGray};
`;

class MessageFeed extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showAll: false,
      addedCount: 0,
    };
  }

  _toggleShowAll = () => {
    this.setState({
      showAll: !this.state.showAll,
    });
  };

  renderBubble = comment => {
    const isOwn = !!comment.dietitian_id;
    const Component = isOwn ? OwnBubble : ClientBubble;

    var options = {
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
    const a = new Date(comment.publish_time);
    const time = a.toLocaleString(this.props.locale, options);
    return (
      <div
        key={`${time}_${comment.comment}`}
        style={{ alignSelf: isOwn ? 'flex-end' : 'flex-start' }}
      >
        <Tooltip
          html={<div style={{ fontSize: 14 }}>{time}</div>}
          position="left"
        >
          <div>
            <Component className="mdc-typography--body2" key={comment.id}>
              <Twemoji text={comment.comment} />
            </Component>
          </div>
        </Tooltip>
      </div>
    );
  };

  renderCommentsLoader = showCount => {
    const { comments } = this.props;
    if (comments.length <= showCount) return null;

    const { showAll } = this.state;
    return (
      <ViewMore
        open={showAll}
        showMessage={messages.viewMore}
        closeMessage={messages.viewLess}
        remaining={comments.length - showCount}
        onClick={this._toggleShowAll}
      />
    );
  };

  render() {
    const { comments } = this.props;
    if (!comments) return null;

    const showCount = SHOW_COUNT + this.state.addedCount;
    return (
      <Wrapper count={comments.length}>
        {this.state.showAll && comments.length > 5
          ? this.renderCommentsLoader(showCount)
          : null}
        {(this.state.showAll
          ? comments
          : take(comments, showCount)
        ).map(comment => this.renderBubble(comment))}
        {this.renderCommentsLoader(showCount)}
      </Wrapper>
    );
  }
}

export default MessageFeed;
