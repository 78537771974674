import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Logo = styled.img`
  height: 45px;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 2;
  &:hover {
    cursor: pointer;
  }
`;

export default () => {
  return (
    <Wrapper>
      <Link to="/">
        <Logo src="/logo_small.png" alt="logo" />
      </Link>
    </Wrapper>
  );
};
