import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Button } from 'components/material/Button';
import styled from 'styled-components';
import Typography from 'components/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const messages = defineMessages({
  title: { id: 'userFoods.empty.title' },
  help: { id: 'userFoods.empty.help' },
  addBtn: { id: 'userFoods.empty.new' },
});

const SuggestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const AddFoodItem = styled(Button)`
  margin-top: 8px;
  padding-left: 20px;
  padding-right: 20px;
`;

function NoUserFoods({ onAdd }) {
  const [render, setRender] = useState(false);
  useEffect(() => {
    // Debounce rendering.
    setTimeout(() => setRender(true), 50);
  }, []);

  if (!render) return null;

  return (
    <Wrapper>
      <SuggestionContainer>
        <Typography use="headline6" rank="primary">
          <FormattedMessage {...messages.title} />
        </Typography>
        <Typography
          use="body2"
          rank="secondary"
          tag="div"
          style={{ maxWidth: 450, textAlign: 'center' }}
        >
          <FontAwesomeIcon
            icon={['fad', 'lightbulb-exclamation']}
            aria-hidden="true"
            style={{ paddingRight: 3 }}
          />
          <FormattedMessage {...messages.help} />
        </Typography>
        <AddFoodItem outlined rank="accent" onClick={onAdd}>
          <FormattedMessage {...messages.addBtn} />
        </AddFoodItem>
      </SuggestionContainer>
    </Wrapper>
  );
}

export default NoUserFoods;
