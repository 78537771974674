import React, { useEffect } from 'react';
import FETCH_STATUS from '../../../../constants/fetchStatus';
import { useHotkeys } from 'react-hotkeys-hook';
import useDebouncedCallback from 'use-debounce/lib/callback';
import { useParams } from 'react-router-dom';

function TemplateLoader(props) {
  const {
    templateId,

    fetchStatus,

    loadTemplate,
    fetchTemplate,
    stageTemplate,
    save,

    isDirty,

    fictiveClientId,
    selectClient,
  } = props;

  const params = useParams();

  function saveVersion() {
    if (isDirty) return save();

    return Promise.resolve();
  }
  function noDefaultSave(e) {
    e.preventDefault();
    e.stopPropagation();
    saveVersion();
  }

  useHotkeys('ctrl+s', noDefaultSave);
  useHotkeys('cmd+s', noDefaultSave);

  // We do not want to save instantly, to avoid creating to many versions
  const [debouncedSave] = useDebouncedCallback(save, 4 * 1000, [], {
    maxWait: 60 * 1000,
  });

  // Stage the template based on the route
  useEffect(() => {
    if (params.templateId && !templateId) {
      stageTemplate(params.templateId);
    }
  }, [params.templateId, templateId]);

  // Fetch the template structure
  useEffect(() => {
    if (fetchStatus === FETCH_STATUS.NONE && templateId) {
      fetchTemplate(templateId);
    }
  }, [fetchStatus, templateId]);

  // Load the template after it was fetched.
  useEffect(() => {
    if (fetchStatus === FETCH_STATUS.LOADED && templateId) {
      loadTemplate(templateId);
    }
  }, [fetchStatus, templateId]);

  // Select a fictive client to let the user view some data
  useEffect(() => {
    if (fictiveClientId) {
      selectClient(fictiveClientId);
    }
  }, [fictiveClientId]);

  // Save the template when it becomes dirty
  useEffect(() => {
    if (isDirty) debouncedSave();
  }, [isDirty]);

  return null;
}

class Loader extends React.Component {
  save = () => {
    if (this.props.isDirty) {
      this.props.save();
    }
  };

  onUnload = event => {
    if (this.props.isDirty) {
      this.save();
      event.returnValue = `Are you sure you want to leave?`;
    }
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    this.save();
    window.removeEventListener('beforeunload', this.onUnload);
  }

  render() {
    return <TemplateLoader {...this.props} />;
  }
}

export default Loader;
