import styled from 'styled-components';

export const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  margin-top: 35px;
  font-family: poppins;
`;

export const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  opacity: 0.54;
  margin-bottom: 20px;
  font-family: poppins;
`;

export const Background = styled.div`
  display: flex;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  flex-direction: column;
`;
