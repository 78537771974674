import CreatorView from './CreatorView';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { getModalProps, updateModalProps } from 'redux/modules/modals/modals';
import {
  getPublicBuilderBuilderId,
  getPublicBuilderName,
  getPublicBuilderType,
} from 'redux/modules/builders/publicBuilders/publicBuilders.selectors';
import {
  getNamespaceGroups,
  getCleanSelectedOptionsNames,
} from 'redux/modules/UI/builderView/builderView.selectors';
import { fetchBuilderConfig } from 'redux/modules/builders/builderConfigs';
import { fetchPublicBuilder } from 'redux/modules/builders/publicBuilders';
import {
  initBuilderOp,
  createBuilderConfigOp,
} from 'redux/modules/UI/builderView/builderView.operations';
import { getBuilderConfigBuilderId } from 'redux/modules/builders/builderConfigs/builderConfigs.selectors';
import { getBuilderName } from 'redux/modules/builders/builders/builders.selectors';
import {
  getEntryQuantity,
  getEntryMeasureId,
} from 'redux/modules/entries/entries.selectors';

const mapStateToProps = (state, { namespace, foodId, entryId }) => {
  const publicBuilderId = get(getModalProps(state), 'publicBuilderId', null);
  const configId = get(getModalProps(state), 'configId', null);
  const builderId = configId
    ? getBuilderConfigBuilderId(state, configId)
    : getPublicBuilderBuilderId(state, publicBuilderId);

  return {
    publicBuilderId,
    configId,

    builderId,

    name: publicBuilderId
      ? getPublicBuilderName(state, publicBuilderId)
      : getBuilderName(state, builderId),
    groups: getNamespaceGroups(state, namespace, builderId, publicBuilderId),
    optionsNames: getCleanSelectedOptionsNames(state, namespace, builderId),
    builderType: getPublicBuilderType(state, publicBuilderId),

    startQuantity: getEntryQuantity(state, entryId),
    startMeasureId: getEntryMeasureId(state, entryId),
  };
};

const mapDispatchToProps = (dispatch, { namespace }) => ({
  setNamespace: namespace => dispatch(updateModalProps({ namespace })),

  fetchBuilderConfig: configId => dispatch(fetchBuilderConfig(configId)),
  setConfigId: configId => {
    dispatch(updateModalProps({ configId }));
  },

  fetchPublicBuilder: publicBuilderId =>
    dispatch(fetchPublicBuilder(publicBuilderId)),
  setPublicBuilderId: publicBuilderId => {
    dispatch(updateModalProps({ publicBuilderId }));
  },

  initBuilder: (builderId, { configId, publicBuilderId }) => {
    dispatch(
      initBuilderOp(namespace, builderId, { configId, publicBuilderId })
    );
  },

  createBuilderConfig: builderId =>
    dispatch(createBuilderConfigOp(namespace, builderId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreatorView);
