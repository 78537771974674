import { createSelector } from 'reselect';
import omit from 'lodash/omit';
import { useSelector, useDispatch } from 'react-redux';

const pre = 'keenoa/modals/';
export const SHOW_MODAL = `${pre}SHOW_MODAL`;
export const HIDE_MODAL = `${pre}HIDE_MODAL`;
export const UPDATE_MODAL_PROPS = `${pre}UPDATE_MODAL_PROPS`;
export const CLOSE_ALL_MODALS = `${pre}CLOSE_ALL_MODALS`;

export const MODAL_TYPES = {
  NEW_CLIENT: 'NEW_CLIENT',
  NEW_STUDY: 'NEW_STUDY',
  SELECT_STUDY: 'SELECT_STUDY',
  SELECT_PARTICIPANT_COUNT: 'SELECT_PARTICIPANT_COUNT',
  NEW_CLIENT_PREVIEW: 'NEW_CLIENT_PREVIEW',
  LOG_DATE_SELECTION: 'LOG_DATE_SELECTION',
  EDIT_ENTRY: 'EDIT_ENTRY',
  NEW_ENTRY: 'NEW_ENTRY',
  ADD_FOOD: 'ADD_FOOD',
  UPDATE_TOOLBOX: 'UPDATE_TOOLBOX',
  UPDATE_NAME: 'UPDATE_NAME',
  UPDATE_LOCALE: 'UPDATE_LOCALE',
  ADD_PAYMENT: 'ADD_PAYMENT',
  WELCOME: 'WELCOME',
  REVOKED_CLIENT_ERROR: 'REVOKED_CLIENT_ERROR',
  TEST_CLIENT_ERROR: 'TEST_CLIENT_ERROR',
  PAYMENT_DUE: 'PAYMENT_DUE',
  MINDFUL_HELP: 'MINDFUL_HELP',
  MEAL_GROUP: 'MEAL_GROUP',
  PROFILE: 'PROFILE',
  EXPORT_PDF: 'EXPORT_PDF',
  PICK_EXPORT_TEMPLATE: 'PICK_EXPORT_TEMPLATE',
  CREATE_REPORT_TEMPLATE: 'CREATE_REPORT_TEMPLATE',

  EDIT_WIDGET_NUTRIENTS: 'EDIT_WIDGET_NUTRIENTS',
  EDIT_TOOLBOX_NUTRIENTS: 'EDIT_TOOLBOX_NUTRIENTS',

  CONFIRMATION_DIALOG: 'CONFIRMATION_DIALOG',

  LOOM_VIDEO: 'LOOM_VIDEO',

  EXPORT_CLIENTS: 'EXPORT_CLIENTS',
  EDIT_EXPORT_NUTRIENTS: 'EDIT_EXPORT_NUTRIENTS',

  EDIT_CLIENT_NUTRIENTS: 'EDIT_CLIENT_NUTRIENTS',

  HELP_CENTER: 'HELP_CENTER',
  YOUTUBE_VIDEO: 'YOUTUBE_VIDEO',
};

/**======================================================
 * REDUCERS
 *=======================================================*/
const initialState = {
  modalType: null,
  modalProps: {},
  previous: [],
};
const modals = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      const previous = [...state.previous];
      if (action.keepPrevious && state.modalType) {
        previous.push(omit(state, 'previous'));
      }
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
        previous,
      };
    case CLOSE_ALL_MODALS:
      return initialState;
    case HIDE_MODAL:
      if (state.modalType !== action.modalType) return state;
      if (state.previous.length > 0) {
        const previous = [...state.previous];
        const prev = previous.pop();
        return {
          ...prev,
          previous,
        };
      }
      return initialState;
    case UPDATE_MODAL_PROPS:
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          ...action.modalProps,
        },
      };
    default:
      return state;
  }
};

export default modals;

/**======================================================
 * SELECTORS
 *=======================================================*/
const _getModals = state => state.modals;
const getModalType = createSelector(_getModals, x => x.modalType);

export const getModalOpen = createSelector(
  [getModalType, (_, type) => type],
  (modalType, type) => type === modalType
);

export const getModalProps = createSelector(_getModals, x => x.modalProps);

/**======================================================
 * ACTIONS
 *=======================================================*/
export const openModal = (modalType, modalProps, keepPrevious = false) => ({
  type: SHOW_MODAL,
  modalType,
  modalProps,
  keepPrevious,
});

export const closeModal = (modalType, modalProps) => ({
  type: HIDE_MODAL,
  modalType,
  modalProps,
});

export const closeAllModals = () => ({
  type: CLOSE_ALL_MODALS,
});

export const updateModalProps = modalProps => ({
  type: UPDATE_MODAL_PROPS,
  modalProps,
});

export const useModal = modalType => {
  const isOpen = useSelector(s => getModalOpen(s, modalType));
  const dispatch = useDispatch();

  const onExit = () => {
    dispatch(closeModal(modalType));
  };

  return [isOpen, onExit];
};
