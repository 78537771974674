import React from 'react';
import { timeStringToSeconds } from '../../../../../../../utils/dateUtils';
const HOUR_IN_S = 60 * 60;

class TimeItem extends React.PureComponent {
  _realSeconds = () => {
    const { time, startHour, endHour } = this.props;
    const seconds = timeStringToSeconds(time);
    const start = startHour * HOUR_IN_S;
    if (seconds < start && endHour > 24) {
      return seconds + 24 * HOUR_IN_S;
    }
    return seconds;
  };

  _topOffset = () => {
    const { startHour, endHour } = this.props;
    const seconds = this._realSeconds();
    const start = startHour * HOUR_IN_S;
    const end = endHour * HOUR_IN_S;
    const total = end - start;
    const fromStart = seconds - start;
    const percent = (fromStart / total) * 100;

    return percent;
  };
  render() {
    const { startHour, endHour } = this.props;
    const seconds = this._realSeconds();
    const start = startHour * HOUR_IN_S;
    const end = endHour * HOUR_IN_S;
    if (seconds > end || seconds < start) return null;

    return (
      <div
        style={{
          position: 'absolute',
          top: `${this._topOffset()}%`,
          left: 0,
          right: 0,
          margin: '0 auto',
          display: 'flex',
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default TimeItem;
