import {
  Menu as M,
  MenuItem as MI,
  SimpleMenu as SM,
  MenuSurfaceAnchor as MSA,
} from '@rmwc/menu';

import '@rmwc/menu/styles';

export const Menu = M;
export const MenuItem = MI;
export const SimpleMenu = SM;
export const MenuSurfaceAnchor = MSA;
