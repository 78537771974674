import { combineReducers } from 'redux';
import builders from './builders';
import builderConfigs from './builderConfigs';
import publicBuilders from './publicBuilders';

export default combineReducers({
  builders,
  builderConfigs,
  publicBuilders,
});
