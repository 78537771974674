import React from 'react';
import styled from 'styled-components';
import Color from 'color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = styled.div`
  color: ${props =>
    props.color
      ? 'white'
      : Color(props.theme.palette.error)
          .fade(0.2)
          .toString()};
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding: 10px 20px;

  transition: opacity 0.15s ease-in-out;

  ${Button}:hover {
    transform: scale(1.4);
    cursor: pointer;
    color: ${props => props.theme.palette.accent1};
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  transition: font-size 0.15s ease-in-out;
`;

const DeleteButton = ({ onClick, styles }) => (
  <Wrapper
    style={styles}
    onClick={e => {
      e.stopPropagation();
      onClick();
    }}
  >
    <Button color={styles ? styles.color : null}>
      <StyledIcon icon={['fas', 'times-circle']} />
    </Button>
  </Wrapper>
);

export default DeleteButton;
