import React from 'react';
import styled, { withTheme } from 'styled-components';
import { defineMessages, injectIntl } from 'react-intl';
import { ONBOARD_IDS } from 'constants/index.js';
import { Export, Verify } from './Buttons';
import { TOGGLES } from 'toggles';
import SimpleTour from 'components/SimpleTour';
import Typography from 'components/material/Typography';

const messages = defineMessages({
  meal: { id: 'foodDiary.toolbar.meal' },
  meals: { id: 'foodDiary.toolbar.meals' },
  verified: { id: 'foodDiary.toolbar.verified' },
  verifiedCheck: { id: 'foodDiary.toolbar.verifiedCheck' },
  verifiedHelp: { id: 'foodDiary.toolbar.verifiedHelp' },
  verifiedClose: {
    id: 'foodDiary.toolbar.verifiedClose',
    defaultMessage: 'Ok!',
  },
  verifiedTitle: { id: 'foodDiary.toolbar.verifiedTitle' },
  days: { id: 'foodDiary.toolbar.days' },
  day: { id: 'foodDiary.toolbar.day' },
  compareTitle: { id: 'foodDiary.toolbar.compareTitle' },
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: none;
  margin-bottom: 8px;
`;

function Toolbar({ compareMode, compareCount, mealCount, date, energy, intl }) {
  const renderTitle = (title, subtitle) => (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography use="headline5" rank="primary">
          {title}
        </Typography>
        <Typography use="body2" rank="secondary">
          {subtitle}
        </Typography>
      </div>
    </div>
  );

  const renderCompare = () => {
    const days = intl.formatMessage(
      compareCount > 1 ? messages.days : messages.day
    );
    const title = `${intl.formatMessage(messages.compareTitle)}`;
    const subtitle = `${compareCount} ${days}`;
    return (
      <Wrapper>
        {renderTitle(title, subtitle)}
        {TOGGLES.REPORT_EXPORT ? <Export /> : null}
      </Wrapper>
    );
  };

  const renderSingle = () => {
    const mealsMsg = mealCount ? messages.meals : messages.meal;
    const meals = intl.formatMessage(mealsMsg);
    const cals = `${Math.round(energy)} cal`;
    const title = `${date}`;
    const subtitle = `${mealCount} ${meals} | ${cals}`;
    return (
      <Wrapper>
        {renderTitle(title, subtitle)}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <SimpleTour
            onboardId={ONBOARD_IDS.VERIFY}
            target=".verified-step"
            titleMsg={messages.verifiedTitle}
            contentMsg={messages.verifiedHelp}
            joyrideOptions={{
              event: 'hover',
              spotlightClicks: true,
            }}
          >
            <div className="verified-step">
              <Verify />
            </div>
          </SimpleTour>

          {TOGGLES.REPORT_EXPORT ? (
            <>
              <Export />
            </>
          ) : null}
        </div>
      </Wrapper>
    );
  };

  return compareMode ? renderCompare() : renderSingle();
}

export default withTheme(injectIntl(Toolbar));
