import React from 'react';
import styled from 'styled-components';
import NewClientModalContainer from './NewClient/NewClientModalContainer';
import WelcomeModal from './WelcomeModal';
import SearchBar from './SearchBar';
import ClientList from './ClientList';
import { defineMessages, FormattedMessage } from 'react-intl';
import ClientsFiltering from './ClientsFiltering';
import ExportModal from './ExportModal';
import { useSelector } from 'react-redux';
import { getVisibleClientsCount } from 'redux/modules/clients/clients.selectors';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import Client from '../Client';
import { Typography } from 'components/material/Typography';
import { PageWrapper } from 'components/PageWrapper';
import ClientActions from './ClientActions';
import CoursesFiltering from './CoursesFiltering';
import StudiesFiltering from './StudiesFiltering';
import {
  getDietitianIsInstructor,
  getDietitianIsResearcher,
} from 'redux/modules/account/account.selectors';

const messages = defineMessages({
  title: { id: 'clients.title' },
});

const ClientsWrapper = styled(PageWrapper)`
  position: fixed;
  top: 0;
  left: 54px;
  right: 0;
  bottom: 20px;
`;

const ActionsWrapper = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
`;

export default function() {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`}>
          <Clients />
        </Route>
        <Route path={`${match.path}/:clientId`}>
          <Client />
        </Route>
      </Switch>
    </>
  );
}

function Clients() {
  const clientsCount = useSelector(getVisibleClientsCount);
  const isInstructor = useSelector(getDietitianIsInstructor);
  const isResearcher = useSelector(getDietitianIsResearcher);

  const renderClients = () => {
    return (
      <ClientsWrapper n={clientsCount} free>
        <Typography use="headline5" rank="primary" tag="div" spacing="lg">
          <FormattedMessage {...messages.title} />
        </Typography>
        <SearchBar />
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ClientsFiltering />
            {isInstructor ? <CoursesFiltering /> : null}
            {isResearcher ? <StudiesFiltering /> : null}
          </div>
          <ActionsWrapper>
            <ClientActions />
          </ActionsWrapper>
        </div>

        <ClientList />
      </ClientsWrapper>
    );
  };

  return (
    <>
      {renderClients()}
      <NewClientModalContainer />
      <WelcomeModal />
      <ExportModal />
    </>
  );
}
