/**
 * Widgets temps allow us to save settings, values for a widget that
 * do not need to be persisted. An example is the "right" value for
 * the nutrientSummary widget, it is a setting for the widget but we
 * can re-generate it so there is no need to persist it.
 */
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import reportViewTypes from '../reportView.types';
import get from 'lodash/get';
import createCachedSelector from 're-reselect';

export const byId = (state = {}, action) => {
  switch (action.type) {
    case reportViewTypes.UPDATE_WIDGET_TEMP: {
      const widgetState = state[action.widgetId] || {};
      return {
        ...state,
        [action.widgetId]: { ...widgetState, ...action.payload },
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  byId,
});

/** SELECTORS */
const _getWidgetsTemps = state => get(state, 'UI.reportView.widgetsTemp');

/** (state) */
export const getById = createSelector(
  _getWidgetsTemps,
  x => get(x, 'byId')
);

/** (state, widgetId) */
export const getWidgetTemp = createCachedSelector(
  [getById, (_, widgetId) => widgetId],
  (byId, widgetId) => byId[widgetId]
)((_, widgetId) => widgetId);

export const getWidgetNutrientsRight = createSelector(
  getWidgetTemp,
  widget => get(widget, 'right', {})
);
