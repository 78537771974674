import { combineReducers } from 'redux';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { getSmallLocale } from '../../../account/account.selectors';
import types from './settings.types';

export const locale = (state = null, action) => {
  switch (action.type) {
    case types.UPDATE_LOCALE:
      return action.locale;
    default:
      return state;
  }
};

export default combineReducers({
  locale,
});

/////////////////////
// Selectors
/////////////////////
const _getSettings = state => get(state, 'UI.reportView.settings');

export const getReportLocale = createSelector(
  [_getSettings, getSmallLocale],
  (settings, dietitianLocale) => get(settings, 'locale') || dietitianLocale
);
