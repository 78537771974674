import React from 'react';
import { Button } from 'components/material/Button';
import Typography from 'components/material/Typography';
import styled from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import Dropdown from 'components/Dropdown';
import { Twemoji } from 'react-emoji-render';

const THUMBNAIL_SIZE = 85;
const PADDING_SIZE = 4;

const messages = defineMessages({
  addEntry: {
    id: 'entries.addEntry',
    defaultMessage: 'Add entry',
  },
  addEntryHelp: {
    id: 'entries.addEntryHelp',
  },
});

const PicturesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${PADDING_SIZE}px;
`;

const baseStyle = `
  margin: ${PADDING_SIZE}px;
  transition: all 0.15s ease-in-out;
  border-radius: 6px;
  width: ${THUMBNAIL_SIZE}px;
  height: ${THUMBNAIL_SIZE}px;
  background-color: #ecf0f1;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const Picture = styled.img`
  ${baseStyle};
`;

const EmojiWrapper = styled.div`
  ${baseStyle};

  display: flex;
  justify-content: center;
  align-items: center;
`;

class AddButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  _width = () => {
    const { meals } = this.props;
    const length = meals.length;
    if (length === 2) return 2 * THUMBNAIL_SIZE + 4 * PADDING_SIZE;
    return 3 * THUMBNAIL_SIZE + 6 * PADDING_SIZE;
  };

  _onClick = () => {
    const { meals, addFood } = this.props;
    if (meals.length === 1) {
      addFood(meals[0]);
    } else {
      this._toggleMeals();
    }
  };

  _toggleMeals = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  renderMealSelector = () => {
    const { pictures, meals, addFood } = this.props;
    return (
      <Dropdown
        isOpen={this.state.open}
        onOutsideClick={this._toggleMeals}
        top="20px"
        left="-180px"
      >
        <Typography
          use="subtitle2"
          rank="primary"
          style={{ textAlign: 'center' }}
        >
          <FormattedMessage {...messages.addEntryHelp} />
        </Typography>
        <PicturesContainer style={{ width: this._width() }}>
          {pictures.map(({ photo, emoji }, i) =>
            emoji ? (
              <EmojiWrapper
                key={`${emoji}-${i}`}
                onClick={() => {
                  this._toggleMeals();
                  addFood(meals[i]);
                }}
              >
                <Twemoji
                  text={emoji}
                  style={{ fontSize: 0.4 * THUMBNAIL_SIZE }}
                />
              </EmojiWrapper>
            ) : (
              <Picture
                key={photo}
                src={photo}
                onClick={() => {
                  this._toggleMeals();
                  addFood(meals[i]);
                }}
              />
            )
          )}
        </PicturesContainer>
      </Dropdown>
    );
  };

  render() {
    const { className, style } = this.props;
    return (
      <>
        <Button
          rank="secondary"
          dense
          outlined
          onClick={this._onClick}
          style={{
            whiteSpace: 'nowrap',
            marginBottom: 10,
            alignSelf: 'flex-end',
            flexShrink: 0,
            ...(style || {}),
          }}
          className={className}
        >
          <FormattedMessage {...messages.addEntry} />
        </Button>
        {this.renderMealSelector()}
      </>
    );
  }
}

export default AddButton;
