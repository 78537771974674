import styled from 'styled-components';
import { SIZES, SPACINGS } from 'components/Spacer';
import { LEFT_NAVIGATION_WIDTH } from './LeftNavigation';
import { LEFT_NAV_WIDTH } from 'constants/index.js';

export const PageWrapper = styled.div`
  margin: auto;

  ${x => (x.dense ? 'max-width: 750px' : '')};

  @media (min-width: 960px) {
    width: 90%;
    margin: 0 auto;
    min-width: ${960 -
      (LEFT_NAVIGATION_WIDTH + LEFT_NAV_WIDTH + 2 * SIZES[SPACINGS.large])}px;
  }
  @media (max-width: 960px) {
    padding: 0 ${SIZES[SPACINGS.large]}px;
  }

  --mdc-theme-secondary: ${x => x.theme.palette.primary1};
`;
