import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Button } from 'components/material/Button';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from 'components/material/Typography';
import Spacer from 'components/Spacer';
import { useSelector, useDispatch } from 'react-redux';
import { getDietitianIsResearcher } from 'redux/modules/account/account.selectors';
import { MODAL_TYPES, openModal } from 'redux/modules/modals/modals';

const messages = defineMessages({
  title: {
    id: 'clientList.noClient.title',
    defaultMessage: 'You can add a new client right here!',
  },
  hint: {
    id: 'clientList.noClient.hint',
    defaultMessage: '(Hint: Add yourself to experiment with the platform.)',
  },
  addBtn: {
    id: 'clientList.noClient.addBtn',
    defaultMessage: 'Invite',
  },
});

const SuggestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

const AddClientButton = styled(Button)`
  margin-top: 8px;
  padding-left: 20px;
  padding-right: 20px;
`;

function NoResults() {
  const isResearcher = useSelector(getDietitianIsResearcher);
  const dispatch = useDispatch();

  const _onClick = () => {
    if (isResearcher) {
      dispatch(openModal(MODAL_TYPES.SELECT_STUDY));
    } else {
      dispatch(openModal(MODAL_TYPES.NEW_CLIENT));
    }
  };

  return (
    <SuggestionContainer>
      <Typography use="headline6" rank="primary">
        <FormattedMessage {...messages.title} />
      </Typography>
      <Typography use="body2" rank="secondary" tag="div">
        <FontAwesomeIcon
          icon={['fad', 'lightbulb-exclamation']}
          aria-hidden="true"
          style={{ paddingRight: 3 }}
        />
        <FormattedMessage {...messages.hint} />
      </Typography>
      <Spacer size="md" />
      <AddClientButton outlined rank="accent" onClick={_onClick}>
        <FormattedMessage {...messages.addBtn} />
      </AddClientButton>
    </SuggestionContainer>
  );
}

export default NoResults;
