import { connect } from 'react-redux';
import ChartContent from './ChartContent';
import { getOrderedCompareDiaries } from 'redux/modules/foodDiaries/foodDiaries.selectors';
import {
  getCurrentClientStartHour,
  getCurrentClientId,
  getCurrentClientSettings,
} from 'redux/modules/clients/clients.selectors';
import { updateClientSettings } from 'redux/modules/clients/clients.actions';

const mapStateToProps = state => ({
  diaries: getOrderedCompareDiaries(state),
  startHour: getCurrentClientStartHour(state),
  endHour: 24 + getCurrentClientStartHour(state),

  clientId: getCurrentClientId(state),
  settings: getCurrentClientSettings(state),
});

const mapDispatchToProps = dispatch => ({
  updateSettings: (clientId, settings) =>
    dispatch(updateClientSettings(clientId, settings)),
});

const mergeProps = (stateProps, { updateSettings }, ownProps) => ({
  ...stateProps,
  ...ownProps,
  setStartHour: startHour =>
    updateSettings(stateProps.clientId, {
      ...stateProps.settings,
      startHour,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ChartContent);
