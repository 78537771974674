import React, { useState } from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Twemoji } from 'react-emoji-render';
import { Button } from 'components/material/Button';
import { TextField } from 'components/forms/Textfield';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title, Background } from './components';
import times from 'lodash/times';
import { validateEmail } from '../../utils/inputValidation';
import { openYoutubeVideo, makeCustomIcon } from '../../utils/misc';
import PreviewModal from '../Dashboard/Clients/NewClient/PreviewModal';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { getSmallLocale } from 'redux/modules/account/account.selectors';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';
import { inviteClient } from 'redux/modules/clients/clients.operations';
import { ONBOARD_IDS } from '../../constants';
import { setOnboardRun } from 'redux/modules/account/account.operations';
import { ButtonLoader } from 'components/ButtonLoader';
import { useHistory } from 'react-router-dom';

const messages = defineMessages({
  title: { id: 'onboard.invite.title' },
  subTitle: { id: 'onboard.invite.subTitle' },
  video: { id: 'onboard.invite.video' },
  watch: { id: 'onboard.invite.watch' },
  email: { id: 'onboard.invite.email' },
  invite: { id: 'onboard.invite.invite' },
  invites: { id: 'onboard.invite.invites' },
  preview: { id: 'onboard.invite.preview' },
});

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 500px;
  box-sizing: border-box;
`;

const HelpText = styled.div`
  margin-top: 10px;
  color: ${x => x.theme.palette.text.secondary};
  font-family: poppins;
`;

const VideoBox = styled.div`
  position: relative;
  background-color: #ebf4ff;
  position: relative;
  padding: 10px;
  border-radius: 8px;
  color: ${x => x.theme.palette.text.secondary};
  box-sizing: border-box;
  margin-top: 10px;

  font-family: poppins;
  font-size: 14px;
  font-weight: 600;
`;

let keys = times(3, i => i);
let startEmails = {};
keys.forEach(i => {
  startEmails[i] = '';
});

function Invite({ intl }) {
  const [emails, setEmails] = useState(startEmails);
  const [loading, setLoading] = useState(false);
  const locale = useSelector(getSmallLocale);
  const dispatch = useDispatch();
  const history = useHistory();

  const openPreview = () => {
    dispatch(openModal(MODAL_TYPES.NEW_CLIENT_PREVIEW));
  };

  const makeUpdateEmail = i => e => {
    setEmails({
      ...emails,
      [i]: e.target.value,
    });
  };

  let readyEmails = [];
  for (let i = 0; i < keys.length; ++i) {
    const email = emails[i];
    if (validateEmail(email)) {
      readyEmails.push(email);
    }
  }

  const sendInvitations = async () => {
    setLoading(true);
    await Promise.all(
      readyEmails.map(email => dispatch(inviteClient(email, locale)))
    );
    await dispatch(setOnboardRun(ONBOARD_IDS.INVITE));
    setLoading(false);
    history.push({ pathname: '/onboard-3', state: { emails: readyEmails } });
  };

  return (
    <Background>
      <Wrapper>
        <Title>
          <FormattedMessage {...messages.title} /> <Twemoji text={'🙌'} />
        </Title>
        <HelpText>
          <FormattedMessage {...messages.subTitle} />
        </HelpText>
        <VideoBox>
          <div style={{ fontSize: 14, marginBottom: 5 }}>
            <FontAwesomeIcon
              icon={['fad', 'question-circle']}
              style={{ marginRight: 4 }}
            />
            <FormattedMessage {...messages.video} />
          </div>
          <div>
            <Button
              rank="saturated"
              onClick={() =>
                openYoutubeVideo(
                  locale === 'fr' ? '44f58MKaK6w' : 'kwHSp8c5v1Q'
                )
              }
              icon={makeCustomIcon(['fad', 'play-circle'])}
              style={{ float: 'right' }}
            >
              <FormattedMessage {...messages.watch} style={{ fontSize: 16 }} />
            </Button>
          </div>
        </VideoBox>
        {times(3, i => (
          <>
            <div style={{ height: 10 }} />
            <TextField
              id={`email-prompt-${i + 1}`}
              type="email"
              outlined
              autoComplete="off"
              name="email"
              label={intl.formatMessage(messages.email, { i: i + 1 })}
              onChange={makeUpdateEmail(i)}
              value={emails[i]}
            />
          </>
        ))}
        <div
          style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          <Button
            rank="saturated"
            unelevated
            disabled={!readyEmails.length || loading}
            onClick={sendInvitations}
          >
            <FormattedMessage
              {...(readyEmails.length > 1 ? messages.invites : messages.invite)}
            />
            {!loading ? null : <ButtonLoader />}
          </Button>
          <Button
            style={{ marginRight: 5 }}
            onClick={openPreview}
            rank="saturated"
          >
            <FormattedMessage {...messages.preview} />
          </Button>
        </div>
        <PreviewModal
          email={get(readyEmails, 0, 'client@gmail.com')}
          locale={locale}
        />
      </Wrapper>
    </Background>
  );
}

export default injectIntl(Invite);
