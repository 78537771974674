import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import {
  defineMessages,
  injectIntl,
  useIntl,
  FormattedMessage,
} from 'react-intl';
import styled, { withTheme } from 'styled-components';
import TemplateCard from 'components/TemplateCard';
import H2 from 'components/H2';
import Typography from 'components/material/Typography';
import SearchBar from 'components/SearchBar';
import HorizontalLoader from 'components/loaders/HorizontalLoader';
import FETCH_STATUS from 'constants/fetchStatus';
import { useHistory, useRouteMatch } from 'react-router-dom';
import get from 'lodash/get';
import Spacer from 'components/Spacer';

const messages = defineMessages({
  header: { id: 'pickTemplate.header' },
  subheader: { id: 'pickTemplate.subheader' },
  placeholder: { id: 'pickTemplate.search.placeholder' },
  baseTitle: { id: 'pickTemplate.base.title' },
  baseDescription: { id: 'picktemplate.base.description' },

  templatesTitle: { id: 'pickTemplate.templates.title' },
  reportsTitle: { id: 'pickTemplate.reports.title' },
  previousTitle: { id: 'pickTemplate.previousReports.title' },

  emptyTitle: { id: 'pickTemplate.reports.emptyTitle' },
  emptyText: { id: 'pickTemplate.reports.emptyText' },

  createFromTemplate: { id: 'pickTemplate.template.creation' },

  downloaded: { id: 'pickTemplate.header.downloaded' },
  sent: { id: 'pickTemplate.header.sent' },
  creationDate: { id: 'pickTemplate.header.creationDate' },
  template: { id: 'pickTemplate.header.template' },

  continue: { id: 'pickTemplate.notify.continue' },
  duplicate: { id: 'pickTemplate.notify.duplicate' },

  cantFind: { id: 'pickTemplate.cantFind' },
  buildYours: { id: 'pickTemplate.buildYours' },
});

const TemplatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const useVisibleTemplates = (templates, searchText) => {
  // Search technique based on http://jsperf.com/substring-test
  const regex = new RegExp(searchText, 'i');
  return templates.filter(
    t =>
      (t.title && regex.test(t.title)) ||
      (t.description && regex.test(t.description)) ||
      (t.emoji && regex.test(t.emoji))
  );
};

function CreateReportModal({
  isOpen,
  status,
  onExit,
  fetchTemplates,
  templates,
  createReport,
  diaryIds,
}) {
  const intl = useIntl();
  const [searchText, setSearchText] = useState('');
  const visibleTemplates = useVisibleTemplates(templates, searchText);
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (isOpen && !templates.length) fetchTemplates();
  }, [isOpen]);

  const onSelectTemplate = async ({ id }) => {
    const clientId = match.params.clientId;
    const newReport = await createReport(id);
    const reportId = get(newReport, 'response.result');
    let url = `/clients/${clientId}/reports/${reportId}`;

    const params = new URLSearchParams();
    if (id) params.append('load_template_id', id);
    if (diaryIds.length) params.append('load_diaries', diaryIds);

    url += `?${params.toString()}`;
    history.push(url);
    onExit();
  };

  const renderLoader = () => (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 100,
      }}
    >
      <HorizontalLoader />
    </div>
  );

  const renderTemplates = () => {
    if (status === FETCH_STATUS.LOADING && !templates.length)
      return renderLoader();

    return (
      <TemplatesWrapper>
        <TemplateCard
          key="base"
          emoji="➕"
          title={intl.formatMessage(messages.baseTitle)}
          description={''}
          onClick={() => onSelectTemplate({ id: null })}
        />
        {visibleTemplates.map(template => (
          <React.Fragment key={template.id}>
            <TemplateCard
              {...template}
              onClick={() => onSelectTemplate(template)}
            />
          </React.Fragment>
        ))}
      </TemplatesWrapper>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onExit}
      style={{
        maxWidth: '685px',
        minHeight: '75vh',
      }}
    >
      <div
        style={{ overflowY: 'auto', maxHeight: '85vh', position: 'relative' }}
      >
        <H2>
          <FormattedMessage {...messages.header} />
        </H2>
        <Typography use="body2" rank="secondary" tag="div">
          <FormattedMessage {...messages.subheader} />
        </Typography>
        <Spacer spacing="md" />
        <SearchBar
          placeholder={intl.formatMessage(messages.placeholder)}
          value={searchText}
          onInputChange={x => setSearchText(x)}
        />
        <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
          {renderTemplates()}
        </div>
      </div>
    </Modal>
  );
}

export default withTheme(injectIntl(CreateReportModal));
