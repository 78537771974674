import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';

const messages = defineMessages({
  protein: {
    id: 'foodLog.protein',
    defaultMessage: 'Protein',
  },
  fat: {
    id: 'foodLog.fat',
    defaultMessage: 'Fat',
  },
  cholesterol: {
    id: 'foodLog.cholesterol',
    defaultMessage: 'Cholesterol',
  },
  monounsaturated: {
    id: 'foodLog.monounsatured',
    defaultMessage: 'Monounsatured',
  },
  polyunsaturated: {
    id: 'foodLog.polyunsaturated',
    defaultMessage: 'Polyunsaturated',
  },
  ala: {
    id: 'foodLog.ala',
    defaultMessage: '18:3n-3 (ALA)',
  },
  dha: {
    id: 'foodLog.dha',
    defaultMessage: '22:6n-3 (DHA)',
  },
  epa: {
    id: 'foodLog.epa',
    defaultMessage: '20:5n-3 (EPA)',
  },
  omega3: {
    id: 'foodLog.omega3',
    defaultMessage: 'Omega 3',
  },
  omega6: {
    id: 'foodLog.omega6',
    defaultMessage: 'Omega 6',
  },
  saturated: {
    id: 'foodLog.saturated',
    defaultMessage: 'Saturated',
  },
  transFat: {
    id: 'foodLog.transFat',
    defaultMessage: 'Trans',
  },
  trans_monoenoic: {
    id: 'foodLog.transMono',
    defaultMessage: 'Trans Monoenoic',
  },
  trans_polyenoic: {
    id: 'foodLog.transPoly',
    defaultMessage: 'Trans Polyenoic',
  },
  carbs: {
    id: 'foodLog.carbs',
    defaultMessage: 'Carbohydrate',
  },
  starch: {
    id: 'foodLog.starch',
    defaultMessage: 'Starch',
  },
  sucrose: {
    id: 'foodLog.sucrose',
    defaultMessage: 'Sucrose',
  },
  glucose: {
    id: 'foodLog.glucose',
    defaultMessage: 'Glucose',
  },
  fructose: {
    id: 'foodLog.fructose',
    defaultMessage: 'Fructose',
  },
  lactose: {
    id: 'foodLog.lactose',
    defaultMessage: 'Lactose',
  },
  maltose: {
    id: 'foodLog.maltose',
    defaultMessage: 'Maltose',
  },
  sugar: {
    id: 'foodLog.sugar',
    defaultMessage: 'Sugar',
  },
  galactose: {
    id: 'foodLog.galactose',
    defaultMessage: 'Galactose',
  },
  fiber: {
    id: 'foodLog.fiber',
    defaultMessage: 'Fiber',
  },
  monosaccarides: {
    id: 'foodLog.monosaccarides',
    defaultMessage: 'Monosaccarides',
  },
  disaccharides: {
    id: 'foodLog.disaccharides',
    defaultMessage: 'Disaccarides',
  },
  calories: {
    id: 'foodLog.calories',
    defaultMessage: 'Energy',
  },
  alcohol: {
    id: 'foodLog.alcohol',
    defaultMessage: 'Alcohol',
  },
  calcium: {
    id: 'foodLog.calcium',
    defaultMessage: 'Calcium',
  },
  iron: {
    id: 'foodLog.iron',
    defaultMessage: 'Iron',
  },
  magnesium: { id: 'foodLog.magnesium', defaultMessage: 'Magnesium' },
  phosphorus: { id: 'foodLog.phosphorus', defaultMessage: 'Phosphorus' },
  potassium: { id: 'foodLog.potassium', defaultMessage: 'Potassium' },
  sodium: { id: 'foodLog.sodium', defaultMessage: 'Sodium' },
  zinc: { id: 'foodLog.zinc', defaultMessage: 'Zinc' },
  copper: { id: 'foodLog.copper', defaultMessage: 'Copper' },
  manganese: { id: 'foodLog.manganese', defaultMessage: 'Manganese' },
  selenium: { id: 'foodLog.selenium', defaultMessage: 'Selenium' },
  retinol: { id: 'foodLog.retinol', defaultMessage: 'Retinol' },
  vit_a: { id: 'foodLog.vit_a', defaultMessage: 'Vit. A (RAE)' },
  beta_carotene: {
    id: 'foodLog.beta_carotene',
    defaultMessage: 'Beta carotene',
  },
  alpha_carotene: {
    id: 'foodLog.alpha_carotene',
    defaultMessage: 'Alpha carotene',
  },
  alpha_tocopherol: {
    id: 'foodLog.alpha_tocopherol',
    defaultMessage: 'Vit. E',
  },
  vitamin_d: { id: 'foodLog.vitamin_d', defaultMessage: 'Vit. D' },
  vitamin_d2_ergocalciferol: {
    id: 'foodLog.vitamin_d2_ergocalciferol',
    defaultMessage: 'Vit. D ergocalciferol',
  },
  vitamin_d_d2_and_d3: {
    id: 'foodLog.vitamin_d_d2_and_d3',
    defaultMessage: 'Vit. D (D2 + D3)',
  },
  beta_cryptoxanthin: {
    id: 'foodLog.beta_cryptoxanthin',
    defaultMessage: 'Beta cryptoxanthin',
  },
  lycopene: { id: 'foodLog.lycopene', defaultMessage: 'Lycopene' },
  lutein_and_zeaxanthin: {
    id: 'foodLog.lutein_and_zeaxanthin',
    defaultMessage: 'Lutein and zeaxanthin',
  },
  beta_tocopherol: {
    id: 'foodLog.beta_tocopherol',
    defaultMessage: 'Beta tocopherol',
  },
  gamma_tocopherol: {
    id: 'foodLog.gamma_tocopherol',
    defaultMessage: 'Gamma tocopherol',
  },
  delta_tocopherol: {
    id: 'foodLog.delta_tocopherol',
    defaultMessage: 'Delta tocopherol',
  },
  vitamin_c: { id: 'foodLog.vitamin_c', defaultMessage: 'Vit. C' },
  thiamin: { id: 'foodLog.thiamin', defaultMessage: 'Thiamin' },
  riboflavin: { id: 'foodLog.riboflavin', defaultMessage: 'Riboflavin' },
  niacin_preformed: {
    id: 'foodLog.niacin_preformed',
    defaultMessage: 'Niacin (nicotinic acid) preformed',
  },
  total_niacin_equivalent: {
    id: 'foodLog.total_niacin_equivalent',
    defaultMessage: 'Niacin equivalent',
  },
  pantothenic_acid: {
    id: 'foodLog.pantothenic_acid',
    defaultMessage: 'Pantothenic acid',
  },
  vitamin_b_6: { id: 'foodLog.vitamin_b_6', defaultMessage: 'Vit. B-6' },
  biotin: { id: 'foodLog.biotin', defaultMessage: 'Biotin' },
  total_folacin: {
    id: 'foodLog.total_folacin',
    defaultMessage: 'folacin',
  },
  vitamin_b_12: { id: 'foodLog.vitamin_b_12', defaultMessage: 'Vit. B-12' },
  choline__total: {
    id: 'foodLog.choline__total',
    defaultMessage: 'Choline, total',
  },
  vitamin_k: { id: 'foodLog.vitamin_k', defaultMessage: 'Vit. K' },
  folic_acid: { id: 'foodLog.folic_acid', defaultMessage: 'Folic acid' },
  naturally_occurring_folate: {
    id: 'foodLog.naturally_occurring_folate',
    defaultMessage: 'Naturally occurring folate',
  },
  dietary_folate_equivalents: {
    id: 'foodLog.dietary_folate_equivalents',
    defaultMessage: 'Folate, DFE',
  },
  betaine: { id: 'foodLog.betaine', defaultMessage: 'Betaine' },
  alpha_tocopherol__added: {
    id: 'foodLog.alpha_tocopherol__added',
    defaultMessage: 'Alpha-tocopherol, added',
  },
  vitamin_b12__added: {
    id: 'foodLog.vitamin_b12__added',
    defaultMessage: 'Vit. B12, added',
  },
  tryptophan: { id: 'foodLog.tryptophan', defaultMessage: 'Tryptophan' },
  threonine: { id: 'foodLog.threonine', defaultMessage: 'Threonine' },
  isoleucine: { id: 'foodLog.isoleucine', defaultMessage: 'Isoleucine' },
  leucine: { id: 'foodLog.leucine', defaultMessage: 'Leucine' },
  lysine: { id: 'foodLog.lysine', defaultMessage: 'Lysine' },
  methionine: { id: 'foodLog.methionine', defaultMessage: 'Methionine' },
  cystine: { id: 'foodLog.cystine', defaultMessage: 'Cystine' },
  phenylalanine: {
    id: 'foodLog.phenylalanine',
    defaultMessage: 'Phenylalanine',
  },
  tyrosine: { id: 'foodLog.tyrosine', defaultMessage: 'Tyrosine' },
  valine: { id: 'foodLog.valine', defaultMessage: 'Valine' },
  arginine: { id: 'foodLog.arginine', defaultMessage: 'Arginine' },
  histidine: { id: 'foodLog.histidine', defaultMessage: 'Histidine' },
  alanine: { id: 'foodLog.alanine', defaultMessage: 'Alanine' },
  aspartic_acid: {
    id: 'foodLog.aspartic_acid',
    defaultMessage: 'Aspartic acid',
  },
  glutamic_acid: {
    id: 'foodLog.glutamic_acid',
    defaultMessage: 'Glutamic acid',
  },
  glycine: { id: 'foodLog.glycine', defaultMessage: 'Glycine' },
  proline: { id: 'foodLog.proline', defaultMessage: 'Proline' },
  serine: { id: 'foodLog.serine', defaultMessage: 'Serine' },
  hydroxyproline: {
    id: 'foodLog.hydroxyproline',
    defaultMessage: 'Hydroxyproline',
  },
  aspartame: { id: 'foodLog.aspartame', defaultMessage: 'Aspartame' },
  water: { id: 'foodLog.water', defaultMessage: 'Water' },

  // Below are for nutrients sections
  fats: { id: 'nutrientsModal.fats' },
  proteins: { id: 'nutrientsModal.proteins' },
  sugars: { id: 'nutrientsModal.sugars' },
  vitamins: { id: 'nutrientsModal.vitamins' },
  others: { id: 'nutrientsModal.others' },

  ns_step: { id: 'foodLog.ns_step', defaultMessage: 'PAS' },
  ns_green_step: { id: 'foodLog.ns_green_step', defaultMessage: 'Green PAS' },
});

// It would be possible to make preset/custom list of nutrimentIds like this
export const defaultDietitianNutrimentsKeys = [
  'PROTEIN',
  'FAT',
  'CHOLESTEROL',
  'WATER',
  'MONOUNSATURATED',
  'POLYUNSATURATED',
  'SATURATED',
  'TRANS',
  'ALA',
  'DHA',
  'EPA',
  'OMEGA_3',
  'OMEGA_6',
  'CARBS',
  'SUGAR',
  'FIBER',
  'STARCH',
  'SUCROSE',
  'GLUCOSE',
  'LACTOSE',
  'CALORIES',
  'ALCOHOL',
  'CALCIUM',
  'IRON',
  'POTASSIUM',
  'SODIUM',
  'MAGNESIUM',
  'PHOSPHORUS',
  'ZINC',
  'VITAMIN_A',
  'VITAMIN_C',
  'VITAMIN_B_12',
  'VITAMIN_D',
  'ALPHA_TOCOPHEROL',
  'DIETARY_FOLATE_EQUIVALENTS',
  'LEUCINE',
  'TRYPTOPHAN',
  'PHENYLALANINE',
  'VALINE',
  'THREONINE',
  'METHIONINE',
  'ISOLEUCINE',
  'LYSINE',
  'HISTIDINE',
  'ASPARTAME',
  'THIAMIN',
  'RIBOFLAVIN',
  'BIOTIN',
  'VITAMIN_K',
  'MANGANESE',
];

export const defaultClientNutrimentsKeys = [
  'PROTEIN',
  'FAT',
  'CARBS',
  'CALORIES',
];

export const findCorrespondingNutriements = (nutrimentsToShow, nutriments) =>
  nutrimentsToShow.map(nutrimentConstant => {
    const nutriment = nutriments.find(
      nutriment => nutriment.code === nutrimentConstant.code
    );
    return nutriment
      ? {
          ...nutrimentConstant,
          ...nutriment,
        }
      : nutrimentConstant; // nutrient is not on the entry!
  });

export const getNutrimentFromCode = code => {
  const nutrient = Object.keys(nutrimentsConstants).find(
    constant => nutrimentsConstants[constant].code === code
  );
  return nutrimentsConstants[nutrient];
};

export const formatNutrients = macronutrients =>
  map(macronutrients, nutrient => {
    const nutrientConstant = getNutrimentFromCode(nutrient.code);
    return {
      id: nutrientConstant.id,
      name: <FormattedMessage {...nutrientConstant.label} />,
      ...nutrient,
    };
  });

export const formatNutrientNestedChild = (nutrient, nutrients) => {
  const children = nutrient.children
    ? nutrient.children.map(id => nutrients.find(n => n.id === id))
    : undefined;
  if (children) {
    const childrenTrimmed = children.filter(c => c); //remove undefined elements!
    return {
      ...nutrient,
      children: childrenTrimmed,
    };
  }
  return {
    ...nutrient,
    children: [],
  };
};

export const NUTRIENT_COLORS = {
  protein: 'protein',
  fat: 'primary2',
  carbs: 'primary3',
  calories: 'black',
  vitamins: 'primary4',
  alcohol: 'warning',
};

export const nutrimentsConstants = {
  CALORIES: {
    id: 'calories',
    label: messages.calories,
    unit: 'kcal',
    code: 208,
    keenoaCode: 'kcal',
    order: 0,
  },
  PROTEIN: {
    id: 'protein',
    label: messages.protein,
    unit: 'g',
    code: 203,
    keenoaCode: 'prot',
    order: 1,
    children: [
      'tryptophan',
      'threonine',
      'isoleucine',
      'leucine',
      'lysine',
      'methionine',
      'cystine',
      'phenylalanine',
      'tyrosine',
      'valine',
      'arginine',
      'histidine',
      'alanine',
      'aspartic_acid',
      'glutamic_acid',
      'glycine',
      'proline',
      'serine',
      'hydroxyproline',
    ],
  },
  TRYPTOPHAN: {
    id: 'tryptophan',
    label: messages.tryptophan,
    unit: 'g',
    code: 501,
    keenoaCode: 'trp',
    order: 46,
    parent: 'protein',
  },
  THREONINE: {
    id: 'threonine',
    label: messages.threonine,
    unit: 'g',
    code: 502,
    keenoaCode: 'thr',
    order: 47,
    parent: 'protein',
  },
  ISOLEUCINE: {
    id: 'isoleucine',
    label: messages.isoleucine,
    unit: 'g',
    code: 503,
    keenoaCode: 'iso',
    order: 48,
    parent: 'protein',
  },
  LEUCINE: {
    id: 'leucine',
    label: messages.leucine,
    unit: 'g',
    code: 504,
    keenoaCode: 'leu',
    order: 49,
    parent: 'protein',
  },
  LYSINE: {
    id: 'lysine',
    label: messages.lysine,
    unit: 'g',
    code: 505,
    keenoaCode: 'lys',
    order: 50,
    parent: 'protein',
  },
  METHIONINE: {
    id: 'methionine',
    label: messages.methionine,
    unit: 'g',
    code: 506,
    keenoaCode: 'met',
    order: 51,
    parent: 'protein',
  },
  CYSTINE: {
    id: 'cystine',
    label: messages.cystine,
    unit: 'g',
    code: 507,
    keenoaCode: 'cys',
    order: 52,
    parent: 'protein',
  },
  PHENYLALANINE: {
    id: 'phenylalanine',
    label: messages.phenylalanine,
    unit: 'g',
    code: 508,
    keenoaCode: 'phe',
    order: 53,
    parent: 'protein',
  },
  TYROSINE: {
    id: 'tyrosine',
    label: messages.tyrosine,
    unit: 'g',
    code: 509,
    keenoaCode: 'tyr',
    order: 54,
    parent: 'protein',
  },
  VALINE: {
    id: 'valine',
    label: messages.valine,
    unit: 'g',
    code: 510,
    keenoaCode: 'val',
    order: 55,
    parent: 'protein',
  },
  ARGININE: {
    id: 'arginine',
    label: messages.arginine,
    unit: 'g',
    code: 511,
    keenoaCode: 'arg',
    order: 56,
    parent: 'protein',
  },
  HISTIDINE: {
    id: 'histidine',
    label: messages.histidine,
    unit: 'g',
    code: 512,
    keenoaCode: 'his',
    order: 57,
    parent: 'protein',
  },
  ALANINE: {
    id: 'alanine',
    label: messages.alanine,
    unit: 'g',
    code: 513,
    keenoaCode: 'ala',
    order: 58,
    parent: 'protein',
  },
  ASPARTIC_ACID: {
    id: 'aspartic_acid',
    label: messages.aspartic_acid,
    unit: 'g',
    code: 514,
    keenoaCode: 'asp',
    order: 59,
    parent: 'protein',
  },
  GLUTAMIC_ACID: {
    id: 'glutamic_acid',
    label: messages.glutamic_acid,
    unit: 'g',
    code: 515,
    keenoaCode: 'glu',
    order: 60,
    parent: 'protein',
  },
  GLYCINE: {
    id: 'glycine',
    label: messages.glycine,
    unit: 'g',
    code: 516,
    keenoaCode: 'gly',
    order: 61,
    parent: 'protein',
  },
  PROLINE: {
    id: 'proline',
    label: messages.proline,
    unit: 'g',
    code: 517,
    keenoaCode: 'pro',
    order: 62,
    parent: 'protein',
  },
  SERINE: {
    id: 'serine',
    label: messages.serine,
    unit: 'g',
    code: 518,
    keenoaCode: 'ser',
    order: 63,
    parent: 'protein',
  },
  HYDROXYPROLINE: {
    id: 'hydroxyproline',
    label: messages.hydroxyproline,
    unit: 'g',
    code: 521,
    keenoaCode: 'hyp',
    order: 64,
    parent: 'protein',
  },
  FAT: {
    id: 'fat',
    label: messages.fat,
    unit: 'g',
    code: 204,
    keenoaCode: 'fat',
    order: 2,
    children: [
      'cholesterol',
      'monounsaturated',
      'polyunsaturated',
      'ala',
      'dha',
      'epa',
      'omega3',
      'omega6',
      'saturated',
      'trans',
      'trans_monoenoic',
      'trans_polyenoic',
    ],
  },
  CHOLESTEROL: {
    id: 'cholesterol',
    label: messages.cholesterol,
    unit: 'mg',
    code: 601,
    keenoaCode: 'chol',
    order: 0,
    parent: 'fat',
  },
  MONOUNSATURATED: {
    id: 'monounsaturated',
    label: messages.monounsaturated,
    unit: 'g',
    code: 645,
    keenoaCode: 'mufa',
    order: 1,
    parent: 'fat',
  },
  POLYUNSATURATED: {
    id: 'polyunsaturated',
    label: messages.polyunsaturated,
    unit: 'g',
    code: 646,
    keenoaCode: 'pufa',
    order: 2,
    parent: 'fat',
  },
  ALA: {
    id: 'ala',
    label: messages.ala,
    unit: 'g',
    code: 619,
    keenoaCode: 'p183',
    order: 2.1,
    parent: 'fat',
  },
  DHA: {
    id: 'dha',
    label: messages.dha,
    unit: 'g',
    code: 621,
    keenoaCode: 'p226',
    order: 2.2,
    parent: 'fat',
  },
  EPA: {
    id: 'epa',
    label: messages.epa,
    unit: 'g',
    code: 629,
    keenoaCode: 'p205',
    order: 2.3,
    parent: 'fat',
  },
  OMEGA_3: {
    id: 'omega3',
    label: messages.omega3,
    unit: 'g',
    code: 902,
    keenoaCode: 'omeg3',
    order: 3,
    parent: 'fat',
  },
  OMEGA_6: {
    id: 'omega6',
    label: messages.omega6,
    unit: 'g',
    code: 903,
    keenoaCode: 'omeg6',
    order: 3.1,
    parent: 'fat',
  },
  SATURATED: {
    id: 'saturated',
    label: messages.saturated,
    unit: 'g',
    code: 606,
    keenoaCode: 'tsat',
    order: 4,
    parent: 'fat',
  },
  TRANS: {
    id: 'trans',
    label: messages.transFat,
    unit: 'g',
    code: 605,
    keenoaCode: 'trfa',
    order: 5,
    parent: 'fat',
  },
  TRANS_MONOENOIC: {
    id: 'trans_monoenoic',
    label: messages.trans_monoenoic,
    unit: 'g',
    code: 693,
    keenoaCode: 'trmo',
    order: 6,
    parent: 'fat',
  },
  TRANS_POLYENOIC: {
    id: 'trans_polyenoic',
    label: messages.trans_polyenoic,
    unit: 'g',
    code: 695,
    keenoaCode: 'trpo',
    order: 7,
    parent: 'fat',
  },
  CARBS: {
    id: 'carbs',
    label: messages.carbs,
    unit: 'g',
    code: 205,
    keenoaCode: 'carb',
    order: 3,
    children: [
      'starch',
      'sugars',
      'fibre',
      'sucrose',
      'glucose',
      'fructose',
      'lactose',
      'maltose',
      'galactose',
      'monosaccarides',
      'disaccharides',
    ],
  },
  STARCH: {
    id: 'starch',
    label: messages.starch,
    unit: 'g',
    code: 209,
    keenoaCode: 'star',
    order: 2,
    parent: 'carbs',
  },
  SUCROSE: {
    id: 'sucrose',
    label: messages.sucrose,
    unit: 'g',
    code: 210,
    keenoaCode: 'sucr',
    order: 3,
    parent: 'carbs',
  },
  GLUCOSE: {
    id: 'glucose',
    label: messages.glucose,
    unit: 'g',
    code: 211,
    keenoaCode: 'gluc',
    order: 4,
    parent: 'carbs',
  },
  FRUCTOSE: {
    id: 'fructose',
    label: messages.fructose,
    unit: 'g',
    code: 212,
    keenoaCode: 'fruc',
    order: 5,
    parent: 'carbs',
  },
  LACTOSE: {
    id: 'lactose',
    label: messages.lactose,
    unit: 'g',
    code: 213,
    keenoaCode: 'lact',
    order: 6,
    parent: 'carbs',
  },
  MALTOSE: {
    id: 'maltose',
    label: messages.maltose,
    unit: 'g',
    code: 214,
    keenoaCode: 'malt',
    order: 7,
    parent: 'carbs',
  },
  SUGAR: {
    id: 'sugars',
    label: messages.sugar,
    unit: 'g',
    code: 269,
    keenoaCode: 'tsug',
    order: 0,
    parent: 'carbs',
  },
  GALACTOSE: {
    id: 'galactose',
    label: messages.galactose,
    unit: 'g',
    code: 287,
    keenoaCode: 'gal',
    order: 8,
    parent: 'carbs',
  },
  FIBER: {
    id: 'fibre',
    label: messages.fiber,
    unit: 'g',
    code: 291,
    keenoaCode: 'tdf',
    order: 1,
    parent: 'carbs',
  },
  MONOSACCARIDES: {
    id: 'monosaccarides',
    label: messages.monosaccarides,
    unit: 'g',
    code: 802,
    keenoaCode: 'tmos',
    order: 9,
    parent: 'carbs',
  },
  DISACCHARIDES: {
    id: 'disaccharides',
    label: messages.disaccharides,
    unit: 'g',
    code: 803,
    keenoaCode: 'tdis',
    order: 10,
    parent: 'carbs',
  },
  ALCOHOL: {
    id: 'alcohol',
    label: messages.alcohol,
    unit: 'g',
    code: 221,
    keenoaCode: 'alco',
    order: 4,
  },
  CALCIUM: {
    id: 'calcium',
    label: messages.calcium,
    unit: 'mg',
    code: 301,
    keenoaCode: 'ca',
    order: 5,
    dv: 1100,
  },
  IRON: {
    id: 'iron',
    label: messages.iron,
    unit: 'mg',
    code: 303,
    keenoaCode: 'fe',
    order: 6,
    dv: 14,
  },
  MAGNESIUM: {
    id: 'magnesium',
    label: messages.magnesium,
    unit: 'mg',
    code: 304,
    keenoaCode: 'mg',
    order: 15,
    dv: 250,
  },
  PHOSPHORUS: {
    id: 'phosphorus',
    label: messages.phosphorus,
    unit: 'mg',
    code: 305,
    keenoaCode: 'ppp',
    order: 16,
    dv: 1100,
  },
  POTASSIUM: {
    id: 'potassium',
    label: messages.potassium,
    unit: 'mg',
    code: 306,
    keenoaCode: 'kkk',
    order: 8,
  },
  SODIUM: {
    id: 'sodium',
    label: messages.sodium,
    unit: 'mg',
    code: 307,
    keenoaCode: 'na',
    order: 7,
    dv: 2400,
  },
  ZINC: {
    id: 'zinc',
    label: messages.zinc,
    unit: 'mg',
    code: 309,
    keenoaCode: 'zn',
    order: 17,
    dv: 9,
  },
  COPPER: {
    id: 'copper',
    label: messages.copper,
    unit: 'mg',
    code: 312,
    keenoaCode: 'cu',
    order: 18,
    dv: 2,
  },
  MANGANESE: {
    id: 'manganese',
    label: messages.manganese,
    unit: 'mg',
    code: 315,
    keenoaCode: 'mn',
    order: 19,
    dv: 2,
  },
  SELENIUM: {
    id: 'selenium',
    label: messages.selenium,
    unit: 'µg',
    code: 317,
    keenoaCode: 'se',
    order: 20,
    dv: 50,
  },
  RETINOL: {
    id: 'retinol',
    label: messages.retinol,
    unit: 'µg',
    code: 319,
    keenoaCode: 'rtug',
    order: 21,
    dv: 214.4144144,
  },
  VITAMIN_A: {
    id: 'vit_a',
    label: messages.vit_a,
    code: 320,
    keenoaCode: 'rae',
    unit: 'µg',
    order: 21.1,
    dv: 214.4144144,
  },
  BETA_CAROTENE: {
    id: 'beta_carotene',
    label: messages.beta_carotene,
    unit: 'µg',
    code: 321,
    keenoaCode: 'bcug',
    order: 22,
  },
  ALPHA_CAROTENE: {
    id: 'alpha_carotene',
    label: messages.alpha_carotene,
    unit: 'µg',
    code: 322,
    keenoaCode: 'acug',
    order: 23,
  },
  ALPHA_TOCOPHEROL: {
    id: 'alpha_tocopherol',
    label: messages.alpha_tocopherol,
    unit: 'mg',
    code: 323,
    keenoaCode: 'vite',
    order: 24,
  },
  VITAMIN_D: {
    id: 'vitamin_d',
    label: messages.vitamin_d,
    unit: 'IU',
    code: 324,
    keenoaCode: 'vitd',
    order: 9,
  },
  VITAMIN_D2_ERGOCALCIFEROL: {
    id: 'vitamin_d2_ergocalciferol',
    label: messages.vitamin_d2_ergocalciferol,
    unit: 'µg',
    code: 325,
    keenoaCode: 'vitd2',
    order: 10,
  },
  VITAMIN_D_D2_AND_D3: {
    id: 'vitamin_d_d2_and_d3',
    label: messages.vitamin_d_d2_and_d3,
    unit: 'µg',
    code: 328,
    keenoaCode: 'vitd23',
    order: 11,
  },
  BETA_CRYPTOXANTHIN: {
    id: 'beta_cryptoxanthin',
    label: messages.beta_cryptoxanthin,
    unit: 'µg',
    code: 334,
    keenoaCode: 'crypx',
    order: 25,
  },
  LYCOPENE: {
    id: 'lycopene',
    label: messages.lycopene,
    unit: 'µg',
    code: 337,
    keenoaCode: 'lycpn',
    order: 26,
  },
  LUTEIN_AND_ZEAXANTHIN: {
    id: 'lutein_and_zeaxanthin',
    label: messages.lutein_and_zeaxanthin,
    unit: 'µg',
    code: 338,
    keenoaCode: 'lutzea',
    order: 27,
  },
  BETA_TOCOPHEROL: {
    id: 'beta_tocopherol',
    label: messages.beta_tocopherol,
    unit: 'mg',
    code: 341,
    keenoaCode: 'btmg',
    order: 28,
  },
  GAMMA_TOCOPHEROL: {
    id: 'gamma_tocopherol',
    label: messages.gamma_tocopherol,
    unit: 'mg',
    code: 342,
    keenoaCode: 'gtmg',
    order: 29,
  },
  DELTA_TOCOPHEROL: {
    id: 'delta_tocopherol',
    label: messages.delta_tocopherol,
    unit: 'mg',
    code: 343,
    keenoaCode: 'dtmg',
    order: 30,
  },
  VITAMIN_C: {
    id: 'vitamin_c',
    label: messages.vitamin_c,
    unit: 'mg',
    code: 401,
    keenoaCode: 'vitc',
    order: 31,
    dv: 60,
  },
  THIAMIN: {
    id: 'thiamin',
    label: messages.thiamin,
    unit: 'mg',
    code: 404,
    keenoaCode: 'thia',
    order: 32,
    dv: 1.3,
  },
  RIBOFLAVIN: {
    id: 'riboflavin',
    label: messages.riboflavin,
    unit: 'mg',
    code: 405,
    keenoaCode: 'ribo',
    order: 33,
    dv: 1.6,
  },
  NIACIN_PREFORMED: {
    id: 'niacin',
    label: messages.niacin_preformed,
    unit: 'mg',
    code: 406,
    keenoaCode: 'nmg',
    order: 34,
  },
  NIACIN: {
    id: 'total_niacin_equivalent',
    label: messages.total_niacin_equivalent,
    unit: 'NE',
    code: 409,
    keenoaCode: 'nne',
    order: 35,
    dv: 23,
  },
  PANTOTHENIC_ACID: {
    id: 'pantothenic_acid',
    label: messages.pantothenic_acid,
    unit: 'mg',
    code: 410,
    keenoaCode: 'pant',
    order: 36,
    dv: 7,
  },
  VITAMIN_B_6: {
    id: 'vitamin_b_6',
    label: messages.vitamin_b_6,
    unit: 'mg',
    code: 415,
    keenoaCode: 'b6',
    order: 37,
  },
  BIOTIN: {
    id: 'biotin',
    label: messages.biotin,
    unit: 'µg',
    code: 416,
    keenoaCode: 'biot',
    order: 38,
    dv: 30,
  },
  TOTAL_FOLACIN: {
    id: 'total_folacin',
    label: messages.total_folacin,
    unit: 'µg',
    code: 417,
    keenoaCode: 'fola',
    order: 39,
    dv: 220,
  },
  VITAMIN_B_12: {
    id: 'vitamin_b_12',
    label: messages.vitamin_b_12,
    unit: 'µg',
    code: 418,
    keenoaCode: 'b12',
    order: 12,
  },
  CHOLINE__TOTAL: {
    id: 'choline__total',
    label: messages.choline__total,
    unit: 'mg',
    code: 421,
    keenoaCode: 'choln',
    order: 40,
  },
  VITAMIN_K: {
    id: 'vitamin_k',
    label: messages.vitamin_k,
    unit: 'µg',
    code: 430,
    keenoaCode: 'vitk',
    order: 41,
    dv: 80,
  },
  FOLIC_ACID: {
    id: 'folic_acid',
    label: messages.folic_acid,
    unit: 'µg',
    code: 431,
    keenoaCode: 'foac',
    order: 13,
  },
  NATURALLY_OCCURRING_FOLATE: {
    id: 'naturally_occurring_folate',
    label: messages.naturally_occurring_folate,
    unit: 'µg',
    code: 432,
    keenoaCode: 'foln',
    order: 42,
  },
  DIETARY_FOLATE_EQUIVALENTS: {
    id: 'dietary_folate_equivalents',
    label: messages.dietary_folate_equivalents,
    unit: 'µg',
    code: 435,
    keenoaCode: 'dfe',
    order: 14,
  },
  BETAINE: {
    id: 'betaine',
    label: messages.betaine,
    unit: 'mg',
    code: 454,
    keenoaCode: 'betn',
    order: 43,
  },
  ALPHA_TOCOPHEROL__ADDED: {
    id: 'alpha_tocopherol__added',
    label: messages.alpha_tocopherol__added,
    unit: 'mg',
    code: 573,
    keenoaCode: 'atmga',
    order: 44,
  },
  VITAMIN_B12__ADDED: {
    id: 'vitamin_b12__added',
    label: messages.vitamin_b12__added,
    unit: 'µg',
    code: 578,
    keenoaCode: 'b12a',
    order: 45,
    dv: 2,
  },
  ASPARTAME: {
    id: 'aspartame',
    label: messages.aspartame,
    unit: 'mg',
    code: 550,
    keenoaCode: 'aspa',
    order: 65,
  },
  WATER: {
    id: 'water',
    label: messages.water,
    unit: 'g',
    code: 255,
    keenoaCode: 'h20',
    order: 1.3,
  },
  // CUSTOM NUTRIENTS
  NS_STEP: {
    id: 'ns_step',
    label: messages.ns_step,
    unit: '',
    code: 10001,
    order: 1.2,
  },
  NS_GREEN_STEP: {
    id: 'ns_green_step',
    label: messages.ns_green_step,
    unit: '',
    code: 10003,
    order: 1.3,
  },
};

/**
 * Build a profile :
 * ear: Estimated Average Requirement
 * rda: Recommended Dietary Allowance
 * ai: Adequate Intake
 * ul: Tolerable Upper Intake Level
 */
const _buildP = (ear, rda, ai, ul) => ({
  ear,
  rda,
  ai,
  ul,
});

/**
 * Build a recommendation :
 * limit: the maximum age for which the limit applies (this number is inclusive)
 * profile: see _buildProfile
 */
const _buildR = (min, max, profile) => ({
  min,
  max,
  profile,
});

export const INFANTS = 'infants';
export const CHILDREN = 'children';
export const MALES = 'males';
export const FEMALES = 'females';
export const PREGNANCY = 'pregnancy';
export const LACTATION = 'lactation';

const _ = undefined;
export const DRI_VALUES = {
  [nutrimentsConstants.VITAMIN_A.id]: {
    unit: 'ug (RAE)',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 400, 600)),
      _buildR(7 / 12, 1, _buildP(_, _, 500, 600)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(210, 300, _, 600)),
      _buildR(4, 8, _buildP(275, 400, _, 900)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(445, 600, _, 1700)),
      _buildR(14, 18, _buildP(630, 900, _, 2800)),
      _buildR(19, 30, _buildP(625, 900, _, 3000)),
      _buildR(31, 50, _buildP(625, 900, _, 3000)),
      _buildR(51, 70, _buildP(625, 900, _, 3000)),
      _buildR(71, _, _buildP(625, 900, _, 3000)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(420, 600, _, 1700)),
      _buildR(14, 18, _buildP(485, 700, _, 2800)),
      _buildR(19, 30, _buildP(500, 700, _, 3000)),
      _buildR(31, 50, _buildP(500, 700, _, 3000)),
      _buildR(51, 70, _buildP(500, 700, _, 3000)),
      _buildR(71, _, _buildP(500, 700, _, 3000)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(530, 750, _, 2800)),
      _buildR(19, 30, _buildP(550, 770, _, 3000)),
      _buildR(31, 50, _buildP(550, 770, _, 3000)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(885, 1200, _, 2800)),
      _buildR(19, 30, _buildP(900, 1300, _, 3000)),
      _buildR(31, 50, _buildP(900, 1300, _, 3000)),
    ],
  },
  [nutrimentsConstants.VITAMIN_D.id]: {
    unit: 'IU',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 400, 1000)),
      _buildR(7 / 12, 1, _buildP(_, _, 400, 1500)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(400, 600, _, 2500)),
      _buildR(4, 8, _buildP(400, 600, _, 3000)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(400, 600, _, 4000)),
      _buildR(14, 18, _buildP(400, 600, _, 4000)),
      _buildR(19, 30, _buildP(400, 600, _, 4000)),
      _buildR(31, 50, _buildP(400, 600, _, 4000)),
      _buildR(51, 70, _buildP(400, 600, _, 4000)),
      _buildR(71, _, _buildP(400, 800, _, 4000)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(400, 600, _, 4000)),
      _buildR(14, 18, _buildP(400, 600, _, 4000)),
      _buildR(19, 30, _buildP(400, 600, _, 4000)),
      _buildR(31, 50, _buildP(400, 600, _, 4000)),
      _buildR(51, 70, _buildP(400, 600, _, 4000)),
      _buildR(71, _, _buildP(400, 800, _, 4000)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(400, 600, _, 4000)),
      _buildR(19, 30, _buildP(400, 600, _, 4000)),
      _buildR(31, 50, _buildP(400, 600, _, 4000)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(400, 600, _, 4000)),
      _buildR(19, 30, _buildP(400, 600, _, 4000)),
      _buildR(31, 50, _buildP(400, 600, _, 4000)),
    ],
  },
  [nutrimentsConstants.ALPHA_TOCOPHEROL.id]: {
    unit: 'mg',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 4, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 5, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(5, 6, _, 200)),
      _buildR(4, 8, _buildP(6, 7, _, 300)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(9, 11, _, 600)),
      _buildR(14, 18, _buildP(12, 15, _, 800)),
      _buildR(19, 30, _buildP(12, 15, _, 1000)),
      _buildR(31, 50, _buildP(12, 15, _, 1000)),
      _buildR(51, 70, _buildP(12, 15, _, 1000)),
      _buildR(71, _, _buildP(12, 15, _, 1000)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(9, 11, _, 600)),
      _buildR(14, 18, _buildP(12, 15, _, 800)),
      _buildR(19, 30, _buildP(12, 15, _, 1000)),
      _buildR(31, 50, _buildP(12, 15, _, 1000)),
      _buildR(51, 70, _buildP(12, 15, _, 1000)),
      _buildR(71, _, _buildP(12, 15, _, 1000)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(12, 15, _, 800)),
      _buildR(19, 30, _buildP(12, 15, _, 1000)),
      _buildR(31, 50, _buildP(12, 15, _, 1000)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(16, 19, _, 800)),
      _buildR(19, 30, _buildP(16, 19, _, 1000)),
      _buildR(31, 50, _buildP(16, 19, _, 1000)),
    ],
  },
  [nutrimentsConstants.VITAMIN_K.id]: {
    unit: 'ug',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 2, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 2.5, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(_, _, 30, _)),
      _buildR(4, 8, _buildP(_, _, 55, _)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(_, _, 60, _)),
      _buildR(14, 18, _buildP(_, _, 75, _)),
      _buildR(19, 30, _buildP(_, _, 120, _)),
      _buildR(31, 50, _buildP(_, _, 120, _)),
      _buildR(51, 70, _buildP(_, _, 120, _)),
      _buildR(71, _, _buildP(_, _, 120, _)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(_, _, 60, _)),
      _buildR(14, 18, _buildP(_, _, 75, _)),
      _buildR(19, 30, _buildP(_, _, 90, _)),
      _buildR(31, 50, _buildP(_, _, 90, _)),
      _buildR(51, 70, _buildP(_, _, 90, _)),
      _buildR(71, _, _buildP(_, _, 90, _)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(_, _, 75, _)),
      _buildR(19, 30, _buildP(_, _, 90, _)),
      _buildR(31, 50, _buildP(_, _, 90, _)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(_, _, 75, _)),
      _buildR(19, 30, _buildP(_, _, 90, _)),
      _buildR(31, 50, _buildP(_, _, 90, _)),
    ],
  },
  [nutrimentsConstants.VITAMIN_C.id]: {
    unit: 'mg',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 40, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 50, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(13, 15, _, 400)),
      _buildR(4, 8, _buildP(22, 25, _, 650)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(39, 45, _, 1200)),
      _buildR(14, 18, _buildP(63, 75, _, 1800)),
      _buildR(19, 30, _buildP(75, 90, _, 2000)),
      _buildR(31, 50, _buildP(75, 90, _, 2000)),
      _buildR(51, 70, _buildP(75, 90, _, 2000)),
      _buildR(71, _, _buildP(75, 90, _, 2000)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(39, 45, _, 1200)),
      _buildR(14, 18, _buildP(56, 65, _, 1800)),
      _buildR(19, 30, _buildP(60, 75, _, 2000)),
      _buildR(31, 50, _buildP(60, 75, _, 2000)),
      _buildR(51, 70, _buildP(60, 75, _, 2000)),
      _buildR(71, _, _buildP(60, 75, _, 2000)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(66, 80, _, 1800)),
      _buildR(19, 30, _buildP(70, 85, _, 2000)),
      _buildR(31, 50, _buildP(70, 85, _, 2000)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(96, 115, _, 1800)),
      _buildR(19, 30, _buildP(100, 120, _, 2000)),
      _buildR(31, 50, _buildP(100, 120, _, 2000)),
    ],
  },
  [nutrimentsConstants.THIAMIN.id]: {
    unit: 'mg',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 0.2, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 0.3, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(0.4, 0.5, _, _)),
      _buildR(4, 8, _buildP(0.5, 0.6, _, _)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(0.7, 0.9, _, _)),
      _buildR(14, 18, _buildP(1, 1.2, _, _)),
      _buildR(19, 30, _buildP(1, 1.2, _, _)),
      _buildR(31, 50, _buildP(1, 1.2, _, _)),
      _buildR(51, 70, _buildP(1, 1.2, _, _)),
      _buildR(71, _, _buildP(1, 1.2, _, _)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(0.7, 0.9, _, _)),
      _buildR(14, 18, _buildP(0.9, 1, _, _)),
      _buildR(19, 30, _buildP(0.9, 1.1, _, _)),
      _buildR(31, 50, _buildP(0.9, 1.1, _, _)),
      _buildR(51, 70, _buildP(0.9, 1.1, _, _)),
      _buildR(71, _, _buildP(0.9, 1.1, _, _)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(1.2, 1.4, _, _)),
      _buildR(19, 30, _buildP(1.2, 1.4, _, _)),
      _buildR(31, 50, _buildP(1.2, 1.4, _, _)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(1.2, 1.4, _, _)),
      _buildR(19, 30, _buildP(1.2, 1.4, _, _)),
      _buildR(31, 50, _buildP(1.2, 1.4, _, _)),
    ],
  },
  [nutrimentsConstants.RIBOFLAVIN.id]: {
    unit: 'mg',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 0.3, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 0.4, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(0.4, 0.5, _, _)),
      _buildR(4, 8, _buildP(0.5, 0.6, _, _)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(0.8, 0.9, _, _)),
      _buildR(14, 18, _buildP(1.1, 1.3, _, _)),
      _buildR(19, 30, _buildP(1.1, 1.3, _, _)),
      _buildR(31, 50, _buildP(1.1, 1.3, _, _)),
      _buildR(51, 70, _buildP(1.1, 1.3, _, _)),
      _buildR(71, _, _buildP(1.1, 1.3, _, _)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(0.8, 0.9, _, _)),
      _buildR(14, 18, _buildP(0.9, 1, _, _)),
      _buildR(19, 30, _buildP(0.9, 1.1, _, _)),
      _buildR(31, 50, _buildP(0.9, 1.1, _, _)),
      _buildR(51, 70, _buildP(0.9, 1.1, _, _)),
      _buildR(71, _, _buildP(0.9, 1.1, _, _)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(1.2, 1.4, _, _)),
      _buildR(19, 30, _buildP(1.2, 1.4, _, _)),
      _buildR(31, 50, _buildP(1.2, 1.4, _, _)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(1.3, 1.6, _, _)),
      _buildR(19, 30, _buildP(1.3, 1.6, _, _)),
      _buildR(31, 50, _buildP(1.3, 1.6, _, _)),
    ],
  },
  [nutrimentsConstants.NIACIN.id]: {
    unit: 'NE',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 2, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 4, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(5, 6, _, 10)),
      _buildR(4, 8, _buildP(6, 8, _, 15)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(9, 12, _, 20)),
      _buildR(14, 18, _buildP(12, 16, _, 30)),
      _buildR(19, 30, _buildP(12, 16, _, 35)),
      _buildR(31, 50, _buildP(12, 16, _, 35)),
      _buildR(51, 70, _buildP(12, 16, _, 35)),
      _buildR(71, _, _buildP(12, 16, _, 35)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(9, 12, _, 20)),
      _buildR(14, 18, _buildP(11, 14, _, 30)),
      _buildR(19, 30, _buildP(11, 14, _, 35)),
      _buildR(31, 50, _buildP(11, 14, _, 35)),
      _buildR(51, 70, _buildP(11, 14, _, 35)),
      _buildR(71, _, _buildP(11, 14, _, 35)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(14, 18, _, 30)),
      _buildR(19, 30, _buildP(14, 18, _, 35)),
      _buildR(31, 50, _buildP(14, 18, _, 35)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(13, 17, _, 30)),
      _buildR(19, 30, _buildP(13, 17, _, 35)),
      _buildR(31, 50, _buildP(13, 17, _, 35)),
    ],
  },
  [nutrimentsConstants.VITAMIN_B_6.id]: {
    unit: 'mg',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 0.1, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 0.3, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(0.4, 0.5, _, 30)),
      _buildR(4, 8, _buildP(0.5, 0.6, _, 40)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(0.8, 1, _, 60)),
      _buildR(14, 18, _buildP(1.1, 1.3, _, 80)),
      _buildR(19, 30, _buildP(1.1, 1.3, _, 100)),
      _buildR(31, 50, _buildP(1.1, 1.3, _, 100)),
      _buildR(51, 70, _buildP(1.4, 1.7, _, 100)),
      _buildR(71, _, _buildP(1.4, 1.7, _, 100)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(0.8, 1.0, _, 60)),
      _buildR(14, 18, _buildP(1, 1.2, _, 80)),
      _buildR(19, 30, _buildP(1.1, 1.3, _, 100)),
      _buildR(31, 50, _buildP(1.1, 1.3, _, 100)),
      _buildR(51, 70, _buildP(1.3, 1.5, _, 100)),
      _buildR(71, _, _buildP(1.3, 1.5, _, 100)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(1.6, 1.9, _, 80)),
      _buildR(19, 30, _buildP(1.6, 1.9, _, 100)),
      _buildR(31, 50, _buildP(1.6, 1.9, _, 100)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(1.7, 2, _, 80)),
      _buildR(19, 30, _buildP(1.7, 2, _, 100)),
      _buildR(31, 50, _buildP(1.7, 2, _, 100)),
    ],
  },
  [nutrimentsConstants.DIETARY_FOLATE_EQUIVALENTS.id]: {
    unit: 'ug',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 65, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 80, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(120, 150, _, 300)),
      _buildR(4, 8, _buildP(160, 200, _, 400)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(250, 300, _, 600)),
      _buildR(14, 18, _buildP(330, 400, _, 800)),
      _buildR(19, 30, _buildP(320, 400, _, 1000)),
      _buildR(31, 50, _buildP(320, 400, _, 1000)),
      _buildR(51, 70, _buildP(320, 400, _, 1000)),
      _buildR(71, _, _buildP(320, 400, _, 1000)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(250, 300, _, 600)),
      _buildR(14, 18, _buildP(330, 400, _, 800)),
      _buildR(19, 30, _buildP(320, 400, _, 1000)),
      _buildR(31, 50, _buildP(320, 400, _, 1000)),
      _buildR(51, 70, _buildP(320, 400, _, 1000)),
      _buildR(71, _, _buildP(320, 400, _, 1000)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(520, 600, _, 800)),
      _buildR(19, 30, _buildP(520, 600, _, 1000)),
      _buildR(31, 50, _buildP(520, 600, _, 1000)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(540, 500, _, 800)),
      _buildR(19, 30, _buildP(540, 500, _, 1000)),
      _buildR(31, 50, _buildP(540, 500, _, 1000)),
    ],
  },
  [nutrimentsConstants.VITAMIN_B_12.id]: {
    unit: 'ug',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 0.4, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 0.5, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(0.7, 0.9, _, _)),
      _buildR(4, 8, _buildP(1, 1.2, _, _)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(1.5, 1.8, _, _)),
      _buildR(14, 18, _buildP(2.0, 2.4, _, _)),
      _buildR(19, 30, _buildP(2.0, 2.4, _, _)),
      _buildR(31, 50, _buildP(2.0, 2.4, _, _)),
      _buildR(51, 70, _buildP(2.0, 2.4, _, _)),
      _buildR(71, _, _buildP(2.0, 2.4, _, _)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(1.5, 1.8, _, _)),
      _buildR(14, 18, _buildP(2.0, 2.4, _, _)),
      _buildR(19, 30, _buildP(2.0, 2.4, _, _)),
      _buildR(31, 50, _buildP(2.0, 2.4, _, _)),
      _buildR(51, 70, _buildP(2.0, 2.4, _, _)),
      _buildR(71, _, _buildP(2.0, 2.4, _, _)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(2.2, 2.6, _, _)),
      _buildR(19, 30, _buildP(2.2, 2.6, _, _)),
      _buildR(31, 50, _buildP(2.2, 2.6, _, _)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(2.4, 2.8, _, _)),
      _buildR(19, 30, _buildP(2.4, 2.8, _, _)),
      _buildR(31, 50, _buildP(2.4, 2.8, _, _)),
    ],
  },
  [nutrimentsConstants.PANTOTHENIC_ACID.id]: {
    unit: 'mg',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 1.7, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 1.8, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(_, _, 2, _)),
      _buildR(4, 8, _buildP(_, _, 3, _)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(_, _, 4, _)),
      _buildR(14, 18, _buildP(_, _, 5, _)),
      _buildR(19, 30, _buildP(_, _, 5, _)),
      _buildR(31, 50, _buildP(_, _, 5, _)),
      _buildR(51, 70, _buildP(_, _, 5, _)),
      _buildR(71, _, _buildP(_, _, 5, _)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(_, _, 4, _)),
      _buildR(14, 18, _buildP(_, _, 5, _)),
      _buildR(19, 30, _buildP(_, _, 5, _)),
      _buildR(31, 50, _buildP(_, _, 5, _)),
      _buildR(51, 70, _buildP(_, _, 5, _)),
      _buildR(71, _, _buildP(_, _, 5, _)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(_, _, 6, _)),
      _buildR(19, 30, _buildP(_, _, 6, _)),
      _buildR(31, 50, _buildP(_, _, 6, _)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(_, _, 7, _)),
      _buildR(19, 30, _buildP(_, _, 7, _)),
      _buildR(31, 50, _buildP(_, _, 7, _)),
    ],
  },
  [nutrimentsConstants.BIOTIN.id]: {
    unit: 'ug',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 5, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 6, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(_, _, 8, _)),
      _buildR(4, 8, _buildP(_, _, 12, _)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(_, _, 20, _)),
      _buildR(14, 18, _buildP(_, _, 25, _)),
      _buildR(19, 30, _buildP(_, _, 30, _)),
      _buildR(31, 50, _buildP(_, _, 30, _)),
      _buildR(51, 70, _buildP(_, _, 30, _)),
      _buildR(71, _, _buildP(_, _, 30, _)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(_, _, 20, _)),
      _buildR(14, 18, _buildP(_, _, 25, _)),
      _buildR(19, 30, _buildP(_, _, 30, _)),
      _buildR(31, 50, _buildP(_, _, 30, _)),
      _buildR(51, 70, _buildP(_, _, 30, _)),
      _buildR(71, _, _buildP(_, _, 30, _)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(_, _, 30, _)),
      _buildR(19, 30, _buildP(_, _, 30, _)),
      _buildR(31, 50, _buildP(_, _, 30, _)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(_, _, 35, _)),
      _buildR(19, 30, _buildP(_, _, 35, _)),
      _buildR(31, 50, _buildP(_, _, 35, _)),
    ],
  },
  [nutrimentsConstants.CHOLINE__TOTAL.id]: {
    unit: 'mg',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 125, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 150, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(_, _, 200, 1000)),
      _buildR(4, 8, _buildP(_, _, 250, 1000)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(_, _, 375, 2000)),
      _buildR(14, 18, _buildP(_, _, 550, 3000)),
      _buildR(19, 30, _buildP(_, _, 550, 3500)),
      _buildR(31, 50, _buildP(_, _, 550, 3500)),
      _buildR(51, 70, _buildP(_, _, 550, 3500)),
      _buildR(71, _, _buildP(_, _, 550, 3500)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(_, _, 375, 2000)),
      _buildR(14, 18, _buildP(_, _, 400, 3000)),
      _buildR(19, 30, _buildP(_, _, 425, 3500)),
      _buildR(31, 50, _buildP(_, _, 425, 3500)),
      _buildR(51, 70, _buildP(_, _, 425, 3500)),
      _buildR(71, _, _buildP(_, _, 425, 3500)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(_, _, 450, 3000)),
      _buildR(19, 30, _buildP(_, _, 450, 3500)),
      _buildR(31, 50, _buildP(_, _, 450, 3500)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(_, _, 35, 3000)),
      _buildR(19, 30, _buildP(_, _, 35, 3500)),
      _buildR(31, 50, _buildP(_, _, 35, 3500)),
    ],
  },
  [nutrimentsConstants.CALCIUM.id]: {
    unit: 'ml',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 200, 1000)),
      _buildR(7 / 12, 1, _buildP(_, _, 260, 1500)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(500, 700, _, 2500)),
      _buildR(4, 8, _buildP(800, 1000, _, 2500)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(1100, 1300, _, 3000)),
      _buildR(14, 18, _buildP(1100, 1300, _, 3000)),
      _buildR(19, 30, _buildP(800, 1000, _, 2500)),
      _buildR(31, 50, _buildP(800, 1000, _, 2500)),
      _buildR(51, 70, _buildP(800, 1000, _, 2000)),
      _buildR(71, _, _buildP(1000, 1200, _, 2000)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(1100, 1300, _, 3000)),
      _buildR(14, 18, _buildP(1100, 1300, _, 3000)),
      _buildR(19, 30, _buildP(800, 1000, _, 2500)),
      _buildR(31, 50, _buildP(800, 1000, _, 2500)),
      _buildR(51, 70, _buildP(1000, 1200, _, 2000)),
      _buildR(71, _, _buildP(1000, 1200, _, 2000)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(1100, 1300, _, 3000)),
      _buildR(19, 30, _buildP(800, 1000, _, 2500)),
      _buildR(31, 50, _buildP(800, 1000, _, 2500)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(1100, 1300, _, 3000)),
      _buildR(19, 30, _buildP(800, 1000, _, 2500)),
      _buildR(31, 50, _buildP(800, 1000, _, 2500)),
    ],
  },
  [nutrimentsConstants.COPPER.id]: {
    unit: 'mg',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 0.2, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 0.22, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(0.26, 0.34, _, 1)),
      _buildR(4, 8, _buildP(0.34, 0.44, _, 3)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(0.54, 0.7, _, 5)),
      _buildR(14, 18, _buildP(0.686, 0.89, _, 8)),
      _buildR(19, 30, _buildP(0.7, 0.9, _, 10)),
      _buildR(31, 50, _buildP(0.7, 0.9, _, 10)),
      _buildR(51, 70, _buildP(0.7, 0.9, _, 10)),
      _buildR(71, _, _buildP(0.7, 0.9, _, 10)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(0.54, 0.7, _, 5)),
      _buildR(14, 18, _buildP(0.686, 0.89, _, 8)),
      _buildR(19, 30, _buildP(0.7, 0.9, _, 10)),
      _buildR(31, 50, _buildP(0.7, 0.9, _, 10)),
      _buildR(51, 70, _buildP(0.7, 0.9, _, 10)),
      _buildR(71, _, _buildP(0.7, 0.9, _, 10)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(0.785, 1, _, 8)),
      _buildR(19, 30, _buildP(0.8, 1, _, 10)),
      _buildR(31, 50, _buildP(0.8, 1, _, 10)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(0.985, 1.3, _, 8)),
      _buildR(19, 30, _buildP(1, 1.3, _, 10)),
      _buildR(31, 50, _buildP(1, 1.3, _, 10)),
    ],
  },
  [nutrimentsConstants.IRON.id]: {
    unit: 'mg',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 0.27, 40)),
      _buildR(7 / 12, 1, _buildP(_, 6.9, 11, 40)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(3.0, 7, _, 40)),
      _buildR(4, 8, _buildP(4.1, 10, _, 40)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(5.9, 8, _, 40)),
      _buildR(14, 18, _buildP(7.7, 11, _, 45)),
      _buildR(19, 30, _buildP(6, 8, _, 45)),
      _buildR(31, 50, _buildP(6, 8, _, 45)),
      _buildR(51, 70, _buildP(6, 8, _, 45)),
      _buildR(71, _, _buildP(6, 8, _, 45)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(5.7, 8, _, 40)),
      _buildR(14, 18, _buildP(7.9, 15, _, 45)),
      _buildR(19, 30, _buildP(8.1, 18, _, 45)),
      _buildR(31, 50, _buildP(8.1, 18, _, 45)),
      _buildR(51, 70, _buildP(5, 8, _, 45)),
      _buildR(71, _, _buildP(5, 8, _, 45)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(23, 27, _, 45)),
      _buildR(19, 30, _buildP(22, 27, _, 45)),
      _buildR(31, 50, _buildP(22, 27, _, 45)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(7, 10, _, 45)),
      _buildR(19, 30, _buildP(6.5, 9, _, 45)),
      _buildR(31, 50, _buildP(6.5, 9, _, 45)),
    ],
  },
  [nutrimentsConstants.MAGNESIUM.id]: {
    unit: 'mg',
    // NOTE: The UL is only for supplements.
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 30, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 75, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(65, 80, _, 65)),
      _buildR(4, 8, _buildP(110, 10, _, 110)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(200, 240, _, 300)),
      _buildR(14, 18, _buildP(340, 410, _, 350)),
      _buildR(19, 30, _buildP(330, 400, _, 350)),
      _buildR(31, 50, _buildP(350, 420, _, 350)),
      _buildR(51, 70, _buildP(350, 420, _, 350)),
      _buildR(71, _, _buildP(350, 420, _, 350)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(200, 240, _, 300)),
      _buildR(14, 18, _buildP(300, 360, _, 350)),
      _buildR(19, 30, _buildP(255, 310, _, 350)),
      _buildR(31, 50, _buildP(265, 320, _, 350)),
      _buildR(51, 70, _buildP(265, 320, _, 350)),
      _buildR(71, _, _buildP(265, 320, _, 350)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(335, 400, _, 350)),
      _buildR(19, 30, _buildP(290, 350, _, 350)),
      _buildR(31, 50, _buildP(300, 360, _, 350)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(300, 360, _, 350)),
      _buildR(19, 30, _buildP(255, 310, _, 350)),
      _buildR(31, 50, _buildP(265, 320, _, 350)),
    ],
  },
  [nutrimentsConstants.MANGANESE.id]: {
    unit: 'mg',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 0.003, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 0.6, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(_, _, 1.2, 2)),
      _buildR(4, 8, _buildP(_, _, 1.5, 3)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(_, _, 1.9, 6)),
      _buildR(14, 18, _buildP(_, _, 2.2, 9)),
      _buildR(19, 30, _buildP(_, _, 2.3, 11)),
      _buildR(31, 50, _buildP(_, _, 2.3, 11)),
      _buildR(51, 70, _buildP(_, _, 2.3, 11)),
      _buildR(71, _, _buildP(_, _, 2.3, 11)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(_, _, 1.6, 6)),
      _buildR(14, 18, _buildP(_, _, 1.6, 9)),
      _buildR(19, 30, _buildP(_, _, 1.8, 11)),
      _buildR(31, 50, _buildP(_, _, 1.8, 11)),
      _buildR(51, 70, _buildP(_, _, 1.8, 11)),
      _buildR(71, _, _buildP(_, _, 1.8, 11)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(_, _, 2, 9)),
      _buildR(19, 30, _buildP(_, _, 2, 11)),
      _buildR(31, 50, _buildP(_, _, 2, 11)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(_, _, 2.6, 9)),
      _buildR(19, 30, _buildP(_, _, 2.6, 11)),
      _buildR(31, 50, _buildP(_, _, 2.6, 11)),
    ],
  },
  [nutrimentsConstants.PHOSPHORUS.id]: {
    unit: 'mg',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 100, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 275, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(380, 460, _, 3000)),
      _buildR(4, 8, _buildP(405, 500, _, 3000)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(1055, 1250, _, 4000)),
      _buildR(14, 18, _buildP(1055, 1250, _, 4000)),
      _buildR(19, 30, _buildP(580, 700, _, 4000)),
      _buildR(31, 50, _buildP(580, 700, _, 4000)),
      _buildR(51, 70, _buildP(580, 700, _, 4000)),
      _buildR(71, _, _buildP(580, 700, _, 3000)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(1055, 1250, _, 4000)),
      _buildR(14, 18, _buildP(1055, 1250, _, 4000)),
      _buildR(19, 30, _buildP(580, 700, _, 4000)),
      _buildR(31, 50, _buildP(580, 700, _, 4000)),
      _buildR(51, 70, _buildP(580, 700, _, 4000)),
      _buildR(71, _, _buildP(580, 700, _, 3000)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(1055, 1250, _, 3500)),
      _buildR(19, 30, _buildP(380, 700, _, 3500)),
      _buildR(31, 50, _buildP(380, 700, _, 3500)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(1055, 1250, _, 4000)),
      _buildR(19, 30, _buildP(380, 700, _, 4000)),
      _buildR(31, 50, _buildP(380, 700, _, 4000)),
    ],
  },
  [nutrimentsConstants.SELENIUM.id]: {
    unit: 'µg',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 15, 45)),
      _buildR(7 / 12, 1, _buildP(_, _, 20, 60)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(17, 20, _, 90)),
      _buildR(4, 8, _buildP(23, 30, _, 150)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(35, 40, _, 280)),
      _buildR(14, 18, _buildP(45, 55, _, 400)),
      _buildR(19, 30, _buildP(45, 55, _, 400)),
      _buildR(31, 50, _buildP(45, 55, _, 400)),
      _buildR(51, 70, _buildP(45, 55, _, 400)),
      _buildR(71, _, _buildP(45, 55, _, 400)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(35, 40, _, 280)),
      _buildR(14, 18, _buildP(45, 55, _, 400)),
      _buildR(19, 30, _buildP(45, 55, _, 400)),
      _buildR(31, 50, _buildP(45, 55, _, 400)),
      _buildR(51, 70, _buildP(45, 55, _, 400)),
      _buildR(71, _, _buildP(45, 55, _, 400)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(49, 60, _, 400)),
      _buildR(19, 30, _buildP(49, 60, _, 400)),
      _buildR(31, 50, _buildP(49, 60, _, 400)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(59, 70, _, 400)),
      _buildR(19, 30, _buildP(59, 70, _, 400)),
      _buildR(31, 50, _buildP(59, 70, _, 400)),
    ],
  },
  [nutrimentsConstants.ZINC.id]: {
    unit: 'mg',
    // NOTE: The requirement for zinc may be as much as 50 percent greater for vegetarians, particularly for strict vegetarians whose major food staples are grains and legumes, due to the lower bioavailability of zinc from a vegetarian diet.
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 2, 4)),
      _buildR(7 / 12, 1, _buildP(2.5, 3, _, 5)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(2.5, 3, _, 7)),
      _buildR(4, 8, _buildP(4.0, 5, _, 12)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(7.0, 8, _, 23)),
      _buildR(14, 18, _buildP(8.5, 11, _, 34)),
      _buildR(19, 30, _buildP(9.4, 11, _, 40)),
      _buildR(31, 50, _buildP(9.4, 11, _, 40)),
      _buildR(51, 70, _buildP(9.4, 11, _, 40)),
      _buildR(71, _, _buildP(9.4, 11, _, 40)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(7.0, 8, _, 22)),
      _buildR(14, 18, _buildP(7.3, 9, _, 34)),
      _buildR(19, 30, _buildP(6.8, 8, _, 40)),
      _buildR(31, 50, _buildP(6.8, 8, _, 40)),
      _buildR(51, 70, _buildP(6.8, 8, _, 40)),
      _buildR(71, _, _buildP(6.8, 8, _, 40)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(10.5, 12, _, 34)),
      _buildR(19, 30, _buildP(9.5, 11, _, 40)),
      _buildR(31, 50, _buildP(9.5, 11, _, 40)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(10.9, 13, _, 34)),
      _buildR(19, 30, _buildP(10.4, 12, _, 40)),
      _buildR(31, 50, _buildP(10.4, 12, _, 40)),
    ],
  },
  [nutrimentsConstants.POTASSIUM.id]: {
    unit: 'mg',
    // NOTE: The beneficial effects of potassium appear to be mainly from the forms of potassium found naturally in foods such as fruits and vegetables. Supplemental potassium should only be provided under medical supervision.
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 400, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 700, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(_, _, 3000, _)),
      _buildR(4, 8, _buildP(_, _, 3800, _)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(_, _, 4500, _)),
      _buildR(14, 18, _buildP(_, _, 4700, _)),
      _buildR(19, 30, _buildP(_, _, 4700, _)),
      _buildR(31, 50, _buildP(_, _, 4700, _)),
      _buildR(51, 70, _buildP(_, _, 4700, _)),
      _buildR(71, _, _buildP(_, _, 4700, _)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(_, _, 4500, _)),
      _buildR(14, 18, _buildP(_, _, 4700, _)),
      _buildR(19, 30, _buildP(_, _, 4700, _)),
      _buildR(31, 50, _buildP(_, _, 4700, _)),
      _buildR(51, 70, _buildP(_, _, 4700, _)),
      _buildR(71, _, _buildP(_, _, 4700, _)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(_, _, 4700, _)),
      _buildR(19, 30, _buildP(_, _, 4700, _)),
      _buildR(31, 50, _buildP(_, _, 4700, _)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(_, _, 5100, _)),
      _buildR(19, 30, _buildP(_, _, 5100, _)),
      _buildR(31, 50, _buildP(_, _, 5100, _)),
    ],
  },
  [nutrimentsConstants.SODIUM.id]: {
    unit: 'mg',
    // NOTE: Grams of sodium x 2.53 = grams of salt.
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 120, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 370, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(_, _, 1000, 1500)),
      _buildR(4, 8, _buildP(_, _, 1200, 1900)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(_, _, 1500, 2200)),
      _buildR(14, 18, _buildP(_, _, 1500, 2300)),
      _buildR(19, 30, _buildP(_, _, 1500, 2300)),
      _buildR(31, 50, _buildP(_, _, 1500, 2300)),
      _buildR(51, 70, _buildP(_, _, 1300, 2300)),
      _buildR(71, _, _buildP(_, _, 1200, 2300)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(_, _, 1500, 2200)),
      _buildR(14, 18, _buildP(_, _, 1500, 2300)),
      _buildR(19, 30, _buildP(_, _, 1500, 2300)),
      _buildR(31, 50, _buildP(_, _, 1500, 2300)),
      _buildR(51, 70, _buildP(_, _, 1300, 2300)),
      _buildR(71, _, _buildP(_, _, 1200, 2300)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(_, _, 1500, 2300)),
      _buildR(19, 30, _buildP(_, _, 1500, 2300)),
      _buildR(31, 50, _buildP(_, _, 1500, 2300)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(_, _, 1500, 2300)),
      _buildR(19, 30, _buildP(_, _, 1500, 2300)),
      _buildR(31, 50, _buildP(_, _, 1500, 2300)),
    ],
  },
  [nutrimentsConstants.CARBS.id]: {
    unit: 'g',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 60, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 90, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(100, 130, _, _)),
      _buildR(4, 8, _buildP(100, 130, _, _)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(100, 130, _, _)),
      _buildR(14, 18, _buildP(100, 130, _, _)),
      _buildR(19, 30, _buildP(100, 130, _, _)),
      _buildR(31, 50, _buildP(100, 130, _, _)),
      _buildR(51, 70, _buildP(100, 130, _, _)),
      _buildR(71, _, _buildP(100, 130, _, _)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(100, 130, _, _)),
      _buildR(14, 18, _buildP(100, 130, _, _)),
      _buildR(19, 30, _buildP(100, 130, _, _)),
      _buildR(31, 50, _buildP(100, 130, _, _)),
      _buildR(51, 70, _buildP(100, 130, _, _)),
      _buildR(71, _, _buildP(100, 130, _, _)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(135, 175, _, _)),
      _buildR(19, 30, _buildP(135, 175, _, _)),
      _buildR(31, 50, _buildP(135, 175, _, _)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(160, 210, _, _)),
      _buildR(19, 30, _buildP(160, 210, _, _)),
      _buildR(31, 50, _buildP(160, 210, _, _)),
    ],
  },
  [nutrimentsConstants.PROTEIN.id]: {
    unit: 'g',
    // NOTE: Available evidence does not support recommending a separate protein requirement for vegetarians who consume complimentary mixtures of plant proteins, as these can provide the same quality of protein as that from animal proteins.
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 9.1, _)),
      _buildR(7 / 12, 1, _buildP(_, 11, _, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(_, 13, _, _)),
      _buildR(4, 8, _buildP(_, 19, _, _)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(_, 34, _, _)),
      _buildR(14, 18, _buildP(_, 52, _, _)),
      _buildR(19, 30, _buildP(_, 56, _, _)),
      _buildR(31, 50, _buildP(_, 56, _, _)),
      _buildR(51, 70, _buildP(_, 56, _, _)),
      _buildR(71, _, _buildP(_, 56, _, _)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(_, 34, _, _)),
      _buildR(14, 18, _buildP(_, 46, _, _)),
      _buildR(19, 30, _buildP(_, 46, _, _)),
      _buildR(31, 50, _buildP(_, 46, _, _)),
      _buildR(51, 70, _buildP(_, 46, _, _)),
      _buildR(71, _, _buildP(_, 46, _, _)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(_, 71, _, _)),
      _buildR(19, 30, _buildP(_, 71, _, _)),
      _buildR(31, 50, _buildP(_, 71, _, _)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(_, 71, _, _)),
      _buildR(19, 30, _buildP(_, 71, _, _)),
      _buildR(31, 50, _buildP(_, 71, _, _)),
    ],
  },
  [nutrimentsConstants.FIBER.id]: {
    unit: 'g',
    // NOTE: Total fibre is defined as the sum of dietary fibre and functional fibre. See definitions for further details.
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, _, _)),
      _buildR(7 / 12, 1, _buildP(_, _, _, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(_, _, 29, _)),
      _buildR(4, 8, _buildP(_, _, 25, _)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(_, _, 31, _)),
      _buildR(14, 18, _buildP(_, _, 38, _)),
      _buildR(19, 30, _buildP(_, _, 38, _)),
      _buildR(31, 50, _buildP(_, _, 38, _)),
      _buildR(51, 70, _buildP(_, _, 30, _)),
      _buildR(71, _, _buildP(_, _, 30, _)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(_, _, 26, _)),
      _buildR(14, 18, _buildP(_, _, 26, _)),
      _buildR(19, 30, _buildP(_, _, 25, _)),
      _buildR(31, 50, _buildP(_, _, 25, _)),
      _buildR(51, 70, _buildP(_, _, 21, _)),
      _buildR(71, _, _buildP(_, _, 21, _)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(_, _, 28, _)),
      _buildR(19, 30, _buildP(_, _, 28, _)),
      _buildR(31, 50, _buildP(_, _, 28, _)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(_, _, 29, _)),
      _buildR(19, 30, _buildP(_, _, 29, _)),
      _buildR(31, 50, _buildP(_, _, 29, _)),
    ],
  },
  [nutrimentsConstants.FAT.id]: {
    unit: 'g',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 31, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 30, _)),
    ],
  },
  [nutrimentsConstants.ALA.id]: {
    unit: 'g',
    [INFANTS]: [
      _buildR(0, 0.5, _buildP(_, _, 0.5, _)),
      _buildR(7 / 12, 1, _buildP(_, _, 0.5, _)),
    ],
    [CHILDREN]: [
      _buildR(1, 3, _buildP(_, _, 0.7, _)),
      _buildR(4, 8, _buildP(_, _, 0.9, _)),
    ],
    [MALES]: [
      _buildR(9, 13, _buildP(_, _, 1.2, _)),
      _buildR(14, 18, _buildP(_, _, 1.6, _)),
      _buildR(19, 30, _buildP(_, _, 1.6, _)),
      _buildR(31, 50, _buildP(_, _, 1.6, _)),
      _buildR(51, 70, _buildP(_, _, 1.6, _)),
      _buildR(71, _, _buildP(_, _, 1.6, _)),
    ],
    [FEMALES]: [
      _buildR(9, 13, _buildP(_, _, 1.0, _)),
      _buildR(14, 18, _buildP(_, _, 1.1, _)),
      _buildR(19, 30, _buildP(_, _, 1.1, _)),
      _buildR(31, 50, _buildP(_, _, 1.1, _)),
      _buildR(51, 70, _buildP(_, _, 1.1, _)),
      _buildR(71, _, _buildP(_, _, 1.1, _)),
    ],
    [PREGNANCY]: [
      _buildR(_, 18, _buildP(_, _, 1.4, _)),
      _buildR(19, 30, _buildP(_, _, 1.4, _)),
      _buildR(31, 50, _buildP(_, _, 1.4, _)),
    ],
    [LACTATION]: [
      _buildR(_, 18, _buildP(_, _, 1.3, _)),
      _buildR(19, 30, _buildP(_, _, 1.3, _)),
      _buildR(31, 50, _buildP(_, _, 1.3, _)),
    ],
  },
};

export const idToCode = {
  [nutrimentsConstants.CALORIES.id]: nutrimentsConstants.CALORIES.code,
  [nutrimentsConstants.PROTEIN.id]: nutrimentsConstants.PROTEIN.code,
  [nutrimentsConstants.FAT.id]: nutrimentsConstants.FAT.code,
  [nutrimentsConstants.CARBS.id]: nutrimentsConstants.CARBS.code,
  [nutrimentsConstants.ALCOHOL.id]: nutrimentsConstants.ALCOHOL.code,
  [nutrimentsConstants.FIBER.id]: nutrimentsConstants.FIBER.code,
};

export const codeToConstant = mapKeys(nutrimentsConstants, v => v.code);
export const idToConstant = mapKeys(nutrimentsConstants, v => v.id);
export const keenoaCodeToConstant = mapKeys(
  nutrimentsConstants,
  v => v.keenoaCode
);

export const VITAMINS = [
  nutrimentsConstants.VITAMIN_A.id,
  nutrimentsConstants.VITAMIN_B_6.id,
  nutrimentsConstants.VITAMIN_B_12.id,
  nutrimentsConstants.VITAMIN_B12__ADDED.id,
  nutrimentsConstants.VITAMIN_C.id,
  nutrimentsConstants.VITAMIN_D.id,
  nutrimentsConstants.VITAMIN_D_D2_AND_D3.id,
  nutrimentsConstants.VITAMIN_D2_ERGOCALCIFEROL.id,
  nutrimentsConstants.BIOTIN.id,

  nutrimentsConstants.VITAMIN_K.id,
  nutrimentsConstants.ALPHA_TOCOPHEROL.id,
  nutrimentsConstants.ALPHA_TOCOPHEROL__ADDED.id,
];

export const OTHERS = [
  nutrimentsConstants.CALORIES.id,
  nutrimentsConstants.WATER.id,
  nutrimentsConstants.ALCOHOL.id,
  nutrimentsConstants.CALCIUM.id,
  nutrimentsConstants.IRON.id,
  nutrimentsConstants.MAGNESIUM.id,
  nutrimentsConstants.PHOSPHORUS.id,
  nutrimentsConstants.POTASSIUM.id,
  nutrimentsConstants.SODIUM.id,
  nutrimentsConstants.ZINC.id,
  nutrimentsConstants.COPPER.id,
  nutrimentsConstants.MANGANESE.id,
  nutrimentsConstants.SELENIUM.id,
  nutrimentsConstants.BETA_CAROTENE.id,
  nutrimentsConstants.ALPHA_CAROTENE.id,
  nutrimentsConstants.LYCOPENE.id,
  nutrimentsConstants.LUTEIN_AND_ZEAXANTHIN.id,
  nutrimentsConstants.BETA_TOCOPHEROL.id,
  nutrimentsConstants.GAMMA_TOCOPHEROL.id,
  nutrimentsConstants.DELTA_TOCOPHEROL.id,
  nutrimentsConstants.THIAMIN.id,
  nutrimentsConstants.RIBOFLAVIN.id,
  nutrimentsConstants.NIACIN.id,
  nutrimentsConstants.NIACIN_PREFORMED.id,
  nutrimentsConstants.PANTOTHENIC_ACID.id,
  nutrimentsConstants.TOTAL_FOLACIN.id,
  nutrimentsConstants.CHOLINE__TOTAL.id,
  nutrimentsConstants.FOLIC_ACID.id,
  nutrimentsConstants.NATURALLY_OCCURRING_FOLATE.id,
  nutrimentsConstants.DIETARY_FOLATE_EQUIVALENTS.id,
  nutrimentsConstants.BETAINE.id,
  nutrimentsConstants.ASPARTAME.id,
];

export default nutrimentsConstants;

export const NUTRIENTS_SECTIONS = [
  {
    title: messages.proteins,
    color: NUTRIENT_COLORS.protein,
    ids: [
      nutrimentsConstants.PROTEIN.id,
      ...nutrimentsConstants.PROTEIN.children,
    ],
  },
  {
    title: messages.fats,
    color: NUTRIENT_COLORS.fat,
    ids: [nutrimentsConstants.FAT.id, ...nutrimentsConstants.FAT.children],
  },
  {
    title: messages.sugars,
    color: NUTRIENT_COLORS.carbs,
    ids: [nutrimentsConstants.CARBS.id, ...nutrimentsConstants.CARBS.children],
  },
  {
    title: messages.vitamins,
    color: NUTRIENT_COLORS.vitamins,
    ids: VITAMINS,
  },
  {
    title: messages.others,
    color: NUTRIENT_COLORS.calories,
    ids: OTHERS,
  },
];

export const NUTRIENTS_SECTIONS_FOR_NS = [
  {
    title: messages.proteins,
    color: NUTRIENT_COLORS.protein,
    ids: [
      nutrimentsConstants.PROTEIN.id,
      ...nutrimentsConstants.PROTEIN.children,
    ],
  },
  {
    title: messages.fats,
    color: NUTRIENT_COLORS.fat,
    ids: [nutrimentsConstants.FAT.id, ...nutrimentsConstants.FAT.children],
  },
  {
    title: messages.sugars,
    color: NUTRIENT_COLORS.carbs,
    ids: [nutrimentsConstants.CARBS.id, ...nutrimentsConstants.CARBS.children],
  },
  {
    title: messages.vitamins,
    color: NUTRIENT_COLORS.vitamins,
    ids: VITAMINS,
  },
  {
    title: messages.others,
    color: NUTRIENT_COLORS.calories,
    ids: [
      nutrimentsConstants.NS_STEP.id,
      nutrimentsConstants.NS_GREEN_STEP.id,
      ...OTHERS,
    ],
  },
];
