import Templates from './Templates';
import { connect } from 'react-redux';
import {
  getCurrentTemplates,
  getFetchTemplatesStatus,
} from 'redux/modules/reportTemplates';
import FETCH_STATUS from 'constants/fetchStatus';
import { fetchReportTemplates } from 'redux/modules/reportTemplates/reportTemplates.actions';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';

const mapStateToProps = state => ({
  templates: getCurrentTemplates(state),
  isLoading: getFetchTemplatesStatus(state) === FETCH_STATUS.LOADING,
});

const mapDispatchToProps = dispatch => ({
  fetchTemplates: () => dispatch(fetchReportTemplates()),
  createTemplate: () =>
    dispatch(openModal(MODAL_TYPES.CREATE_REPORT_TEMPLATE, {})),
  duplicateTemplate: templateId => {
    dispatch(
      openModal(
        MODAL_TYPES.CREATE_REPORT_TEMPLATE,
        { templateId, isDuplicate: true },
        true
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
