import React from 'react';
import { withTheme } from 'styled-components';
import { withContentRect } from 'react-measure';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
} from 'recharts';
import nutrimentsConstants, {
  NUTRIENT_COLORS,
} from '../../../../../../constants/nutriments';
import { shadowLevels } from 'components/WhiteFrame';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import Color from 'color';
import Typography from 'components/material/Typography';
import './Graph.css';

const DEFAULT_HEIGHT = 244;
const MIN_WIDTH = 400;

const messages = defineMessages({
  hourlyTitle: {
    id: 'diary.detailed.hourlyGraph',
  },
  dailyTitle: {
    id: 'diary.detailed.dailyGraph',
  },
});

class Graph extends React.PureComponent {
  formatter = value => {
    return (value || 0).toFixed(0);
  };

  _fakeEnergy = label => {
    const { labelToEnergy, theme, intl } = this.props;
    const value = labelToEnergy[label];
    const dataKey = nutrimentsConstants.CALORIES.id;
    const fill = Color(theme.palette[NUTRIENT_COLORS[dataKey]])
      .fade(0.3)
      .toString();
    const name = intl.formatMessage(nutrimentsConstants.CALORIES.label);
    const unit = ' kcal';
    return {
      fill,
      color: fill,
      value,
      dataKey,
      name,
      unit,
    };
  };

  /** I copied the defaultToopTip implementation from recharts and updated it
   * to be able to include the energy in the legend. */
  renderTooltip = ({ payload, separator, label, itemStyle }) => {
    if (!payload || !payload.length) return null;

    const listStyle = { padding: 0, margin: 0 };
    const energy = this._fakeEnergy(label);
    const withEnergy = [...payload, energy];
    const items = withEnergy.map((entry, i) => {
      const finalItemStyle = {
        display: 'block',
        padding: 0,
        margin: 0,
        color: entry.color || '#000',
        ...itemStyle,
        fontWeight: 600,
      };
      const hasName = !!entry.name;
      const finalFormatter = this.formatter;

      return (
        <li
          className="recharts-tooltip-item mdc-typography--body1"
          key={`tooltip-item-${i}`}
          style={finalItemStyle}
        >
          {hasName ? (
            <span className="recharts-tooltip-item-name">{entry.name}</span>
          ) : null}
          {hasName ? (
            <span className="recharts-tooltip-item-separator">{separator}</span>
          ) : null}
          <span className="recharts-tooltip-item-value">
            {finalFormatter
              ? finalFormatter(entry.value, entry.name, entry, i)
              : entry.value}
          </span>
          <span className="recharts-tooltip-item-unit">{entry.unit || ''}</span>
        </li>
      );
    });

    return (
      <div
        style={{
          backgroundColor: 'white',
          boxShadow: shadowLevels['2'],
          padding: 10,
          borderRadius: 6,
        }}
      >
        <span className="mdc-typography--headline6">{label}</span>
        <ul className="recharts-tooltip-item-list" style={listStyle}>
          {items}
        </ul>
      </div>
    );
  };

  render() {
    const {
      intl,
      theme,
      data,
      measureRef,
      contentRect,
      isPdf,
      isHourly,
    } = this.props;
    const width = Math.max(MIN_WIDTH, contentRect.bounds.width);
    const height = this.props.height || DEFAULT_HEIGHT;
    return (
      <div
        ref={measureRef}
        style={{
          flex: 1,
          backgroundColor: 'transparent',
          position: 'relative',
          left: -20,
        }}
      >
        {isPdf ? null : (
          <Typography
            use="subtitle2"
            rank="midPrimary"
            tag="div"
            style={{ padding: 5, marginLeft: 30 }}
          >
            <FormattedMessage
              {...(isHourly ? messages.hourlyTitle : messages.dailyTitle)}
            />
          </Typography>
        )}
        <Typography use="caption" rank="midPrimary" tag="div">
          <BarChart width={width} height={height} data={data}>
            {this.props.hideAxis ? null : <XAxis dataKey="name" tickSize={4} />}
            <YAxis unit=" g" />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={this.renderTooltip} />
            {this.props.hideLegend ? null : (
              <Legend verticalAlign="top" height={36} />
            )}
            <Bar
              name={intl.formatMessage(nutrimentsConstants['PROTEIN'].label)}
              dataKey="protein"
              fill={Color(theme.palette[NUTRIENT_COLORS['protein']])
                .fade(0.3)
                .toString()}
              unit={' g'}
            />
            <Bar
              name={intl.formatMessage(nutrimentsConstants['FAT'].label)}
              dataKey="fat"
              fill={Color(theme.palette[NUTRIENT_COLORS['fat']])
                .fade(0.3)
                .toString()}
              unit={' g'}
            />
            <Bar
              name={intl.formatMessage(nutrimentsConstants['CARBS'].label)}
              dataKey="carbs"
              fill={Color(theme.palette[NUTRIENT_COLORS['carbs']])
                .fade(0.3)
                .toString()}
              unit={' g'}
            />
            <Bar
              name={intl.formatMessage(nutrimentsConstants.ALCOHOL.label)}
              dataKey="alcohol"
              fill={Color(theme.palette[NUTRIENT_COLORS.alcohol])
                .fade(0.3)
                .toString()}
              unit={' g'}
            />
          </BarChart>
        </Typography>
      </div>
    );
  }
}

export default withContentRect('bounds')(withTheme(injectIntl(Graph)));
