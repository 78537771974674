import React from 'react';
import { withTheme } from 'styled-components';
import { STATUS_FILTERS } from '../../../../constants/clientStatus';
import { defineMessages, injectIntl } from 'react-intl';
import Select from 'components/forms/Select';

const messages = defineMessages({
  all: { id: 'clients.filter.all' },
  allBut: { id: 'clients.filter.allBut' },
  active: { id: 'clients.filter.active' },
  pending: { id: 'clients.filter.pending' },
  archived: { id: 'clients.filter.archived' },
  revoked: { id: 'clients.filter.revoked' },
});

class ClientsFiltering extends React.PureComponent {
  _getFilterLabel = filter => {
    const sf = STATUS_FILTERS;
    const { intl } = this.props;
    switch (filter) {
      case sf.ALL_BUT_ARCHIVED:
        return `    ${intl.formatMessage(messages.allBut)}`;
      case sf.ACTIVE:
        return `        ${intl.formatMessage(messages.active)}`;
      case sf.PENDING:
        return `        ${intl.formatMessage(messages.pending)}`;
      case sf.ARCHIVED:
        return `    ${intl.formatMessage(messages.archived)}`;
      default:
        return intl.formatMessage(messages.all);
    }
  };
  _getOptions = () => {
    return Object.values(STATUS_FILTERS).map(val => ({
      id: val,
      label: this._getFilterLabel(val),
    }));
  };

  render() {
    const { filter, selectFilter, theme } = this.props;

    return (
      <div style={{ width: 175, padding: '15px 3px 3px 3px' }}>
        <Select
          maxWidth={150}
          buttonStyle={{ padding: '6px 8px', borderWidth: 1, marginTop: -5 }}
          buttonTextStyle={{
            color: theme.palette.text.secondary,
          }}
          carretIcon="filter_list"
          value={{ id: filter, label: this._getFilterLabel(filter) }}
          options={this._getOptions()}
          onChange={x => selectFilter(x.id)}
        />
      </div>
    );
  }
}

export default injectIntl(withTheme(ClientsFiltering));
