import React from 'react';
import Modal from 'components/Modal';

import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import H2 from 'components/H2';
import { AVAILABLE_LOCALES } from '../../../../../constants';

const messages = defineMessages({
  title: {
    id: 'editLocale.title',
    defaultMessage: 'Choose a language :',
  },
});

const LocalesWrapper = styled.div`
  display: flex;
  flex-drection: row;
  flex-wrap: true;
`;

const LocaleWrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: flex-start;
`;

const Locale = styled.div`
  color: ${props => props.theme.palette.primary1};
  padding: 20px;

  &:hover {
    cursor: pointer;
    filter: contrast(1.5);
  }
`;

class EditLocale extends React.Component {
  _submitLocale = locale => () => {
    this.props.onUpdateLocale(locale);
    this.props.onExit();
  };

  _onClose = () => {
    this.props.onExit();
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Modal isOpen={isOpen} onClose={this._onClose}>
        <H2 style={{ marginBottom: 25 }}>
          <FormattedMessage {...messages.title} />
        </H2>
        <LocalesWrapper>
          {AVAILABLE_LOCALES.map(x => (
            <LocaleWrapper key={x.id}>
              <Locale
                className="mdc-typography--subheading2"
                onClick={this._submitLocale(x.value)}
              >
                {' '}
                <FormattedMessage {...x.label} />{' '}
              </Locale>
            </LocaleWrapper>
          ))}
        </LocalesWrapper>
      </Modal>
    );
  }
}

export default EditLocale;
