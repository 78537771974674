import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Button } from 'components/material/Button';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from 'components/material/Typography';
import Spacer from 'components/Spacer';

const messages = defineMessages({
  title: {
    id: 'reports.noReports.title',
    defaultMessage: 'Would you like to create a new report ?',
  },
  hint: {
    id: 'reports.noReports.hint',
    defaultMessage:
      'Reports can be sent to your clients or shared with other professionnals. By starting from a template, they can be generated in a minute.',
  },
  addBtn: {
    id: 'reports.noReports.addBtn',
    defaultMessage: 'Create a report',
  },
});

const SuggestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

const AddClientButton = styled(Button)`
  margin-top: 8px;
  padding-left: 20px;
  padding-right: 20px;
`;

const NoReports = ({ onCreateReport }) => {
  return (
    <SuggestionContainer>
      <Typography use="headline6" rank="primary">
        <FormattedMessage {...messages.title} />
      </Typography>
      <div style={{ maxWidth: 475 }}>
        <Typography
          use="body2"
          rank="secondary"
          tag="div"
          style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
        >
          <FontAwesomeIcon
            icon={['fad', 'lightbulb-exclamation']}
            aria-hidden="true"
            style={{ paddingRight: 3 }}
          />
          <FormattedMessage {...messages.hint} />
        </Typography>
      </div>

      <Spacer size="md" />
      <AddClientButton outlined rank="accent" onClick={onCreateReport}>
        <FormattedMessage {...messages.addBtn} />
      </AddClientButton>
    </SuggestionContainer>
  );
};

export default NoReports;
