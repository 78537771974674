import React, { useState } from 'react';
import { Menu, MenuSurfaceAnchor } from '../../Menu';
import { confirmAction } from '../../../utils/misc';
import ActionRow from '../ActionRow';
import { TEMPLATE_VISIBILITY } from '../../../constants';
import IconButton from '../../forms/IconButton';

const messages = {
  edit: { id: 'templateActions.edit' },
  delete: { id: 'templateActions.delete' },
  duplicate: { id: 'templateActions.duplicate' },

  deleteHeader: { id: 'templateActions.delete.header' },
  deleteExplanation: { id: 'templateActions.delete.explanation' },
  deleteConfirm: { id: 'templateActions.delete.confirm' },
  deleteCancel: { id: 'templateActions.delete.cancel' },
};

export default function TemplateActions({
  onEdit,
  onDelete,
  onDuplicate,
  visibility,
}) {
  const [open, setOpen] = useState(false);
  const isPublic = visibility === TEMPLATE_VISIBILITY.PUBLIC;

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open);
  };

  const handleSelect = i => {
    if (i === 0) {
      isPublic ? onDuplicate() : onEdit();
    }
    if (i === 1 && !isPublic) onDuplicate();
    if (i === 2 && !isPublic)
      confirmAction(
        {
          title: messages.deleteHeader,
          explanation: messages.deleteExplanation,
          confirm: messages.deleteConfirm,
          cancel: messages.deleteCancel,
        },
        onDelete
      );

    setOpen(false);
  };

  const makeHandleMenuClick = i => e => {
    e.stopPropagation();
    handleSelect(i);
  };

  return (
    <>
      <MenuSurfaceAnchor>
        <Menu
          open={open}
          onSelect={x => handleSelect(x.detail.index)}
          onClose={() => setOpen(false)}
        >
          {!isPublic && (
            <ActionRow
              message={messages.edit}
              icon={['far', 'edit']}
              onClick={makeHandleMenuClick(0)}
              width={70}
            />
          )}
          <ActionRow
            message={messages.duplicate}
            icon={['far', 'clone']}
            onClick={makeHandleMenuClick(isPublic ? 0 : 1)}
            width={70}
          />
          {!isPublic && (
            <ActionRow
              message={messages.delete}
              icon={['far', 'trash-alt']}
              onClick={makeHandleMenuClick(2)}
              width={70}
            />
          )}
        </Menu>
        <IconButton
          onClick={handleClick}
          style={{ fontSize: 20, color: 'rgba(0,0,0,0.33)' }}
          icon="more_horiz"
        />
      </MenuSurfaceAnchor>
    </>
  );
}
