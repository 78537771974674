import { connect } from 'react-redux';
import {
  closeModal,
  getModalOpen,
  MODAL_TYPES,
} from 'redux/modules/modals/modals';
import SearchFoodModal from './SearchFoodModal';
import { refreshSearchKey } from 'redux/modules/search';

export default connect(
  state => ({
    isOpen: getModalOpen(state, MODAL_TYPES.ADD_FOOD),
  }),
  dispatch => ({
    onClose: () => dispatch(closeModal(MODAL_TYPES.ADD_FOOD)),
    refreshKey: () => dispatch(refreshSearchKey()),
  })
)(SearchFoodModal);
