import React, { useEffect, useState } from 'react';
import { isTokenValid } from '../../utils/auth';
import { Redirect, useLocation } from 'react-router-dom';
import { acceptDietitianInvitationApi } from 'api/client';
import { connect } from 'react-redux';
import { fetchClients } from 'redux/modules/clients/clients.operations';
import { useQuery } from 'utils/hooks';
import { notify } from 'react-notify-toast';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  success: { id: 'receiveInvite.success' },
  error: { id: 'receiveInvite.error' },
});

const mapDispatchToProps = dispatch => ({
  loadClients: () => dispatch(fetchClients()),
});
export default connect(null, mapDispatchToProps)(ReceiveClientInvite);

function ReceiveClientInvite({ loadClients }) {
  const location = useLocation();
  const query = useQuery();
  const token = query.get('token');
  const tokenValid = isTokenValid();
  const intl = useIntl();
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (!token || !tokenValid) return;

    const acceptInvitation = async () => {
      try {
        await acceptDietitianInvitationApi(token);
        await loadClients();
        notify.show(intl.formatMessage(messages.success), 'success');
      } catch (e) {
        notify.show(intl.formatMessage(messages.error), 'error');
      }
    };
    acceptInvitation();
    setSent(true);
  }, [token, tokenValid]);

  if (!token || sent) {
    return (
      <Redirect
        to={{
          pathname: '/clients',
        }}
      />
    );
  }

  if (!isTokenValid()) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: `${location.pathname}?token=${token}` },
        }}
      />
    );
  }

  return null;
}
