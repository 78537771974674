import mapValues from 'lodash/mapValues';

/** FEATURE TOGGLES */

const _TOGGLES = {
  REPORT_EXPORT: true,
};

const setWithEnv = toggles =>
  mapValues(toggles, (value, key) => {
    let val = process.env[`REACT_APP_${key}`];
    if (val !== undefined) {
      return val === true || val === 'true';
    }
    return value;
  });

export const TOGGLES = setWithEnv(_TOGGLES);

export default TOGGLES;
