import has from 'lodash/has';
import get from 'lodash/get';
import last from 'lodash/last';
import FETCH_STATUS from '../constants/fetchStatus';

/**
 * Return a byId reducer for the entityName. This reducer work
 * with entities noramalized by normalizr.
 * @param {string} entityName
 */
export const makeById = entityName => (state = {}, action) => {
  const normalizedEntityLocation = `response.entities.${entityName}`;
  if (has(action, normalizedEntityLocation)) {
    const newObj = get(action, normalizedEntityLocation);
    return {
      ...state,
      ...newObj,
    };
  }
  return state;
};

export const makeError = prefix => (state = null, action) => {
  if (!has(action, 'type') || !action.type.startsWith(prefix)) return state;

  switch (last(action.type.split('_'))) {
    case 'REQUEST':
    case 'SUCCESS':
      return null;
    case 'FAILURE':
      return action.error;
    default:
      return state;
  }
};

export const makeMapFetchStatus = (prefix, idLocation = 'id') => (
  state = {},
  action
) => {
  if (!has(action, 'type') || !action.type.startsWith(prefix)) return state;

  switch (last(action.type.split('_'))) {
    case 'REQUEST':
      return { ...state, [action[idLocation]]: FETCH_STATUS.LOADING };
    case 'SUCCESS':
      return { ...state, [action[idLocation]]: FETCH_STATUS.LOADED };
    case 'FAILURE':
      return { ...state, [action[idLocation]]: FETCH_STATUS.FAILED };
    default:
      return state;
  }
};

export const makeFetchStatus = prefix => (
  state = FETCH_STATUS.NONE,
  action
) => {
  if (!has(action, 'type') || !action.type.startsWith(prefix)) return state;

  switch (last(action.type.split('_'))) {
    case 'REQUEST':
      return FETCH_STATUS.LOADING;
    case 'SUCCESS':
      return FETCH_STATUS.LOADED;
    case 'FAILURE':
      return FETCH_STATUS.FAILED;
    default:
      return state;
  }
};

export const makeByIdFetchStatus = (prefix, idLocation) => {
  const fetchStatus = makeFetchStatus(prefix);

  return (state = {}, action) => {
    if (!has(action, 'type') || !action.type.startsWith(prefix)) return state;

    const id = get(action, idLocation);
    return {
      ...state,
      [id]: fetchStatus(state[id], action),
    };
  };
};

/**
 * Helper to namespace a reducer.
 * @param {reducer} namespaceReducer
 * @param {[string]} removeTypes
 * @param {[string]} namespaceTypes
 */
export const makeByNamespace = (
  namespaceReducer,
  removeTypes,
  namespaceTypes
) => {
  const namespaceTypesSet = new Set(namespaceTypes);
  const removeTypesSet = new Set(removeTypes);

  return (state = {}, action) => {
    const { type, namespace } = action;
    if (namespaceTypesSet.has(type)) {
      return {
        ...state,
        [namespace]: namespaceReducer(get(state, namespace), action),
      };
    }
    if (removeTypesSet.has(type)) {
      const { [namespace]: value, ...rest } = state;
      return rest;
    }
    return state;
  };
};

/**
 * Generic reducer to set and clear a value
 * @param {array} setters : array Setter ex.
 * [{
 *  type: SET_NAME,
 *  prop: 'cool.name',
 * }, {
 *  type: NEW_PERSON,
 *  prop: 'fullName',
 * }]
 * @param {array} clearers : the types that should clear the value
 */
export const makeSimpleValue = (setters, clearers, startState = null) => {
  const clearersTypes = new Set(clearers.map(x => x.type));
  const settersMap = {};
  for (let i = 0; i < setters.length; ++i) {
    const { type, prop } = setters[i];
    settersMap[type] = prop;
  }

  return (state = startState, action) => {
    // Check clearers
    if (clearersTypes.has(action.type)) return null;

    // Check setters
    const prop = settersMap[action.type];
    if (prop) return get(action, prop, null);

    return state;
  };
};
