import React from 'react';
import { withTheme } from 'styled-components';
import { defineMessages, injectIntl } from 'react-intl';
import { notify } from 'react-notify-toast';
import TableActions from 'components/TableActions';
import { USER_FOOD_ACTIONS } from 'constants/index';
import { confirmAction } from 'utils/misc';

const messages = defineMessages({
  label: { id: 'userFood.label' },

  remove: { id: 'userFood.remove' },
  removeSuccessP: { id: 'userFood.removeSuccessP' },
  removeSuccess: { id: 'userFood.removeSuccess' },

  deleteHeader: { id: 'userFood.deleteHeader' },
  deleteExplanation: { id: 'userFood.deleteExplanation' },
  deleteConfirm: { id: 'userFood.deleteConfirm' },
  deleteCancel: { id: 'userFood.deleteCancel' },
});

const AVAILABLE_ACTIONS = [USER_FOOD_ACTIONS.DELETE];

function UserFoodActions({
  selectedIds,
  unselectAll,
  deleteFood,

  intl,
  theme,
}) {
  const _notifySuccess = (action, count) => {
    let text = '';
    // eslint-disable-next-line
    switch (action) {
      case USER_FOOD_ACTIONS.DELETE:
        text =
          count > 1
            ? intl.formatMessage(messages.removeSuccessP, { count })
            : intl.formatMessage(messages.removeSuccess);
        break;
    }
    notify.show(text, 'custom', 1400, {
      background: theme.palette.success,
      text: 'white',
    });
  };

  const _removeSelected = () => {
    return Promise.all(selectedIds.map(deleteFood))
      .then(() => _notifySuccess(USER_FOOD_ACTIONS.DELETE, selectedIds.length))
      .then(unselectAll);
  };

  const _onAction = action => {
    // eslint-disable-next-line
    switch (action) {
      case USER_FOOD_ACTIONS.DELETE:
        return confirmAction(
          {
            title: messages.deleteHeader,
            explanation: messages.deleteExplanation,
            confirm: messages.deleteConfirm,
            cancel: messages.deleteCancel,
          },
          _removeSelected
        );
    }
  };

  const tableActions = AVAILABLE_ACTIONS.map(a => ({
    id: a,
    ...actionProps(a),
  }));
  return (
    <TableActions
      actions={tableActions}
      label={intl.formatMessage(messages.label)}
      onAction={_onAction}
      selectCount={selectedIds.length}
    />
  );
}

export default injectIntl(withTheme(UserFoodActions));

function actionProps(action) {
  let icon;
  let message;
  let color = 'midPrimary';
  switch (action) {
    case USER_FOOD_ACTIONS.DELETE:
      icon = 'trash-alt';
      message = messages.remove;
      color = 'error';
      break;
    default:
      break;
  }
  return {
    icon,
    message,
    color,
  };
}
