import React from 'react';
import styled from 'styled-components';
import Color from 'color';
import Spacer from 'components/Spacer';

const bg = Color('black')
  .fade(0.98)
  .toString();
const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 98px;
  position: relative;
`;
const Fake = styled.div`
  height: 56px;
  background-color: ${bg};
  border-radius: 5px;
`;
const FakeValue = styled(Fake)`
  width: 130px;
`;
const FakeUnit = styled(Fake)`
  width: 455px;
`;
const FakeButton = styled(Fake)`
  width: 70px;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 34px;
`;

export default function PlaceholderPicker({ renderMessage, style }) {
  return (
    <>
      <Spacer spacing="md" />
      <PlaceholderWrapper style={style}>
        <FakeValue />
        <FakeUnit>{renderMessage ? renderMessage() : null}</FakeUnit>
        <FakeButton />
      </PlaceholderWrapper>
    </>
  );
}
