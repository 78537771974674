import React from 'react';
import styled from 'styled-components';

const MIN_IMAGE_HEIGHT = 480;
const MAX_IMAGE_HEIGHT = 1080;

const FullPage = styled.div`
  position: fixed;
  overflow: auto;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3000;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  max-height: ${MAX_IMAGE_HEIGHT}px;
  min-height: ${MIN_IMAGE_HEIGHT}px;
  height: 90%;
  border-radius: 10px;
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`;

class FullPageImage extends React.Component {
  _onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClose(e);
  };

  _renderImg = src => (
    <Img onClick={e => e.stopPropagation()} src={src} open={this.props.open} />
  );

  render() {
    const { url } = this.props;

    if (!this.props.open) return null;
    return (
      <FullPage onClick={this._onClick} open={this.props.open}>
        {this._renderImg(url)}
        <CloseIcon className="material-icons">close</CloseIcon>
      </FullPage>
    );
  }
}

export default FullPageImage;
