import types, { pre } from './billing.types';
import { combineReducers } from 'redux';
import { makeById, makeFetchStatus } from '../../../utils/standardReducers';

const invoicesById = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        ...action.response.entities.invoices,
      };
    default:
      return state;
  }
};
const isFetchingInvoices = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_INVOICES_REQUEST:
      return true;
    case types.FETCH_INVOICES_SUCCESS:
    case types.FETCH_INVOICES_FAILURE:
      return false;
    default:
      return state;
  }
};

const subscriptionsById = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        ...action.response.entities.subscriptions,
      };
    default:
      return state;
  }
};
const isFetchingSubscriptions = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_SUBSCRIPTIONS_REQUEST:
      return true;
    case types.FETCH_SUBSCRIPTIONS_SUCCESS:
    case types.FETCH_SUBSCRIPTIONS_FAILURE:
      return false;
    default:
      return state;
  }
};

const sourcesById = makeById('sources');
const sourcesStatus = makeFetchStatus(`${pre}FETCH_SOURCES`);

export default combineReducers({
  invoicesById,
  isFetchingInvoices,

  subscriptionsById,
  isFetchingSubscriptions,

  sourcesById,
  sourcesStatus,
});
