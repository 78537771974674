import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Authorize from './Authorize';
import Logo from 'components/Logo';

export default function Oauth() {
  return (
    <>
      <Logo />
      <Switch>
        <Route path={'/oauth/authorize'}>
          <Authorize />
        </Route>
      </Switch>
    </>
  );
}
