import React from 'react';
import Modal from 'components/Modal';
import EditEntry from './EditEntry';

class EditEntryModal extends React.PureComponent {
  _onClose = () => {
    const { onClose } = this.props;
    // Without this the whole dashboard was unmounting when
    // the modal was closed manually (the x or outside click).
    // However, an update was working fine which is why I tried
    // this patch. It works, but I have no idea what is causing
    // this. I suspect that there is a bug in React or react-Modal.
    return setTimeout(onClose, 0);
  };
  render() {
    const { isOpen, namespace } = this.props;

    return (
      <Modal
        style={{
          overflow: 'visible',
          width: '600px',
          maxWidth: '95wh',
        }}
        isOpen={isOpen}
        onClose={this._onClose}
      >
        <EditEntry
          onClose={this._onClose}
          {...this.props}
          namespace={namespace}
        />
      </Modal>
    );
  }
}

export default EditEntryModal;
