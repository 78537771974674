import React from 'react';
import { useTheme } from 'styled-components';
import { REPORT_FILTERS } from 'constants/index.js';
import { defineMessages } from 'react-intl';
import Select from 'components/forms/Select';
import { useIntl } from 'react-intl';

const messages = defineMessages({
  all: { id: 'reports.filter.all' },
  finalized: { id: 'reports.filter.finalized' },
  current: { id: 'reports.filter.current' },
});

function ReportsFiltering({ filter, selectFilter }) {
  const intl = useIntl();
  const theme = useTheme();

  const _getFilterLabel = filter => {
    switch (filter) {
      case REPORT_FILTERS.FINALIZED:
        return `    ${intl.formatMessage(messages.finalized)}`;
      case REPORT_FILTERS.CURRENT:
        return `    ${intl.formatMessage(messages.current)}`;
      default:
        return intl.formatMessage(messages.all);
    }
  };
  const _getOptions = () => {
    return Object.values(REPORT_FILTERS).map(val => ({
      id: val,
      label: _getFilterLabel(val),
    }));
  };

  return (
    <div style={{ width: 175, padding: '15px 3px 3px 3px' }}>
      <Select
        buttonStyle={{ padding: '6px 8px', borderWidth: 1, marginTop: -5 }}
        buttonTextStyle={{
          color: theme.palette.text.secondary,
        }}
        carretIcon="filter_list"
        value={{ id: filter, label: _getFilterLabel(filter) }}
        options={_getOptions()}
        onChange={x => selectFilter(x.id)}
      />
    </div>
  );
}

export default ReportsFiltering;
