import { combineReducers } from 'redux';
import types from './builderView.types';
import { makeByNamespace } from '../../../../utils/standardReducers';
import get from 'lodash/get';

/**
 * This reducer is used to keep track of the selected options for
 * each group. Technicaly, the selected options could be calculated
 * by a selector. However, going through the builder graph everytime
 * is relatively expensive and causes the option click to be lag.
 */
export const groupOptions = (state = {}, action) => {
  const groupId = `${action.groupId}`;
  const optionId = `${action.optionId}`;
  switch (action.type) {
    case types.SELECT_OPTION:
      return {
        ...state,
        [groupId]: [...get(state, groupId, []), optionId],
      };
    case types.UNSELECT_OPTION:
      const next = get(state, groupId, []).filter(x => x !== optionId);
      if (!next.length) {
        const { [groupId]: value, ...withoutGroup } = state;
        return withoutGroup;
      }
      return {
        ...state,
        [groupId]: next,
      };
    case types.CLEAR_OPTIONS:
      return {};
    default:
      return state;
  }
};

export const selectedOptions = (state = new Set(), action) => {
  const optionId = `${action.optionId}`;
  switch (action.type) {
    case types.SELECT_OPTION:
      const next = new Set(state);
      next.add(optionId);
      return next;
    case types.UNSELECT_OPTION:
      const n = new Set(state);
      n.delete(optionId);
      return n;
    case types.CLEAR_OPTIONS:
      return new Set();
    default:
      return state;
  }
};

const builderReducer = combineReducers({
  selectedOptions,
  groupOptions,
});

export default combineReducers({
  byNamespace: makeByNamespace(
    builderReducer,
    [types.REMOVE_BUILDER_NAMESPACE],
    [
      types.SELECT_OPTION,
      types.UNSELECT_OPTION,
      types.STAGE_BUILDER,
      types.STAGE_PUBLIC_BUILDER,
      types.ADD_ADJUSTMENT,
      types.REMOVE_ADJUSTMENT,
      types.CLEAR_OPTIONS,
    ]
  ),
});
