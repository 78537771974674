import React from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import Import from './Import';

const Private = () => {
  const match = useRouteMatch();

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex' }}>
      <Switch>
        <Route path={`${match.path}/import`}>
          <Import />
        </Route>
        <Route path={`${match.path}`}>
          <Redirect to={`${match.path}/import`} />
        </Route>
      </Switch>
    </div>
  );
};

export default Private;
