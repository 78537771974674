/*eslint-disable max-len,quotes*/
export default {
  'mainNav.clients': 'Clients',
  'mainNav.library': 'Bibli.',
  'leftNav.support': 'Support',
  'leftNav.support.header': 'Support',
  'leftNav.support.body':
    'Vous pouvez contacter notre équipe de support par courriel à ',

  'library.title': 'Bibliothèque',
  'library.back': 'Retour aux clients',
  'library.templates': 'Gabarits',
  'library.foods': 'Aliments',

  'account.title': 'Mon compte',
  'account.back': 'Retour',
  'account.general': 'Général',
  'account.billing': 'Facturation',
  'clients.columns.image': ' ',
  'foodDiary.compareSwitch.onboard.close': 'Fermer',

  'diary.detailed.hourlyGraph': 'Apport en  macronutriments par heure',
  'diary.detailed.dailyGraph': 'Apport en macronutriments journalier',

  'dashboard.menu2.diaries': 'Journal',
  'dashboard.menu2.reports': 'Rapports',

  'youtube.next': 'Vidéo suivante',

  'onboard.invalidToken': 'Accès expiré, connexion requise.',
  'onboard.welcome.title': 'Bienvenue sur la plateforme Keenoa',
  'onboard.welcome.subTitle': 'Voici 4 astuces pour vous aider à démarrer',
  'onboard.welcome.continue': 'Continuer',

  'tableActions.selected': '1 {label} sélectionné',
  'tableActions.selecteds': '{count} {label}s sélectionnés',

  'onboard.invite.title': 'Invitez vos clients',
  'onboard.invite.subTitle':
    'Ajoutez le courriel de vos clients ci-dessous pour leur envoyer une invitation à télécharger l’application mobile Keenoa qui sera connectée à votre plateforme.',
  'onboard.invite.video':
    'Vous-aimeriez voir à quoi ressemble l’app mobile Keenoa pour votre client? ',
  'onboard.invite.watch': 'Regarder une courte video',
  'onboard.invite.email': 'Courriel client #{i}',
  'onboard.invite.invite': "Envoyer l'invitation",
  'onboard.invite.invites': 'Envoyer les invitations',
  'onboard.invite.preview': "Prévisualiser l'invitation",

  'onboard.confirm.title': 'Invitations envoyées',
  'onboard.confirm.to': 'à:',
  'onboard.confirm.explore': 'Explorer la plateforme Keenoa',

  'onboard.detailed.title': 'Journal détaillé 🤓',
  'onboard.detailed.content':
    'Keenoa utilise une base de données nationale et décompose chaque aliment et repas en 30+ nutriments.',

  'onboard.videos.title': 'Courtes vidéos',
  'onboard.videos.content':
    'Nous avons préparé de courtes vidéos pour vous aider à en tirer le maximum de votre utilisation de Keenoa.',
  'onboard.videos.tutorial': 'Tutoriels',

  'client.fictive.title': 'Client fictif 👀',
  'client.fictive.content':
    'En attendant que vos clients acceptent votre invitation et prennent des photos, nous avons pris le soin de vous ajouter un client fictif pour que vous puissiez explorer la plateforme. Cliquez ici pour voir les photos et l’apport nutritionnel de ce client.',
  'client.fictive.close': 'Génial!',

  'onboard.videos.welcome': 'Démarrer efficacement avec Keenoa!',
  'onboard.videos.clientExperience':
    'L’expérience que vous offrirez à votre client',
  'onboard.videos.consultation': 'Pimentez vos consultations',
  'onboard.videos.retention': 'Gardez vos clients près de vous',

  'newClient.preview.invite': 'Inviter',
  'newClient.preview.acceptHelp':
    'Lorsque votre client appui sur ce bouton, il/elle est redirigé.e vers l’App Store ou le Play Store pour télécharger l’application Keenoa.',
  'newClient.preview.title': "Aperçu du courriel d'invitation",
  'newClient.preview.to': 'À: ',
  'newClient.preview.from': 'De: ',
  'newClient.preview.subject': 'Sujet: ',
  'newClient.preview.subjectRow':
    '{dietitianName}, votre nutritionniste, vous invite sur Keenoa',
  'newClient.preview.hi': 'Bonjour!',
  'newClient.preview.first':
    "{dietitianName} vous invite à utiliser l’application mobile Keenoa, le journal alimentaire intelligent! Votre nutritionniste vous offre ce service de fine pointe afin de mieux vous aider à atteindre vos objectifs nutritionnels. Utilisez le bouton ci-dessous pour télécharger l'app et accepter l'invitation:",
  'newClient.preview.button': "Accepter l'invitation",
  'newClient.preview.second':
    "Si vous avez des questions, n'hésitez surtout pas à nous contacter. (Nous répondons très rapidement.) \n\nBienvenue,\nL'équipe Keenoa\n\n P.S. Besoin d'un coup de pouce pour débuter? Consultez notre <a href='https://keenoa.com/fr/client/how-it-works' target='_blank'>guide étape par étape</a>.",
  'newClient.preview.small':
    "Si vous avez de la difficulté avec le bouton ci-dessus, vous pouvez télécharger directement l'app sur le App Store ou le Play Store. Assurez-vous simplement d'utiliser cette adresse: {email} lors de la création de votre compte.",

  'newClient.preview.tite': 'Aperçu',

  'widgets.clientInformation.firstName': 'Prénom:',
  'widgets.clientInformation.lastName': 'Nom:',
  'widgets.clientInformation.title': 'Information personnelle',
  'widgets.clientInformation.gender': 'Sexe:',
  'widgets.clientInformation.birthDate': 'Date de naissance:',
  'widgets.dailyMacro.title': 'Apport en macronutriments',
  'widgets.dailyAverageDistribution.title': 'Distribution des macronutriments',
  'widgets.dailyAverageDistribution.empty': 'Distribution non disponible.',

  'export.title': 'Générer un rapport',
  'export.backTitle': 'Retour au journal',
  'report.settings.logo': 'Votre logo',
  'report.settings.colors': 'Vos couleurs',
  'report.settings.colors.principal': 'Primaire',

  'report.label': 'rapport',

  'reports.title': 'Rapports',
  'reports.actions.sendSuccess': 'Rapport envoyé à votre client.',
  'reports.status.finalized': 'Finalisé',
  'reports.status.unfinalized': 'En cours',
  'reports.search.placeholder': 'Recher par titre du rapport...',

  'createTemplate.header': 'Créer un gabarit de rapport',
  'createTemplate.subheader':
    'Identifiez votre gabarit pour facilement le retrouver lorsque vous en aurez besoin.',
  'createTemplate.create': 'Créer',
  'createTemplate.success': 'Gabarit , « {emoji} {title} » créé avec succès!',
  'createTemplate.header.update': 'Modifier le gabarit',
  'createTemplate.create.update': 'Mettre à jour',
  'createTemplate.header.duplicate': 'Dupliquer le gabarit',
  'createTemplate.create.duplicate': 'Dupliquer',
  'createTemplate.succes.duplicate':
    'Gabarit « {emoji} {title} » créé avec succès!',
  'createTemplate.success.update':
    'Gabarit {title}{emoji} mit à jour avec succès!',
  'createTemplate.title': 'Titre',
  'createTemplate.description': 'Description',
  'createTemplate.icon': 'Icône',

  'export.createTemplate.onboard.title': 'Créer un gabarit ⏳',
  'export.createTemplate.onboard.content':
    'Sauvez du temps en créant un gabarit de rapport. Vous pourrez ensuite l’utiliser pour rapidement exporter des rapports pour des suivis ou pour d’autres clients.',
  'export.createTemplate.onboard.close': 'Go',

  'templateActions.delete.header': 'Supprimer le gabarit?',
  'templateActions.delete.explanation':
    "Le gabarit sera supprimé de manière permanente. Vous ne pourrez plus l'utiliser pour générer des rapports.",
  'templateActions.delete.confirm': 'Oui, supprimer le gabarit',
  'templateActions.delete.cancel': 'Non, conserver le gabarit',
  'templateActions.edit': 'Modifier',
  'templateActions.delete': 'Supprimer',
  'templateActions.duplicate': 'Dupliquer',

  'rowActions.delete.header': 'Supprimer le rapport?',
  'rowActions.delete.explanation':
    'Le rapport sera supprimé de manière permanente.',
  'rowActions.delete.confirm': 'Oui, supprimer le rapport',
  'rowActions.delete.cancel': 'Non, conserver le rapport',
  'rowActions.delete': 'Supprimer',
  'rowActions.download': 'Télécharger',
  'rowActions.view': 'Voir dans un onglet',
  'rowActions.send': 'Envoyer à {name}',

  'finalizeForm.submit': 'Finaliser le raport',
  'finalizeForm.cancel': 'Retour à la création',
  'finalizeForm.warning':
    'Une fois finalisé, le rapport ne peut plus être modifié. Vous pourrez toutefois le dupliquer et modifier cette nouvelle version.',
  'finalizeForm.title': 'Titre',
  'finalizeForm.help':
    'Choisissez un titre qui vous permettra de facilement retrouver votre rapport.',

  'templateLinks.update': 'Mettre à jour ',
  'templateLinks.create': 'Nouveau gabarit',

  'clientList.inviteInstruction':
    "Appuyer sur ce bouton pour inviter un client à télécharger l'application Keenoa.",
  'clientList.addParticipantsButton': 'Ajouter des participants',

  'logo.idle':
    'Glisser & déposer votre logo ou <b> appuyer pour parcourir </b>',
  'logo.hover': "Glissez l'image ici...",
  'logo.loading': 'Téléversement...',
  'logo.remove': 'Appuyer pour supprimer',
  'logo.invalid':
    'Erreur lors du télévesement, seules les images en format png ou jpg sont acceptées.',

  'widgets.nutrientsSummary.title':
    'Tableau de références et apport en nutriments',

  'widgets.nutrientsSummary.basic': 'Apports',
  'widgets.nutrientsSummary.dri': 'Références',
  'widgets.nutrientsSummary.rdaOrAi': 'ANR/AS* (1)',
  'widgets.nutrientsSummary.rdaHelp':
    "(1) Apport nutritionnel recommandé(ANR): L'apport nutritionnel quotidien moyen permettant de combler les besoins nutritionnels de la quasi-totalité (97 à 98 %) des sujets en bonne santé. L'apport suffisant (AS) représente l'objectif de consommation usuelle chez les individus lorsqu'on ne peut pas fixer d'ANR pour un nutriment donné.",
  'widgets.nutrientsSummary.ul': 'AMT (2)',
  'widgets.nutrientsSummary.ulHelp':
    "(2) Apport maximal tolérable (AMT): L'apport quotidien continu le plus élevé qui ne comporte vraisemblablement pas de risques d'effets indésirables pour la santé chez la plupart des membres d' un groupe donné.",

  'widgets.nutrientsSummary.advanced': 'Avancé',
  'widgets.nutrientsSummary.average': 'Moyenne',
  'widgets.nutrientsSummary.min': 'Min.',
  'widgets.nutrientsSummary.max': 'Max.',
  'sections.summaryANR': 'Apport nutritionnel recommandé (ANT)',
  'sections.summaryAMT': 'Apport maximal tolérable (AMT)',
  'sections.summaryAverage': 'Moyenne',
  'sections.summaryMin': 'Min.',
  'sections.summaryMax': 'Max.',
  'widgetsConfigs.nutrientsSummary.reference': 'Références',
  'widgetsConfigs.nutrientsSummary.intakes': 'Apports',
  'widgetsConfigs.nutrientsSummary.sections': 'Sections',
  'widgetsConfigs.nutrientsSummary.edit': 'Modifier',

  'widgetsConfigs.clientInformation.sections': 'Sections',
  'widgetsConfigs.dailyAverage.sections': 'Sections',
  'sections.infoFirstName': 'Prénom',
  'sections.infoLastName': 'Nom de famille',
  'sections.infoBirthdate': 'Date de naissance',
  'sections.infoGender': 'Sexe',

  'sections.dailProt': 'Protéine',
  'sections.dailyCarb': 'Glucide',
  'sections.dailyFat': 'Lipide',
  'sections.dailyAlcohol': 'Alcool',
  'sections.dailyCal': 'Énergie',

  'nutrientsModal.configTitle': 'Choix des nutriments',
  'nutrientsModal.fats': 'Lipide',
  'nutrientsModal.proteins': 'Protéine',
  'nutrientsModal.sugars': 'Glucide',
  'nutrientsModal.vitamins': 'Vitamines',
  'nutrientsModal.others': 'Autres',
  'nutrientsModal.save': 'Enregistrer',
  'nutrientsModal.placeholder': 'Nom du nutriment...',
  'nutrientsModal.selectAll': 'Tout sélectionner',
  'nutrientsModal.unselectAll': 'Tout désélectionner',

  'widgetsConfigs.nutrientsSummary.nutrients': 'Nutriments',

  'widgets.dailyMarco.protein': 'Prot.',
  'widgets.dailyMacro.fat': 'Lipide',
  'widgets.dailyMacro.carbs': 'Glucide',
  'widgets.dailyMacro.alcohol': 'Alcool',
  'widget.dailyMacro.calories': 'Énergie',

  'widget.richtext.placeholder':
    'Ajoutez une note, un commentaire ou une section...',
  'widget.richText.tooLong':
    'Cette note est trop longue. Veuillez ajouter une nouvelle section et y copier une partie de cette note.',
  'widget.visualOverview.morning': 'Repas | Avant-midi ☕',
  'widget.visualOverview.afternoon': 'Repas | Après-midi ☀️',
  'widget.visualOverview.evening': 'Repas | Soirée 🌃',

  'widget.remove.confirm.title': 'Supprimer la section',
  'widget.remove.confirm.explanation':
    'Voulez-vous vraiment supprimer cette section de votre rapport ?',
  'widget.remove.confirm.confirm': 'Supprimer la section',
  'widget.remove.confirm.cancel': 'Conserver la section',

  'widgetConfig.title': 'Configuration du contenu',
  'widgetConfig.visualOverview.startTime': 'Heure de début de la période',
  'widgetConfig.visualOverview.endTime': 'Heure de fin de la période',
  'widgetConfig.visualOverview.invalidTime':
    "L'heure de début doit être avant l'heure de fin.",
  'widgetConfig.visualOverview.tooLargeSpan':
    'La plage horaire doit être inférieur ou égale à 12 heures.',

  'contentTab.summary.title': 'Résumé',
  'contentTab.organization.title': 'Organisation',
  'contentTab.summary.dailyMacro': 'Apport en macros.',
  'contentTab.summary.clientInfo': 'Information client',
  'contentTab.summary.dailyAverage': 'Distribution des macros.',
  'contentTab.summary.nutrientsSummary':
    'Tableau de références et apport en nutriments',
  'contentTab.recommendations.title': 'Recommandations',
  'contentTab.summary.tab': 'Note ou commentaire',
  'contentTab.summary.visualOverview': 'Calendrier des repas',
  'contentTab.dragWarning':
    'Pour ajouter du contenu, glissez le carré sur la page.',

  'report.settings.locale': 'Langue du rapport',

  'export.top.saved': 'Enregistré',
  'export.top.saving': 'Enregistrement en cours...',

  'export.tab.content': 'Contenu',
  'export.tab.design': 'Désign',
  'export.tab.design.onboard.title': 'Votre image de marque 🎨',
  'export.tab.design.onboard.content':
    'Personnalisez vos rapports en ajoutant votre logo et vos couleurs.',
  'export.tab.design.onboard.close': 'Parfait',
  'export.tab.days': 'Journées',
  'export.tab.days.calendar': 'Journées sélectionnées',
  'export.tab.days.fictive':
    'Un client fictif est pré-sélectionné pour vous aider à créer et visualiser votre gabarit.',
  'export.page.placeholder':
    "💡<b>Glissez</b> les carrés à partir de <b>l'onglet contenu</b> et déposez-les <b>ici</b> pour créer votre rapport.",

  'footer.builtWith': 'Conçu avec',
  'footer.of': 'de',
  'widget.macrotable.average': 'Moyenne',

  'login.welcome': 'Connexion à Keenoa',
  'global.email': 'Courriel',
  'global.password': 'Mot de passe',
  'global.ok': 'Ok',
  'global.submit': 'Terminer',
  'global.empty': ' ',
  'login.forgotPassword': 'Mot de passe oublié ?',
  'login.loginButton': 'Connexion',
  'newClient.form.title': 'Inviter un client.',
  'newClient.form.info':
    "Un courriel lui sera envoyé afin qu'il vous donne accès à son journal alimentaire.",
  'newClient.form.fr': 'Français',
  'newClient.form.en': 'Anglais',
  'newClient.form.language': "Langue d'invitation",
  'newClient.form.inviteBtn': 'Inviter',
  'newClient.confirm.title': 'Invitation envoyée!',
  'newClient.confirm.to': 'à: ',
  'newClient.form.renewTip':
    'Astuce: Vous pouvez inviter un client à nouveau directement en le sélectionnant dans votre liste.',
  'newClient.form.renewWarning':
    "Il semblerait qu'il existe déja une invitation avec ce courriel, désirez-vous la réenvoyer ?",
  'newClient.form.renewBtn': 'Réenvoyer',
  'newClient.form.inviteLoading': 'Envoit...',
  'newClient.form.preview': 'Aperçu',
  'clientList.noClient.title': 'Voulez-vous ajouter un nouveau client ?',
  'clientList.noClient.hint':
    "Astuce: Ajoutez-vous comme client afin d'explorer la plateforme.",
  'clientList.noClient.addBtn': 'Ajouter',
  'clientList.searchHint': 'Quel client cherchez-vous ?',
  'clientList.addBtn': 'Nouveau client',

  'client.backTitle': 'Retour aux clients',
  'client.export': 'Exporter',
  'client.archive': 'Archiver',
  'client.remove': 'Supprimer',
  'client.reInvite': "Réenvoyer l'invitation",
  'client.unarchive': 'Restaurer',
  'client.noActions': 'Aucune action possible',
  'client.archived': 'Archivé',
  'client.declined': 'Refusé',
  'clients.filter.all': 'Tous les clients',
  'clients.filter.allBut': 'Clients non archivés',
  'clients.filter.active': 'Clients actifs',
  'clients.filter.pending': 'Clients en attentes',
  'clients.filter.archived': 'Clients archivés',
  'clients.filter.revoked': 'Clients révoqués',
  'client.selected': 'client sélectionné',
  'client.selecteds': 'clients sélectionnés',
  'clients.firstName': 'Prénom',
  'clients.learnerName': "Nom de l'étudiant",
  'clients.courses': 'Cours',
  'clients.lastName': 'Nom',
  'clients.acceptDate': "Date d'acceptation",
  'clients.filter.courses.all': 'Tous les cours',
  'clients.filter.studies.all': 'Toutes les études',

  'client.profile.mobileSubtitle':
    'Vous pouvez choisir les fonctionnalités que vous désirez activer dans l’application mobile de votre client.',
  'client.profile.questionActive': "Questions d'alimentation intuitive",
  'client.profile.questionActiveHelp':
    "Vous pouvez activer ou désactiver les questions d'alimentation intuitive (signaux de satiété, etc).",
  'client.profile.diaryTitle': "Paramètres de l'application mobile du client",
  'client.profile.activeNutrients': 'Nutriments activés (max. 4)',
  'client.profile.activeNutrientsHelp':
    'Seulement les nutriments activés sont accessibles/visibles à partir de l’application mobile Keenoa de votre client.',
  'client.profile.nutrients.title': 'Nutriments actifs pour votre client',
  'client.profile.edit': 'Modifier',

  'dri.rda': 'ANR/AS*',
  'dri.rda.help': `<b><a href='https://www.canada.ca/fr/sante-canada/services/aliments-nutrition/saine-alimentation/apports-nutritionnels-reference/tableaux.html' target='_blank'>Apport nutritionnel recommandé(ANR)</a></b>: L'apport nutritionnel quotidien moyen permettant de combler les besoins nutritionnels de la quasi-totalité (97 à 98 %) des sujets en bonne santé.
  <b><a href='https://www.canada.ca/fr/sante-canada/services/aliments-nutrition/saine-alimentation/apports-nutritionnels-reference/tableaux.html' target='_blank'>Apport suffisant(AS) </a></b>: on fixe un AS  lorsqu'on ne dispose pas de données scientifiques suffisantes pour fixer un ANR. Il est fixer à un niveau qui semble assurer un statut nutritionnel adéquat.`,
  'dri.ul': 'AMT',
  'dri.ul.help': `<b><a href='https://www.canada.ca/fr/sante-canada/services/aliments-nutrition/saine-alimentation/apports-nutritionnels-reference/tableaux.html' target='_blank'>Apport maximal tolérable (AMT)</a></b>: L'apport quotidien continu le plus élevé qui ne comporte vraisemblablement pas de risques d'effets indésirables pour la santé chez la plupart des membres d' un groupe donné.`,
  'dri.profile.title': `Modifier le profile de {firstName}`,
  'dri.profile.explanation': `Nous avons besoin du sexe et de l'age de votre client pour calculer les <a href='https://www.canada.ca/fr/sante-canada/services/aliments-nutrition/saine-alimentation/apports-nutritionnels-reference/tableaux.html' target='_blank'>apports nutritionnels de référence (ANREF)</a>.`,
  'dri.profile.sex': 'Sexe :',
  'dri.profile.done': 'Modifier le profile',
  'dri.profile.birthdate': 'Date de naissance :',
  'dri.profile.day': 'Jour',
  'dri.profile.month': 'Mois',
  'dri.profile.year': 'Année',
  'dri.profile.lactation': 'Lactation',
  'dri.profile.pregnancy': 'Grossesse',
  'dri.profile.woman': 'Femme',
  'dri.profile.man': 'Homme',
  'dri.profile.other': 'Autres',
  'dri.noProfile.0':
    'Pour voir les apports nutritionnels de référence (ANREF),',
  'dri.noProfile.1': 'le profile de votre client.',
  'dri.noProfile.edit': 'modifiez',
  'foodLog.dri': 'ANREF',

  'clients.archiveSuccess': 'Archivage réussi.',
  'clients.archiveSuccessP': '{count} archivages réussis.',
  'clients.removeSuccess': 'Requête supprimée.',
  'clients.removeSuccesP': '{count} requêtes supprimées.',
  'clients.unarchiveSuccess': "Client retiré de l'archive.",
  'clients.unarchiveSuccessP': "{count} clients retirés de l'archive.",
  'clients.reinviteSuccess': 'Invitation réenvoyée.',
  'clients.reinviteSuccessP': '{count} invitations rèenvoyées.',
  'clients.title': 'Mes clients',

  'clients.performance.noActive':
    "Aucun client n'a capturé de repas dans les derniers 30 jours.",
  'clients.performance.yAxisTitle': 'Nombre total de repas par jour',
  'clients.performance.title': "Niveau d'activité de mes clients",
  'clients.performance.help':
    "Ce graphique illustre le nombre de repas ajoutés par vos clients chaque jour pour les 30 derniers jours. Vous pouvez l’utiliser pour identifier vos meilleurs clients ou pour trouver ceux qui bénéficieraientt d'encouragements. Travaillez avec vos clients pour accroitre leur utilisation et suivre votre rétention.",
  'clients.performance.error':
    "Une erreur s'est produite lors du téléchargement de l'activité de vos clients.",
  'clients.performance.retry': 'Appuyez pour réessayer',

  'welcome.header': 'Bienvenue dans votre interface Keenoa',
  'welcome.content':
    "Nous avons pris le soin de vous ajouter un client fictif pour vous permettre d'explorer l'interface.",
  'welcome.footer': 'Bonne découverte',

  'paymentDue.header': 'Carte de crédit requise',
  'paymentDue.content':
    "Votre essai gratuit est maintenant terminé. Pour continuer d'utiliser la plateforme, veuillez ajouter une carte de crédit à votre compte.",
  'paymentDue.continue': 'Continuer',

  'signup.welcome': 'Inscription',
  'signup.board': 'Ordre professionnel',
  'signup.name': 'Nom complet',
  'signup.submitBtn': "S'inscrire",
  'signup.smallTerms': 'En vous inscrivant, vous acceptez les ',
  'signup.terms': "Conditions d'utilisation",
  'signup.smallTermsMiddle': ' et les ',
  'signup.subscriptionTerms': "Conditions d'inscription et d'abonnement",
  'signup.invalidError': 'Le courriel entré est invalide ou déjà utilisé.',
  'signup.usedError': 'Le courriel entré est déjà utilisé.',
  'signup.boardName': 'Nom de votre ordre professionnel',
  'signup.permitNumber': 'Numéro de licence*',
  'signup.permitNumberExplanation':
    '*Nous nécéssitons de cette information afin de valider votre statut professionnel.',
  'signup.aboutYou': 'Nutritionniste',
  'signup.credentials': 'Identifiants de connexion',
  'signup.logIn': 'Connexion',
  'signup.existingAccount': 'Déja un compte Keenoa ?',

  'beginPasswordReset.title':
    'Récupération du mot de passe de mon compte Keenoa.',
  'beginPasswordReset.sendBtn': 'Rechercher',
  'beginPasswordReset.wrongEmail':
    "Nous n'avons pas réussi à trouver votre compte avec ce courriel.",
  'beginPasswordReset.sentTitle': 'Regardez vos courriels.',
  'beginPasswordReset.sentMessage':
    'Nous avons envoyé un courriel à {email}. Appuyez sur le lien dans le courriel pour réinitialiser votre mot de passe.',
  'beginPasswordReset.wrongEmailText':
    'Veuillez rechercher votre courriel à nouveau.',
  'completePasswordReset.title': 'Réinitialisez votre mot de passe.',
  'completePasswordReset.sendBtn': 'Réinitialiser',
  'completePasswordReset.paragraph': 'Veuillez saisir un nouveau mot de passe.',
  'completePasswordReset.expiredToken': 'Oups, votre demande est expirée.',
  'completePasswordReset.sentTitle':
    'Génial! Votre mot de passe a été mis à jour.',
  'completePasswordReset.linkToLoginBtn': 'Connexion',
  'completePasswordReset.expiredTokenMessage':
    'Veuillez envoyer une nouvelle requête.',
  'completePasswordReset.linkToResetBtn': 'Nouvelle demande',
  'foodLog.breakfast': 'Déjeuner',
  'foodLog.morningSnack': "Collation d'avant-midi",
  'foodLog.lunch': 'Diner',
  'foodLog.afternoonLunch': "Collation d'après-midi",
  'foodLog.dinner': 'Souper',
  'foodLog.afterDinner': 'Collation de soirée',
  'foodLog.anytime': 'Autre',

  'foodLog.fat': 'Lipides',
  'foodLog.cholesterol': 'Cholestérol',
  'foodLog.monounsatured': 'Monoinsaturés',
  'foodLog.polyunsaturated': 'Polyinsaturés',
  'foodLog.omega3': 'Oméga-3',
  'foodLog.omega6': 'Oméga-6',
  'foodLog.ala': '18:3n-3 (ALA)',
  'foodLog.dha': '22:6n-3 (ADH)',
  'foodLog.epa': '20:5n-3 (EPA)',
  'foodLog.saturated': 'Saturés',
  'foodLog.transFat': 'Trans',
  'foodLog.transMono': 'Trans monoénoïques',
  'foodLog.transPoly': 'Trans polyénoïques',

  'foodLog.carbs': 'Glucides',
  'foodLog.starch': 'Amidon',
  'foodLog.sucrose': 'Sucrose',
  'foodLog.glucose': 'Glucose',
  'foodLog.fructose': 'Fructose',
  'foodLog.lactose': 'Lactose',
  'foodLog.maltose': 'Maltose',
  'foodLog.sugar': 'Sucre',
  'foodLog.galactose': 'Galactose',
  'foodLog.fiber': 'Fibre',
  'foodLog.monosaccarides': 'Monosaccharides',
  'foodLog.disaccharides': 'Disaccharides',

  'foodLog.alcohol': 'Alcool',
  'foodLog.calories': 'Énergie',

  'foodLog.calcium': 'Calcium',
  'foodLog.iron': 'Fer',

  'foodLog.magnesium': 'Magnésium',
  'foodLog.phosphorus': 'Phosphore',
  'foodLog.potassium': 'Potassium',
  'foodLog.zinc': 'Zinc',
  'foodLog.copper': 'Cuivre',
  'foodLog.manganese': 'Manganèse',
  'foodLog.selenium': 'Sélénium',
  'foodLog.vit_a': 'Vit. A (RAE)',
  'foodLog.retinol': 'Retinol',
  'foodLog.beta_carotene': 'Bêta carotène',
  'foodLog.alpha_carotene': 'Alpha carotène',
  'foodLog.alpha_tocopherol': 'Vit. E',
  'foodLog.vitamin_d': 'Vit. D',
  'foodLog.vitamin_d2_ergocalciferol': 'Vit. D ergocalciférol',
  'foodLog.vitamin_d_d2_and_d3': 'Vit. D (D2 + D3)',
  'foodLog.beta_cryptoxanthin': 'Bêta cryptozanthine',
  'foodLog.lycopene': 'Lycopène',
  'foodLog.lutein_and_zeaxanthin': 'Lutéine and zéaxanthine',
  'foodLog.beta_tocopherol': 'Bêta-tocophérol',
  'foodLog.gamma_tocopherol': 'Gamma-tocophérol',
  'foodLog.delta_tocopherol': 'Delta-tocophérol',
  'foodLog.vitamin_c': 'Vit. C',
  'foodLog.thiamin': 'Thiamine',
  'foodLog.riboflavin': 'Riboflavine',
  'foodLog.niacin_preformed': 'Niacine préformée',
  'foodLog.total_niacin_equivalent': 'Équivalent niacine',
  'foodLog.pantothenic_acid': 'Acide pantothénique',
  'foodLog.vitamin_b_6': 'Vit. B6',
  'foodLog.biotin': 'Biotine',
  'foodLog.total_folacin': 'Folacine',
  'foodLog.vitamin_b_12': 'Vit. B12',
  'foodLog.choline__total': 'Choline',
  'foodLog.vitamin_k': 'Vit. K',
  'foodLog.folic_acid': 'Acide folique',
  'foodLog.naturally_occurring_folate': 'Folate naturel',
  'foodLog.dietary_folate_equivalents': 'Folate, EFA',
  'foodLog.betaine': 'Bétaïne',
  'foodLog.alpha_tocopherol__added': 'Vit. E ajoutée',
  'foodLog.vitamin_b12__added': 'Vit. B12 ajoutée',
  'foodLog.tryptophan': 'Tryptophane',
  'foodLog.threonine': 'Thréonine',
  'foodLog.isoleucine': 'Isoleucine',
  'foodLog.leucine': 'Leucine',
  'foodLog.lysine': 'Lysine',
  'foodLog.methionine': 'Méthionine',
  'foodLog.cystine': 'Cystine',
  'foodLog.phenylalanine': 'Phénylalanine',
  'foodLog.tyrosine': 'Tyrosine',
  'foodLog.valine': 'Valine',
  'foodLog.arginine': 'Arginine',
  'foodLog.histidine': 'Histidine',
  'foodLog.alanine': 'Alanine',
  'foodLog.aspartic_acid': 'Acide aspartique',
  'foodLog.glutamic_acid': 'Acide glutamique',
  'foodLog.glycine': 'Glycine',
  'foodLog.proline': 'Proline',
  'foodLog.serine': 'Serine',
  'foodLog.hydroxyproline': 'Hydroxyproline',
  'foodLog.sodium': 'Sodium',
  'foodLog.aspartame': 'Aspartame',
  'foodLog.water': 'Eau',
  'foodLog.protein': 'Protéines',
  'foodLog.ns_step': 'PAS',
  'foodLog.ns_green_step': 'PAS Vert',

  'global.prefixMister': 'M {name}',
  'global.prefixMiss': ' Mme {name}',
  'userBadge.settingsLink': 'Mes configurations...',
  'userBadge.logoutLink': 'Déconnexion',
  'settings.localeEn': 'English',
  'settings.localeFr': 'Francais',
  'settings.title': 'Configuration de mon profil',
  'settings.timeFormat': 'Format horaire',
  'pendingClients.title': 'Requêtes en attente',
  'clientList.title': 'Mes clients',
  'foodLog.ageLabel': 'ans',
  'foodLog.weightLabelKg': 'kg',
  'foodLog.heightLabelCm': 'cm',
  'foodLog.totalRow': 'Total :',
  'foodLog.averageRow': 'Moyenne:',
  'foodLog.stdevRow': 'Écart type:',
  'foodLog.compareMin': 'Min:',
  'foodLog.compareMax': 'Max:',
  'foodLog.coefVar': 'Coefficient de variation:',
  'foodLog.coefVarHelp':
    "Le coefficient de variation ou l’écart type relatif est une mesure de dispersion relative. Il vous permet de comparer la variation de chacun des nutriments, c.-à-d. que le nutriment ayant le coefficient de variation le plus élevé à la plus grande variation relative entre les différentes journées. Pour plus d’information, vous pouvez lire <a href='https://fr.wikipedia.org/wiki/Coefficient_de_variation' target='_blank'>ceci</a>.",
  'foodlog.stdevHelp':
    "L’écart type est mesure une de la dispersion des nombres dans une distribution. Pour un nutriment, un écart type élevé implique que la quantité consommée de ce nutriment diffère beaucoup entre les journées sélectionnées. Pour plus de détails, voir <a href='https://fr.wikipedia.org/wiki/%C3%89cart_type' target='_blank'>ce lien</a>.",
  'foodDiary.toolbar.meal': 'repas',
  'foodDiary.toolbar.meals': 'repas',
  'foodDiary.toolbar.verified': 'Vérifier la journée: ',
  'foodDiary.toolbar.verifiedCheck': 'Journée vérifiée: ',
  'foodDiary.toolbar.verifiedTitle': 'Journée vérifiée 👌',
  'foodDiary.toolbar.verifiedHelp':
    'Prenez note des journées complètes que vous révisez en cochant la case. Une fois vérifiée, la journée sera marquée avec un rond vert dans le calendrier.',
  'foodDiary.toolbar.days': 'journées sélectionnées',
  'foodDiary.toolbar.day': 'journée sélectionnée',
  'foodDiary.toolbar.compareTitle': 'Mode de comparaison ',
  'foodDiary.compareSwitch': 'Mode de comparaison',
  'foodDiary.compareSwitch.onboard.title': 'Mode de comparaison',
  'foodDiary.compareSwitchHelp':
    'Activez ce mode pour comparer et agréger les valeurs nutritionnelles de plusieurs journées. Dans le journal détaillé, vous pourrez voir les moyennes, min, max, etc. Dans le journal visuel, vous pourrez voir les photos de repas pour chaque journée côte à côte.',
  'foodLog.basic': 'Essentiel',
  'foodLog.advanced': 'Avancé',

  'foodModal.creator.submit': 'Sauvegarder',
  'foodModal.picker.submitting': 'Sauvegarde...',
  'foodModal.creator.ingredients': 'Ingrédients / options: ',
  'foodModal.creator.help': 'Veuillez sélectionner les options nécessaires',
  'foodModal.creator.error':
    'Oups, une erreur inattendue s’est produite. Veuillez réessayer ou contacter notre support à la clientèle pour recevoir de l’aide.',
  'searchFood.creator': 'créateur',

  'login.signUp': 'Créer un compte',
  'login.new': 'Nouveau sur Keenoa ?',
  'client.active': 'Actif',
  'client.inactive': 'Inactif',
  'client.pending': 'En attente',
  'client.revoked': 'Révoqué',
  'client.fictive': 'Fictif',
  'client.sent': 'Envoyé',

  'client.foodLogButton': 'Journal alimentaire',
  'client.sendReminderButton': 'Envoyer la requête de nouveau',
  'clients.client': 'Client',
  'clients.email': "Courriel d'invitation",
  'clients.status': 'Statut',
  'clients.participantId': 'ID du participant',

  'selectStudyModal.header': 'Choisir un étude',
  'selectStudyModal.content':
    "Veuillez choisir l'étude pour laquelle vous désirez ajouter des participants.",
  'selectStudyModal.newStudy': '➕ Créer une nouvelle étude',
  'selectStudyModal.selectLabel': 'Étude',
  'selectStudyModal.next': 'Continuer',

  'newStudyModal.header': 'Nouvelle étude',
  'newStudyModal.content':
    'Veuillez choisir un code de 3 lettres et un nom pour votre étude. Vous pourrez inviter et classifier vos participants par étude.',
  'newStudyModal.code': 'Code',
  'newStudyModal.name': 'Nom',
  'newStudyModal.create': 'Créer',
  'newStudyMOdal.creating': 'Création en cours...',

  'participantCountModal.header': 'Nombre de participants',
  'participantCountModal.content':
    "Veuillez saisir le nombre d’identifiants que vous souhaitez générer pour votre étude, c'est-à-dire le nombre de participants que vous prévoyez recruter. Vous pouvez générer jusqu'à 100 identifiants à la fois, mais vous pouvez générer des identifiants additionnels à tout moment.",
  'participantCountModal.content1':
    'IMPORTANT: veuillez vous assurer de fournir un identifiant unique (généré par ce processus) à chacun de vos participants, de l’associer vous-même à leurs coordonnées respectives et de conserver cette information dans vos documents sécurisés.',
  'participantCountModal.content2':
    "Après avoir téléchargé l'application Keenoa sur leur téléphone, vos participants seront invités à entrer leur identifiant. Cela permettra la connexion entre votre compte de chercheur Keenoa et leur journal alimentaire mobile.",
  'participantCountModal.count': 'Nombre de participants',
  'participantCountModal.generate': 'Générer',
  'participantCountModal.generating': 'Génératio nen cours...',
  'participantCountModal.help': 'Max 100.',

  'editEntry.updateBtn': 'Modifier',
  'editEntry.foodNamePlaceholder': "Nom de l'aliment",
  'editEntry.title': 'Modifier un aliment',
  'editEntry.titleNew': 'Ajouter un aliment',
  'addFoodForm.addBtn': 'Ajouter',
  'addFood.searchHint': 'Recherche',
  'login.error':
    'Désolés, nous n’avons pu trouver votre compte avec ce courriel et ce mot de passe.',
  'dashboard.menu.clients': 'Clients',
  'dashboard.menu.templates': 'Gabarits de rapports',
  'dashboard.menu2.detailed': 'Détaillé',
  'dashboard.menu2.visualLog': 'Visuel',
  'dashboard.menu2.profile': 'Mobile',
  'dashboard.menu2.back': 'Retour au journal alimentaire',
  'foodDiary.noDiaryAvailableTitle': 'Aucune photo',
  'foodDiary.noDiaryAvailableText':
    "Votre client n'a pas encore capturé de photos de son alimentation. C'est ici que vous pourrez voir son journal alimentaire.",
  'comingSoon.comingSoon': 'Bientôt disponible!',
  'comingSoon.infoDetailed':
    'Ici, vous pourrez voir les informations détaillées sur les nutriments consommés par vos clients.',
  'comingSoon.exciting': 'Excitant non !?',
  'comingSoon.suggestions':
    'Des suggestions sur ce dont vous avez besoin? Envoyez un courriel à ',
  'entries.addEntry': 'Ajouter un aliment',
  'entries.noEntry': 'Aucun aliment disponible',
  'toolsBox.title': 'Modifier ma boîte à nutriments',
  'entries.addEntryHelp': "L'aliment est lié à: ",
  'entries.viewMore': 'Voir tous les aliments',
  'entries.viewLess': "Voir moins d'aliments",

  'dayChart.header': 'Distribution totale',
  'dayChart.compareHeader': 'Distribution moyenne journalière',
  'compare.visualOverview': 'Aperçu visuel',
  'account.menu2.title': 'Compte personnel',
  'account.menu2.general': 'Général',
  'account.menu2.billing': 'Facturation',

  'editName.title': 'Modifier votre nom',
  'editName.firstName': 'Prénom',
  'editName.lastName': 'Nom',
  'editName.button': 'Modifier le nom',

  'general.on': 'Activé',
  'general.off': 'Désactivé',
  'general.personnalInfo': 'Général',
  'general.basics.name': 'Nom',
  'general.basics.language': 'Langue',
  'general.edit': 'Modifier',
  'general.timeformat.title': 'Choisir le format des heures :',
  'general.timeformat.subtitle':
    "Vous pouvez sélectionner le format d'heure qui vous convient le mieux.",

  'editLocale.title': 'Choisissez une langue :',

  'billing.title': 'Facturation',
  'billing.checkoutButton': 'Sauvegarder',
  'billing.name': 'Nom sur la carte',
  'addSource.header': 'Ajouter une carte de crédit',
  'billing.expiredError':
    'La carte de crédit utilisée est expirée. Veuillez réessayer avec une carte différente.',
  'billing.incorectCVC':
    'Le code de sécurité (CVV) soumis est incorrect. Veuillez reviser votre code de sécurité.',
  'billing.incorectZIP':
    'Le code postal entré est incorrect. Veuillez reviser votre code postal.',
  'billing.cardDeclined':
    'La carte de crédit utilisée a été déclinée. Veuillez réessayer avec une carte différente.',
  'billing.processingError':
    'Une erreur est survenue lors du traitement de votre carte de crédit. Veuillez réessayer avec une carte différente.',
  'billing.savingCard': 'Enregistrement...',
  'billing.unknownError':
    "Désolés, une erreur inconnue est survenue. Veuillez essayer de nouveau. Si l'erreur persiste, vous pouvez rejoindre notre équipe de support (support@keenoa.co).",
  'billing.sourcesHeader': 'Cartes de crédit',
  'billing.addPaymentBtn': 'Ajouter une carte',
  'billing.invoicesHeader': 'Historique des paiements',
  'billing.noSources': 'Aucune carte de crédit',
  'billing.expires': 'Expiration',

  'testClient.header': 'Client fictif',
  'testClient.explanation':
    "Les clients fictifs vous permettent d'explorer la plateforme. Par contre, il n'est pas possible de modifier le journal d'un client fictif.",
  'testClient.opening':
    'Lorsque vous aurez invité vos clients sur la plateforme, vous aurez le plein contrôle de leurs journaux alimentaires.',
  'testClient.invite': 'Inviter un client',

  'revokedClient.header': 'Client révoqué',
  'revokedClient.explanation':
    'Ce client a décidé de révoquer votre accès à ses informations.',
  'revokedClient.opening':
    "Vous pouvez toujours consulter les journaux alimentaires des journées précédentes à la date de révocation d'accès. Par contre, vous ne pouvez pas les modifier et vous ne receverez pas les nouvelles informations de ce client.",
  'revokedClient.continue': 'Continuer',

  'messages.viewMore': 'Voir plus de commentaires',
  'messages.viewLess': 'Voir moins de commentaires',
  'messages.send': 'Envoyer',
  'messages.sendPlaceholder': 'Écrire un commentaire...',
  'messages.sendHelp': 'Appuyez sur Enter pour envoyer',
  'messages.paragraphHelp':
    'Appuyez sur Shift+Enter pour ajouter un paragraphe',

  'toolbox.title': 'Boîte à nutriments',
  'toolbox.instruction':
    'Cliquez sur un nutriment d’intérêt pour l’ajouter au tableau ci-dessous.',

  'answers.why.0': 'Ennui',
  'answers.why.1': 'Stressé',
  'answers.why.2': 'Triste',
  'answers.why.3': 'Social',
  'answers.why.4': 'C’est l’heure ',
  'answers.why.5': 'Fatigué',
  'answers.why.6': 'Faim',
  'answers.why.7': 'Pourquoi pas ?',
  'answers.why.8': 'Soif',

  'answers.how.0': 'Ordinaire',
  'answers.how.1': 'Bon',
  'answers.how.2': 'Génial',

  'answers.feel.0': 'Heureux',
  'answers.feel.1': 'Coupable',
  'answers.feel.2': 'Satisfait',
  'answers.feel.3': 'Décu',

  'answers.hungriness.0': 'Affamé',
  'answers.hungriness.1': 'Un peu faim',
  'answers.hungriness.2': 'Pas faim',
  'answers.hungriness.3': 'Très faim',
  'answers.hungriness.4': 'Faim',

  'answers.fullness.0': 'Trop plein',
  'answers.fullness.1': 'Comfortable',
  'answers.fullness.2': 'Encore faim',
  'answers.fullness.3': 'Très plein',
  'answers.fullness.4': 'Plein',

  'questions.why': 'Pourquoi vous apprêtez-vous à manger ',
  'questions.how': 'Comment était votre repas/collation ?',
  'questions.feel': 'Comment vous sentez-vous ?',
  'questions.fullness': 'Quel est votre niveau de satiété ?',
  'questions.hungriness': 'Quel est votre niveau de faim ?',

  'mindful.help.title': 'Alimentation Intuitive (Aide)',
  'mindful.help.before':
    'Lorsque {firstName} prend une photo, nous lui posons ces questions :',
  'mindful.help.after':
    'Les questions suivantes sont configurées pour être remplies après le repas :',

  'meal.mindfullness': 'Alimentation Intuitive',
  'meal.description': 'Description',
  'meal.entries': 'Aliments',
  'meal.mindfullnes.after': 'Après :',
  'meal.mindfullness.before': 'Avant :',

  'meal.forgot.help':
    "Lorsque votre client oublie de prendre une photo, il peut tout de même ajouter l'information à son journal. Dans cette situation, la photo est remplacée par l'emoji de son choix.",

  'export.exportButton': 'Finaliser le rapport',
  'toolbar.button.export': 'Créer un rapport',
  'toolbar.button.verify': 'Vérifier',
  'toolbar.button.verified': 'Vérifiée',

  'export.modal.title': 'Exporter/partager le rapport',
  'export.modal.download': 'Télécharger le PDF',
  'export.modal.oneClick': 'Envoyer par courriel',
  'export.modal.email': 'Envoi en un clic à {firstName}',
  'export.modal.finalized': 'Rapport finalisé avec succès',
  'export.modal.finalizing': 'Finalisation du rapport en cours...',
  'export.modal.emailHelp':
    'Si vous appuyez sur ce bouton, un courriel contenant un lien sécurisé pour télécharger le rapport sera envoyé directement à votre client.',
  'export.modal.generating': 'Création du rapport en cours...',
  'export.modal.user': 'Retourner au journal',

  'templates.title': 'Mes gabarits de rapports',
  'templates.newButton': 'Nouveau gabarit',
  'templates.noTemplates.title':
    'Aucun gabarit, voulez-vous en créer un nouveau ?',
  'templates.noTemplates.new': 'Créer un gabarit',
  'templates.search': 'Chercher un gabarit',
  'templates.help':
    'Les gabarits vous permettent de sauvegarder et réutiliser la structure d’un rapport. Lors de l’export vous pourrez ainsi débuter à partir d’un gabarit au lieu d’une page blanche. Vous pouvez vous créer autant de gabarits que désiré; pour différents types de clientèle, pour vos dossiers ou encore pour l’envoyer à d’autres professionnels.',

  'pickTemplate.header': "Création d'un rapport",
  'pickTemplate.subheader':
    'Choisissez un de nos gabarits ou créer votre rapport de toutes pièces.',
  'pickTemplate.search.placeholder': 'Rechercher un gabarit...',
  'pickTemplate.base.title': 'Page blanche',
  'picktemplate.base.description':
    "Vous avez votre propre idée de rapport? Commencez à partir d'une page blanche.",
  'pickTemplate.reports.emptyTitle': 'Aucun rapport',
  'pickTemplate.reports.emptyText':
    'Lorsque vous finaliserez un rapport pour {name}, il apparaîtra ici.',
  'pickTemplate.templates.title': 'Créer un nouveau rapport',
  'pickTemplate.reports.title': "Poursuivre la création d'un rapport",
  'pickTemplate.previousReports.title': 'Dupliquer un rapport précédent',
  'pickTemplate.template.creation': `Nouveau rapport créé à partir du gabarit '{emoji} {title}'.`,
  'pickTemplate.header.downloaded': 'Téléchargé',
  'pickTemplate.header.template': 'Gabarit',
  'pickTemplate.header.sent': 'Envoyé',
  'pickTemplate.header.creationDate': 'Date de création',
  'pickTemplate.notify.continue': 'Reprise de la modification du rapport',
  'pickTemplate.notify.duplicate':
    "Nouveau rapport créé en dupliquant '{title}'",
  'pickTemplate.templateCard.mine': 'Vos rapports',
  'pickTemplate.cantFind': 'Vous ne trouvez pas le bon gabarit?',
  'pickTemplate.buildYours': 'Créer le vôtre',

  'reports.filter.all': 'Tous les rapports',
  'reports.filter.finalized': 'Rapports finalisés',
  'reports.filter.current': 'Rapports en cours',
  'reports.newReport': 'Nouveau rapport',
  'reports.columns.sent': 'Envoyé',
  'reports.columns.date': 'Date de création',
  'reports.columns.status': 'Statut',
  'reports.columns.title': 'Titre',
  'reports.columns.downloaded': 'Téléchargé',
  'reports.columns.options': ' ',

  'report.actions.delete': 'Supprimer',
  'report.actions.download': 'Télécharger',
  'report.actions.send': 'Envoyer',
  'report.actions.edit': 'Modifier',
  'report.actions.copyAndEdit': 'Copier & modifier',

  'builder.specialOptions.other': 'Plus...',

  'reports.noReports.title': 'Voulez-vous créer un rapport ?',
  'reports.noReports.hint':
    "Les rapports peuvent être utilisé pour vos dossiers, d'autres professionnels ou votre client. Générez un rapport en personnalisé en un instant.",
  'reports.noReports.addBtn': 'Créer un rapport',

  'builder.one': 'choisissez 1',
  'builder.atLeastOne': 'choisissez au moins 1',
  'builder.oneIfApplicable': 'choisissez si applicable',
  'builder.onlyIfApplicable': 'choisissez 1 si applicable',

  'report.defaultTitle': 'Rapport sans titre',

  'templates.export.backTitle': 'Retour aux gabarits',

  'csv.export.nutrients.title': 'Veuillez choisir les nutriments à exporter',
  'csv.export.nutrientsTitle': 'Choix de nutriments',
  'csv.export.selected': '{count} sélectionnés',
  'csv.export.configure': 'Configurer',
  'csv.export.confirm.title': 'Export envoyé',
  'csv.export.confirm.detail':
    "Nous préparons votre export. Vous receverez un courriel contenant vos fichier csv aussitôt qu'ils seront prêts. À l'heure de pointe, quelques heures peuvent être nécessaires.",
  'csv.export.confirm.done': 'Terminer',
  'csv.export.title': 'Export en format CSV',
  'csv.export.info':
    'Les journaux alimentaires de {count} client(s) seront exportés en format csv. Notez que tous les nutriments disponibles seront exportés.',
  'csv.export.range': 'Intervalle de l’export',
  'csv.export.withinRange':
    'Dates comprisent entre: Date de début, date de fin',
  'csv.export.all': 'Toutes les dates disponibles',
  'csv.export.options': 'Journées vérifiées/non-vérifiées ',
  'csv.export.verifiedOnly': 'Vérifiées seulement',
  'csv.export.details': 'Exporter les nutriments par: ',
  'csv.export.withIngredients': 'Aliments séparés (p.ex.,pain, jambon, tomate)',
  'csv.export.withDishes': 'Plat (p.ex., sandwich)',
  'csv.export.allDiaries': 'Toutes',
  'csv.export.grouping': 'CSV simple/multiple',
  'csv.export.aggregate':
    'CSV simple (données des clients/participants dans un fichier)',
  'csv.export.single': 'CSV multiple',
  'csv.export.export': 'Exporter',
  'csv.export.submitting': 'Soumission',

  'rangePicker.from': 'Date de début',
  'rangePicker.to': 'Date de fin',

  'oauth.authorize.error':
    "⚠ Un ou plusieurs paramètres sont manquants dans le lien d'authorization",
  'oauth.authorize.errorHelp':
    "Veuillez contacter les développeurs de l'application qui vous ont redirigé ici et informez les que le lien doit inclure: client_id, scope & redirect_uri.",
  'oauth.authorize.title': 'Autoriser',
  'oauth.authorize.subtitle': 'aimerait pouvoir :',
  'oauth.authorize.data':
    'Les données partagées avec {app_name} seront gouvernées par leurs ',
  'oauth.authorize.review':
    ". Veuillez vous assurer de bien les lire avant d'autoriser l'accès.",
  'oauth.authorize.allow': 'Autoriser',
  'oauth.authorize.deny': 'Décliner',
  'oauth.authorize.privacy': 'politique relative à la vie privée',
  'oauth.authorize.terms': "conditions d'utilisation",
  'oauth.authorize.and': 'et ',
  'oauth.authorize.confirm': "J'ai lu et compris les politiques de {app_name}.",
  'oauth.authorize.callError': 'Erreur lors de la redirection.',
  'oauth.authorize.callErrorHelp':
    "Une erreur s'est produite lors de la réception de votre autorisation. Veuillez contacter les développeurs de {app_name}.",
  'oauth.authorize.retry': 'Réessayer',
  'oauth.scope.clientRead':
    'Accéder au profil de vos clients (nom, courriel, âge et sexe).',
  'oauth.scope.clientWrite':
    'Modifier les informations du profil de vos clients (nom, courriel, âge et sexe).',
  'oauth.scope.diaryRead':
    'Accéder à l’information nutritionnelle de vos clients.',
  'oauth.scope.diaryWrite':
    'Modifier l’information nutritionnelle de vos clients.',
  'oauth.scope.commentRead':
    'Accéder aux commentaires échangés entre vous et vos clients.',
  'oauth.scope.photoRead': 'Accéder aux photos de repas de vos clients.',
  'oauth.scope.inviteRead':
    'Être averti  du statut de vos invitations et les accéder.',
  'oauth.scope.inviteWrite': 'Inviter de nouveaux clients à votre nom.',

  'oauth.box.title': 'Lier votre compte Keenoa',

  'foods.create.name': '',
  'foods.create.pickFoodName.placeholder': "Nom de l'aliment",
  'foods.create.allNutrients.search': 'Chercher un nutrient...',
  'foods.create.save': 'Sauvegarder',
  'foods.create.saving': 'Sauvegarde en cours...',

  'foods.create.size': 'Taille',
  'foods.create.serving': 'eg. Sandwich',
  'foods.create.amount': 'eg. 60',
  'foods.create.allowClients':
    "Cocher pour permettre à vos clients d'utiliser cet aliment",
  'foods.create.basicLabel': "Info de l'étiquette",
  'foods.create.allNutrients': 'Tous les nutriments',
  'foods.create.selectNameError': 'Veuillez sélectionner un nom.',
  'foods.create.selectServingAmountError':
    'Veuillez sélectioner la quantité de la portion.',
  'foods.create.selectServingNameError':
    'Veuillez spécifier la taille de la portion.',

  'foods.create.unsavedChanges':
    'Vous avez des changements non sauvegardés, êtes-vous certain de vouloir quitter cette page?',

  'userFoods.title': 'Mes aliments',
  'userFoods.newFood': 'Nouvel aliment',
  'userFoods.search': 'Rechercher un aliment',
  'userFoods.columns.name': 'Nom',
  'userFoods.columns.serving': 'Portion',
  'userFoods.columns.shared': 'Visible par les clients',
  'userFood.label': 'aliment',
  'userFood.remove': "Supprimer l'aliment",
  'userFood.deleteHeader': "Supprimer l'aliment ?",
  'userFood.deleteExplanation':
    "L'aliment sera supprimé de manière permanente. Vous ne pourrez plus l'ajouter au journal d'un client.",
  'userFood.deleteConfirm': "Oui, supprimer l'aliment",
  'userFood.deleteCancel': "Non, conserver l'aliment",
  'userFood.removeSuccess': 'Aliment supprimé avec succès.',
  'userFood.removeSuccessP': '{count} aliments ont été supprimés.',
  'foods.create.nutrientsError': 'Veuillez spécifier au moins 1 nutriment.',
  'userFoods.empty.title': "Besoin d'un aliment en particular ?",
  'userFoods.empty.help':
    'Vous pouvez rapidement ajouter des aliments à la base de donnée. Ces aliments seront disponible uniquement pour vous ainsi que pour vos clients si vous le désiré.',
  'userFoods.empty.new': 'Ajouter un aliment',

  'courses.enroll': 'Utilisez Keenoa dans votre cours',
  'courses.instructorIntro':
    'Lorsque que vous rejoignez un cours comme instructeur, vous obtenez accès aux données collectées par tous les étudiants enregistrés au cours.',
  'courses.learnerIntro':
    "Lorsque que vous rejoignez un cours, l'instructeur de celui-ci a accès aux données collectées dans votre compte Keenoa.",
  'courses.invalidToken':
    'Il semblerait que lien que vous avez utilisé pour vous enregisrer au cours ne soit plus valide. Veuillez utiliser un autre lien.',

  'receiveInvite.success': "L'invitation a été envoyée avec succès",
  'receiveInvite.error':
    "Une erreur est survenue lors de l'envoi de l'invitation. Celle-ci est peut-être déjà dans votre liste.",
};
