import React, { useState, useEffect } from 'react';
import { SPECIAL_OPTION_TYPES } from '../../../../../../../constants';
import { Chip } from 'components/forms/Chip';
import RedirectOption from './RedirectOption';

function Option({
  id,
  name,
  foodId,
  specialType,
  isMultiple,

  selected,

  select,
  unselect,
}) {
  const [isSelected, setIsSelected] = useState(selected);
  useEffect(() => {
    if (selected !== isSelected) {
      setIsSelected(selected);
    }
  }, [isSelected, selected]);

  const onClick = () => {
    if (isSelected) {
      unselect(id, true);
    } else {
      select(id, true);
    }
  };

  if (specialType) {
    let SpecialOption;
    switch (specialType) {
      // Scan options are not supported on web
      case SPECIAL_OPTION_TYPES.SCAN:
      case SPECIAL_OPTION_TYPES.DIRECT_SCAN:
      // Search option is not yet supported.
      case SPECIAL_OPTION_TYPES.SEARCH:
        SpecialOption = null;
      case SPECIAL_OPTION_TYPES.REDIRECT:
        SpecialOption = RedirectOption;
        break;
      default:
        // Unsupported unknown special option
        SpecialOption = null;
    }
    return SpecialOption === null ? null : (
      <SpecialOption id={id} name={name} foodId={foodId} />
    );
  }

  return (
    <Chip onClick={onClick} selected={isSelected}>
      {name}
    </Chip>
  );
}
export default Option;
