import { connect } from 'react-redux';
import MealBody from './MealBody';
import { openSearchFoodModal } from 'redux/modules/entries/entries.operations';
import {
  getMealsNutrients,
  getMealsWithEntries,
} from 'redux/modules/meals/meals.selectors';

const mapStateToProps = (state, { meals }) => ({
  sum: getMealsNutrients(state, meals),
  meals: getMealsWithEntries(state, meals),
});

const mapDispatchToProps = dispatch => ({
  onAddEntry: mealId => {
    dispatch(openSearchFoodModal(mealId));
  },
});

const MealBodyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MealBody);

export default MealBodyContainer;
