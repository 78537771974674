import React from 'react';
import ReportLoader from './ReportLoader';
import { connect } from 'react-redux';
import {
  getCurrentReportId,
  getIsDirty,
  getCurrentTemplateStructure,
} from 'redux/modules/UI/reportView';
import {
  fetchReport,
  getReportFetchStatus,
  getCreateReportStatus,
  getReportStructure,
} from 'redux/modules/reports';
import {
  loadReport,
  saveCurrentExport,
} from 'redux/modules/UI/reportView/reportView.operations';
import { getCurrentClientId } from 'redux/modules/clients/clients.selectors';
import {
  loadStructure,
  toggleReportDiary,
  stageReport,
} from 'redux/modules/UI/reportView/reportView.actions';
import { fetchReportTemplate } from 'redux/modules/reportTemplates/reportTemplates.actions';
import { selectClient } from 'redux/modules/clients/clients.operations';
import { useQuery } from 'utils/hooks';

const mapStateToProps = (state, { loadReportId }) => {
  const reportId = getCurrentReportId(state);
  return {
    clientId: getCurrentClientId(state),

    reportId,
    fetchStatus: getReportFetchStatus(state, reportId),
    createStatus: getCreateReportStatus(state),

    loadReportFetchStatus: getReportFetchStatus(state, loadReportId),
    loadReportStructure: getReportStructure(state, loadReportId),

    templateStructure: getCurrentTemplateStructure(state),

    isDirty: getIsDirty(state),
  };
};

const mapDispatchToProps = dispatch => ({
  selectClient: clientId => dispatch(selectClient(clientId)),
  stageReport: reportId => dispatch(stageReport(reportId)),

  fetchReport: id => dispatch(fetchReport(id)),
  loadReport: id => dispatch(loadReport(id)),

  fetchTemplate: id => dispatch(fetchReportTemplate(id)),
  loadStructure: structure => dispatch(loadStructure(structure)),

  selectDiary: diaryId => dispatch(toggleReportDiary(diaryId, false)),

  save: () => dispatch(saveCurrentExport()),
});

const Loader = connect(mapStateToProps, mapDispatchToProps)(ReportLoader);

export default function(props) {
  const query = useQuery();
  const loadReportId = query.get('load_report_id');

  return <Loader {...props} loadReportId={loadReportId} />;
}
