import { getIsFetching } from './clients.selectors';
import actions from './clients.actions';
import { normalize } from 'normalizr';
import jwtDecode from 'jwt-decode';
import * as schema from '../../schemas';
import clientApi from '../../../api/client';
import selectors from './clients.selectors';
import { idToCode, codeToConstant } from '../../../constants/nutriments';

const _tokenValid = token => {
  if (!token) return false;

  const decodedToken = jwtDecode(token);
  // We divide by 1000 since Date.now returns miliseconds instead of seconds in javascript.
  // https://auth0.com/forum/t/reading-expiry-date-of-jwt-on-client/2033/5
  const expired = decodedToken.exp < Date.now() / 1000;
  return !expired;
};

const _fetchToken = clientId => (dispatch, getState) => {
  const existingToken = selectors.makeGetClientToken(clientId)(getState());
  if (_tokenValid(existingToken)) return Promise.resolve(existingToken);

  dispatch(actions.fetchToken(clientId));
  return clientApi
    .fetchToken(clientId)
    .then(token => {
      dispatch(actions.fetchTokenSuccess(clientId, token));
      return token;
    })
    .catch(err => dispatch(actions.fetchTokenFailure(err)));
};

const _initializeNutrients = clients =>
  clients.map(x => ({
    ...x,
    nutrients: x.nutrients ? x.nutrients : [],
  }));

export const fetchClients = () => (dispatch, getState) => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  dispatch(actions.fetchClients());
  return clientApi
    .fetchClients()
    .then(res => _initializeNutrients(res))
    .then(res => normalize(res, schema.arrayOfClient))
    .then(response => dispatch(actions.fetchClientsSuccess(response)))
    .catch(err => dispatch(actions.fetchClientsFailure(err)));
};

export const selectClient = clientId => (dispatch, getState) => {
  const loadClient = !selectors.getById(getState())[clientId]
    ? fetchClients()(dispatch, getState)
    : new Promise(r => r());

  const loadClientToken = _fetchToken(clientId)(dispatch, getState);
  return Promise.all([loadClient, loadClientToken]).then(() =>
    dispatch(actions.setCurrentClient(clientId))
  );
};

export const removeCurrentClient = () => {
  return actions.removeCurrentCLient();
};

export const inviteClient = (email, locale) => dispatch => {
  dispatch(actions.inviteClient(email, locale));
  return clientApi
    .inviteClient(email, locale)
    .then(() => dispatch(actions.inviteClientSuccess(email, locale)))
    .catch(err => dispatch(actions.inviteClientFailure(err)));
};

const _recursiveAddNutrientToCurrentClient = (
  nutrient,
  nutrients
) => dispatch => {
  dispatch(actions.addClientNutrient(nutrient));
  if (nutrient.children && nutrient.children.length > 0) {
    //We need to dispatch child AddNutrient to also add UI column in detailedView State
    nutrient.children.forEach(id => {
      const child = nutrients.find(elem => elem.id === id);
      if (child) {
        dispatch(_recursiveAddNutrientToCurrentClient(child, nutrients));
      }
    });
  }
};

export const addNutrientToCurrentClient = (nutrient, nutrients) => (
  dispatch,
  getState
) => {
  if (nutrients.find(elem => elem.id === nutrient.id)) return Promise.resolve();

  const parentPromise =
    // If the parent of the nutrient is not selected we add it before adding the child
    nutrient.parent && !nutrients.find(({ id }) => id === nutrient.parent)
      ? dispatch(
          addNutrientToCurrentClient(
            codeToConstant[idToCode[nutrient.parent]],
            nutrients
          )
        ).then(() => {
          nutrients.push(codeToConstant[idToCode[nutrient.parent]]);
        })
      : Promise.resolve();
  return parentPromise.then(() => {
    dispatch(_recursiveAddNutrientToCurrentClient(nutrient, nutrients));
    const clientId = selectors.getCurrentClientId(getState());
    return clientApi
      .updateClientNutrients(clientId, [...nutrients, nutrient])
      .then(response => normalize(response, schema.client))
      .then(res => dispatch(actions.updateClientNutrientsSuccess(res)))
      .catch(err => dispatch(actions.updateClientNutrientsFailure(err)));
  });
};

export const removeNutrientFromCurrentClient = (nutrient, nutrients) => (
  dispatch,
  getState
) => {
  if (!nutrients.find(elem => elem.id === nutrient.id))
    return Promise.resolve();

  dispatch(actions.removeClientNutrient(nutrient));
  const clientId = selectors.getCurrentClientId(getState());
  return clientApi
    .updateClientNutrients(
      clientId,
      nutrients.filter(elem => elem.id !== nutrient.id)
    )
    .then(res => normalize(res, schema.client))
    .then(client => dispatch(actions.updateClientNutrientsSuccess(client)))
    .catch(err => dispatch(actions.updateClientNutrientsFailure(err)));
};

export const updateCurrentClientNutrients = newNutrients => (
  dispatch,
  getState
) => {
  const clientId = selectors.getCurrentClientId(getState());
  return clientApi
    .updateClientNutrients(clientId, newNutrients)
    .then(res => normalize(res, schema.client))
    .then(client => dispatch(actions.updateClientNutrientsSuccess(client)))
    .catch(err => dispatch(actions.updateClientNutrientsFailure(err)));
};

export default {
  selectClient,
  fetchClients,
  inviteClient,
  removeCurrentClient,
  updateSearchString: actions.updateSearchString,
  addNutrientToCurrentClient,
  removeNutrientFromCurrentClient,
  updateCurrentClientNutrients,
};
