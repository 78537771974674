/**
 * Report templates define how a report should be build. See the UI reportView
 * redux module for more info on how they are built.
 */
import { combineReducers } from 'redux';
import { makeById } from '../../../utils/standardReducers';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import values from 'lodash/values';
import types from './reportSettings.types';
import {
  getReportSettingsApi,
  updateReportSettingApi,
  uploadLogoApi,
} from '../../../api/report';
import { reportSetting } from '../../schemas';
import { normalize } from 'normalizr';
import { light } from '../../../themes';

export default combineReducers({
  byId: makeById('reportSettings'),
});

/// SELECTORS
const _getRoot = state => state.reportSettings;
export const getById = createSelector(_getRoot, x => x.byId);

export const getSetting = createSelector(getById, byId => {
  return get(values(byId), 0, null);
});
export const getReportSettingId = createSelector(getSetting, setting =>
  get(setting, 'id', null)
);
export const getMyReportTheme = createSelector(getSetting, setting =>
  get(setting, 'theme', {})
);
export const getReportTheme = createSelector([getMyReportTheme], theme => {
  const themePalette = get(theme, 'palette', {});
  const palette = {
    ...light.palette,
    ...themePalette,
  };
  return {
    ...light,
    ...theme,
    palette,
  };
});
export const getReportPrimaryColor = createSelector(getReportTheme, theme =>
  get(theme, 'palette.primary1')
);
export const getReportLogo = createSelector(getSetting, setting =>
  get(setting, 'logo_url', null)
);

/// ACTION
export const fetchReportSetting = () => ({
  types: [
    types.FETCH_REPORT_SETTING_REQUEST,
    types.FETCH_REPORT_SETTING_SUCCESS,
    types.FETCH_REPORT_SETTING_FAILURE,
  ],
  shouldCallAPI: state => getSetting(state) === null,
  callAPI: () =>
    getReportSettingsApi().then(res => normalize(res, reportSetting)),
});

export const updateReportTheme = (settingId, theme) => ({
  types: [
    types.UPDATE_REPORT_SETTING_REQUEST,
    types.UPDATE_REPORT_SETTING_SUCCESS,
    types.UPDATE_REPORT_SETTING_FAILURE,
  ],
  callAPI: () =>
    updateReportSettingApi(settingId, { theme }).then(res =>
      normalize(res, reportSetting)
    ),
  payload: {
    settingId,
    theme,
  },
});

export const deleteReportLogo = settingId => ({
  types: [
    types.UPDATE_REPORT_SETTING_REQUEST,
    types.UPDATE_REPORT_SETTING_SUCCESS,
    types.UPDATE_REPORT_SETTING_FAILURE,
  ],
  callAPI: () =>
    updateReportSettingApi(settingId, { logo_url: null }).then(res =>
      normalize(res, reportSetting)
    ),
  payload: {
    settingId,
    logo_url: null,
  },
});

export const uploadReportLogo = (settingId, logo) => ({
  types: [
    types.UPDATE_REPORT_LOGO_REQUEST,
    types.UPDATE_REPORT_LOGO_SUCCESS,
    types.UPDATE_REPORT_LOGO_FAILURE,
  ],
  callAPI: () =>
    uploadLogoApi(settingId, logo).then(res => normalize(res, reportSetting)),
  payload: {
    settingId,
    logo,
  },
});
