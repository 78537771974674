import React from 'react';
import styled from 'styled-components';
import Color from 'color';
import { defineMessages, FormattedMessage } from 'react-intl';
import InfoText from './InfoText';
import Typography from './material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTheme } from 'styled-components';

const messages = defineMessages({
  edit: {
    id: 'general.edit',
    defaultMessage: 'Edit',
  },
  on: { id: 'general.on' },
  off: { id: 'general.off' },
});

const BORDER_TOP_COLOR = Color('black')
  .fade(0.95)
  .toString();
const RowWrapper = styled.div`
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.palette.text.primary};
  border-bottom: 1px solid ${BORDER_TOP_COLOR};
  margin-right: -20px;
  width: 100%;

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

const Boolean = withTheme(({ value, theme }) => {
  const icon = value ? 'check-circle' : 'pause-circle';
  const color = value ? theme.palette.primary1 : theme.palette.text.secondary;
  const message = value ? messages.on : messages.off;

  return (
    <Typography
      use="body2"
      tag="div"
      style={{ color, display: 'flex', alignItems: 'center' }}
    >
      <FontAwesomeIcon
        icon={['fad', icon]}
        style={{ fontSize: 18, marginRight: 8 }}
      />
      <FormattedMessage {...message} />
    </Typography>
  );
});
const ArrayValue = ({ value }) => {
  if (!value.length)
    return (
      <Typography use="body2" tag="div" rank="secondary">
        <FontAwesomeIcon icon={['fad', 'empty-set']} />
      </Typography>
    );
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {value.map(v => (
        <Typography key={v} use="body2" tag="div" rank="secondary">
          {v}
        </Typography>
      ))}
    </div>
  );
};

export default ({ name, icon, nameHelp, value, renderValue, onEdit }) => (
  <RowWrapper onClick={onEdit}>
    <div style={{ flex: 1 }}>
      <Typography use="subtitle1" rank="midPrimary">
        <FontAwesomeIcon
          icon={['fad', icon]}
          style={{ width: 60, fontSize: 18 }}
        />
        <InfoText textMessage={name} tooltipMessage={nameHelp} />
      </Typography>
    </div>
    <div
      style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {typeof value === 'boolean' ? <Boolean value={value} /> : null}
      {typeof value === 'string' ? (
        <Typography use="body2" rank="primary">
          {value}
        </Typography>
      ) : null}
      {Array.isArray(value) ? <ArrayValue value={value} /> : null}
      <Typography
        use="body2"
        rank="secondary"
        tag="div"
        style={{ marginRight: 32, marginLeft: 16 }}
      >
        <FontAwesomeIcon icon={['fas', 'chevron-right']} />
      </Typography>
    </div>
  </RowWrapper>
);
