import styled from 'styled-components';
import React from 'react';
import SearchBar from 'components/SearchBar';
import Spacer from 'components/Spacer';
import InviteButton from '../InviteButton';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  searchHint: {
    id: 'clientList.searchHint',
    defaultMessage: 'Who am I looking for ?',
  },
});

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
`;

function ClientsSearchBar({ value, onInputChange }) {
  const intl = useIntl();

  return (
    <TopContainer>
      <SearchBar
        value={value}
        onInputChange={onInputChange}
        placeholder={intl.formatMessage(messages.searchHint)}
      />
      <Spacer spacing="xl" />
      <InviteButton />
    </TopContainer>
  );
}

export default ClientsSearchBar;
