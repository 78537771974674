import React from 'react';
import styled from 'styled-components';
import { Chip } from 'components/forms/Chip';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  other: { id: 'builder.specialOptions.other' },
});

const Wrapper = styled.div`
  padding: 4px;
`;

function OtherOption({ onClick }) {
  return (
    <Wrapper key={'other_special_option'}>
      <Chip onClick={onClick} selected={false}>
        <FormattedMessage {...messages.other} />
      </Chip>
    </Wrapper>
  );
}
export default OtherOption;
