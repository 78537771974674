import { notify } from 'react-notify-toast';
import { intl } from '../../utils/intl';
import get from 'lodash/get';
/* Copied from the Redux documentation:
 http://redux.js.org/docs/recipes/ReducingBoilerplate.html

 Added:
 - messages: an array of 3 items containing notification messages
             that should be sent on request, success, failure
 */

function callAPIMiddleware({ dispatch, getState }) {
  return next => action => {
    const {
      types,
      callAPI,
      shouldCallAPI = () => true,
      payload = {},
      messages,
    } = action;

    if (!types) {
      // Normal action: pass it on
      return next(action);
    }

    if (
      !Array.isArray(types) ||
      types.length !== 3 ||
      !types.every(type => typeof type === 'string')
    ) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.');
    }

    if (shouldCallAPI && !shouldCallAPI(getState())) {
      return;
    }

    const [requestType, successType, failureType] = types;

    dispatch(
      Object.assign({}, payload, {
        type: requestType,
      })
    );

    return callAPI().then(
      response => {
        const successMsg = get(messages, 1);
        if (successMsg) notify.show(intl.formatMessage(successMsg), 'success');

        return dispatch(
          Object.assign({}, payload, {
            response,
            type: successType,
          })
        );
      },
      error => {
        const errorMsg = get(messages, 2);
        if (errorMsg) notify.show(intl.formatMessage(errorMsg), 'error');
        dispatch(
          Object.assign({}, payload, {
            error,
            type: failureType,
          })
        );
      }
    );
  };
}

export default callAPIMiddleware;
