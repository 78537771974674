import React from 'react';
import NutrientsSumRow from '../NutrientsSumRow';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  max: {
    id: 'foodLog.compareMax',
  },
});

class CompareMax extends React.PureComponent {
  render() {
    const { sum, nutrients } = this.props;
    return (
      <tbody>
        <NutrientsSumRow
          top
          nutrimentsToShow={nutrients}
          sum={sum}
          message={messages.max}
        />
      </tbody>
    );
  }
}

export default CompareMax;
