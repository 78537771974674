import React from 'react';
import styled from 'styled-components';
import WhiteFrame from 'components/WhiteFrame';
import NutrientsChart from '../NutrientsChart';
import { defineMessages, FormattedMessage } from 'react-intl';
import get from 'lodash/get';

const messages = defineMessages({
  header: {
    id: 'dayChart.header',
    defaultMessage: 'Daily Summary',
  },
  compareHeader: { id: 'dayChart.compareHeader' },
});

const Wrapper = styled(WhiteFrame)`
  margin-top: 10px;
  justify-content: center;
  padding: 10px;
  max-width: 263px;
`;

const Header = styled.div`
  color: ${x => x.theme.palette.text.midPrimary};
  padding: 5px 10px;
`;

class DayChart extends React.PureComponent {
  render() {
    const { summary, compareMode } = this.props;
    if (!get(summary, 'energy')) {
      return null;
    }
    const headerMessage = compareMode
      ? messages.compareHeader
      : messages.header;
    return (
      <Wrapper>
        <Header className="mdc-typography--subtitle2">
          <FormattedMessage {...headerMessage} />
        </Header>
        <NutrientsChart
          legendType="percent"
          maxRadius={80}
          energy={summary.energy}
          nutrients={summary.macronutrients}
        />
      </Wrapper>
    );
  }
}

export default DayChart;
