import Login from './Login';
import { connect } from 'react-redux';
import { authOperations } from 'redux/modules/auth';

const mapStateToProps = ({ auth }) => {
  const { errorType } = auth;
  return { errorType };
};
const mapDispatchToProps = dispatch => ({
  onSubmit: (credentials, redirect) =>
    dispatch(authOperations.login(credentials, redirect)),
});

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer;
