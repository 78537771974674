import React from 'react';
import { Checkbox as c } from '@rmwc/checkbox';
import '@rmwc/checkbox/styles';
import styled from 'styled-components';

export const Checkbox = c;
export default Checkbox;

const CheckboxWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  width: 52px;
`;

export const FastCheckbox = props => (
  <CheckboxWrapper onClick={props.onChange}>
    <input type="checkbox" {...props} />
  </CheckboxWrapper>
);
