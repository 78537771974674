import React from 'react';
import Dashboard from './Dashboard';
import { connect } from 'react-redux';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';
import { loadProfile } from 'redux/modules/auth/auth.operations';
import { Switch, Route } from 'react-router-dom';
import { fetchClients } from 'redux/modules/clients/clients.operations';
import ReceiveClientInvite from './ReceiveClientInvite';

const mapDispatchToProps = dispatch => ({
  openWelcome: () => dispatch(openModal(MODAL_TYPES.WELCOME)),
  loadProfile: () => dispatch(loadProfile()),
  loadClients: () => dispatch(fetchClients()),
});
const DashboardContainer = connect(null, mapDispatchToProps)(Dashboard);

export default function() {
  return (
    <Switch>
      <Route
        path={['/clients', '/account', '/reports', '/library', '/private']}
      >
        <DashboardContainer />
      </Route>
      <Route path={['/receive-client-invite']}>
        <ReceiveClientInvite />
      </Route>
    </Switch>
  );
}
