export const pre = 'keenoa/reports/';

export default {
  CREATE_REPORT_REQUEST: `${pre}CREATE_REPORT_REQUEST`,
  CREATE_REPORT_SUCCESS: `${pre}CREATE_REPORT_SUCCESS`,
  CREATE_REPORT_FAILURE: `${pre}CREATE_REPORT_FAILURE`,

  FETCH_REPORT_REQUEST: `${pre}FETCH_REPORT_REQUEST`,
  FETCH_REPORT_SUCCESS: `${pre}FETCH_REPORT_SUCCESS`,
  FETCH_REPORT_FAILURE: `${pre}FETCH_REPORT_FAILURE`,

  FETCH_REPORTS_REQUEST: `${pre}FETCH_REPORTS_REQUEST`,
  FETCH_REPORTS_SUCCESS: `${pre}FETCH_REPORTS_SUCCESS`,
  FETCH_REPORTS_FAILURE: `${pre}FETCH_REPORTS_FAILURE`,

  DELETE_REPORT_REQUEST: `${pre}DELETE_REPORT_REQUEST`,
  DELETE_REPORT_SUCCESS: `${pre}DELETE_REPORT_SUCCESS`,
  DELETE_REPORT_FAILURE: `${pre}DELETE_REPORT_FAILURE`,

  FINALIZE_REPORT_REQUEST: `${pre}FINALIZE_REPORT_REQUEST`,
  FINALIZE_REPORT_SUCCESS: `${pre}FINALIZE_REPORT_SUCCESS`,
  FINALIZE_REPORT_FAILURE: `${pre}FINALIZE_REPORT_FAILURE`,

  UPDATE_REPORT_REQUEST: `${pre}UPDATE_REPORT_REQUEST`,
  UPDATE_REPORT_SUCCESS: `${pre}UPDATE_REPORT_SUCCESS`,
  UPDATE_REPORT_FAILURE: `${pre}UPDATE_REPORT_FAILURE`,

  SEND_REPORT_REQUEST: `${pre}SEND_REPORT_REQUEST`,
  SEND_REPORT_SUCCESS: `${pre}SEND_REPORT_SUCCESS`,
  SEND_REPORT_FAILURE: `${pre}SEND_REPORT_FAILURE`,

  VIEW_REPORT: `${pre}VIEW_REPORT`,

  DOWNLOAD_REPORT: `${pre}DOWNLOAD_REPORT`,
};
