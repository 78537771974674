import types from './builderView.types';

export const removeBuilderNamespace = namespace => ({
  type: types.REMOVE_BUILDER_NAMESPACE,
  namespace,
});

export const stagePublicBuilder = namespace => publicBuilderId => ({
  type: types.STAGE_PUBLIC_BUILDER,
  namespace,
  id: publicBuilderId,
});

export const stageBuilder = namespace => builderId => ({
  type: types.STAGE_BUILDER,
  namespace,
  builderId,
});

export const selectOption = namespace => (optionId, groupId, isManual) => ({
  type: types.SELECT_OPTION,
  namespace,
  optionId,
  groupId,
  isManual,
});

export const unselectOption = namespace => (optionId, groupId, isManual) => ({
  type: types.UNSELECT_OPTION,
  namespace,
  optionId,
  groupId,
  isManual,
});

export const clearOptions = namespace => ({
  type: types.CLEAR_OPTIONS,
  namespace,
});
