import React from 'react';
import Modal from 'components/Modal';
import { Button } from 'components/material/Button';
import Typography from 'components/material/Typography';
import Spacer from 'components/Spacer';
import H2 from 'components/H2';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useHistory } from 'react-router-dom';

const messages = defineMessages({
  header: { id: 'paymentDue.header' },
  content: { id: 'paymentDue.content' },
  continue: { id: 'paymentDue.continue' },
});

function PaymentDueModal({ isOpen, onExit, onContinue }) {
  const history = useHistory();

  const _onContinue = () => {
    history.push('/account/billing');
    onContinue();
  };

  const _onExit = () => {
    history.push('/account/billing');
    onExit();
  };
  return (
    <Modal isOpen={isOpen} onClose={_onExit}>
      <H2>
        <FormattedMessage {...messages.header} />
      </H2>
      <Spacer spacing="md" />
      <Typography rank="body1" use="primary" tag="div">
        <FormattedMessage {...messages.content} />
      </Typography>
      <Spacer spacing="lg" />
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Button unelevated onClick={_onContinue}>
          <FormattedMessage {...messages.continue} />
        </Button>
      </div>
    </Modal>
  );
}

export default PaymentDueModal;
