import Signup from './Signup';
import { connect } from 'react-redux';
import { authOperations } from 'redux/modules/auth';

const mapStateToProps = ({ account, auth }) => {
  const { locale } = account;
  const { errorType } = auth;
  return { locale, errorType };
};
const mapDispatchToProps = dispatch => ({
  onSubmit: credentials =>
    dispatch(authOperations.signupAndEnroll(credentials)),
});

const SignupContainer = connect(mapStateToProps, mapDispatchToProps)(Signup);

export default SignupContainer;
