import React, { useState } from 'react';
import styled from 'styled-components';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import LeftNavigation from 'components/LeftNavigation';
import { defineMessages, useIntl } from 'react-intl';
import Templates from './Templates';
import UserFoods from './UserFoods';

const messages = defineMessages({
  title: { id: 'library.title' },
  back: { id: 'library.back' },
  templates: { id: 'library.templates' },
  foods: { id: 'library.foods' },
});

const Wrapper = styled.div`
  flex: 1;
  overflow-y: auto !important;
`;

const Library = () => {
  const match = useRouteMatch();
  const [open, setOpen] = useState(true);
  const intl = useIntl();

  const rows = [
    {
      message: messages.foods,
      to: `${match.url}/foods`,
    },
    {
      message: messages.templates,
      to: `${match.url}/templates`,
    },
  ];

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex' }}>
      <LeftNavigation
        titleMsg={intl.formatMessage(messages.title)}
        rows={rows}
        open={open}
        setOpen={setOpen}
      />
      <Wrapper open={open}>
        <Switch>
          <Route path={`${match.path}/templates`}>
            <Templates />
          </Route>
          <Route path={`${match.path}/foods`}>
            <UserFoods />
          </Route>
          <Route>
            <Redirect to={`${match.url}/foods`} />
          </Route>
        </Switch>
      </Wrapper>
    </div>
  );
};

export default Library;
