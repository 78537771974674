import types from './reportTemplates.types';
import { normalize } from 'normalizr';
import {
  reportTemplate as reportTemplateSchemma,
  arrayOfReportTemplate,
} from '../../schemas';
import {
  createReportTemplateApi,
  getReportTemplateApi,
  updateReportTemplateApi,
  getReportTemplatesApi,
  deleteReportTemplateApi,
} from '../../../api/report';

export const createReportTemplate = ({ title, emoji, description }) => ({
  types: [
    types.CREATE_REPORT_TEMPLATE_REQUEST,
    types.CREATE_REPORT_TEMPLATE_SUCCESS,
    types.CREATE_REPORT_TEMPLATE_FAILURE,
  ],
  callAPI: () =>
    createReportTemplateApi({ title, emoji, description }).then(res =>
      normalize(res, reportTemplateSchemma)
    ),
  payload: { title, emoji, description },
});

export const fetchReportTemplate = templateId => ({
  types: [
    types.FETCH_REPORT_TEMPLATE_REQUEST,
    types.FETCH_REPORT_TEMPLATE_SUCCESS,
    types.FETCH_REPORT_TEMPLATE_FAILURE,
  ],
  callAPI: () =>
    getReportTemplateApi(templateId).then(res =>
      normalize(res, reportTemplateSchemma)
    ),
  payload: { templateId },
});

export const fetchReportTemplates = () => ({
  types: [
    types.FETCH_REPORT_TEMPLATES_REQUEST,
    types.FETCH_REPORT_TEMPLATES_SUCCESS,
    types.FETCH_REPORT_TEMPLATES_FAILURE,
  ],
  callAPI: () =>
    getReportTemplatesApi().then(res => normalize(res, arrayOfReportTemplate)),
});

export const updateReportTemplate = (templateId, update) => ({
  types: [
    types.UPDATE_REPORT_TEMPLATE_REQUEST,
    types.UPDATE_REPORT_TEMPLATE_SUCCESS,
    types.UPDATE_REPORT_TEMPLATE_FAILURE,
  ],
  callAPI: () =>
    updateReportTemplateApi(templateId, update).then(res =>
      normalize(res, reportTemplateSchemma)
    ),
  payload: { templateId, ...update },
});

export const deleteReportTemplate = templateId => ({
  types: [
    types.DELETE_REPORT_TEMPLATES_REQUEST,
    types.DELETE_REPORT_TEMPLATES_SUCCESS,
    types.DELETE_REPORT_TEMPLATES_FAILURE,
  ],
  callAPI: () => deleteReportTemplateApi(templateId),
  payload: { templateId },
});
