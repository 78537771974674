import React from 'react';
import Info from './Info';
import { FormattedMessage } from 'react-intl';

class InfoText extends React.PureComponent {
  render() {
    const {
      textStyle,
      textMessage,
      textValues = {},
      tooltipMessage,
    } = this.props;
    return (
      <span style={textStyle}>
        <FormattedMessage {...textMessage} values={textValues} />
        {tooltipMessage && <Info tooltipMessage={tooltipMessage} />}
      </span>
    );
  }
}

export default InfoText;
