import storage from '../../../utils/storage';
import accountApi from '../../../api/account';
import actions, { updateOnboard } from './account.actions';
import { safeLocale } from '../../../utils/misc';
import { getOnboard } from './account.selectors';

export function switchLocale(locale) {
  return dispatch => {
    const oldLocale = storage.get('locale');
    if (oldLocale !== locale) {
      storage.put('locale', safeLocale(locale));
      accountApi
        .updateLocale(locale)
        .then(res => {
          if (res.token) {
            storage.put('token', res.token);
          }
        })
        .then(() => dispatch(actions.updateLocaleSuccess()))
        .catch(() => dispatch(actions.updateLocaleFailure()));
    }
    dispatch(actions.switchLocale(locale));
  };
}

export const addNutrientToToolbox = (nutrient, toolbox) => dispatch =>
  !toolbox.some(elem => elem.id === nutrient.id)
    ? accountApi
        .updateToolbox([...toolbox, nutrient])
        .then(dietitian => dispatch(actions.updateToolboxSuccess(dietitian)))
        .catch(err => dispatch(actions.updateToolboxFailure(err)))
    : Promise.resolve();

export const removeNutrientFromToolbox = (nutrient, toolbox) => dispatch =>
  toolbox.some(elem => elem.id === nutrient.id) && toolbox.length > 1 // We always want at least one
    ? accountApi
        .updateToolbox(toolbox.filter(elem => elem.id !== nutrient.id))
        .then(dietitian => dispatch(actions.updateToolboxSuccess(dietitian)))
        .catch(err => dispatch(actions.updateToolboxFailure(err)))
    : Promise.resolve();

export const updateToolbox = nutrients => dispatch =>
  accountApi
    .updateToolbox(nutrients)
    .then(dietitian => dispatch(actions.updateToolboxSuccess(dietitian)))
    .catch(err => dispatch(actions.updateToolboxFailure(err)));

export const updateName = (firstName, lastName) => dispatch => {
  const fullName = [firstName, lastName].join(' ');
  dispatch(actions.updateName(fullName));
  return accountApi
    .updateName(fullName)
    .then(dietitian => dispatch(actions.updateNameSuccess(dietitian)))
    .catch(err => dispatch(actions.updateNameFailure(err)));
};

export const setOnboardRun = onboardId => (dispatch, getState) => {
  const onboard = getOnboard(getState());
  return dispatch(
    updateOnboard({
      ...onboard,
      [onboardId]: true,
    })
  );
};

export default {
  switchLocale,
  addNutrientToToolbox,
  removeNutrientFromToolbox,

  updateToolbox,
  updateName,
};
