import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { REPORT_ACTIONS } from 'constants/index.js';
import TableActions from 'components/TableActions';
import { confirmAction } from 'utils/misc';
import { useHistory } from 'react-router-dom';

const messages = defineMessages({
  delete: { id: 'report.actions.delete' },
  download: { id: 'report.actions.download' },
  send: { id: 'report.actions.send' },
  edit: { id: 'report.actions.edit' },
  copy: { id: 'report.actions.copyAndEdit' },

  deleteHeader: { id: 'rowActions.delete.header' },
  deleteExplanation: { id: 'rowActions.delete.explanation' },
  deleteConfirm: { id: 'rowActions.delete.confirm' },
  deleteCancel: { id: 'rowActions.delete.cancel' },

  report: { id: 'report.label' },
});

function ReportActions({
  selectCount,

  unselectAll,
  actions,

  reportIds,
  onAction,
}) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const intl = useIntl();

  const _onAction = async action => {
    setLoading(true);
    for (let i = 0; i < reportIds.length; ++i) {
      await onAction(action, reportIds[i], history);
    }
    unselectAll();
    setLoading(false);
  };
  const _onActionConfirm = action => {
    if (action === REPORT_ACTIONS.REMOVE) {
      confirmAction(
        {
          title: messages.deleteHeader,
          explanation: messages.deleteExplanation,
          confirm: messages.deleteConfirm,
          cancel: messages.deleteCancel,
        },
        () => _onAction(action)
      );
    } else {
      _onAction(action);
    }
  };
  const _actionConfig = action => {
    let icon;
    let message;
    let color = 'midPrimary';
    // eslint-disable-next-line
    switch (action) {
      case REPORT_ACTIONS.REMOVE:
        icon = 'trash-alt';
        color = 'error';
        message = messages.delete;
        break;
      case REPORT_ACTIONS.SEND:
        icon = 'envelope';
        message = messages.send;
        break;
      case REPORT_ACTIONS.COPY:
        icon = 'copy';
        message = messages.copy;
        break;
      case REPORT_ACTIONS.DOWNLOAD:
        icon = 'download';
        message = messages.download;
        break;
      case REPORT_ACTIONS.EDIT:
        icon = 'edit';
        message = messages.edit;
        color = 'warning';
        break;
    }
    return {
      icon,
      message,
      color,
    };
  };

  const tableActions = actions.map(action => ({
    id: action,
    ..._actionConfig(action),
  }));
  return (
    <TableActions
      loading={loading}
      actions={tableActions}
      onAction={_onActionConfirm}
      label={intl.formatMessage(messages.report)}
      selectCount={selectCount}
    />
  );
}

export default ReportActions;
