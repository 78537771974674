import React from 'react';
import { useTheme } from 'styled-components';
import Typography from './material/Typography';

export default function A(props) {
  const theme = useTheme();

  return (
    <Typography
      use="subtitle2"
      {...props}
      style={{ color: theme.palette.primary1 }}
    >
      {props.children}
    </Typography>
  );
}
