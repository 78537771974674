import React, { useEffect, useRef } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import Row from './Row';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

const Wrapper = styled.ul`
  height: 60vh;
  overflow-y: auto;
  overscroll-behavior: contain;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

function InfiniteHits({ hits, refine, hasMore }) {
  const root = useRef();
  const [ref, inView] = useInView({ root: root.current });

  useEffect(() => {
    if (inView && hasMore) refine();
  }, [inView, hasMore, refine]);

  return (
    <Wrapper ref={root}>
      {hits.map(hit => (
        <Row hit={hit} rowId={hit.objectID} key={hit.objectID} />
      ))}
      <div ref={ref} />
    </Wrapper>
  );
}

export default connectInfiniteHits(InfiniteHits);
