import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Center from 'components/Center';
import { Button } from 'components/material/Button';
import H2 from 'components/H2';

const messages = defineMessages({
  title: {
    id: 'newClient.confirm.title',
    defaultMessage: 'Invitation sent!',
  },
  to: {
    id: 'newClient.confirm.to',
    defaultMessage: 'to: ',
  },
  okBtn: {
    id: 'global.ok',
    defaultMessage: 'Ok',
  },
});

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const ConfirmEmail = styled.span`
  color: ${props => props.theme.palette.accent1};
`;

const SentIcon = styled.i`
  margin-left: 15px;
`;

const ConfirmLabel = styled.p`
  color: ${props => props.theme.palette.accent2};
  font-size: 16px;
`;

const NewClientForm = ({ email, onConfirmClick }) => {
  return (
    <div>
      <H2>
        <FormattedMessage {...messages.title} />
        <SentIcon className="material-icons">flight_takeoff</SentIcon>{' '}
      </H2>
      <Center>
        <div>
          <ConfirmLabel>
            <FormattedMessage {...messages.to} />
            <ConfirmEmail>{email}</ConfirmEmail>
          </ConfirmLabel>
        </div>
      </Center>
      <ButtonContainer>
        <Button
          unelevated
          onClick={onConfirmClick}
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          <FormattedMessage {...messages.okBtn} />
        </Button>
      </ButtonContainer>
    </div>
  );
};

export default NewClientForm;
