import UserFoodActions from './UserFoodActions';
import { connect } from 'react-redux';
import {
  getSelectedUserFoodsIds,
  unselectAllUserFoods,
} from 'redux/modules/UI/userFoodsView';
import { deleteUserFood, getById } from 'redux/modules/userFoods';

const mapStateToProps = state => ({
  selectedIds: getSelectedUserFoodsIds(state),
  byId: getById(state),
});

const mapDispatchToProps = dispatch => ({
  deleteFood: foodId => dispatch(deleteUserFood(foodId)),
  unselectAll: () => dispatch(unselectAllUserFoods()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserFoodActions);
