/**
 * Report templates define how a report should be build. See the UI reportView
 * redux module for more info on how they are built.
 */
import { combineReducers } from 'redux';
import { makeById } from '../../../utils/standardReducers';
import types from './reportVersions.types';
import { createSelector } from 'reselect';
import { createReportVersionApi } from '../../../api/report';
import { normalize } from 'normalizr';
import schemas from '../../schemas';
import get from 'lodash/get';

export default combineReducers({
  byId: makeById('reportVersions'),
});

/// SELECTORS
const _getRoot = state => state.reportVersions;

export const getById = createSelector(_getRoot, x => x.byId);

/** (state, versionId) */
export const getReportVersion = createSelector(
  [getById, (_, id) => id],
  (byId, id) => {
    return get(byId, id, null);
  }
);

/// ACTION
export const createReportVersion = (reportId, diaryIds, structure) => ({
  types: [
    types.CREATE_REPORT_VERSION_REQUEST,
    types.CREATE_REPORT_VERSION_SUCCESS,
    types.CREATE_REPORT_VERSION_FAILURE,
  ],
  callAPI: () =>
    createReportVersionApi(reportId, diaryIds, structure).then(res =>
      normalize(res, schemas.reportVersion)
    ),
  payload: {
    reportId,
    diaryIds,
    structure,
  },
});
