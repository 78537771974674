import React from 'react';
import { withTheme } from 'styled-components';
import { COURSES_FILTERS } from '../../../../constants/clientStatus';
import { defineMessages, injectIntl } from 'react-intl';
import Select from 'components/forms/Select';

const messages = defineMessages({
  all: { id: 'clients.filter.courses.all' },
});

class CoursesFiltering extends React.PureComponent {
  _getFilterLabel = filter => {
    const { intl } = this.props;

    switch (filter) {
      case COURSES_FILTERS.ALL:
        return intl.formatMessage(messages.all);
      default:
        return `    ${filter}`;
    }
  };
  _getOptions = () => {
    return [
      {
        id: COURSES_FILTERS.ALL,
        label: this._getFilterLabel(COURSES_FILTERS.ALL),
      },
      ...this.props.courses.map(course => ({
        id: course,
        label: this._getFilterLabel(course),
      })),
    ];
  };

  render() {
    const { filter, selectFilter, theme } = this.props;

    return (
      <div style={{ width: 250, padding: '15px 3px 3px 3px' }}>
        <Select
          maxWidth={220}
          buttonStyle={{
            padding: '6px 8px',
            borderWidth: 1,
            marginTop: -5,
          }}
          buttonTextStyle={{
            color: theme.palette.text.secondary,
          }}
          carretIcon="filter_list"
          value={{ id: filter, label: this._getFilterLabel(filter) }}
          options={this._getOptions()}
          onChange={x => selectFilter(x.id)}
        />
      </div>
    );
  }
}

export default injectIntl(withTheme(CoursesFiltering));
