import React from 'react';
import { connect } from 'react-redux';
import DetailedFoodLog from './DetailedFoodLog';
import { foodDiariesSelectors } from 'redux/modules/foodDiaries';
import { clientsSelectors, clientsOperations } from 'redux/modules/clients';
import { accountSelectors, accountOperations } from 'redux/modules/account';
import { detailedViewOperations } from 'redux/modules/UI/detailedView';
import FETCH_STATUS from 'constants/fetchStatus';
import { getIsCurrentClientNoFoodDiary } from 'redux/modules/foodDiaries/foodDiaries.selectors';

class DetailedFoodLogContainer extends React.Component {
  render() {
    return this.props.clientNutrients ? (
      <DetailedFoodLog {...this.props} />
    ) : null;
  }
}

const mapStateToProps = state => {
  const clientInfo = clientsSelectors.getCurrentClientInfo(state);
  const isFetching = foodDiariesSelectors.getFetchStatusCurrentFoodDiary(state);
  const isFetchingMonth = !foodDiariesSelectors.getIsMonthLoadedCurrentFoodDiary(
    state
  );
  const currentFoodDiary = foodDiariesSelectors.getCurrentFoodDiary(state);
  const hasNoFoodDiary = getIsCurrentClientNoFoodDiary(state);
  const isStillLoading =
    (!currentFoodDiary && !hasNoFoodDiary) ||
    isFetchingMonth ||
    (isFetching !== FETCH_STATUS.LOADED && isFetching !== undefined);
  return {
    dietitianNutrients: accountSelectors.getNutrients(state),
    clientNutrients: clientInfo ? clientInfo.nutrients : undefined,
    currentFoodDiary,
    hasNoFoodDiary,
    isStillLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  removeNutrientFromClient: (nutrient, nutrients) =>
    dispatch(
      clientsOperations.removeNutrientFromCurrentClient(nutrient, nutrients)
    ),
  addNutrientToToolbox: (nutrient, toolbox) =>
    dispatch(accountOperations.addNutrientToToolbox(nutrient, toolbox)),
  initDetailedView: nutrients =>
    dispatch(detailedViewOperations.init(nutrients)),
});

DetailedFoodLogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailedFoodLogContainer);

export default DetailedFoodLogContainer;
