import React from 'react';
import PhotoMosaic, { lengthToHeight } from './PhotoMosaic';
import Analytics from './Analytics';
import styled from 'styled-components';
import WhiteFrame from 'components/WhiteFrame';
import CommentBox from './CommentBox';
import MessageFeed from './MessageFeed';
import Meal from './Meal';

export const IMAGE_SIZE = 260;

const RightPanel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  padding-top: 8px;
`;

const Card = styled(WhiteFrame)`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
  min-height: ${IMAGE_SIZE}px;
`;

const BackgroundImage = styled.div`
  background-color: rgba(0, 0, 0, 0.12);
  height: ${IMAGE_SIZE}px;
  width: ${IMAGE_SIZE}px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
`;

class MealGroup extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (
      (nextProps === this.props && nextState === this.state) ||
      (!nextProps.mealsId.length && !this.props.mealsId.length)
    ) {
      return false;
    }
    return true;
  }

  renderContent = () => {
    const { mealsId, diaryId } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div style={{ minHeight: IMAGE_SIZE, position: 'relative' }}>
          <BackgroundImage />
          {mealsId.map((id, i) => (
            <div
              key={id}
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'flexStart',
                justifyContent: 'SpaceBetween',
                marginBottom: i < mealsId.length - 1 ? 60 : 20,
              }}
            >
              <PhotoMosaic meals={[id]} />
              <RightPanel>
                <Meal id={id} />
              </RightPanel>
            </div>
          ))}
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Analytics ids={mealsId} diaryId={diaryId} row />
        </div>
        <CommentBox meals={mealsId} />
        <MessageFeed meals={mealsId} />
      </div>
    );
  };
  render() {
    const { mealsId, inModal } = this.props;

    if (inModal)
      return (
        <div style={{ overflowY: 'auto', maxHeight: '85vh' }}>
          {this.renderContent()}
        </div>
      );

    return (
      <Card height={lengthToHeight(mealsId.length) + 50}>
        {this.renderContent()}
      </Card>
    );
  }
}

export default MealGroup;
