import types from './search.types';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import get from 'lodash/get';
import { refreshSearchKeyApi } from '../../../api/foods';

const TWO_HOURS_MS = 1000 * 60 * 2;

// REDUCERS
const searchKey = (state = null, action) => {
  if (action.type === types.REFRESH_SEARCH_KEY_SUCCESS) {
    return action.response;
  }
  return state;
};

export default combineReducers({
  searchKey,
});

// SELECTORS
export const _getRoot = state => get(state, 'search');
const _getSearchKey = createSelector(
  _getRoot,
  root => get(root, 'searchKey', null)
);
export const getSearchKey = createSelector(
  _getSearchKey,
  searchKey => get(searchKey, 'key')
);
export const getSearchKeyExpiration = createSelector(
  _getSearchKey,
  searchKey => get(searchKey, 'validUntil')
);

// ACTIONS
export const refreshSearchKey = () => ({
  types: [
    types.REFRESH_SEARCH_KEY_REQUEST,
    types.REFRESH_SEARCH_KEY_SUCCESS,
    types.REFRESH_SEARCH_KEY_ERROR,
  ],
  shouldCallAPI: state => {
    const key = getSearchKey(state);
    if (!key) return true;

    const exp = getSearchKeyExpiration(state);
    const now = Date.now();
    const diff = exp - now;
    return diff < TWO_HOURS_MS;
  },
  callAPI: () => refreshSearchKeyApi(),
});
