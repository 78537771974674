import React from 'react';
import { TextField } from 'components/forms/Textfield';
import { defineMessages, useIntl } from 'react-intl';
import { SPACINGS, SIZES } from 'components/Spacer';

const messages = defineMessages({
  placeholder: { id: 'foods.create.pickFoodName.placeholder' },
});

export function PickFoodName({ value, setValue }) {
  const intl = useIntl();

  return (
    <div
      style={{
        width: 'calc(578px + (100% - 1049px)*.16666667)',
        paddingLeft: SIZES[SPACINGS.medium],
        paddingRight: SIZES[SPACINGS.medium],
      }}
    >
      <TextField
        autoFocus
        value={value}
        onChange={e => setValue(e.target.value)}
        fullwidth
        name="name"
        className="title"
        placeholder={intl.formatMessage(messages.placeholder)}
      />
    </div>
  );
}
