import React from 'react';
import { DataTableRow, DataTableCell } from 'components/material/DataTable';
import { defineMessages } from 'react-intl';
import { ONBOARD_IDS, FICTIVE_CLIENT_IDS } from '../../../../constants';
import ClientCheckbox from '../ClientCheckbox';
import { useHistory } from 'react-router-dom';
import SimpleTour from 'components/SimpleTour';
import { CLIENT_STATUS } from 'constants/clientStatus';

const messages = defineMessages({
  action: {
    id: 'clients.action',
    defaultMessage: ' ',
  },
  performanceTitle: {
    id: 'clients.performance.title',
  },
  performanceHelp: {
    id: 'clients.performance.help',
  },
  title: {
    id: 'clients.title',
  },
  fictiveTitle: {
    id: 'client.fictive.title',
    defaultMessage: 'Fictive client 👀',
  },
  fictiveContent: {
    id: 'client.fictive.content',
    defaultMessage:
      'While your clients accept your invitation and start snapping pictures of their meals, we have added a fictive client for you to explore the platform. Click here to view this client’s photos and dietary intake.',
  },
  fictiveClose: {
    id: 'client.fictive.close',
    defaultMessage: 'Awesome !',
  },
});

const Checkbox = React.memo(_Checkbox);
function _Checkbox({ id }) {
  return <ClientCheckbox id={id} />;
}

function Row({
  id,
  status,
  selected,
  columns,
  canRunOnboard,
  setFictiveClientRun,
}) {
  const history = useHistory();
  const isFictive = FICTIVE_CLIENT_IDS.has(id);

  const onClickClient =
    id && status !== CLIENT_STATUS.PENDING
      ? () => history.push(`/clients/${id}`)
      : undefined;

  const renderRow = ({ props, onClick, render }) => (
    <DataTableCell onClick={onClick} {...props}>
      {render(id)}
    </DataTableCell>
  );

  const renderContent = onClickContent => (
    <DataTableRow
      key={id}
      selected={selected}
      className={isFictive ? 'fictive-client' : ''}
    >
      <DataTableCell alignEnd>
        <Checkbox id={id} />
      </DataTableCell>
      {columns.map(c => renderRow({ ...c, onClick: onClickContent }))}
    </DataTableRow>
  );

  const renderFictive = () => {
    return (
      <SimpleTour
        key={`${ONBOARD_IDS.FICTIVE_CLIENT}_${id}`}
        onboardId={ONBOARD_IDS.FICTIVE_CLIENT}
        target=".fictive-client"
        titleMsg={messages.fictiveTitle}
        contentMsg={messages.fictiveContent}
        closeMsg={messages.fictiveClose}
        joyrideOptions={{
          event: 'hover',
          spotlightClicks: true,
          hideFooter: true,
          disableBeacon: true,
        }}
        canRun={canRunOnboard}
      >
        {renderContent(() => {
          setFictiveClientRun();
          onClickClient();
        })}
      </SimpleTour>
    );
  };

  const renderReal = () => renderContent(onClickClient);

  return isFictive ? renderFictive() : renderReal();
}

export const ClientRow = React.memo(Row);
