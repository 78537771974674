import React from 'react';
import Modal from 'components/Modal';
import { defineMessages, FormattedMessage } from 'react-intl';
import Typography from 'components/material/Typography';
import styled from 'styled-components';
import { AVAILABLE_TIME_FORMATS } from '../../../../constants';
import Spacer from 'components/Spacer';

const messages = defineMessages({
  title: { id: 'general.timeformat.title' },
  subtitle: { id: 'general.timeformat.subtitle' },
});

const LocaleWrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: flex-start;
  color: ${props => props.theme.palette.primary1};
  padding: 20px;

  &:hover {
    cursor: pointer;
    filter: contrast(1.5);
  }
`;

export default function EditTimeFormat({
  visible,
  onDismiss,
  setTimeFormatId,
}) {
  return (
    <Modal isOpen={visible} onClose={onDismiss}>
      <Typography use="headline6" rank="primary" tag="div">
        <FormattedMessage {...messages.title} />
      </Typography>
      <Typography use="body2" rank="secondary" tag="div">
        <FormattedMessage {...messages.subtitle} />
      </Typography>
      <Spacer spacing="lg" />
      <div style={{ display: 'flex' }}>
        {AVAILABLE_TIME_FORMATS.map(x => (
          <LocaleWrapper
            key={x.id}
            onClick={() => {
              setTimeFormatId(x.id);
              onDismiss();
            }}
          >
            <Typography use="subtitle1">{x.label}</Typography>
          </LocaleWrapper>
        ))}
      </div>
    </Modal>
  );
}
