import React from 'react';
import Group from './Group';
import { connect } from 'react-redux';
import { getGroupOptions } from 'redux/modules/UI/builderView/builderView.selectors';
import {
  selectOption,
  unselectOption,
} from 'redux/modules/UI/builderView/builderView.actions';
import isEqual from 'lodash/isEqual';

const mapStateToProps = (state, { namespace, id }) => ({
  selectedOptions: getGroupOptions(state, namespace, id),
});

const mapDispatchToProps = (dispatch, { namespace }) => ({
  select: (optionId, groupId, isManual) =>
    dispatch(selectOption(namespace)(optionId, groupId, isManual)),
  unselect: (optionId, groupId, isManual) =>
    dispatch(unselectOption(namespace)(optionId, groupId, isManual)),
});

class GroupWrapper extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.id !== this.props.id ||
      !isEqual(nextProps.selectedOptions, this.props.selectedOptions)
    ) {
      return true;
    }
    return false;
  }
  onSelect = (optionId, isManual = false) => {
    const { isMultiple, selectedOptions, unselect, select, id } = this.props;
    if (!isMultiple) {
      selectedOptions.map(oId => unselect(oId, id, false));
    }
    select(optionId, id, isManual);
  };
  onUnselect = (optionId, isManual = false) => {
    const { isRequired, isSubgroup, unselect, id } = this.props;
    if (isRequired && isSubgroup) return;
    unselect(optionId, id, isManual);
  };

  render() {
    return (
      <Group
        {...this.props}
        onSelect={this.onSelect}
        onUnselect={this.onUnselect}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupWrapper);
