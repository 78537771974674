import React from 'react';
import WhiteFrame from 'components/WhiteFrame';
import GroupModal from './GroupModal';
import DayHeaders from './DayHeaders';
import ChartContent from './ChartContent';

class CompareCharts extends React.PureComponent {
  render() {
    return (
      <WhiteFrame free style={{ maxWidth: 'calc(100vw - 350px)', padding: 0 }}>
        <DayHeaders />
        <ChartContent />
        <GroupModal />
      </WhiteFrame>
    );
  }
}

export default CompareCharts;
