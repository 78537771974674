import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import reportViewTypes from '../reportView.types';
import omit from 'lodash/omit';
import get from 'lodash/get';
import createCachedSelector from 're-reselect';
import {
  nutrimentsConstants,
  idToConstant,
} from '../../../../../constants/nutriments';
import sortBy from 'lodash/sortBy';
import keys from 'lodash/keys';
import flatMap from 'lodash/flatMap';
import uniqBy from 'lodash/uniqBy';

const DEFAULT_CHUNK_HOURS = {
  0: {
    start: 7,
    end: 12,
  },
  1: {
    start: 12,
    end: 17,
  },
  2: {
    start: 17,
    end: 22,
  },
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case reportViewTypes.ADD_WIDGET: {
      return { ...state, [action.id]: action.payload };
    }
    case reportViewTypes.REMOVE_WIDGET: {
      return omit(state, action.widgetId);
    }
    case reportViewTypes.UPDATE_WIDGET: {
      const widgetState = state[action.widgetId];
      if (!widgetState) return state;

      return {
        ...state,
        [action.widgetId]: { ...widgetState, ...action.payload },
      };
    }
    case reportViewTypes.SET_WIDGET_TITLE:
      return {
        ...state,
        [action.widgetId]: {
          ...(state[action.widgetId] || {}),
          title: action.title,
        },
      };
    case reportViewTypes.SET_WIDGET_SECTION:
      const widgetState = state[action.widgetId];
      if (!widgetState) return state;

      const widgetSections = get(widgetState, 'sections', {});

      return {
        ...state,
        [action.widgetId]: {
          ...widgetState,
          sections: { ...widgetSections, [action.section]: action.value },
        },
      };
    case reportViewTypes.LOAD_STRUCTURE:
      return action.payload.widgets;
    default:
      return state;
  }
};

export default combineReducers({
  byId,
});

/** SELECTORS */
const _getWidgets = state => get(state, 'UI.reportView.widgets');

/** (state) */
export const getById = createSelector(
  _getWidgets,
  x => get(x, 'byId')
);

/** (state, widgetId) */
export const getWidget = createCachedSelector(
  [getById, (_, widgetId) => widgetId],
  (byId, widgetId) => byId[widgetId]
)((_, widgetId) => widgetId);

/** (state, widgetId) */
export const getRichTextContent = createSelector(
  getWidget,
  widget => get(widget, 'content')
);

/** (state, widgetId) */
export const getWidgetNutrientsIds = createCachedSelector(getWidget, widget =>
  get(widget, 'nutrients', [])
)((_, widgetId) => widgetId);

const _parentToOrder = parent => {
  const nc = nutrimentsConstants;
  if (parent === nc.CARBS.id) return nc.CARBS.order;
  if (parent === nc.PROTEIN.id) return nc.PROTEIN.order;
  if (parent === nc.FAT.id) return nc.FAT.order;

  // should not return 4.
  return 4;
};

export const _getCompleteNutrients = createSelector(
  getWidgetNutrientsIds,
  nutrients => nutrients.map(id => idToConstant[id])
);
export const getWidgetNutrients = createCachedSelector(
  _getCompleteNutrients,
  nutrients => {
    const withFirstOrder = nutrients.map(nutrient => ({
      ...nutrient,
      order: nutrient.children ? 0 : nutrient.order,
      firstOrder: nutrient.parent
        ? _parentToOrder(nutrient.parent)
        : nutrient.order,
    }));
    return sortBy(withFirstOrder, ['firstOrder', 'order']);
  }
)((_, widgetId) => widgetId);

export const getCompleteExportNutrients = createSelector(
  state => {
    const ids = keys(getById(state));
    return uniqBy(flatMap(ids, id => _getCompleteNutrients(state, id)), 'id');
  },
  nutrients => nutrients
);

/** (state, widgetId) */
export const getWidgetTitle = createSelector(
  [getWidget],
  widget => get(widget, 'title', null)
);

/** (state, widgetId, sectionName) */
export const getWidgetSection = createSelector(
  [getWidget, (a, b, sectionName) => sectionName],
  (widget, sectionName) => get(widget, ['sections', sectionName], true)
);

const _defaultHours = widgetId => {
  const chunk = widgetId.split('_')[1];
  return DEFAULT_CHUNK_HOURS[chunk];
};

/** (state, widgetId) */
export const getWidgetStartHour = createSelector(
  [getWidget],
  widget => get(widget, ['startHour'], _defaultHours(widget.id).start)
);
export const getWidgetEndHour = createSelector(
  [getWidget],
  widget => get(widget, ['endHour'], _defaultHours(widget.id).end)
);
