import React, { useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { Elevation } from 'components/material/Elevation';
import { WIDGET_TYPES, DROPPABLE_IDS } from '../../../../../constants';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import '@material/elevation/dist/mdc.elevation.css';
import SettingTitle from '../../SettingTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notify } from 'react-notify-toast';

const messages = defineMessages({
  summaryTitle: { id: 'contentTab.summary.title' },
  clientTitle: { id: 'contentTab.client.title' },
  organizationTitle: { id: 'contentTab.organization.title' },
  recommendationsTitle: { id: 'contentTab.recommendations.title' },

  clientInfo: { id: 'contentTab.summary.clientInfo' },

  dailyMacro: { id: 'contentTab.summary.dailyMacro' },
  dailyAverage: { id: 'contentTab.summary.dailyAverage' },
  nutrientsSummary: { id: 'contentTab.summary.nutrientsSummary' },
  visualOverview: { id: 'contentTab.summary.visualOverview' },
  note: { id: 'contentTab.summary.tab' },

  dragWarning: { id: 'contentTab.dragWarning' },
});

const Card = styled(Elevation)`
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  margin: 4px;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 6px;
  background-color: white;
  position: relative;
`;

const WidgetsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const WIDGET_TYPE_TO_CONFIG = {
  [WIDGET_TYPES.CLIENT_INFO]: {
    title: messages.clientInfo,
    type: WIDGET_TYPES.CLIENT_INFO,
    icon: 'person',
  },
  [WIDGET_TYPES.DAILY_MACRO]: {
    title: messages.dailyMacro,
    type: WIDGET_TYPES.DAILY_MACRO,
    icon: 'bar_chart',
  },
  [WIDGET_TYPES.DAILY_AVERAGE]: {
    title: messages.dailyAverage,
    type: WIDGET_TYPES.DAILY_AVERAGE,
    icon: 'pie_chart',
  },
  [WIDGET_TYPES.NUTRIENTS_SUMMARY]: {
    title: messages.nutrientsSummary,
    type: WIDGET_TYPES.NUTRIENTS_SUMMARY,
    icon: 'table_chart',
  },
  [WIDGET_TYPES.VISUAL_OVERVIEW]: {
    title: messages.visualOverview,
    type: WIDGET_TYPES.VISUAL_OVERVIEW,
    icon: 'today',
  },
};

const SECTIONS = [
  {
    title: messages.organizationTitle,
    widgets: [WIDGET_TYPE_TO_CONFIG[WIDGET_TYPES.CLIENT_INFO]],
  },
  {
    title: messages.summaryTitle,
    widgets: [
      WIDGET_TYPE_TO_CONFIG[WIDGET_TYPES.DAILY_MACRO],
      WIDGET_TYPE_TO_CONFIG[WIDGET_TYPES.DAILY_AVERAGE],
      WIDGET_TYPE_TO_CONFIG[WIDGET_TYPES.NUTRIENTS_SUMMARY],
      WIDGET_TYPE_TO_CONFIG[WIDGET_TYPES.VISUAL_OVERVIEW],
    ],
  },
  {
    title: messages.recommendationsTitle,
    widgets: [
      {
        title: messages.note,
        type: WIDGET_TYPES.RICH_TEXT,
        icon: 'text_fields',
      },
    ],
  },
];

const WidgetText = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${x => x.theme.palette.text.secondary};
  text-align: center;
  flex: 1;
`;

const DraggingIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  border-radius: 6px 0 0 6px;
  background-color: ${x => x.theme.palette.primary3};
`;

function WidgetCard_({ icon, title, isDragging, theme, intl }) {
  const [elevation, setElevation] = useState(1);
  return (
    <Card
      z={isDragging ? 4 : elevation}
      transition
      onMouseOver={() => setElevation(4)}
      onMouseOut={() => setElevation(1)}
      onClick={() =>
        notify.show(intl.formatMessage(messages.dragWarning), 'custom', 3000, {
          background: theme.palette.warning,
          text: 'white',
        })
      }
    >
      {isDragging && <DraggingIndicator />}
      <div
        style={{
          position: 'absolute',
          top: 5,
          right: 5,
          opacity: 0.17,
          fontSize: '0.8em',
        }}
      >
        <FontAwesomeIcon icon={['far', 'arrows']} />
      </div>
      <div
        style={{
          flex: 1.5,
          display: 'flex',
          alignItems: 'center',
          color: 'rgba(0,0,0,0.54)',
        }}
      >
        <div className="material-icons">{icon}</div>
      </div>
      <WidgetText>
        <FormattedMessage {...title} />
      </WidgetText>
    </Card>
  );
}
const WidgetCard = injectIntl(withTheme(WidgetCard_));

class ContentTab extends React.PureComponent {
  renderWidget = ({ title, type, icon }, index) => {
    return (
      <Draggable key={type} draggableId={type} index={index}>
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <WidgetCard
                icon={icon}
                title={title}
                isDragging={snapshot.isDragging}
              />
            </div>
          );
        }}
      </Draggable>
    );
  };

  renderSection = obj => {
    const { title, widgets } = obj;
    return (
      <div key={title.id} style={{ marginBottom: 25 }}>
        <SettingTitle message={title} />
        <Droppable
          direction="horizontal"
          droppableId={DROPPABLE_IDS.CONTENT_TAB}
          isDropDisabled={true}
        >
          {provided => (
            <div ref={provided.innerRef}>
              <WidgetsWrapper>{widgets.map(this.renderWidget)}</WidgetsWrapper>
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  render() {
    return <div>{SECTIONS.map(this.renderSection)}</div>;
  }
}

export default ContentTab;
