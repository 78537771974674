import CreateTemplateModal from './CreateTemplateModal';
import { connect } from 'react-redux';

import {
  closeModal,
  getModalOpen,
  MODAL_TYPES,
  getModalProps,
} from 'redux/modules/modals/modals';
import {
  getTemplateEmoji,
  getTemplateTitle,
  getTemplateDescription,
} from 'redux/modules/reportTemplates';
import get from 'lodash/get';
import { generateTemplate } from 'redux/modules/UI/reportView/reportView.operations';
import { updateReportTemplate } from 'redux/modules/reportTemplates/reportTemplates.actions';
import { duplicateTemplate } from 'redux/modules/reportTemplates/reportTemplates.operations';

const mapStateToProps = state => {
  const templateId = get(getModalProps(state), 'templateId');
  const isDuplicate = !!get(getModalProps(state), 'isDuplicate');

  let startTitle = !templateId ? '' : getTemplateTitle(state, templateId);
  if (isDuplicate) startTitle += ' - copy';

  return {
    isOpen: getModalOpen(state, MODAL_TYPES.CREATE_REPORT_TEMPLATE),

    startEmoji: !templateId ? '' : getTemplateEmoji(state, templateId),
    startTitle,
    startDescription: !templateId
      ? ''
      : getTemplateDescription(state, templateId),

    isUpdate: !!templateId && !isDuplicate,
    isDuplicate,
    templateId,
  };
};

const mapDispatchToProps = dispatch => ({
  createTemplate: (title, description, emoji) =>
    dispatch(generateTemplate(title, description, emoji)),
  updateTemplate: (templateId, update) =>
    dispatch(updateReportTemplate(templateId, update)),
  duplicateTemplate: (templateId, update) =>
    dispatch(duplicateTemplate(templateId, update)),
  onExit: () => dispatch(closeModal(MODAL_TYPES.CREATE_REPORT_TEMPLATE)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  ...dispatchProps,
  updateTemplate: (title, description, emoji) =>
    dispatchProps.updateTemplate(stateProps.templateId, {
      title,
      description,
      emoji,
    }),
  duplicateTemplate: (title, description, emoji) =>
    dispatchProps.duplicateTemplate(stateProps.templateId, {
      title,
      description,
      emoji,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateTemplateModal);
