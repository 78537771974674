import ReportCheckbox from './ReportCheckbox';
import { connect } from 'react-redux';
import {
  selectReport,
  unselectReport,
  getReportIsSelected,
} from 'redux/modules/UI/reportsView';

const mapStateToProps = (state, { id }) => ({
  selected: getReportIsSelected(state, id),
});

const mapDispatchToProps = dispatch => ({
  select: id => dispatch(selectReport(id)),
  unselect: id => dispatch(unselectReport(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportCheckbox);
