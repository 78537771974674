import { schema } from 'normalizr';

export const client = new schema.Entity(
  'clients',
  {},
  {
    idAttribute: value => {
      if (value.id) return value.id;
      if (value.code) return value.code;
      return value.invite_email;
    },
  }
);
export const arrayOfClient = [client];

export const entry = new schema.Entity('entries');

export const photo = new schema.Entity('photos');

export const comment = new schema.Entity('comments');
export const arrayOfComment = [comment];
export const meal = new schema.Entity('meals', {
  photo: photo,
  waste_photo: photo,
  entries: [entry],
  comments: [comment],
});

export const foodDiary = new schema.Entity('foodDiaries', {
  meals: [meal],
});
export const arrayOfFoodDiary = [foodDiary];

export const food = new schema.Entity('foods');

export const invoice = new schema.Entity('invoices', {});
export const arrayOfInvoice = [invoice];

export const subscription = new schema.Entity('subscriptions', {});
export const arrayOfSubscription = [subscription];

export const source = new schema.Entity('sources', {});
export const arrayOfSources = [source];

export const widget = new schema.Entity('widgets');

export const reportTemplateVersion = new schema.Entity('templateVersions');
export const reportTemplate = new schema.Entity('reportTemplates', {
  version: reportTemplateVersion,
});
export const arrayOfReportTemplate = [reportTemplate];

export const reportSetting = new schema.Entity('reportSettings');

export const reportVersion = new schema.Entity('reportVersions');
export const report = new schema.Entity('reports', {
  version: reportVersion,
});
export const arrayOfReport = [report];

export const builder = new schema.Entity('builders');
export const publicBuilder = new schema.Entity('publicBuilders', { builder });
export const builderConfig = new schema.Entity('builderConfigs', {
  food,
  builder,
});

export const userFood = new schema.Entity('userFoods');
export const arrayOfUserFoods = [userFood];

export const study = new schema.Entity('studies');
export const arrayOfStudy = [study];

export default {
  arrayOfFoodDiary,
  foodDiary,
  meal,
  photo,
  food,
  entry,
  client,
  widget,

  arrayOfSubscription,
  subscription,

  arrayOfInvoice,
  invoice,

  source,
  arrayOfSources,

  comment,
  arrayOfComment,

  reportTemplate,
  reportTemplateVersion,
  reportSetting,
  report,
  reportVersion,
  arrayOfReport,

  builder,
  builderConfig,
  publicBuilder,

  study,
  arrayOfStudy,
};
