import { connect } from 'react-redux';
import { getOnboard } from 'redux/modules/account/account.selectors';
import { updateOnboard } from 'redux/modules/account/account.actions';
import OnboardVideos, { ONBOARD_VIDEO_WIDTH as OVW } from './OnboardVideos';
import { updateModalProps } from 'redux/modules/modals/modals';

const mapStateToProps = state => ({
  onboard: getOnboard(state),
});

const mapDispatchToProps = dispatch => ({
  updateOnboard: newOnboard => dispatch(updateOnboard(newOnboard)),
  updateModalProps: newProps => dispatch(updateModalProps(newProps)),
});

export const ONBOARD_VIDEO_WIDTH = OVW;
export default connect(mapStateToProps, mapDispatchToProps)(OnboardVideos);
