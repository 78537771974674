import { connect } from 'react-redux';
import {
  getModalOpen,
  MODAL_TYPES,
  getModalProps,
  updateModalProps,
  closeModal,
  closeAllModals,
} from 'redux/modules/modals/modals';
import {
  updateEntry,
  addEntry,
} from 'redux/modules/entries/entries.operations';
import EditEntryModal from './EditEntryModal';
import { getEntryFoodId } from 'redux/modules/entries/entries.selectors';
import get from 'lodash/get';

const mapStateToProps = state => {
  const editOpen = getModalOpen(state, MODAL_TYPES.EDIT_ENTRY);
  const newOpen = getModalOpen(state, MODAL_TYPES.NEW_ENTRY);

  const entryId = get(getModalProps(state), 'entryId', null);
  const foodId =
    get(getModalProps(state), 'foodId', null) || getEntryFoodId(state, entryId);

  return {
    isOpen: editOpen || newOpen,
    isEdit: editOpen,
    foodId,
    entryId,
    mealId: get(getModalProps(state), 'mealId', null),

    namespace: get(getModalProps(state), 'namespace', null),
  };
};

const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(closeModal(MODAL_TYPES.EDIT_ENTRY));
    dispatch(closeModal(MODAL_TYPES.NEW_ENTRY));
  },

  setNamespace: namespace => dispatch(updateModalProps({ namespace })),

  onSubmit: async (newEntry, { entryId, mealId }) => {
    if (entryId) {
      // If we have an id -> its necessarely an update
      await dispatch(updateEntry({ id: entryId, ...newEntry }));
    } else {
      await dispatch(addEntry(newEntry, mealId));
    }
    dispatch(closeAllModals());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEntryModal);
