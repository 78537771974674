import React from 'react';
import { CardElement } from 'react-stripe-elements';
import { Button } from 'components/material/Button';
import { TextField } from 'components/forms/Textfield';
import Spacer from 'components/Spacer';
import './CheckoutForm.css';
import styled, { withTheme } from 'styled-components';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import indexOf from 'lodash/indexOf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// https://stripe.com/docs/api#errors
const SUPPORTED_CARD_ERRORS = [
  'expired_card', // The card has expired.
  'incorrect_cvc', // 	The card's security code is incorrect.
  'incorrect_zip', //	The card's zip code failed validation.
  'card_declined', //	The card was declined.
  'processing_error', // An error occurred while processing the card.
];

const messages = defineMessages({
  saveButton: {
    id: 'billing.checkoutButton',
    defaultMessage: 'Save',
  },
  billingName: {
    id: 'billing.name',
    defaultMessage: 'Name on card',
  },
  [SUPPORTED_CARD_ERRORS[0]]: {
    id: 'billing.expiredError',
  },
  [SUPPORTED_CARD_ERRORS[1]]: {
    id: 'billing.incorectCVC',
  },
  [SUPPORTED_CARD_ERRORS[2]]: {
    id: 'billing.incorrectZIP',
  },
  [SUPPORTED_CARD_ERRORS[3]]: {
    id: 'billing.cardDeclined',
  },
  [SUPPORTED_CARD_ERRORS[4]]: {
    id: 'billing.processingError',
  },
  unknownError: {
    id: 'billing.unknownError',
  },
  sending: {
    id: 'billing.savingCard',
  },
});

const CardWrapper = styled.div`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid;
  border-color: ${x => (!x.focused ? '#9e9e9e' : x.theme.palette.primary1)};
  border-radius: 3px;
  transition: border box-shadow 0.25s ease-in-out;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 17px;

  box-shadow: ${x =>
    !x.focused
      ? 'none'
      : `inset 0 0 transparent, 0 0 0 1px ${x.theme.palette.primary1}`};

  &:hover {
    border-color: ${x => (x.focused ? x.theme.palette.primary1 : 'black')};
  }
`;

const ErrorWrapper = styled.div`
  height: 20px;
  color: ${props => props.theme.palette.error};
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
`;

const ErrorBox = styled.div`
  background-color: ${props => props.theme.palette.errorBg};
  border: 1px solid ${props => props.theme.palette.canvas};
  padding: 15px;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 10px;
  color: ${props => props.theme.palette.error};
  text-align: center;
`;
const ErrorBoxIcon = styled.div`
  font-size: 28px;
`;

const CardError = ({ errorType }) => {
  if (!errorType) return null;
  return (
    <ErrorBox>
      <ErrorBoxIcon>
        <FontAwesomeIcon icon={['fad', 'credit-card']} />
      </ErrorBoxIcon>
      <div className="mdc-typography--body2">
        <FormattedMessage {...messages[errorType]} />
      </div>
    </ErrorBox>
  );
};

const createOptions = theme => {
  return {
    style: {
      base: {
        color: theme.palette.text.primary,
        fontFamily: 'Roboto, sans-serif',
        fontSize: '16px',
        fontWeight: 300,
        fontSmoothing: 'antialiased',
        flex: 1,
      },
      invalid: {
        color: theme.palette.error,
      },
    },
  };
};

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      isReady: false,
      error: '',
      loading: false,
      cardError: '',
    };
  }

  handleSubmit = ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    this.setState({
      loading: true,
    });

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    this.props.stripe
      .createToken({ type: 'card', name: this.state.billingName })
      .then(({ error, token }) => {
        if (error) {
          return this.setState({
            error: error.message,
            loading: false,
          });
        }
        return this.props.submitToken(token);
      })
      .then(({ type, error }) => {
        if (!error) {
          return this.props.close();
        } else {
          this._cardElement.clear();
          if (indexOf(SUPPORTED_CARD_ERRORS, error.type) > -1) {
            this.setState({
              cardError: error.type,
              loading: false,
            });
          } else {
            this.setState({
              cardError: 'unknownError',
              loading: false,
            });
          }
        }
      });
  };

  _onFocus = () => {
    this.setState({
      isFocused: true,
    });
  };
  _onBlur = () => {
    this.setState({
      isFocused: false,
    });
  };
  _onChange = ({ error, brand, complete }) => {
    if (complete !== this.state.isReady) {
      this.setState({
        isReady: complete,
      });
    }
    if (error) {
      this.setState({
        error: error.message,
      });
    }
    if (this.state.error && !error) {
      this.setState({
        error: '',
      });
    }
    if (this.state.cardError && complete) {
      this.setState({
        cardError: '',
      });
    }
  };

  _onNameChange = e =>
    this.setState({
      billingName: e.target.value,
    });

  _canSubmit = () =>
    this.state.isReady &&
    this.state.billingName &&
    this.state.billingName.trim() !== '';

  render() {
    const { intl } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          outlined
          style={{ width: '100%' }}
          value={this.state.billingName}
          onChange={this._onNameChange}
          label={intl.formatMessage(messages.billingName)}
          autoFocus={true}
        />
        <Spacer spacing="md" />
        <CardWrapper focused={this.state.isFocused}>
          <CardElement
            style={{ flex: 1 }}
            elementRef={c => (this._cardElement = c)}
            onFocus={this._onFocus}
            onBlur={this._onBlur}
            onChange={this._onChange}
            {...createOptions(this.props.theme)}
          />
        </CardWrapper>
        <ErrorWrapper>{this.state.error}</ErrorWrapper>
        <CardError errorType={this.state.cardError} />
        <Spacer spacing="md" />
        <Button
          onClick={this.handleSubmit}
          unelevated
          icon={'lock'}
          disabled={!this._canSubmit() || this.state.loading}
          style={{ float: 'right' }}
        >
          <FormattedMessage
            {...(!this.state.loading ? messages.saveButton : messages.sending)}
          />
        </Button>
      </form>
    );
  }
}

export default withTheme(injectIntl(CheckoutForm));
