export const pre = 'keenoa/templates/';

export default {
  CREATE_REPORT_TEMPLATE_REQUEST: `${pre}CREATE_REPORT_TEMPLATE_REQUEST`,
  CREATE_REPORT_TEMPLATE_SUCCESS: `${pre}CREATE_REPORT_TEMPLATE_SUCCESS`,
  CREATE_REPORT_TEMPLATE_FAILURE: `${pre}CREATE_REPORT_TEMPLATE_FAILURE`,

  UPDATE_REPORT_TEMPLATE_REQUEST: `${pre}UPDATE_REPORT_TEMPLATE_REQUEST`,
  UPDATE_REPORT_TEMPLATE_SUCCESS: `${pre}UPDATE_REPORT_TEMPLATE_SUCCESS`,
  UPDATE_REPORT_TEMPLATE_FAILURE: `${pre}UPDATE_REPORT_TEMPLATE_FAILURE`,

  FETCH_REPORT_TEMPLATE_REQUEST: `${pre}FETCH_REPORT_TEMPLATE_REQUEST`,
  FETCH_REPORT_TEMPLATE_SUCCESS: `${pre}FETCH_REPORT_TEMPLATE_SUCCESS`,
  FETCH_REPORT_TEMPLATE_FAILURE: `${pre}FETCH_REPORT_TEMPLATE_FAILURE`,

  FETCH_REPORT_TEMPLATES_REQUEST: `${pre}FETCH_REPORT_TEMPLATES_REQUEST`,
  FETCH_REPORT_TEMPLATES_SUCCESS: `${pre}FETCH_REPORT_TEMPLATES_SUCCESS`,
  FETCH_REPORT_TEMPLATES_FAILURE: `${pre}FETCH_REPORT_TEMPLATES_FAILURE`,

  DELETE_REPORT_TEMPLATES_REQUEST: `${pre}DELETE_REPORT_TEMPLATES_REQUEST`,
  DELETE_REPORT_TEMPLATES_SUCCESS: `${pre}DELETE_REPORT_TEMPLATES_SUCCESS`,
  DELETE_REPORT_TEMPLATES_FAILURE: `${pre}DELETE_REPORT_TEMPLATES_FAILURE`,
};
