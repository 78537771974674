import { connect } from 'react-redux';
import {
  getOnboard,
  getFullName,
} from 'redux/modules/account/account.selectors';
import SimpleTour from './SimpleTour';
import { setOnboardRun } from 'redux/modules/account/account.operations';

const mapStateToProps = state => ({
  onboard: getOnboard(state),
  profileLoaded: !!getFullName(state),
});

const mapDispatchToProps = dispatch => ({
  setOnboardRun: onboardId => dispatch(setOnboardRun(onboardId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SimpleTour);
