import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, defineMessages } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const messages = defineMessages({
  expires: { id: 'billing.expires' },
});

const Wrapper = styled.div`
  position: relative;
  padding: 15px;
  border-bottom: 1px solid ${props => props.theme.palette.canvas};
`;

const Expires = styled.span`
  position: relative;
  top: -4px;
  margin-right: 5px;
  margin-left: 15px;
  font-size: 10px !important;
  color: ${props => props.theme.palette.text.tertiary};
`;

export default ({ id, brand, exp_month, exp_year, last4 }, i) => {
  return (
    <Wrapper key={id} className="mdc-typography--subheading2">
      <FontAwesomeIcon icon={['fad', 'credit-card']} />{' '}
      {` ${brand} **** **** **** ${last4}`}
      <Expires className="mdc-typography--button">
        {' '}
        <FormattedMessage {...messages.expires} />
      </Expires>
      {`${exp_month} ${exp_year}`}
    </Wrapper>
  );
};
