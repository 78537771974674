import types from './auth.types';

const loginStart = () => ({
  type: types.LOGIN_START,
});
const loginSuccess = info => ({
  type: types.LOGIN_SUCCESS,
  info,
});
const loginFailure = err => ({
  type: types.LOGIN_FAILURE,
  errorType: err.type,
});

const signupStart = () => ({
  type: types.SIGNUP_START,
});
const signupSuccess = info => ({
  type: types.SIGNUP_SUCCESS,
  info,
});
const signupFailure = err => ({
  type: types.SIGNUP_FAILURE,
  errorType: err.type,
});

const passwordResetSent = () => ({
  type: types.PASSWORD_RESET_SENT,
});
const passwordResetSuccess = () => ({
  type: types.PASSWORD_RESET_SUCCESS,
});
const passwordResetFailure = err => ({
  type: types.PASSWORD_RESET_FAILURE,
  errorType: err.type || 'unknown',
});
const executePasswordResetFailure = err => ({
  type: types.EXECUTE_PASSWORD_RESET_FAILURE,
  errorType: err.type || 'unknown',
});

const profileLoadSuccess = info => ({
  type: types.PROFILE_LOAD_SUCCESS,
  info,
});
const profileLoadUnauthorized = () => ({
  type: types.PROFILE_LOAD_UNAUTHORIZED,
});

const logout = () => ({
  type: types.LOGOUT,
});

export default {
  loginStart,
  loginSuccess,
  loginFailure,
  signupStart,
  signupSuccess,
  signupFailure,
  passwordResetSent,
  passwordResetSuccess,
  passwordResetFailure,
  executePasswordResetFailure,
  profileLoadSuccess,
  profileLoadUnauthorized,
  logout,
};
