import React, { useEffect, useState } from 'react';
import Picker from '../Picker';
import { useSelector } from 'react-redux';
import {
  getNamespaceMeasures,
  getNamespaceBuilderReady,
} from 'redux/modules/UI/builderView/builderView.selectors';
import { FRACTION_OPTIONS } from '../../../../../../constants/fractions';
import {
  getFractionOptionFromQuantity,
  getTotalQuantity,
  adjustNutrientsValuesByUnit,
  getBestMatchingMeasure,
} from '../../../../../../utils/selectedFoods';
import get from 'lodash/get';
import PlaceholderPicker from '../PlaceholderPicker';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notify } from 'react-notify-toast';

const messages = defineMessages({
  help: { id: 'foodModal.creator.help' },
  submit: { id: 'foodModal.creator.submit' },
  error: { id: 'foodModal.creator.error' },
});

function QuantityPicker({
  builderId,
  namespace,
  createBuilderConfig,
  onSubmit,
  startQuantity,
  startMeasureId,
  theme,
  intl,
  loading,
}) {
  const [measure, setMeasure] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [fraction, setFraction] = useState(FRACTION_OPTIONS[0]);

  const [selectedQty, setSelectedQty] = useState(false);

  const measures = useSelector(state =>
    getNamespaceMeasures(state, namespace, builderId)
  );
  const isReady = useSelector(state =>
    getNamespaceBuilderReady(state, namespace, builderId)
  );

  useEffect(() => {
    if (!measures || !measures.length) return;

    if (quantity !== null) {
      setMeasure(getBestMatchingMeasure(measures, null, quantity));
    } else if (startQuantity) {
      const newFraction = getFractionOptionFromQuantity(
        startQuantity,
        FRACTION_OPTIONS
      );
      setFraction(newFraction);
      setQuantity(parseInt(startQuantity, 10));
      setMeasure(
        getBestMatchingMeasure(measures, startMeasureId, startQuantity)
      );
    } else {
      const nextMeasure = measures[0];
      setMeasure(nextMeasure);
      setFraction(
        getFractionOptionFromQuantity(
          nextMeasure.initialValue,
          FRACTION_OPTIONS
        )
      );
      setQuantity(parseInt(nextMeasure.initialValue, 10));
    }
  }, [measures]);

  const _onSubmit = async () => {
    const totalQty = getTotalQuantity(quantity, fraction.value);
    try {
      const config = await createBuilderConfig(builderId);
      const foodId = get(
        config,
        `response.entities.builderConfigs.${config.response.result}.food`
      );
      if (!foodId) throw new Error('No food id created with config.');
      const food = get(config, `response.entities.foods.${foodId}`);
      const m = food.measures.find(
        ({ measureid }) => measure.unit_measure_id === measureid
      );
      const nutrients = adjustNutrientsValuesByUnit(food.nutrients, totalQty, {
        conversionFactorValue: m.conversionFactorValue,
        initialValue: m.initialvalue,
      });
      await onSubmit({
        food_id: foodId,
        food_description: food.description,
        quantity: totalQty,
        nutrients,
        unit: measure.unit,
        unit_name: measure.unit_name,
        unit_dimension: measure.unit_dimension,
        unit_measure_id: measure.unit_measure_id,
      });
    } catch (err) {
      notify.show(intl.formatMessage(messages.error), 'error');
    }
  };

  const _onMeasureChange = newMeasure => {
    if (!selectedQty) {
      const newQuantity = newMeasure.initialValue;
      if (!newMeasure.unit) {
        setFraction(FRACTION_OPTIONS[0]);
      } else {
        const newFraction = getFractionOptionFromQuantity(
          newQuantity,
          FRACTION_OPTIONS
        );
        setFraction(newFraction);
      }
      setQuantity(parseInt(newQuantity, 10));
    }
    setMeasure(newMeasure);
  };

  const renderMessage = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0.54,
        height: '100%',
      }}
    >
      <FontAwesomeIcon
        icon={['fas', 'exclamation-circle']}
        style={{ color: theme.palette.warning, marginRight: 3 }}
      />
      <FormattedMessage
        className="mdc-typography--caption"
        {...messages.help}
      />
    </div>
  );

  if (!isReady || !measure || !measures || !measures.length)
    return (
      <PlaceholderPicker
        renderMessage={!loading ? renderMessage : undefined}
        style={{ paddingTop: 10 }}
      />
    );

  return (
    <Picker
      isWholeUnit={measure.unit === null}
      quantity={quantity}
      onQuantityChange={q => {
        setQuantity(q);
        setSelectedQty(true);
      }}
      fraction={fraction}
      onFractionChange={f => setFraction(f)}
      measure={measure}
      measures={measures || []}
      onMeasureChange={_onMeasureChange}
      submitMessage={messages.submit}
      onSubmit={_onSubmit}
    />
  );
}

export default injectIntl(withTheme(QuantityPicker));
