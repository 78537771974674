import React from 'react';
import styled from 'styled-components';
import Color from 'color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from './material/Typography';

const bg = props =>
  Color(props.theme.palette[props.type])
    .alpha(0.33)
    .string();

const Wrapper = styled(Typography)`
  background-color: ${bg};
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
`;

export const InfoBox = ({ children }) => {
  return (
    <Wrapper use="body2" rank="midPrimary" tag="div" type={'primary1'}>
      <FontAwesomeIcon
        icon={['fas', 'info-circle']}
        style={{ marginRight: 4 }}
      />
      {children}
    </Wrapper>
  );
};

export const WarningBox = ({ children }) => {
  return (
    <Wrapper use="body2" rank="midPrimary" tag="div" type={'warning'}>
      <FontAwesomeIcon
        icon={['fas', 'exclamation-circle']}
        style={{ marginRight: 4 }}
      />
      {children}
    </Wrapper>
  );
};

export const SuccessBox = ({ children }) => {
  return (
    <Wrapper use="body2" rank="midPrimary" tag="div" type={'success'}>
      <FontAwesomeIcon
        icon={['fas', 'check-circle']}
        style={{ marginRight: 4 }}
      />
      {children}
    </Wrapper>
  );
};
