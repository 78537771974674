import { connect } from 'react-redux';
import { getOnboard } from 'redux/modules/account/account.selectors';
import Help from './Help';
import { setOnboardRun } from 'redux/modules/account/account.operations';
import { ONBOARD_IDS } from 'constants/index.js';

const mapStateToProps = state => ({
  onboard: getOnboard(state),
});

const mapDispatchToProps = dispatch => ({
  setOnboarded: () => dispatch(setOnboardRun(ONBOARD_IDS.VIDEOS)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Help);
