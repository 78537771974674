import types from './detailedView.types';

// === ACTIONS ===
export const init = nutrients => ({
  type: types.INIT,
  nutrients,
});

export const nutrientClick = nutrient => ({
  type: types.NUTRIENT_CLICK,
  nutrient,
});

// === OPERATIONS ===
export default {
  init,
  nutrientClick,
};
