import React from 'react';
import Typography from 'components/material/Typography';
import styled from 'styled-components';

const hoverStyle = props =>
  'cursor: pointer;' +
  'filter: contrast(1.1);' +
  `box-shadow: inset 0 0 transparent, 0 0 0 1px ${props.theme.palette.primary3};`;

const AvatarRound = styled.div`
  border-radius: 50px;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  background-color: ${props => props.theme.palette.primary3};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow -webkit-text-stroke 0.15s ease-in-out;

  &:hover {
    ${props => (props.onClick ? hoverStyle(props) : 'cursor: default;')};
  }
`;

export default ({ initial, onClick, size }) => (
  <AvatarRound onClick={onClick} size={size || 30}>
    <Typography use="button" style={{ color: 'white' }}>
      {initial}
    </Typography>
  </AvatarRound>
);
