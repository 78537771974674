import React from 'react';
import styled from 'styled-components';

// Configurable settings for interpolation
const MIN_SPACING = 0;
const MAX_SPACING = 3;

const variationRate = maxTime => (MAX_SPACING - MIN_SPACING) / maxTime;

const _timeSpacing = (time, min, max) =>
  time && time >= min
    ? Math.min(
        Math.max(MIN_SPACING, MIN_SPACING + (time - min) * variationRate(max)),
        MAX_SPACING
      )
    : 1;

const _timeString = time => {
  const hours = Math.floor(time / 60);
  const minutes = time % 60;
  return (
    <Text className="mdc-typography--headline6">
      {(hours > 0 ? hours + ' h ' : '') + (minutes > 0 ? minutes + ' min' : '')}
    </Text>
  );
};

const TimeLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 7em;
`;
const Line = styled.div`
  width: 5px;
  background: ${props => props.theme.palette.text.tertiary};
`;
const Text = styled.p`
  font-weight: 800 !important;
  color: ${props => props.theme.palette.text.tertiary};
`;
const Space = styled.div`
  margin-top: ${props => props.spacing}em;
  margin-bottom: ${props => props.spacing}em;
  margin-left: 1em;
  text-align: center;
`;
const Nothing = styled.div`
  height: 0px;
  width: 0px;
`;

const TimeLine = ({ time, min, max }) => {
  const spacing = _timeSpacing(time, min, max);
  return time === null ? (
    <Nothing />
  ) : (
    <TimeLineWrapper>
      <Line spacing={spacing} />
      <Space spacing={spacing}>{time >= min ? _timeString(time) : ''}</Space>
    </TimeLineWrapper>
  );
};

export default TimeLine;
