import React from 'react';
import Modal from './Modal';
import HorizontalLoader from './loaders/HorizontalLoader';
import {
  MODAL_TYPES,
  getModalOpen,
  closeModal,
  getModalProps,
} from 'redux/modules/modals/modals';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { shadowLevels } from './WhiteFrame';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defineMessages, FormattedMessage } from 'react-intl';
import { getSmallLocale } from 'redux/modules/account/account.selectors';

const messages = defineMessages({
  next: { id: 'youtube.next' },
});

const Next = styled.div`
  position: absolute;
  top: 45%;
  right: -120px;
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  box-shadow: ${shadowLevels[3]};
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid black;

  &:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }
`;

export const Iframe = React.memo(props => React.createElement('iframe', props));

export default function YoutubeVideo() {
  const isOpen = useSelector(s => getModalOpen(s, MODAL_TYPES.YOUTUBE_VIDEO));
  const modalProps = useSelector(s => getModalProps(s));
  const id = get(modalProps, 'id', null);
  const onNext = get(modalProps, 'onNext');
  const locale = useSelector(getSmallLocale);

  const dispatch = useDispatch();
  const onClose = () => dispatch(closeModal(MODAL_TYPES.YOUTUBE_VIDEO));

  if (!id) return null;
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ width: 640, padding: 5, height: 400, maxWidth: 640 }}
    >
      {onNext ? (
        <Next onClick={() => onNext(id)}>
          <FontAwesomeIcon
            icon={['far', 'step-forward']}
            style={{ fontSize: 24 }}
          />
          <FormattedMessage {...messages.next} />
        </Next>
      ) : null}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <HorizontalLoader />
      </div>
      <Iframe
        src={`https://www.youtube-nocookie.com/embed/${id}?rel=0&autoplay=1&cc_load_policy=1&cc_lang_pref=${locale}&color=#4990e2&modestbranding=1`}
        frameBorder="0"
        height="400px"
        width="640px"
        display="initial"
        position="relative"
        allow="autoplay; fullscreen"
      />
    </Modal>
  );
}
