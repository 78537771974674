import { getBuilderConfigOptions } from '../../builders/builderConfigs/builderConfigs.selectors';
import { getBuilderOptionGroupId } from '../../builders/builders/builders.selectors';
import { batch } from 'react-redux';
import { selectOption, clearOptions } from './builderView.actions';
import { getCleanSelectedOptions } from './builderView.selectors';
import { createBuilderConfig } from '../../builders/builderConfigs';
import { getPublicBuilderOptions } from '../../builders/publicBuilders/publicBuilders.selectors';

export const initBuilderOp = (
  namespace,
  builderId,
  { configId, publicBuilderId }
) => (dispatch, getState) => {
  const state = getState();

  const startOptions = configId
    ? getBuilderConfigOptions(state, configId)
    : getPublicBuilderOptions(state, publicBuilderId);

  const actions = startOptions.map(option =>
    selectOption(namespace)(
      option,
      getBuilderOptionGroupId(state, builderId, option),
      false
    )
  );

  batch(() => {
    dispatch(clearOptions(namespace));
    actions.forEach(dispatch);
  });
};

export const createBuilderConfigOp = (namespace, builderId) => (
  dispatch,
  getState
) => {
  const state = getState();

  const options = getCleanSelectedOptions(state, namespace, builderId);
  return dispatch(createBuilderConfig(builderId, options, []));
};
