import { combineReducers } from 'redux';
import auth from './auth';
import account from './account';
import modals from './modals/modals';
import clients from './clients';
import foodDiaries from './foodDiaries';
import meals from './meals';
import entries from './entries';
import search from './search';
import foods from './foods';
import comments from './comments';
import billing from './billing';
import UI from './UI';
import authtypes from './auth/auth.types';
import reports from './reports';
import reportTemplates from './reportTemplates';
import templateVersions from './templateVersions';
import reportSettings from './reportSettings';
import reportVersions from './reportVersions';
import builders from './builders';
import userFoods from './userFoods';
import studies from './studies';

const appReducer = combineReducers({
  account,
  auth,
  modals,
  clients,
  foodDiaries,
  meals,
  entries,
  search,
  foods,
  billing,
  comments,
  UI,
  reports,
  reportSettings,
  reportTemplates,
  reportVersions,
  templateVersions,
  builders,
  userFoods,
  studies,
});

/* Based on Dan Abramov's advice on how to handle LOGOUT:
 * https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
 *
 * Basically, it forces the initial state whenever LOGOUT is called */
const rootReducer = (state, action) => {
  if (action.type === authtypes.LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
