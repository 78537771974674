import React from 'react';
import styled from 'styled-components';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  title: { id: 'oauth.box.title' },
});

const Wrapper = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;
const LoginWrapper = styled.div`
  margin-top: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 48px;
  padding-top: 84px;
  width: 450px;
  box-sizing: border-box;
  position: relative;
`;
const KeenoaInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 36px;
  padding-left: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: ${x => x.theme.palette.text.secondary};
`;

export const withOauth = Component => {
  const hoc = React.forwardRef((props, ref) => (
    <Wrapper>
      <LoginWrapper>
        <KeenoaInfo>
          <img
            alt="Keenoa logo"
            src={'/android-chrome-256x256.png'}
            style={{ height: 30, paddingRight: 12 }}
          />
          <FormattedMessage {...messages.title} />
        </KeenoaInfo>
        <Component ref={ref} {...props} />
      </LoginWrapper>
    </Wrapper>
  ));
  hoistNonReactStatics(hoc, Component);
  return hoc;
};
