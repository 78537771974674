import React, { useState } from 'react';
import Typography from 'components/material/Typography';
import { NUTRIENTS_SECTIONS, idToConstant } from 'constants/nutriments';
import styled from 'styled-components';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Nutrient } from './Nutrient';
import SearchBar from 'components/SearchBar';

const messages = defineMessages({
  searchPlaceholder: { id: 'foods.create.allNutrients.search' },
});

const SECTION_WIDTH = 240;

const SectionTitleStyle = styled.div`
  background-color: ${x => x.theme.palette[x.color]};
  width: 3px;
  height: 20px;
  border-radius: 3px;
  margin-right: 3px;
`;

const SectionWrapper = styled.div`
  --mdc-theme-secondary: ${x => x.theme.palette[x.color]};

  display: flex;
  flex-direction: column;
`;

const SectionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${SECTION_WIDTH}px;
  margin-top: 40px;
  padding: 8px;
`;

const TopBar = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
`;

export function AllNutrients({ nutrients, setNutrient }) {
  const intl = useIntl();
  const [searchText, setSearchText] = useState('');

  const renderSectionTitle = ({ title, color }) => {
    return (
      <Typography
        use="overline"
        rank="secondary"
        tag="div"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: SECTION_WIDTH,
          background: 'white',
          zIndex: 5,
          position: 'absolute',
        }}
      >
        <SectionTitleStyle color={color} />
        <FormattedMessage {...title} />
      </Typography>
    );
  };

  const matchSearch = ({ title, color, ids }) => {
    // Search technique based on http://jsperf.com/substring-test
    const regex = new RegExp(searchText, 'i');

    const filteredIds = ids
      .map(id => idToConstant[id])
      .map(n => ({ label: intl.formatMessage(n.label), id: n.id }))
      .filter(x => regex.test(x.label))
      .map(x => x.id);
    return { ids: filteredIds, title, color };
  };

  const renderSection = ({ title, color, ids }) => {
    return (
      <SectionWrapper key={title.id} color={color}>
        {renderSectionTitle({ title, color })}
        <SectionContentWrapper>
          {ids
            .map(id => idToConstant[id])
            .map(n => (
              <Nutrient
                key={n.id}
                {...n}
                small
                value={nutrients[n.id]}
                setValue={v => setNutrient(n.id, v)}
              />
            ))}
        </SectionContentWrapper>
      </SectionWrapper>
    );
  };

  return (
    <div style={{ minWidth: SECTION_WIDTH * NUTRIENTS_SECTIONS.length }}>
      <TopBar>
        <SearchBar
          value={searchText}
          onInputChange={setSearchText}
          placeholder={intl.formatMessage(messages.searchPlaceholder)}
        />
      </TopBar>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {NUTRIENTS_SECTIONS.map(matchSearch)
          .filter(x => !!x.ids.length)
          .map(renderSection)}
      </div>
    </div>
  );
}
