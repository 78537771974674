import { defineMessages } from 'react-intl';

export const JOIN_TOKEN = ',';

const messages = defineMessages({
  client: { id: 'oauth.scope.client' },
  clientRead: { id: 'oauth.scope.clientRead' },
  clientWrite: { id: 'oauth.scope.clientWrite' },

  diary: { id: 'oauth.scope.diary' },
  diaryRead: { id: 'oauth.scope.diaryRead' },
  diaryWrite: { id: 'oauth.scope.diaryWrite' },
  commentRead: { id: 'oauth.scope.commentRead' },
  photoRead: { id: 'oauth.scope.photoRead' },

  invite: { id: 'oauth.scope.invite' },
  inviteRead: { id: 'oauth.scope.inviteRead' },
  inviteWrite: { id: 'oauth.scope.inviteWrite' },
});

export const scopeToMessage = {
  'client:read': messages.clientRead,
  'client:write': messages.clientWrite,
  'diary:read': messages.diaryRead,
  'diary:write': messages.diaryWrite,
  'comment:read': messages.commentRead,
  'comment:write': messages.commentWrite,
  'photo:read': messages.photoRead,
  'invite:write': messages.inviteWrite,
  'invite:read': messages.inviteRead,
};
export const scopeToGroup = {
  'client:read': messages.client,
  'diary:read': messages.client,
  'diary:write': messages.diary,
  'comment:read': messages.diary,
  'comment:write': messages.diary,
  'photo:read': messages.diary,
  'invite:write': messages.invite,
  'invite:read': messages.invite,
};
