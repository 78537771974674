import Option from './Option';
import React from 'react';
import { connect } from 'react-redux';
import { getOptionSelected } from 'redux/modules/UI/builderView/builderView.selectors';

const mapStateToProps = (state, { id, namespace }) => ({
  selected: getOptionSelected(state, namespace, id),
});

class OptionWrapper extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.id !== this.props.id ||
      nextProps.selected !== this.props.selected
    ) {
      return true;
    }
    return false;
  }
  render() {
    return <Option {...this.props} />;
  }
}
export default connect(mapStateToProps)(OptionWrapper);
