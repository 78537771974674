import TemplateLoader from './TemplateLoader';
import { connect } from 'react-redux';
import { getIsDirty, getStagedTemplateId } from 'redux/modules/UI/reportView';
import {
  saveCurrentTemplate,
  loadTemplate,
} from 'redux/modules/UI/reportView/reportView.operations';
import { getTemplateFetchStatus } from 'redux/modules/reportTemplates';
import { fetchReportTemplate } from 'redux/modules/reportTemplates/reportTemplates.actions';
import { stageTemplate } from 'redux/modules/UI/reportView/reportView.actions';
import { getFictiveClientId } from 'redux/modules/clients/clients.selectors';
import { selectClient } from 'redux/modules/clients/clients.operations';

const mapStateToProps = state => {
  const templateId = getStagedTemplateId(state);
  return {
    fetchStatus: getTemplateFetchStatus(state, templateId),
    isDirty: getIsDirty(state),
    templateId,

    fictiveClientId: getFictiveClientId(state),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchTemplate: id => dispatch(fetchReportTemplate(id)),
  save: () => dispatch(saveCurrentTemplate()),
  loadTemplate: id => dispatch(loadTemplate(id)),
  stageTemplate: id => dispatch(stageTemplate(id)),
  selectClient: clientId => dispatch(selectClient(clientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateLoader);
