import React from 'react';
import times from 'lodash/times';
import styled from 'styled-components';
import padStart from 'lodash/padStart';
import TimeFormat from 'components/TimeFormat';
import Select from 'components/forms/Select';

export const ONE_HOUR_HEIGHT = 62;
export const START_HOUR = 6;

const Hour = styled.div`
  height: ${ONE_HOUR_HEIGHT}px;
  width: 66px;
  text-align: center;
`;

const Wrapper = styled.div`
  margin-top: -10px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

export default React.memo(function DayScale(props) {
  const { startHour = START_HOUR, endHour, setStartHour } = props;
  const hoursCount = endHour ? endHour - startHour + 1 : 24;
  const makeHour = offset => i => {
    const h = (i + offset) % 24;
    const hourS = padStart(`${h}`, 2, '0');
    return {
      id: h,
      label: `${hourS}:00`,
    };
  };
  const hours = times(hoursCount, makeHour(startHour));
  const options = times(Math.min(endHour, 24), makeHour(0));

  const value = options.find(x => x.id === startHour);
  return (
    <Wrapper>
      {setStartHour && (
        <div style={{ height: ONE_HOUR_HEIGHT, width: 64 }}>
          <Select
            options={options}
            value={value}
            onChange={({ id }) => setStartHour(id)}
            buttonStyle={{ padding: 0, paddingLeft: 4 }}
          />
        </div>
      )}

      {hours.map((h, i) =>
        i === 0 && setStartHour ? null : (
          <Hour key={h.id}>
            <span className="mdc-typography--caption">
              <TimeFormat time={h.label} simple />
            </span>
          </Hour>
        )
      )}
    </Wrapper>
  );
});
