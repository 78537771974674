import React from 'react';
import styled from 'styled-components';
import NutrientsChart from '../../NutrientsChart';
import get from 'lodash/get';

const EmptyNutrientsChart = styled.div`
  width: 136px;
  padding-left: 1em;
`;

const Analytics = ({ mealSummary, id, maxEnergy, row }) => {
  if (!get(mealSummary, 'energy')) {
    return <EmptyNutrientsChart />;
  }

  return (
    <NutrientsChart
      maxEnergy={maxEnergy}
      energy={mealSummary.energy}
      nutrients={mealSummary.macronutrients}
      key={id}
      row={row}
    />
  );
};

export default Analytics;
