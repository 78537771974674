import React, { useState } from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Twemoji } from 'react-emoji-render';
import WistiaEmbed from 'components/WistiaEmbed';
import { Button } from 'components/material/Button';
import { Title, Subtitle, Background } from './components';
import { useSelector, useDispatch } from 'react-redux';
import { getSmallLocale } from 'redux/modules/account/account.selectors';
import { ONBOARD_IDS } from '../../constants';
import { setOnboardRun } from 'redux/modules/account/account.operations';
import { useHistory } from 'react-router-dom';
import { makeCustomIcon } from '../../utils/misc';

const messages = defineMessages({
  title: { id: 'onboard.welcome.title' },
  subTitle: { id: 'onboard.welcome.subTitle' },
  continue: { id: 'onboard.welcome.continue' },
});

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FadeInButton = styled(Button)`
  opacity: ${x => (x.visible ? 1 : 0)};
  transition: opacity 1s linear;
  display: flex;
  align-items: center;
`;

function Welcome() {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useSelector(getSmallLocale);

  const onReady = () => {
    setReady(true);
  };

  const continueOnboarding = () => {
    const welcomeYoutubeId = locale === 'fr' ? 'IO0UbvizEKg' : 'nxe1WGpxv1Q';
    dispatch(setOnboardRun(`onboard_video_${welcomeYoutubeId}`));
    dispatch(setOnboardRun(ONBOARD_IDS.WELCOME));
    history.push('/onboard-2');
  };

  return (
    <Background>
      <Wrapper>
        <Title>
          <FormattedMessage {...messages.title} /> <Twemoji text={'👋'} />
        </Title>
        <Subtitle>
          <FormattedMessage {...messages.subTitle} />
        </Subtitle>
        <div style={{ width: '50vw', maxWidth: 600, position: 'relative' }}>
          <WistiaEmbed
            hashedId={locale === 'fr' ? 'vpvfkb81gl' : '3txjvdjzha'}
            onReady={onReady}
          />
        </div>

        <FadeInButton
          visible={ready}
          disabled={!ready}
          onClick={continueOnboarding}
          style={{ marginTop: 20, marginLeft: 'auto' }}
          unelevated
          rank="saturated"
          icon={makeCustomIcon(['fad', 'arrow-circle-right'])}
        >
          <FormattedMessage {...messages.continue} />
        </FadeInButton>
      </Wrapper>
    </Background>
  );
}

export default Welcome;
