import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import Typography from 'components/material/Typography';
import H2 from 'components/H2';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_TYPES, openModal, useModal } from 'redux/modules/modals/modals';
import get from 'lodash/get';
import { Select } from 'components/material/Select';
import { Button } from 'components/material/Button';
import { LinearProgress } from 'components/loaders/LinearProgress';
import Spacer from 'components/Spacer';
import { getStudies, fetchStudies } from 'redux/modules/studies';
import last from 'lodash/last';

const messages = defineMessages({
  header: { id: 'selectStudyModal.header' },
  content: { id: 'selectStudyModal.content' },
  new: { id: 'selectStudyModal.newStudy' },
  selectLabel: { id: 'selectStudyModal.selectLabel' },
  continue: { id: 'selectStudyModal.next' },
});
const NEW_STUDY_ID = 'new_study_id';
const SelectStudyModal = React.memo(_SelectStudyModal);
function _SelectStudyModal({ createNewStudy }) {
  const [isOpen, onExit] = useModal(MODAL_TYPES.SELECT_STUDY);
  const intl = useIntl();
  const studies = useSelector(getStudies);
  const [value, setValue] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const load = async () => {
    setLoading(true);
    try {
      await dispatch(fetchStudies());
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!isOpen) return;

    load();
  }, [isOpen]);

  useEffect(() => {
    setValue(get(last(studies), 'id'));
  }, [studies]);

  const _onContinue = () => {
    dispatch(
      openModal(MODAL_TYPES.SELECT_PARTICIPANT_COUNT, { studyId: value }, true)
    );
  };

  const _onChange = evt => {
    const nextValue = evt.currentTarget.value;
    if (nextValue === NEW_STUDY_ID) {
      createNewStudy();
    } else {
      setValue(nextValue);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onExit}>
      <H2>
        <FormattedMessage {...messages.header} />
      </H2>
      <Typography use="body2" rank="secondary" tag="div">
        <FormattedMessage {...messages.content} />
      </Typography>
      <Spacer spacing="xl" />
      {loading ? (
        <LinearProgress
          style={{ borderRadius: '5px 5px 0 0', marginBottom: -4 }}
        />
      ) : null}
      <Select
        label={intl.formatMessage(messages.selectLabel)}
        enhanced
        value={value}
        onChange={_onChange}
        outlined
        disabled={loading && !studies.length}
        options={[
          {
            options: [
              {
                label: intl.formatMessage(messages.new),
                value: NEW_STUDY_ID,
              },
            ],
          },
          {
            options: studies.map(({ id, code, name }) => ({
              label: `${code} - ${name}`,
              value: id,
            })),
          },
        ]}
      />
      <Spacer spacing="md" />
      <Button
        style={{ float: 'right' }}
        unelevated
        disabled={!value || loading}
        onClick={_onContinue}
        label={intl.formatMessage(messages.continue)}
      />
    </Modal>
  );
}

export default function() {
  const dispatch = useDispatch();

  const createNewStudy = () => {
    dispatch(openModal(MODAL_TYPES.NEW_STUDY, {}, true));
  };

  return <SelectStudyModal createNewStudy={createNewStudy} />;
}
