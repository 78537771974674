import { createSelector } from 'reselect';
import { dateStringToAge, dateStringToDate } from '../../../utils/dateUtils';
import {
  CLIENT_STATUS,
  CLIENT_ACTIONS,
  STATUS_FILTERS,
  COURSES_FILTERS,
  STUDIES_FILTERS,
} from '../../../constants/clientStatus';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import {
  getStatusFilter,
  getSortColumn,
  getSortOrder,
  getCoursesFilter,
  getStudiesFilter,
} from '../UI/clientsView';
import { nutrimentsConstants } from '../../../constants/nutriments';
import values from 'lodash/values';
import { SORT_ORDERS, FICTIVE_CLIENT_IDS } from '../../../constants';
import sortBy from 'lodash/sortBy';
import {
  getFullLocale,
  getDietitianIsResearcher,
} from '../account/account.selectors';
import { getCompleteExportNutrients } from '../UI/reportView/widgets';
import uniqBy from 'lodash/uniqBy';
import keys from 'lodash/keys';

export const getInviteStatus = state => state.clients.inviteStatus;
export const getRenewStatus = state => state.clients.renewStatus;
export const getInviteError = state => state.clients.inviteError;

export const getById = state => state.clients.byId;
const _getAllIds = state => state.clients.allIds;
const _getCurrentId = state => state.clients.currentId;
export const getSelectedIds = state => state.clients.selectedIds;

export const getSearchString = state => state.clients.searchString;
export const getIsFetching = state => state.clients.isFetching;
export const getCurrentClientId = _getCurrentId;

export const getAllClients = state =>
  _getAllIds(state).map(id => getById(state)[id]);

const getAllClientsWithStatus = createSelector(
  [getAllClients, getSelectedIds],
  (clients, selectedIds) => {
    const map = {};
    selectedIds.forEach(i => (map[i] = true));
    return clients.map(client => ({
      ...client,
      status: client.is_fictive ? CLIENT_STATUS.FICTIVE : client.status,
      selected: !!map[client.id],
    }));
  }
);
export const getAllConnectedClients = createSelector(getAllClients, clients =>
  clients.filter(c => !!c.id)
);

const _makeClientStatusFilter = filter => {
  const sf = STATUS_FILTERS;
  const cs = CLIENT_STATUS;
  switch (filter) {
    case sf.ALL_BUT_ARCHIVED:
      return p => p.status !== cs.ARCHIVED && p.status !== cs.REVOKED;
    case sf.ACTIVE:
      return p => p.status === cs.ACTIVE;
    case sf.ARCHIVED:
      return p => p.status === cs.ARCHIVED || p.status === cs.REVOKED;
    case sf.PENDING:
      return p => p.status === cs.PENDING;
    default:
      return () => true;
  }
};

const _makeClientCoursesFilter = filter => {
  if (filter === COURSES_FILTERS.ALL) {
    return () => true;
  }

  return p => p.courses.includes(filter);
};

const _makeCLientStudiesFilter = filter => {
  if (filter === STUDIES_FILTERS.ALL) {
    return () => true;
  }

  return p => (!p.study_id ? true : p.study_id.includes(filter));
};

export const getMakeClientFilter = createSelector(
  [getDietitianIsResearcher, getSearchString],
  (isResearcher, searchString) => {
    // Search technique based on http://jsperf.com/substring-test
    const regex = new RegExp(searchString, 'i');

    return !isResearcher
      ? c =>
          !c.code &&
          ((c.first_name && regex.test(c.first_name)) ||
            (c.last_name && regex.test(c.last_name)) ||
            (c.learner_name && regex.test(c.learner_name)) ||
            (c.invite_email && regex.test(c.invite_email)))
      : c => !!c.code && regex.test(`${c.code}`);
  }
);

const _addKey = client => {
  const { id, invite_email, code } = client;
  client.key = id || invite_email || code;
  return client;
};

export const getVisibleClients = createSelector(
  [
    getAllClientsWithStatus,
    getStatusFilter,
    getSortColumn,
    getSortOrder,
    getMakeClientFilter,
    getCoursesFilter,
    getStudiesFilter,
  ],
  (
    allClients,
    statusFilter,
    sortColumn,
    sortOrder,
    makeClientFilter,
    coursesFilter,
    studiesFilter
  ) => {
    const visibleClients = uniqBy(
      allClients
        .filter(_makeClientCoursesFilter(coursesFilter))
        .filter(_makeClientStatusFilter(statusFilter))
        .filter(_makeCLientStudiesFilter(studiesFilter))
        .filter(makeClientFilter)
        .map(_addKey),
      'key'
    );

    if (sortColumn) {
      const sorted = sortBy(visibleClients, sortColumn);
      return sortOrder === SORT_ORDERS.ASCENDING ? sorted : sorted.reverse();
    }

    return visibleClients.reverse();
  }
);

export const getVisibleClientIds = createSelector(getVisibleClients, clients =>
  clients.map(x => x.id || x.invite_email)
);
export const getVisibleClientsCount = createSelector(getVisibleClientIds, ids =>
  get(ids, 'length', 0)
);

export const getIsAllSelected = createSelector(
  [getVisibleClientIds, getSelectedIds],
  (clients, ids) => {
    var map = {};
    ids.forEach(x => (map[x] = true));
    return clients.filter(x => !map[x]).length === 0 && clients.length;
  }
);

/** (state, clientId) */
export const getClient = createSelector(
  [getById, (_, clientId) => clientId],
  (byId, id) => get(byId, id)
);

/** (state, clientId) */
export const getClientProfileImage = createSelector([getClient], client =>
  get(client, 'profile_picture_url')
);
/** (state, clientId) */
export const getAvailableCourses = createSelector([getAllClients], clients => {
  const availableCourses = new Set();
  clients
    .filter(c => !!c.courses)
    .flatMap(c => c.courses)
    .forEach(course => {
      availableCourses.add(course);
    });

  return [...availableCourses].sort();
});
/** (state, clientId) */
export const getClientCourses = createSelector([getClient], client =>
  get(client, 'courses', [])
);
/** (state, clientId) */
export const getClientFormattedCourses = createSelector(
  [getClientCourses],
  courses => courses.sort().join(', ')
);
/** (state, clientId) */
export const getClientLearnerName = createSelector([getClient], client =>
  get(client, 'learner_name')
);
/** (state, clientId) */
export const getClientFirstName = createSelector([getClient], client =>
  get(client, 'first_name')
);
/** (state, clientId) */
export const getClientLastName = createSelector([getClient], client =>
  get(client, 'last_name')
);
/** (state, clientId) */
export const getClientInviteEmail = createSelector([getClient], client =>
  get(client, 'invite_email')
);
/** (state, clientId) */
export const getClientStatus = createSelector([getClient], client =>
  get(client, 'status')
);
/** (state, clientId) */
export const getClientParticipantCode = createSelector([getClient], client =>
  get(client, 'code')
);
/** (state, clientId) */
export const getClientNotifications = createSelector([getClient], client =>
  get(client, 'notifications')
);
/** (state, clientId) */
export const getClientAcceptDate = createSelector([getClient], client =>
  get(client, 'accept_date')
);

export const getCurrentClient = createSelector(
  [_getCurrentId, getById],
  (id, byId) => (id || id === 0 ? byId[id] : null)
);

export const getCurrentClientName = createSelector(
  [_getCurrentId, getById],
  (id, byId) => {
    if (!id) return null;

    const firstName = get(byId, [id, 'first_name'], '');
    const lastName = get(byId, [id, 'last_name'], '');
    return `${firstName} ${lastName}`;
  }
);
export const getCurrentClientShortName = createSelector(
  [_getCurrentId, getById],
  (id, byId) => {
    if (!id) return null;

    const firstName = get(byId, [id, 'first_name', 0], '');
    const lastName = get(byId, [id, 'last_name'], '');
    return `${firstName}. ${lastName}`;
  }
);

export const getCurrentClientQuestionActive = createSelector(
  getCurrentClient,
  client => get(client, 'question_active', true)
);
export const getCurrentClientActiveNutrients = createSelector(
  getCurrentClient,
  client => get(client, 'active_nutrients', []).filter(x => x)
);

export const getCurrentClientFirstName = createSelector(
  getCurrentClient,
  client => (client ? client.first_name : '')
);
export const getCurrentClientLastName = createSelector(
  getCurrentClient,
  client => (client ? client.last_name : '')
);

export const getCurrentClientAvatar = createSelector(
  [_getCurrentId, getById],
  (id, byId) => get(byId, [id, 'profile_picture_url'], null)
);

export const getCurrentClientInfo = createSelector([getCurrentClient], props =>
  !props
    ? null
    : omit(
        {
          ...props,
          weight: props.weight_kg,
          height: props.height_cm,
          age: dateStringToAge(props.birth_date),
        },
        ['weight_kg', 'height_cm']
      )
);

export const getCurrentClientLastActive = createSelector(
  [_getCurrentId, getById],
  (id, byId) => (id || id === 0 ? byId[id].last_active : undefined)
);

export const getCurrentClientNutrients = createSelector(
  [getCurrentClientInfo, getCompleteExportNutrients],
  (info, exportNutrients) => {
    const nutrients = [...get(info, 'nutrients', []), ...exportNutrients];
    return uniqBy(nutrients, 'id');
  }
);
export const getWidgetDefaultNutrients = createSelector(
  getCurrentClientNutrients,
  nutrients => nutrients.map(({ id }) => id)
);

/** For optimisation reasons, we want the selector to always calculate
 * the macros even if they wont be displayed in the detailed view.
 */
export const getCurrentClientNutrientsWithMacros = createSelector(
  getCurrentClientNutrients,
  nutrients => {
    const nc = nutrimentsConstants;
    const macros = {
      [nc.CALORIES.code]: nc.CALORIES,
      [nc.PROTEIN.code]: nc.PROTEIN,
      [nc.CARBS.code]: nc.CARBS,
      [nc.FAT.code]: nc.FAT,
      [nc.ALCOHOL.code]: nc.ALCOHOL,
      [nc.FIBER.code]: nc.FIBER,
    };
    nutrients.forEach(({ code }) => {
      if (macros[code]) {
        delete macros[code];
      }
    });
    const missing = values(macros);
    return [...nutrients, ...missing];
  }
);

/** (state, clientId) */
export const getClientDiaryIds = createSelector(
  [getById, (_, clientId) => clientId],
  (byId, clientId) => get(byId, [clientId, 'foodDiariesIds'], [])
);

/** (state) */
export const getCurrentClientDiaryIds = createSelector(
  state => {
    const clientId = getCurrentClientId(state);
    return getClientDiaryIds(state, clientId);
  },
  diaryIds => diaryIds
);

export const makeGetFoodDiariesIds = clientId =>
  createSelector([getById], byId =>
    clientId || clientId === 0 ? byId[clientId].foodDiariesIds : undefined
  );

export const getCurrentClientFoodDiariesIds = state =>
  makeGetFoodDiariesIds(_getCurrentId(state))(state);

const _getTokens = state => state.clients.tokens;
export const makeGetClientToken = clientId => state =>
  state.clients.tokens[clientId];

export const getIsCurrentClientFictive = createSelector(
  getCurrentClient,
  client => client && client.is_fictive
);

export const _getSelectedClients = createSelector(
  [getById, getSelectedIds],
  (byId, ids) => ids.map(x => byId[x]).filter(x => x)
);
export const _getSelectedClientsStatus = createSelector(
  _getSelectedClients,
  clients =>
    uniq(
      clients.map(({ is_fictive, status }) =>
        is_fictive ? CLIENT_STATUS.FICTIVE : status
      )
    )
);
const ACTIONS_NOT_AVAILABLE_IN_RESEARCH = new Set([CLIENT_ACTIONS.RE_INVITE]);
export const getAvailableActions = createSelector(
  [_getSelectedClientsStatus, getDietitianIsResearcher],
  (status, isResearcher) => {
    const cs = CLIENT_STATUS;
    const ca = CLIENT_ACTIONS;
    const statusToAction = {
      [cs.ACTIVE]: [ca.ARCHIVE, ca.EXPORT],
      [cs.PENDING]: [ca.REMOVE, ca.RE_INVITE],
      [cs.REVOKED]: [ca.ARCHIVE, ca.RE_INVITE, ca.EXPORT],
      [cs.ARCHIVED]: [ca.UNARCHIVE, ca.EXPORT],
      [cs.FICTIVE]: [ca.ARCHIVE, ca.REMOVE, ca.EXPORT],
      [cs.DECLINED]: [ca.RE_INVITE, ca.REMOVE],
    };
    const actions = status.map(s => statusToAction[s]);
    return intersection(...actions).filter(action =>
      !isResearcher ? true : !ACTIONS_NOT_AVAILABLE_IN_RESEARCH.has(action)
    );
  }
);

export const makeGetSelected = clientId =>
  createSelector(
    getSelectedIds,
    ids => ids.find(x => x === clientId) !== undefined
  );

/** (state) */
export const getCurrentClientAge = createSelector(getCurrentClientInfo, info =>
  get(info, 'age')
);
/** (state) */
export const getCurrentClientGender = createSelector(
  getCurrentClientInfo,
  info => get(info, 'gender')
);
/** (state) */
export const getCurrentClientBirthdate = createSelector(
  getCurrentClientInfo,
  info => get(info, 'birth_date')
);
/** (state) */
export const getCurrentClientLocalizedBirthdate = createSelector(
  [getCurrentClientBirthdate, getFullLocale],
  (date, locale) => {
    if (!date) return '';
    const d = dateStringToDate(date);
    return d.toLocaleDateString(locale);
  }
);

export const getCurrentClientAttributes = createSelector(
  getCurrentClientInfo,
  info => get(info, 'attributes', {})
);
/** (state) */
export const getCurrentClientLactation = createSelector(
  getCurrentClientAttributes,
  attributes => get(attributes, 'lactation')
);
/** (state) */
export const getCurrentClientPregnancy = createSelector(
  getCurrentClientAttributes,
  attributes => get(attributes, 'pregnancy')
);
/** (state) */
export const getIsCurrentClientProfileComplete = createSelector(
  [getCurrentClientAge, getCurrentClientGender],
  (age, gender) => age !== undefined && gender
);
/** (state) */
export const getCurrentClientSettings = createSelector(
  getCurrentClientInfo,
  info => get(info, 'settings', {})
);
export const getCurrentClientStartHour = createSelector(
  getCurrentClientSettings,
  settings => get(settings, 'startHour', 7)
);

export const getCurrentClientToken = createSelector(
  [getCurrentClientId, _getTokens],
  (id, tokens) => get(tokens, id, null)
);

/** (state) */
export const getFictiveClientId = createSelector(getById, byId => {
  for (const id of FICTIVE_CLIENT_IDS.keys()) {
    if (byId[id]) {
      return id;
    }
  }
  // Should never get there...
  return null;
});

export default {
  getSearchString,
  getIsFetching,
  getById,
  getAllClientsWithStatus,
  getVisibleClients,

  getCurrentClientId,
  getCurrentClient,
  getCurrentClientInfo,
  getCurrentClientName,
  getCurrentClientAvatar,
  getCurrentClientLastActive,
  getIsCurrentClientFictive,

  makeGetFoodDiariesIds,
  getCurrentClientFoodDiariesIds,

  makeGetClientToken,
};
