import Select from 'components/forms/Select';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { withTheme } from 'styled-components';
import { STUDIES_FILTERS } from '../../../../constants/clientStatus';

const messages = defineMessages({
  all: { id: 'clients.filter.studies.all' },
});

class StudiesFiltering extends React.PureComponent {
  _getFilterLabel = filter => {
    const { intl, studies } = this.props;

    if (filter === STUDIES_FILTERS.ALL) {
      return intl.formatMessage(messages.all);
    }

    const study = studies.find(study => study.id === filter);
    if (!study) return STUDIES_FILTERS.ALL;

    return `    ${study.code} - ${study.name}`;
  };
  _getOptions = () => {
    return [
      {
        id: STUDIES_FILTERS.ALL,
        label: this._getFilterLabel(STUDIES_FILTERS.ALL),
      },
      ...this.props.studies.map(study => ({
        id: study.id,
        label: this._getFilterLabel(study.id),
      })),
    ];
  };

  render() {
    const { filter, selectFilter, theme, isFetching } = this.props;

    if (isFetching) return null;

    return (
      <div style={{ width: 250, padding: '15px 3px 3px 3px' }}>
        <Select
          maxWidth={220}
          buttonStyle={{
            padding: '6px 8px',
            borderWidth: 1,
            marginTop: -5,
          }}
          buttonTextStyle={{
            color: theme.palette.text.secondary,
          }}
          carretIcon="filter_list"
          value={{ id: filter, label: this._getFilterLabel(filter) }}
          options={this._getOptions()}
          onChange={x => selectFilter(x.id)}
        />
      </div>
    );
  }
}

export default injectIntl(withTheme(StudiesFiltering));
