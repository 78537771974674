const pre = 'keenoa/UI/reportsView/';

export default {
  INVERT_SORT_ORDER: `${pre}INVERT_SORT_ORDER`,
  SELECT_SORT_COLUMN: `${pre}SELECT_SORT_COLUMN`,

  SET_REPORTS_FILTER: `${pre}SET_REPORTS_FILTER`,

  SELECT_REPORT: `${pre}SELECT_REPORT`,
  SELECT_REPORTS: `${pre}SELECT_REPORTS`,
  UNSELECT_REPORT: `${pre}UNSELECT_REPORT`,
  UNSELECT_ALL_REPORTS: `${pre}UNSELECT_ALL_REPORTS`,
};
