/**
 * Report templates define how a report should be build. See the UI reportView
 * redux module for more info on how they are built.
 */
import { combineReducers } from 'redux';
import {
  makeById,
  makeFetchStatus,
  makeByIdFetchStatus,
} from '../../../utils/standardReducers';
import reportTemplatesTypes, { pre } from './reportTemplates.types';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import merge from 'lodash/merge';
import templateVersionsType from '../templateVersions/templateVersions.type';
import FETCH_STATUS from '../../../constants/fetchStatus';

export const currentIds = (state = [], action) => {
  switch (action.type) {
    case reportTemplatesTypes.FETCH_REPORT_TEMPLATES_SUCCESS:
      return action.response.result;
    case reportTemplatesTypes.DELETE_REPORT_TEMPLATES_SUCCESS:
      return state.filter(id => id !== action.templateId);
    case reportTemplatesTypes.CREATE_REPORT_TEMPLATE_SUCCESS:
      return [action.response.result, ...state];
    default:
      return state;
  }
};

const _baseById = makeById('reportTemplates');
export const byId = (state = {}, action) => {
  if (action.type === reportTemplatesTypes.FETCH_REPORT_TEMPLATES_SUCCESS) {
    const templates = action.response.entities.reportTemplates;
    return merge(templates, state);
  }

  let nextState = _baseById(state, action);
  if (action.type === templateVersionsType.CREATE_TEMPLATE_VERSION_SUCCESS) {
    return {
      ...nextState,
      [action.templateId]: {
        ...get(state, action.templateId),
        version: action.response.result,
      },
    };
  }

  return nextState;
};

export default combineReducers({
  byId,
  fetchTemplateStatus: makeByIdFetchStatus(
    `${pre}FETCH_REPORT_TEMPLATE`,
    'templateId'
  ),
  fetchTemplatesStatus: makeFetchStatus(`${pre}FETCH_REPORT_TEMPLATES`),
  currentIds,
});

/// SELECTORS
const _getRoot = state => state.reportTemplates;
export const getById = createSelector(_getRoot, x => x.byId);
export const _getCurrentIds = createSelector(_getRoot, x => x.currentIds);
export const getCurrentTemplates = createSelector(
  [getById, _getCurrentIds],
  (byId, ids) => ids.map(id => byId[id])
);

export const getFetchTemplatesStatus = createSelector(
  _getRoot,
  x => x.fetchTemplatesStatus
);

export const getFetchTemplateStatus = createSelector(
  _getRoot,
  x => x.fetchTemplateStatus
);

/** (state, templateId) */
export const getTemplateFetchStatus = createSelector(
  [(_, templateId) => templateId, getFetchTemplateStatus],
  (id, statusById) => get(statusById, id, FETCH_STATUS.NONE)
);

/** (state, templateId) */
export const getTemplate = createSelector(
  [(_, templateId) => templateId, getById],
  (id, byId) => get(byId, id, null)
);
export const getTemplateEmoji = createSelector(getTemplate, t =>
  get(t, 'emoji', '')
);
export const getTemplateTitle = createSelector(getTemplate, t =>
  get(t, 'title', '')
);
export const getTemplateDescription = createSelector(getTemplate, t =>
  get(t, 'description', '')
);
export const getTemplateVersionId = createSelector(getTemplate, t =>
  get(t, 'version', '')
);
