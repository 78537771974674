import React from 'react';
import FoodEntry from '../FoodEntry';
import MealBodyHeader from './MealBodyHeader';
import NutrientsSumRow from '../NutrientsSumRow';
import last from 'lodash/last';
import { trimSeconds } from 'redux/modules/meals/meals.selectors';

class MealBody extends React.PureComponent {
  render() {
    const {
      meals,
      nutrimentsToShow,
      onDeleteEntry,
      onAddEntry,
      onEntryClick,
      onHeaderClose,
      onHeaderClick,
      sum,
    } = this.props;

    const first = meals[0].consume_time;
    const lastTime = last(meals).consume_time;

    const mealName =
      first === lastTime
        ? trimSeconds(first)
        : [first, lastTime].map(trimSeconds).join(' - ');
    return (
      <tbody>
        <MealBodyHeader
          mealName={mealName}
          showNutriments={false}
          nutrimentsToShow={nutrimentsToShow}
          onRemoveColumn={onHeaderClose}
          onClick={onHeaderClick}
          mealIds={meals.map(x => x.id)}
          onAddEntry={() => onAddEntry()}
        />
        {meals.map(meal =>
          meal.entries.map((entry, i) => (
            <FoodEntry
              key={entry.id}
              even={i % 2}
              nutrimentsToShow={nutrimentsToShow}
              entry={entry}
              onDelete={() => onDeleteEntry(entry.id)}
              onClick={() => onEntryClick(entry.id)}
            />
          ))
        )}
        <NutrientsSumRow nutrimentsToShow={nutrimentsToShow} sum={sum} />
      </tbody>
    );
  }
}

export default MealBody;
