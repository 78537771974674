import types, { pre } from './foods.types';
import { combineReducers } from 'redux';
import { makeMapFetchStatus, makeById } from '../../../utils/standardReducers';

const baseById = makeById('foods');
export const byId = (state = {}, action) => {
  if (action.type === types.CLEAR_FOOD_LIST) {
    return {};
  }
  return baseById(state, action);
};

export default combineReducers({
  byId,
  fetchStatus: makeMapFetchStatus(`${pre}FETCH_FOOD`, 'foodId'),
});
