import LinearProgress from './loaders/LinearProgress';
import React from 'react';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  --mdc-theme-primary: ${x => x.theme.palette.primary1};
`;

export const ButtonLoader = () => (
  <LoaderWrapper>
    <LinearProgress />
  </LoaderWrapper>
);
