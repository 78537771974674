import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SearchBar from 'components/SearchBar';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import TemplateCard from 'components/TemplateCard';
import { Button } from 'components/material/Button';
import CreateTemplateModal from './CreateTemplateModal';
import NoTemplates from './NoTemplates';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { TEMPLATE_VISIBILITY } from 'constants/index';
import HorizontalLoader from 'components/loaders/HorizontalLoader';
import FullPage from 'components/FullPage';
import { Switch, Route } from 'react-router-dom';
import ReportEditor from '../../Export/ReportEditor';
import TemplateLoader from '../../Export/TemplateLoader';
import { useVisibleElements } from 'utils/hooks';
import Spacer from 'components/Spacer';
import { PageWrapper } from 'components/PageWrapper';
import Typography from 'components/material/Typography';
import InfoText from 'components/InfoText';

const messages = defineMessages({
  title: { id: 'templates.title' },
  newTemplate: { id: 'templates.newButton' },
  placeholder: { id: 'templates.search' },
  help: { id: 'templates.help' },
});

const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
`;

const TemplatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 160px;
`;

function Templates({
  fetchTemplates,
  templates,
  createTemplate,
  duplicateTemplate,
  isLoading,
}) {
  const [search, setSearch] = useState('');
  const history = useHistory();
  const match = useRouteMatch();
  const intl = useIntl();
  const visibleTemplates = useVisibleElements(search, templates, [
    'title',
    'description',
    'emoji',
  ]);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const openTemplate = templateId => {
    history.push(`${match.url}/${templateId}`);
  };

  const renderLoader = () => (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 100,
      }}
    >
      <HorizontalLoader />
    </div>
  );

  return (
    <PageWrapper>
      <Typography use="headline5" rank="primary" tag="div" spacing="lg">
        <InfoText textMessage={messages.title} tooltipMessage={messages.help} />
      </Typography>
      <SearchBarWrapper>
        <SearchBar
          value={search}
          onInputChange={value => setSearch(value)}
          placeholder={intl.formatMessage(messages.placeholder)}
        />
        <Spacer spacing="xl" />
        <div>
          <Button
            unelevated
            onClick={createTemplate}
            style={{ whiteSpace: 'nowrap' }}
          >
            <FormattedMessage {...messages.newTemplate} />
          </Button>
        </div>
      </SearchBarWrapper>
      <TemplatesWrapper>
        {isLoading && !visibleTemplates.length ? renderLoader() : null}
        {visibleTemplates.map(template => (
          <React.Fragment key={template.id}>
            <TemplateCard
              {...template}
              onClick={
                template.visibility === TEMPLATE_VISIBILITY.PUBLIC
                  ? duplicateTemplate
                  : openTemplate
              }
              actionsEnabled
            />
          </React.Fragment>
        ))}
        {!isLoading && !visibleTemplates.length && (
          <NoTemplates onAdd={createTemplate} />
        )}
      </TemplatesWrapper>
      <CreateTemplateModal />
    </PageWrapper>
  );
}

export default function(props) {
  const match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={`${match.path}`}>
          <Templates {...props} />
        </Route>
        <Route path={`${match.path}/:templateId`}>
          <FullPage>
            <ReportEditor />
            <TemplateLoader />
          </FullPage>
        </Route>
      </Switch>
    </div>
  );
}
