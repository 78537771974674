import DayHeaders from './DayHeaders';
import { connect } from 'react-redux';
import { getOrderedComparedDiariesDates } from 'redux/modules/foodDiaries/foodDiaries.selectors';
import { getModalOpen, MODAL_TYPES } from 'redux/modules/modals/modals';
import { getFullLocale } from 'redux/modules/account/account.selectors';

const mapStateToProps = state => ({
  dates: getOrderedComparedDiariesDates(state),
  modalOpen:
    getModalOpen(state, MODAL_TYPES.MEAL_GROUP) ||
    getModalOpen(state, MODAL_TYPES.ADD_FOOD) ||
    getModalOpen(state, MODAL_TYPES.EDIT_ENTRY) ||
    getModalOpen(state, MODAL_TYPES.MINDFUL_HELP),
  locale: getFullLocale(state),
});

export default connect(mapStateToProps)(DayHeaders);
