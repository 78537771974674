import { secureCall, json, JSON_HEADER } from './api';
import { get } from '../utils/storage';
import { TIME_FORMATS } from '../constants';

export const updateLocale = locale => _updateProfile({ locale });

export const updateToolbox = nutrients => _updateProfile({ nutrients });
export const updateOnboard = onboard => _updateProfile({ onboard });
export const updateName = full_name => _updateProfile({ full_name });
export const updateTimeFormat = time_format =>
  _updateProfile({
    prefer_twelve_hour: time_format === TIME_FORMATS.TWELVE_HOUR,
  });

export const _updateProfile = profileProps =>
  secureCall(`/dietitians/${get('id')}`, {
    method: 'put',
    headers: JSON_HEADER,
    body: JSON.stringify(profileProps),
  }).then(json);

export const fetchAccount = () =>
  secureCall(`/dietitians/${get('id')}`).then(json);

export const updateToken = () =>
  secureCall('/dietitians/token', {
    method: 'PATCH',
    headers: JSON_HEADER,
  }).then(json);

export default {
  fetchAccount,

  updateLocale,
  updateToolbox,
  updateName,
  updateOnboard,
  updateTimeFormat,

  updateToken,
};
