import React, { useEffect, useState } from 'react';
import { Select } from 'components/material/Select';
import { useSelector } from 'react-redux';
import { getIsLocaleFr } from 'redux/modules/account/account.selectors';
import { defineMessages, useIntl } from 'react-intl';
import get from 'lodash/get';

const messages = defineMessages({
  month: { id: 'dri.profile.month' },
});

export const MONTH_OPTIONS = isFr => [
  { id: 0, label: isFr ? 'Janvier' : 'January', value: 1 },
  { id: 1, label: isFr ? 'Février' : 'February', value: 2 },
  { id: 2, label: isFr ? 'Mars' : 'March', value: 3 },
  { id: 3, label: isFr ? 'Avril' : 'April', value: 4 },
  { id: 4, label: isFr ? 'Mai' : 'May', value: 5 },
  { id: 5, label: isFr ? 'Juin' : 'June', value: 6 },
  { id: 6, label: isFr ? 'Juillet' : 'July', value: 7 },
  { id: 7, label: isFr ? 'Août' : 'August', value: 8 },
  { id: 8, label: isFr ? 'Septembre' : 'September', value: 9 },
  { id: 9, label: isFr ? 'Octobre' : 'October', value: 10 },
  { id: 10, label: isFr ? 'Novembre' : 'November', value: 11 },
  { id: 11, label: isFr ? 'Décembre' : 'December', value: 12 },
];

function findMonth(monthNumber, options) {
  const num = monthNumber ? parseInt(monthNumber, 10) : monthNumber;
  if (typeof num !== 'number') {
    return undefined;
  }
  return options[num - 1];
}

export function MonthPicker({ value, onChange }) {
  const isFr = useSelector(getIsLocaleFr);
  const intl = useIntl();
  const options = MONTH_OPTIONS(isFr);

  const [val, setVal] = useState(findMonth(value, options));
  useEffect(() => {
    const f = findMonth(value, options);
    if (!f || val.id !== f.id) {
      setVal(f);
    }
  }, [value]);

  return (
    <Select
      outlined
      value={`${get(val, 'value', undefined)}`}
      options={options}
      onChange={e => {
        setVal(options[e.detail.index]);
        onChange(parseInt(e.currentTarget.value));
      }}
      label={intl.formatMessage(messages.month)}
      placeholder={intl.formatMessage(messages.month)}
      rootProps={{ style: { width: '100%' } }}
    />
  );
}
