import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { withTheme } from 'styled-components';
import { injectIntl } from 'react-intl';

/**
 *
 * @param {string} target : className, must start with a dot (ex. .verified-step)
 * @param {string} onBoardId : the onboard id linked to this tour
 */
function SimpleTour({
  onboardId,
  target,
  titleMsg,
  contentMsg,
  closeMsg,

  joyrideOptions = {},
  waitS = 0,

  profileLoaded,
  onboard,
  setOnboardRun,

  theme,
  intl,

  children,

  canRun = true,
}) {
  const [run, setRun] = useState(false);
  const [step, setStep] = useState({
    target,
    title: intl.formatMessage(titleMsg),
    content: intl.formatMessage(contentMsg),
    placement: 'bottom',
    locale: {
      close: closeMsg ? intl.formatMessage(closeMsg) : 'Ok',
    },
    isFixed: true,
  });
  const steps = [step];
  const [waiting, setWaiting] = useState(waitS ? true : false);

  useEffect(() => {
    if (waitS === 0 && !waiting) return;

    setWaiting(true);
    const t = setTimeout(() => {
      setWaiting(false);
    }, waitS * 1000);

    return () => clearTimeout(t);
  }, [waitS]);

  useEffect(() => {
    if (!profileLoaded) return;

    setStep({ ...step, ...joyrideOptions });

    setRun(!onboard[onboardId]);
  }, [onboard, profileLoaded]);

  const _onEndTour = ({ step, status }) => {
    if (status !== STATUS.FINISHED || step.target !== target) return;

    setOnboardRun(onboardId);
  };

  return (
    <>
      {children}
      <Joyride
        steps={steps}
        run={run && !waiting && canRun}
        callback={_onEndTour}
        styles={{
          options: {
            primaryColor: theme.palette.primarySaturated,
            zIndex: 1000,
          },
        }}
      />
    </>
  );
}

export default withTheme(injectIntl(SimpleTour));
