import thunk from 'redux-thunk';
import api from './api';
import logger from './logger';
import revoked from './revoked';
import analytics from './analytics';

const middlewares = [thunk, api, analytics, revoked];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

export default middlewares;
