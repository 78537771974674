import styled from 'styled-components';
import React from 'react';
import CircleLoader from './loaders/CircleLoader';

// Somehow, this particular div does not work using styled-components
const TopContainer = styled.div``;
const TopContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexGrow: '1',
  width: '100%',
  padding: '0',
  height: '45px',
};

const SearchInput = styled.input`
  display: inline-block;
  outline: 0;
  color: ${props => props.theme.palette.text.primary};
  border: 2px solid;
  margin: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  flex-grow: 1;
  align-self: stretch;
  transition: border 0.25s linear, color 0.25s linear,
    background-color 0.25s linear;
  border-color: ${props =>
    props.value ? props.theme.palette.primary1 : props.theme.palette.accent2};
`;

const SearchIconContainer = styled.div`
  position: relative;
  color: ${props =>
    props.search ? props.theme.palette.primary1 : props.theme.palette.accent2};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  align-self: stretch;
  border-bottom: 2px solid;
  transition: border 0.25s linear, color 0.25s linear,
    background-color 0.25s linear;
  border-color: ${props =>
    props.search ? props.theme.palette.primary1 : props.theme.palette.accent2};
`;

const SearchBar = ({ placeholder, value, onInputChange, loading = false }) => {
  return (
    <TopContainer style={TopContainerStyle}>
      <SearchIconContainer search={value}>
        {loading ? <CircleLoader /> : <i className="material-icons">search</i>}
      </SearchIconContainer>
      <SearchInput
        className="mdc-typography--body1"
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={e => onInputChange(e.target.value)}
        autoFocus={true}
      />
    </TopContainer>
  );
};

export default SearchBar;
