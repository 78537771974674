import React from 'react';
import styled, { useTheme } from 'styled-components';
import { openYoutubeVideo } from 'utils/misc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defineMessages, FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { getSmallLocale } from 'redux/modules/account/account.selectors';

const messages = defineMessages({
  welcome: { id: 'onboard.videos.welcome' },
  clientExperience: { id: 'onboard.videos.clientExperience' },
  consultation: { id: 'onboard.videos.consultation' },
  retention: { id: 'onboard.videos.retention' },
});

export const ONBOARD_VIDEO_WIDTH = 400;

const Wrapper = styled.div`
  line-height: 1.15rem !important;
`;
const VideoRow = styled.div`
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
`;

const VIDEOS = isFr => [
  {
    title: messages.welcome,
    id: isFr ? 'IO0UbvizEKg' : 'nxe1WGpxv1Q',
    duration: '1 min',
  },
  {
    title: messages.clientExperience,
    id: isFr ? '44f58MKaK6w' : 'kwHSp8c5v1Q',
    duration: '1 min',
  },
  {
    title: messages.consultation,
    id: isFr ? 'UDAuY7V7GBs' : 'fPotqgiWvyg',
    duration: '1 min',
  },
  {
    title: messages.retention,
    id: isFr ? '-MeFxIUL-DI' : 'kzf7JM-ImKY',
    duration: '1 min',
  },
];

function Video({ title, id, viewed, onClick, duration }) {
  return (
    <VideoRow onClick={onClick}>
      <div>
        {!viewed ? (
          <FontAwesomeIcon
            icon={['fal', 'play-circle']}
            style={{ marginRight: 8, fontSize: 24 }}
          />
        ) : (
          <FontAwesomeIcon
            icon={['fas', 'check-circle']}
            style={{ marginRight: 8, fontSize: 24 }}
            color={'#4B9E73'}
          />
        )}
      </div>
      <Wrapper className="mdc-typography--body2">
        <div style={{ fontSize: 15, fontWeight: 500 }}>
          <FormattedMessage {...title} />
        </div>
        <div className="mdc-typography--body2" style={{ opacity: 0.33 }}>
          {duration}
        </div>
      </Wrapper>
      <div style={{ width: 120, marginLeft: 'auto' }}>
        <img
          src={`https://i1.ytimg.com/vi/${id}/mqdefault.jpg`}
          style={{ width: 120, height: 'auto' }}
          alt={'Youtube video thumbnail'}
        />
      </div>
    </VideoRow>
  );
}

function Onboarding({ onboard, updateOnboard, updateModalProps }) {
  const locale = useSelector(getSmallLocale);
  const theme = useTheme();

  const onboardKey = id => {
    return `onboard_video_${id}`;
  };

  const isViewed = ({ id }) => {
    return !!onboard[onboardKey(id)];
  };

  const onNext = currentId => {
    const _videos = VIDEOS(locale === 'fr');
    const nextId = _findNextId(currentId);
    let props = {
      id: nextId,
    };
    const nextNextId = _findNextId(nextId);
    if (!nextNextId) {
      props.onNext = undefined;
    }
    updateModalProps(props);

    function _findNextId(id) {
      const index = _videos.findIndex(x => x.id === id);
      return get(_videos, [index + 1, 'id']);
    }
  };

  const makeOnClick = ({ id }) => () => {
    updateOnboard({ ...onboard, [onboardKey(id)]: true });
    openYoutubeVideo(id, onNext);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: ONBOARD_VIDEO_WIDTH,
        color: theme.palette.text.primary,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {VIDEOS(locale === 'fr').map(v => (
          <Video {...v} viewed={isViewed(v)} onClick={makeOnClick(v)} />
        ))}
      </div>
    </div>
  );
}
export default Onboarding;
