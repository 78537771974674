import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import Typography from 'components/material/Typography';
import Avatar from './Avatar';
import { LEFT_NAV_WIDTH } from 'constants/index.js';
import Help from './Help';
import Support from './Support';
import { defineMessages, FormattedMessage } from 'react-intl';
import NavButton from './NavButton';

const messages = defineMessages({
  clients: { id: 'mainNav.clients' },
  library: { id: 'mainNav.library' },
});

const Wrapper = styled.div`
  width: ${LEFT_NAV_WIDTH}px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: static;
  opacity: 1;
  z-index: 1000;
`;

const Logo = styled.img`
  height: 45px;
  margin: 5px;
  &:hover {
    cursor: pointer;
  }
`;

function LeftNav({ theme }) {
  return (
    <Wrapper style={{}}>
      <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
        <li>
          <Link to="/">
            <Logo src="/logo_small.png" alt="logo" />
          </Link>
        </li>
        <li>
          <NavButton
            to="/clients"
            activeStyle={{
              color: theme.palette.primarySaturated,
              backgroundColor: 'white',
            }}
          >
            <FontAwesomeIcon icon={['fad', 'users']} style={{ fontSize: 20 }} />
            <Typography use="subtitle2" style={{ fontSize: 10 }}>
              <FormattedMessage {...messages.clients} />
            </Typography>
          </NavButton>
        </li>
        <li>
          <NavButton
            to="/library"
            activeStyle={{
              color: theme.palette.primarySaturated,
              backgroundColor: 'white',
            }}
          >
            <FontAwesomeIcon icon={['fad', 'books']} style={{ fontSize: 20 }} />
            <Typography use="subtitle2" style={{ fontSize: 10 }}>
              <FormattedMessage {...messages.library} />
            </Typography>
          </NavButton>
        </li>
      </ul>
      <ul
        style={{
          listStyleType: 'none',
          padding: 5,
          paddingBottom: 15,
          margin: 0,
        }}
      >
        <li>
          <Support />
        </li>
        <li>
          <Help />
        </li>
        <li style={{ padding: 7 }}>
          <Avatar />
        </li>
      </ul>
    </Wrapper>
  );
}

export default withTheme(LeftNav);
