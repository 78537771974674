import { connect } from 'react-redux';
import {
  closeModal,
  getModalOpen,
  MODAL_TYPES,
} from 'redux/modules/modals/modals';
import AddPayment from './AddPayment';
import { accountSelectors } from 'redux/modules/account';

const mapStateToProps = state => ({
  isOpen: getModalOpen(state, MODAL_TYPES.ADD_PAYMENT),
  locale: accountSelectors.getLocale(state),
});

const mapDispatchToProps = dispatch => ({
  onExit: () => dispatch(closeModal(MODAL_TYPES.ADD_PAYMENT)),
});

const AddPaymentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPayment);

export default AddPaymentContainer;
