import React from 'react';
import NutrientsSumRow from '../NutrientsSumRow';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  stdev: {
    id: 'foodLog.stdevRow',
  },
  stdevHelp: {
    id: 'foodlog.stdevHelp',
  },
});

class CompareStdev extends React.PureComponent {
  render() {
    const { sum, nutrients } = this.props;
    return (
      <tbody>
        <NutrientsSumRow
          top
          nutrimentsToShow={nutrients}
          sum={sum}
          message={messages.stdev}
          helpMessage={messages.stdevHelp}
        />
      </tbody>
    );
  }
}

export default CompareStdev;
