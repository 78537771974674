import React from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { TextField } from 'components/forms/Textfield';
import Spacer from 'components/Spacer';
import { Button } from 'components/material/Button';
import Typography from 'components/material/Typography';
import styled from 'styled-components';
import A from 'components/A';
import { useState } from 'react';
import { useHistory, useLocation, Link, Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import get from 'lodash/get';
import { isTokenValid } from 'utils/auth';

const messages = defineMessages({
  welcome: {
    id: 'login.welcome',
    defaultMessage: 'Sign in to Keenoa',
  },
  emailPlaceholder: {
    id: 'global.email',
    defaultMessage: 'Email',
  },
  passwordPlaceHolder: {
    id: 'global.password',
    defaultMessage: 'Password',
  },
  forgotPassword: {
    id: 'login.forgotPassword',
    defaultMessage: 'Forgot your password ?',
  },
  loginButton: {
    id: 'login.loginButton',
    defaultMessage: 'Login',
  },
  signUp: {
    id: 'login.signUp',
    defaultMessage: 'Create an account',
  },
  newAccount: {
    id: 'login.new',
    defaultMessage: 'New to Keenoa ?',
  },
  error: {
    id: 'login.error',
    defaultMessage:
      'Sorry, we could not find your account with this email and password.',
  },
});

const SpacedInput = styled(TextField)`
  width: 100%;
`;

const ErrorContainer = styled.div`
  background-color: ${props => props.theme.palette.errorBg};
  border: 1px solid ${props => props.theme.palette.canvas};
  padding: 15px;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const ErrorBox = ({ type, showErrors }) =>
  !type || !showErrors ? null : (
    <ErrorContainer>
      <FormattedMessage {...messages.error} />
      <Link style={{ marginLeft: '3px' }} to="/begin-password-reset">
        <FormattedMessage {...messages.forgotPassword} />
      </Link>
    </ErrorContainer>
  );

function Login({ errorType, onSubmit, intl }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showErrors, setShowErrors] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const from = get(location, 'state.from');

  const loggedIn = isTokenValid();

  useEffect(() => {
    if (errorType) {
      setPassword('');
      setShowErrors(true);
    }
  }, [errorType]);

  const onChangeEmail = e => {
    setEmail(e);
    setShowErrors(false);
  };
  const onChangePassword = p => {
    setPassword(p);
    setShowErrors(false);
  };
  const _onSubmit = async () => {
    try {
      await onSubmit({ email, password });
      history.replace(from || '/clients');
    } catch (e) {}
  };
  const _canSubmit = () => {
    return email && password;
  };

  if (loggedIn) return <Redirect to="/clients" />;

  return (
    <form
      autoComplete="on"
      onSubmit={e => {
        e.preventDefault();
        _onSubmit();
      }}
      onKeyPress={e => {
        if (e.key === 'Enter') _onSubmit();
      }}
    >
      <ErrorBox type={errorType} showErrors={showErrors} />
      <Typography use="headline5" rank="primary">
        <FormattedMessage {...messages.welcome} />
      </Typography>
      <Spacer spacing="xl" />
      <SpacedInput
        outlined
        type="email"
        name="email"
        autoComplete="email"
        label={intl.formatMessage(messages.emailPlaceholder)}
        value={email}
        onChange={e => onChangeEmail(e.target.value)}
      />
      <Spacer spacing="lg" />
      <SpacedInput
        outlined
        label={intl.formatMessage(messages.passwordPlaceHolder)}
        type="password"
        name="password"
        autoComplete="current-password"
        value={password}
        onChange={e => onChangePassword(e.target.value)}
      />
      <Spacer spacing="sm" />
      <Link to="/begin-password-reset">
        <A>
          <FormattedMessage {...messages.forgotPassword} />
        </A>
      </Link>
      <Spacer spacing="lg" />
      <Button
        type="submit"
        unelevated
        style={{ width: '100%' }}
        disabled={!_canSubmit()}
      >
        <FormattedMessage {...messages.loginButton} />
      </Button>
      <Spacer spacing="md" />
      <span>
        <Typography use="subtitle2" rank="secondary">
          <FormattedMessage {...messages.newAccount} />{' '}
        </Typography>
        <Link to="signup">
          <A>
            <FormattedMessage {...messages.signUp} />
          </A>
        </Link>
      </span>
    </form>
  );
}

export default injectIntl(Login);
