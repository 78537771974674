import { secureCall, json, JSON_HEADER } from './api';
import { get } from '../utils/storage';

export const fetchClients = () =>
  secureCall(`/dietitians/${get('id')}/clients`).then(json);

export const inviteClient = (email, locale) =>
  secureCall(`/dietitians/${get('id')}/clients`, {
    method: 'post',
    headers: JSON_HEADER,
    body: JSON.stringify({ email, locale }),
  });

export const renewInviteApi = email =>
  secureCall(`/dietitians/${get('id')}/clients`, {
    method: 'PATCH',
    headers: JSON_HEADER,
    body: JSON.stringify({ email }),
  });

export const updateProfileApi = (clientId, props) =>
  secureCall(`/clients/${clientId}`, {
    method: 'PUT',
    headers: JSON_HEADER,
    body: JSON.stringify(props),
  }).then(json);

export const fetchToken = clientId =>
  secureCall(`/clients/${clientId}/permissions`)
    .then(json)
    .then(x => x.token);

export const updateClientSettingsApi = (clientId, settings) =>
  secureCall(`/dietitians/${get('id')}/clients/${clientId}`, {
    method: 'PUT',
    headers: JSON_HEADER,
    body: JSON.stringify({ settings }),
  }).then(json);

export const updateClientNutrients = (clientId, nutrients) =>
  secureCall(`/dietitians/${get('id')}/clients/${clientId}`, {
    method: 'PUT',
    headers: JSON_HEADER,
    body: JSON.stringify({ nutrients }),
  }).then(json);

export const updateClientStatusApi = (clientId, status) =>
  secureCall(`/dietitians/${get('id')}/clients/${clientId}`, {
    method: 'PUT',
    headers: JSON_HEADER,
    body: JSON.stringify({ status }),
  }).then(json);

export const removeInviteApi = email =>
  secureCall(`/dietitians/${get('id')}/clients`, {
    method: 'DELETE',
    headers: JSON_HEADER,
    body: JSON.stringify({ email }),
  });

export const acceptDietitianInvitationApi = token =>
  secureCall(`/dietitian-invitations`, {
    method: 'PATCH',
    headers: JSON_HEADER,
    body: JSON.stringify({ token }),
  });

export default {
  fetchToken,
  fetchClients,

  inviteClient,
  renewInviteApi,
  updateClientNutrients,

  updateClientStatusApi,
  removeInviteApi,
};
