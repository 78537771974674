import React from 'react';
import styled from 'styled-components';
import MealBody from './MealBody';
import NutrientsSumRow from './NutrientsSumRow';
import NutrientTd from './NutrientTd';
import MealBodyHeader from './MealBody/MealBodyHeader';
import CompareAverage from './CompareAverage';
import { defineMessages, FormattedMessage } from 'react-intl';
import CompareStdev from './CompareStdev';
import CompareCoefVar from './CompareCoefVar';
import CompareMin from './CompareMin';
import CompareMax from './CompareMax';
import DRIs from './DRIs';
import { shadowLevels } from 'components/WhiteFrame';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const messages = defineMessages({
  total: { id: 'foodLog.totalRow' },
  basic: { id: 'foodLog.basic' },
  advanced: { id: 'foodLog.advanced' },
  dri: { id: 'foodLog.dri' },
});

const StyledTable = styled.table`
  border-collapse: collapse;
  margin-top: 3px;
  text-align: center;
  width: 100%;
`;

const Spacer = ({ nutrimentsToShow }) => (
  <tbody>
    <tr>
      <td />
      {nutrimentsToShow.map(n => (
        <NutrientTd style={{ padding: '10px' }} key={n.id} nutrient={n} />
      ))}
      <td />
    </tr>
  </tbody>
);

const Subheader = ({ message, first }) => (
  <tbody>
    <tr>
      <td
        className="mdc-typography--overline"
        style={{
          textAlign: 'right',
          paddingTop: first ? 0 : 20,
          paddingRight: 20,
          color: 'rgba(0,0,0,0.33)',
          minWidth: 280,
        }}
      >
        <div style={first ? { marginTop: '-32px' } : {}}>
          <FormattedMessage {...message} />
        </div>
      </td>
    </tr>
  </tbody>
);

const BoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  z-index: 1;
`;
const DRIBox = styled.div`
  background: #f2f2f2;
  padding: 7px 14px;
  z-index: 1;
  position: relative;
  border-radius: 50px;
  margin-left: ${x => (x.compareMode ? '-95px' : '-42.5px')};
  transition: all 0.1s ease-in-out;

  &:hover {
    box-shadow: ${shadowLevels[2]};
    transform: scale(1.1);
    cursor: pointer;
    color: ${x => x.theme.palette.primary1};
  }
`;

const EditIcon = styled(FontAwesomeIcon)`
  color: transparent;
  ${DRIBox}:hover & {
    color: ${props =>
      props.visible === 'true' ? props.theme.palette.primary1 : 'transparent'};
  }
`;

class FoodLog extends React.PureComponent {
  handleNutrientClick = nutrient => this.props.onNutrientClick(nutrient);

  renderDRIHeader = compareMode =>
    !this.props.groupDRIString ? null : (
      <tbody>
        <tr>
          <td style={{ padding: 0 }}>
            <BoxWrapper style={{ boxShadow: shadowLevels[3] }}>
              <DRIBox
                compareMode={compareMode}
                onClick={this.props.onEditProfile}
              >
                <span className="mdc-typography--body2">
                  <EditIcon icon={['far', 'pencil']} />{' '}
                  {this.props.groupDRIString}{' '}
                  <EditIcon visible="true" icon={['far', 'pencil']} />
                </span>
              </DRIBox>
            </BoxWrapper>
          </td>
        </tr>
      </tbody>
    );

  renderCompare = () => {
    const { nutrientsColumns, onMealHeaderClose, innerRef } = this.props;
    return (
      <StyledTable>
        <tbody>
          <MealBodyHeader
            showNutriments
            nutrimentsToShow={nutrientsColumns}
            onRemoveColumn={onMealHeaderClose}
            onClick={this.handleNutrientClick}
            innerRef={innerRef}
          />
        </tbody>
        <Subheader message={messages.dri} first />
        {this.renderDRIHeader(true)}
        <DRIs nutrientsColumns={nutrientsColumns} />
        <Subheader message={messages.basic} />
        <CompareAverage nutrients={nutrientsColumns} />
        <CompareMin nutrients={nutrientsColumns} />
        <CompareMax nutrients={nutrientsColumns} />
        <Subheader message={messages.advanced} />
        <CompareStdev nutrients={nutrientsColumns} />
        <CompareCoefVar nutrients={nutrientsColumns} />
      </StyledTable>
    );
  };

  render() {
    const {
      mealGroups,
      dailySum,
      nutrientsList,
      nutrientsColumns,
      onEditEntry,
      onDeleteEntry,
      onMealHeaderClose,
      innerRef,
      compareMode,
    } = this.props;
    if (compareMode) return this.renderCompare();

    return (
      <StyledTable>
        <tbody>
          <MealBodyHeader
            showNutriments
            nutrimentsToShow={nutrientsColumns}
            onRemoveColumn={onMealHeaderClose}
            onClick={this.handleNutrientClick}
            innerRef={innerRef}
          />
        </tbody>
        {this.renderDRIHeader()}
        <DRIs nutrientsColumns={nutrientsColumns} />
        <tbody>
          <NutrientsSumRow
            top
            double
            nutrimentsToShow={nutrientsColumns}
            sum={dailySum}
            final={true}
            message={messages.total}
          />
        </tbody>
        {mealGroups.map((mealGroup, i) => (
          <MealBody
            meals={mealGroup}
            nutrimentsToShow={nutrientsColumns}
            nutrientsList={nutrientsList}
            key={mealGroup[0]}
            onEntryClick={entryId => onEditEntry(entryId, mealGroup[0])}
            onDeleteEntry={entryId => onDeleteEntry(entryId, mealGroup[0])}
            onHeaderClose={onMealHeaderClose}
            onHeaderClick={this.handleNutrientClick}
          />
        ))}
        <Spacer nutrimentsToShow={nutrientsColumns} />
        <tbody>
          <NutrientsSumRow
            nutrimentsToShow={nutrientsColumns}
            sum={dailySum}
            final={true}
            message={messages.total}
          />
        </tbody>
      </StyledTable>
    );
  }
}

export default FoodLog;
