import React from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Spacer from 'components/Spacer';
import Typography from 'components/material/Typography';
import { TextField } from 'components/forms/Textfield';
import { Button } from 'components/material/Button';
import { Select } from 'components/material/Select';
import H2 from 'components/H2';
import { validateEmail } from '../../../../utils/inputValidation';
import { FETCH_STATUS } from '../../../../constants/fetchStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const messages = defineMessages({
  title: {
    id: 'newClient.form.title',
    defaultMessage: 'Invite a client.',
  },
  emailPlaceholder: {
    id: 'global.email',
    defaultMessage: 'Email',
  },
  info: {
    id: 'newClient.form.info',
    defaultMessage:
      'An email will be sent to your client so that they can grant you access to their food diary.',
  },
  inviteBtn: {
    id: 'newClient.form.inviteBtn',
    defaultMessage: 'Invite',
  },
  renewBtn: {
    id: 'newClient.form.renewBtn',
    defaultMessage: 'Re-Invite',
  },
  renewWarning: {
    id: 'newClient.form.renewWarning',
    defaultMessage:
      'It looks like you already have an invite with this email, do you want to renew the invitation ?',
  },
  renewTip: {
    id: 'newClient.form.renewTip',
    defaultMessage:
      'Tip: You can renew an invite directly by selecting a client from your clients list.',
  },
  inviteLoading: {
    id: 'newClient.form.inviteLoading',
    defaultMessage: 'Sending...',
  },
  fr: {
    id: 'newClient.form.fr',
    defaultMessage: 'French',
  },
  en: {
    id: 'newClient.form.en',
    defaultMessage: 'English',
  },
  language: {
    id: 'newClient.form.language',
    defaultMessage: 'Invite language',
  },
  preview: {
    id: 'newClient.form.preview',
    defaultMessage: 'Preview',
  },
});

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const WarningIcon = styled(FontAwesomeIcon)`
  color: ${x => x.theme.palette.warning};
  margin-right: 3px;
`;

class NewClientForm extends React.PureComponent {
  _renewInvite = () => {
    const { email, renewInvite } = this.props;
    renewInvite(email);
  };

  renderError() {
    const { renewStatus } = this.props;
    return (
      <div>
        <WarningIcon icon={['fad', 'exclamation-triangle']} />
        <FormattedMessage {...messages.renewWarning} />
        <Typography use="body2" rank="secondary" tag="div">
          <FormattedMessage {...messages.renewTip} />
        </Typography>
        <ButtonContainer>
          <Button
            btnType="primary"
            onClick={this._renewInvite}
            style={{ padding: '4px 30px' }}
            disabled={renewStatus === FETCH_STATUS.LOADING}
          >
            {renewStatus === FETCH_STATUS.LOADING ? (
              <FormattedMessage {...messages.inviteLoading} />
            ) : (
              <FormattedMessage {...messages.renewBtn} />
            )}
          </Button>
        </ButtonContainer>
      </div>
    );
  }

  _pickLocale = id => {
    const { onLocaleChange } = this.props;
    onLocaleChange(id);
  };

  renderLocalePicker = () => {
    const { locale, intl } = this.props;
    const options = [
      {
        value: 'fr',
        label: intl.formatMessage(messages.fr),
      },
      {
        value: 'en',
        label: intl.formatMessage(messages.en),
      },
    ];
    const l = locale ? locale.slice(0, 2) : 'en';
    const { value } = options.find(x => x.value === l);
    return (
      <Select
        outlined
        label={intl.formatMessage(messages.language)}
        options={options}
        value={value}
        onChange={e => this._pickLocale(e.currentTarget.value)}
        rootProps={{ style: { width: '100%' } }}
      />
    );
  };

  renderForm() {
    const {
      intl,
      email,
      onEmailChange,
      onInviteClick,
      status,
      onPreview,
    } = this.props;
    const { formatMessage } = intl;
    const disabled = !validateEmail(email) || status === FETCH_STATUS.LOADING;
    return (
      <div>
        <Typography use="body2" rank="secondary" tag="div">
          <FormattedMessage {...messages.info} />
        </Typography>
        <Spacer spacing="xl" />
        <TextField
          outlined
          value={email}
          type="email"
          autoComplete="off"
          name="email"
          style={{ width: '100%' }}
          onChange={e => onEmailChange(e.target.value)}
          label={formatMessage(messages.emailPlaceholder)}
          autoFocus={true}
        />
        <Spacer spacing="lg" />
        {this.renderLocalePicker()}
        <Spacer spacing="md" />
        <ButtonContainer>
          <Button
            unelevated
            disabled={disabled}
            onClick={() => onInviteClick()}
          >
            {status === FETCH_STATUS.LOADING ? (
              <FormattedMessage {...messages.inviteLoading} />
            ) : (
              <FormattedMessage {...messages.inviteBtn} />
            )}
          </Button>
          <Button
            style={{ marginRight: 10 }}
            disabled={disabled}
            onClick={() => onPreview()}
          >
            <FormattedMessage {...messages.preview} />
          </Button>
        </ButtonContainer>
      </div>
    );
  }

  render() {
    const { onInviteClick, email, error } = this.props;
    return (
      <div
        onKeyPress={e => {
          if (e.key === 'Enter') {
            onInviteClick(email);
          }
        }}
      >
        <H2>
          <FormattedMessage {...messages.title} />
        </H2>
        {error ? this.renderError() : this.renderForm()}
      </div>
    );
  }
}

export default injectIntl(NewClientForm);
