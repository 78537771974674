export default {
  fontFamily: 'Roboto, sans-serif',
  palette: {
    protein: '#4990e2',
    primary1: '#4990e2',
    primarySaturated: '#2e8fff',
    primary2: '#37A475',
    primary3: '#fda453',
    primary4: '#FD9D80',
    accent1: '#FF5252',
    accent2: '#a5a5b1',
    warning: '#f1c40f',
    success: '#2ecc71',
    error: '#e74c3c',
    errorBg: '#fbdedb',
    text: {
      primary: '#212121',
      midPrimary: '#474747',
      secondary: '#757575',
      tertiary: '#ABABAB',
    },
    textLight: '#818181',
    alternateText: '#ecf0f1',
    canvas: 'white',
    border: '#a5a5b1',
    border2: 'gainsboro',
    white: 'white',
    black: '#1a1a1a',
    neutral: '#7f7f7f',

    customGray: '#EFEFF4',
    lightGray: '#E5E5EA',
    lightGray2: '#D1D1D6',
    midGray: '#C7C7CC',
    gray: '#8E8E93',
  },
};
