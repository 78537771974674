import React, { Component } from 'react';
import './WistiaEmbed.css';
import HorizontalLoader from './loaders/HorizontalLoader';

class WistiaEmbed extends Component {
  constructor(props) {
    super(props);
    const { hashedId, ...embedOptions } = { ...this.props };
    window._wq = window._wq || [];
    window._wq.push({
      id: hashedId,
      options: embedOptions,
      onHasData: this.bind,
    });
  }

  bind = video => {
    this.video = video;
    this.props.onReady();
    video.bind('crosstime', 63, this.props.onEnd);
  };

  componentWillUnmount() {
    if (this.video) {
      this.video.unbind();
    }
  }

  render() {
    return (
      <div
        className="wistia_responsive_padding"
        style={{ padding: '56.25% 0 0 0', position: 'relative' }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <HorizontalLoader />
        </div>
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: '100%',
            left: '0',
            position: 'absolute',
            top: '0',
            width: '100%',
          }}
        >
          <div
            className={`wistia_embed wistia_async_${this.props.hashedId} videoFoam=true`}
            style={{ height: '100%', width: '100%' }}
          >
            &nbsp;
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (!document.getElementById('wistia_script')) {
      var wistiaScript = document.createElement('script');
      wistiaScript.id = 'wistia_script';
      wistiaScript.type = 'text/javascript';
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaScript.async = true;
      document.body.appendChild(wistiaScript);
    }
  }
}

export default WistiaEmbed;
