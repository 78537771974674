export default [
  {
    value: 1,
    label: 'College of Dietitians of British Columbia',
  },
  {
    value: 2,
    label: 'College of Dietitians of Alberta',
  },
  {
    value: 3,
    label: 'Saskatchewan Dietitians Association',
  },
  {
    value: 4,
    label: 'College of Dietitians of Manitoba',
  },
  {
    value: 5,
    label: 'College of Dietitians of Ontario',
  },
  {
    value: 6,
    label: 'Ordre professionnel des diététistes du Québec',
  },
  {
    value: 7,
    label: 'New Brunswick Association of Dietitians',
  },
  {
    value: 8,
    label: 'Nova Scotia Dietetic Association',
  },
  {
    value: 9,
    label: 'PEI Dietitians Registration Board',
  },
  {
    value: 10,
    label: 'Newfoundland and Labrador College of Dietitians',
  },
  {
    value: 0,
    label: 'Other',
  },
];
