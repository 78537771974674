const pre = 'keenoa/UI/reportView/';

export default {
  LOAD_STRUCTURE: `${pre}LOAD_STRUCTURE`,
  LOAD_DIARY_IDS: `${pre}LOAD_DIARY_IDS`,

  STAGE_TEMPLATE: `${pre}STAGE_TEMPLATE`,
  STAGE_REPORT: `${pre}STAGE_REPORT`,

  OPEN_REPORT_EXPORT: `${pre}OPEN_REPORT_EXPORT`,
  TOGGLE_REPORT_DIARY: `${pre}TOGGLE_REPORT_DIARY`,

  UPDATE_LOGO: `${pre}UPDATE_LOGO`,

  ADD_WIDGET: `${pre}ADD_WIDGET`,
  UPDATE_WIDGET: `${pre}UPDATE_WIDGET`,
  REMOVE_WIDGET: `${pre}REMOVE_WIDGET`,
  SET_WIDGET_SECTION: `${pre}SET_WIDGET_SECTION`,
  SET_WIDGET_TITLE: `${pre}SET_WIDGET_TITLE`,

  UPDATE_WIDGET_TEMP: `${pre}UPDATE_WIDGET_TEMP`,

  REORDER_WIDGET: `${pre}REORDER_WIDGET`,
  CONFIG_WIDGET: `${pre}CONFIG_WIDGET`,
  CLOSE_WIDGET_CONFIG: `${pre}CLOSE_CONFIG_WIDGET`,

  DRAG_WIDGET_START: `${pre}DRAG_WIDGET_START`,
  DRAG_WIDGET_END: `${pre}DRAG_WIDGET_END`,

  UPDATE_WIDGET_IMAGE: `${pre}UPDATE_WIDGET_IMAGE`,
  UPDATE_WIDGET_TEXT: `${pre}UPDATE_WIDGET_TEXT`,

  START_PDF_PRE_PROCESSING: `${pre}START_PDF_PRE_PROCESSING`,
  UPDATE_PDF_PRE_PROCESSING: `${pre}UPDATE_PDF_PRE_PROCESSING`,
  DONE_PDF_PRE_PROCESSING: `${pre}DONE_PDF_PRE_PROCESSING`,

  REMOVE_PAGE: `${pre}REMOVE_PAGE`,

  RESET_EXPORT: `${pre}RESET_EXPORT`,

  RESUME_REPORT: `${pre}RESUME_REPORT`,
};
