import { createSelector } from 'reselect';
import mapValues from 'lodash/mapValues';
import {
  getCurrentFoodDiary,
  getCurrentFoodDiaryId,
  getCompareDiaries,
  getDiariesById,
  getExportDiaries,
} from '../foodDiaries/foodDiaries.selectors';
import {
  getCompareDiaryIds,
  getCompareDiariesCount,
  getExportDiariesCount,
} from '../UI/clientView';
import { getById as getEntriesId } from '../entries/entries.selectors';
import nutrimentsConstant, {
  findCorrespondingNutriements,
  idToCode,
  DRI_VALUES,
  INFANTS,
  CHILDREN,
  MALES,
  FEMALES,
  LACTATION,
  PREGNANCY,
} from './../../../constants/nutriments';
import map from 'lodash/map';
import { macroToCalorie } from '../../../utils/food';
import get from 'lodash/get';
import flatMap from 'lodash/flatMap';
import first from 'lodash/first';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import pick from 'lodash/pick';
import times from 'lodash/times';
import mapKeys from 'lodash/mapKeys';
import {
  getCurrentClientNutrientsWithMacros,
  getCurrentClientAge,
  getCurrentClientGender,
  getCurrentClientNutrients,
  getCurrentClientLactation,
  getCurrentClientPregnancy,
} from '../clients/clients.selectors';
import { dateStringToDate } from '../../../utils/dateUtils';
import { getFullLocale, getLocale } from '../account/account.selectors';
import createCachedSelector from 're-reselect';
import { createShallowEqualSelector } from '../../../utils/selectUtils';
import { CLIENT_GENDERS } from '../../../constants';
import { getExportDiaryIds } from '../UI/reportView';

export const getById = state => state.meals.byId;
const getCurrentId = state => state.meals.currentId;

/*****************************
 * HELPERS
 *******************************/
export const trimSeconds = time => {
  const t = time.split(':');
  return t[0] + ':' + t[1];
};
const _mealWithTrimedSecond = meal => {
  return { ...meal, consume_time: trimSeconds(meal.consume_time) };
};

const _pickMacros = nutrients => {
  const nc = nutrimentsConstant;
  const macros = [
    nc.CALORIES,
    nc.PROTEIN,
    nc.CARBS,
    nc.FAT,
    nc.ALCOHOL,
    nc.FIBER,
  ].map(x => x.id);
  return pick(nutrients, macros);
};
const _timeSinceLast = (before, after) => {
  const b = before.split(':');
  const a = after.split(':');
  return (a[0] - b[0]) * 60 + (a[1] - b[1]);
};

const _getInitHours = nutrimentsToShow => {
  const hours = times(12, i => i);
  const AMHours = hours.map(hr =>
    nutrimentsToShow.reduce(
      (res, n) => ({
        ...res,
        [n.id]: 0,
      }),
      { id: hr, name: `${hr}-${hr + 1}am` }
    )
  );
  const PMHours = hours.map(hr =>
    nutrimentsToShow.reduce(
      (res, n) => ({
        ...res,
        [n.id]: 0,
      }),
      { id: hr + 12, name: `${hr}-${hr + 1}pm` }
    )
  );
  return [...AMHours, ...PMHours];
};

/** (state, mealId) */
export const getMeal = createCachedSelector(
  getById,
  (_, mealId) => mealId,
  (byId, mealId) => byId[mealId]
)((_, mealId) => mealId);

export const _getByIdWithTrimmedSecond = createSelector([getById], meals =>
  mapValues(meals, meal => _mealWithTrimedSecond(meal))
);
/*****************************
 * SELECTORS
 *******************************/
/** (state, mealId) */
export const getMealEntries = createCachedSelector(
  getMeal,
  getEntriesId,
  (meal, entriesById) =>
    get(meal, 'entries', [])
      .map(entryId => get(entriesById, entryId, null))
      .filter(x => x)
)((_, mealId) => mealId);

/** (state, mealId) */
export const getMealEntriesWithCalorie = createCachedSelector(
  getMealEntries,
  (entries = []) =>
    entries.map(entry => {
      const cal = get(entry, 'nutrients', []).find(
        x => x.code === nutrimentsConstant.CALORIES.code
      );
      const calories = get(cal, 'value');
      return {
        ...entry,
        calories,
      };
    })
)((_, mealId) => mealId);

/** (state, mealId) */
export const getMealCalories = createCachedSelector(
  getMealEntriesWithCalorie,
  (entries = []) =>
    entries.reduce((total, current) => total + get(current, 'calories', 0), 0)
)((_, mealId) => mealId);

/** (state, mealId) */
export const getMealWithCalories = createCachedSelector(
  [getMeal, getMealCalories],
  (meal, calories) => ({ ...meal, calories })
)((_, mealId) => mealId);

/** (state, mealId) */
export const getMealWithEntries = createCachedSelector(
  getMeal,
  getMealEntries,
  (meal, entries) => ({ ...meal, entries })
)((_, mealId) => mealId, {
  selectorCreator: createShallowEqualSelector,
});

/** (state, mealIds) */
export const getMealsWithEntries = createCachedSelector(
  (state, mealIds) => mealIds.map(id => getMealWithEntries(state, id)),
  meals => meals
)((_, mealIds) => mealIds.join('_'), {
  selectorCreator: createShallowEqualSelector,
});

/** (state, mealIds) */
export const getMealsSortedByCalories = createCachedSelector(
  (state, mealIds) => mealIds.map(id => getMealWithCalories(state, id)),
  meals => sortBy(meals, 'calories')
)((_, mealIds) => mealIds.join('_'), {
  selectorCreator: createShallowEqualSelector,
});

/** (state, mealIds) */
export const getMealsSortedCalories = createCachedSelector(
  getMealsSortedByCalories,
  meals => meals.map(x => get(x, 'calories', 0))
)((_, mealIds) => mealIds.join('_'));

/** (state, mealIds) */
export const getMealsEntries = createCachedSelector(
  getMealsWithEntries,
  meals => flatMap(meals, meal => get(meal, 'entries', []))
)((_, mealIds) => mealIds.join('_'));

/** (state, mealIds) */
export const getMealsThumbnails = createCachedSelector(
  getMealsWithEntries,
  meals =>
    meals.map(meal => ({
      photo: get(meal, 'photo.urls.thumbnail'),
      emoji: get(meal, 'emoji'),
    }))
)((_, mealIds) => mealIds.join('_'));

/** (state, mealIds) */
export const getMealsSmallImagesSortedByCalories = createCachedSelector(
  getMealsSortedByCalories,
  meals =>
    meals.map(meal => ({
      photo: get(meal, 'photo.urls.small'),
      emoji: get(meal, 'emoji'),
    }))
)((_, mealIds) => mealIds.join('_'), {
  selectorCreator: createShallowEqualSelector,
});

export const getCurrentMealGroupCounts = createSelector(
  getCurrentFoodDiary,
  diary => {
    return get(diary, 'meal_groups_count', []);
  }
);

export const getSumMealGroups = createSelector(
  getCurrentMealGroupCounts,
  counts => get(counts, 'length', 0)
);

const _nutrimentsSumByMeal = (sum, meal, initSum, nutrimentsToShow) =>
  get(meal, 'entries', []).reduce((sum, entry) => {
    const nutriments = findCorrespondingNutriements(
      nutrimentsToShow,
      entry.nutrients
    );
    return nutriments.reduce((sum, n) => {
      if (n && n.value) {
        sum[n.id] += n.value;
      }
      return sum;
    }, initSum);
  }, initSum);

const _concatResNutrientValueWithMealSum = (res, mealSum) =>
  Object.keys(mealSum).reduce(
    (object, key) => ({
      ...object,
      [key]: object[key] + res[key],
    }),
    mealSum
  );

const _mergeMacroInHours = (result, time, macro) => {
  const hour = Number(time.substring(0, 2));
  return result.map(res => {
    if (res.id === hour) {
      return {
        ...res,
        ..._concatResNutrientValueWithMealSum(res, macro),
      };
    }
    return res;
  });
};

const _mealNutrientsSum = (meal, nutrients = []) => {
  const initSum = nutrients.reduce(
    (object, n) => ({ ...object, [n.id]: 0 }),
    {}
  );
  return _nutrimentsSumByMeal(initSum, meal, initSum, nutrients);
};

/** (state, mealId) */
export const getMealNutrients = createCachedSelector(
  getMealWithEntries,
  getCurrentClientNutrientsWithMacros,
  (meal, nutrients) => _mealNutrientsSum(meal, nutrients)
)((_, mealId) => `${mealId}`);

/** (state, mealId) */
export const getMealMacros = createCachedSelector(
  getMealNutrients,
  _pickMacros
)((_, mealId) => `${mealId}`);

const getInitSum = createSelector(
  getCurrentClientNutrientsWithMacros,
  nutrients =>
    (nutrients || []).reduce((object, n) => ({ ...object, [n.id]: 0 }), {})
);

/** Merge an array of nutrients sum into on object */
const _mergeNutrients = (nutrients, initSum) =>
  nutrients.reduce(
    (sum, cur) => mapValues(sum, (v, k) => v + get(cur, k, 0)),
    initSum
  );

/** (state, mealIds) */
export const getMealsNutrients = createCachedSelector(
  [
    (state, mealIds) => mealIds.map(id => getMealNutrients(state, id)),
    getInitSum,
  ],
  (a, b) => _mergeNutrients(a, b)
)((_, mealIds) => mealIds.join('_'), {
  selectorCreator: createShallowEqualSelector,
});

/** (state, mealIds) */
export const getMealsMacros = createCachedSelector(
  getMealsNutrients,
  _pickMacros
)((_, mealIds) => mealIds.join('_'));

/** (state, diaryId) */
export const getDiary = createCachedSelector(
  [getDiariesById, (_, diaryId) => diaryId],
  (byId, diaryId) => get(byId, diaryId)
)((_, diaryId) => diaryId);

/** (state, diaryId) */
export const getDiaryMealGroups = createSelector(getDiary, diary =>
  get(diary, 'meal_groups', [])
);
/** (state) */
export const getCurrentDiaryMealGroups = createSelector(
  state => {
    const diaryId = getCurrentFoodDiaryId(state);
    return getDiaryMealGroups(state, diaryId);
  },
  groups => groups
);

/** (state, diaryId, pos) */
export const getMealGroupFromPos = createSelector(
  getDiaryMealGroups,
  (_, diaryId, pos) => pos,
  (groups, pos) => get(groups, pos, [])
);

/** (state, pos) */
export const getCurrentDiaryMealGroupFromPos = createSelector(
  (state, pos) => {
    const diaryId = getCurrentFoodDiaryId(state);
    return getMealGroupFromPos(state, diaryId, pos);
  },
  group => group
);

/** (state, diaryId) */
export const getDiaryMealGroupsTimes = createCachedSelector(
  [getDiaryMealGroups, getById],
  (mealGroups, byId) =>
    (mealGroups || []).map(group => get(byId, [first(group), 'consume_time']))
)((_, diaryId) => diaryId);

/** (state, diaryId) */
export const getDiaryMealGroupsLastTimes = createCachedSelector(
  [getDiaryMealGroups, getById],
  (mealGroups, byId) =>
    (mealGroups || []).map(group => get(byId, [last(group), 'consume_time']))
)((_, diaryId) => diaryId);

/** (state, diaryId, pos) */
export const getGroupTimeSinceLastFromPos = createCachedSelector(
  getDiaryMealGroupsTimes,
  getDiaryMealGroupsLastTimes,
  (_, diaryId, pos) => pos,
  (firsts, lasts, pos) => {
    if (pos === 0) return null;

    const past = lasts[pos - 1];
    const next = firsts[pos];
    if (!past || !next) return null;
    return _timeSinceLast(past, next);
  }
)((_, diaryId, pos) => `${diaryId}_${pos}`);

/** (state, pos) */
export const getCurrentDiaryGroupTimeSinceLastFromPos = createCachedSelector(
  (state, pos) => {
    const diaryId = getCurrentFoodDiaryId(state);
    return getGroupTimeSinceLastFromPos(state, diaryId, pos);
  },
  time => time
)((_, pos) => `${pos}`);

/** (state, diaryId) */
export const getDiaryMealGroupsNutrients = createCachedSelector(
  [
    (state, diaryId) => {
      const mealGroups = getDiaryMealGroups(state, diaryId);
      return (mealGroups || []).map(mealIds =>
        getMealsNutrients(state, mealIds)
      );
    },
  ],
  nutrients => nutrients
)((_, diaryId) => diaryId, {
  selectorCreator: createShallowEqualSelector,
});

/** (state, diaryId) */
export const getDiaryMealGroupsMacros = createCachedSelector(
  getDiaryMealGroupsNutrients,
  groups => groups.map(_pickMacros)
)((_, diaryId) => diaryId);

/** (state, diaryId) */
export const getDiaryNutrients = createCachedSelector(
  [getDiaryMealGroupsNutrients, getInitSum],
  (a, b) => _mergeNutrients(a, b)
)((_, diaryId) => diaryId);

/** (state, diaryId) */
export const getDiaryMacros = createCachedSelector(
  getDiaryNutrients,
  _pickMacros
)((_, diaryId) => diaryId);

/** (state) */
export const getCurrentDiaryNutrients = createSelector(
  state => {
    const diaryId = getCurrentFoodDiaryId(state);
    return getDiaryNutrients(state, diaryId);
  },
  nutrients => nutrients
);

/** (state) */
export const getCompareDiariesNutrients = createShallowEqualSelector(
  [
    state => {
      const diaries = getCompareDiaryIds(state);
      return diaries.map(diaryId => getDiaryNutrients(state, diaryId));
    },
  ],
  nutrients => nutrients
);
/** (state) */
export const getExportDiariesNutrients = createShallowEqualSelector(
  [
    state => {
      const diaries = getExportDiaryIds(state);
      return diaries.map(diaryId => getDiaryNutrients(state, diaryId));
    },
  ],
  nutrients => nutrients
);

/** (state) */
const _getDiariesWithNutrients = (nutrients, diaries) =>
  diaries.map((diary, i) => ({
    ...diary,
    nutrients: nutrients[i],
  }));
export const getCompareDiariesWithNutrients = createSelector(
  [getCompareDiariesNutrients, getCompareDiaries],
  _getDiariesWithNutrients
);
export const getExportDiariesWithNutrients = createSelector(
  [getExportDiariesNutrients, getExportDiaries],
  _getDiariesWithNutrients
);

/** (state) */
export const getCompareDiariesNutrientsSum = createSelector(
  [getCompareDiariesNutrients, getInitSum],
  _mergeNutrients
);
export const getExportDiariesNutrientsSum = createSelector(
  [getExportDiariesNutrients, getInitSum],
  _mergeNutrients
);

/** (state) */
const _getDiariesNutrientAverage = (nutrients, count) =>
  mapValues(nutrients, v => v / count);
export const getCompareFoodDiariesNutrientAverage = createSelector(
  [getCompareDiariesNutrientsSum, getCompareDiariesCount],
  _getDiariesNutrientAverage
);
export const getExportDiariesNutrientAverage = createSelector(
  [getExportDiariesNutrientsSum, getExportDiariesCount],
  _getDiariesNutrientAverage
);

/** (state) */
export const getCompareFoodDiariesNutrientStandardDev = createSelector(
  [
    getCompareDiariesNutrients,
    getCompareFoodDiariesNutrientAverage,
    getInitSum,
  ],
  (diaries, average, initSum) => {
    var diff = diaries
      .map(s => mapValues(s, (v, k) => Math.pow(average[k] - v, 2)))
      .reduce((sum, x) => mapValues(sum, (v, k) => x[k] + v), initSum);
    return mapValues(diff, v => Math.sqrt(v / diaries.length));
  }
);

/** (state) */
const _getDiariesNutrientMin = (diaries, initSum) => {
  if (diaries.length === 0) return initSum;
  return diaries.reduce((min, x) =>
    mapValues(min, (v, k) => (x[k] < v ? x[k] : v))
  );
};
export const getCompareFoodDiariesNutrientMin = createSelector(
  [getCompareDiariesNutrients, getInitSum],
  _getDiariesNutrientMin
);
export const getExportDiariesNutrientMin = createSelector(
  [getExportDiariesNutrients, getInitSum],
  _getDiariesNutrientMin
);

/** (state) */
const _getDiariesNutrientMax = (diaries, initSum) =>
  diaries.reduce(
    (max, x) => mapValues(max, (v, k) => (x[k] > v ? x[k] : v)),
    initSum
  );
export const getCompareFoodDiariesNutrientMax = createSelector(
  [getCompareDiariesNutrients, getInitSum],
  _getDiariesNutrientMax
);
export const getExportDiariesNutrientMax = createSelector(
  [getExportDiariesNutrients, getInitSum],
  _getDiariesNutrientMax
);

/** (state) */
export const getCompareFoodDiariesNutrientCoefficientVar = createSelector(
  [
    getCompareFoodDiariesNutrientStandardDev,
    getCompareFoodDiariesNutrientAverage,
  ],
  (stdev, average) => {
    return mapValues(stdev, (v, k) => v / average[k]);
  }
);

/** (state, mealId) */
export const getMealSummary = createCachedSelector(getMealMacros, macros => {
  const macro = mapKeys(macros, (_, k) => idToCode[k]);
  const energy = macro[nutrimentsConstant.CALORIES.code];
  delete macro[nutrimentsConstant.CALORIES.code];

  const fiber = macro[nutrimentsConstant.FIBER.code];
  const netCarbs = macro[nutrimentsConstant.CARBS.code] - fiber;
  macro[nutrimentsConstant.CARBS.code] = netCarbs;

  delete macro[nutrimentsConstant.FIBER.code];

  const macronutrients = map(macro, (value, code) => ({
    code: parseInt(code, 10),
    grams: value,
    energy: macroToCalorie({ code, value }),
  }));
  const energyWithoutFiber = macronutrients.reduce(
    (sum, { energy }) => sum + energy,
    0
  );
  const energyFromFiber = energy - energyWithoutFiber;
  const carbs = macronutrients.find(
    m => m.code === nutrimentsConstant.CARBS.code
  );
  carbs.energy += energyFromFiber;

  return {
    macronutrients,
    energy,
  };
})((_, mealId) => mealId);

/** (state, mealIds) */
export const getMealsSummary = createCachedSelector(
  (state, mealIds) => mealIds.map(id => getMealSummary(state, id)),
  summaries => {
    if (!summaries.length) return {};
    return summaries.reduce((sum, current) => {
      return {
        macronutrients: sum.macronutrients.map(v => {
          const m = current.macronutrients.find(x => x.code === v.code);
          return {
            ...v,
            grams: v.grams + m.grams,
            energy: v.energy + m.energy,
          };
        }),
        energy: sum.energy + current.energy,
      };
    });
  }
)((_, mealIds) => mealIds.join('_'), {
  selectorCreator: createShallowEqualSelector,
});

/** (state, diaryId) */
export const getDiaryMealGroupsSummaries = createCachedSelector(
  [
    (state, diaryId) => {
      const mealGroups = getDiaryMealGroups(state, diaryId);
      return (mealGroups || []).map(mealIds => getMealsSummary(state, mealIds));
    },
  ],
  summaries => summaries
)((_, diaryId) => diaryId, {
  selectorCreator: createShallowEqualSelector,
});

/** (state) */
export const getCurrentDiaryMealGroupsSummaries = createSelector(
  state => {
    const diaryId = getCurrentFoodDiaryId(state);
    return getDiaryMealGroupsSummaries(state, diaryId);
  },
  summaries => summaries
);

/** (state, diaryId) */
export const getDiarySummary = createCachedSelector(
  getDiaryMealGroupsSummaries,
  summaries => {
    const grams = {
      [nutrimentsConstant.PROTEIN.code]: 0,
      [nutrimentsConstant.CARBS.code]: 0,
      [nutrimentsConstant.FAT.code]: 0,
      [nutrimentsConstant.ALCOHOL.code]: 0,
    };
    const energies = {
      [nutrimentsConstant.PROTEIN.code]: 0,
      [nutrimentsConstant.CARBS.code]: 0,
      [nutrimentsConstant.FAT.code]: 0,
      [nutrimentsConstant.ALCOHOL.code]: 0,
      energy: 0,
    };
    let totalEnergy = 0;
    summaries.forEach(summary => {
      totalEnergy += summary.energy;
      summary.macronutrients.forEach(nutrient => {
        grams[nutrient.code] += nutrient.grams;
        energies[nutrient.code] += nutrient.energy;
      });
    });
    const macronutrients = map(grams, (gramsValue, code) => ({
      code: parseInt(code, 10),
      grams: gramsValue,
      energy: energies[code],
    }));
    return {
      macronutrients,
      energy: totalEnergy,
    };
  }
)((_, diaryId) => diaryId);

export const makeGetMealsStartTime = mealsId => {
  return createSelector(getById, byId => {
    const meal = byId[first(mealsId)];
    return get(meal, 'consume_time');
  });
};

/** (state) */
const _getDiariesSummary = average => {
  const nc = nutrimentsConstant;
  const grams = {
    [nc.PROTEIN.code]: average[nc.PROTEIN.id],
    [nc.CARBS.code]: average[nc.CARBS.id],
    [nc.FAT.code]: average[nc.FAT.id],
    [nc.ALCOHOL.code]: average[nc.ALCOHOL.id],
  };
  const energy = average[nc.CALORIES.id];
  const energies = {
    [nc.PROTEIN.code]: macroToCalorie({
      value: average[nc.PROTEIN.id],
      code: nc.PROTEIN.code,
    }),
    [nc.FAT.code]: macroToCalorie({
      value: average[nc.FAT.id],
      code: nc.FAT.code,
    }),
    [nc.ALCOHOL.code]: macroToCalorie({
      value: average[nc.ALCOHOL.id] || 0,
      code: nc.ALCOHOL.code,
    }),
  };

  energies[nc.CARBS.code] =
    energy -
    energies[nc.PROTEIN.code] -
    energies[nc.FAT.code] -
    energies[nc.ALCOHOL.code];

  const macronutrients = map(grams, (gramsValue, code) => ({
    code: parseInt(code, 10),
    grams: gramsValue,
    energy: energies[code],
  }));
  return {
    macronutrients,
    energy,
  };
};
export const getCompareDiariesSummary = createSelector(
  getCompareFoodDiariesNutrientAverage,
  _getDiariesSummary
);
export const getExportDiariesSummary = createSelector(
  getExportDiariesNutrientAverage,
  _getDiariesSummary
);

/** (state) */
export const getCurrentDiarySummary = createSelector(
  state => {
    const diaryId = getCurrentFoodDiaryId(state);
    return getDiarySummary(state, diaryId);
  },
  summary => summary
);

/** (state, diaryId) */
export const getDiaryHourlyMacros = createCachedSelector(
  [
    getDiaryMealGroupsMacros,
    getDiaryMealGroupsTimes,
    getCurrentClientNutrientsWithMacros,
  ],
  (macros, times, nutrients) =>
    macros.reduce(
      (sum, current, i) => _mergeMacroInHours(sum, times[i], current),
      _getInitHours(nutrients)
    )
)((_, diaryId) => diaryId);

/** (state) */
export const getCurrentDiaryHourlyMacros = createSelector(
  state => {
    const diaryId = getCurrentFoodDiaryId(state);
    return getDiaryHourlyMacros(state, diaryId);
  },
  macros => macros
);

/** (state, diaryId) */
export const getDiaryMaxEnergy = createCachedSelector(
  getDiaryMealGroupsSummaries,
  summaries =>
    summaries.reduce((max, v) => (v.energy > max ? v.energy : max), 0)
)((_, diaryId) => diaryId);

/** (state) */
export const getCurrentDiaryMaxEnergy = createSelector(
  state => {
    const diaryId = getCurrentFoodDiaryId(state);
    return getDiaryMaxEnergy(state, diaryId);
  },
  max => max
);

const __getOrderedWithSums = diaries => {
  return sortBy(diaries, 'diary_date');
};
const _getOrderedCompareFoodDiariesWithSums = createSelector(
  getCompareDiariesWithNutrients,
  __getOrderedWithSums
);
const _getOrderedExportDiariesWithSums = createSelector(
  getExportDiariesWithNutrients,
  __getOrderedWithSums
);

const __getDaysNutrients = (diaries, locale) => {
  const dateOptions = { month: 'short', day: 'numeric' };
  return diaries.map(diary => {
    const date = dateStringToDate(diary.diary_date);
    const name = date.toLocaleDateString(locale, dateOptions);
    return {
      id: name,
      name,
      ...pick(diary.nutrients, [
        'carbs',
        'fat',
        'protein',
        nutrimentsConstant.FIBER.id,
        nutrimentsConstant.ALCOHOL.id,
        'calories',
      ]),
    };
  });
};
export const getCompareDaysNutrients = createSelector(
  [_getOrderedCompareFoodDiariesWithSums, getFullLocale],
  __getDaysNutrients
);
export const getExportDaysNutrients = createSelector(
  [_getOrderedExportDiariesWithSums, getFullLocale],
  __getDaysNutrients
);
export const getExportDaysNutrientsWithAverage = createSelector(
  [getExportDaysNutrients, getExportDiariesSummary],
  (nutrients, summary) => {
    const nc = nutrimentsConstant;
    const prot = summary.macronutrients.find(x => x.code === nc.PROTEIN.code);
    const fat = summary.macronutrients.find(x => x.code === nc.FAT.code);
    const carb = summary.macronutrients.find(x => x.code === nc.CARBS.code);
    const alco = summary.macronutrients.find(x => x.code === nc.ALCOHOL.code);
    return [
      {
        id: 'average',
        [nc.PROTEIN.id]: prot.grams,
        [nc.FAT.id]: fat.grams,
        [nc.CARBS.id]: carb.grams,
        [nc.ALCOHOL.id]: alco.grams,
        [nc.CALORIES.id]: summary.energy,
      },
      ...nutrients,
    ];
  }
);

export const getExportDaysPercentages = createSelector(
  [getExportDaysNutrients],
  nutrients =>
    nutrients.map(nutrient => {
      const nc = nutrimentsConstant;
      const energy = nutrient[nc.CALORIES.id];

      const protCal = macroToCalorie({
        value: nutrient[nc.PROTEIN.id],
        code: nc.PROTEIN.code,
      });
      const fatCal = macroToCalorie({
        value: nutrient[nc.FAT.id],
        code: nc.FAT.code,
      });
      const alcoCal = macroToCalorie({
        value: nutrient[nc.ALCOHOL.id],
        code: nc.ALCOHOL.code,
      });
      const carbCal = energy - protCal - fatCal - alcoCal;

      return {
        ...nutrient,
        [nc.PROTEIN.id]: (protCal / energy) * 100,
        [nc.FAT.id]: (fatCal / energy) * 100,
        [nc.CARBS.id]: (carbCal / energy) * 100,
        [nc.ALCOHOL.id]: (alcoCal / energy) * 100,
      };
    })
);
export const getExportDaysPercentagesWithAverage = createSelector(
  [getExportDaysPercentages, getExportDiariesSummary],
  (nutrients, summary) => {
    const nc = nutrimentsConstant;
    const prot = summary.macronutrients.find(x => x.code === nc.PROTEIN.code);
    const fat = summary.macronutrients.find(x => x.code === nc.FAT.code);
    const carb = summary.macronutrients.find(x => x.code === nc.CARBS.code);
    const alco = summary.macronutrients.find(x => x.code === nc.ALCOHOL.code);
    const total = prot.energy + fat.energy + carb.energy + alco.energy;
    return [
      {
        id: 'average',
        [nc.PROTEIN.id]: (prot.energy / total) * 100,
        [nc.FAT.id]: (fat.energy / total) * 100,
        [nc.CARBS.id]: (carb.energy / total) * 100,
        [nc.ALCOHOL.id]: (alco.energy / total) * 100,
        [nc.CALORIES.id]: summary.energy,
      },
      ...nutrients,
    ];
  }
);

const _buildLabelToEnergy = macros => {
  const obj = {};
  (macros || []).forEach(macro => {
    obj[macro.name] = macro[nutrimentsConstant.CALORIES.id];
  });
  return obj;
};
/** (state) */
export const getCurrentDiaryLabelToEnergy = createSelector(
  getCurrentDiaryHourlyMacros,
  _buildLabelToEnergy
);
/** (state) */
export const getCompareLabelToEnergy = createSelector(
  getCompareDaysNutrients,
  _buildLabelToEnergy
);

/** (state) */
export const getCurrentDiaryEnergy = createSelector(getCurrentDiarySummary, x =>
  get(x, 'energy', 0)
);

export const getClientDriPath = createSelector(
  [
    getCurrentClientAge,
    getCurrentClientGender,
    getCurrentClientLactation,
    getCurrentClientPregnancy,
  ],
  (age, sex, lactation, pregnancy) => {
    if (age === undefined || !sex) return null;
    // We could use any nutrients that has values for each group.
    const dri = DRI_VALUES[nutrimentsConstant.VITAMIN_A.id];

    // 1 - Check if infant or children
    if (age < 9) {
      const infants = get(dri, INFANTS, []);
      for (let i = 0; i < infants.length; ++i) {
        if (age < infants[i].max) {
          return [INFANTS, i];
        }
      }
      const children = get(dri, CHILDREN, []);
      for (let i = 0; i < children.length; ++i) {
        if (age < children[i].max) {
          return [CHILDREN, i];
        }
      }
    }

    // 2 - Check if man
    if (sex === CLIENT_GENDERS.MALE) {
      // 2.1 - Check age group
      const males = get(dri, MALES, []);
      for (let i = 0; i < males.length; ++i) {
        if (age < males[i].max) {
          return [MALES, i];
        }
      }
      return [MALES, males.length - 1];
    }

    // 3 - Check if pregnant
    if (lactation) {
      const lactations = get(dri, LACTATION, []);
      for (let i = 0; i < lactations.length; ++i) {
        if (age < lactations[i].max) {
          return [LACTATION, i];
        }
      }
    }
    // 4 - Check if pregnant
    if (pregnancy) {
      const pregnancies = get(dri, PREGNANCY, []);
      for (let i = 0; i < pregnancies.length; ++i) {
        if (age < pregnancies[i].max) {
          return [PREGNANCY, i];
        }
      }
    }

    // 5 - Check if woman
    if (sex === CLIENT_GENDERS.FEMALE) {
      // 5.1 - Check age group
      const females = get(dri, FEMALES, []);
      for (let i = 0; i < females.length; ++i) {
        if (age < females[i].max) {
          return [FEMALES, i];
        }
      }
      return [FEMALES, females.length - 1];
    }

    // No path - Should not be there
    return null;
  }
);

const PREGNANCY_MAPPING_FR = ['< 18 ans', '19-30 ans', '31-50 ans'];
const PREGNANCY_MAPPING_EN = ['< 18 y', '19-30 y', '31-50 y'];
const MF_MAPPING_FR = [
  '9-13 ans',
  '14-18 ans',
  '19-30 ans',
  '31-50 ans',
  '51-70 ans',
  '>70 ans',
];
const MF_MAPPING_EN = [
  '9-13 y',
  '14-18 y',
  '19-30 y',
  '31-50 y',
  '51-70 y',
  '>70 y',
];
const INFANT_MAPPING_FR = ['0-6 mois', '7-12 mois'];
const INFANT_MAPPING_EN = ['0-6 mo', '7-12 mo'];
const CHILDREN_MAPPING_FR = ['1-3 ans', '4-8 ans'];
const CHILDREN_MAPPING_EN = ['1-3 y', '4-8 y'];
export const getCurrentClientDRIGroupString = createSelector(
  [getClientDriPath, getLocale],
  (path, locale) => {
    if (!path) return null;

    const [sex, age] = path;
    const isFr = locale.toLowerCase().startsWith('fr');
    let part1 = '';
    let part2 = '';
    switch (sex) {
      case LACTATION:
        part1 = 'Lactation';
        part2 = isFr ? PREGNANCY_MAPPING_FR[age] : PREGNANCY_MAPPING_EN[age];
        break;
      case PREGNANCY:
        part1 = isFr ? 'Grossesse' : 'Pregnancy';
        part2 = isFr ? PREGNANCY_MAPPING_FR[age] : PREGNANCY_MAPPING_EN[age];
        break;
      case INFANTS:
        part1 = isFr ? 'Nourrisson' : 'Infant';
        part2 = isFr ? INFANT_MAPPING_FR[age] : INFANT_MAPPING_EN[age];
        break;
      case CHILDREN:
        part1 = isFr ? 'Enfant' : 'Child';
        part2 = isFr ? CHILDREN_MAPPING_FR[age] : CHILDREN_MAPPING_EN[age];
        break;
      case FEMALES:
        part1 = isFr ? 'Femme' : 'Woman';
        part2 = isFr ? MF_MAPPING_FR[age] : MF_MAPPING_EN[age];
        break;
      case MALES:
        part1 = isFr ? 'Homme' : 'Man';
        part2 = isFr ? MF_MAPPING_FR[age] : MF_MAPPING_EN[age];
        break;
      default:
        // Should not be here.
        break;
    }
    return `${part1} | ${part2}`;
  }
);

export const getCurrentClientDris = createSelector(
  [getClientDriPath, getCurrentClientNutrients],
  (path, nutrients) => {
    if (!path) return undefined;
    const dris = {};
    nutrients.forEach(({ id }) => {
      const dri = DRI_VALUES[id];
      if (!dri) {
        return;
      }
      const d = get(dri, path);
      if (!d) return;

      dris[id] = d.profile;
    });
    return dris;
  }
);

export const getCurrentClientRdas = createSelector(getCurrentClientDris, dris =>
  mapValues(dris, ({ rda }) => rda)
);
export const getCurrentClientAi = createSelector(getCurrentClientDris, dris =>
  mapValues(dris, ({ ai }) => (ai ? `${ai}*` : ai))
);
export const getCurrentClientRdaOrAi = createSelector(
  [getCurrentClientRdas, getCurrentClientAi],
  (rdas, ais) => mapValues(rdas, (val, k) => (val ? val : ais[k]))
);
export const getCurrentClientUl = createSelector(getCurrentClientDris, dris =>
  mapValues(dris, ({ ul }) => ul)
);

/** (state, mealId) */
export const getMealPhoto = createSelector(getMeal, meal => get(meal, 'photo'));

/** (state, mealId) */
export const getMealTime = createSelector(getMeal, meal =>
  trimSeconds(get(meal, 'consume_time'))
);

/** (state, mealId) */
export const getMealEmoji = createSelector(getMeal, meal => get(meal, 'emoji'));

export default {
  getCurrentId,
  _getByIdWithTrimmedSecond,
};
