import NewClientModal from './NewClientModal';
import { connect } from 'react-redux';
import { clientsOperations } from 'redux/modules/clients';
import {
  closeModal,
  getModalOpen,
  MODAL_TYPES,
  openModal,
} from 'redux/modules/modals/modals';
import {
  getInviteStatus,
  getInviteError,
  getRenewStatus,
} from 'redux/modules/clients/clients.selectors';
import { renewInvite } from 'redux/modules/clients/clients.actions';
import { getLocale } from 'redux/modules/account/account.selectors';

const mapStateToProps = state => ({
  isOpen: getModalOpen(state, MODAL_TYPES.NEW_CLIENT),
  inviteStatus: getInviteStatus(state),
  renewStatus: getRenewStatus(state),
  inviteError: getInviteError(state),
  defaultLocale: getLocale(state),
});

const mapDispatchToProps = dispatch => ({
  onExit: () => dispatch(closeModal(MODAL_TYPES.NEW_CLIENT)),
  onAddClientClick: (email, locale) =>
    dispatch(clientsOperations.inviteClient(email, locale)),
  renewInvite: email => dispatch(renewInvite(email)),
  onOpenPreview: () =>
    dispatch(openModal(MODAL_TYPES.NEW_CLIENT_PREVIEW, {}, true)),
});

const NewClientModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewClientModal);

export default NewClientModalContainer;
