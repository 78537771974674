import React from 'react';
import styled from 'styled-components';
import { findCorrespondingNutriements } from 'constants/nutriments';
import ListEntry from '../../../components/ListEntry';
import NutrientTd from './NutrientTd';
import Color from 'color';

const bg = Color('black')
  .fade(0.97)
  .toString();

const StyledTr = styled.tr`
  min-width: 300px;
  background-color: ${props => (props.even ? 'white' : bg)};
`;

const _getNutrientValues = (entry, nutriment) => {
  const initialValues = { [nutriment.id]: nutriment.value };
  return nutriment.children
    ? findCorrespondingNutriements(nutriment.children, entry.nutrients).reduce(
        (values, n) => ({ ...values, [n.id]: n.value }),
        initialValues
      )
    : initialValues;
};

const FoodEntry = ({ even, nutrimentsToShow, entry, onClick, onDelete }) => {
  return (
    <StyledTr even={even}>
      <ListEntry entry={entry} onEdit={onClick} onDelete={onDelete} />
      {findCorrespondingNutriements(nutrimentsToShow, entry.nutrients).map(
        (nutriment, i) => (
          <NutrientTd
            className="mdc-typography--body1"
            key={i}
            nutrient={nutriment}
            values={_getNutrientValues(entry, nutriment)}
          />
        )
      )}
    </StyledTr>
  );
};

export default FoodEntry;
