import React from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import H2 from 'components/H2';
import { TextField } from 'components/forms/Textfield';
import { Button } from 'components/material/Button';
import RightAlign from 'components/RightAlign';
import { useState } from 'react';
import { useQuery } from 'utils/hooks';
import Spacer from 'components/Spacer';

const messages = defineMessages({
  title: {
    id: 'completePasswordReset.title',
    defaultMessage: 'Reset your password.',
  },
  passwordPlaceholder: {
    id: 'global.password',
    defaultMessage: 'Password',
  },
  sendBtn: {
    id: 'completePasswordReset.sendBtn',
    defaultMessage: 'Reset',
  },
  paragraph: {
    id: 'completePasswordReset.paragraph',
    defaultMessage: 'Type your new password',
  },
  expiredToken: {
    id: 'completePasswordReset.expiredToken',
    defaultMessage: 'Oups. It looks like your request has expired.',
  },
  sentTitle: {
    id: 'completePasswordReset.sentTitle',
    defaultMessage: 'Great! Your password has been reset.',
  },
  linkToLoginBtn: {
    id: 'completePasswordReset.linkToLoginBtn',
    defaultMessage: 'Login',
  },
  expiredTokenMessage: {
    id: 'completePasswordReset.expiredTokenMessage',
    defaultMessage: 'Please send another request.',
  },
  linkToResetBtn: {
    id: 'completePasswordReset.linkToResetBtn',
    defaultMessage: 'New Request',
  },
});

const SpacedInput = styled(TextField)`
  margin: 14px 0;
  width: 100%;
`;

const ErrorH2 = styled(H2)`
  color: ${props => props.theme.palette.error};
`;

function CompletePasswordReset({ sent, error, onSubmit }) {
  const [password, setPassword] = useState('');
  const query = useQuery();
  const token = query.get('token');
  const intl = useIntl();

  const _submit = () => {
    onSubmit(token, password);
  };

  const renderError = () => (
    <div>
      <ErrorH2>
        <FormattedMessage {...messages.expiredToken} />
      </ErrorH2>
      <p>
        <FormattedMessage {...messages.expiredTokenMessage} />
      </p>
      <RightAlign>
        <Link to="/begin-password-reset">
          <Button unelevated>
            <FormattedMessage {...messages.linkToResetBtn} />
          </Button>
        </Link>
      </RightAlign>
    </div>
  );

  const renderSent = () => (
    <div>
      <H2>
        <FormattedMessage {...messages.sentTitle} />
      </H2>
      <Spacer spacing="lg" />
      <RightAlign>
        <Link to="/">
          <Button unelevated>
            <FormattedMessage {...messages.linkToLoginBtn} />
          </Button>
        </Link>
      </RightAlign>
    </div>
  );
  const renderForm = () => (
    <form
      onKeyPress={e => {
        if (e.key === 'Enter') {
          _submit();
        }
      }}
      onSubmit={e => {
        e.preventDefault();
        _submit();
      }}
      autoComplete="on"
    >
      <H2>
        <FormattedMessage {...messages.title} />
      </H2>
      <div>
        <SpacedInput
          outlined
          label={intl.formatMessage(messages.passwordPlaceholder)}
          type="password"
          name="password"
          autoComplete="new-password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </div>

      <RightAlign>
        <Button unelevated type="submit" disabled={!password}>
          <FormattedMessage {...messages.sendBtn} />
        </Button>
      </RightAlign>
    </form>
  );

  if (error) return renderError();
  if (sent) return renderSent();
  return renderForm();
}
export default CompletePasswordReset;
