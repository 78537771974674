import React from 'react';
import { connect } from 'react-redux';
import { getLocale } from 'redux/modules/account/account.selectors';

const FormattedDate = props => {
  const locale = props.locale;
  var options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formatted = props.date.toLocaleDateString(locale, options);
  return <span>{formatted}</span>;
};

const mapStateToProps = state => ({
  locale: getLocale(state),
});
export default connect(mapStateToProps)(FormattedDate);
