import React from 'react';
import { withTheme } from 'styled-components';
import {
  CLIENT_STATUS,
  CLIENT_ACTIONS,
} from '../../../../constants/clientStatus';
import { defineMessages, injectIntl } from 'react-intl';
import { notify } from 'react-notify-toast';
import TableActions from 'components/TableActions';

const messages = defineMessages({
  export: { id: 'client.export' },
  archive: { id: 'client.archive' },
  remove: { id: 'client.remove' },
  reInvite: { id: 'client.reInvite' },
  unarchive: { id: 'client.unarchive' },
  noActions: { id: 'client.noActions' },

  clientSelected: { id: 'client.selected' },
  clientsSelected: { id: 'client.selecteds' },

  archiveSuccess: { id: 'clients.archiveSuccess' },
  archiveSuccessP: { id: 'clients.archiveSuccessP' },
  removeSuccess: { id: 'clients.removeSuccess' },
  removeSuccessP: { id: 'clients.removeSuccesP' },
  unarchiveSuccess: { id: 'clients.unarchiveSuccess' },
  unarchiveSuccessP: { id: 'clients.unarchiveSuccessP' },
  reinviteSuccess: { id: 'clients.reinviteSuccess' },
  reinviteSuccessP: { id: 'clients.reinviteSuccessP' },

  actionError: { id: 'clients.actionError' },
});

function ClientActions({
  selectedIds,
  updateStatus,
  unselectAll,
  removeInvite,
  renewInvite,
  byId,
  intl,
  openExportModal,
  actions,
  theme,
}) {
  const _notifySuccess = (action, count) => {
    const ca = CLIENT_ACTIONS;
    let text = '';
    // eslint-disable-next-line
    switch (action) {
      case ca.REMOVE:
        text =
          count > 1
            ? intl.formatMessage(messages.removeSuccessP, { count })
            : intl.formatMessage(messages.removeSuccess);
        break;
      case ca.ARCHIVE:
        text =
          count > 1
            ? intl.formatMessage(messages.archiveSuccessP, { count })
            : intl.formatMessage(messages.archiveSuccess);
        break;
      case ca.UNARCHIVE:
        text =
          count > 1
            ? intl.formatMessage(messages.unarchiveSuccessP, { count })
            : intl.formatMessage(messages.unarchiveSuccess);
        break;
      case ca.RE_INVITE:
        text =
          count > 1
            ? intl.formatMessage(messages.reinviteSuccessP, { count })
            : intl.formatMessage(messages.reinviteSuccess);
        break;
    }
    notify.show(text, 'custom', 1400, {
      background: theme.palette.success,
      text: 'white',
    });
  };

  const _onAction = action => {
    const ca = CLIENT_ACTIONS;
    const count = selectedIds.length;
    // eslint-disable-next-line
    switch (action) {
      case ca.REMOVE:
        return Promise.all(
          selectedIds.map(id => removeInvite(byId[id].invite_email, id))
        )
          .then(() => _notifySuccess(ca.REMOVE, count))
          .then(unselectAll);
      case ca.RE_INVITE:
        return Promise.all(
          selectedIds.map(id => renewInvite(byId[id].invite_email, id))
        )
          .then(() => _notifySuccess(ca.RE_INVITE, count))
          .then(unselectAll);
      case ca.ARCHIVE:
        return Promise.all(
          selectedIds.map(clientId =>
            updateStatus(clientId, CLIENT_STATUS.ARCHIVED)
          )
        )
          .then(() => _notifySuccess(ca.ARCHIVE, count))
          .then(unselectAll);
      case ca.UNARCHIVE:
        return Promise.all(
          selectedIds.map(id => {
            const client = byId[id];
            const nextStatus = client.isFictive
              ? CLIENT_STATUS.FICTIVE
              : client.revoke_date
              ? CLIENT_STATUS.REVOKED
              : CLIENT_STATUS.ACTIVE;
            return updateStatus(id, nextStatus);
          })
        )
          .then(() => _notifySuccess(ca.UNARCHIVE, count))
          .then(unselectAll);
      case ca.EXPORT:
        return openExportModal();
    }
  };

  const tableActions = actions.map(a => ({
    id: a,
    ...actionProps(a),
  }));
  return (
    <TableActions
      actions={tableActions}
      label="client"
      onAction={_onAction}
      selectCount={selectedIds.length}
    />
  );
}

export default injectIntl(withTheme(ClientActions));

function actionProps(action) {
  let icon;
  let message;
  let color = 'midPrimary';
  switch (action) {
    case CLIENT_ACTIONS.REMOVE:
      icon = 'trash-alt';
      message = messages.remove;
      color = 'error';
      break;
    case CLIENT_ACTIONS.RE_INVITE:
      icon = 'paper-plane';
      message = messages.reInvite;
      break;
    case CLIENT_ACTIONS.ARCHIVE:
      icon = 'box';
      message = messages.archive;
      break;
    case CLIENT_ACTIONS.UNARCHIVE:
      icon = 'box-open';
      message = messages.unarchive;
      break;
    case CLIENT_ACTIONS.EXPORT:
      icon = 'file-csv';
      message = messages.export;
      break;
    default:
      break;
  }
  return {
    icon,
    message,
    color,
  };
}
