import types from './account.types';
import accountApi from '../../../api/account';
import { put } from '../../../utils/storage';

export function switchLocale(locale) {
  return {
    type: types.SWITCH_LOCALE,
    locale,
  };
}

export function openSettings() {
  return { type: types.OPEN_SETTINGS };
}

export function closeSettings() {
  return { type: types.CLOSE_SETTINGS };
}

export function updateLocaleSuccess() {
  return { type: types.UPDATE_LOCALE_SUCCESS };
}

export function updateLocaleFailure() {
  return { type: types.UPDATE_LOCALE_FAILURE };
}

export const updateToolboxSuccess = info => ({
  type: types.UPDATE_TOOLBOX_SUCCESS,
  info,
});

export const updateToolboxFailure = error => ({
  type: types.UPDATE_TOOLBOX_FAILURE,
  error,
});

export const updateName = name => ({
  type: types.UPDATE_NAME,
  name,
});

export const updateNameSuccess = info => ({
  type: types.UPDATE_NAME_SUCCESS,
  info,
});

export const updateNameFailure = err => ({
  type: types.UPDATE_NAME_FAILURE,
  err,
});

export const updateToken = () => ({
  types: [
    types.UPDATE_TOKEN_REQUEST,
    types.UPDATE_TOKEN_SUCCESS,
    types.UPDATE_TOKEN_FAILURE,
  ],
  callAPI: () =>
    accountApi.updateToken().then(({ token }) => {
      put('token', token);
    }),
});

export const updateOnboard = onboard => ({
  types: [
    types.UPDATE_ONBOARD_REQUEST,
    types.UPDATE_ONBOARD_SUCCESS,
    types.UPDATE_ONBOARD_FAILURE,
  ],
  callAPI: () => accountApi.updateOnboard(onboard),
  payload: { onboard },
});

export const updateTimeFormat = format => ({
  types: [
    types.UPDATE_TIME_FORMAT_REQUEST,
    types.UPDATE_TIME_FORMAT_SUCCESS,
    types.UPDATE_TIME_FORMAT_FAILURE,
  ],
  callAPI: () => accountApi.updateTimeFormat(format),
  payload: { format },
});

export default {
  switchLocale,
  openSettings,
  closeSettings,
  updateLocaleSuccess,
  updateLocaleFailure,

  updateToolboxSuccess,
  updateToolboxFailure,

  updateName,
  updateNameSuccess,
  updateNameFailure,
};
