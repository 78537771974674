import React from 'react';
import MealImage from './MealImage';
import styled from 'styled-components';

const SEPARATOR_HEIGHT = 3;
const PAIR_HEIGHT = 130;
const SINGLE_HEIGHT = 200;
const FULL_HEIGHT = 260;

const Wrapper = styled.div`
  width: ${FULL_HEIGHT}px;
  min-height: ${FULL_HEIGHT}px;
`;

export const lengthToHeight = length => {
  if (length === 1) return FULL_HEIGHT;
  if (length === 2) return 2 * SINGLE_HEIGHT + SEPARATOR_HEIGHT;

  const singleCount = length % 2 === 1 ? 1 : 0;
  const pairCount = Math.floor(length / 2);
  const separatorCount = singleCount + pairCount - 1;
  return (
    singleCount * SINGLE_HEIGHT +
    pairCount * PAIR_HEIGHT +
    separatorCount * SEPARATOR_HEIGHT
  );
};

class PhotoMosaic extends React.Component {
  renderTwo(first, second, isLast) {
    return (
      <div
        key={first + second}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          paddingBottom: isLast ? 0 : SEPARATOR_HEIGHT,
        }}
      >
        <div style={{ flex: 50 }}>
          <MealImage id={first} height={PAIR_HEIGHT} />
        </div>
        <div style={{ flex: 50, marginLeft: SEPARATOR_HEIGHT }}>
          <MealImage id={second} height={PAIR_HEIGHT} />
        </div>
      </div>
    );
  }
  renderOne(mealId, isLast, isAlone) {
    return (
      <div
        key={mealId}
        style={{
          flex: 1,
          flexDirection: 'row',
          paddingBottom: isLast ? 0 : SEPARATOR_HEIGHT,
        }}
      >
        <MealImage id={mealId} height={isAlone ? 260 : SINGLE_HEIGHT} />
      </div>
    );
  }

  render() {
    const { meals } = this.props;
    const isOdd = meals.length % 2 === 1;

    const pairs = [];
    for (let i = isOdd ? 1 : 0; i < meals.length; i += 2) {
      pairs.push({
        first: meals[i],
        second: meals[i + 1],
      });
    }
    if (meals.length === 1) {
      return <Wrapper>{this.renderOne(meals[0], true, true)}</Wrapper>;
    }
    if (meals.length === 2) {
      return (
        <Wrapper>
          {this.renderOne(meals[0], false)}
          {this.renderOne(meals[1], true)}
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        {isOdd ? this.renderOne(meals[0], !pairs.length) : null}
        {pairs.map(({ first, second }, i) =>
          this.renderTwo(first, second, i === pairs.length - 1)
        )}
      </Wrapper>
    );
  }
}

export default PhotoMosaic;
