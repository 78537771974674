import React from 'react';
import styled, { withTheme } from 'styled-components';
import FlipMove from 'react-flip-move';
import { FormattedMessage } from 'react-intl';
import Color from 'color';
import Typography from './material/Typography';

const StyledFlipBox = styled(FlipMove)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const chipColor = Color('black').fade(0.94);
const Chip = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  background-color: ${chipColor.toString()};
  border-radius: 25px;
  margin: 5px;
  padding: 5px 14px;
  white-space: nowrap;
  border: ${props =>
    props.borderColor
      ? `2px solid ${props.borderColor}`
      : `1px solid ${props.theme.palette.text.secondary}`};

  &:hover {
    ${x => (!x.onClick ? '' : 'cursor: pointer')};
    background-color: ${props =>
      props.borderColor
        ? Color('black')
            .fade(0.96)
            .toString()
        : chipColor.opaquer(0.7).toString()};
    ${x => (!x.onClick ? 'background-color: ' + chipColor.toString() : '')};
  }
`;

const Element = ({ element, onClick, chipColorMapper, theme }) => {
  const onChipClick = () => onClick(element);

  const borderColor = chipColorMapper[element.id]
    ? theme.palette[chipColorMapper[element.id]]
    : chipColorMapper[element.parent]
    ? theme.palette[chipColorMapper[element.parent]]
    : undefined;

  return (
    <Typography use="body2" rank="primary" tag="div">
      <Chip
        onClick={onClick ? onChipClick : undefined}
        borderColor={borderColor}
      >
        <FormattedMessage {...element.label} />
      </Chip>
    </Typography>
  );
};

class NutrientTags extends React.PureComponent {
  render() {
    return (
      <StyledFlipBox duration={750} easing="ease-out">
        {this.props.elements.map(element => (
          <div key={element.id}>
            <Element
              element={element}
              onClick={this.props.onElementClick}
              chipColorMapper={this.props.chipColorMapper}
              theme={this.props.theme}
            />
          </div>
        ))}
      </StyledFlipBox>
    );
  }
}

export default withTheme(NutrientTags);
