import React, { useState } from 'react';
import Dropdown from 'components/Dropdown';
import styled from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import Color from 'color';
import { useHistory } from 'react-router-dom';
import Avatar from 'components/Avatar';
import Typography from 'components/material/Typography';

const messages = defineMessages({
  settings: {
    id: 'userBadge.settingsLink',
    defaultMessage: 'Settings',
  },
  logout: {
    id: 'userBadge.logoutLink',
    defaultMessage: 'Logout',
  },
});

const WIDTH_MENU = 200;
const AVATAR_WIDTH = 30;

const MenuWrapper = styled.div`
  width: ${WIDTH_MENU}px;
  background-color: white;
  color: ${props => props.theme.palette.text.primary};
`;

const BORDER_TOP_COLOR = Color('black')
  .fade(0.95)
  .toString();
const Section = styled.div`
  border-top: 1px solid ${BORDER_TOP_COLOR};
  padding: 12px 0;
`;

const ROW_BG = Color('black')
  .fade(0.97)
  .toString();
const Row = styled.div`
  padding: 9px 15px;
  color: ${props => props.theme.palette.text.primary};
  &:hover {
    cursor: pointer;
    background-color: ${ROW_BG};
  }
`;

const AvatarWrapper = styled.div`
  margin-right: 20px;
`;

const FullName = styled.div`
  padding: 18px 15px 20px 15px;

  &:hover {
    cursor: default;
  }
`;
const AvatarOtherWrapper = styled.div`
  padding: 15px 15px 0 15px;
`;

export default function ProfileAvatar({ onLogout, fullName }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const history = useHistory();

  const _toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const _toggleThenAction = action => () => {
    _toggleDropdown();
    action();
  };

  return (
    <AvatarWrapper>
      <Avatar onClick={_toggleDropdown} size={AVATAR_WIDTH} />

      <Dropdown
        isOpen={dropdownOpen}
        onOutsideClick={_toggleDropdown}
        bottom="8px"
        right={`${-(WIDTH_MENU + AVATAR_WIDTH)}px`}
      >
        <MenuWrapper className="mdc-typography--body1">
          <AvatarOtherWrapper>
            <Avatar size={AVATAR_WIDTH + 5} />
          </AvatarOtherWrapper>
          <FullName>
            <Typography use="body2" rank="primary">
              {fullName}
            </Typography>
          </FullName>
          <Section>
            <Row onClick={_toggleThenAction(() => history.push('/account'))}>
              <Typography use="body2" rank="primary">
                <FormattedMessage {...messages.settings} />
              </Typography>
            </Row>
          </Section>
          <Section>
            <Row
              onClick={_toggleThenAction(() => {
                onLogout();
                history.replace('/login');
              })}
            >
              <Typography use="body2" rank="primary">
                <FormattedMessage {...messages.logout} />
              </Typography>
            </Row>
          </Section>
        </MenuWrapper>
      </Dropdown>
    </AvatarWrapper>
  );
}
