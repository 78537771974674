import { createSelectorCreator, defaultMemoize } from 'reselect';

// Custom equality check function
const shallowArrayEqualityCheck = (a, b) => {
  if (!Array.isArray(a)) return a === b;

  if (a.length !== b.length) {
    return false;
  }

  const length = a.length;
  for (let i = 0; i < length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
};

export const createShallowEqualSelector = createSelectorCreator(
  defaultMemoize,
  shallowArrayEqualityCheck
);
