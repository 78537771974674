import { connect } from 'react-redux';
import CompareModeSwitch from './CompareModeSwitch';
import {
  getIsInCompareMode,
  getCompareDiaryIds,
} from 'redux/modules/UI/clientView';
import {
  setCompareMode,
  selectCompareDay,
} from 'redux/modules/UI/clientView/clientView.actions';
import { getCurrentFoodDiaryId } from 'redux/modules/foodDiaries/foodDiaries.selectors';
import { getCanRunCompare } from 'redux/modules/account/onboard.selectors';
import { getOnboardRun } from 'redux/modules/account/account.selectors';
import { ONBOARD_IDS } from '../../../../../constants';
import { setOnboardRun } from 'redux/modules/account/account.operations';

const mapStateToProps = state => ({
  open: getIsInCompareMode(state),
  diaryId: getCurrentFoodDiaryId(state),
  compareIds: getCompareDiaryIds(state),

  onboarded: getOnboardRun(ONBOARD_IDS.COMPARE_MODE),
  canOnboard: getCanRunCompare(state),
});

const mapDispatchToProps = dispatch => ({
  setMode: isInCompareMode => dispatch(setCompareMode(isInCompareMode)),
  selectDay: diaryId => dispatch(selectCompareDay(diaryId)),

  setOnboarded: () => dispatch(setOnboardRun(ONBOARD_IDS.COMPARE_MODE)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompareModeSwitch);
