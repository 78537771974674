import { connect } from 'react-redux';
import General from './General';
import { accountSelectors } from 'redux/modules/account';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';
import { getTimeFormat } from 'redux/modules/account/account.selectors';
import { updateTimeFormat } from 'redux/modules/account/account.actions';

const mapStateToProps = state => ({
  fullName: accountSelectors.getFullName(state),
  locale: accountSelectors.getLocale(state),
  timeFormat: getTimeFormat(state),
});

const mapDispatchToProps = dispatch => ({
  editName: () => dispatch(openModal(MODAL_TYPES.UPDATE_NAME)),
  editLocale: () => dispatch(openModal(MODAL_TYPES.UPDATE_LOCALE)),
  pickTimeFormat: format => dispatch(updateTimeFormat(format)),
});

const GeneralContainer = connect(mapStateToProps, mapDispatchToProps)(General);

export default GeneralContainer;
