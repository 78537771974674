import { connect } from 'react-redux';
import { updateModalProps, getModalProps } from 'redux/modules/modals/modals';
import get from 'lodash/get';
import SearchBox from './SearchBox';
import debounce from 'lodash/debounce';

const mapStateToProps = state => ({
  pastRefinement: get(getModalProps(state), 'refinement', ''),
});

const debounceSave = debounce((dispatch, refinement) => {
  dispatch(
    updateModalProps({
      refinement,
    })
  );
}, 200);

const mapDispatchToProps = dispatch => ({
  saveRefinement: refinement => debounceSave(dispatch, refinement),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
