import React from 'react';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';

const DISTANCE_TO_FIXED = 66;
export const COMPACT_DAY_HEADER_HEIGHT = 30;

const Wrapper = styled.div`
  margin-left: 66px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  background-color: white;
  z-index: 1;
  max-width: ${x => (x.fixed ? 'calc(100vw - 401px)' : '')};

  ${x => (x.fixed ? `position: fixed; top: 0;` : '')};
`;
const DateCell = styled.div`
  height: ${x => (x.compact ? COMPACT_DAY_HEADER_HEIGHT : 70)}px;
  width: 161px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  padding-left: ${x => (x.compact ? 0 : '16px')};
  box-sizing: border-box;
  display: flex;
  flex-direction: ${x => (x.compact ? 'row' : 'column')};
  justify-content: center;
  align-items: ${x => (x.compact ? 'center' : '')};
`;

const WeekDay = styled.div``;
const CompactWeekDay = styled.div`
  margin-right: 2px;
`;
const Day = styled.div``;
const CompactDay = styled.div`
  text-align: center;
`;

class DayHeaders extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fixed: false,
    };
    this.last_known_scroll_position = 0;
    this.ticking = false;
  }
  componentDidUpdate(prevProps) {
    if (this.props.modalOpen !== prevProps.modalOpen) {
      var scrolledDiv = document.getElementById('keenoa_page_content');
      if (!scrolledDiv) return;
      this._onYScroll(scrolledDiv.scrollTop, this.props.modalOpen);
    }
  }

  componentDidMount() {
    var scrolledDiv = document.getElementById('keenoa_page_content');
    if (!scrolledDiv) return;
    scrolledDiv.addEventListener('scroll', this._onYScrollTrigger);
  }
  componentWillUnmount() {
    var scrolledDiv = document.getElementById('keenoa_page_content');
    if (!scrolledDiv) return;
    scrolledDiv.removeEventListener('scroll', this._onYScrollTrigger);
  }

  /** https://developer.mozilla.org/en-US/docs/Web/Events/scroll */
  _onYScrollTrigger = (e, modalOpen) => {
    this.last_known_scroll_position = e.target.scrollTop;

    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this._onYScroll(this.last_known_scroll_position, modalOpen);
        this.ticking = false;
      });

      this.ticking = true;
    }
  };

  _onYScroll = (yPosition, modalOpen) => {
    const { fixed } = this.state;
    const open = modalOpen === undefined ? this.props.modalOpen : modalOpen;

    const shouldBeFixed = yPosition > DISTANCE_TO_FIXED && !open;
    if (fixed !== shouldBeFixed) {
      this.setState({
        fixed: shouldBeFixed,
      });
    }
  };

  _getWeekday = date => {
    const { locale } = this.props;
    var options = { weekday: 'short' };
    return capitalize(date.toLocaleDateString(locale, options));
  };
  _getDay = date => {
    const { locale } = this.props;
    var options = { day: 'numeric' };
    return date.toLocaleDateString(locale, options);
  };

  renderHeader = fixed => {
    const { dates, compact } = this.props;
    const WDay = compact ? CompactWeekDay : WeekDay;
    const DDay = compact ? CompactDay : Day;
    return (
      <Wrapper fixed={fixed}>
        {dates.map(date => (
          <DateCell key={date} compact={compact}>
            <WDay
              className={`mdc-typography--${compact ? 'body1' : 'caption'}`}
            >
              {this._getWeekday(date)}
            </WDay>
            <DDay
              className={`mdc-typography--${compact ? 'body1' : 'headline4'}`}
            >
              {this._getDay(date)}
            </DDay>
          </DateCell>
        ))}
      </Wrapper>
    );
  };

  render() {
    const { fixed } = this.state;
    return (
      <div>
        {this.renderHeader()}
        {!fixed ? null : this.renderHeader(true)}
      </div>
    );
  }
}

export default DayHeaders;
