import React from 'react';
import Modal from 'components/Modal';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Switch } from 'components/forms/Switch';
import Typography from 'components/material/Typography';
import styled from 'styled-components';

const messages = defineMessages({
  title: { id: 'client.profile.questionActive' },
  subtitle: { id: 'client.profile.questionActiveHelp' },
});

const SwitchWrapper = styled.div`
  --mdc-theme-secondary: ${x => x.theme.palette.primary1};
`;

export default function IntuitiveModal({
  visible,
  onDismiss,
  questionActive,
  setQuestionActive,
}) {
  return (
    <Modal isOpen={visible} onClose={onDismiss}>
      <div style={{ padding: 25 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography use="headline6" rank="primary" tag="div">
            <FormattedMessage {...messages.title} />
          </Typography>
          <SwitchWrapper>
            <Switch
              checked={questionActive}
              onChange={() => setQuestionActive(!questionActive)}
            />
          </SwitchWrapper>
        </div>
        <Typography use="body2" rank="secondary" tag="div" spacing="sm">
          <FormattedMessage {...messages.subtitle} />
        </Typography>
      </div>
    </Modal>
  );
}
