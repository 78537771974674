import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withOauth } from '../Oauth';
import queryString from 'query-string';
import { secureCall, json, JSON_HEADER } from '../../../api/api';
import Loader from 'components/loaders/CircleLoader';
import Checkbox from 'components/forms/Checkbox';
import { Button } from 'components/material/Button';
import { defineMessages, FormattedMessage } from 'react-intl';
import { scopeToMessage, JOIN_TOKEN } from '../../../constants/scopes';
import { isTokenValid } from 'utils/auth';
import { Redirect, useLocation } from 'react-router-dom';

const messages = defineMessages({
  error: { id: 'oauth.authorize.error' },
  errorHelp: { id: 'oauth.authorize.errorHelp' },
  title: { id: 'oauth.authorize.title' },
  subtitile: { id: 'oauth.authorize.subtitle' },
  data: { id: 'oauth.authorize.data' },
  allow: { id: 'oauth.authorize.allow' },
  deny: { id: 'oauth.authorize.deny' },
  privacy: { id: 'oauth.authorize.privacy' },
  review: { id: 'oauth.authorize.review' },
  terms: { id: 'oauth.authorize.terms' },
  and: { id: 'oauth.authorize.and' },
  confirm: { id: 'oauth.authorize.confirm' },
  callError: { id: 'oauth.authorize.callError' },
  callErrorHelp: { id: 'oauth.authorize.callErrorHelp' },
  retry: { id: 'oauth.authorize.retry' },
});

const Scope = styled.div`
  padding: 16px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: ${x => x.theme.palette.text.primary};
`;

const CheckWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-left: -10px;
  margin-top: -10px;

  --mdc-theme-secondary: ${x => x.theme.palette.primary1};
`;

const ErrorBox = styled.div`
  background-color: ${props => props.theme.palette.errorBg};
  border: 1px solid ${props => props.theme.palette.canvas};
  color: ${props => props.theme.palette.text.primary};
  padding: 15px;
  margin: -20px;
  font-size: 14px;
  border-radius: 6px;
`;

const ScopeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

function Authorize() {
  const [clientId, setClientId] = useState(null);
  const [scope, setScope] = useState(null);
  const [redirectUri, setRedirectUri] = useState(null);
  const [state, setState] = useState(null);
  const [app, setApp] = useState(null);
  const [badParams, setBadParams] = useState(false);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();

  const loadApp = async clientId => {
    const a = await secureCall(`/apps/${clientId}`).then(json);
    setApp(a);
  };

  const allowApp = async () => {
    try {
      const res = await secureCall(`/oauth/authorize`, {
        queryParams: {
          client_id: clientId,
          redirect_uri: redirectUri,
          scope,
          state,
          grant_type: 'authorization_code',
          response_type: 'code',
        },
        redirect: 'follow',
        method: 'POST',
        headers: JSON_HEADER,
      });
      const { location } = await res.json();
      window.location = location;
    } catch (err) {
      setError(true);
    }
  };

  const denyApp = () => {
    window.location.replace(`${redirectUri}?error=access_denied`);
  };

  useEffect(() => {
    const { client_id, scope, redirect_uri, state } = queryString.parse(
      window.location.search
    );

    if (!client_id || !scope || !redirect_uri) {
      setBadParams(true);
      return;
    }

    setClientId(client_id);
    setScope((scope || '').split(JOIN_TOKEN));
    setRedirectUri(redirect_uri);
    setState(state);

    loadApp(client_id);
  }, []);

  if (!isTokenValid()) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: `${location.pathname}${location.search}` },
        }}
      />
    );
  }

  if (badParams)
    return (
      <ErrorBox className="mdc-typography--body1">
        <FormattedMessage {...messages.error} />
        <br />
        <br />
        <FormattedMessage {...messages.errorHelp} />
      </ErrorBox>
    );
  if (error)
    return (
      <ErrorBox className="mdc-typography--body1">
        <FormattedMessage {...messages.callError} />
        <br />
        <br />
        <FormattedMessage
          {...messages.callErrorHelp}
          values={{ app_name: app.name }}
        />
        <Button
          inverted
          style={{ padding: '4px 24px', marginTop: 10 }}
          onClick={() => setError(false)}
        >
          <FormattedMessage {...messages.retry} />
        </Button>
      </ErrorBox>
    );

  if (!app) return <Loader style={{ minHeight: 250 }} />;

  return (
    <div>
      <div>
        <p
          className="mdc-typography--headline"
          style={{
            color: 'rgba(0,0,0,0.72)',
            textAlign: 'center',
            marginTop: 0,
          }}
        >
          <a href={app.website_url} target="_blank" rel="noopener noreferrer">
            {app.name}
          </a>{' '}
          <FormattedMessage {...messages.subtitile} />
        </p>

        <ScopeWrapper>
          {scope
            .map(s => ({ id: s, msg: scopeToMessage[s] }))
            .filter(s => s.msg)
            .map(({ id, msg }) => (
              <Scope className="mdc-typography--caption" key={id}>
                <FormattedMessage {...msg} style={{ color: 'inherit' }} />
              </Scope>
            ))}
        </ScopeWrapper>

        <p
          className="mdc-typography--caption"
          style={{ color: 'rgba(0,0,0,0.54)' }}
        >
          <FormattedMessage
            {...messages.data}
            values={{ app_name: app.name }}
          />
          <a href={app.privacy_url} rel="noopener noreferrer">
            <FormattedMessage {...messages.privacy} />
          </a>{' '}
          <FormattedMessage {...messages.and} />
          <a href={app.terms_url} target="_blank" rel="noopener noreferrer">
            <FormattedMessage {...messages.terms} />
          </a>
          <FormattedMessage {...messages.review} />
        </p>
        <CheckWrapper>
          <Checkbox checked={checked} onChange={e => setChecked(!checked)} />
          <p
            className="mdc-typography--caption"
            style={{ color: 'rgba(0,0,0,0.72)' }}
          >
            <FormattedMessage
              {...messages.confirm}
              values={{ app_name: app.name }}
            />
          </p>
        </CheckWrapper>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button danger onClick={denyApp}>
            <FormattedMessage {...messages.deny} />
          </Button>
          <Button unelevated onClick={allowApp} disabled={!checked}>
            <FormattedMessage {...messages.allow} />
          </Button>
        </div>
      </div>
    </div>
  );
}

const A = withOauth(Authorize);
export default () => <A />;
