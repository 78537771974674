import { createTemplateVersion, getTemplateVersion } from '../templateVersions';
import {
  fetchReportTemplate,
  createReportTemplate,
} from './reportTemplates.actions';
import { getTemplateVersionId } from './';

export const duplicateTemplate = (
  templateId,
  { title, emoji, description }
) => async (dispatch, getState) => {
  let newTemplateId;
  await Promise.all([
    dispatch(fetchReportTemplate(templateId)),
    dispatch(createReportTemplate({ title, emoji, description })).then(res => {
      newTemplateId = res.response.result;
    }),
  ]);
  const versionId = getTemplateVersionId(getState(), templateId);
  const version = getTemplateVersion(getState(), versionId);
  if (!version) return;

  return dispatch(createTemplateVersion(newTemplateId, version.structure));
};
