import React, { useState } from 'react';
import { Button } from 'components/material/Button';
import { Select } from 'components/material/Select';
import NumberPicker from 'components/forms/NumberPicker';
import styled from 'styled-components';
import { FormattedMessage, defineMessages } from 'react-intl';
import { FRACTION_OPTIONS } from '../../../../../constants/fractions';
import CircularProgress from 'components/loaders/CircularProgress';
import Spacer from 'components/Spacer';
import { UnitPicker } from './UnitPicker';

const messages = defineMessages({
  submitting: { id: 'foodModal.picker.submitting' },
});

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

function Picker({
  isWholeUnit,

  quantity,
  onQuantityChange,

  fraction,
  onFractionChange,

  measure,
  measures,
  onMeasureChange,

  submitMessage,
  onSubmit,
}) {
  const [submitting, setSubmitting] = useState(false);

  const renderWholeUnit = () => {
    return (
      <>
        <Spacer spacing="md" />
        <Select
          outlined
          value={`${fraction.value}`}
          options={FRACTION_OPTIONS}
          onChange={e => {
            const newValue = e.currentTarget.value;
            const newFraction = FRACTION_OPTIONS.find(
              ({ value }) => `${value}` === newValue
            );
            onFractionChange(newFraction);
          }}
          rootProps={{ style: { width: 200 } }}
        />
      </>
    );
  };

  const _onSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    await onSubmit();
    setSubmitting(false);
  };

  return (
    <form onSubmit={_onSubmit}>
      <Spacer spacing="md" />
      <div style={{ display: 'flex' }}>
        <div>
          <NumberPicker
            input={{
              value: quantity,
              onChange: onQuantityChange,
            }}
          />
        </div>
        {isWholeUnit ? renderWholeUnit() : null}
        <Spacer spacing="md" />
        <UnitPicker value={measure} onChange={onMeasureChange}>
          {measures}
        </UnitPicker>
      </div>
      <Spacer spacing="md" />
      <ButtonWrapper>
        <Button
          type="submit"
          unelevated
          disabled={submitting}
          icon={submitting ? <CircularProgress /> : undefined}
        >
          {submitting ? (
            <>
              <FormattedMessage {...messages.submitting} />
            </>
          ) : (
            <FormattedMessage {...submitMessage} />
          )}
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default Picker;
