import ClientsFiltering from './ClientsFiltering';

import { connect } from 'react-redux';
import { setStatusFilter, getStatusFilter } from 'redux/modules/UI/clientsView';

const mapStateToProps = state => ({
  filter: getStatusFilter(state),
});

const mapDispatchToProps = dispatch => ({
  selectFilter: filter => dispatch(setStatusFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsFiltering);
