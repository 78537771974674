import { secureCall, json, JSON_HEADER } from './api';
import { periodToDate } from '../utils/dateUtils';

export const fetchInvoices = () =>
  secureCall('/invoices', {
    method: 'GET',
  }).then(json);

export const fetchSubscriptions = () =>
  secureCall('/subscriptions', {
    method: 'GET',
  }).then(json);

export const fetchActiveClients = cycle => {
  const queryParams = cycle.current
    ? {}
    : {
        'start-date': periodToDate(cycle.period_start).toISOString(),
        'end-date': periodToDate(cycle.period_end).toISOString(),
      };
  return secureCall('/active-clients', {
    method: 'GET',
    queryParams,
  }).then(json);
};

export const addSource = token => {
  return secureCall('/sources', {
    method: 'post',
    headers: JSON_HEADER,
    body: JSON.stringify({
      tokenId: token.id,
    }),
  }).then(json);
};

export const fetchSources = () => secureCall('/sources').then(json);

export default {
  fetchInvoices,
  fetchSubscriptions,
  fetchActiveClients,

  fetchSources,
  addSource,
};
