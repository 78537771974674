import { combineReducers } from 'redux';
import types, { pre } from './publicBuilders.types';
import { normalize } from 'normalizr';
import {
  makeById,
  makeMapFetchStatus,
} from '../../../../utils/standardReducers';
import { fetchPublicBuilderApi } from '../../../../api/foods';
import { getPublicBuilder } from './publicBuilders.selectors';
import schemas from '../../../schemas';

export default combineReducers({
  byId: makeById('publicBuilders'),
  byIdFetchStatus: makeMapFetchStatus(`${pre}FETCH_BUILDER`),
});

// Actions
export const fetchPublicBuilder = (builderId, dev) => ({
  types: [
    types.FETCH_PUBLIC_BUILDER_REQUEST,
    types.FETCH_PUBLIC_BUILDER_SUCCESS,
    types.FETCH_PUBLIC_BUILDER_FAILURE,
  ],
  callAPI: () =>
    fetchPublicBuilderApi(builderId, dev).then(res => {
      return normalize(res, schemas.publicBuilder);
    }),
  payload: { builderId, id: builderId, dev },
  shouldCallAPI: state => getPublicBuilder(state, builderId) === null,
});
