import styled from 'styled-components';

const base = 4.5;
export default styled.div`
  padding-left: ${2 * base}px;
  padding-right: ${2 * base}px;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: ${base * 2}px;
  margin: 4px;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: ${base * 6}px;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.palette.text.primary};
`;
