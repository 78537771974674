import React from 'react';
import { SPACINGS, SIZES } from 'components/Spacer';
import { Nutrient } from './Nutrient';
import { BASIC_NUTRIENTS_MAIN, BASIC_NUTRIENTS_SECOND } from './constants';

export function BasicNutrients({ nutrients, setNutrient }) {
  const borderStyle = '1px solid rgba(0, 0, 0, 0.42)';
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div
        style={{
          width: 280,
          padding: SIZES[SPACINGS.medium],
          borderTop: borderStyle,
          borderLeft: borderStyle,
          borderRight: borderStyle,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      >
        {BASIC_NUTRIENTS_MAIN.map(n => (
          <Nutrient
            {...n}
            value={nutrients[n.id]}
            setValue={v => setNutrient(n.id, v)}
          />
        ))}
      </div>
      <div
        style={{
          width: 280,
          padding: SIZES[SPACINGS.medium],
          borderLeft: borderStyle,
          borderRight: borderStyle,
          borderBottom: borderStyle,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          marginLeft: SIZES[SPACINGS.medium],
        }}
      >
        {BASIC_NUTRIENTS_SECOND.map(n => (
          <Nutrient
            {...n}
            value={nutrients[n.id]}
            setValue={v => setNutrient(n.id, v)}
          />
        ))}
      </div>
    </div>
  );
}
