import { createSelector } from 'reselect';
import get from 'lodash/get';
import { TIME_FORMATS, GROUP_TAGS, TAGS } from '../../../constants';
import nutrimentsConstants, {
  defaultDietitianNutrimentsKeys,
} from 'constants/nutriments';

export const _getAccount = state => state.account;

const _getNutrients = state => get(state, 'account.nutrients', null);

export const getAccountTheme = createSelector(_getAccount, a =>
  get(a, 'theme')
);

export const getFullName = state => state.account.full_name;
export const getFirstName = createSelector(getFullName, x => x.split(' ')[0]);
export const getLocale = state => get(state, 'account.locale');
export const getSmallLocale = createSelector(getLocale, locale =>
  locale ? locale.slice(0, 2) : locale
);
export const getIsLocaleFr = createSelector(getLocale, locale => {
  if (!locale) return false;

  return locale.toLowerCase().startsWith('fr');
});
export const getFullLocale = createSelector(getLocale, locale => {
  const lang = locale.split('-')[0];
  const browserLang =
    window.navigator.userLanguage || window.navigator.language;
  const countryCode = browserLang.split('-')[1];
  return `${lang}-${countryCode || 'CA'}`;
});
export const getOnboard = createSelector(
  _getAccount,
  a => get(a, 'onboard') || {}
);
/** (state, onboardId) */
export const getOnboardRun = createSelector(
  [getOnboard, (_, onboardId) => onboardId],
  (onboard, onboardId) => {
    const hasRun = !!get(onboard, onboardId, false);
    return hasRun;
  }
);

export const getInitial = createSelector([getFullName], fullName =>
  fullName ? fullName[0] : ''
);

export const _getIsTwelveHour = createSelector(_getAccount, a =>
  get(a, 'prefer_twelve_hour', false)
);
export const getTimeFormat = createSelector(_getIsTwelveHour, x =>
  x ? TIME_FORMATS.TWELVE_HOUR : TIME_FORMATS.TWENTY_FOUR_HOUR
);

export const getDietitianId = createSelector(_getAccount, a => get(a, 'id'));

export const getDietitianSignature = createSelector(_getAccount, a =>
  get(a, 'signature')
);

export const getDietitianEmail = createSelector(_getAccount, a =>
  get(a, 'email')
);

export const getDietitianLogTimestamp = createSelector(_getAccount, a =>
  get(a, 'logTimestamp')
);

export const getTags = createSelector(_getAccount, account =>
  get(account, 'tags', [])
);

export const getDietitianIsWithNutrisimple = createSelector(getTags, tags => {
  return tags.includes(GROUP_TAGS.NUTRISIMPLE);
});

export const getDietitianIsResearcher = createSelector(getTags, tags => {
  return tags.includes(TAGS.RESEARCHER);
});
export const getDietitianIsInstructor = createSelector(getTags, tags => {
  return tags.includes(TAGS.INSTRUCTOR);
});

const defaultNutrients = defaultDietitianNutrimentsKeys.map(
  key => nutrimentsConstants[key]
);
export const getNutrients = createSelector(
  [_getNutrients, getDietitianIsWithNutrisimple],
  (nutrients, isWithNutrisimple) => {
    if (nutrients && nutrients.length) return nutrients;
    return [
      ...(isWithNutrisimple
        ? [nutrimentsConstants.NS_STEP, nutrimentsConstants.NS_GREEN_STEP]
        : []),
      ...defaultNutrients,
    ];
  }
);

export default {
  getNutrients,
  getInitial,

  getFullName,
  getLocale,
};
