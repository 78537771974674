import React, { useEffect, useRef, useState } from 'react';
import Group from './Group';
import QuantityPicker from './QuantityPicker';
import uuid from 'uuid';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';
import Measure from 'react-measure';
import { vhToPx } from '../../../../../../utils/misc';
import CircleLoader from 'components/loaders/CircleLoader';
import get from 'lodash/get';
import { BUILDER_TYPES } from '../../../../../../constants';
import { defineMessages, FormattedMessage } from 'react-intl';
import { getIdParts } from 'utils/food';

const messages = defineMessages({
  ingredients: { id: 'foodModal.creator.ingredients' },
});

function hasNumber(myString) {
  return /\d/.test(myString);
}

const Title = styled.span`
  border-bottom: 3px solid ${x => x.theme.palette.primary4};
  color: ${x => x.theme.palette.text.secondary};
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -30px
  padding-bottom: 5px;
  border-bottom: ${x => (x.elevated ? '1px solid #b5b5bf' : 'none')};
  transition: border-bottom .2s linear;
`;

const TARGET_HEIGHT_VH = 45;

function CreatorView({
  namespace,
  setNamespace,

  foodId,

  fetchBuilderConfig,
  setConfigId,
  configId,
  fetchPublicBuilder,
  setPublicBuilderId,
  publicBuilderId,

  builderId,
  initBuilder,

  name,
  groups,

  createBuilderConfig,
  onSubmit,

  optionsNames,
  builderType,

  startQuantity,
  startMeasureId,
}) {
  const refs = useRef({});
  const [ready, setReady] = useState(false);
  const [height, setHeight] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!groups.length || loading) {
      setReady(false);
      return;
    }

    const t = setTimeout(() => setReady(true), 250);
    return () => clearTimeout(t);
  }, [groups.length, ready]);

  /** Initialising the namespace. */
  useEffect(() => {
    if (namespace) return;

    setNamespace(uuid.v4());
  }, [namespace]);

  /** Fetching the food. */
  useEffect(() => {
    if (!foodId || !namespace) return;

    const [pre, id] = getIdParts(foodId);
    if (pre === 'kpb') {
      _fetchPublicBuilder(id);
    } else {
      _fetchBuilderConfig(id);
    }

    async function _fetchPublicBuilder(publicBuilderId) {
      setLoading(true);
      setReady(false);
      await fetchPublicBuilder(publicBuilderId);
      setPublicBuilderId(publicBuilderId);
      setLoading(false);
    }
    async function _fetchBuilderConfig(configId) {
      setLoading(true);
      setReady(false);
      await fetchBuilderConfig(configId);
      setConfigId(configId);
      setLoading(false);
    }
  }, [foodId, namespace]);

  /** Initialising the builder. */
  useEffect(() => {
    if (!builderId) return;

    initBuilder(builderId, { configId, publicBuilderId });
  }, [builderId]);

  const _isTitleFixed = () => {
    const v = vhToPx(TARGET_HEIGHT_VH);
    return height + 35 > v;
  };

  const renderSelectedOptions = () => {
    if (builderType !== BUILDER_TYPES.CREATOR && optionsNames.length < 5)
      return null;
    return (
      <div style={{ fontSize: 14 }}>
        <span style={{ marginRight: 4, fontWeight: 'bold' }}>
          <FormattedMessage {...messages.ingredients} />
        </span>
        <span style={{ marginRight: 3 }}>
          {optionsNames.filter(n => !hasNumber(n)).join(', ')}
        </span>
      </div>
    );
  };

  const renderQuantityPicker = () => {
    return (
      <div style={{ borderTop: '1px solid #b5b5bf' }}>
        {renderSelectedOptions()}
        <QuantityPicker
          namespace={namespace}
          builderId={builderId}
          createBuilderConfig={createBuilderConfig}
          onSubmit={onSubmit}
          startQuantity={startQuantity}
          startMeasureId={startMeasureId}
          loading={!ready || loading}
        />
      </div>
    );
  };

  const renderTitle = elevated => {
    return (
      <TitleWrapper elevated={elevated}>
        <Title
          className="mdc-typography--headline6"
          style={{ marginBottom: 2 }}
        >
          {capitalize(name)}
        </Title>
      </TitleWrapper>
    );
  };

  const _makeSetRef = id => r => {
    if (!r) return;

    const isNew = !refs.current[id];

    refs.current[id] = r;
    if (isNew && ready) {
      r.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  const renderLoader = () => {
    if (!loading) return null;

    return (
      <div
        style={{
          height: `${TARGET_HEIGHT_VH}vh`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircleLoader />
      </div>
    );
  };

  const renderGroups = () =>
    loading ? (
      renderLoader()
    ) : (
      <div
        style={{
          display: 'flex',
          minHeight: `${TARGET_HEIGHT_VH}vh`,
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        {_isTitleFixed() ? null : renderTitle(false)}
        <Measure
          bounds
          onResize={contentRect => {
            setHeight(get(contentRect, 'bounds.height', 0));
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef}>
              {groups.map(group => (
                <div key={group.id} ref={_makeSetRef(group.id)}>
                  <Group {...group} namespace={namespace} />
                </div>
              ))}
            </div>
          )}
        </Measure>
      </div>
    );

  return (
    <div>
      {_isTitleFixed() ? renderTitle(true) : null}
      <div
        style={{
          overflowY: 'auto',
          overscrollBehavior: 'contain',
          height: `${TARGET_HEIGHT_VH}vh`,
        }}
      >
        {renderGroups()}
      </div>
      {renderQuantityPicker()}
    </div>
  );
}

export default CreatorView;
