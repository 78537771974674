import { get } from './storage';
import decode from 'jwt-decode';

export const isTokenValid = () => {
  const token = get('token');
  if (!token) return false;

  try {
    const decoded = decode(token);
    const exp = decoded.exp;
    const twoHoursInSeconds = 2 * 60 * 60;
    // We divide by 1000 since Date.now returns miliseconds instead of seconds in javascript.
    // https://auth0.com/forum/t/reading-expiry-date-of-jwt-on-client/2033/5
    return exp - twoHoursInSeconds > Date.now() / 1000;
  } catch (err) {
    return false;
  }
};
