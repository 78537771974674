import { connect } from 'react-redux';
import CheckoutForm from './CheckoutForm';
import { billingOperations } from 'redux/modules/billing';
import { injectStripe } from 'react-stripe-elements';
import { updateToken } from 'redux/modules/account/account.actions';

const mapDispatchToProps = (dispatch, ownProps) => ({
  submitToken: token =>
    dispatch(billingOperations.addSource(token)).then(() =>
      dispatch(updateToken())
    ),
});

const CheckoutFormContainer = injectStripe(
  connect(null, mapDispatchToProps)(CheckoutForm)
);

export default CheckoutFormContainer;
