import { connect } from 'react-redux';
import { accountOperations } from 'redux/modules/account';
import {
  closeModal,
  getModalOpen,
  MODAL_TYPES,
} from 'redux/modules/modals/modals';
import EditName from './EditName';

const mapStateToProps = state => ({
  isOpen: getModalOpen(state, MODAL_TYPES.UPDATE_NAME),
});

const mapDispatchToProps = dispatch => ({
  onExit: () => dispatch(closeModal(MODAL_TYPES.UPDATE_NAME)),
  onUpdateName: (firstName, lastName) => {
    dispatch(accountOperations.updateName(firstName, lastName));
    dispatch(closeModal(MODAL_TYPES.UPDATE_NAME));
  },
});

const EditNameContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditName);

export default EditNameContainer;
