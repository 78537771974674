export const pre = 'keenoa/builderConfigs/';

export default {
  CREATE_BUILDER_CONFIG_REQUEST: `${pre}CREATE_BUILDER_CONFIG_REQUEST`,
  CREATE_BUILDER_CONFIG_SUCCESS: `${pre}CREATE_BUILDER_CONFIG_SUCCESS`,
  CREATE_BUILDER_CONFIG_FAILURE: `${pre}CREATE_BUILDER_CONFIG_FAILURE`,

  FETCH_BUILDER_CONFIG_REQUEST: `${pre}FETCH_BUILDER_CONFIG_REQUEST`,
  FETCH_BUILDER_CONFIG_SUCCESS: `${pre}FETCH_BUILDER_CONFIG_SUCCESS`,
  FETCH_BUILDER_CONFIG_FAILURE: `${pre}FETCH_BUILDER_CONFIG_FAILURE`,
};
