const pre = 'keenoa/foodDiaries/';

export default {
  FETCH_FOOD_DIARIES_REQUEST: `${pre}FETCH_FOOD_DIARIES_REQUEST`,
  FETCH_FOOD_DIARIES_SUCCESS: `${pre}FETCH_FOOD_DIARIES_SUCCESS`,
  FETCH_FOOD_DIARIES_FAILURE: `${pre}FETCH_FOOD_DIARIES_FAILURE`,

  NEW_CLIENT_FETCH_FOOD_DIARIES: `${pre}NEW_CLIENT_FETCH_FOOD_DIARIES`,
  SET_CURRENT_FOOD_DIARY: `${pre}SET_CURRENT_FOOD_DIARY`,

  FETCH_FOOD_DIARY_REQUEST: `${pre}FETCH_FOOD_DIARY_REQUEST`,
  FETCH_FOOD_DIARY_SUCCESS: `${pre}FETCH_FOOD_DIARY_SUCCESS`,
  FETCH_FOOD_DIARY_FAILURE: `${pre}FETCH_FOOD_DIARY_FAILURE`,

  SET_DIARY_VERIFY_REQUEST: `${pre}SET_DIARY_VERIFY_REQUEST`,
  SET_DIARY_VERIFY_SUCCESS: `${pre}SET_DIARY_VERIFY_SUCCESS`,
  SET_DIARY_VERIFY_FAILURE: `${pre}SET_DIARY_VERIFY_FAILURE`,
};
