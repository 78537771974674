import React from 'react';

import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './RangePicker.css';
import Dropdown from '../Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  from: { id: 'rangePicker.from' },
  to: { id: 'rangePicker.to' },
});

export default class RangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
    this.state.open = false;
  }
  getInitialState() {
    return {
      from: null,
      to: null,
      enteredTo: null, // Keep track of the last day for mouseEnter.
    };
  }
  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }
  handleDayClick(day) {
    const { from, to } = this.state;
    if (from && to && day >= from && day <= to) {
      this.handleResetClick();
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null,
      });
      this.props.setRange({ from: day, to: null });
    } else {
      this.setState({
        to: day,
        enteredTo: day,
        open: false,
      });
      this.props.setRange({ from, to: day });
    }
  }
  handleDayMouseEnter(day) {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  }
  handleResetClick() {
    this.setState(this.getInitialState());
    this.props.setRange({ from: null, to: null });
  }
  render() {
    const { from, to, enteredTo, open } = this.state;
    const modifiers = { start: from, end: enteredTo };
    const disabledDays = { before: this.state.from };
    const selectedDays = [from, { from, to: enteredTo }];
    return (
      <div>
        <Dropdown
          isOpen={open}
          onOutsideClick={() => this.setState({ open: !open })}
          top="50px"
        >
          <DayPicker
            className="Range"
            numberOfMonths={2}
            selectedDays={selectedDays}
            disabledDays={disabledDays}
            modifiers={modifiers}
            onDayClick={this.handleDayClick}
            onDayMouseEnter={this.handleDayMouseEnter}
          />
        </Dropdown>
        <div
          className="range-wrapper"
          onClick={() => this.setState({ open: !open })}
        >
          <div>
            {from ? (
              from.toLocaleDateString()
            ) : (
              <FormattedMessage {...messages.from} />
            )}
          </div>
          <FontAwesomeIcon
            icon={['fal', 'long-arrow-right']}
            style={{
              fontSize: 26,
              color: 'rgba(0,0,0,0.33)',
            }}
          />
          <div>
            {to ? (
              to.toLocaleDateString()
            ) : (
              <FormattedMessage {...messages.to} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
