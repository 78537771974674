import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from 'components/material/Typography';

export default React.memo(function({ message }) {
  return (
    <Typography
      use="subtitle2"
      rank="secondary"
      tag="div"
      style={{ marginBottom: 5 }}
    >
      <FormattedMessage {...message} />
    </Typography>
  );
});
