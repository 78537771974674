export const pre = 'keenoa/UI/builderView/';

export default {
  REMOVE_BUILDER_NAMESPACE: `${pre}REMOVE_BUILDER_NAMESPACE`,

  STAGE_BUILDER: `${pre}STAGE_BUILDER`,
  STAGE_PUBLIC_BUILDER: `${pre}STAGE_PUBLIC_BUILDER`,

  SELECT_OPTION: `${pre}SELECT_OPTION`,
  UNSELECT_OPTION: `${pre}UNSELECT_OPTION`,
  CLEAR_OPTIONS: `${pre}CLEAR_OPTIONS`,

  CONFIRM_BUILDER: `${pre}CONFIRM_BUILDER`,

  ADD_ADJUSTMENT: `${pre}ADD_ADJUSTMENT`,
  REMOVE_ADJUSTMENT: `${pre}REMOVE_ADJUSTMENT`,
};
