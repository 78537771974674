import React, { useState, useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import Spacer, { SPACINGS, SIZES } from 'components/Spacer';
import IconButton from 'components/forms/IconButton';
import { Button } from 'components/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import './UserFoodEditor.css';
import { useHistory, Prompt } from 'react-router-dom';
import { PickFoodName } from './PickFoodName';
import { PickServingSize } from './PickServingSize';
import { PickNutrients } from './PickNutrients';
import { PickFoodVisibility } from './PickFoodVisibility';
import { SERVING_QUANTITY_UNITS } from './constants';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import forEach from 'lodash/forEach';
import { idToConstant, keenoaCodeToConstant } from 'constants/nutriments';
import { notify } from 'react-notify-toast';
import { CircularProgress } from 'components/loaders/CircularProgress';
import { LinearProgress } from 'components/loaders/LinearProgress';

const messages = defineMessages({
  save: { id: 'foods.create.save' },
  saving: { id: 'foods.create.saving' },

  nameError: { id: 'foods.create.selectNameError' },
  servingAmountError: { id: 'foods.create.selectServingAmountError' },
  servingNameError: { id: 'foods.create.selectServingNameError' },
  nutrientsError: { id: 'foods.create.nutrientsError' },
  unsavedChanges: { id: 'foods.create.unsavedChanges' },
});

const Wrapper = styled.div`
  --mdc-theme-secondary: ${x => x.theme.palette.primary1};
`;

const PickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

function SideIcon({ icon, style = {} }) {
  return (
    <div style={{ width: 56, fontSize: 18, ...style }}>
      {!icon ? null : (
        <FontAwesomeIcon
          icon={icon}
          style={{ marginLeft: 14, opacity: 0.63 }}
        />
      )}
    </div>
  );
}

function saveFormatToEditFormat(nutrients = {}) {
  const out = {};
  forEach(nutrients, (value, key) => {
    if (value === null) return;

    out[keenoaCodeToConstant[key].id] = `${value}`;
  });
  return out;
}

function editFormatToSaveFormat(nutrients) {
  return mapValues(
    mapKeys(nutrients, (_, id) => idToConstant[id].keenoaCode),
    parseFloat
  );
}

export function UserFoodEditor({
  onSave,

  defaultName = '',
  defaultServingAmount = 1,
  defaultServingName = '',
  defaultServingQuantity,
  defaultServingQuantityUnit = SERVING_QUANTITY_UNITS.G,
  defaultNutrients,

  isFetchingDefaults,
}) {
  const [name, setName] = useState(defaultName);
  useEffect(() => {
    setName(defaultName);
  }, [defaultName]);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const intl = useIntl();

  const history = useHistory();

  const [allowClients, setAllowClients] = useState(true);

  const [servingAmount, setServingAmount] = useState(1);
  const [servingFraction, setServingFraction] = useState(0);
  useEffect(() => {
    const defaultAmount = Math.floor(defaultServingAmount);
    setServingAmount(defaultAmount);
    const defaultFraction = defaultServingAmount % 1;
    setServingFraction(defaultFraction);
  }, [defaultServingAmount]);

  const [servingName, setServingName] = useState(defaultServingName);
  useEffect(() => {
    setServingName(defaultServingName);
  }, [defaultServingName]);

  const [servingQuantity, setServingQuantity] = useState(undefined);
  useEffect(() => {
    if (typeof defaultServingQuantity === 'number') {
      setServingQuantity(defaultServingQuantity);
    }
  }, [defaultServingQuantity]);

  const [servingQuantityUnit, setServingQuantityUnit] = useState(
    defaultServingQuantityUnit
  );
  useEffect(() => {
    setServingQuantityUnit(defaultServingQuantityUnit);
  }, [defaultServingQuantityUnit]);

  const [nutrients, setNutrients] = useState({});
  useEffect(() => {
    setNutrients(saveFormatToEditFormat(defaultNutrients));
  }, [defaultNutrients]);

  const _goBack = () => {
    history.goBack();
  };

  const _onSave = async () => {
    if (!_validate()) return;
    setLoading(true);

    const quantity =
      servingQuantity === null || servingQuantity === undefined
        ? null
        : {
            value: parseFloat(servingQuantity),
            unit: servingQuantityUnit,
          };
    const serving = {
      amount: parseFloat(servingAmount) + parseFloat(servingFraction),
      name: servingName,
      quantity,
    };
    const permissions = { allowClients };
    try {
      await onSave(
        name,
        serving,
        editFormatToSaveFormat(nutrients),
        permissions
      );
      setDirty(false);
      _goBack();
    } finally {
      setLoading(false);
    }
  };

  const _validate = () => {
    if (!name) {
      notify.show(intl.formatMessage(messages.nameError), 'error');
      return false;
    }
    if (servingAmount === null || servingAmount === undefined) {
      notify.show(intl.formatMessage(messages.servingAmountError), 'error');
      return false;
    }
    if (!servingName) {
      notify.show(intl.formatMessage(messages.servingNameError), 'error');
      return false;
    }
    if (Object.values(nutrients).filter(x => x).length === 0) {
      notify.show(intl.formatMessage(messages.nutrientsError), 'error');
      return false;
    }

    return true;
  };

  const _setDirty = fn => x => {
    setDirty(true);
    fn(x);
  };

  return (
    <Wrapper
      style={{ padding: SIZES[SPACINGS.large] }}
      className="create-user-food"
    >
      <Prompt
        when={dirty && !loading}
        message={intl.formatMessage(messages.unsavedChanges)}
      />
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: 'white',
        }}
      >
        {isFetchingDefaults ? <LinearProgress /> : null}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <IconButton
          icon={'close'}
          style={{ color: 'rgba(0,0,0,0.61)' }}
          onClick={_goBack}
        />
        <PickFoodName value={name} setValue={setName} />
        <Button
          unelevated
          onClick={_onSave}
          disabled={loading}
          icon={loading ? <CircularProgress /> : null}
        >
          <FormattedMessage {...(loading ? messages.saving : messages.save)} />
        </Button>
      </div>
      <Spacer spacing={SPACINGS.large} />
      <PickerWrapper>
        <SideIcon />
        <PickServingSize
          amount={servingAmount}
          setAmount={_setDirty(setServingAmount)}
          fraction={servingFraction}
          setFraction={_setDirty(setServingFraction)}
          name={servingName}
          setName={_setDirty(setServingName)}
          quantity={servingQuantity}
          setQuantity={_setDirty(setServingQuantity)}
          quantityUnit={servingQuantityUnit}
          setQuantityUnit={_setDirty(setServingQuantityUnit)}
        />
      </PickerWrapper>
      <Spacer spacing={SPACINGS.medium} />
      <PickerWrapper>
        <SideIcon />
        <PickFoodVisibility
          value={allowClients}
          setValue={_setDirty(setAllowClients)}
        />
      </PickerWrapper>
      <Spacer spacing={SPACINGS.medium} />
      <PickerWrapper>
        <div>
          <Spacer spacing={SPACINGS.large} />
          <SideIcon icon={['fad', 'tags']} />
        </div>
        <PickNutrients
          nutrients={nutrients}
          setNutrients={_setDirty(setNutrients)}
        />
      </PickerWrapper>

      <Spacer spacing={SPACINGS.large} />
    </Wrapper>
  );
}
