import { insecureFetch, json, JSON_HEADER } from './api';

const _emailPass = path => credentials =>
  insecureFetch(path, {
    method: 'post',
    headers: JSON_HEADER,
    body: JSON.stringify(credentials),
  }).then(json);

export const signupAndEnroll = _emailPass('/courses/enroll');
export const signup = _emailPass('/dietitians/register');

export const login = _emailPass('/dietitians/login');

export const requestPasswordReset = email =>
  insecureFetch('/accounts/request-password', {
    method: 'post',
    headers: JSON_HEADER,
    body: JSON.stringify({ email }),
  });

export const sendPasswordReset = (token, password) =>
  insecureFetch('/accounts/reset-password', {
    method: 'post',
    headers: JSON_HEADER,
    body: JSON.stringify({ token, password }),
  });

export default {
  signup,
  login,
  requestPasswordReset,
  sendPasswordReset,
  signupAndEnroll,
};
