import { get, remove } from '../utils/storage';
import { store } from 'redux/store';
import { getCurrentClientToken } from 'redux/modules/clients/clients.selectors';
import history from '../history';
import includes from 'lodash/includes';

const ENDPOINT_PREFIX = '/v1';

const queryParams = params =>
  Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');

const extendUrl = (url, params) =>
  params ? `${url}?${queryParams(params)}` : url;

export const imageProxyUrl = url => {
  const params = {
    url,
    authorization: `Bearer ${get('token')}`,
  };
  return extendUrl(`${ENDPOINT_PREFIX}/image-proxy`, params);
};

export const extendHeader = (headers = {}) => {
  const extendedHeader = {
    ...headers,
    Authorization: `Bearer ${get('token')}`,
  };
  const clientToken = getCurrentClientToken(store.getState());
  if (clientToken) {
    extendedHeader['client-authorization'] = `Bearer ${clientToken}`;
  }
  return extendedHeader;
};

const status = res => {
  if (res.status >= 200 && res.status < 300) {
    return Promise.resolve(res);
  } else {
    return res.json().then(resBody => {
      if (res.status === 401 || res.status === 403 || res.status === 402) {
        if (res.status === 402) {
          const href = window.location.href;
          if (!includes(href, 'billing')) {
            history.push('/account/billing/?payment_due=true');
          }
        }
        if (resBody.type === 'invalid_token' || res.status === 403) {
          remove('token');
        }
      }
      return Promise.reject(resBody);
    });
  }
};

export function text(res) {
  return res.text();
}

export function json(res) {
  return res.json();
}

export const JSON_HEADER = { 'Content-type': 'application/json' };

export function insecureFetch(url, options = {}) {
  return fetch(ENDPOINT_PREFIX + url, options).then(status);
}

export const completeUrl = url =>
  `${ENDPOINT_PREFIX}/api/${url}`.replace(/\/\/+/g, '/');

export const secureCall = (url, options = {}) => {
  const extendedOptions = {
    ...options,
    headers: extendHeader(options.headers),
  };
  const extendedUrl = extendUrl(url, options.queryParams);
  return fetch(completeUrl(extendedUrl), extendedOptions).then(status);
};
