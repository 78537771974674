import React from 'react';
import { FastCheckbox } from 'components/forms/Checkbox';

class ClientCheckBox extends React.Component {
  handleInputChange = e => {
    const { selected, select, unselect, id } = this.props;
    e.stopPropagation();
    if (!selected) {
      select(id);
    } else {
      unselect(id);
    }
  };
  stopPropagation = e => {
    e.stopPropagation();
  };

  render() {
    const { selected, indeterminate } = this.props;
    return (
      <FastCheckbox
        type="checkbox"
        indeterminate={indeterminate}
        checked={selected}
        onChange={this.handleInputChange}
        onClick={this.stopPropagation}
      />
    );
  }
}

export default ClientCheckBox;
