import CoursesFiltering from './CoursesFiltering';

import { connect } from 'react-redux';
import {
  getCoursesFilter,
  setCoursesFilter,
} from 'redux/modules/UI/clientsView';
import { getAvailableCourses } from 'redux/modules/clients/clients.selectors';

const mapStateToProps = state => ({
  filter: getCoursesFilter(state),
  courses: getAvailableCourses(state),
});

const mapDispatchToProps = dispatch => ({
  selectFilter: filter => dispatch(setCoursesFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoursesFiltering);
