import React from 'react';
import Modal from '../Modal';
import H2 from '../H2';
import Typography from '../material/Typography';
import { Button } from '../material/Button';
import Spacer from 'components/Spacer';
import { FormattedMessage } from 'react-intl';

export default function ConfirmDialog({
  isOpen,

  title,
  explanation,
  confirm,
  cancel,

  onConfirm,
  onCancel,
}) {
  if (!title) return null;
  return (
    <Modal isOpen={isOpen} onClose={onCancel} style={{ maxWidth: 450 }}>
      <H2>
        <FormattedMessage {...title} />
      </H2>
      <Typography use="body1" rank="secondary" tag="div">
        <FormattedMessage {...explanation} />
      </Typography>
      <Spacer spacing="lg" />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <Button onClick={onConfirm} rank="error" unelevated>
          <FormattedMessage {...confirm} />
        </Button>
        <Spacer spacing="sm" />
        <Button onClick={onCancel} rank="secondary">
          <FormattedMessage {...cancel} />
        </Button>
      </div>
    </Modal>
  );
}
