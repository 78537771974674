import ReportsFiltering from './ReportsFiltering';
import { connect } from 'react-redux';
import {
  getReportsFilter,
  setReportsFilter,
} from 'redux/modules/UI/reportsView';

const mapStateToProps = state => ({
  filter: getReportsFilter(state),
});

const mapDispatchToProps = dispatch => ({
  selectFilter: filter => dispatch(setReportsFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsFiltering);
