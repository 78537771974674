import { secureCall, json, JSON_HEADER } from './api';

export const createReportTemplateApi = ({ title, emoji, description }) =>
  secureCall('/report-templates', {
    method: 'POST',
    headers: JSON_HEADER,
    body: JSON.stringify({ title, emoji, description }),
  }).then(json);

export const getReportTemplateApi = id =>
  secureCall(`/report-templates/${id}`, {
    method: 'GET',
  }).then(json);

export const getReportTemplatesApi = () =>
  secureCall(`/report-templates`, {
    method: 'GET',
  }).then(json);

export const updateReportTemplateApi = (id, update) =>
  secureCall(`/report-templates/${id}`, {
    method: 'PATCH',
    headers: JSON_HEADER,
    body: JSON.stringify(update),
  }).then(json);

export const deleteReportTemplateApi = id =>
  secureCall(`/report-templates/${id}`, {
    method: 'DELETE',
  });

export const getReportSettingsApi = () =>
  secureCall('/report-settings/me').then(json);

export const updateReportSettingApi = (id, update) =>
  secureCall(`/report-settings/${id}`, {
    method: 'PATCH',
    headers: JSON_HEADER,
    body: JSON.stringify(update),
  }).then(json);

export const uploadLogoApi = (id, logo) => {
  return secureCall(`/report-settings/${id}`, {
    method: 'PATCH',
    body: logo,
  }).then(json);
};

export const createReportApi = templateId => {
  return secureCall(`/reports`, {
    method: 'POST',
    headers: JSON_HEADER,
    body: JSON.stringify({ templateId }),
  }).then(json);
};

export const sendReportApi = reportId => {
  return secureCall(`/reports/${reportId}/send`, {
    method: 'POST',
  }).then(json);
};

export const fetchReportApi = reportId => {
  return secureCall(`/reports/${reportId}`, {
    method: 'GET',
  }).then(json);
};

export const fetchReportsApi = () => {
  return secureCall(`/reports`, {
    method: 'GET',
  }).then(json);
};

export const deleteReportApi = reportId => {
  return secureCall(`/reports/${reportId}`, {
    method: 'DELETE',
  });
};

export const finalizeReportApi = (reportId, report) => {
  return secureCall(`/reports/${reportId}`, {
    method: 'PATCH',
    body: report,
  }).then(json);
};

export const updateReportApi = (reportId, values) => {
  return secureCall(`/reports/${reportId}`, {
    method: 'PATCH',
    headers: JSON_HEADER,
    body: JSON.stringify(values),
  }).then(json);
};

export const createReportVersionApi = (
  reportId,
  diaryIds,
  { widgets, locale, pages }
) => {
  return secureCall(`/reports/${reportId}/versions`, {
    method: 'POST',
    headers: JSON_HEADER,
    body: JSON.stringify({ diaryIds, widgets, locale, pages }),
  }).then(json);
};

export const createTemplateVersionApi = (
  templateId,
  { widgets, locale, pages }
) => {
  return secureCall(`/report-templates/${templateId}/versions`, {
    method: 'POST',
    headers: JSON_HEADER,
    body: JSON.stringify({ widgets, locale, pages }),
  }).then(json);
};

export default {
  createReportTemplateApi,
  getReportTemplateApi,
  getReportTemplatesApi,
  updateReportTemplateApi,
};
