import React from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import H2 from 'components/H2';
import { TextField } from 'components/forms/Textfield';
import { Button } from 'components/material/Button';
import Spacer from 'components/Spacer';
import RightAlign from 'components/RightAlign';

const messages = defineMessages({
  title: {
    id: 'beginPasswordReset.title',
    defaultMessage: 'Find your Keenoa account password.',
  },
  emailPlaceholder: {
    id: 'global.email',
    defaultMessage: 'Email',
  },
  sendBtn: {
    id: 'beginPasswordReset.sendBtn',
    defaultMessage: 'Search',
  },
  wrongEmail: {
    id: 'beginPasswordReset.wrongEmail',
    defaultMessage: "We couldn't find your account with this email.",
  },
  sentTitle: {
    id: 'beginPasswordReset.sentTitle',
    defaultMessage: 'Check you Email.',
  },
  sentMessage: {
    id: 'beginPasswordReset.sentMessage',
    defaultMessage:
      "We've sent an email to {email}. Click the link in the email to reset you password.",
  },
  wrongEmailText: {
    id: 'beingPasswordReset.wrongEmailText',
    defaultMessage: 'Please try searching for your email again.',
  },
});

const ErrorH2 = styled(H2)`
  color: ${props => props.theme.palette.error};
`;

class BeginPasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: '' };
  }

  onChangeEmail = email => {
    this.setState({ email: email });
  };

  render() {
    const { intl, sent, error, onSubmit } = this.props;
    const { formatMessage } = intl;
    const email = this.state.email;
    return sent ? (
      <div>
        <H2>
          <FormattedMessage {...messages.sentTitle} />
        </H2>
        <p>
          <FormattedMessage
            {...messages.sentMessage}
            values={{
              email: <b>{email}</b>,
            }}
          />
        </p>
      </div>
    ) : (
      <form
        onKeyPress={e => {
          if (e.key === 'Enter') {
            onSubmit(email);
          }
        }}
        onSubmit={e => {
          e.preventDefault();
          onSubmit(email);
        }}
        autoComplete="on"
      >
        {!error ? (
          <H2>
            <FormattedMessage {...messages.title} />
          </H2>
        ) : (
          <div>
            <ErrorH2>
              <FormattedMessage {...messages.wrongEmail} />
            </ErrorH2>
            <p>
              <FormattedMessage {...messages.wrongEmailText} />
            </p>
          </div>
        )}
        <Spacer spacing="lg" />
        <TextField
          outlined
          name="email"
          autoComplete="email"
          label={formatMessage(messages.emailPlaceholder)}
          value={email}
          onChange={e => this.onChangeEmail(e.target.value)}
          style={{ width: '100%' }}
        />
        <Spacer spacing="lg" />
        <RightAlign>
          <Button type="submit" unelevated disabled={!email}>
            <FormattedMessage {...messages.sendBtn} />
          </Button>
        </RightAlign>
      </form>
    );
  }
}

export default injectIntl(BeginPasswordReset);
