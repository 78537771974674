import RevokedModal from './RevokedModal';
import { connect } from 'react-redux';
import {
  closeModal,
  getModalOpen,
  MODAL_TYPES,
} from 'redux/modules/modals/modals';

const mapStateToProps = state => ({
  isOpen: getModalOpen(state, MODAL_TYPES.REVOKED_CLIENT_ERROR),
});

const mapDispatchToProps = dispatch => ({
  onExit: () => dispatch(closeModal(MODAL_TYPES.REVOKED_CLIENT_ERROR)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RevokedModal);
