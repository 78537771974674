import React from 'react';
import { useRef, useContext } from 'react';
import { FixedSizeList } from 'react-window';
import {
  DataTable,
  DataTableContent,
  DataTableBody,
} from 'components/material/DataTable';

/** Context for cross component communication */
const VirtualTableContext = React.createContext({
  header: <></>,
  footer: <></>,
});

/** The virtual table. It basically accepts all of the same params as the original FixedSizeList.*/
function VirtualTable({ row, header, footer, ...rest }) {
  const listRef = useRef();

  return (
    <VirtualTableContext.Provider value={{ header, footer }}>
      <FixedSizeList
        {...rest}
        innerElementType={Inner}
        onItemsRendered={props => {
          // Call the original callback
          rest.onItemsRendered && rest.onItemsRendered(props);
        }}
        ref={el => (listRef.current = el)}
      >
        {row}
      </FixedSizeList>
    </VirtualTableContext.Provider>
  );
}

/**
 * The Inner component of the virtual list. This is the "Magic".
 * Capture what would have been the top elements position and apply it to the table.
 * Other than that, render an optional header and footer.
 **/
const Inner = React.forwardRef(function Inner({ children, ...rest }, ref) {
  const { header } = useContext(VirtualTableContext);
  return (
    <div {...rest} ref={ref}>
      <DataTable
        style={{
          top: 0,
          position: 'sticky',
          width: '100%',
          border: 'none',
          cursor: 'pointer',
        }}
        className="no-scrollbar"
      >
        <DataTableContent>
          {header}
          <DataTableBody>{children}</DataTableBody>
        </DataTableContent>
      </DataTable>
    </div>
  );
});

export default VirtualTable;
