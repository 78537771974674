import { connect } from 'react-redux';
import {
  getMealsSmallImagesSortedByCalories,
  getMealsSortedCalories,
} from 'redux/modules/meals/meals.selectors';
import MealMosaic from './MealMosaic';

const mapStateToProps = (state, { ids }) => ({
  pictures: getMealsSmallImagesSortedByCalories(state, ids),
  calories: getMealsSortedCalories(state, ids),
  ids: null, // We dont need it below and it was causing useless re-rendering
});

export default connect(mapStateToProps)(MealMosaic);
