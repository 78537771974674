import React from 'react';
import times from 'lodash/times';
import styled from 'styled-components';
import { ONE_HOUR_HEIGHT } from './DayScale';

const Hour = styled.div`
  height: ${ONE_HOUR_HEIGHT - 1}px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

class DayGrid extends React.PureComponent {
  render() {
    const { hoursCount } = this.props;
    const hours = times(hoursCount, i => i);
    return (
      <div>
        {hours.map(h => (
          <Hour key={h} />
        ))}
      </div>
    );
  }
}

export default DayGrid;
