import React, { Component } from 'react';
import SearchBar from './SearchBar';
import { connect } from 'react-redux';
import { clientsOperations, clientsSelectors } from 'redux/modules/clients';

class SearchBarContainer extends Component {
  componentDidMount() {
    // We clear the search field.
    this.props.onInputChange('');
  }
  render() {
    return <SearchBar {...this.props} />;
  }
}

const mapStateToProps = state => ({
  value: clientsSelectors.getSearchString(state),
});

const mapDispatchToProps = dispatch => ({
  onInputChange: value => dispatch(clientsOperations.updateSearchString(value)),
});

SearchBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBarContainer);

export default SearchBarContainer;
