import React, { useEffect } from 'react';
import {
  DataTable,
  DataTableContent,
  DataTableRow,
  DataTableHead,
  DataTableHeadCell,
  DataTableCell,
  HoverableDataTableBody,
} from 'components/material/DataTable';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import FormattedDate from 'components/FormattedDate';
import Checkbox from 'components/forms/Checkbox';
import { useRouteMatch, useHistory, Switch, Route } from 'react-router-dom';
import FullPage from 'components/FullPage';
import { useState } from 'react';
import HorizontalLoader from 'components/loaders/HorizontalLoader';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SORT_ORDERS,
  REPORTS_SORT_COLUMNS,
  REPORT_FILTERS,
} from 'constants/index.js';
import sortBy from 'lodash/sortBy';
import ReportEditor from '../../Export/ReportEditor';
import ReportLoader from '../../Export/ReportLoader';
import { Button } from 'components/material/Button';
import SearchBar from 'components/SearchBar';
import CreateReportModal from '../modals/CreateReportModal';
import styled from 'styled-components';
import { useVisibleElements } from 'utils/hooks';
import NoReports from './NoReports';
import Spacer from 'components/Spacer';
import ReportActions from './ReportsActions';
import ReportCheckbox from './ReportCheckbox';
import ReportsCheckbox from './ReportsCheckbox';
import ReportsFiltering from './ReportsFiltering';
import { Typography } from 'components/material/Typography';
import { PageWrapper } from 'components/PageWrapper';

const messages = defineMessages({
  title: { id: 'reports.title' },

  finalized: { id: 'reports.status.finalized' },
  unfinalized: { id: 'reports.status.unfinalized' },
  newReport: { id: 'reports.newReport' },

  search: { id: 'reports.search.placeholder' },
});

const ActionsWrapper = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
`;

const SORT_COLUMNS = REPORTS_SORT_COLUMNS;
const COLUMNS = [
  {
    key: SORT_COLUMNS.TITLE,
    label: { defaultMessage: 'Title', id: 'reports.columns.title' },
    props: { alignStart: true, style: { minWidth: 175 } },
  },
  {
    key: SORT_COLUMNS.STATUS,
    label: { defaultMessage: 'Status', id: 'reports.columns.status' },
    props: { alignStart: true, style: { width: 130 } },
  },
  {
    key: SORT_COLUMNS.CREATION_DATE,
    label: { defaultMessage: 'Creation date', id: 'reports.columns.date' },
    props: { alignStart: true, style: { width: 160 } },
  },
  {
    key: 'sent',
    label: { defaultMessage: 'Sent', id: 'reports.columns.sent' },
    props: {
      alignStart: true,
      style: { width: 130 },
    },
  },
  {
    key: 'downloaded',
    label: { defaultMessage: 'Downloaded', id: 'reports.columns.downloaded' },
    props: {
      alignStart: true,
      style: { width: 100 },
    },
  },
];

function _isFinalized(report) {
  return !!report.pdf_url;
}
export default function(props) {
  const match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={`${match.path}`}>
          <Reports {...props} />
        </Route>
        <Route path={`${match.path}/:reportId`}>
          <FullPage>
            <ReportEditor />
            <ReportLoader />
          </FullPage>
        </Route>
      </Switch>
    </div>
  );
}

function ReportRow(props) {
  const {
    id,
    title,
    creation_date,
    last_download_timestamp,
    last_sent_timestamp,
    pdf_url,
  } = props;
  const match = useRouteMatch();
  const history = useHistory();
  const theme = useTheme();

  const isFinalized = _isFinalized(props);

  const onClick = () => {
    if (pdf_url) {
      window.open(pdf_url);
    } else {
      history.push(`${match.url}/${id}`);
    }
  };

  const renderStatus = () => {
    const { success, warning } = theme.palette;
    let color;
    let message;
    let icon;
    if (isFinalized) {
      icon = 'check-circle';
      color = success;
      message = messages.finalized;
    } else {
      icon = 'exclamation-circle';
      color = warning;
      message = messages.unfinalized;
    }
    return (
      <div>
        <FontAwesomeIcon
          icon={['fas', icon]}
          style={{ marginRight: 5 }}
          color={color}
        />
        <FormattedMessage {...message} />
      </div>
    );
  };

  return (
    <DataTableRow key={id} onClick={onClick}>
      <DataTableCell alignEnd>
        <ReportCheckbox id={id} />
      </DataTableCell>
      <DataTableCell alignStart style={{ minWidth: 175 }}>
        {title}
      </DataTableCell>
      <DataTableCell alignStart>{renderStatus()}</DataTableCell>
      <DataTableCell alignStart>
        <FormattedDate date={new Date(creation_date)} />
      </DataTableCell>
      <DataTableCell alignStart>
        <Checkbox disabled checked={!!last_sent_timestamp} />
      </DataTableCell>
      <DataTableCell alignStart>
        <Checkbox disabled checked={!!last_download_timestamp} />
      </DataTableCell>
    </DataTableRow>
  );
}

function Reports({
  reports,
  reportsStatus,
  fetchReports,
  fetchTemplates,

  sortColumn,
  sortOrder,
  selectSortColumn,
  invertSortOrder,
  filter,

  createReport,
}) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const intl = useIntl();

  let visibleReports = useVisibleElements(search, reports, ['title']);
  if (filter === REPORT_FILTERS.FINALIZED) {
    visibleReports = visibleReports.filter(r => !!_isFinalized(r));
  } else if (filter === REPORT_FILTERS.CURRENT) {
    visibleReports = visibleReports.filter(r => !_isFinalized(r));
  }

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await fetchReports();
      setLoading(false);
      fetchTemplates();
    };

    load();
  }, []);

  const renderHeadCell = ({ key, label, props, noSort }) => (
    <DataTableHeadCell
      key={key}
      style={{ textTransform: 'uppercase', ...props.style }}
      onSortChange={() => {
        if (noSort) return;
        if (sortColumn !== key) return selectSortColumn(key);

        invertSortOrder();
      }}
      sort={
        sortColumn === key
          ? sortOrder === SORT_ORDERS.ASCENDING
            ? 1
            : -1
          : noSort
          ? undefined
          : null
      }
      {...props}
    >
      <FormattedMessage {...label} />
    </DataTableHeadCell>
  );

  const renderLoader = () => (
    <div style={{ flex: 1 }}>
      <HorizontalLoader colspan="100" />
    </div>
  );

  const renderBody = sortedReports => {
    return (
      <HoverableDataTableBody>
        {sortedReports.map(r => (
          <ReportRow {...r} key={r.id} />
        ))}
      </HoverableDataTableBody>
    );
  };

  const renderFilters = () => {
    return (
      <div style={{ position: 'relative' }}>
        <ReportsFiltering />
        <ActionsWrapper>
          <ReportActions />
        </ActionsWrapper>
      </div>
    );
  };

  const renderContent = () => {
    if (loading && !reports.length) return renderLoader();

    let sortedReports = visibleReports;
    if (sortColumn) {
      sortedReports = sortBy(visibleReports, sortColumn);
      if (sortOrder !== SORT_ORDERS.ASCENDING) {
        sortedReports = sortedReports.reverse();
      }
    }

    if (!sortedReports.length)
      return <NoReports onCreateReport={createReport} />;

    return (
      <DataTable
        stickyRows={1}
        stickyColumns={0}
        style={{
          border: 'none',
          maxHeight: 'calc(100vh - 158px)',
          width: '100%',
        }}
        className="no-scrollbar"
      >
        <DataTableContent style={{ width: '100%' }}>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCell style={{ width: 70 }}>
                <ReportsCheckbox ids={visibleReports.map(x => x.id)} />
              </DataTableHeadCell>
              {COLUMNS.map(renderHeadCell)}
            </DataTableRow>
          </DataTableHead>
          {renderBody(sortedReports)}
        </DataTableContent>
      </DataTable>
    );
  };

  return (
    <PageWrapper>
      <Typography use="headline5" rank="primary" tag="div" spacing="lg">
        <FormattedMessage {...messages.title} />
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <SearchBar
          value={search}
          onInputChange={value => setSearch(value)}
          placeholder={intl.formatMessage(messages.search)}
        />
        <Spacer spacing="xl" />
        <div>
          <Button
            unelevated
            onClick={createReport}
            style={{ whiteSpace: 'nowrap' }}
          >
            <FormattedMessage {...messages.newReport} />
          </Button>
        </div>
      </div>

      {renderFilters()}
      {renderContent()}
      <CreateReportModal />
    </PageWrapper>
  );
}
