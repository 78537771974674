import React from 'react';
import { Typography as T } from '@rmwc/typography';
import '@rmwc/typography/styles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const SPACINGS = {
  none: 'none',
  small: 'sm',
  medium: 'md',
  large: 'lg',
};

const SIZE = {
  [SPACINGS.none]: 0,
  [SPACINGS.small]: 4,
  [SPACINGS.medium]: 8,
  [SPACINGS.large]: 16,
};

const Typo = styled(T)`
  color: ${x => (x.rank ? x.theme.palette.text[x.rank] : '')};
  padding: ${x => SIZE[x.spacing]}px 0;
  ${x => (x.center ? 'text-align: center;' : '')}
`;
export const Typography = injectIntl(
  ({
    use,
    rank,
    style,
    className,
    message,
    intl,
    children,
    tag,
    center,
    spacing = SPACINGS.none,
  }) => {
    return (
      <Typo
        use={use}
        rank={rank}
        style={style}
        tag={tag}
        spacing={spacing}
        className={className}
        center={center}
      >
        {message ? intl.formatMessage(message) : null}
        {children}
      </Typo>
    );
  }
);
Typography.propTypes = {
  /** The type of typography style/size to use (see. https://rmwc.io/typography). */
  use: PropTypes.oneOf([
    'headline1',
    'headline2',
    'headline3',
    'headline4',
    'headline5',
    'headline6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
  ]),

  /** The typography weight */
  rank: PropTypes.oneOf(['primary', 'midPrimary', 'secondary', 'tertiary']),

  /** If it should include additional spacing */
  spacing: PropTypes.oneOf(Object.values(SPACINGS)),

  /** Set to true to center the text */
  center: PropTypes.bool,
};
export default Typography;
