import times from 'lodash/times';
import { defineMessages } from 'react-intl';

/** WARNING: It is important that these IDS do not change as
 * they are also hardcoded into the mobile app. */

export const QUESTIONS_BEFORE = ['questions_why', 'questions_hungriness'];

export const QUESTIONS_AFTER = [
  'questions_fullness',
  'questions_how',
  'questions_feel',
];

export const ANSWER_TO_I18N = defineMessages({
  answers_why_0: { id: 'answers.why.0' },
  answers_why_1: { id: 'answers.why.1' },
  answers_why_2: { id: 'answers.why.2' },
  answers_why_3: { id: 'answers.why.3' },
  answers_why_4: { id: 'answers.why.4' },
  answers_why_5: { id: 'answers.why.5' },
  answers_why_6: { id: 'answers.why.6' },
  answers_why_7: { id: 'answers.why.7' },
  answers_why_8: { id: 'answers.why.8' },
  //////////////////////////////////////
  answers_how_0: { id: 'answers.how.0' },
  answers_how_1: { id: 'answers.how.1' },
  answers_how_2: { id: 'answers.how.2' },
  ///////////////////////////////////////
  answers_feel_0: { id: 'answers.feel.0' },
  answers_feel_1: { id: 'answers.feel.1' },
  answers_feel_2: { id: 'answers.feel.2' },
  answers_feel_3: { id: 'answers.feel.3' },
  ///////////////////////////////////////
  answers_hungriness_0: { id: 'answers.hungriness.0' },
  answers_hungriness_1: { id: 'answers.hungriness.1' },
  answers_hungriness_2: { id: 'answers.hungriness.2' },
  answers_hungriness_3: { id: 'answers.hungriness.3' },
  answers_hungriness_4: { id: 'answers.hungriness.4' },
  ///////////////////////////////////////////////////////
  answers_fullness_0: { id: 'answers.fullness.0' },
  answers_fullness_1: { id: 'answers.fullness.1' },
  answers_fullness_2: { id: 'answers.fullness.2' },
  answers_fullness_3: { id: 'answers.fullness.3' },
  answers_fullness_4: { id: 'answers.fullness.4' },
});

export const QUESTIONS_TO_I18N = defineMessages({
  questions_why: { id: 'questions.why' },
  questions_how: { id: 'questions.how' },
  questions_feel: { id: 'questions.feel' },
  questions_hungriness: { id: 'questions.hungriness' },
  questions_fullness: { id: 'questions.fullness' },
});

export const WHY = {
  id: 'questions_why',
  options: times(8, i => `answers_why_${i}`),
};

export const HOW = {
  id: 'questions_how',
  options: times(3, i => `answers_how_${i}`),
};

export const FEEL = {
  id: 'questions_feel',
  options: times(4, i => `answers_feel_${i}`),
};

export const HUNGRINESS = {
  id: 'questions_hungriness',
  options: [
    `answers_hungriness_0`,
    `answers_hungriness_3`,
    `answers_hungriness_4`,
    `answers_hungriness_1`,
    `answers_hungriness_2`,
  ],
};

export const FULLNESS = {
  id: 'questions_fullness',
  options: [
    `answers_fullness_0`,
    `answers_fullness_3`,
    `answers_fullness_4`,
    `answers_fullness_1`,
    `answers_fullness_2`,
  ],
};
