import { getReportVersion, createReportVersion } from '../../reportVersions';
import {
  loadStructure,
  loadDiaryIds,
  stageTemplate,
} from './reportView.actions';
import {
  getCurrentReportId,
  getExportDiaryIds,
  getCurrentStructure,
  getStagedTemplateId,
} from '.';
import { getReport } from '../../reports';
import {
  createTemplateVersion,
  getTemplateVersion,
} from '../../templateVersions';
import get from 'lodash/get';
import { createReportTemplate } from '../../reportTemplates/reportTemplates.actions';
import { getTemplate } from '../../reportTemplates';
import { fetchFoodDiary } from '../../foodDiaries/foodDiaries.operations';
import {
  getCurrentClientId,
  getFictiveClientId,
} from '../../clients/clients.selectors';

export const loadReport = reportId => (dispatch, getState) => {
  const state = getState();
  const report = getReport(state, reportId);
  const version = getReportVersion(state, report.version);
  if (!version || !version.structure) return;

  dispatch(loadStructure(version.structure));
  return dispatch(loadCompleteDiaryIds(version.diary_ids));
};

export const loadCompleteDiaryIds = diaryIds => (dispatch, getState) => {
  dispatch(loadDiaryIds(diaryIds));
  const clientId =
    getCurrentClientId(getState()) || getFictiveClientId(getState());
  return Promise.all(
    diaryIds.map(id => dispatch(fetchFoodDiary(id, clientId)))
  );
};

export const saveCurrentExport = () => (dispatch, getState) => {
  const state = getState();
  const reportId = getCurrentReportId(state);
  const diaryIds = getExportDiaryIds(state);
  const structure = getCurrentStructure(state);
  return dispatch(createReportVersion(reportId, diaryIds, structure));
};

export const generateTemplate = (
  title,
  description,
  emoji
) => async dispatch => {
  const res = await dispatch(
    createReportTemplate({ title, emoji, description })
  );
  const templateId = get(res, 'response.result');
  await dispatch(saveCurrentTemplate());

  return templateId;
};

export const loadTemplate = templateId => (dispatch, getState) => {
  const state = getState();
  const template = getTemplate(state, templateId);
  dispatch(stageTemplate(templateId));

  if (!template) return;
  const version = getTemplateVersion(state, template.version);
  if (!version || !version.structure) return;

  dispatch(loadStructure(version.structure));
};

export const saveCurrentTemplate = () => (dispatch, getState) => {
  const state = getState();
  const templateId = getStagedTemplateId(state);
  const structure = getCurrentStructure(state);
  return dispatch(createTemplateVersion(templateId, structure));
};
