import React from 'react';
import Typography from 'components/material/Typography';
import { TextField } from 'components/forms/Textfield';
import Spacer, { SPACINGS } from 'components/Spacer';
import { Radio } from 'components/forms/Radio';
import { FractionPicker } from 'components/FractionPicker';
import { defineMessages, useIntl } from 'react-intl';
import { SERVING_QUANTITY_UNITS } from './constants';

const messages = defineMessages({
  name: { id: 'foods.create.name' },
  addName: { id: 'foods.create.addName' },
  save: { id: 'foods.create.save' },

  size: { id: 'foods.create.size' },
  serving: { id: 'foods.create.serving' },
  amount: { id: 'foods.create.amount' },

  allowClients: { id: 'foods.create.allowClients' },
});

export function PickServingSize({
  amount,
  setAmount,
  fraction,
  setFraction,
  name,
  setName,
  quantity,
  setQuantity,
  quantityUnit,
  setQuantityUnit,
}) {
  const intl = useIntl();

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <TextField
        value={amount}
        onChange={e => setAmount(e.target.value)}
        name="size"
        type="number"
        style={{ width: 80 }}
        className="kmdc_compact"
      />
      <Spacer spacing={SPACINGS.medium} />
      <FractionPicker
        value={fraction}
        onChange={setFraction}
        outlined={false}
        className="kmdc_compact"
      />
      <Spacer spacing={SPACINGS.medium} />
      <TextField
        required
        value={name}
        onChange={e => setName(e.target.value)}
        name="serving"
        className="kmdc_compact"
        placeholder={intl.formatMessage(messages.serving)}
      />
      <Spacer spacing={SPACINGS.medium} />
      <Typography use="headline5" rank="secondary" tag="div">
        (
      </Typography>
      <TextField
        value={quantity}
        style={{ width: 80 }}
        onChange={e => setQuantity(e.target.value)}
        name="amount"
        className="kmdc_compact"
        placeholder={intl.formatMessage(messages.amount)}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Radio
          value={SERVING_QUANTITY_UNITS.G}
          checked={quantityUnit === SERVING_QUANTITY_UNITS.G}
          onChange={e => setQuantityUnit(e.target.value)}
        >
          {SERVING_QUANTITY_UNITS.G}
        </Radio>
        <Spacer spacing={SPACINGS.medium} />
        <Radio
          value={SERVING_QUANTITY_UNITS.ML}
          checked={quantityUnit === SERVING_QUANTITY_UNITS.ML}
          onChange={e => setQuantityUnit(e.target.value)}
        >
          {SERVING_QUANTITY_UNITS.ML}
        </Radio>
      </div>

      <Typography use="headline5" rank="secondary" tag="div">
        )
      </Typography>
    </div>
  );
}
