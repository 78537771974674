import { connect } from 'react-redux';
import { getMealEntriesWithCalorie } from 'redux/modules/meals/meals.selectors';
import Entries from './Entries';
import {
  openEditEntryModal,
  deleteEntry,
} from 'redux/modules/entries/entries.operations';

const mapStateToProps = (state, { mealId }) => ({
  entries: getMealEntriesWithCalorie(state, mealId),
});

const mapDispatchToProps = dispatch => ({
  editEntry: (entryId, mealId) => dispatch(openEditEntryModal(entryId, mealId)),
  deleteEntry: (entryId, mealId) => dispatch(deleteEntry(entryId, mealId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Entries);
