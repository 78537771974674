import { connect } from 'react-redux';
import {
  deleteReport,
  updateReport,
  sendReport,
  downloadReport,
} from 'redux/modules/reports';
import ReportActions from './ReportActions';
import {
  getSelectedReportsActions,
  getSelectedReportsCount,
  onReportAction,
  getSelectedReportsIds,
  unselectAllReports,
} from 'redux/modules/UI/reportsView';

const mapStateToProps = state => ({
  selectCount: getSelectedReportsCount(state),
  actions: getSelectedReportsActions(state),
  reportIds: getSelectedReportsIds(state),
});

const mapDispatchToProps = (dispatch, { reportId }) => ({
  onDelete: () => dispatch(deleteReport(reportId)),
  onDownload: () => {
    dispatch(downloadReport());
    dispatch(
      updateReport(reportId, {
        last_download_timestamp: new Date(),
      })
    );
  },
  onSend: () => dispatch(sendReport(reportId)),

  onAction: (action, targetedReportId, history) =>
    dispatch(onReportAction(action, targetedReportId, history)),

  unselectAll: () => dispatch(unselectAllReports()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportActions);
