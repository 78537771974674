import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import CreateReportModal from '../../../../modals/CreateReportModal';
import { Button } from 'components/material/Button';
import { makeCustomIcon } from '../../../../../../../utils/misc';

const messages = defineMessages({
  exportName: { id: 'toolbar.button.export' },
});

export default React.memo(function({ openTemplates }) {
  return (
    <>
      <Button
        icon={makeCustomIcon(['far', 'file-pdf'])}
        rank="midPrimary"
        dense
        onClick={openTemplates}
      >
        <FormattedMessage {...messages.exportName} />
      </Button>
      <CreateReportModal />
    </>
  );
});
