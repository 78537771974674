import { secureCall, json } from './api';

export const searchFood = (query, clientId, size = 50, offset = 0) =>
  secureCall('foods/search', {
    method: 'GET',
    queryParams: {
      q: query,
      size,
      offset,
    },
  }).then(json);

export const fetchFoodApi = foodId =>
  secureCall(`/foods/${foodId}`, {
    method: 'GET',
  }).then(json);

export default {
  searchFood,
  fetchOne: fetchFoodApi,
};
