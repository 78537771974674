import React from 'react';
import Modal from 'components/Modal';
import { useSelector, useDispatch } from 'react-redux';
import {
  MODAL_TYPES,
  getModalOpen,
  closeModal,
} from 'redux/modules/modals/modals';
import H2 from 'components/H2';
import {
  FormattedMessage,
  defineMessages,
  FormattedHTMLMessage,
  injectIntl,
  IntlProvider,
} from 'react-intl';
import {
  getFirstName,
  getFullName,
} from 'redux/modules/account/account.selectors';
import { Button } from 'components/material/Button';
import Typography from 'components/material/Typography';
import Spacer from 'components/Spacer';
import * as i18n from '../../../../i18n';
import styled from 'styled-components';
import { notify } from 'react-notify-toast';

const messages = defineMessages({
  title: { id: 'newClient.preview.title' },
  to: { id: 'newClient.preview.to' },
  subject: { id: 'newClient.preview.subject' },
  subjectRow: { id: 'newClient.preview.subjectRow' },
  from: { id: 'newClient.preview.from' },

  hi: { id: 'newClient.preview.hi' },
  first: { id: 'newClient.preview.first' },
  button: { id: 'newClient.preview.button' },
  second: { id: 'newClient.preview.second' },
  small: { id: 'newClient.preview.small' },

  acceptHelp: { id: 'newClient.preview.acceptHelp' },
  invite: { id: 'newClient.preview.invite' },
});

const TopLineWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const AdressBox = styled.div`
  background-color: #ddd;
  border-radius: 8px;
  padding: 1px 8px;
`;
const EmailBox = styled.div`
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 10px;
  max-height: 50vh;
  overflow: auto;
  color: ${x => x.theme.palette.text.primary};
`;

function PreviewModal({ email, intl, onInvite, locale }) {
  const dispatch = useDispatch();
  const isOpen = useSelector(s =>
    getModalOpen(s, MODAL_TYPES.NEW_CLIENT_PREVIEW)
  );
  const firstName = useSelector(getFirstName);
  const fullName = useSelector(getFullName);
  const onClose = () => dispatch(closeModal(MODAL_TYPES.NEW_CLIENT_PREVIEW));

  const onAcceptClick = () => {
    notify.show(intl.formatMessage(messages.acceptHelp), 'info');
  };

  const onInviteClick = () => {
    onInvite();
    onClose();
  };

  const intlData = {
    locale,
    messages: i18n[locale],
    key: locale, // Key is present to re-render on locale change
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ maxWidth: '600px', minWidth: '500px' }}
    >
      <H2>
        <FormattedMessage {...messages.title} />
      </H2>
      <Spacer spacing="lg" />
      <TopLineWrapper>
        <Typography use="overline" rank="secondary">
          <FormattedMessage {...messages.to} />
        </Typography>
        <AdressBox>
          <Typography use="body2" rank="primary">
            {email}
          </Typography>
        </AdressBox>
      </TopLineWrapper>
      <TopLineWrapper>
        <Typography use="overline" rank="secondary">
          <FormattedMessage {...messages.from} />
        </Typography>
        <AdressBox>
          <Typography use="body2" rank="primary">
            support@keenoa.co
          </Typography>
        </AdressBox>
      </TopLineWrapper>
      <TopLineWrapper>
        <Typography use="overline" rank="secondary">
          <FormattedMessage {...messages.subject} />
        </Typography>
        <IntlProvider {...intlData}>
          <Typography use="body2" rank="primary">
            <FormattedMessage
              {...messages.subjectRow}
              values={{ dietitianName: fullName }}
            />
          </Typography>
        </IntlProvider>
      </TopLineWrapper>
      <IntlProvider {...intlData}>
        <EmailBox>
          <p style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
            <FormattedMessage {...messages.hi} />
          </p>
          <p>
            <FormattedMessage
              {...messages.first}
              values={{ dietitianName: firstName }}
            />
          </p>
          <div style={{ display: 'flex' }}>
            <Button
              onClick={onAcceptClick}
              unelevated
              dense
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <FormattedMessage {...messages.button} />
            </Button>
          </div>

          <p style={{ whiteSpace: 'pre-line' }}>
            <FormattedHTMLMessage {...messages.second} />
          </p>
          <p
            style={{
              borderTop: '1px solid rgba(0,0,0,0.1)',
              paddingTop: 13,
              fontSize: '0.75rem',
            }}
          >
            <FormattedMessage {...messages.small} values={{ email }} />
          </p>
        </EmailBox>
      </IntlProvider>
      <Spacer spacing="md" />
      {onInvite ? (
        <Button onClick={onInviteClick} unelevated style={{ float: 'right' }}>
          <FormattedMessage {...messages.invite} />
        </Button>
      ) : null}
    </Modal>
  );
}
export default injectIntl(PreviewModal);
