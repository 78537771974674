import React from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import TimeLine from './Timeline';
import Calendar from '../../components/Calendar';
import WhiteFrame from 'components/WhiteFrame';
import DayChart from './DayChart';
import EditEntryModal from '../../modals/EditEntryModal';
import SearchFoodModal from '../../modals/SearchFoodModal';
import MindfulHelpModal from './MindfulHelpModal';
import MealGroup from './MealGroup';
import Toolbar from '../../components/Toolbar';
import CompareCharts from './CompareCharts';

const messages = defineMessages({
  noDiaryAvailable: {
    id: 'foodDiary.noDiaryAvailableTitle',
    defaultMessage:
      'No food diary yet! Once your clients enters meals, you’ll see them here.',
  },
});

const SidePanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: flex-start;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  padding-bottom: 200px;
`;

const FoodDiarieslist = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: stretch;
  max-width: 800px;
  margin: 0 auto;
`;

const NoDiary = styled(WhiteFrame)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 260px;
  width: 680px;
`;
const NoDiaryText = styled.div`
  padding: 0 20%;
`;

class VisualLog extends React.PureComponent {
  renderEmptyState = () => (
    <NoDiary>
      <NoDiaryText>
        <FormattedMessage {...messages.noDiaryAvailable} />
      </NoDiaryText>
    </NoDiary>
  );

  renderMealGroups = () => {
    const { mealGroupCounts } = this.props;
    return mealGroupCounts.map((count, i) => {
      return (
        <React.Fragment key={i}>
          <TimeLine pos={i} min={15} max={300} />
          <MealGroup pos={i} count={count} />
        </React.Fragment>
      );
    });
  };

  renderContent = () => {
    const { hasNoFoodDiary, compareMode } = this.props;

    if (!compareMode) {
      return (
        <FoodDiarieslist space={hasNoFoodDiary}>
          {hasNoFoodDiary ? this.renderEmptyState() : this.renderMealGroups()}
        </FoodDiarieslist>
      );
    } else {
      return <CompareCharts />;
    }
  };

  render() {
    return (
      <Wrapper>
        <div style={{ flex: 1 }}>
          <Toolbar />
          {this.renderContent()}
        </div>
        <SidePanel>
          <Calendar />
          <DayChart />
        </SidePanel>
        <EditEntryModal />
        <SearchFoodModal />
        <MindfulHelpModal />
      </Wrapper>
    );
  }
}

export default VisualLog;
