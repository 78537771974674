import React from 'react';
import { defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentClientShortName } from 'redux/modules/clients/clients.selectors';
import { getTargetDiaryId } from 'redux/modules/foodDiaries/foodDiaries.selectors';
import LeftNavigation from 'components/LeftNavigation';
import { useRouteMatch } from 'react-router-dom';

const messages = defineMessages({
  menuLinkDetailed: {
    id: 'dashboard.menu2.detailed',
    defaultMessage: 'Detailed',
  },
  menuLinkVisualLog: {
    id: 'dashboard.menu2.visualLog',
    defaultMessage: 'Visual',
  },
  menuLinkBack: {
    id: 'dashboard.menu2.back',
    defaultMessage: 'Back to diary',
  },
  menuLinkDiaries: {
    id: 'dashboard.menu2.diaries',
    defaultMessage: 'Food diary',
  },
  menuLinkProfile: {
    id: 'dashboard.menu2.profile',
    defaultMessage: 'Mobile features',
  },
  menuLinkReports: {
    id: 'dashboard.menu2.reports',
    defaultMessage: 'Reports',
  },
  back: { id: 'client.backTitle' },
});

export default function({ open, setOpen }) {
  const clientName = useSelector(getCurrentClientShortName);
  const diaryId = useSelector(getTargetDiaryId);

  const match = useRouteMatch();
  const detailedUrl = diaryId
    ? `${match.url}/diaries/${diaryId}/detailed`
    : `${match.url}/diaries#detailed`;
  const visualUrl = diaryId
    ? `${match.url}/diaries/${diaryId}/visual`
    : `${match.url}/diaries`;

  const rows = [
    {
      message: messages.menuLinkDiaries,
      icon: 'newspaper',
      links: [
        {
          url: detailedUrl,
          message: messages.menuLinkDetailed,
          icon: 'th',
        },
        {
          url: visualUrl,
          message: messages.menuLinkVisualLog,
          icon: 'image',
        },
      ],
      startOpen: true,
    },
    {
      message: messages.menuLinkProfile,
      icon: 'user-circle',
      to: `${match.url}/profile`,
    },
    {
      message: messages.menuLinkReports,
      icon: 'file-pdf',
      to: `${match.url}/reports`,
    },
  ];

  return (
    <LeftNavigation
      titleMsg={clientName}
      backMsg={messages.back}
      backTo={'/clients'}
      rows={rows}
      open={open}
      setOpen={setOpen}
    />
  );
}
