import { createSelector } from 'reselect';
import values from 'lodash/values';

const _getBilling = state => state.billing;

export const getIsFetchingInvoices = state => state.billing.isFetchingInvoices;

export const getIsFetchingSubscriptions = state =>
  state.billing.isFetchingSubscriptions;

const _getSubscriptionsById = state => state.billing.subscriptionsById;

export const getCurrentSubscription = createSelector(
  _getSubscriptionsById,
  byId => {
    const subscriptions = Object.values(byId);
    /* At the moment, a customer cannotn have more than one subscription so
       the current subscription is always the first (and only) subscription. */
    return subscriptions.length > 0 ? subscriptions[0] : undefined;
  }
);

const _getSourcesById = createSelector(_getBilling, x => x.sourcesById);
export const getSources = createSelector(_getSourcesById, byId => values(byId));
export const getSourcesStatus = createSelector(
  _getBilling,
  x => x.sourcesStatus
);

export default {
  getIsFetchingInvoices,
  getIsFetchingSubscriptions,

  getCurrentSubscription,

  getSources,
};
