import types from './entries.types';

export const addEntry = (entry, mealId) => ({
  type: types.ADD_ENTRY_REQUEST,
  entry,
  mealId,
});

export const addEntrySuccess = (response, mealId) => ({
  type: types.ADD_ENTRY_SUCCESS,
  response,
  mealId,
});

export const addEntryFailure = error => ({
  type: types.ADD_ENTRY_FAILURE,
  error,
});

export const deleteEntry = (entryId, mealId) => ({
  type: types.DELETE_ENTRY_REQUEST,
  entryId,
  mealId,
});

export const deleteEntrySuccess = (entryId, mealId) => ({
  type: types.DELETE_ENTRY_SUCCESS,
  entryId,
  mealId,
});

export const deleteEntryFailure = error => ({
  type: types.DELETE_ENTRY_FAILURE,
  error,
});

export const updateEntry = entry => ({
  type: types.UPDATE_ENTRY_REQUEST,
  entry,
});

export const updateEntrySuccess = response => ({
  type: types.UPDATE_ENTRY_SUCCESS,
  response,
});

export const updateEntryFailure = error => ({
  type: types.UPDATE_ENTRY_FAILURE,
  error,
});

export default {
  addEntry,
  addEntrySuccess,
  addEntryFailure,

  deleteEntry,
  deleteEntrySuccess,
  deleteEntryFailure,

  updateEntry,
  updateEntrySuccess,
  updateEntryFailure,
};
