import React, { useEffect } from 'react';
import Modal from 'components/Modal';
import SearchFood from './SearchFood';

const SearchFoodModal = props => {
  useEffect(() => {
    props.refreshKey();
  }, []);

  return (
    <Modal
      style={{
        overflow: 'visible',
        width: '640px',
        maxWidth: '95wh',
        padding: 0,
      }}
      {...props}
    >
      <SearchFood />
    </Modal>
  );
};

export default SearchFoodModal;
