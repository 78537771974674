import React from 'react';
import styled from 'styled-components';
import { shadowLevels } from 'components/WhiteFrame';
import { imageProxyUrl } from 'api/api';
import { ONE_HOUR_HEIGHT } from '../DayScale';
import { packCircles } from './circlePacker';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import first from 'lodash/first';
import { useDebounce } from 'utils/hooks';
import { Twemoji } from 'react-emoji-render';

const MIN_RADIUS = 23;
const MAX_RADIUS = 43;

const SIZ = ONE_HOUR_HEIGHT * 1.5;
export const THUMBNAIL_SIZE = ONE_HOUR_HEIGHT + 12;
const PADDING_SIZE = 2;
const PicturesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${PADDING_SIZE}px;
  height: ${SIZ}px;
  margin-top: -${0.5 * SIZ}px;
  position: relative;
`;
const PictureWrapper = styled.div`
  position: absolute;
  top: ${x => SIZ * 0.5 - x.circle.c.y - x.circle.r}px;
  left: ${x => x.width * 0.5 - x.circle.c.x - x.circle.r}px;
`;

const makeBaseStyle = x => `
  margin: ${PADDING_SIZE}px;
  transition: all 0.1s ease-in-out;
  border-radius: 50px;
  height: ${x.size}px;
  width: ${x.size}px;
  z-index: 0;
  position: relative;
  background-color: #ecf0f1;

  &:hover {
    z-index: 10;
    transform: scale(${x.zoom});
    border-radius: 6px;
    cursor: pointer;
    box-shadow: ${shadowLevels[3]};
  }
`;

const EmojiWrapper = styled.div`
  ${x => makeBaseStyle(x)};
  background-color: #ecf0f1;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Picture = styled.img`
  ${x => makeBaseStyle(x)};
`;

const calculateRadius = calorie => {
  // This is a simple linear regression, calculated by using the points:
  // 60 cal => radius of 23
  // 500 cal => radius of 43
  const res = 0.045455 * calorie + 20.272727;
  if (res < MIN_RADIUS) return MIN_RADIUS;
  if (res > MAX_RADIUS) return MAX_RADIUS;
  return res;
};

class Pictures extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.width !== nextProps.width) return true;
    if (this.props.pictures !== nextProps.pictures) return true;
    if (this.props.calories !== nextProps.calories) return true;

    return false;
  }

  renderImage = (circle, src, emoji) => {
    if (!circle) return null;

    const size = circle.r * 2;
    const zoom = 150 / size;
    const { width, onClick } = this.props;
    return (
      <PictureWrapper width={width} circle={circle}>
        {src && (
          <Picture
            src={imageProxyUrl(src)}
            size={size}
            zoom={zoom}
            onClick={onClick}
          />
        )}
        {emoji && (
          <EmojiWrapper size={size} zoom={zoom} onClick={onClick}>
            <Twemoji text={emoji.trim()} />
          </EmojiWrapper>
        )}
      </PictureWrapper>
    );
  };

  calculateCircles = () => {
    const { pictures, calories, width } = this.props;

    // We add a small boost when there is just on picture because we
    // know that we have room in the cell. However, we do not want to
    // boost it too much to keep things coherent with other pictures.
    let minMult = pictures.length === 1 ? 1.25 : 1;
    let min = Math.min(minMult * calculateRadius(first(calories)), MAX_RADIUS);
    const max = calculateRadius(last(calories) || MAX_RADIUS);
    const circles = packCircles(width, SIZ, min, max, pictures.length);
    return sortBy(circles, 'r');
  };

  render() {
    const { pictures } = this.props;

    const circles = this.calculateCircles();
    return (
      <PicturesContainer>
        {pictures.map(({ photo, emoji }, i) => (
          <React.Fragment key={photo ? photo : `${emoji}-${i}`}>
            {this.renderImage(circles[i], photo, emoji)}
          </React.Fragment>
        ))}
      </PicturesContainer>
    );
  }
}

function MealMosaic(props) {
  const debouncedWidth = useDebounce(props.width, 500);

  // We debounce the width because circle size calculations are
  // expensive. If someone is shrinking the window, we do not
  // want to calculate the intermediarrry width, we only want
  // to calculate the final width.
  return (
    <Pictures
      onClick={props.onClick}
      pictures={props.pictures}
      calories={props.calories}
      width={debouncedWidth || props.width}
    />
  );
}

export default React.memo(MealMosaic);
