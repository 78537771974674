import React from 'react';
import NewClientButton from './NewClientButton';
import NewParticipantButton from './NewParticipantsButton';
import { useSelector } from 'react-redux';
import { getDietitianIsResearcher } from 'redux/modules/account/account.selectors';

export default function() {
  const isResearcher = useSelector(getDietitianIsResearcher);
  return isResearcher ? <NewParticipantButton /> : <NewClientButton />;
}
