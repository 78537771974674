import React, { useState, useEffect } from 'react';
import Dropdown from 'components/Dropdown';
import OnboardVideos, { ONBOARD_VIDEO_WIDTH } from './OnboardVideos';
import { ONBOARD_IDS } from 'constants/index.js';
import { withTheme } from 'styled-components';
import SimpleTour from 'components/SimpleTour';
import { defineMessages, FormattedMessage } from 'react-intl';
import Typography from 'components/material/Typography';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const messages = defineMessages({
  title: { id: 'onboard.videos.title' },
  content: { id: 'onboard.videos.content' },
  tutorial: { id: 'onboard.videos.tutorial' },
});

const NavButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  color: ${x =>
    x.selected
      ? x.theme.palette.primarySaturated
      : x.theme.palette.text.secondary};

  &:hover {
    cursor: pointer;
  }
`;

function Help({ setOnboarded }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setOnboarded(true);
    }
  }, [isOpen]);

  const _toggle = () => setIsOpen(!isOpen);

  return (
    <SimpleTour
      onboardId={ONBOARD_IDS.VIDEOS}
      target=".getting-started-step"
      titleMsg={messages.title}
      contentMsg={messages.content}
      waitS={0}
      joyrideOptions={{
        event: 'hover',
        spotlightClicks: true,
        hideFooter: true,
        placement: 'right',
      }}
      canRun={!isOpen}
    >
      <div className="getting-started-step">
        <NavButton onClick={_toggle} selected={!!isOpen}>
          <FontAwesomeIcon icon={['fad', 'video']} style={{ fontSize: 20 }} />
          <Typography use="subtitle2" style={{ fontSize: 10 }}>
            <FormattedMessage {...messages.tutorial} />
          </Typography>

          <Dropdown
            isOpen={isOpen}
            onOutsideClick={_toggle}
            bottom="15px"
            right={`-${ONBOARD_VIDEO_WIDTH + 25}px`}
          >
            <OnboardVideos />
          </Dropdown>
        </NavButton>
      </div>
    </SimpleTour>
  );
}

export default withTheme(Help);
