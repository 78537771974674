import { connect } from 'react-redux';
import Profile from './Profile';

import {
  getCurrentClientQuestionActive,
  getCurrentClientActiveNutrients,
  getCurrentClientId,
} from 'redux/modules/clients/clients.selectors';
import { updateClientProfile } from 'redux/modules/clients/clients.actions';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';

const mapStateToProps = state => ({
  clientId: getCurrentClientId(state),
  questionActive: getCurrentClientQuestionActive(state),
  activeNutrients: getCurrentClientActiveNutrients(state),
});

const mapDispatchToProps = dispatch => ({
  updateProfile: (clientId, props) =>
    dispatch(updateClientProfile(clientId, props)),
  openNutrientsModal: () => {
    dispatch(openModal(MODAL_TYPES.EDIT_CLIENT_NUTRIENTS));
  },
});

const mergeProps = (
  stateProps,
  { updateProfile, openNutrientsModal },
  ownProps
) => ({
  ...ownProps,
  ...stateProps,
  setQuestionActive: value => {
    updateProfile(stateProps.clientId, { question_active: value });
  },
  updateActiveNutrients: nutrientsCodes =>
    updateProfile(stateProps.clientId, { active_nutrients: nutrientsCodes }),
  openNutrientsModal,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Profile);
