import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import omit from 'lodash/omit';
import keys from 'lodash/keys';
import types from './clientView.types';
import clientsTypes from '../../clients/clients.types.js';
import { getExportDiaryIds } from '../reportView';

const isInCompareMode = (state = false, action) => {
  switch (action.type) {
    case types.SET_COMPARE_MODE:
      return action.isInCompareMode;
    case clientsTypes.SET_CURRENT_CLIENT:
      return false;
    default:
      return state;
  }
};

const compareById = (state = {}, action) => {
  switch (action.type) {
    case types.SELECT_DAY_COMPARE:
      return {
        ...state,
        [action.diaryId]: true,
      };
    case types.UNSELECT_DAY_COMPARE:
      return omit(state, action.diaryId);
    case clientsTypes.SET_CURRENT_CLIENT:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  isInCompareMode,
  compareById,
});

/// SELECTORS
const _getClientView = state => get(state, 'UI.clientView');
export const getIsInCompareMode = createSelector(_getClientView, x =>
  get(x, 'isInCompareMode')
);

export const getCompareById = createSelector(_getClientView, x =>
  get(x, 'compareById')
);
export const getCompareDiaryIds = createSelector(getCompareById, x => keys(x));

const _getCount = x => get(x, 'length', 0);
export const getCompareDiariesCount = createSelector(
  getCompareDiaryIds,
  _getCount
);
export const getExportDiariesCount = createSelector(
  getExportDiaryIds,
  _getCount
);
