import UserFoodCheckbox from './UserFoodCheckbox';
import { connect } from 'react-redux';
import {
  selectUserFood,
  unselectUserFood,
  getIsUserFoodSelected,
} from 'redux/modules/UI/userFoodsView';

const mapStateToProps = (state, { id }) => ({
  selected: getIsUserFoodSelected(state, id),
});

const mapDispatchToProps = dispatch => ({
  select: id => dispatch(selectUserFood(id)),
  unselect: id => dispatch(unselectUserFood(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserFoodCheckbox);
