import React, { useEffect, useState } from 'react';
import {
  useParams,
  useRouteMatch,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import Visual from './Visual';
import Detailed from './Detailed';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFoodDiariesNMonthsAgo,
  fetchFoodDiary,
  unselectFoodDiary,
} from 'redux/modules/foodDiaries/foodDiaries.operations';
import { getCurrentClientId } from 'redux/modules/clients/clients.selectors';
import { getLatestDiaryId } from 'redux/modules/foodDiaries/foodDiaries.selectors';
import { selectFoodDiary } from 'redux/modules/foodDiaries/foodDiaries.actions';
import LinearProgress from 'components/loaders/LinearProgress';
import { NoDiaries } from './NoDiaries';

function Diary() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { diaryId, clientId } = useParams();
  const location = useLocation();
  const defaultRoute = location.hash === '#detailed' ? 'detailed' : 'visual';

  useEffect(() => {
    if (!diaryId || !clientId) return;

    dispatch(fetchFoodDiary(diaryId, clientId)).then(() => {
      dispatch(selectFoodDiary(diaryId));
    });
  }, [diaryId, clientId, fetchFoodDiary]);
  useEffect(() => {
    return () => dispatch(unselectFoodDiary());
  }, [unselectFoodDiary]);

  return (
    <Switch>
      <Route path={`${match.path}/visual`}>
        <Visual />
      </Route>
      <Route path={`${match.path}/detailed`}>
        <Detailed />
      </Route>
      <Route>
        <Redirect to={`${match.url}/${defaultRoute}`} />
      </Route>
    </Switch>
  );
}

export default function Diaries() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const currentClientId = useSelector(getCurrentClientId);
  const latestDiaryId = useSelector(getLatestDiaryId);
  const location = useLocation();
  const [loadingDiaries, setLoadingDiaries] = useState(true);

  useEffect(() => {
    if (!clientId || clientId !== currentClientId) return;

    setLoadingDiaries(true);
    dispatch(fetchFoodDiariesNMonthsAgo(clientId, 48)).finally(() => {
      setLoadingDiaries(false);
    });
  }, [clientId, currentClientId]);

  if (currentClientId !== clientId) return <LinearProgress />;

  const renderDiary = () => {
    if (!latestDiaryId && loadingDiaries) {
      return <LinearProgress />;
    }
    if (!latestDiaryId && !loadingDiaries) {
      return <NoDiaries />;
    }
    return <Redirect to={`${match.url}/${latestDiaryId}${location.hash}`} />;
  };

  return (
    <Switch>
      <Route path={`${match.path}/:diaryId`}>
        <Diary />
      </Route>
      <Route>{renderDiary()}</Route>
    </Switch>
  );
}
