import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import Typography from 'components/material/Typography';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import Dropdown from 'components/Dropdown';
import {
  getDietitianEmail,
  getIsLocaleFr,
} from 'redux/modules/account/account.selectors';
import Spacer from 'components/Spacer';

const messages = defineMessages({
  support: { id: 'leftNav.support' },
  header: { id: 'leftNav.support.header' },
  body: { id: 'leftNav.support.body' },
});

const NavButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  color: ${x =>
    x.selected
      ? x.theme.palette.primarySaturated
      : x.theme.palette.text.secondary};

  &:hover {
    cursor: pointer;
  }
`;

const Supportbox = styled.div`
  padding: 10px;
  width: 350px;

  &:hover {
    cursor: text;
  }
`;

function SupportMessage() {
  const email = useSelector(getDietitianEmail);
  const isFr = useSelector(getIsLocaleFr);

  const body = `Hi Keenoa team!\nThe email linked to my account is: ${email}\n I would like to support with...`;
  const bodyFr = `Bonjour Keenoa!\nLe courriel lié à mon compte est: ${email}\n Pouvez vous m'aider avec...`;
  const mailTo = `mailto:support@keenoa.com?body=${encodeURIComponent(
    isFr ? bodyFr : body
  )}`;

  return (
    <Supportbox onClick={e => e.stopPropagation()}>
      <div style={{ display: 'flex', flexDirection: 'row', opacity: 0.54 }}>
        <FontAwesomeIcon icon={['fad', 'envelope']} style={{ fontSize: 30 }} />
        <Spacer spacing="md" />
        <Typography use="headline6">
          <FormattedMessage {...messages.header} />
        </Typography>
      </div>
      <Spacer spacing="md" />
      <Typography use="body1">
        <FormattedMessage {...messages.body} />
        <a
          style={{ fontWeight: 'bold' }}
          href={mailTo}
          target="_blank"
          rel="noopener noreferrer"
        >
          support@keenoa.com
        </a>
        .
      </Typography>
    </Supportbox>
  );
}

function Support({}) {
  const [isOpen, setIsOpen] = useState(false);
  const _toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <NavButton onClick={_toggle} selected={!!isOpen}>
        <FontAwesomeIcon icon={['fad', 'envelope']} style={{ fontSize: 20 }} />
        <Typography use="subtitle2" style={{ fontSize: 10 }}>
          <FormattedMessage {...messages.support} />
        </Typography>
      </NavButton>
      <Dropdown
        isOpen={isOpen}
        onOutsideClick={_toggle}
        bottom="5px"
        left={`${45}px`}
      >
        <SupportMessage />
      </Dropdown>
    </div>
  );
}

export default withTheme(Support);
