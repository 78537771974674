import React from 'react';
import { connect } from 'react-redux';
import { fetchUserFoods, getUserFoods } from 'redux/modules/userFoods';
import { UserFoods } from './UserFoods';
import {
  getUserFoodsSortOrder,
  getUserFoodsSortColumn,
  setUserFoodsSortColumn,
  invertUserFoodsSortOrder,
} from 'redux/modules/UI/userFoodsView';
import FullPage from 'components/FullPage';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import EditUserFood from './EditUserFood';
import CreateUserFood from './CreateUserFood';

const mapStateToProps = state => ({
  foods: getUserFoods(state),

  sortColumn: getUserFoodsSortColumn(state),
  sortOrder: getUserFoodsSortOrder(state),
});

const mapDispatchToProps = dispatch => ({
  fetchFoods: () => dispatch(fetchUserFoods()),

  selectSortColumn: col => dispatch(setUserFoodsSortColumn(col)),
  invertSortOrder: () => dispatch(invertUserFoodsSortOrder()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserFoodsRouter);

function UserFoodsRouter(props) {
  const match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={`${match.path}`}>
          <UserFoods {...props} />
        </Route>
        <Route path={`${match.path}/create`}>
          <FullPage>
            <CreateUserFood />
          </FullPage>
        </Route>
        <Route path={`${match.path}/:userFoodId`}>
          <FullPage>
            <EditUserFood />
          </FullPage>
        </Route>
      </Switch>
    </div>
  );
}
