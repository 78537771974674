const pre = 'keenoa/entries/';

export default {
  ADD_ENTRY_REQUEST: `${pre}ADD_ENTRY_REQUEST`,
  ADD_ENTRY_SUCCESS: `${pre}ADD_ENTRY_SUCCESS`,
  ADD_ENTRY_FAILURE: `${pre}ADD_ENTRY_FAILURE`,

  DELETE_ENTRY_REQUEST: `${pre}DELETE_ENTRY_REQUEST`,
  DELETE_ENTRY_SUCCESS: `${pre}DELETE_ENTRY_SUCCESS`,
  DELETE_ENTRY_FAILURE: `${pre}DELETE_ENTRY_FAILURE`,

  UPDATE_ENTRY_REQUEST: `${pre}UPDATE_ENTRY_REQUEST`,
  UPDATE_ENTRY_SUCCESS: `${pre}UPDATE_ENTRY_SUCCESS`,
  UPDATE_ENTRY_FAILURE: `${pre}UPDATE_ENTRY_FAILURE`,
};
