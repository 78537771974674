import { combineReducers } from 'redux';
import { makeById, makeFetchStatus } from '../../../utils/standardReducers';
import types, { pre } from './studies.types';
import { createSelector } from 'reselect';
import {
  createStudyApi,
  fetchStudiesApi,
  generateParticipantsApi,
} from '../../../api/study';
import { normalize } from 'normalizr';
import * as schema from '../../schemas';

import get from 'lodash/get';
import values from 'lodash/values';
import FETCH_STATUS from 'constants/fetchStatus';

export default combineReducers({
  byId: makeById('studies'),
  studiesFetchStatus: makeFetchStatus(`${pre}FETCH_STUDIES`),
});

/// SELECTORS
const _getRoot = state => state.studies;

export const getById = createSelector(_getRoot, x => x.byId);

/** (state, studyId) */
export const getStudy = createSelector([getById, (_, id) => id], (byId, id) => {
  return get(byId, id, null);
});

export const getStudies = createSelector([getById], values);

export const getStudiesFetchStatus = createSelector(
  _getRoot,
  x => x.studiesFetchStatus
);

export const getIsFetchingStudies = createSelector(
  [getStudiesFetchStatus],
  status => status === FETCH_STATUS.LOADING
);

/// ACTION
export const createStudy = (code, name) => ({
  types: [
    types.CREATE_STUDY_REQUEST,
    types.CREATE_STUDY_SUCCESS,
    types.CREATE_STUDY_FAILURE,
  ],
  callAPI: () =>
    createStudyApi({ code, name }).then(res => normalize(res, schema.study)),
  payload: {
    code,
    name,
  },
});

export const fetchStudies = () => ({
  types: [
    types.FETCH_STUDIES_REQUEST,
    types.FETCH_STUDIES_SUCCESS,
    types.FETCH_STUDIES_FAILURE,
  ],
  callAPI: () =>
    fetchStudiesApi().then(res => normalize(res, schema.arrayOfStudy)),
});

export const generateParticipants = (studyId, count) => ({
  types: [
    types.GENERATE_PARTICIPANTS_REQUEST,
    types.GENERATE_PARTICIPANTS_SUCCESS,
    types.GENERATE_PARTICIPANTS_FAILURE,
  ],
  callAPI: () =>
    generateParticipantsApi({ studyId, count }).then(res =>
      normalize(res, schema.arrayOfClient)
    ),
  payload: {
    studyId,
    count,
  },
});
