import get from 'lodash/get';
import minBy from 'lodash/minBy';

export const formatQuantityInfo = (quantity, fraction) => {
  const fractionToDisplay = !fraction || fraction === '-' ? '' : fraction;
  const qtyToDisplay = fractionToDisplay && quantity === '0' ? '' : quantity;
  const totalQtyToDisplay =
    fractionToDisplay && qtyToDisplay
      ? `${qtyToDisplay} ${fractionToDisplay}`
      : fractionToDisplay || qtyToDisplay;

  return totalQtyToDisplay;
};

export const formatUnitInfo = (unit, unitName) => {
  return unit && unitName ? `${unit} ${unitName}` : unit || unitName || '';
};

export const formatUnitInfoWithDimension = (unit, unitName, unitDimension) => {
  const unitInfo = formatUnitInfo(unit, unitName);
  return unitDimension ? `${unitInfo} ${unitDimension}` : unitInfo;
};

export const getTotalQuantity = (quantity, fraction) =>
  parseInt(quantity, 10) + (fraction ? fraction : 0);

export const getFractionOptionFromQuantity = (quantity, fractionOptions) => {
  const decimal = quantity % 1;
  const reduce = _findClosestFraction(decimal, fractionOptions);
  return reduce.fraction;
};
export const getBestMatchingMeasure = (measures, measureId, quantity) => {
  if (measureId) {
    let newMeasure = measures.find(m => m.unit_measure_id === measureId);
    if (newMeasure) return newMeasure;
  }
  // If its not an exact match we get the measure with an initial value closest to
  // the quantity.
  return minBy(
    measures.map(m => ({ ...m, diff: Math.abs(m.initialValue - quantity) })),
    'diff'
  );
};

const _findClosestFraction = (decimal, fractionOptions) => {
  return fractionOptions.reduce(
    (min, fraction, i) => {
      const diff = Math.abs(decimal - fraction.value);
      if (diff < min.diff) {
        return { diff: diff, fraction: fractionOptions[i] };
      }
      return min;
    },
    { diff: 1, fraction: fractionOptions[0] }
  );
};

export const adjustNutrientsValuesByUnit = (nutrients, totalQuantity, unit) => {
  return nutrients.map(nutrient => ({
    ...nutrient,
    value:
      get(nutrient, 'baseValue', 0) +
      (nutrient.value * unit.conversionFactorValue * totalQuantity) /
        unit.initialValue,
  }));
};

export const convertMeasure = (from, to) => {
  const unitEqualInGram =
    (from.conversionFactorValue * 100) /
    (from.initialValue || from.initialvalue);
  const qtyInGram = from.quantity * unitEqualInGram;
  const toUnitEqualInGram = (to.conversionFactorValue * 100) / to.initialValue;
  return qtyInGram / toUnitEqualInGram;
};

export default {
  formatQuantityInfo,
  getTotalQuantity,
  getFractionOptionFromQuatity: getFractionOptionFromQuantity,
  adjustNutrientsValuesByUnit,
  formatUnitInfo,
  formatUnitInfoWithDimension,
  convertMeasure,
};
