import React from 'react';
import styled from 'styled-components';
import InfoText from './InfoText';
import Typography from './material/Typography';

export const shadowLevels = {
  1: '0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12)',
  2: '0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)',
  3: '0 1px 8px 0 rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12)',
};

const StyledDiv = styled.div`
  /* box-shadow: ${props => shadowLevels[props.level]}; */
  border: ${x => (x.free ? 0 : 1)}px solid #dfdfdf;
  background-color: ${props => props.theme.palette.white};
  color: ${props => props.theme.palette.text.primary};
  padding: 8px;
  border-radius: 4px;
`;

class WhiteFrame extends React.PureComponent {
  renderTitle() {
    const { title, help, left } = this.props;
    if (!title) return null;

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          use="subtitle2"
          rank="primary"
          tag="div"
          style={{ padding: '5px 10px' }}
        >
          <InfoText textMessage={title} tooltipMessage={help} />
        </Typography>
        {left ? left() : null}
      </div>
    );
  }

  render() {
    return (
      <StyledDiv
        level={this.props.level | 1}
        style={this.props.style}
        free={this.props.free}
        className={this.props.className}
      >
        {this.renderTitle()}
        {this.props.children}
      </StyledDiv>
    );
  }
}

export default WhiteFrame;
