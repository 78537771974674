import { connect } from 'react-redux';
import Avatar from './Avatar';
import { accountSelectors } from 'redux/modules/account';
import { authOperations } from 'redux/modules/auth';

const mapStateToProps = state => ({
  initial: accountSelectors.getInitial(state),
  fullName: accountSelectors.getFullName(state),
});

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(authOperations.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
