import React from 'react';
import { withTheme } from 'styled-components';
import { defineMessages } from 'react-intl';
import InfoText from 'components/InfoText';
import { Button } from 'components/material/Button';
import { makeCustomIcon } from '../../../../../../../utils/misc';
import { ThemeProvider } from '@rmwc/theme';

const messages = defineMessages({
  verify: { id: 'toolbar.button.verify' },
  verified: { id: 'toolbar.button.verified' },
  verifiedHelp: { id: 'foodDiary.toolbar.verifiedHelp' },
});

export default withTheme(
  React.memo(function Export({ isVerified, updateVerify, theme }) {
    return (
      <ThemeProvider options={{ onPrimary: theme.palette.text.midPrimary }}>
        <Button
          dense
          onClick={() => updateVerify(!isVerified)}
          icon={makeCustomIcon(['far', 'clipboard-list-check'])}
          rank={isVerified ? 'success' : 'midPrimary'}
          unelevated={isVerified ? true : false}
          style={{ marginRight: 5 }}
        >
          <InfoText
            textMessage={isVerified ? messages.verified : messages.verify}
            tooltipMessage={messages.verifiedHelp}
          />
        </Button>
      </ThemeProvider>
    );
  })
);
