import React from 'react';
import styled, { withTheme } from 'styled-components';
import { NUTRIENT_COLORS } from '../../../../../../constants/nutriments';
import Decimal from 'components/Decimal';
import Color from 'color';
import Percent from 'components/Percent';

const Wrapper = styled.td`
  padding: 0;
  background-color: ${props =>
    props.isHeader
      ? 'transparent'
      : props.active || props.isHeader
      ? props.activeBg
      : props.bg};
`;

const StyledDiv = styled.div`
  ${props => (props.isHeader ? '' : 'height: 31px')};
  background-color: ${props =>
    !props.isHeader
      ? ''
      : props.active || props.isHeader
      ? props.activeBg
      : props.bg};
`;

const StyledInnerTable = styled.table`
  display: flex;
  justify-content: space-evenly;
`;

const StyledInnerTbody = styled.tbody`
  width: 100%;
`;

const StyledTr = styled.tr`
  display: flex;
  justify-content: space-evenly;
`;

const StyledNumberTd = styled.td`
  width: 100%;
`;

const TdNumberContent = ({ nutrient, values, percentage }) => {
  const ValObject = percentage ? Percent : Decimal;
  if (
    nutrient.isActive &&
    nutrient.children &&
    nutrient.children.length !== 0
  ) {
    return (
      <StyledInnerTable>
        <StyledInnerTbody>
          <StyledTr>
            {nutrient.children.map((c, i) => (
              <StyledNumberTd key={i}>
                <ValObject>{values[c.id]}</ValObject>
              </StyledNumberTd>
            ))}
            <StyledNumberTd>
              <ValObject>{values[nutrient.id]}</ValObject>
            </StyledNumberTd>
          </StyledTr>
        </StyledInnerTbody>
      </StyledInnerTable>
    );
  }
  return <ValObject>{values[nutrient.id]}</ValObject>;
};

class NutrientTd extends React.PureComponent {
  render() {
    const {
      theme,
      nutrient,
      values,
      isHeader,
      children,
      space,
      percentage,
    } = this.props;

    let color = NUTRIENT_COLORS[nutrient ? nutrient.id : 'neutral'];
    if (!color) color = 'neutral';
    const activeBg = !isHeader
      ? Color(theme.palette[color])
          .fade(0.95)
          .toString()
      : Color(theme.palette[color])
          .saturate(0.1)
          .toString();
    const bg = isHeader ? theme.palette.neutral : 'transparent';

    return (
      <Wrapper
        active={nutrient.isActive}
        isHeader={isHeader}
        activeBg={activeBg}
        bg={bg}
      >
        <StyledDiv
          active={nutrient.isActive}
          isHeader={isHeader}
          activeBg={activeBg}
          bg={bg}
          {...this.props}
        >
          {values ? (
            <TdNumberContent
              nutrient={nutrient}
              values={values}
              percentage={percentage}
            />
          ) : (
            children
          )}
        </StyledDiv>
        {!isHeader ? null : (
          <div
            style={{
              height: space,
              backgroundColor: 'transparent',
            }}
          />
        )}
      </Wrapper>
    );
  }
}

export default withTheme(NutrientTd);
