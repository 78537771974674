import { createSelector } from 'reselect';
import get from 'lodash/get';
import FETCH_STATUS from '../../../../constants/fetchStatus';

/** (state) */
const _getRoot = state => get(state, ['builders', 'publicBuilders']);
export const getById = createSelector(
  _getRoot,
  x => get(x, 'byId')
);
const _getByIdFetchStatus = createSelector(
  _getRoot,
  x => get(x, 'byIdFetchStatus')
);

/** (state, publicBuilderId) */
export const getPublicBuilder = createSelector(
  [getById, (_, builderId) => builderId],
  (byId, builderId) => get(byId, builderId, null)
);
/** (state, publicBuilderId) */
export const getPublicBuilderBuilderId = createSelector(
  [getPublicBuilder],
  x => get(x, 'builder')
);
/** (state, publicBuilderId) */
export const getPublicBuilderOptions = createSelector(
  [getPublicBuilder],
  x => get(x, 'defaultOptions', []).map(x => get(x, 'id'))
);
/** (state, publicBuilderId) */
export const getPublicBuilderName = createSelector(
  [getPublicBuilder],
  x => get(x, 'name', '')
);
/** (state, publicBuilderId) */
export const getPublicBuilderFetchStatus = createSelector(
  [_getByIdFetchStatus, (_, id) => id],
  (byId, id) => get(byId, id, FETCH_STATUS.NONE)
);
/** (state, publicBuilderId) */
export const getPublicBuilderType = createSelector(
  [getPublicBuilder],
  builder => get(builder, 'builderType', '')
);
