import React from 'react';
import styled, { withTheme } from 'styled-components';
import {
  NUTRIENT_COLORS,
  formatNutrients,
  nutrimentsConstants,
} from '../../../../../constants/nutriments';
import Color from 'color';
import { FormattedMessage } from 'react-intl';
import MyPieChart from './MyPieChart';

const { CALORIES } = nutrimentsConstants;

const Wrapper = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: ${x => (x.row ? 'row' : 'column')};
  padding: 10px;
`;

const LegendWrapper = styled.div`
  margin-top: 10px;
  margin-left: ${x => (x.row ? 10 : 0)}px;
  flex: 1;
  min-width: 150px;
  max-width: 250px;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: -12px 0;
`;
const Square = styled.div`
  height: 10px;
  width: 10px;
  margin-right: 8px;
  background: ${props => props.color};
`;
const Text = styled.div`
  margin: 0.7em 0;
`;

const TotalEnergy = styled.div`
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 500 !important;
  color: ${props => props.theme.palette.text.primary};
  border-bottom: 1px solid ${props => props.theme.palette.text.primary};
`;

class NutrientsChart extends React.PureComponent {
  _renderLegend = formattedNutrients => {
    const { energy, theme, legendType } = this.props;
    const formatter =
      legendType && legendType === 'percent'
        ? n => `${((n.energy * 100) / energy).toFixed(1)} %`
        : n => `${n.grams >= 1 ? n.grams.toFixed(0) : n.grams.toPrecision(1)}g`;

    return (
      <LegendWrapper className="mdc-typography--caption" row={this.props.row}>
        <TotalEnergy>
          {' '}
          <div>
            <FormattedMessage {...CALORIES.label} />:
          </div>{' '}
          <div style={{ marginLeft: 'auto' }}>
            {this.props.energy.toFixed(0)} {CALORIES.unit}
          </div>
        </TotalEnergy>
        {formattedNutrients.map(n => (
          <Legend key={n.id}>
            <Square
              color={Color(theme.palette[NUTRIENT_COLORS[n.id]])
                .fade(0.3)
                .toString()}
            />
            <Text>{n.name}</Text>
            <Text style={{ marginLeft: 'auto' }}>{formatter(n)}</Text>
          </Legend>
        ))}
      </LegendWrapper>
    );
  };

  render() {
    const { nutrients, row, hideLegend } = this.props;
    const formattedNutrients = formatNutrients(nutrients);
    return (
      <Wrapper row={row}>
        <MyPieChart
          maxEnergy={this.props.maxEnergy}
          maxRadius={this.props.maxRadius}
          formattedNutrients={formattedNutrients}
          energy={this.props.energy}
        />
        {hideLegend ? null : this._renderLegend(formattedNutrients)}
      </Wrapper>
    );
  }
}

export default withTheme(NutrientsChart);
