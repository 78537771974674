import { nutrimentsConstants } from 'constants/nutriments';

export const BASIC_NUTRIENTS_MAIN = [
  nutrimentsConstants.CALORIES,
  nutrimentsConstants.FAT,
  nutrimentsConstants.SATURATED,
  nutrimentsConstants.TRANS,
  nutrimentsConstants.CHOLESTEROL,
  nutrimentsConstants.SODIUM,
  nutrimentsConstants.CARBS,
  nutrimentsConstants.FIBER,
  nutrimentsConstants.SUGAR,
  nutrimentsConstants.PROTEIN,
];
export const BASIC_NUTRIENTS_SECOND = [
  nutrimentsConstants.VITAMIN_A,
  nutrimentsConstants.VITAMIN_C,
  nutrimentsConstants.VITAMIN_D,
  nutrimentsConstants.CALCIUM,
  nutrimentsConstants.IRON,
  nutrimentsConstants.PHOSPHORUS,
  nutrimentsConstants.POTASSIUM,
];

export const HEIGHT_BEFORE_NUTRIENTS = 240;

export const MAIN_IDS = new Set([
  nutrimentsConstants.CALORIES.id,
  nutrimentsConstants.FAT.id,
  nutrimentsConstants.CHOLESTEROL.id,
  nutrimentsConstants.SODIUM.id,
  nutrimentsConstants.CARBS.id,
  nutrimentsConstants.PROTEIN.id,
]);

export const CHILDREN_IDS = new Set([
  nutrimentsConstants.SATURATED.id,
  nutrimentsConstants.TRANS.id,
  nutrimentsConstants.FIBER.id,
  nutrimentsConstants.SUGAR.id,
]);

export const SERVING_QUANTITY_UNITS = {
  ML: 'ml',
  G: 'g',
};
