import CompactView from './CompactView';
import { connect } from 'react-redux';
import { fetchFood } from 'redux/modules/foods/foods.actions';
import { getFoodFetchStatus } from 'redux/modules/foods/foods.selectors';
import {
  getEntryQuantity,
  getEntryMeasureId,
} from 'redux/modules/entries/entries.selectors';
import {
  getModalProps,
  openModal,
  MODAL_TYPES,
} from 'redux/modules/modals/modals';
import get from 'lodash/get';

const mapStateToProps = (state, { foodId, entryId }) => ({
  fetchStatus: getFoodFetchStatus(state, foodId),
  foodDescription: get(getModalProps(state), 'foodDescription', ''),

  startQuantity: getEntryQuantity(state, entryId),
  startMeasureId: getEntryMeasureId(state, entryId),
});

const mapDispatchToProps = dispatch => ({
  fetchFood: foodId => dispatch(fetchFood(foodId)),
  searchFood: (value, entryId) =>
    dispatch(
      openModal(MODAL_TYPES.ADD_FOOD, { refinement: value, entryId }, true)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompactView);
