export const pre = 'keenoa/studies/';

export default {
  CREATE_STUDY_REQUEST: `${pre}CREATE_STUDY_REQUEST`,
  CREATE_STUDY_SUCCESS: `${pre}CREATE_STUDY_SUCCESS`,
  CREATE_STUDY_FAILURE: `${pre}CREATE_STUDY_FAILURE`,

  FETCH_STUDIES_REQUEST: `${pre}FETCH_STUDIES_REQUEST`,
  FETCH_STUDIES_SUCCESS: `${pre}FETCH_STUDIES_SUCCESS`,
  FETCH_STUDIES_FAILURE: `${pre}FETCH_STUDIES_FAILURE`,

  GENERATE_PARTICIPANTS_REQUEST: `${pre}GENERATE_PARTICIPANTS_REQUEST`,
  GENERATE_PARTICIPANTS_SUCCESS: `${pre}GENERATE_PARTICIPANTS_SUCCESS`,
  GENERATE_PARTICIPANTS_FAILURE: `${pre}GENERATE_PARTICIPANTS_FAILURE`,
};
