import ClientCheckbox from './ClientCheckbox';
import { connect } from 'react-redux';
import { makeGetSelected } from 'redux/modules/clients/clients.selectors';
import {
  selectClient,
  unselectClient,
} from 'redux/modules/clients/clients.actions';

const mapStateToProps = (state, { id }) => ({
  selected: makeGetSelected(id)(state),
});

const mapDispatchToProps = dispatch => ({
  select: id => dispatch(selectClient(id)),
  unselect: id => dispatch(unselectClient(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientCheckbox);
