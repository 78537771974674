import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import QuantityPicker from './QuantityPicker';
import { defineMessages, injectIntl } from 'react-intl';
import { TextField } from 'components/forms/Textfield';
import { getEntryDescription } from 'redux/modules/entries/entries.selectors';
import FETCH_STATUS from '../../../../../../constants/fetchStatus';
import PlaceholderPicker from '../PlaceholderPicker';

const messages = defineMessages({
  foodNamePlaceholder: { id: 'editEntry.foodNamePlaceholder' },
});

function CompactView({
  foodId,
  foodDescription,
  entryId,
  intl,

  fetchStatus,
  fetchFood,
  searchFood,

  startQuantity,
  startMeasureId,

  onSubmit,
}) {
  const entryDescription = useSelector(state =>
    getEntryDescription(state, entryId)
  );
  const text = foodDescription || entryDescription;

  useEffect(() => {
    if (!foodId) return;

    fetchFood(foodId);
  }, [foodId]);

  const _editFood = () => {
    const t = text.split(',')[0].split('-');
    // We just take the first part of the string, if the persone
    // wants to update the item its likely going to be the same
    // item with a slight variation.
    searchFood(t, entryId);
  };

  return (
    <div style={{ position: 'relative' }}>
      <TextField
        outlined
        style={{ width: '100%' }}
        type="text"
        label={intl.formatMessage(messages.foodNamePlaceholder)}
        value={text}
        onClick={_editFood}
      />
      {fetchStatus === FETCH_STATUS.LOADING ? (
        <PlaceholderPicker />
      ) : (
        <QuantityPicker
          onSubmit={onSubmit}
          foodId={foodId}
          foodDescription={text}
          startQuantity={startQuantity}
          startMeasureId={startMeasureId}
        />
      )}
    </div>
  );
}

export default injectIntl(CompactView);
