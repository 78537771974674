import React from 'react';
import NutrientsSumRow from '../NutrientsSumRow';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Body = styled.tbody`
  background: #f5f9fd;
`;

const messages = defineMessages({
  rda: { id: 'dri.rda' },
  rdaHelp: { id: 'dri.rda.help' },
  ul: { id: 'dri.ul' },
  ulHelp: { id: 'dri.ul.help' },
  noProfileZero: { id: 'dri.noProfile.0' },
  editProfile: { id: 'dri.noProfile.edit' },
  noProfileOne: { id: 'dri.noProfile.1' },
});

const UpdateLink = styled.a`
  font-weight: 900;
  padding: 5px 2px;
  &:hover {
    cursor: pointer;
  }
`;

const EmptyTd = styled.td`
  padding: 15px;
  color: ${x => x.theme.palette.text.secondary};
`;

class DRIs extends React.PureComponent {
  renderEmpty = () => {
    // Renders when the sex or age isnt available.
    return (
      <Body>
        <tr colSpan="100">
          <EmptyTd colSpan="100" style={{ padding: 15 }}>
            <span className="mdc-typography--body1">
              <FormattedMessage {...messages.noProfileZero} />
              <UpdateLink onClick={this.props.onEditProfile}>
                <FormattedMessage {...messages.editProfile} />
              </UpdateLink>
              <FormattedMessage {...messages.noProfileOne} />
            </span>
          </EmptyTd>
        </tr>
      </Body>
    );
  };

  render() {
    const { rdasOrAis, uls, empty, nutrientsColumns } = this.props;
    if (empty) return this.renderEmpty();

    return (
      <Body>
        <NutrientsSumRow
          top
          nutrimentsToShow={nutrientsColumns}
          sum={rdasOrAis}
          message={messages.rda}
          helpMessage={messages.rdaHelp}
          noBorder
        />
        <NutrientsSumRow
          top
          nutrimentsToShow={nutrientsColumns}
          sum={uls}
          message={messages.ul}
          helpMessage={messages.ulHelp}
          noBorder
        />
      </Body>
    );
  }
}

export default DRIs;
