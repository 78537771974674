import React, { useLayoutEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import Option from '../Option';
import OtherOption from '../Option/OtherOption';
import take from 'lodash/take';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defineMessages, injectIntl } from 'react-intl';
import QuestionHeader from 'components/QuestionHeader';
import { ChipSet } from 'components/forms/Chip';

const messages = defineMessages({
  one: { id: 'builder.one' },
  atLeastOne: { id: 'builder.atLeastOne' },
  oneIfApplicable: { id: 'builder.oneIfApplicable' },
  onlyIfApplicable: { id: 'builder.onlyIfApplicable' },
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  padding-top: 15px;
  flex-direction; ${x => (x.compact ? 'row' : 'column')};

  --mdc-theme-primary: ${x => x.theme.palette.primarySaturated};
`;

const OptionsWrapper = styled(ChipSet)`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  padding-left: 11px;
  padding-right: 11px;
`;

function helpText(isMultiple, isRequired, intl) {
  if (isRequired) {
    if (isMultiple) return intl.formatMessage(messages.atLeastOne);
    else return intl.formatMessage(messages.one);
  } else {
    if (isMultiple) return intl.formatMessage(messages.onlyIfApplicable);
    else return intl.formatMessage(messages.oneIfApplicable);
  }
}

function _StatusIcon({ isRequired, selectCount, theme, style }) {
  let icon;
  let color;
  if (isRequired && !selectCount) {
    icon = 'exclamation-circle';
    color = theme.palette.warning;
  } else {
    icon = 'check-circle';
    color = !!selectCount
      ? theme.palette.success
      : theme.palette.text.secondary;
  }
  return (
    <FontAwesomeIcon
      icon={['fas', icon]}
      style={{ ...style, fontSize: 16, color }}
    />
  );
}
const StatusIcon = withTheme(_StatusIcon);

function Group({
  id,
  options,
  name,
  isMultiple,
  isRequired,
  shownOptionCount,
  onSelect,
  onUnselect,
  defaultOptions,
  selectedOptions,
  intl,
  namespace,
}) {
  const [isMore, setIsMore] = useState(false);

  useLayoutEffect(() => {
    if (defaultOptions.length && !selectedOptions.length) {
      defaultOptions.forEach(({ id }) => onSelect(id));
    }
  }, []);

  const shownOptions =
    shownOptionCount && !isMore ? take(options, shownOptionCount) : options;

  const renderOtherOption = () =>
    shownOptions.length < options.length ? (
      <OtherOption onClick={() => setIsMore(true)} />
    ) : null;

  return (
    <Wrapper key={id}>
      <div
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          padding: '5px 10px',
          display: 'flex',
        }}
      >
        <StatusIcon
          isRequired={isRequired}
          selectCount={selectedOptions.length}
        />
        <QuestionHeader text={name} style={{ padding: 0, paddingLeft: 4 }} />
        {
          <span
            className="mdc-typography--caption"
            style={{ marginLeft: 2 }}
          >{` - ${helpText(isMultiple, isRequired, intl)}`}</span>
        }
      </div>
      <OptionsWrapper choice>
        {shownOptions.map(option => (
          <Option
            key={option.id}
            {...option}
            select={onSelect}
            unselect={onUnselect}
            isMultiple={isMultiple}
            namespace={namespace}
          />
        ))}
        {renderOtherOption()}
      </OptionsWrapper>
    </Wrapper>
  );
}

export default injectIntl(Group);
