import { connect } from 'react-redux';
import DayChart from './DayChart';
import {
  getCurrentDiarySummary,
  getCompareDiariesSummary,
} from 'redux/modules/meals/meals.selectors';
import { getIsInCompareMode } from 'redux/modules/UI/clientView';

const mapStateToProps = state => {
  const compareMode = getIsInCompareMode(state);
  return {
    compareMode,
    summary: compareMode
      ? getCompareDiariesSummary(state)
      : getCurrentDiarySummary(state),
  };
};

const DayChartContainer = connect(mapStateToProps, null)(DayChart);

export default DayChartContainer;
