import { connect } from 'react-redux';
import {
  getCurrentDiaryMealGroupFromPos,
  getMealGroupFromPos,
} from 'redux/modules/meals/meals.selectors';
import MealGroup from './MealGroup';

const mapStateToProps = (state, { pos, diaryId }) => ({
  mealsId: !diaryId
    ? getCurrentDiaryMealGroupFromPos(state, pos)
    : getMealGroupFromPos(state, diaryId, pos),
});
export default connect(mapStateToProps)(MealGroup);
