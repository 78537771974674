import React, { useEffect, useState } from 'react';
import { Select } from 'components/material/Select';
import { FRACTION_OPTIONS } from 'constants/fractions';

const findClosestFraction = value => {
  let min = 1;
  let minPos = 0;
  for (let i = 0; i < FRACTION_OPTIONS.length; ++i) {
    const dist = Math.abs(value - FRACTION_OPTIONS[i].value);
    if (dist < min) {
      min = dist;
      minPos = i;
    }
  }
  return FRACTION_OPTIONS[minPos];
};

export function FractionPicker({
  value,
  onChange,
  outlined = true,
  className,
}) {
  const [val, setVal] = useState(findClosestFraction(value));
  useEffect(() => {
    const f = findClosestFraction(value);
    if (val.id !== f.id) {
      setVal(f);
    }
  }, [value]);

  return (
    <Select
      outlined={outlined}
      value={`${val.value}`}
      options={FRACTION_OPTIONS}
      onChange={e => {
        setVal(FRACTION_OPTIONS[e.detail.index]);
        onChange(e.currentTarget.value);
      }}
      rootProps={{ style: { width: 80 }, className }}
      className={className}
    />
  );
}
