import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBold,
  faItalic,
  faHighlighter,
  faLink,
  faH1,
  faH2,
  faListUl,
  faListOl,
  faUnderline,
  faGripHorizontal,
  faTrashAlt,
  faArrows,
  faFilePdf as farFilePdf,
  faClipboardListCheck,
  faTimes,
  faEdit,
  faExclamationCircle,
  faTrash,
  faEllipsisH,
  faCog,
  faSave,
  faCopy,
  faEllipsisV,
  faFilePdf as faFilePdfR,
  faDownload,
  faEnvelope as farEnvelope,
  faExternalLinkAlt,
  faChevronLeft,
  faClone,
  faUserCog,
  faPaperPlane as farPaperPlane,
  faFileCsv,
  faBox,
  faBoxOpen,
  faTable,
  faGripLines,
  faListAlt,
  faStepForward,
  faPencil,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faTh,
  faFilePdf,
  faPaperPlane,
  faEnvelope,
  faGripVertical,
  faPaste,
  faCheckCircle,
  faExclamationCircle as fasExclamationCircle,
  faInfoCircle as fasInfoCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faClipboardUser,
  faCaretDown,
  faCaretRight,
  faTimesCircle,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faFilePdf as falFilePdf,
  faSmile,
  faLongArrowRight,
  faPlayCircle,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/pro-light-svg-icons';
import {
  faArrowCircleRight,
  faQuestionCircle as fadQuestionCircle,
  faPlayCircle as fadPlayCircle,
  faPaperPlane as fadPaperPlane,
  faEye as fadEye,
  faCheckCircle as fadCheckCircle,
  faStreetView as fadStreetView,
  faCreditCard as fadCreditCard,
  faLightbulbExclamation,
  faLock as fadLock,
  faExclamationTriangle as fadExclamationTriangle,
  faPencilAlt as faPencilAltDuo,
  faEnvelope as fadEnvelope,
} from '@fortawesome/pro-duotone-svg-icons';

import {
  faNewspaper,
  faTh as faThDuo,
  faImage as faImageDuo,
  faUserCircle,
  faCreditCard,
  faFilePdf as faFilePdfDuo,
  faSpa as faSpaDuo,
  faMonitorHeartRate as faMonitorHeartRateDuo,
  faPauseCircle as faPauseCircleDuo,
  faUsers as faUsersDuo,
  faFileChartLine as faFileChartLineDuo,
  faIdCardAlt as faIdCardAltDuo,
  faLanguage as faLanguageDuo,
  faWatch as faWatchDuo,
  faEmptySet as faEmptySetDuo,
  faPlus as faPlusDuo,
  faCalendarDay as faCalendarDayDuo,
  faPalette as faPaletteDuo,
  faCubes as faCubesDuo,
  faVideo as faVideoDuo,
  faFilter as faFilterDuo,
  faBooks as faBooksDuo,
  faBalanceScale as faBalanceScaleDuo,
  faTags as faTagsDuo,
  faEye as faEyeDuo,
} from '@fortawesome/pro-duotone-svg-icons';

export const init = () => {
  library.add(
    faEyeDuo,
    faTagsDuo,
    faBalanceScaleDuo,
    faBooksDuo,
    faFilterDuo,
    faCalendarDayDuo,
    faPaletteDuo,
    faCubesDuo,
    faPlusDuo,
    faEmptySetDuo,
    faWatchDuo,
    faLanguageDuo,
    faIdCardAltDuo,
    faFileChartLineDuo,
    faUsersDuo,
    faBold,
    faItalic,
    faHighlighter,
    faLink,
    faH1,
    faH2,
    faListOl,
    faListUl,
    faUnderline,
    faGripHorizontal,
    faTrashAlt,
    faTh,
    faArrows,
    faFilePdf,
    faPaperPlane,
    faEnvelope,
    farFilePdf,
    falFilePdf,
    faClipboardListCheck,
    faTimes,
    faEdit,
    faExclamationCircle,
    faTrash,
    faEllipsisH,
    faSmile,
    faGripVertical,
    faCog,
    faPaste,
    faCheckCircle,
    faClipboardUser,
    faSave,
    faCopy,
    faEllipsisV,
    faExternalLinkAlt,
    faFilePdfR,
    farEnvelope,
    faDownload,
    fasExclamationCircle,
    faChevronLeft,
    fasInfoCircle,
    faClone,
    faLongArrowRight,
    faUserCog,
    fasExclamationTriangle,
    farPaperPlane,
    faFileCsv,
    faBox,
    faBoxOpen,
    faTable,
    faGripLines,
    faListAlt,
    faNewspaper,
    faCaretDown,
    faCaretRight,
    faThDuo,
    faImageDuo,
    faUserCircle,
    faCreditCard,
    faFilePdfDuo,
    fadQuestionCircle,
    faArrowCircleRight,
    fadPlayCircle,
    fadPaperPlane,
    fadEye,
    faVideoDuo,
    faPlayCircle,
    faStepForward,
    fadCheckCircle,
    fadStreetView,

    faTimesCircle,
    fadCreditCard,
    fadLock,
    faPencil,
    faLightbulbExclamation,
    fadExclamationTriangle,
    faSpaDuo,
    faPauseCircleDuo,
    faMonitorHeartRateDuo,
    faChevronRight,
    faPencilAltDuo,
    faAngleLeft,
    faAngleRight,
    fadEnvelope
  );
};
