import React, { useState, useEffect } from 'react';
import {
  defineMessages,
  injectIntl,
  FormattedMessage,
  useIntl,
} from 'react-intl';
import Spacer from 'components/Spacer';
import { TextField } from 'components/forms/Textfield';
import Typography from 'components/material/Typography';
import { Button } from 'components/material/Button';
import styled from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import A from 'components/A';
import { CourseRegistrationHeader } from '../components/CourseRegistrationHeader';
import { useEnrollmentToken } from '../hooks';
import { Terms } from 'components/Terms';

const messages = defineMessages({
  namePlaceholder: {
    id: 'signup.name',
    defaultMessage: 'Full Name',
  },
  emailPlaceholder: {
    id: 'global.email',
    defaultMessage: 'Email',
  },
  passwordPlaceHolder: {
    id: 'global.password',
    defaultMessage: 'Password',
  },
  signupBtn: {
    id: 'signup.submitBtn',
    defaultMessage: 'Register and Join course',
  },
  invalidError: {
    id: 'signup.invalidError',
    defaultMessage: 'The provided email is not valid or is already in use.',
  },
  UsedError: {
    id: 'signup.usedError',
    defaultMessage: 'The provided email is already in use.',
  },
  aboutYou: {
    id: 'signup.aboutYou',
  },
  credentials: {
    id: 'signup.credentials',
  },
  logIn: {
    id: 'signup.logIn',
  },
  existingAccount: {
    id: 'signup.existingAccount',
  },
  invalidToken: {
    id: 'courses.invalidToken',
  },
});

const SpacedInput = styled(TextField)`
  width: 100%;
`;

const ErrorContainer = styled.div`
  background-color: ${props => props.theme.palette.errorBg};
  border: 1px solid ${props => props.theme.palette.canvas};
  padding: 15px;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const _getErrorMsg = type => {
  switch (type) {
    case 'email_or_pass':
      return messages.UsedError;
    case 'invalid_email':
      return messages.invalidError;
    case 'invalid_registration_token':
      return messages.invalidToken;
    default:
      return messages.invalidError;
  }
};

const ErrorBox = ({ type, showErrors }) =>
  !type || !showErrors ? null : (
    <ErrorContainer>
      <FormattedMessage {..._getErrorMsg(type)} />
    </ErrorContainer>
  );

function Signup({ locale, onSubmit, errorType }) {
  const { formatMessage } = useIntl();

  const enrollmentToken = useEnrollmentToken();
  const history = useHistory();
  const location = useLocation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (errorType) {
      setShowErrors(true);
      setPassword('');
    }
  }, [errorType]);

  const _onKeyPress = e => {
    if (e.key === 'Enter' && _canSubmit()) {
      _submit();
    }
  };

  const _submit = async () => {
    const body = {
      name,
      email,
      password,
      token: enrollmentToken,
      locale,
    };
    try {
      await onSubmit(body);
      history.replace('/clients');
    } catch (e) {}
  };

  const _canSubmit = () => {
    return name && email && password && password.length > 5;
  };

  return (
    <form
      onKeyPress={_onKeyPress}
      autoComplete="on"
      onSubmit={e => {
        e.preventDefault();
        _submit();
      }}
    >
      <CourseRegistrationHeader />
      <Spacer spacing="xl" />
      <SpacedInput
        outlined
        autoFocus
        type="text"
        name="fname"
        autoComplete="name"
        label={formatMessage(messages.namePlaceholder)}
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Spacer spacing="lg" />
      <Typography use="overline" rank="tertiary">
        <FormattedMessage {...messages.credentials} />
      </Typography>
      <SpacedInput
        outlined
        name="email"
        autoComplete="email"
        label={formatMessage(messages.emailPlaceholder)}
        value={email}
        onChange={e => {
          setShowErrors(false);
          setEmail(e.target.value);
        }}
      />
      <Spacer spacing="lg" />
      <SpacedInput
        outlined
        label={formatMessage(messages.passwordPlaceHolder)}
        name="password"
        autoComplete="new-password"
        type="password"
        value={password}
        onChange={e => {
          setShowErrors(false);
          setPassword(e.target.value);
        }}
      />
      <Spacer spacing="xl" />
      <ErrorBox type={errorType} showErrors={showErrors} />
      <Button unelevated disabled={!_canSubmit()} style={{ width: '100%' }}>
        <FormattedMessage {...messages.signupBtn} />
      </Button>
      <Typography use="subtitle2" rank="secondary">
        <FormattedMessage {...messages.existingAccount} />{' '}
        <Link
          to={{
            pathname: '/courses/login',
            search: location.search,
          }}
        >
          <A>
            <FormattedMessage {...messages.logIn} />
          </A>
        </Link>
      </Typography>
      <Spacer spacing="md" />
      <Terms />
    </form>
  );
}

export default injectIntl(Signup);
