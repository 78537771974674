import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal';
import Dropdown from 'components/Dropdown';
import { Button } from 'components/material/Button';
import MyEmojiPicker from 'components/EmojiPicker';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import H2 from 'components/H2';
import { TextField } from 'components/forms/Textfield';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notify } from 'react-notify-toast';
import { Twemoji } from 'react-emoji-render';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Typography from 'components/material/Typography';
import Spacer from 'components/Spacer';

const messages = defineMessages({
  header: { id: 'createTemplate.header' },
  headerUpdate: { id: 'createTemplate.header.update' },
  headerDuplicate: { id: 'createTemplate.header.duplicate' },
  subheader: { id: 'createTemplate.subheader' },
  create: { id: 'createTemplate.create' },
  createUpdate: { id: 'createTemplate.create.update' },
  createDuplicate: { id: 'createTemplate.create.duplicate' },
  success: { id: 'createTemplate.success' },
  successUpdate: { id: 'createTemplate.success.update' },
  successDuplicate: { id: 'createTemplate.succes.duplicate' },

  title: { id: 'createTemplate.title' },
  description: { id: 'createTemplate.description' },
  icon: { id: 'createTemplate.icon' },
});

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextFormWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;

  --mdc-theme-primary: ${x => x.theme.palette.primary1};
`;

const EmojiWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.17);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: color 100ms ease-in-out;
  color: rgba(0, 0, 0, 0.17);

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.87);
    color: rgba(0, 0, 0, 0.33);
    cursor: pointer;
  }
`;

const IconText = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  color: ${x => x.theme.palette.text.secondary};
`;

function EmojiPicker({ emoji, setEmoji }) {
  const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        flex: 1,
        marginRight: 10,
        paddingBottom: 2,
        position: 'relative',
      }}
    >
      <EmojiWrapper onClick={() => setOpen(!open)}>
        {emoji ? (
          <Twemoji text={emoji} style={{ fontSize: 42 }} />
        ) : (
          <FontAwesomeIcon icon={['fal', 'smile']} style={{ fontSize: 42 }} />
        )}
      </EmojiWrapper>
      <IconText>
        <FormattedMessage {...messages.icon} />
      </IconText>
      <Dropdown
        isOpen={open}
        onOutsideClick={() => setOpen(!open)}
        top="-283px"
        left="-93px"
      >
        <MyEmojiPicker
          onSelect={e => {
            setEmoji(e.native);
            setOpen(false);
          }}
        />
      </Dropdown>
    </div>
  );
}

function CreateTemplateModal(props) {
  const {
    isOpen,
    onExit,

    startEmoji = '',
    startTitle = '',
    startDescription = '',

    createTemplate,
    updateTemplate,
    duplicateTemplate,

    isUpdate,
    isDuplicate,
  } = props;
  const [emoji, setEmoji] = useState(startEmoji);
  const [title, setTitle] = useState(startTitle);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(startDescription);
  const history = useHistory();
  const intl = useIntl();
  const match = useRouteMatch();

  useEffect(() => setEmoji(startEmoji), [startEmoji]);
  useEffect(() => setTitle(startTitle), [startTitle]);
  useEffect(() => setDescription(startDescription), [startDescription]);

  return (
    <Modal isOpen={isOpen} onClose={onExit} style={{ maxWidth: '460px' }}>
      <H2 style={{ padding: 0 }}>
        <FormattedMessage
          {...(isDuplicate
            ? messages.headerDuplicate
            : isUpdate
            ? messages.headerUpdate
            : messages.header)}
        />
      </H2>
      <Typography use="body2" rank="secondary" tag="div">
        <FormattedMessage {...messages.subheader} />
      </Typography>
      <Spacer spacing="md" />
      <FormWrapper>
        <EmojiPicker emoji={emoji} setEmoji={setEmoji} />
        <TextFormWrapper>
          <TextField
            outlined
            label={intl.formatMessage(messages.title)}
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          <div style={{ height: 10 }} />
          <TextField
            textarea
            outlined
            label={intl.formatMessage(messages.description)}
            rows="2"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </TextFormWrapper>
      </FormWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          paddingTop: 10,
        }}
      >
        <Button
          unelevated
          disabled={loading || !title || !description || !emoji}
          onClick={() => {
            setLoading(true);

            const fn = isDuplicate
              ? duplicateTemplate
              : isUpdate
              ? updateTemplate
              : createTemplate;

            fn(title, description, emoji)
              .then(newTemplateId => {
                const msg = isDuplicate
                  ? messages.successDuplicate
                  : isUpdate
                  ? messages.successUpdate
                  : messages.success;
                notify.show(
                  intl.formatMessage(msg, { emoji, title }),
                  'success'
                );
                setTitle('');
                setEmoji('');
                setDescription('');

                if (!isUpdate && !isDuplicate) {
                  history.push(`${match.url}/${newTemplateId}`);
                }

                onExit();
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        >
          <FormattedMessage
            {...(isDuplicate
              ? messages.createDuplicate
              : isUpdate
              ? messages.createUpdate
              : messages.create)}
          />
        </Button>
      </div>
    </Modal>
  );
}

export default CreateTemplateModal;
