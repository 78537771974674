import Reports from './Reports';
import { connect } from 'react-redux';
import {
  getCurrentClientReports,
  getFetchReportsStatus,
  fetchReports,
} from 'redux/modules/reports';
import { fetchReportTemplates } from 'redux/modules/reportTemplates/reportTemplates.actions';
import {
  getReportsSortColumn,
  getReportsSortOrder,
  setReportsSortColumn,
  invertReportsSortOrder,
  getReportsFilter,
} from 'redux/modules/UI/reportsView';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';

const mapStateToProps = state => ({
  reports: getCurrentClientReports(state),
  reportsStatus: getFetchReportsStatus(state),

  sortColumn: getReportsSortColumn(state),
  sortOrder: getReportsSortOrder(state),
  filter: getReportsFilter(state),
});
const mapDispatchToProps = dispatch => ({
  createReport: () => dispatch(openModal(MODAL_TYPES.PICK_EXPORT_TEMPLATE)),

  fetchReports: () => dispatch(fetchReports()),
  fetchTemplates: () => dispatch(fetchReportTemplates()),

  selectSortColumn: col => dispatch(setReportsSortColumn(col)),
  invertSortOrder: () => dispatch(invertReportsSortOrder()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
