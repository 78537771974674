import React from 'react';
import styled, { useTheme } from 'styled-components';
import { PageWrapper } from 'components/PageWrapper';
import { Chocolate } from 'react-kawaii';
import Typography from 'components/material/Typography';
import Spacer, { SIZES, SPACINGS } from 'components/Spacer';
import { defineMessages, FormattedMessage } from 'react-intl';

const Wrapper = styled.div`
  border-radius: 8px;
  background-color: #f7f7f7;
  padding: ${SIZES[SPACINGS.xxLarge]}px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Side = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const messages = defineMessages({
  title: { id: 'foodDiary.noDiaryAvailableTitle' },
  text: { id: 'foodDiary.noDiaryAvailableText' },
});

export function NoDiaries() {
  const theme = useTheme();
  return (
    <PageWrapper dense>
      <Spacer spacing="xxl" />
      <Wrapper>
        <Side>
          <Typography use="headline6" rank="primary" tag="div" center>
            <FormattedMessage {...messages.title} />
          </Typography>
          <Typography use="body2" rank="secondary" tag="div" center>
            <FormattedMessage {...messages.text} />
          </Typography>
        </Side>
        <Side style={{ opacity: 0.75 }}>
          <Chocolate size={175} mood="sad" color={theme.palette.primary1} />
        </Side>
      </Wrapper>
    </PageWrapper>
  );
}
