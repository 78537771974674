import { combineReducers } from 'redux';
import {
  makeById,
  makeFetchStatus,
  makeByIdFetchStatus,
} from '../../../utils/standardReducers';
import types, { pre } from './reports.types';
import { createSelector } from 'reselect';
import {
  createReportApi,
  fetchReportApi,
  fetchReportsApi,
  deleteReportApi,
  finalizeReportApi,
  updateReportApi,
  sendReportApi,
} from '../../../api/report';
import { normalize } from 'normalizr';
import schemas from '../../schemas';
import get from 'lodash/get';
import FETCH_STATUS from '../../../constants/fetchStatus';
import { defineMessages } from 'react-intl';
import createCachedSelector from 're-reselect';
import { getCurrentClientId } from '../clients/clients.selectors';
import { getReportVersion } from '../reportVersions';

const messages = defineMessages({
  sendSucess: { id: 'reports.actions.sendSuccess' },
  deleteSucess: { id: 'reports.actions.deleteSuccess' },
});

export const reportIds = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_REPORTS_SUCCESS:
      return action.response.result;
    case types.CREATE_REPORT_SUCCESS:
      return [action.response.result, ...state];
    case types.DELETE_REPORT_SUCCESS:
      return state.filter(x => x !== action.reportId);
    default:
      return state;
  }
};

export default combineReducers({
  byId: makeById('reports'),
  fetchStatus: makeByIdFetchStatus(`${pre}FETCH_REPORT`, 'reportId'),
  reportIds,
  fetchReportsStatus: makeFetchStatus(`${pre}FETCH_REPORTS`),
  finalizeReportStatus: makeFetchStatus(`${pre}FINALIZE_REPORT`),
  createReportStatus: makeFetchStatus(`${pre}CREATE_REPORT`),
});

/// SELECTORS
const _getRoot = state => {
  return state.reports;
};
export const getById = createSelector(_getRoot, x => x.byId);
export const getFetchStatus = createSelector(_getRoot, x => x.fetchStatus);
export const getCreateReportStatus = createSelector(
  _getRoot,
  x => x.createReportStatus
);
export const getFinalizeReportStatus = createSelector(
  _getRoot,
  x => x.finalizeReportStatus
);
export const getFetchReportsStatus = createSelector(
  _getRoot,
  x => x.fetchReportsStatus
);
/** (state, reportId) */
export const getReport = createCachedSelector(
  [getById, (_, id) => id],
  (byId, id) => get(byId, id, null)
)((_, id) => id);
/** (state, reportId) */
export const getIsReportFinalized = createSelector(
  getReport,
  report => !!(report && report.pdf_url)
);
/** (state, reportId) */
export const getReportUrl = createSelector(getReport, report =>
  get(report, 'pdf_url', null)
);
/** (state, reportId) */
export const getReportTitle = createSelector(getReport, report =>
  get(report, 'title', null)
);

export const getReportIds = createSelector(_getRoot, x => x.reportIds);
export const getReports = createSelector([getReportIds, getById], (ids, byId) =>
  ids.map(id => byId[id])
);
export const getCurrentClientReports = createSelector(
  [getReports, getCurrentClientId],
  (reports, id) => reports.filter(r => r.client_id === id)
);

/** (state, reportId) */
export const getReportFetchStatus = createSelector(
  [getFetchStatus, (_, id) => id],
  (fetchStatus, id) => get(fetchStatus, id, FETCH_STATUS.NONE)
);

/** (state, reportId) */
export const getReportVersionId = createSelector(getReport, report =>
  get(report, 'version', null)
);
/** (state, reportId) */
export const getReportVersionObject = createSelector(
  (state, reportId) => {
    const versionId = getReportVersionId(state, reportId);
    return getReportVersion(state, versionId);
  },
  x => x
);
/** (state, reportId) */
export const getReportStructure = createSelector(
  getReportVersionObject,
  version => get(version, 'structure', null)
);

/// ACTION
export const createReport = templateId => ({
  types: [
    types.CREATE_REPORT_REQUEST,
    types.CREATE_REPORT_SUCCESS,
    types.CREATE_REPORT_FAILURE,
  ],
  callAPI: () =>
    createReportApi(templateId).then(res => normalize(res, schemas.report)),
  payload: { templateId },
});

export const fetchReport = reportId => ({
  types: [
    types.FETCH_REPORT_REQUEST,
    types.FETCH_REPORT_SUCCESS,
    types.FETCH_REPORT_FAILURE,
  ],
  callAPI: () =>
    fetchReportApi(reportId).then(res => normalize(res, schemas.report)),
  payload: { reportId },
});

export const fetchReports = () => ({
  types: [
    types.FETCH_REPORTS_REQUEST,
    types.FETCH_REPORTS_SUCCESS,
    types.FETCH_REPORTS_FAILURE,
  ],
  callAPI: () =>
    fetchReportsApi().then(res => normalize(res, schemas.arrayOfReport)),
});

export const deleteReport = reportId => ({
  types: [
    types.DELETE_REPORT_REQUEST,
    types.DELETE_REPORT_SUCCESS,
    types.DELETE_REPORT_FAILURE,
  ],
  callAPI: () => deleteReportApi(reportId),
  payload: { reportId },
});

export const updateReport = (reportId, values) => ({
  types: [
    types.UPDATE_REPORT_REQUEST,
    types.UPDATE_REPORT_SUCCESS,
    types.UPDATE_REPORT_FAILURE,
  ],
  callAPI: () =>
    updateReportApi(reportId, values).then(res =>
      normalize(res, schemas.report)
    ),
  payload: { reportId, values },
});

export const finalizeReport = (reportId, report) => ({
  types: [
    types.FINALIZE_REPORT_REQUEST,
    types.FINALIZE_REPORT_SUCCESS,
    types.FINALIZE_REPORT_FAILURE,
  ],
  callAPI: () =>
    finalizeReportApi(reportId, report).then(res =>
      normalize(res, schemas.report)
    ),
  payload: { reportId },
});

export const sendReport = reportId => ({
  types: [
    types.SEND_REPORT_REQUEST,
    types.SEND_REPORT_SUCCESS,
    types.SEND_REPORT_FAILURE,
  ],
  messages: [null, messages.sendSucess, null],
  callAPI: () =>
    sendReportApi(reportId).then(res => normalize(res, schemas.report)),
  payload: { reportId },
});

export const viewReport = reportId => ({
  type: types.VIEW_REPORT,
  reportId,
});

export const downloadReport = reportId => ({
  type: types.DOWNLOAD_REPORT,
  reportId,
});
