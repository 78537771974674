const pre = 'keenoa/auth/';

export default {
  LOGIN_START: `${pre}LOGIN_START`,
  LOGIN_SUCCESS: `${pre}LOGIN_SUCCESS`,
  LOGIN_FAILURE: `${pre}LOGIN_FAILURE`,

  SIGNUP_START: `${pre}SIGNUP_START`,
  SIGNUP_SUCCESS: `${pre}SIGNUP_SUCCESS`,
  SIGNUP_FAILURE: `${pre}SIGNUP_FAILURE`,

  PASSWORD_RESET_SENT: `${pre}PASSWORD_RESET_SENT`,
  PASSWORD_RESET_SUCCESS: `${pre}PASSWORD_RESET_SUCCESS`,
  PASSWORD_RESET_FAILURE: `${pre}PASSWORD_RESET_FAILURE`,
  EXECUTE_PASSWORD_RESET_FAILURE: `${pre}EXECUTE_PASSWORD_RESET_FAILURE`,

  PROFILE_LOAD_SUCCESS: `${pre}PROFILE_LOAD_SUCCESS`,
  PROFILE_LOAD_UNAUTHORIZED: `${pre}PROFILE_LOAD_UNAUTHORIZED`,

  LOGOUT: `${pre}LOGOUT`,
};
