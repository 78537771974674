import React from 'react';
import styled from 'styled-components';
import Title from './Title';
import Description from './Description';
import Color from 'color';
import get from 'lodash/get';
const bg = Color('black')
  .fade(0.97)
  .toString();

const Wrapper = styled.div`
  padding: 15px;

  &:hover {
    cursor: pointer;
    background-color: ${bg};
  }
`;

export default function Row({
  locale,

  rowId,
  hit,
  onClick,
  mealId,
  entryId,
}) {
  const _onClick = () => {
    const description = get(hit, `description_${locale}`);
    onClick(rowId, description, mealId, entryId);
  };

  return (
    <Wrapper key={rowId} onClick={_onClick}>
      <Title hit={hit} locale={locale} />
      <Description hit={hit} locale={locale} />
    </Wrapper>
  );
}
