import React from 'react';
import styled from 'styled-components';
import WhiteFrame from 'components/WhiteFrame';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Login from './Login';
import Signup from './Signup';
import { useQuery } from 'utils/hooks';
import { useEnrollmentToken } from './hooks';
import { FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
  invalidToken: {
    id: 'courses.invalidToken',
  },
});

const MainLayout = styled.div`
  position: absolute;
  padding: 10px;
  top: 0px;
  padding-top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
`;
const StyledWhiteframe = styled(WhiteFrame)`
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
  padding: 20px 0;
`;
const Content = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;
const ErrorContainer = styled.div`
  background-color: ${props => props.theme.palette.errorBg};
  border: 1px solid ${props => props.theme.palette.canvas};
  padding: 15px;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 10px;
`;

function Courses() {
  const query = useQuery();
  const enrollmentToken = useEnrollmentToken();

  const match = useRouteMatch();
  const renderRoutes = () => (
    <Switch>
      <Route path={`${match.path}/login`}>
        <Login />
      </Route>
      <Route path={`${match.path}/register`}>
        <Signup />
      </Route>
    </Switch>
  );
  return (
    <div>
      <MainLayout>
        <StyledWhiteframe>
          <Content>
            {!enrollmentToken ? (
              <ErrorContainer>
                <FormattedMessage {...messages.invalidToken} />
              </ErrorContainer>
            ) : (
              renderRoutes()
            )}
          </Content>
        </StyledWhiteframe>
      </MainLayout>
    </div>
  );
}

export default Courses;
