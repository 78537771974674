import React from 'react';
import styled from 'styled-components';
import FullPageImage from 'components/FullPageImage';
import TimeFormat from 'components/TimeFormat';
import get from 'lodash/get';
import { Twemoji } from 'react-emoji-render';
import Info from 'components/Info';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  forgot: { id: 'meal.forgot.help' },
});

export const IMAGE_SIZE = 250;
const ImgContainer = styled.div`
  height: ${props => props.height}px;
  position: relative;
  text-align: right;
  display: flex;
  flex: 1;

  &:hover {
    ${x => (x.onClick ? 'cursor: pointer' : '')};
  }
`;

const EmojiWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: #ecf0f1;
  border-radius: 20px;
  position: relative;
`;
const HelpWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
`;

const Stamp = styled.div`
  width: 90%;
  padding: 55% 5% 5% 5%;
  position: absolute;
  bottom: 0;
  right: 0;

  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25));
  color: white;
  border-radius: 20px;
`;

const MealEmptyImg = styled(ImgContainer)`
  background-color: #ecf0f1;
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.palette.canvas};
  background-image: ${props => `url(${props.src})`};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
`;

class StampedImg extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  _onClose = e => {
    this.setState({
      open: false,
    });
  };

  _onOpen = () => {
    this.setState({
      open: true,
    });
  };

  renderImg = src => <Image src={src} />;

  renderImage = () => {
    const { photo } = this.props;

    const src = get(photo, 'urls.small');
    const large = get(photo, 'urls.default');

    return (
      <>
        {this.renderImg(src)}
        <FullPageImage
          open={this.state.open}
          onClose={this._onClose}
          preview={src}
          url={large}
        />
      </>
    );
  };

  renderEmoji = () => {
    const { emoji } = this.props;
    return (
      <EmojiWrapper>
        <HelpWrapper>
          <Info tooltipMessage={messages.forgot} />
        </HelpWrapper>
        <Twemoji text={emoji || ''} style={{ fontSize: 64 }} />
      </EmojiWrapper>
    );
  };

  render() {
    const { photo, time, height, emoji } = this.props;

    if (!photo && !emoji) return <MealEmptyImg />;

    return (
      <ImgContainer onClick={!emoji ? this._onOpen : undefined} height={height}>
        {emoji ? this.renderEmoji() : this.renderImage()}
        <Stamp className="mdc-typography--headline4">
          <TimeFormat time={time} />
        </Stamp>
      </ImgContainer>
    );
  }
}

export default StampedImg;
