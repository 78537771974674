import React from 'react';
import Modal from 'components/Modal';
import Chip from 'components/Chip';
import { FormattedMessage, defineMessages } from 'react-intl';
import {
  ANSWER_TO_I18N,
  WHY,
  QUESTIONS_TO_I18N,
  HUNGRINESS,
  FULLNESS,
  HOW,
  FEEL,
} from '../../../../../../constants/mindful';
import H2 from 'components/H2';
import styled from 'styled-components';

const messages = defineMessages({
  title: { id: 'mindful.help.title' },
  before: { id: 'mindful.help.before' },
  after: { id: 'mindful.help.after' },
});

const Explanation = styled.p`
  color: ${p => p.theme.palette.text.secondary};
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

class NewClientModal extends React.PureComponent {
  renderQuestion = question => (
    <div>
      <div className="mdc-typography--body1">
        <FormattedMessage {...QUESTIONS_TO_I18N[question.id]} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          paddingBottom: 12,
        }}
      >
        {question.options.map(x => (
          <Chip key={x}>
            <FormattedMessage {...ANSWER_TO_I18N[x]} />
          </Chip>
        ))}
      </div>
    </div>
  );

  render() {
    const { isOpen, onExit, firstName } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onExit}>
        <H2>
          <FormattedMessage {...messages.title} />
        </H2>
        <Explanation className="mdc-typography--subheading2">
          <FormattedMessage {...messages.before} values={{ firstName }} />
        </Explanation>

        {this.renderQuestion(WHY)}
        {this.renderQuestion(HUNGRINESS)}
        <Explanation className="mdc-typography--subheading2">
          <FormattedMessage {...messages.after} />
        </Explanation>
        {this.renderQuestion(FULLNESS)}
        {this.renderQuestion(HOW)}
        {this.renderQuestion(FEEL)}
      </Modal>
    );
  }
}

export default NewClientModal;
