import React, { useState } from 'react';
import Modal from 'components/Modal';
import H2 from 'components/H2';
import { useDispatch, useSelector } from 'react-redux';
import {
  MODAL_TYPES,
  useModal,
  getModalProps,
  closeAllModals,
} from 'redux/modules/modals/modals';
import { TextField } from 'components/forms/Textfield';
import Typography from 'components/material/Typography';
import { Button } from 'components/material/Button';
import Spacer from 'components/Spacer';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { generateParticipants } from 'redux/modules/studies';
import { CircularProgress } from 'components/loaders/CircularProgress';
import get from 'lodash/get';
import FileSaver from 'file-saver';

const messages = defineMessages({
  header: { id: 'participantCountModal.header' },
  content: { id: 'participantCountModal.content' },
  content1: { id: 'participantCountModal.content1' },
  content2: { id: 'participantCountModal.content2' },
  count: { id: 'participantCountModal.count' },
  help: { id: 'participantCountModal.help' },

  generate: { id: 'participantCountModal.generate' },
  generating: { id: 'participantCountModal.generating' },
});

function SelectParticipantCountModal() {
  const intl = useIntl();
  const [isOpen, onExit] = useModal(MODAL_TYPES.SELECT_PARTICIPANT_COUNT);
  const [count, setCount] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const studyId = get(useSelector(getModalProps), 'studyId');

  const _onGenerate = async () => {
    setLoading(true);
    try {
      const result = await dispatch(generateParticipants(studyId, count));
      const rows = get(result, 'response.result', []).map(c => [c]);
      var blob = new Blob([rows.map(e => e.join(',')).join('\n')], {
        type: 'data:text/txt;charset=utf-8,',
      });
      FileSaver.saveAs(blob, 'participant_ids.txt');
      setCount();
      dispatch(closeAllModals());
    } finally {
      setLoading(false);
    }
  };

  const isCountValid = () => {
    if (!count) return true;

    return count <= 100;
  };

  return (
    <Modal isOpen={isOpen} onClose={onExit}>
      <H2>
        <FormattedMessage {...messages.header} />
      </H2>
      <Spacer spacing="xl" />
      <Typography use="body2" rank="secondary" tag="div">
        <FormattedMessage {...messages.content} />
      </Typography>
      <Spacer spacing="lg" />
      <Typography use="body2" rank="secondary" tag="div">
        <FormattedMessage {...messages.content1} />
      </Typography>
      <Spacer spacing="lg" />
      <Typography use="body2" rank="secondary" tag="div">
        <FormattedMessage {...messages.content2} />
      </Typography>
      <Spacer spacing="xl" />
      <TextField
        outlined
        value={count}
        type="number"
        onChange={e => setCount(parseInt(e.target.value))}
        label={intl.formatMessage(messages.count)}
        autoFocus={true}
        invalid={!isCountValid()}
        style={{ width: '70%' }}
        helpText={{
          persistent: true,
          validationMsg: true,
          children: intl.formatMessage(messages.help),
        }}
      />
      <Spacer spcing="md" />
      <Button
        style={{ float: 'right', marginTop: '-24px' }}
        unelevated
        icon={loading ? <CircularProgress /> : null}
        disabled={!count || !isCountValid() || loading}
        onClick={_onGenerate}
        label={intl.formatMessage(
          loading ? messages.generating : messages.generate
        )}
      />
    </Modal>
  );
}

export default SelectParticipantCountModal;
