import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { get as getStorage, put } from '../../../../utils/storage';
import {
  STATUS_FILTER_NAME,
  STATUS_FILTERS,
  COURSES_FILTER_NAME,
  COURSES_FILTERS,
  STUDIES_FILTER_NAME,
  STUDIES_FILTERS,
} from '../../../../constants/clientStatus';
import clientsViewTypes from './clientsView.types';
import get from 'lodash/get';
import { SORT_ORDERS } from '../../../../constants';
import {
  getDietitianIsInstructor,
  getDietitianIsResearcher,
} from 'redux/modules/account/account.selectors';

const defaultStatus = () =>
  getStorage(STATUS_FILTER_NAME) || STATUS_FILTERS.ALL_BUT_ARCHIVED;
export const statusFilter = (state = defaultStatus(), action) => {
  switch (action.type) {
    case clientsViewTypes.SET_STATUS_FILTER:
      return action.filter;
    default:
      return state;
  }
};

export const sortColumn = (state = null, action) => {
  if (action.type === clientsViewTypes.SELECT_SORT_COLUMN) {
    return action.column;
  }
  return state;
};

export const sortOrder = (state = SORT_ORDERS.ASCENDING, action) => {
  if (action.type === clientsViewTypes.INVERT_SORT_ORDER) {
    return state === SORT_ORDERS.DESCENDING
      ? SORT_ORDERS.ASCENDING
      : SORT_ORDERS.DESCENDING;
  }
  return state;
};

const defaultCourse = () =>
  getStorage(COURSES_FILTER_NAME) || COURSES_FILTERS.ALL;
export const coursesFilter = (state = defaultCourse(), action) => {
  switch (action.type) {
    case clientsViewTypes.SET_COURSES_FILTER:
      return action.filter;
    default:
      return state;
  }
};

const defaultStudy = () =>
  getStorage(STUDIES_FILTER_NAME) || STUDIES_FILTERS.ALL;
export const studiesFilter = (state = defaultStudy(), action) => {
  switch (action.type) {
    case clientsViewTypes.SET_STUDIES_FILTER:
      return action.filter;
    default:
      return state;
  }
};

export default combineReducers({
  statusFilter,
  coursesFilter,
  studiesFilter,

  sortColumn,
  sortOrder,
});

// OPERATIONS
export const setStatusFilter = filter => dispatch => {
  put(STATUS_FILTER_NAME, filter);
  return dispatch({
    type: clientsViewTypes.SET_STATUS_FILTER,
    filter,
  });
};
export const setCoursesFilter = filter => dispatch => {
  put(COURSES_FILTER_NAME, filter);
  return dispatch({
    type: clientsViewTypes.SET_COURSES_FILTER,
    filter,
  });
};
export const setStudiesFilter = filter => dispatch => {
  put(STUDIES_FILTER_NAME, filter);
  return dispatch({
    type: clientsViewTypes.SET_STUDIES_FILTER,
    filter,
  });
};
export const setSortColumn = column => ({
  type: clientsViewTypes.SELECT_SORT_COLUMN,
  column,
});
export const invertSortOrder = () => ({
  type: clientsViewTypes.INVERT_SORT_ORDER,
});

/// SELECTORS
const _getClientsView = state =>
  get(state, 'UI.clientsView', { statusFilter: defaultStatus() });

export const getStatusFilter = createSelector(
  _getClientsView,
  x => x.statusFilter
);

export const getCoursesFilter = createSelector(
  [_getClientsView, getDietitianIsInstructor],
  (x, isInstructor) => (isInstructor ? x.coursesFilter : COURSES_FILTERS.ALL)
);

export const getStudiesFilter = createSelector(
  [_getClientsView, getDietitianIsResearcher],
  (x, isResearcher) => (isResearcher ? x.studiesFilter : STUDIES_FILTERS.ALL)
);

export const getSortColumn = createSelector(_getClientsView, x => x.sortColumn);
export const getSortOrder = createSelector(_getClientsView, x => x.sortOrder);
