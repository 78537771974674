import React from 'react';
import Typography from 'components/material/Typography';
import { defineMessages, FormattedMessage } from 'react-intl';

import Checkbox from 'components/forms/Checkbox';

const messages = defineMessages({
  allowClients: { id: 'foods.create.allowClients' },
});

export function PickFoodVisibility({ value, setValue }) {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: -12,
        }}
      >
        <Checkbox checked={value} onChange={() => setValue(!value)} />

        <Typography use="body1" rank="midPrimary" tag="div">
          <FormattedMessage {...messages.allowClients} />
        </Typography>
      </div>
    </div>
  );
}
