import { connect } from 'react-redux';
import FoodLog from './FoodLog';
import { entriesOperations } from 'redux/modules/entries/index';
import {
  detailedViewOperations,
  detailedViewSelectors,
} from 'redux/modules/UI/detailedView';
import { openEditEntryModal } from 'redux/modules/entries/entries.operations';
import {
  getCurrentDiaryNutrients,
  getCurrentDiaryMealGroups,
  getCurrentClientDRIGroupString,
} from 'redux/modules/meals/meals.selectors';
import { getIsInCompareMode } from 'redux/modules/UI/clientView';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';

const mapStateToProps = state => ({
  dailySum: getCurrentDiaryNutrients(state),
  mealGroups: getCurrentDiaryMealGroups(state),
  nutrientsColumns: detailedViewSelectors.getNutrientsColumns(state),
  compareMode: getIsInCompareMode(state),
  groupDRIString: getCurrentClientDRIGroupString(state),
});

const mapDispatchToProps = dispatch => ({
  onEditEntry: (entryId, mealId) =>
    dispatch(openEditEntryModal(entryId, mealId)),
  onDeleteEntry: (entryId, mealId) =>
    dispatch(entriesOperations.deleteEntry(entryId, mealId)),
  onNutrientClick: nutrient =>
    dispatch(detailedViewOperations.nutrientClick(nutrient)),
  onEditProfile: () => dispatch(openModal(MODAL_TYPES.PROFILE)),
});

const FoodLogContainer = connect(mapStateToProps, mapDispatchToProps)(FoodLog);

export default FoodLogContainer;
