import actions from './entries.actions';
import entriesServices from './../../../api/entries';
import schemas from '../../schemas';
import { normalize } from 'normalizr';
import { setCurrentMeal } from '../meals/meals.actions';
import { openModal, MODAL_TYPES, closeModal } from '../modals/modals';

export const addEntry = (entry, mealId) => dispatch => {
  dispatch(actions.addEntry(entry, mealId));
  return entriesServices
    .addFoodEntry(entry, mealId)
    .then(res =>
      dispatch(actions.addEntrySuccess(normalize(res, schemas.entry), mealId))
    )
    .catch(err => dispatch(actions.addEntryFailure(err)));
};

export const deleteEntry = (entryId, mealId) => dispatch => {
  dispatch(actions.deleteEntry(entryId, mealId));
  return entriesServices
    .deleteFoodEntry(entryId)
    .then(() => dispatch(actions.deleteEntrySuccess(entryId, mealId)))
    .catch(err => dispatch(actions.deleteEntryFailure(err)));
};

export const updateEntry = entry => dispatch => {
  dispatch(actions.updateEntry(entry));
  return entriesServices
    .updateFoodEntry(entry)
    .then(res =>
      dispatch(actions.updateEntrySuccess(normalize(res, schemas.entry)))
    )
    .catch(err => dispatch(actions.updateEntryFailure(err)));
};

export const openEditEntryModal = (entryId, mealId) => dispatch => {
  dispatch(setCurrentMeal(mealId));
  dispatch(openModal(MODAL_TYPES.EDIT_ENTRY, { entryId, mealId }, true));
};
export const closeEditEntryModal = () => dispatch => {
  dispatch(closeModal(MODAL_TYPES.EDIT_ENTRY));
  dispatch(setCurrentMeal(null));
};

export const openSearchFoodModal = mealId => dispatch => {
  dispatch(setCurrentMeal(mealId));
  dispatch(openModal(MODAL_TYPES.ADD_FOOD, { mealId }, true));
};
export const closeSearchFoodModal = () => dispatch => {
  dispatch(setCurrentMeal(null));
  dispatch(closeModal(MODAL_TYPES.ADD_FOOD));
};

export default {
  deleteEntry,
  addEntry,
  updateEntry,

  openEditEntryModal,
  closeEditEntryModal,
  openSearchFoodModal,
  closeSearchFoodModal,
};
