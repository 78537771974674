import React from 'react';
import { Route, Redirect, Router } from 'react-router-dom';
import * as pages from '../pages';

import 'react-tippy/dist/tippy.css';

import Providers from './Providers';
import { isTokenValid } from '../utils/auth';
import ConfirmationDialog from 'components/ConfirmationDialog';
import YoutubeVideo from '../components/YoutubeVideo';
import history from '../history';
import GAListener from 'components/GAListener';
function Root() {
  const loggedIn = isTokenValid();

  return (
    <Providers>
      <Router history={history}>
        <GAListener trackingId="UA-90846811-2">
          <pages.Onboarding />
          <pages.Auth />
          <pages.Dashboard />
          <pages.Oauth />
          <pages.Courses />
          <Route exact path="/">
            {loggedIn ? <Redirect to="/clients" /> : <Redirect to="/login" />}
          </Route>
        </GAListener>
        <ConfirmationDialog />
        <YoutubeVideo />
      </Router>
    </Providers>
  );
}

export default Root;
