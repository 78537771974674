import React from 'react';
import styled from 'styled-components';
import { TextField } from './Textfield';
import Spacer from 'components/Spacer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const onClickHandler = cb => e => {
  e.preventDefault();
  cb();
};

const onInputChangeHandler = cb => e => {
  e.preventDefault();
  const value = e.target.value;
  const isNotNumber = isNaN(parseFloat(value)) || !isFinite(value);
  if (isNotNumber) {
    return cb(0);
  }
  return cb(parseInt(Math.abs(value), 10));
};

const StyledButton = styled.button`
  padding: 5px 11px;
  font-size: 1.4em;
  border-radius: 6px;
  border-color: rgba(0, 0, 0, 0.33);
  color: rgba(0, 0, 0, 0.33);

  &:hover {
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.87);
    color: rgba(0, 0, 0, 0.87);
  }
`;

const UnitController = styled.div`
  display: flex;
`;

const NumberPicker = ({ input: { value, onChange } }) => {
  return (
    <div>
      <UnitController>
        <StyledButton
          inverted={true}
          btnType="primary"
          onClick={onClickHandler(() => onChange(value > 0 ? value - 1 : 0))}
        >
          <FontAwesomeIcon icon={['fal', 'angle-left']} />
        </StyledButton>
        <Spacer spacing="sm" />
        <TextField
          type="number"
          style={{ width: 80 }}
          outlined
          value={value}
          onChange={onInputChangeHandler(onChange)}
        />
        <Spacer spacing="sm" />
        <StyledButton
          inverted={true}
          btnType="primary"
          onClick={onClickHandler(() => onChange(value + 1))}
        >
          <FontAwesomeIcon icon={['fal', 'angle-right']} />
        </StyledButton>
      </UnitController>
    </div>
  );
};

export default NumberPicker;
