import { combineReducers } from 'redux';
import { makeById, makeFetchStatus } from '../../../utils/standardReducers';
import types, { pre } from './templateVersions.type';
import { createSelector } from 'reselect';
import { createTemplateVersionApi } from '../../../api/report';
import { normalize } from 'normalizr';
import schemas from '../../schemas';
import get from 'lodash/get';

export default combineReducers({
  byId: makeById('templateVersions'),
  createStatus: makeFetchStatus(`${pre}CREATE_TEMPLATE_VERSION`),
});

/// SELECTORS
const _getRoot = state => state.templateVersions;

export const getById = createSelector(_getRoot, x => x.byId);

export const getCreateTemplateVersionStatus = createSelector(
  _getRoot,
  x => x.createStatus
);

/** (state, versionId) */
export const getTemplateVersion = createSelector(
  [getById, (_, id) => id],
  (byId, id) => get(byId, id, null)
);

/// ACTION
export const createTemplateVersion = (templateId, structure) => ({
  types: [
    types.CREATE_TEMPLATE_VERSION_REQUEST,
    types.CREATE_TEMPLATE_VERSION_SUCCESS,
    types.CREATE_TEMPLATE_VERSION_FAILURE,
  ],
  callAPI: () =>
    createTemplateVersionApi(templateId, structure).then(res =>
      normalize(res, schemas.reportTemplateVersion)
    ),
  payload: {
    templateId,
    structure,
  },
});
