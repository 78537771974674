import React from 'react';
import { connectHighlight } from 'react-instantsearch-dom';
import { withTheme } from 'styled-components';
import lower from 'lodash/lowerFirst';

export default withTheme(
  connectHighlight(
    ({ highlight, attribute, hit, highlightProperty, lowerFirst }) => {
      const parsedHit = highlight({ attribute, hit, highlightProperty });

      const highligtedHit = parsedHit.map((part, idx) => {
        if (part.isHighlighted)
          return (
            <span key={idx} style={{ fontWeight: 'bold' }}>
              {lowerFirst ? lower(part.value) : part.value}
            </span>
          );
        return lowerFirst ? lower(part.value) : part.value;
      });
      return <span>{highligtedHit}</span>;
    }
  )
);
