import React, { useState } from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import Typography from 'components/material/Typography';
import Spacer from 'components/Spacer';
import { PageWrapper } from 'components/PageWrapper';
import { TextField } from 'components/forms/Textfield';
import { Radio } from 'components/forms/Radio';
import { Button } from 'components/material/Button';
import { importFoodApi } from 'api/import';
import { SuccessBox, WarningBox } from 'components/NotificationBox';
import { get } from 'lodash';
import CircularProgress from 'components/loaders/CircularProgress';
import { FOOD_IMPORT_SOURCES } from '../../../../constants';

const messages = defineMessages({
  title: {
    id: 'private.import.title',
    defaultMessage: 'Import food',
  },
  usdaImportID: {
    id: 'private.import.usda.id',
    defaultMessage: 'USDA FDC ID',
  },
  hkfdImportID: {
    id: 'private.import.hkfd.id',
    defaultMessage: 'Hong Kong Food Database ID',
  },
  button: {
    id: 'private.import.button',
    defaultMessage: 'Import',
  },
  loading: {
    id: 'private.import.loading',
    defaultMessage: 'Importing...',
  },
});

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

function Import() {
  const [importId, setImportId] = useState('');
  const [source, setSource] = useState(FOOD_IMPORT_SOURCES.USDA);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const intl = useIntl();

  const _onSubmit = async () => {
    setError(null);
    setLoading(true);

    try {
      const result = await importFoodApi({ source, id: importId });
      setSuccess(result);
    } catch (e) {
      setError(e.message);
    } finally {
      setImportId('');
      setLoading(false);
    }
  };

  const formattedSuccess = () => {
    const id = get(success, 'id');
    const name = get(success, 'name.en');

    const url = `https://data.keenoa.co/editor/item_types/57651/items/${id}/edit`;

    const message = ` has been succesffully imported from ${source} into Dato, you can find and edit the item here: `;

    return (
      <>
        <strong>{name}</strong>
        {message}
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      </>
    );
  };

  return (
    <PageWrapper dense>
      <Spacer spacing="xxl" />
      <Typography use="headline5" rank="primary" tag="div" center>
        <FormattedMessage {...messages.title} />
      </Typography>
      <Spacer spacing="lg" />
      {error ? <WarningBox>{error}</WarningBox> : null}
      {success ? <SuccessBox>{formattedSuccess()}</SuccessBox> : null}
      <Typography use="overline" rank="secondary" tag="div">
        Import source
      </Typography>
      <Radio
        value={FOOD_IMPORT_SOURCES.USDA}
        checked={source === FOOD_IMPORT_SOURCES.USDA}
        onChange={evt => setSource(String(evt.currentTarget.value))}
      >
        USDA
      </Radio>
      <Radio
        value={FOOD_IMPORT_SOURCES.HONG_KONG_FOOD_DATABASE}
        checked={source === FOOD_IMPORT_SOURCES.HONG_KONG_FOOD_DATABASE}
        onChange={evt => setSource(String(evt.currentTarget.value))}
      >
        Hong Kong Food Database
      </Radio>
      <Spacer spacing="xl" />
      <TextField
        outlined
        value={importId}
        onChange={e => setImportId(e.target.value)}
        label={intl.formatMessage(
          messages[
            source === FOOD_IMPORT_SOURCES.USDA
              ? 'usdaImportID'
              : 'hkfdImportID'
          ]
        )}
        autoFocus={true}
        style={{ width: '100%' }}
      />
      <Spacer spacing="lg" />
      <ButtonWrapper>
        <Button
          unelevated
          disabled={loading || !importId}
          onClick={_onSubmit}
          icon={loading ? <CircularProgress /> : null}
        >
          <FormattedMessage
            {...(loading ? messages.loading : messages.button)}
          />
        </Button>
      </ButtonWrapper>
    </PageWrapper>
  );
}

export default Import;
