import React from 'react';
import { TIME_FORMATS } from '../../constants';

class TimeFormat extends React.PureComponent {
  renderTwelve() {
    const { time, simple } = this.props;
    const [hour, minute] = time.split(':');
    let suffix = hour >= 12 ? 'pm' : 'am';

    let h = hour % 12;
    if (h === 0) h = 12;

    return simple ? `${h}${suffix}` : `${h}:${minute}${suffix}`;
  }

  render() {
    const { time, format } = this.props;

    if (format === TIME_FORMATS.TWELVE_HOUR) {
      return this.renderTwelve();
    }
    return time;
  }
}

export default TimeFormat;
