import styled from 'styled-components';
import React from 'react';
import { Button } from 'components/material/Button';
import { defineMessages, FormattedMessage } from 'react-intl';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';
import { useDispatch } from 'react-redux';
import SelectStudyModal from '../SelectStudyModal';
import NewStudyModal from '../NewStudyModal';
import SelectParticipantCountModal from '../SelectParticipantCountModal';

const messages = defineMessages({
  inviteBtn: {
    id: 'clientList.addParticipantsButton',
  },
});

const NewParticipantsButton = styled(Button)`
  white-space: nowrap;
`;

function NewClientButton() {
  const dispatch = useDispatch();

  const _selectStudy = () => {
    dispatch(openModal(MODAL_TYPES.SELECT_STUDY));
  };

  return (
    <>
      <NewParticipantsButton unelevated onClick={_selectStudy}>
        <FormattedMessage {...messages.inviteBtn} />
      </NewParticipantsButton>
      <SelectStudyModal />
      <NewStudyModal />
      <SelectParticipantCountModal />
    </>
  );
}

export default NewClientButton;
