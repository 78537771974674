import React, { useEffect } from 'react';
import WhiteFrame from 'components/WhiteFrame';
import styled from 'styled-components';
import { Button } from 'components/material/Button';
import AddPayment from './AddPayment';
import { FormattedMessage, defineMessages } from 'react-intl';
import CreditCard from './CreditCard';
import fetchStatus from '../../../../constants/fetchStatus';
import PaymentDueModal from './PaymentDueModal';
import Typography from 'components/material/Typography';
import Spacer from 'components/Spacer';
import { useQuery } from 'utils/hooks';
import { PageWrapper } from 'components/PageWrapper';

const messages = defineMessages({
  title: { id: 'billing.title' },
  sourcesHeader: { id: 'billing.sourcesHeader' },
  addPaymentBtn: { id: 'billing.addPaymentBtn' },
  invoicesHeader: { id: 'billing.invoicesHeader' },
  noSources: { id: 'billing.noSources' },
});

const PersonnalWhiteFrame = styled(WhiteFrame)`
  min-width: 550px;
`;

const LoadingSource = styled.div`
  height: 50px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  background-color: ${props => props.theme.palette.canvas};
  color: ${props => props.theme.palette.text.secondary};
  justify-content: center;
`;

function Billing({
  sources,
  sourcesStatus,
  fetchSources,
  addPayment,
  openPaymentDue,
}) {
  const query = useQuery();
  const paymentDue = query.get('payment_due');

  useEffect(() => {
    fetchSources();
  }, []);
  useEffect(() => {
    if (paymentDue) {
      openPaymentDue();
    }
  }, [paymentDue]);

  const renderSources = () => {
    if (!sources.length && sourcesStatus === fetchStatus.LOADING) {
      return <LoadingSource />;
    }

    if (
      !sources.length &&
      (sourcesStatus === fetchStatus.LOADED ||
        sourcesStatus === fetchStatus.FAILED)
    ) {
      return (
        <LoadingSource className="mdc-typography--body2">
          <FormattedMessage {...messages.noSources} />
        </LoadingSource>
      );
    }

    return sources.map(CreditCard);
  };

  return (
    <PageWrapper dense>
      <Spacer spacing="xxl" />
      <Typography use="headline5" rank="primary" center tag="div">
        <FormattedMessage {...messages.title} />
      </Typography>
      <Spacer spacing="lg" />
      <PersonnalWhiteFrame
        title={messages.sourcesHeader}
        left={() => (
          <Button style={{ margin: 0 }} outlined onClick={addPayment}>
            <FormattedMessage {...messages.addPaymentBtn} />
          </Button>
        )}
      >
        {renderSources()}
      </PersonnalWhiteFrame>
      <AddPayment />
      <PaymentDueModal />
    </PageWrapper>
  );
}

export default Billing;
