import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import {
  SORT_ORDERS,
  REPORTS_SORT_COLUMNS,
  REPORT_STATUS,
  REPORT_ACTIONS,
  REPORT_FILTERS,
} from '../../../../constants';
import reportsViewTypes from './reportsView.types';
import uniq from 'lodash/uniq';
import intersection from 'lodash/intersection';
import {
  getIsReportFinalized,
  sendReport,
  updateReport,
  downloadReport,
  getReportUrl,
  getReportTitle,
  deleteReport,
  createReport,
} from 'redux/modules/reports';
import { saveAs } from 'file-saver';
import { imageProxyUrl } from 'api/api';
import { getCurrentClientId } from 'redux/modules/clients/clients.selectors';

const sortColumn = (state = REPORTS_SORT_COLUMNS.CREATION_DATE, action) => {
  if (action.type === reportsViewTypes.SELECT_SORT_COLUMN) {
    return action.column;
  }
  return state;
};

const sortOrder = (state = SORT_ORDERS.DESCENDING, action) => {
  if (action.type === reportsViewTypes.INVERT_SORT_ORDER) {
    return state === SORT_ORDERS.DESCENDING
      ? SORT_ORDERS.ASCENDING
      : SORT_ORDERS.DESCENDING;
  }
  return state;
};

const selectedIds = (state = [], action) => {
  switch (action.type) {
    case reportsViewTypes.UNSELECT_ALL_REPORTS:
      return [];
    case reportsViewTypes.SELECT_REPORT:
      return uniq([...state, action.id]);
    case reportsViewTypes.UNSELECT_REPORT:
      return state.filter(x => x !== action.id);
    case reportsViewTypes.SELECT_REPORTS:
      return action.ids;
    default:
      return state;
  }
};

const filter = (state = REPORT_FILTERS.ALL, action) => {
  switch (action.type) {
    case reportsViewTypes.SET_REPORTS_FILTER:
      return action.filter;
    default:
      return state;
  }
};

export default combineReducers({
  sortColumn,
  sortOrder,

  selectedIds,
  filter,
});

// ACTIONS
export const setReportsSortColumn = column => ({
  type: reportsViewTypes.SELECT_SORT_COLUMN,
  column,
});
export const invertReportsSortOrder = () => ({
  type: reportsViewTypes.INVERT_SORT_ORDER,
});

export const selectReport = id => ({
  type: reportsViewTypes.SELECT_REPORT,
  id,
});
export const selectReports = ids => ({
  type: reportsViewTypes.SELECT_REPORTS,
  ids,
});
export const unselectReport = id => ({
  type: reportsViewTypes.UNSELECT_REPORT,
  id,
});
export const unselectAllReports = () => ({
  type: reportsViewTypes.UNSELECT_ALL_REPORTS,
});
export const setReportsFilter = filter => ({
  type: reportsViewTypes.SET_REPORTS_FILTER,
  filter,
});

/// SELECTORS
const _getReportsView = state => get(state, 'UI.reportsView');

export const getReportsSortColumn = createSelector(
  _getReportsView,
  x => x.sortColumn
);
export const getReportsSortOrder = createSelector(
  _getReportsView,
  x => x.sortOrder
);

export const getSelectedReportsIds = createSelector(
  _getReportsView,
  x => x.selectedIds
);
export const getSelectedReportsCount = createSelector(
  getSelectedReportsIds,
  x => x.length
);

export const _getSelectedReportsStatus = createSelector(
  [
    state => {
      const ids = getSelectedReportsIds(state);
      return ids.map(id => getIsReportFinalized(state, id));
    },
  ],
  areFinalizeds =>
    uniq(
      areFinalizeds.map(x =>
        x ? REPORT_STATUS.FINALIZED : REPORT_STATUS.CURRENT
      )
    )
);
export const getSelectedReportsActions = createSelector(
  [_getSelectedReportsStatus, getSelectedReportsCount],
  (status, count) => {
    const rs = REPORT_STATUS;
    const ra = REPORT_ACTIONS;
    const statusToAction = {
      [rs.CURRENT]: [ra.REMOVE],
      [rs.FINALIZED]:
        count > 1
          ? [ra.DOWNLOAD, ra.SEND]
          : [ra.DOWNLOAD, ra.SEND, ra.COPY, ra.EDIT],
    };
    const actions = status.map(s => statusToAction[s]);
    return intersection(...actions);
  }
);

/** (state, reportId) */
export const getReportIsSelected = createSelector(
  [getSelectedReportsIds, (_, reportId) => reportId],
  (selectedIds, id) => selectedIds.includes(id)
);

export const getReportsFilter = createSelector(_getReportsView, x => x.filter);

/// OPERATIONS
export const onReportAction = (action, reportId, history) => async (
  dispatch,
  getState
) => {
  switch (action) {
    case REPORT_ACTIONS.SEND:
      await dispatch(sendReport(reportId));
      await dispatch(
        updateReport(reportId, {
          last_sent_timestamp: new Date(),
        })
      );
      break;
    case REPORT_ACTIONS.COPY:
      const newReport = await dispatch(createReport());
      const newReportId = get(newReport, 'response.result');
      const clientId = getCurrentClientId(getState());
      const url = `/clients/${clientId}/reports/${newReportId}?load_report_id=${reportId}`;
      history.push(url);
      break;
    case REPORT_ACTIONS.DOWNLOAD:
      const pdfUrl = getReportUrl(getState(), reportId);
      const reportTitle = getReportTitle(getState(), reportId);
      const blob = await fetch(imageProxyUrl(pdfUrl)).then(x => x.blob());
      saveAs(blob, `${reportTitle}.pdf`);
      await dispatch(downloadReport());
      await dispatch(
        updateReport(reportId, {
          last_download_timestamp: new Date(),
        })
      );
      break;
    case REPORT_ACTIONS.EDIT:
      history.push(
        `/clients/${getCurrentClientId(getState())}/reports/${reportId}`
      );
      break;
    case REPORT_ACTIONS.REMOVE:
      await dispatch(deleteReport(reportId));
      break;
    default:
    // do nothing
  }
};
