import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Button } from 'components/material/Button';
import styled from 'styled-components';
import Typography from 'components/material/Typography';

const messages = defineMessages({
  title: { id: 'templates.noTemplates.title' },
  addBtn: { id: 'templates.noTemplates.new' },
});

const SuggestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const AddTemplate = styled(Button)`
  margin-top: 8px;
  padding-left: 20px;
  padding-right: 20px;
`;

function Templates({ onAdd }) {
  const [render, setRender] = useState(false);
  useEffect(() => {
    // Debounce rendering.
    setTimeout(() => setRender(true), 50);
  }, []);

  if (!render) return null;

  return (
    <Wrapper>
      <SuggestionContainer>
        <Typography use="headline6" rank="primary">
          <FormattedMessage {...messages.title} />
        </Typography>
        <AddTemplate outlined rank="accent" onClick={onAdd}>
          <FormattedMessage {...messages.addBtn} />
        </AddTemplate>
      </SuggestionContainer>
    </Wrapper>
  );
}

export default Templates;
