import { secureCall, json, JSON_HEADER } from './api';

export const createStudyApi = ({ code, name }) =>
  secureCall('/studies', {
    method: 'POST',
    headers: JSON_HEADER,
    body: JSON.stringify({ code, name }),
  }).then(json);

export const fetchStudiesApi = id =>
  secureCall(`/studies`, {
    method: 'GET',
  }).then(json);

export const generateParticipantsApi = ({ studyId, count }) =>
  secureCall(`/studies/${studyId}/cohort`, {
    method: 'POST',
    headers: JSON_HEADER,
    body: JSON.stringify({ count }),
  }).then(json);
