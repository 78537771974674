import types from './detailedView.types';
import { clientsTypes } from '../../clients/';
import { combineReducers } from 'redux';
import { formatNutrientNestedChild } from '../../../../constants/nutriments';

const _sortNutrients = nutrients => nutrients.sort((a, b) => a.order - b.order);
const _removeFirstLevelChild = nutrients => nutrients.filter(n => !n.parent);

const _addParent = (parent, nutrients) =>
  _sortNutrients([
    ...nutrients,
    formatNutrientNestedChild({ ...parent, isActive: true }, nutrients),
  ]);
const _addChild = (child, nutrients) =>
  nutrients.map(n => {
    if (n.id === child.parent) {
      return {
        ...n,
        children: _sortNutrients([...n.children, child]),
        isActive: true,
      };
    }
    return n;
  });

const _removeParent = (parent, nutrients) =>
  nutrients.filter(n => n.id !== parent.id);
const _removeChild = (child, nutrients) =>
  nutrients.map(n => {
    if (n.id === child.parent) {
      return {
        ...n,
        children: n.children.filter(c => c.id !== child.id),
      };
    }
    return n;
  });

const _nutrientClick = (nutrientId, nutrients) =>
  nutrients.map(n => {
    if (n.id === nutrientId) {
      return {
        ...n,
        isActive: !n.isActive,
      };
    }
    return n;
  });

export const nutrientColumns = (state = [], action) => {
  switch (action.type) {
    case types.INIT:
      const nutrientsWithChild = action.nutrients.map(n =>
        formatNutrientNestedChild(n, action.nutrients)
      );
      const nutrients = _sortNutrients(
        _removeFirstLevelChild(nutrientsWithChild)
      );
      return nutrients.map(n => ({
        ...n,
        isActive: false,
      }));
    case clientsTypes.ADD_CLIENT_NUTRIENT:
      return !action.nutrient.parent
        ? _addParent(action.nutrient, state)
        : _addChild(action.nutrient, state);
    case clientsTypes.REMOVE_CLIENT_NUTRIENT:
      return !action.nutrient.parent
        ? _removeParent(action.nutrient, state)
        : _removeChild(action.nutrient, state);
    case types.NUTRIENT_CLICK:
      return !action.nutrient.parent
        ? _nutrientClick(action.nutrient.id, state)
        : _nutrientClick(action.nutrient.parent, state);
    default:
      return state;
  }
};

export default combineReducers({
  nutrientColumns,
});
