import React, { memo } from 'react';
import {
  DataTableRow,
  DataTableHead,
  DataTableHeadCell,
} from 'components/material/DataTable';
import { FormattedMessage } from 'react-intl';
import { SORT_ORDERS, HEIGHT_BEFORE_CLIENT_LIST } from '../../../../constants';
import ClientsCheckbox from '../ClientsCheckbox';
import HorizontalLoader from 'components/loaders/HorizontalLoader';
import NoResults from './NoResults';
import { ClientRow } from './ClientRow';
import { areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualTable from './VirtualTable';
import memoize from 'memoize-one';

const Row = memo(({ index, data }) => {
  const client = data.clients[index];
  return (
    <ClientRow
      id={client.id}
      status={client.status}
      selected={client.selected}
      columns={data.columns}
      canRunOnboard={data.canRunOnboard}
      setFictiveClientRun={data.setFictiveClientRun}
    />
  );
}, areEqual);

const createItemData = memoize(
  (clients, columns, canRunOnboard, setFictiveClientRun) => ({
    clients,
    columns,
    canRunOnboard,
    setFictiveClientRun,
  })
);

function ClientList({
  isFetching,
  clients,

  columns,
  sortColumn,
  sortOrder,
  selectSortColumn,
  invertSortOrder,

  onAddClientClick,
  setFictiveClientRun,
  canRunOnboard,
}) {
  const isLoading = () => isFetching && !clients.length;
  const hasNoClients = () => !isLoading() && !clients.length;

  const renderHeadCell = ({ key, label, props, noSort }) => {
    return (
      <DataTableHeadCell
        key={key}
        style={{
          textTransform: 'uppercase',
          height: 52,
        }}
        onSortChange={() => {
          if (noSort) return;
          if (sortColumn !== key) return selectSortColumn(key);

          invertSortOrder();
        }}
        sort={
          sortColumn === key
            ? sortOrder === SORT_ORDERS.ASCENDING
              ? 1
              : -1
            : noSort
            ? undefined
            : null
        }
        {...props}
      >
        <FormattedMessage {...label} />
      </DataTableHeadCell>
    );
  };

  const renderLoader = () => (
    <div style={{ flex: 1 }}>
      <HorizontalLoader colspan="100" />
    </div>
  );

  const renderNoResults = () => (
    <div style={{ flex: 1 }}>
      <NoResults onAddClientClick={onAddClientClick} />
    </div>
  );

  const renderHead = () => {
    return (
      <DataTableHead>
        <DataTableRow>
          <DataTableHeadCell style={{ position: 'sticky', top: 0 }}>
            <ClientsCheckbox />
          </DataTableHeadCell>
          {columns.map(renderHeadCell)}
        </DataTableRow>
      </DataTableHead>
    );
  };

  if (isLoading()) return renderLoader();
  if (hasNoClients()) return renderNoResults();

  const itemData = createItemData(
    clients,
    columns,
    canRunOnboard,
    setFictiveClientRun
  );
  return (
    <AutoSizer>
      {({ height, width }) => (
        <VirtualTable
          height={height - HEIGHT_BEFORE_CLIENT_LIST}
          width={width}
          itemCount={clients.length}
          itemSize={51}
          header={renderHead()}
          row={Row}
          itemData={itemData}
        />
      )}
    </AutoSizer>
  );
}

export default ClientList;
