import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { ThemeProvider } from '../Root/Providers';

const customStyles = {
  content: {
    top: '30%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '550px',
    marginRight: '-50%',
    width: '100%',
    height: 'auto',
    overflow: 'visible',
    WebkitFontSmoothing: 'antialiased',
    transform: 'translate(-50%, -30%)',
    overscrollBehavior: 'contain',
  },
};

const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.palette.primary1};
  position: fixed;
  right: -15px;
  top: -15px;
  background-color: ${props => props.theme.palette.white};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.palette.primary1};
  padding: 3px;
  font-weight: bold;
  transition: box-shadow 0.2s ease-in-out, -webkit-text-stroke 0.2s ease-in-out;
  z-index: 100;

  &:hover {
    box-shadow: inset 0 0 transparent,
      0 0 0 1px ${props => props.theme.palette.primary1};
    -webkit-text-stroke: 0.04em ${props => props.theme.palette.primary1};
  }
`;
const CloseIcon = styled.i`
  font-size: 18px;
`;

function Modal({ isOpen, onClose, children, style }) {
  return (
    <ReactModal
      style={{
        content: {
          ...customStyles.content,
          ...style,
        },
        zIndex: 1000,
        position: 'relative',
      }}
      ariaHideApp={false}
      onRequestClose={onClose}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      contentLabel="Just to not have a warning"
    >
      <ThemeProvider>
        <div>
          <CloseButton className="clickable" onClick={onClose}>
            <CloseIcon className="material-icons">close</CloseIcon>
          </CloseButton>
          {isOpen && children}
        </div>
      </ThemeProvider>
    </ReactModal>
  );
}

export default Modal;
