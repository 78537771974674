import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const RENDERING_CONTEXT = {
  PDF: 'pdf',
  DOM: 'dom',
};
export const RenderingContext = React.createContext(RENDERING_CONTEXT.DOM);

export const withRenderingContext = Component => {
  const hoc = React.forwardRef((props, ref) =>
    <RenderingContext.Consumer>
      {renderingContext =>
        <Component {...props} renderingContext={renderingContext} ref={ref} />}
    </RenderingContext.Consumer>
  );
  hoistNonReactStatics(hoc, Component);
  return hoc;
};

export default RenderingContext;
