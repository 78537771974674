import types from './foods.types';
import { fetchFoodApi } from '../../../api/foodSearch';
import schemas from '../../schemas';
import { getFood } from './foods.selectors';
import { normalize } from 'normalizr';

export const fetchFood = foodId => ({
  types: [
    types.FETCH_FOOD_REQUEST,
    types.FETCH_FOOD_SUCCESS,
    types.FETCH_FOOD_FAILURE,
  ],
  callAPI: () => fetchFoodApi(foodId).then(res => normalize(res, schemas.food)),
  shouldCallAPI: state => !getFood(state, foodId),
  payload: { foodId },
});
