import React from 'react';
import styled from 'styled-components';
import WhiteFrame from 'components/WhiteFrame';
import DatePicker from './DatePicker';
import CompareModeSwitch from './CompareModeSwitch';

export const CALENDAR_WIDTH = 260;
const StyledWhiteframe = styled(WhiteFrame)`
  padding: 0;
  color: ${props => props.theme.palette.text.primary};
  min-height: 350px;
  display: flex;
  flex-direction: column;
  width: ${CALENDAR_WIDTH}px;
`;

export default function Calendar({ params }) {
  return (
    <StyledWhiteframe>
      <DatePicker params={params} />
      <CompareModeSwitch />
    </StyledWhiteframe>
  );
}
