import React, { useEffect } from 'react';
import {
  DataTable,
  DataTableContent,
  DataTableRow,
  DataTableHead,
  DataTableHeadCell,
  DataTableCell,
  HoverableDataTableBody,
} from 'components/material/DataTable';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import Checkbox from 'components/forms/Checkbox';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useState } from 'react';
import HorizontalLoader from 'components/loaders/HorizontalLoader';
import { USER_FOODS_SORT_COLUMNS, SORT_ORDERS } from 'constants/index.js';
import { Button } from 'components/material/Button';
import SearchBar from 'components/SearchBar';
import { useVisibleElements, sortElements } from 'utils/hooks';
import Spacer from 'components/Spacer';
import { Typography } from 'components/material/Typography';
import { PageWrapper } from 'components/PageWrapper';
import styled from 'styled-components';
import NoUserFoods from './NoUserFoods';
import UserFoodActions from './UserFoodActions';
import UserFoodCheckbox from './UserFoodCheckbox';
import { UserFoodsCheckbox } from './UserFoodCheckbox/index.foods';

const messages = defineMessages({
  title: { id: 'userFoods.title' },
  newFood: { id: 'userFoods.newFood' },
  search: { id: 'userFoods.search' },

  name: { id: 'userFoods.columns.name' },
  serving: { id: 'userFoods.columns.serving' },
  shared: { id: 'userFoods.columns.shared' },
});

const ActionsWrapper = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: -39px;
  right: 0;
`;

const SORT_COLUMNS = USER_FOODS_SORT_COLUMNS;
const COLUMNS = [
  {
    key: SORT_COLUMNS.NAME,
    label: { defaultMessage: 'Name', id: 'userFoods.columns.name' },
    props: { alignStart: true },
  },
  {
    key: SORT_COLUMNS.SERVING,
    label: { defaultMessage: 'Serving', id: 'userFoods.columns.serving' },
    props: { alignStart: true },
  },
  {
    key: SORT_COLUMNS.IS_SHARED,
    label: {
      defaultMessage: 'Visible to clients',
      id: 'userFoods.columns.shared',
    },
    props: { alignStart: true },
  },
];

function FoodRow(props) {
  const { id, name, serving, is_shared_with_clients } = props;
  const match = useRouteMatch();
  const history = useHistory();

  const onClick = () => {
    history.push(`${match.url}/${id}`);
  };

  const formatAmount = value => {
    const precision = 2;
    const power = Math.pow(10, precision);
    return String(Math.round(value * power) / power);
  };

  return (
    <DataTableRow key={id} onClick={onClick}>
      <DataTableCell alignEnd>
        <UserFoodCheckbox id={id} />
      </DataTableCell>
      <DataTableCell alignStart style={{ minWidth: 175 }}>
        {name}
      </DataTableCell>
      <DataTableCell alignStart>{`${formatAmount(serving.amount)} ${
        serving.name
      }`}</DataTableCell>
      <DataTableCell alignStart>
        <Checkbox disabled checked={!!is_shared_with_clients} />
      </DataTableCell>
    </DataTableRow>
  );
}

export function UserFoods({
  foods,

  fetchFoods,

  sortColumn,
  sortOrder,
  selectSortColumn,
  invertSortOrder,
}) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const history = useHistory();
  const intl = useIntl();
  const match = useRouteMatch();

  let visibleFoods = useVisibleElements(search, foods, [
    'name',
    'serving_name',
  ]);
  const sortedFoods = sortElements(visibleFoods, sortColumn, sortOrder);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await fetchFoods();
      setLoading(false);
    };

    load();
  }, []);

  const renderLoader = () => (
    <div style={{ flex: 1 }}>
      <HorizontalLoader colspan="100" />
    </div>
  );

  const renderBody = () => {
    return (
      <HoverableDataTableBody>
        {sortedFoods.map(r => (
          <FoodRow {...r} key={r.id} />
        ))}
      </HoverableDataTableBody>
    );
  };

  const renderHeadCell = ({ key, label, props, noSort }) => (
    <DataTableHeadCell
      key={key}
      style={{ textTransform: 'uppercase' }}
      onClick={() => {
        if (noSort) return;
        if (sortColumn !== key) return selectSortColumn(key);

        invertSortOrder();
      }}
      sort={
        sortColumn === key
          ? sortOrder === SORT_ORDERS.ASCENDING
            ? 1
            : -1
          : noSort
          ? undefined
          : null
      }
      {...props}
    >
      <FormattedMessage {...label} />
    </DataTableHeadCell>
  );

  const renderHead = () => {
    if (loading && !foods.length) return <div style={{ height: 60 }} />;

    return (
      <DataTableHead>
        <DataTableRow>
          <DataTableHeadCell style={{ width: 75 }}>
            <UserFoodsCheckbox />
          </DataTableHeadCell>
          {COLUMNS.map(renderHeadCell)}
        </DataTableRow>
      </DataTableHead>
    );
  };

  const openCreateFood = () => {
    history.push(`${match.url}/create`);
  };

  const renderContent = () => {
    if (loading && !foods.length) return renderLoader();

    if (!sortedFoods.length) return <NoUserFoods onAdd={openCreateFood} />;

    return (
      <DataTable
        stickyRows={1}
        stickyColumns={0}
        style={{
          border: 'none',
          maxHeight: 'calc(100vh - 158px)',
          width: '100%',
        }}
        className="no-scrollbar"
      >
        <DataTableContent style={{ width: '100%' }}>
          {renderHead()}
          {renderBody()}
        </DataTableContent>
      </DataTable>
    );
  };

  return (
    <PageWrapper>
      <Typography use="headline5" rank="primary" tag="div" spacing="lg">
        <FormattedMessage {...messages.title} />
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <SearchBar
          value={search}
          onInputChange={value => setSearch(value)}
          placeholder={intl.formatMessage(messages.search)}
        />
        <ActionsWrapper>
          <UserFoodActions />
        </ActionsWrapper>
        <Spacer spacing="xxl" />
        <div>
          <Button
            unelevated
            onClick={openCreateFood}
            style={{ whiteSpace: 'nowrap' }}
          >
            <FormattedMessage {...messages.newFood} />
          </Button>
        </div>
      </div>
      <Spacer spacing="xl" />
      {renderContent()}
    </PageWrapper>
  );
}
