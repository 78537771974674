import types from './auth.types';

const initialState = () => ({
  loggedIn: false,
  resetEmailSent: false,
  loading: true,
});

export default function reducer(state = initialState(), action = {}) {
  switch (action.type) {
    case types.LOGIN_START:
    case types.SIGNUP_START:
      return {
        ...state,
        errorType: null,
      };
    case types.LOGIN_SUCCESS:
    case types.SIGNUP_SUCCESS:
    case types.PROFILE_LOAD_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false,
      };
    case types.LOGIN_FAILURE:
    case types.SIGNUP_FAILURE:
      return {
        ...state,
        errorType: action.errorType,
      };
    case types.PASSWORD_RESET_SENT:
      return {
        ...state,
        resetEmailSent: true,
        resetError: '',
      };
    case types.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        executeResetError: '',
        passwordReset: true,
      };
    case types.PASSWORD_RESET_FAILURE:
      return {
        ...state,
        resetError: action.errorType,
      };
    case types.EXECUTE_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        executeResetError: action.errorType,
      };
    case types.PROFILE_LOAD_UNAUTHORIZED:
    case types.LOGOUT:
      return {
        ...state,
        loading: false,
        loggedIn: false,
      };
    default:
      return state;
  }
}
