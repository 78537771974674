import { combineReducers } from 'redux';
import { makeById } from '../../../utils/standardReducers';
import { getMeal } from '../meals/meals.selectors';
import get from 'lodash/get';
import createCachedSelector from 're-reselect';
import { createShallowEqualSelector } from '../../../utils/selectUtils';

export default combineReducers({
  byId: makeById('comments'),
});

/// SELECTORS
export const _getById = state => state.comments.byId;

export const _getMealMessages = createCachedSelector(
  [getMeal, _getById],
  (meal, byId) => get(meal, 'comments', []).map(c => byId[c]).reverse()
)((_, mealId) => mealId);

// We split in 2 to be able to use the shallowEqualCheck here. This
// way, component linked to this selector wont re-render everytimes that
// commentsById change.
export const getMealMessages = createCachedSelector(
  _getMealMessages,
  messages => messages
)((_, mealId) => mealId, {
  selectorCreator: createShallowEqualSelector,
});
