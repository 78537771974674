import { combineReducers } from 'redux';
import detailedView from './detailedView';
import clientsView from './clientsView';
import clientView from './clientView';
import reportView from './reportView';
import reportsView from './reportsView';
import builderView from './builderView';
import userFoodsView from './userFoodsView';

export default combineReducers({
  detailedView,
  clientsView,
  clientView,
  reportView,
  reportsView,
  builderView,
  userFoodsView,
});
