import React from 'react';
import { SORT_COLUMNS } from '../../../../constants';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import FormattedDate from 'components/FormattedDate';
import {
  getClientProfileImage,
  getClientNotifications,
  getClientFirstName,
  getClientLastName,
  getClientLearnerName,
  getClientFormattedCourses,
  getClientInviteEmail,
  getClientParticipantCode,
  getClientStatus,
  getClientAcceptDate,
} from 'redux/modules/clients/clients.selectors';
import styled, { useTheme, keyframes } from 'styled-components';
import { CLIENT_STATUS } from '../../../../constants/clientStatus';
import { Tooltip } from 'react-tippy';

const Dot = styled.span`
  height: 8px;
  width: 8px;
  margin-right: 2px;
  background-color: ${x => x.color};
  border-radius: 50%;
  display: inline-block;
`;

const messages = defineMessages({
  firstName: { id: 'clients.firstName' },
  lastName: { id: 'clients.lastName' },
  email: { id: 'clients.email' },
  status: { id: 'clients.status' },
  participantId: { id: 'clients.participantId' },
  projectCode: { id: 'clients.projectCode' },
  acceptDate: { id: 'clients.acceptDate' },
  active: { id: 'client.active' },
  inactive: { id: 'client.inactive' },
  archived: { id: 'client.archived' },
  declined: { id: 'client.declined' },
  pending: { id: 'client.pending' },
  revoked: { id: 'client.revoked' },
  fictive: { id: 'client.fictive' },
  learnerName: { id: 'clients.learnerName' },
  courses: { id: 'clients.courses' },

  fictiveTitle: {
    id: 'client.fictive.title',
    defaultMessage: 'Fictive client 👀',
  },
  fictiveContent: {
    id: 'client.fictive.content',
    defaultMessage:
      'While your clients accept your invitation and start snapping pictures of their meals, we have added a fictive client for you to explore the platform. Click here to view this client’s photos and dietary intake.',
  },
  fictiveClose: {
    id: 'client.fictive.close',
    defaultMessage: 'Awesome !',
  },
});

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const ProfileImg = styled.img`
  transform: scale(0.113);
  border-radius: 4px;

  animation: ${props => (props.out ? fadeOut : fadeIn)} 0.225s linear;
`;

const ProfileImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Badge = styled.div`
  position: absolute;
  top: 4px;
  right: 8px;
  padding: 1px 3px;
  color: white;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fa3e3e;
  background-clip: padding-box;
  display: inline-block;
  font-size: 10px;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 1.3;
  min-height: 13px;
  font-weight: 600;
  z-index: 1;
`;

const FixedWidth = styled.div`
  width: 100px;
`;
const CourseCell = styled.div`
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProfileImage = React.memo(_ProfileImage);
function _ProfileImage({ id }) {
  const src = useSelector(s => getClientProfileImage(s, id));
  const notifications = useSelector(s => getClientNotifications(s, id));
  return (
    <ProfileImageWrapper>
      <ProfileImg src={src} />
      {notifications ? <Badge>{notifications}</Badge> : null}
    </ProfileImageWrapper>
  );
}

const Courses = React.memo(_Courses);
function _Courses({ id }) {
  const courses = useSelector(s => getClientFormattedCourses(s, id));

  return (
    <CourseCell>
      <Tooltip
        position="bottom"
        title={courses}
        trigger="mouseenter"
        animateFill={false}
        interactive={true}
        duration={105}
        delay={500}
        arrow={false}
        theme="light"
        animtation="fade"
        interactiveBorder={0}
      >
        {courses}
      </Tooltip>
    </CourseCell>
  );
}

const LearnerName = React.memo(_LearnerName);
function _LearnerName({ id }) {
  const learnerName = useSelector(s => getClientLearnerName(s, id));

  return <FixedWidth>{learnerName}</FixedWidth>;
}

const FirstName = React.memo(_FirstName);
function _FirstName({ id }) {
  const firstName = useSelector(s => getClientFirstName(s, id));

  return <FixedWidth>{firstName}</FixedWidth>;
}

const LastName = React.memo(_LastName);
function _LastName({ id }) {
  const lastName = useSelector(s => getClientLastName(s, id));
  return <FixedWidth>{lastName}</FixedWidth>;
}

const Email = React.memo(_Email);
function _Email({ id }) {
  const email = useSelector(s => getClientInviteEmail(s, id));
  return <FixedWidth>{email}</FixedWidth>;
}

const Status = React.memo(_Status);
function _Status({ id }) {
  const theme = useTheme();
  const status = useSelector(s => getClientStatus(s, id));

  const { success, warning, error, neutral } = theme.palette;
  let color;
  let message;
  let icon;
  if (status === CLIENT_STATUS.ACTIVE) {
    icon = 'check-circle';
    color = success;
    message = messages.active;
  } else if (status === CLIENT_STATUS.FICTIVE) {
    icon = 'check-circle';
    color = success;
    message = messages.fictive;
  } else if (status === CLIENT_STATUS.PENDING) {
    color = warning;
    icon = 'exclamation-circle';
    message = messages.pending;
  } else if (status === CLIENT_STATUS.REVOKED) {
    color = error;
    icon = 'exclamation-triangle';
    message = messages.revoked;
  } else if (status === CLIENT_STATUS.DECLINED) {
    color = error;
    icon = 'exclamation-triangle';
    message = messages.declined;
  } else if (status === CLIENT_STATUS.ARCHIVED) {
    color = neutral;
    icon = 'check-circle';
    message = messages.archived;
  } else {
    color = warning;
    message = messages.pending;
  }
  return (
    <FixedWidth>
      <Dot color={color} />
      <FormattedMessage {...message} />
    </FixedWidth>
  );
}

const ParticipantId = React.memo(_ParticipantId);
function _ParticipantId({ id }) {
  const code = useSelector(s => getClientParticipantCode(s, id));
  return <div>{code}</div>;
}

const AcceptDate = React.memo(_AcceptDate);
function _AcceptDate({ id }) {
  const acceptDate = useSelector(s => getClientAcceptDate(s, id));
  if (!acceptDate) return null;
  return (
    <FixedWidth>
      <FormattedDate date={new Date(acceptDate)} />
    </FixedWidth>
  );
}

const COLUMNS = {
  IMAGE: {
    key: 'image',
    label: { defaultMessage: ' ', id: 'clients.columns.image' },
    props: { alignEnd: true, style: { width: 75 } },
    noSort: true,
    render: id => <ProfileImage id={id} />,
  },
  FIRST_NAME: {
    key: SORT_COLUMNS.FIRST_NAME,
    label: messages.firstName,
    props: { alignStart: true },
    render: id => <FirstName id={id} />,
  },
  LAST_NAME: {
    key: SORT_COLUMNS.LAST_NAME,
    label: messages.lastName,
    props: { alignStart: true },
    render: id => <LastName id={id} />,
  },
  EMAIL: {
    key: SORT_COLUMNS.EMAIL,
    label: messages.email,
    props: {
      alignStart: true,
      style: {
        width: 400,
      },
    },
    render: id => <Email id={id} />,
  },
  STATUS: {
    key: SORT_COLUMNS.STATUS,
    label: messages.status,
    props: {
      alignStart: true,
    },
    render: id => <Status id={id} />,
  },
  PARTICIPANT_ID: {
    key: SORT_COLUMNS.PARTICIPANT_ID,
    label: messages.participantId,
    render: id => <ParticipantId id={id} />,
  },
  PROJECT_CODE: {
    key: SORT_COLUMNS.PARTICIPANT_ID,
    label: messages.projectCode,
  },
  ACCEPT_DATE: {
    key: SORT_COLUMNS.ACCEPT_DATE,
    label: messages.acceptDate,
    props: {
      alignStart: true,
    },
    render: id => <AcceptDate id={id} />,
  },
  LEARNER_NAME: {
    key: SORT_COLUMNS.LEARNER_NAME,
    label: messages.learnerName,
    render: id => <LearnerName id={id} />,
  },
  COURSES: {
    key: SORT_COLUMNS.COURSES,
    label: messages.courses,
    render: id => <Courses id={id} />,
  },
};
const DIETITIAN_COLUMNS = [
  COLUMNS.IMAGE,
  COLUMNS.FIRST_NAME,
  COLUMNS.LAST_NAME,
  COLUMNS.EMAIL,
  COLUMNS.STATUS,
];
const RESEARCHER_COLUMNS = [
  COLUMNS.IMAGE,
  // COLUMNS.PROJECT_CODE,
  COLUMNS.PARTICIPANT_ID,
  COLUMNS.STATUS,
  COLUMNS.ACCEPT_DATE,
];
const INSTRUCTOR_COLUMNS = [
  COLUMNS.IMAGE,
  COLUMNS.COURSES,
  COLUMNS.LEARNER_NAME,
  COLUMNS.FIRST_NAME,
  COLUMNS.LAST_NAME,
  COLUMNS.EMAIL,
  COLUMNS.STATUS,
];
export function getClientsColumns({ isResearcher, isInstructor }) {
  if (isResearcher) return RESEARCHER_COLUMNS;

  if (isInstructor) return INSTRUCTOR_COLUMNS;

  return DIETITIAN_COLUMNS;
}
