import React from 'react';
import Color from 'color';
import { PieChart, Pie, Cell } from 'recharts';
import styled, { withTheme } from 'styled-components';
import { NUTRIENT_COLORS } from '../../../../../constants/nutriments';

export const DEFAULT_MAX_RADIUS = 50;
const MIN_RADIUS = 10;

const PieChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

class MyPieChart extends React.PureComponent {
  render() {
    const { formattedNutrients, energy, theme } = this.props;
    const maxEnergy = this.props.maxEnergy || energy;
    const maxRadius = this.props.maxRadius || DEFAULT_MAX_RADIUS;
    const minArea = Math.PI * (MIN_RADIUS * MIN_RADIUS);
    const maxArea = Math.PI * (maxRadius * maxRadius);
    const actualArea = minArea + ((maxArea - minArea) * energy) / maxEnergy;
    const actualSize = Math.sqrt(actualArea / Math.PI);

    return (
      <PieChartWrapper style={this.props.row ? { paddingRight: 10 } : {}}>
        <PieChart width={maxRadius * 2} height={maxRadius * 2}>
          <Pie
            dataKey={'value'}
            data={formattedNutrients.map(x => ({ ...x, value: x.energy }))}
            cx={'50%'}
            cy={'50%'}
            outerRadius={actualSize}
            fill="#8884d8"
          >
            {formattedNutrients.map((n, index) => (
              <Cell
                key={n.code}
                fill={Color(theme.palette[NUTRIENT_COLORS[n.id]])
                  .fade(0.4)
                  .toString()}
              />
            ))}
          </Pie>
        </PieChart>
      </PieChartWrapper>
    );
  }
}

export default withTheme(MyPieChart);
