import React, { useEffect } from 'react';
import styled from 'styled-components';
import TestClientModal from './modals/TestClientModal';
import RevokedModal from './modals/RevokedModal';
import { isTokenValid } from '../../utils/auth';
import { Redirect, useLocation, Switch, Route } from 'react-router-dom';
import Clients from './Clients';
import Account from './Account';
import Library from './Library';
import Private from './Private';
import LeftNav from './LeftNav';
import { notify } from 'react-notify-toast';
import { defineMessages, useIntl } from 'react-intl';
import { identifyGA } from 'components/GAListener';

const messages = defineMessages({
  badToken: { id: 'onboard.invalidToken' },
});

const MainLayout = styled.div`
  flex: 1;
  overflow-y: auto !important;
  overflow-x: auto !important;
`;

export default function Dashboard({ loadProfile, loadClients }) {
  const location = useLocation();
  const intl = useIntl();

  useEffect(() => {
    loadProfile().then(identifyGA);
    loadClients();
  }, []);

  if (!isTokenValid()) {
    setTimeout(() => {
      notify.show(intl.formatMessage(messages.badToken), 'info');
    }, 250);

    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location.pathname },
        }}
      />
    );
  }

  const renderRoutes = () => (
    <Switch>
      <Route path={'/clients'}>
        <Clients />
      </Route>
      <Route path={'/account'}>
        <Account />
      </Route>
      <Route path={'/library'}>
        <Library />
      </Route>
      <Route path={'/private'}>
        <Private />
      </Route>
    </Switch>
  );

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex' }}>
      <LeftNav />
      <MainLayout>{renderRoutes()}</MainLayout>
      <TestClientModal />
      <RevokedModal />
    </div>
  );
}
