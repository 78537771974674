import React from 'react';
import styled from 'styled-components';
import NutrientTd from './NutrientTd';
import { NUTRIENT_COLORS } from '../../../../../../constants/nutriments';
import Color from 'color';
import { FormattedMessage } from 'react-intl';
import InfoText from 'components/InfoText';

const SumTr = styled.tr`
  color: ${props =>
    props.final
      ? props.theme.black
      : props.theme.palette.text.primary.secondary};
  font-weight: ${props => (props.final ? '900' : '600')} !important;
  font-size: ${props => (props.final ? '16px' : '14px')} !important;
  backgroundcolor: ${props =>
    props.final
      ? Color('black')
          .fade(0.93)
          .toString()
      : 'inherit'};
`;

const StyledNutrientTd = styled(NutrientTd)`
  padding: 6px 0;
  ${props =>
    `border-${props.top ? 'bottom' : 'top'}: ${
      props.final ? '2px' : '1px'
    } solid ${props.noBorder ? 'transparent' : props.theme.palette.border}`};
  ${props =>
    props.double
      ? 'border-top: 2px solid ' + props.theme.palette.border + ''
      : ''};

  color: ${props => {
    return NUTRIENT_COLORS[props.nutrient.id]
      ? props.theme.palette[NUTRIENT_COLORS[props.nutrient.id]]
      : 'inherit';
  }};
`;

const TotalTd = styled.td`
  text-align: right;
  padding-right: 15px;
  font-weight: 500 !important;
`;

const NutrientsSumRow = ({
  nutrimentsToShow,
  sum,
  final,
  top,
  double,
  message,
  percentage,
  helpMessage,
  noBorder,
}) => (
  <SumTr final={final} className="mdc-typography--body2">
    <TotalTd>
      {message ? (
        helpMessage ? (
          <InfoText textMessage={message} tooltipMessage={helpMessage} />
        ) : (
          <FormattedMessage {...message} />
        )
      ) : null}
    </TotalTd>
    {nutrimentsToShow.map(n => (
      <StyledNutrientTd
        final={final}
        noBorder={noBorder}
        top={top}
        double={double}
        key={n.id}
        nutrient={n}
        values={sum}
        percentage={percentage}
      />
    ))}
  </SumTr>
);

export default NutrientsSumRow;
