import React from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import FoodLog from './FoodLog';
import { getNutrimentFromCode } from 'constants/nutriments';
import WhiteFrame from 'components/WhiteFrame';
import CircleLoader from 'components/loaders/CircleLoader';
import { NUTRIENT_COLORS } from 'constants/nutriments';
import Calendar, { CALENDAR_WIDTH } from '../../components/Calendar';

import ToolsBox from './ToolsBox/ToolsBoxContainer';
import Graph from './Graph';
import EditEntryModal from '../../modals/EditEntryModal';
import SearchFoodModal from '../../modals/SearchFoodModal';
import Measure from 'react-measure';
import throttle from 'lodash/throttle';
import Toolbar from '../../components/Toolbar';
import ProfileModal from './FoodLog/ProfileModal';

const messages = defineMessages({
  noDiaryAvailable: {
    id: 'foodDiary.noDiaryAvailableTitle',
    defaultMessage:
      'No food diary yet! Once your clients enters meals, you’ll see them here.',
  },
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
`;

const GraphWrapper = styled(WhiteFrame)`
  flex: 1;
  margin-bottom: 10px;
  padding-right: 20px;
`;

const FoodLogFrame = styled.div`
  font-size: 12px;
  color: ${props => props.theme.palette.text.primary};
`;

const EmptyFoodLog = styled(WhiteFrame)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  flex: 1 100%;
`;

const LoadingState = () => (
  <EmptyFoodLog>
    <CircleLoader />
  </EmptyFoodLog>
);

const EmptyState = () => (
  <EmptyFoodLog>
    <FormattedMessage {...messages.noDiaryAvailable} />
  </EmptyFoodLog>
);

class DetailedTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this._onYScroll = throttle(this._onYScroll, 250);
    // The minimum space between the bottom of the screen and the
    // nutrients topBar to show the scrollBar
    this._REQUIRED_FOR_BAR = 900;
    this.state = {
      width: 0,
      visible: false,
    };
  }

  componentDidMount() {
    this.wrapper.addEventListener('scroll', this._onWrapperScroll);
    this.bar.addEventListener('scroll', this._onBarScroll);
    window.addEventListener('scroll', this._onYScroll);
  }
  componentWillUnmount() {
    this.wrapper.removeEventListener('scroll', this._onScroll);
    this.bar.removeEventListener('scroll', this._onBarScroll);
    window.removeEventListener('scroll', this._onYScroll);
  }
  _onWrapperScroll = () => {
    this.bar.scrollLeft = this.wrapper.scrollLeft;
  };
  _onBarScroll = () => {
    this.wrapper.scrollLeft = this.bar.scrollLeft;
  };
  _onYScroll = e => {
    const innerHeight = window.innerHeight;
    const yPosition = window.scrollY;
    const { visible } = this.state;

    const shouldBeVisible = yPosition > this._REQUIRED_FOR_BAR - innerHeight;
    if (visible !== shouldBeVisible) {
      this.setState({
        visible: shouldBeVisible,
      });
    }
  };

  renderScrollBar = () => {
    const { width } = this.state;
    return (
      <div
        ref={x => {
          this.bar = x;
        }}
        style={{
          position: 'absolute',
          bottom: 5,
          transform: `translateY(${this.state.visible ? 0 : '30px'}`,
          transition: 'all 0.1s ease-in-out',
          left: 20,
          right: 20,
          height: 20,
          overflowX: 'auto',
        }}
      >
        <div
          style={{
            width: width - 20,
            height: 20,
            backgroundColor: 'white',
            opacity: 0.01,
          }}
        />
      </div>
    );
  };

  render() {
    const { dietitianNutrients, clientNutrients, onNutrientClose } = this.props;
    return (
      <>
        <ToolsBox
          elements={dietitianNutrients}
          elementsInUse={clientNutrients}
          chipColorMapper={NUTRIENT_COLORS}
        />

        <WhiteFrame
          style={{
            flex: '1 100%',
            minWidth: 600,
            overflow: 'hidden',
            marginBottom: -5,
          }}
        >
          <div
            style={{
              overflowX: 'auto',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: -30,
              position: 'relative',
              background: 'white',
              paddingBottom: 60,
            }}
            ref={x => (this.wrapper = x)}
          >
            <FoodLogFrame>
              <Measure
                bounds
                onResize={contentRect => {
                  this.setState({
                    width: contentRect.bounds.width,
                  });
                }}
              >
                {({ measureRef }) => (
                  <FoodLog
                    innerRef={measureRef}
                    onMealHeaderClose={onNutrientClose}
                    nutrientsList={clientNutrients}
                  />
                )}
              </Measure>
            </FoodLogFrame>
          </div>
          {this.renderScrollBar()}
        </WhiteFrame>
      </>
    );
  }
}

class DetailedFoodLog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.props.initDetailedView(this.props.clientNutrients);
  }

  _onNutrientClose = nutrient => {
    // UI props, we need to fetch the corresponding model in constant before sending to B-E
    this.props.removeNutrientFromClient(
      getNutrimentFromCode(nutrient.code),
      this.props.clientNutrients
    );

    // When we close nutrient that is not in dietitian toolbox, we add it to dietitianNutrients
    if (!this.props.dietitianNutrients.some(n => n.id === nutrient.id)) {
      this.props.addNutrientToToolbox(
        getNutrimentFromCode(nutrient.code),
        this.props.dietitianNutrients
      );
    }
  };

  render() {
    const {
      dietitianNutrients,
      clientNutrients,
      currentFoodDiary,
      hasNoFoodDiary,
      isStillLoading,
    } = this.props;
    return (
      <Wrapper>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            marginRight: 10,
            maxWidth: `calc(100% - ${CALENDAR_WIDTH + 15}px)`,
          }}
        >
          <Toolbar />
          <GraphWrapper>
            <Graph />
          </GraphWrapper>
        </div>
        <Calendar />

        {isStillLoading ? (
          <LoadingState />
        ) : !hasNoFoodDiary && currentFoodDiary ? (
          <DetailedTable
            dietitianNutrients={dietitianNutrients}
            clientNutrients={clientNutrients}
            onNutrientClose={this._onNutrientClose}
          />
        ) : (
          <EmptyState />
        )}
        <div style={{ position: 'relative', zIndex: 1000 }}>
          <EditEntryModal />
          <SearchFoodModal />
          <ProfileModal />
        </div>
      </Wrapper>
    );
  }
}

export default DetailedFoodLog;
