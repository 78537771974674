import React from 'react';
import Modal from 'components/Modal';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Button } from 'components/material/Button';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import H2 from 'components/H2';
import Typography from 'components/material/Typography';
import Spacer from 'components/Spacer';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  openModal,
  MODAL_TYPES,
} from '../../../../redux/modules/modals/modals';

const messages = defineMessages({
  header: { id: 'testClient.header' },
  explanatinon: { id: 'testClient.explanation' },
  opening: { id: 'testClient.opening' },
  invite: { id: 'testClient.invite' },
});

const WarningIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.palette.warning};
`;

function TestClientModal({ isOpen, onExit }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const onInvite = () => {
    onExit();
    history.push('/clients');
    dispatch(openModal(MODAL_TYPES.NEW_CLIENT));
  };

  return (
    <Modal isOpen={isOpen} onClose={onExit}>
      <H2>
        <WarningIcon icon={['fad', 'exclamation-triangle']} />{' '}
        <FormattedMessage {...messages.header} />
      </H2>
      <Spacer spacing="lg" />
      <Typography use="body1" rank="primary">
        <FormattedMessage {...messages.explanatinon} />
      </Typography>
      <Spacer spacing="md" />
      <Typography use="body1" rank="primary">
        <FormattedMessage {...messages.opening} />
      </Typography>
      <Spacer spacing="lg" />
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Button unelevated onClick={onInvite}>
          <FormattedMessage {...messages.invite} />
        </Button>
      </div>
    </Modal>
  );
}

export default TestClientModal;
