export const FRACTION_OPTIONS = [
  { id: 0, label: '-', value: 0 },
  { id: 1, label: '1/8', value: 1 / 8 },
  { id: 2, label: '1/4', value: 1 / 4 },
  { id: 3, label: '1/3', value: 1 / 3 },
  { id: 4, label: '1/2', value: 1 / 2 },
  { id: 5, label: '2/3', value: 2 / 3 },
  { id: 6, label: '3/4', value: 3 / 4 },
];

export default {
  FRACTION_OPTIONS,
};
