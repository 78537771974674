import React, { useEffect } from 'react';
import SearchBar from 'components/SearchBar';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  searchHint: {
    id: 'addFood.searchHint',
    defaultMessage: 'Search',
  },
});

function SearchBox({
  saveRefinement,
  pastRefinement,
  currentRefinement,
  refine,
  intl: { formatMessage },
}) {
  useEffect(() => {
    if (pastRefinement && !currentRefinement) {
      refine(pastRefinement);
    }
  }, [pastRefinement]);

  const _onInputChange = v => {
    saveRefinement(v);
    refine(v);
  };

  return (
    <SearchBar
      value={currentRefinement}
      onInputChange={_onInputChange}
      loading={false}
      placeholder={formatMessage(messages.searchHint)}
    />
  );
}

export default injectIntl(connectAutoComplete(SearchBox));
