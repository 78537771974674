import types from './meals.types';
import { foodDiariesTypes } from '../foodDiaries';
import entriesTypes from '../entries/entries.types';
import commentTypes from '../comments/comments.types';
import { combineReducers } from 'redux';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';

const _addPhotoUrlsToMeals = action => {
  return mapValues(action.response.entities.meals, meal => {
    const photo = get(
      action,
      ['response', 'entities', 'photos', meal.photo],
      null
    );
    return {
      ...meal,
      photo,
    };
  });
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case foodDiariesTypes.FETCH_FOOD_DIARY_SUCCESS:
      return {
        ...state,
        ..._addPhotoUrlsToMeals(action),
      };
    case entriesTypes.DELETE_ENTRY_SUCCESS:
      const updatedMeal = { ...state[action.mealId] };
      const entryIndex = updatedMeal.entries.indexOf(action.entryId);
      updatedMeal.entries.splice(entryIndex, 1);
      return {
        ...state,
        [updatedMeal.id]: updatedMeal,
      };
    case entriesTypes.ADD_ENTRY_SUCCESS:
      return {
        ...state,
        [action.mealId]: {
          ...state[action.mealId],
          entries: [...state[action.mealId].entries, action.response.result],
        },
      };
    case commentTypes.ADD_COMMENT_SUCCESS:
      return {
        ...state,
        [action.mealId]: {
          ...state[action.mealId],
          comments: [...state[action.mealId].comments, action.response.result],
        },
      };
    case commentTypes.FETCH_COMMENTS_SUCCESS:
      return {
        ...state,
        [action.mealId]: {
          ...state[action.mealId],
          comments: action.response.result,
        },
      };
    default:
      return state;
  }
};

export const currentId = (state = null, action) => {
  switch (action.type) {
    case types.SET_CURRENT_MEAL:
      return action.mealId;
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  currentId,
});
