import GroupModal from './GroupModal';
import { connect } from 'react-redux';
import {
  closeModal,
  getModalOpen,
  getModalProps,
  MODAL_TYPES,
} from 'redux/modules/modals/modals';

const mapStateToProps = state => ({
  isOpen: getModalOpen(state, MODAL_TYPES.MEAL_GROUP),
  ...getModalProps(state),
});

const mapDispatchToProps = dispatch => ({
  onExit: () => dispatch(closeModal(MODAL_TYPES.MEAL_GROUP)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupModal);
