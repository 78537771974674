import { connect } from 'react-redux';
import { accountOperations } from 'redux/modules/account';
import {
  closeModal,
  getModalOpen,
  MODAL_TYPES,
} from 'redux/modules/modals/modals';
import EditLocale from './EditLocale';

const mapStateToProps = state => ({
  isOpen: getModalOpen(state, MODAL_TYPES.UPDATE_LOCALE),
});

const mapDispatchToProps = dispatch => ({
  onExit: () => dispatch(closeModal(MODAL_TYPES.UPDATE_LOCALE)),
  onUpdateLocale: locale => {
    dispatch(accountOperations.switchLocale(locale));
    dispatch(closeModal(MODAL_TYPES.UPDATE_NAME));
  },
});

const EditLocaleContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLocale);

export default EditLocaleContainer;
