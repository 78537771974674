import PaymentDueModal from './PaymentDueModal';
import { connect } from 'react-redux';
import {
  closeModal,
  getModalOpen,
  MODAL_TYPES,
  openModal,
} from 'redux/modules/modals/modals';

const mapStateToProps = state => ({
  isOpen: getModalOpen(state, MODAL_TYPES.PAYMENT_DUE),
});

const mapDispatchToProps = dispatch => ({
  onContinue: () => {
    dispatch(openModal(MODAL_TYPES.ADD_PAYMENT));
  },
  onExit: () => {
    dispatch(closeModal(MODAL_TYPES.PAYMENT_DUE));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDueModal);
