import { connect } from 'react-redux';
import { getMeal } from 'redux/modules/meals/meals.selectors';
import Meal from './Meal';
import { openModal, MODAL_TYPES } from 'redux/modules/modals/modals';

const mapStateToProps = (state, ownProps) => ({
  meal: getMeal(state, ownProps.id),
});

const mapDispatchToProps = dispatch => ({
  openMindfulHelp: () =>
    dispatch(openModal(MODAL_TYPES.MINDFUL_HELP, undefined, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Meal);
