import styled, { keyframes, css } from 'styled-components';
import React from 'react';
import Center from '../Center';

const load3 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`;

const load3Animation = css`
  ${load3} 0.9s infinite linear;
`;

const Loader = styled.div`
  font-size: 10px;
  text-indent: -9999em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background: ${props => props.theme.palette.primary1};
  background: linear-gradient(
    to right,
    ${props => props.theme.palette.primary1} 10%,
    rgba(255, 255, 255, 0) 42%
  );
  position: relative;
  animation: ${load3Animation};
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: ${props => props.theme.palette.white};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

export default ({ style }) => (
  <Center style={style}>
    <Loader />
  </Center>
);
