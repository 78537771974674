import types from './clients.types';
import {
  updateClientStatusApi,
  removeInviteApi,
  renewInviteApi,
  updateProfileApi,
  updateClientSettingsApi,
} from '../../../api/client';
import { normalize } from 'normalizr';
import schemas from '../../schemas';
import { light } from '../../../themes';
import { notify } from 'react-notify-toast';

export const setCurrentClient = clientId => ({
  type: types.SET_CURRENT_CLIENT,
  clientId,
});

export const removeCurrentCLient = () => ({
  type: types.REMOVE_CURRENT_CLIENT,
});

export const fetchClients = () => ({
  type: types.FETCH_CLIENTS_REQUEST,
});

export const fetchClientsSuccess = response => ({
  type: types.FETCH_CLIENTS_SUCCESS,
  response,
});

export const fetchClientsFailure = error => ({
  type: types.FETCH_CLIENTS_FAILURE,
  error,
});

export const updateSearchString = newValue => ({
  type: types.UPDATE_SEARCH_STRING,
  value: newValue,
});

export const inviteClient = (email, locale) => ({
  type: types.ADD_CLIENT_REQUEST,
  email,
  locale,
});

export const inviteClientSuccess = (email, locale) => ({
  type: types.ADD_CLIENT_SUCCESS,
  email,
  locale,
});

export const inviteClientFailure = error => ({
  type: types.ADD_CLIENT_FAILURE,
  error,
});

export const renewInvite = (email, id) => ({
  types: [
    types.RENEW_CLIENT_REQUEST,
    types.RENEW_CLIENT_SUCCESS,
    types.RENEW_CLIENT_FAILURE,
  ],
  callAPI: () => renewInviteApi(email).catch(notifyError),
  payload: {
    email,
    id,
  },
});

export const updateClientProfile = (clientId, props) => ({
  types: [
    types.UPDATE_PROFILE_REQUEST,
    types.UPDATE_PROFILE_SUCCESS,
    types.UPDATE_PROFILE_FAILURE,
  ],
  callAPI: () => updateProfileApi(clientId, props).catch(notifyError),
  payload: {
    clientId,
    props,
  },
});

export const fetchToken = clientId => ({
  type: types.FETCH_CLIENT_TOKEN_REQUEST,
  clientId,
});

export const fetchTokenSuccess = (clientId, token) => ({
  type: types.FETCH_CLIENT_TOKEN_SUCCESS,
  clientId,
  token,
});

export const fetchTokenFailure = error => ({
  type: types.FETCH_CLIENT_TOKEN_FAILURE,
  error,
});

export const addClientNutrient = nutrient => ({
  type: types.ADD_CLIENT_NUTRIENT,
  nutrient,
});

export const removeClientNutrient = nutrient => ({
  type: types.REMOVE_CLIENT_NUTRIENT,
  nutrient,
});

export const updateClientNutrientsSuccess = response => ({
  type: types.UPDATE_CLIENT_NUTRIENTS_SUCCESS,
  response,
});

export const updateClientNutrientsFailure = error => ({
  type: types.UPDATE_CLIENT_NUTRIENTS_FAILURE,
  error,
});

export const selectClients = ids => ({
  type: types.SELECT_CLIENTS,
  ids,
});
export const unselectAllClients = () => ({
  type: types.UNSELECT_ALL_CLIENTS,
});
export const selectClient = id => ({
  type: types.SELECT_CLIENT,
  id,
});
export const unselectClient = id => ({
  type: types.UNSELECT_CLIENT,
  id,
});

export const updateClientStatus = (clientId, status) => ({
  types: [
    types.UPDATE_CLIENT_STATUS_REQUEST,
    types.UPDATE_CLIENT_STATUS_SUCCESS,
    types.UPDATE_CLIENT_STATUS_FAILURE,
  ],
  callAPI: () =>
    updateClientStatusApi(clientId, status)
      .then(res => {
        return normalize(res, schemas.client);
      })
      .catch(notifyError),
  payload: {
    clientId,
    status,
  },
});

export const updateClientSettings = (clientId, settings) => {
  return {
    types: [
      types.UPDATE_SETTINGS_REQUEST,
      types.UPDATE_SETTINGS_SUCCESS,
      types.UPDATE_SETTINGS_FAILURE,
    ],
    callAPI: () =>
      updateClientSettingsApi(clientId, settings).then(res =>
        normalize(res, schemas.client)
      ),
    payload: {
      clientId,
      settings,
    },
  };
};

export const removePendingInvite = (email, id) => ({
  types: [
    types.REMOVE_INVITE_REQUEST,
    types.REMOVE_INVITE_SUCCESS,
    types.REMOVE_INVITE_FAILURE,
  ],
  callAPI: () => removeInviteApi(email).catch(notifyError),
  payload: {
    email,
    id,
  },
});

function notifyError(err) {
  notify.show('An error occured, please try again', 'custom', 3000, {
    background: light.palette.error,
    text: 'white',
  });
  throw err;
}

export default {
  fetchToken,
  fetchTokenSuccess,
  fetchTokenFailure,

  fetchClients,
  fetchClientsSuccess,
  fetchClientsFailure,

  inviteClient,
  inviteClientSuccess,
  inviteClientFailure,

  renewInvite,

  setCurrentClient,
  removeCurrentCLient,

  updateSearchString,

  addClientNutrient,
  removeClientNutrient,
  updateClientNutrientsSuccess,
  updateClientNutrientsFailure,

  selectClients,
  unselectAllClients,
  selectClient,
  unselectClient,
};
