import nutrimentsConstant from '../constants/nutriments';

export const macroToCalorie = ({ value, code }) => {
  switch (parseInt(code, 10)) {
    case nutrimentsConstant.PROTEIN.code:
    case nutrimentsConstant.CARBS.code:
      return value * 4; // 4 Cal/g
    case nutrimentsConstant.FAT.code:
      return value * 9; // 9 Cal/g
    case nutrimentsConstant.ALCOHOL.code:
      return value * 7; // 7 Cal/g
    default:
      throw new Error(`${code} is not a macronutriments`);
  }
};

export function getIdParts(id) {
  const strId = `${id}`;
  const regex = new RegExp(/^([a-z]{3})_(.*)$/);
  const match = regex.exec(strId);
  return match ? [match[1], match[2]] : ['', strId];
}

export default {
  macroToCalorie,
};
