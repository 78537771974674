import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import widgets, { getById as getWidgetsById } from './widgets';
import widgetsTemp from './widgetsTemp';
import settings, { getReportLocale } from './settings';
import times from 'lodash/times';
import types from './reportView.types';
import { removeWidget } from './reportView.actions';
import {
  getCurrentClientName,
  getCurrentClientFirstName,
  getCurrentClientLastName,
} from '../../clients/clients.selectors';
import { getFullLocale, getDietitianId } from '../../account/account.selectors';
import upperFirst from 'lodash/upperFirst';
import storage from '../../../../utils/storage';
import { getById as getTemplatesById } from '../../reportTemplates';
import reportVersionsTypes from '../../reportVersions/reportVersions.types';
import { getReport, getIsReportFinalized } from '../../reports';
import { getReportVersion } from '../../reportVersions';
import { getTemplateVersion } from '../../templateVersions';
import reportTemplatesTypes from '../../reportTemplates/reportTemplates.types';
import templateVersionsType from '../../templateVersions/templateVersions.type';

export const diaryIds = (state = [], action) => {
  switch (action.type) {
    case types.LOAD_DIARY_IDS:
      return action.payload.diaryIds;
    case types.OPEN_REPORT_EXPORT:
      return action.diaryIds;
    case types.TOGGLE_REPORT_DIARY:
      const { diaryId } = action;
      const index = state.findIndex(x => x === diaryId);
      if (index < 0) {
        return [...state, diaryId];
      } else {
        return [...state.slice(0, index), ...state.slice(index + 1)];
      }
    default:
      return state;
  }
};

export const draggingId = (state = null, action) => {
  switch (action.type) {
    case types.DRAG_WIDGET_START:
      return action.widgetId;
    case types.DRAG_WIDGET_END:
      return null;
    default:
      return state;
  }
};

export const widgetImages = (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_WIDGET_IMAGE:
      return { ...state, [action.widgetId]: action.image };
    default:
      return state;
  }
};
export const widgetTexts = (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_WIDGET_TEXT:
      return { ...state, [action.widgetId]: action.markdown };
    default:
      return state;
  }
};

export const toProcessCount = (state = 0, action) => {
  switch (action.type) {
    case types.START_PDF_PRE_PROCESSING:
      return action.itemCount;
    default:
      return state;
  }
};
export const remainingProcessItems = (state = 0, action) => {
  switch (action.type) {
    case types.START_PDF_PRE_PROCESSING:
      return action.itemCount;
    case types.UPDATE_PDF_PRE_PROCESSING:
      return Math.max(0, state - 1);
    case types.DONE_PDF_PRE_PROCESSING:
      return 0;
    default:
      return state;
  }
};

export const widgetIds = (state = [], action) => {
  switch (action.type) {
    case types.ADD_WIDGET:
      const index =
        action.index !== undefined ? action.index : state.length - 1;
      return [...state.slice(0, index), action.id, ...state.slice(index)];
    case types.REMOVE_WIDGET:
      return state.filter(x => x !== action.widgetId);
    case types.REORDER_WIDGET:
      const start = Math.min(action.startIndex, action.endIndex);
      const end = Math.max(action.startIndex, action.endIndex);
      const result = Array.from(state); // Avoid mutation
      const [removed] = result.splice(start, 1);
      result.splice(end, 0, removed);
      return result;
    default:
      return state;
  }
};

export const pages = (state = [[]], action) => {
  switch (action.type) {
    case types.REMOVE_PAGE:
      return state.filter((_, index) => index !== action.pageIndex);
    case types.REMOVE_WIDGET:
      return state.map(x => widgetIds(x, action));
    case types.ADD_WIDGET:
      const { pageIndex } = action;
      return state.map((x, index) =>
        index !== pageIndex ? x : widgetIds(x, action)
      );
    case types.REORDER_WIDGET:
      const { startPage, endPage, startIndex, endIndex } = action;
      if (startPage === endPage) {
        return state.map((x, index) =>
          index !== startPage ? x : widgetIds(x, action)
        );
      } else {
        let a = state;
        if (endPage > a.length - 1) {
          a = [...state, times(endPage - a.length, () => [])];
        }
        return a.map((x, index) => {
          switch (index) {
            case startPage:
              return widgetIds(x, removeWidget(x[startIndex]));
            case endPage:
              return widgetIds(x, {
                type: types.ADD_WIDGET,
                id: state[startPage][startIndex],
                index: endIndex,
              });
            default:
              return x;
          }
        });
      }
    case types.RESET_EXPORT:
      return [[]];
    case types.LOAD_STRUCTURE:
      return get(action, 'payload.pages', [[]]);
    default:
      return state;
  }
};

export const editWidgetId = (state = null, action) => {
  switch (action.type) {
    case types.CONFIG_WIDGET:
      return action.widgetId;
    case types.REMOVE_WIDGET:
      return state === action.widgetId ? null : state;
    case types.CLOSE_WIDGET_CONFIG:
      return null;
    default:
      return state;
  }
};

// The logo path stay the same. As a result components are
// no re-rendering when there is a logo update. We use this
// variable to trigger updates.
export const logoToggle = (state = true, action) => {
  switch (action.type) {
    case types.UPDATE_LOGO:
      return !state;
    default:
      return state;
  }
};

export const reportId = (state = null, action) => {
  switch (action.type) {
    case types.STAGE_REPORT:
      return action.reportId;
    default:
      return state;
  }
};

export const stagedTemplateId = (state = null, action) => {
  switch (action.type) {
    case types.STAGE_TEMPLATE:
      return action.templateId;
    case reportTemplatesTypes.CREATE_REPORT_TEMPLATE_SUCCESS:
      return action.response.result;
    default:
      return state;
  }
};

export const isDirty = (state = false, action) => {
  switch (action.type) {
    case reportVersionsTypes.CREATE_REPORT_VERSION_REQUEST:
    case templateVersionsType.CREATE_TEMPLATE_VERSION_REQUEST:
    case types.LOAD_STRUCTURE:
    case types.LOAD_DIARY_IDS:
      return false;
    case types.ADD_WIDGET:
    case types.REMOVE_WIDGET:
    case types.REORDER_WIDGET:
    case types.UPDATE_WIDGET:
    case types.SET_WIDGET_SECTION:
      return true;
    case types.TOGGLE_REPORT_DIARY:
      // Templates do not persist dates but reports do
      return !action.isTemplateMode;
    default:
      return state;
  }
};

export const ExportReducer = combineReducers({
  stagedTemplateId,

  reportId,
  isDirty,

  // Current template components
  widgets,
  settings,

  // Current Target
  diaryIds,
  pages,

  // Shared state
  widgetsTemp,
  widgetImages,
  widgetTexts,
  remainingProcessItems,
  toProcessCount,
  editWidgetId,
  logoToggle,

  draggingId,
});
export default (state, action) => {
  if (action.type === types.RESET_EXPORT) {
    state = undefined;
  }

  return ExportReducer(state, action);
};

/// SELECTORS
export const getReportView = state => get(state, 'UI.reportView');

/** (state) */
export const getExportDiaryIds = createSelector(getReportView, x =>
  get(x, 'diaryIds', [])
);

export const getExportImage = createSelector(getReportView, x =>
  get(x, 'image')
);

export const getReportWidgetIds = createSelector(getReportView, x =>
  get(x, 'widgetIds')
);

export const getReportPages = createSelector(getReportView, x =>
  get(x, 'pages', [])
);
export const getReportPagesCount = createSelector(
  getReportPages,
  pages => pages.length
);
/** (state, pageIndex) */
export const getPageWidgetIds = createSelector(
  [getReportPages, (_, pageIndex) => pageIndex],
  (pages, pageIndex) => get(pages, pageIndex, [])
);

export const getDraggingId = createSelector(getReportView, x =>
  get(x, 'draggingId')
);

const _getImages = createSelector(getReportView, x => get(x, 'widgetImages'));

/** (state, widgetId) */
export const getWidgetImage = createSelector(
  [_getImages, (_, widgetId) => widgetId],
  (images, widgetId) => get(images, widgetId, null)
);

const _getTexts = createSelector(getReportView, x => get(x, 'widgetTexts'));

/** (state, widgetId) */
export const getWidgetText = createSelector(
  [_getTexts, (_, widgetId) => widgetId],
  (texts, widgetId) => get(texts, widgetId, null)
);

export const getRemainingProcessItems = createSelector(getReportView, x =>
  get(x, 'remainingProcessItems')
);
export const getToProcessCount = createSelector(getReportView, x =>
  get(x, 'toProcessCount')
);

export const getReportName = createSelector(
  [getCurrentClientFirstName, getCurrentClientLastName, getFullLocale],
  (firstName, lastName, locale) => {
    if (!firstName) return 'report.pdf';

    var date = new Date();
    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const dateStr = date.toLocaleDateString(locale, options);
    const nameStr = `${lastName}${upperFirst(firstName[0]) || ''}`;
    return [nameStr, dateStr, 'Keenoa'].join('__') + '.pdf';
  }
);
export const getReportTitle = createSelector(
  [getCurrentClientName, getFullLocale],
  (name, locale) => {
    var date = new Date();
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    const dateStr = date.toLocaleDateString(locale, options);
    return `${name} (${dateStr})`;
  }
);

export const getDietitianLogo = () => {
  const id = storage.get('id');
  const logoName = process.env.NODE_ENV === 'production' ? id : `dev-${id}`;
  const url = `https://s3.ca-central-1.amazonaws.com/keenoa-dietitians-logos/${logoName}`;
  return url;
};

export const getLogoToggle = createSelector(getReportView, x =>
  get(x, 'logoToggle')
);

export const getEditWidgetId = createSelector(getReportView, x =>
  get(x, 'editWidgetId')
);

export const getEditWidgetType = createSelector(
  [getEditWidgetId, getWidgetsById],
  (id, byId) => get(byId, [id, 'type'], null)
);

export const getCurrentReportId = createSelector(
  getReportView,
  r => r.reportId
);
export const getCurrentReport = createSelector(
  state => {
    const reportId = getCurrentReportId(state);
    return getReport(state, reportId);
  },
  report => report
);
export const getCurrentReportTitle = createSelector(getCurrentReport, r =>
  get(r, 'title', '')
);
export const getCurrentReportEmoji = createSelector(getCurrentReport, r =>
  get(r, 'emoji', '')
);
export const getCurrentReportClientId = createSelector(
  getCurrentReport,
  report => get(report, 'client_id', null)
);
export const getCurrentReportVersion = createSelector(
  state => {
    const report = getCurrentReport(state);
    return report ? getReportVersion(state, report.version) : null;
  },
  version => version
);
export const getIsCurrentReportFinalized = createSelector(
  state => {
    const id = getCurrentReportId(state);
    return getIsReportFinalized(state, id);
  },
  is => is
);

export const getCurrentReportTemplateId = createSelector(getCurrentReport, r =>
  get(r, 'report_template_id', null)
);

export const getStagedTemplateId = createSelector(
  getReportView,
  v => v.stagedTemplateId
);
export const getStagedTemplate = createSelector(
  [getStagedTemplateId, getTemplatesById],
  (id, byId) => (!id ? null : get(byId, id, null))
);
export const getStagedTemplateTitle = createSelector(getStagedTemplate, t =>
  get(t, 'title', ' ')
);
export const getStagedTemplateEmoji = createSelector(getStagedTemplate, t =>
  get(t, 'emoji', '')
);

export const getCurrentTemplate = createSelector(
  [getCurrentReportTemplateId, getTemplatesById],
  (id, byId) => (!id ? null : get(byId, id, null))
);
export const getCurrentTemplateVersionId = createSelector(
  getCurrentTemplate,
  t => get(t, 'version', null)
);
export const getCurrentTemplateVersion = createSelector(
  state => {
    const id = getCurrentTemplateVersionId(state);
    return id ? getTemplateVersion(state, id) : null;
  },
  v => v
);
export const getCurrentTemplateStructure = createSelector(
  getCurrentTemplateVersion,
  version => get(version, 'structure', null)
);

export const getIsCurrentTemplateEditable = createSelector(
  [getCurrentTemplate, getDietitianId],
  (template, id) => {
    if (!template) return false;
    if (template.visibility === 'private') return true;

    return template.owner_id === id;
  }
);

export const getCurrentTemplateTitle = createSelector(getCurrentTemplate, t =>
  get(t, 'title', null)
);

export const getCurrentTemplateEmoji = createSelector(getCurrentTemplate, t =>
  get(t, 'emoji', null)
);

export const getIsDirty = createSelector(getReportView, x => x.isDirty);

export const getCurrentStructure = createSelector(
  [getReportLocale, getReportPages, getWidgetsById],
  (locale, pages, widgets) => ({ widgets, pages, locale })
);
