import React, { useState } from 'react';
import Spacer, { SPACINGS } from 'components/Spacer';
import { TabBar, Tab } from 'components/material/Tabs';
import { BasicNutrients } from './BasicNutrients';
import { AllNutrients } from './AllNutrients';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  basic: { id: 'foods.create.basicLabel' },
  all: { id: 'foods.create.allNutrients' },
});

const TABS = {
  BASIC: 0,
  ALL: 1,
};
const HEIGHT_BEFORE_NUTRIENTS = 240;

export function PickNutrients({ nutrients, setNutrients }) {
  const [activeTab, setActiveTab] = useState(0);

  const setNutrient = (id, value) => {
    setNutrients({ ...nutrients, [id]: value });
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            minWidth: 'calc(578px + (100vw - 1049px)*.16666667)',
            borderBottom: '1px solid rgba(0,0,0,0.42)',
            marginBotton: 60,
          }}
        >
          <div
            style={{
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <TabBar
              activeTabIndex={activeTab}
              onActivate={evt => setActiveTab(evt.detail.index)}
              className="kmdc_tabBar_compact"
            >
              <Tab restrictIndicator>
                <FormattedMessage {...messages.basic} />
              </Tab>
              <Spacer spacing={SPACINGS.medium} />
              <Tab restrictIndicator>
                <FormattedMessage {...messages.all} />
              </Tab>
            </TabBar>
          </div>
        </div>

        <Spacer spacing={SPACINGS.medium} />
        <div
          style={{
            height: `calc(100vh - ${HEIGHT_BEFORE_NUTRIENTS + 10}px)`,
            overflow: 'auto',
            maxWidth: '90vw',
          }}
        >
          <div style={{ width: 'fit-content' }}>
            {activeTab === TABS.ALL ? (
              <AllNutrients nutrients={nutrients} setNutrient={setNutrient} />
            ) : (
              <BasicNutrients nutrients={nutrients} setNutrient={setNutrient} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
